import * as types from '@constants/actionTypes';

const initialState = {
  entries: [
    {
      uid: "matos",
      src: "https://cdn.sumo.app/assets/matos.mp3",
      title: "Andrealistic - Matos",
      // duration: 12,
      genre: "Electronic",
      energy: "Medium"
    },
  ],
  isFetching: false,
  didInvalidate: false,
};

export default function music(state = initialState, action) {
  switch (action.type) {
    case types.MUSIC_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case types.MUSIC_RECEIVE: {
      return {
        ...state,
        entries: action.payload.entries.reverse(),
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.payload.receivedAt,
      };
    }

    default:
      return state;
  }
}
