/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './InputNumber.scss';

const enterPressed = (e) => {
  if (e.charCode === 13) {
    e.target.blur();
  }
};

const InputNumber = ({
  name,
  onBlurFunction,
  onChangeFunction,
  onFocus,
  initialInput,
  max,
  notifications,
  onTriggerNotification,
  min,
}) => {
  const [inputValue, changeInputValue] = useState(initialInput);
  const [editingInput, toggleEditingInput] = useState(false);
  useEffect(() => {
    if (!editingInput) {
      changeInputValue(initialInput);
    }
  });

  // SEC TO MIN CONVERSION
  const currentTimeMinutes = Math.floor((inputValue) / 60);
  const currentTimeSeconds = Math.floor((inputValue) % 60);
  const paddedCurrentSeconds = `0${currentTimeSeconds}`.slice(-2);

  const displayedCurrentTime = currentTimeMinutes > 0 ? `${currentTimeMinutes}:${paddedCurrentSeconds}m` : '';
  const moveUp = inputValue >= 60 ? '-18px' : '0px';
  const visible = inputValue >= 60 ? 'block' : 'none';

  return (
    <div className='inputNumber' style={{ top: moveUp }}>
      {/* <div className = "minuteConversion" style={{ display: visible }}>
      {displayedCurrentTime}
      </div> */}
      <div style={{ display: 'flex' }}>
        <input
          name={name}
          value={inputValue}
          type="number"
          step=".1"
          max={max}
          min="0"
          onFocus={() => {
            if (onFocus) onFocus(true);
            toggleEditingInput(!editingInput);
          }}
          onChange={(e) => {
            changeInputValue(e.target.value);
            onChangeFunction(e);
          }}

          onBlur={(e) => {
            if (onFocus) onFocus(false);
            if (Number(inputValue) * 1000 > max) {
              onTriggerNotification({
                kind: 'info', // success, info, warning, danger
                message: notifications.maxExceeded,
              });
            } else if (Number(inputValue) * 1000 < min) {
              onTriggerNotification({
                kind: 'info', // success, info, warning, danger
                message: notifications.minDuration,
              });
            }
            if (e.target.value !== initialInput) {
              onBlurFunction(e);
            }
            toggleEditingInput(!editingInput);
          }}
          onKeyPress={event => enterPressed(event)}
        />
        <span>s</span>
      </div>
    </div>
  );
};

InputNumber.propTypes = {
  name: PropTypes.string,
  onBlurFunction: PropTypes.func,
  onChangeFunction: PropTypes.func,
  onTriggerNotification: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  initialInput: PropTypes.string,
  notifications: PropTypes.object,
  onFocus: PropTypes.func,
};

export default InputNumber;
