/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import * as dndTypes from '@constants/dndTypes';
import classNames from 'classnames';
import { VideoLayer, AudioLayer } from '@lib/render-engine';
import { fabric } from 'fabric';
import isEqual from 'lodash.isequal';
import { renderHelper } from '@lib/renderHelper';
import { buildAnimeTimelines, seekTimelines } from '@lib/animeTimelines';
import { shouldExitTextEdit, shouldExitShapeEdit } from '@helpers/textLayer';
import { getVideoOptions, getImageOptions } from '@helpers/videoPreview';
import { findEmptySlot } from '@helpers/timeline';
import Button from '@components/Button';
import Spinner from '@components/Spinner';
import {
  updateLayers,
  addLayer,
  createImageLayer,
  getLogoLayer,
} from '@helpers/layer';
import { getUniqueId } from '@helpers/footage';
import TitleLayer from '@components/TitleLayer';
import ShapeLayer from '@components/ShapeLayer';
import ImageLayer from '@components/ImageLayer';
import VideoElement from './VideoElement';
import AudioElement from './AudioElement';
import Guides from './Guides';
import ShortcutsModal from './ShortcutsModal';
import './VideoPreview.scss';

const propTypes = {
  buffering: PropTypes.bool,
  duration: PropTypes.number,
  backgroundInstances: PropTypes.array,
  audioInstances: PropTypes.array,
  layers: PropTypes.array,
  textInstances: PropTypes.array,
  shapeInstances: PropTypes.array,
  uid: PropTypes.string,
  onTogglePlay: PropTypes.func,
  onSidebarAdjusters: PropTypes.func,
  onDropOverlay: PropTypes.func,
  onAddShape: PropTypes.func,
  playing: PropTypes.bool,
  showGuideX: PropTypes.bool,
  showGuideY: PropTypes.bool,
  currentTime: PropTypes.number,
  onTextLayerEditEnter: PropTypes.func.isRequired,
  onTextLayerEditExit: PropTypes.func.isRequired,
  onLayerEditEnter: PropTypes.func.isRequired,
  onLayerEditExit: PropTypes.func.isRequired,
  onLayerModify: PropTypes.func.isRequired,
  onBgModify: PropTypes.func.isRequired,
  onTextLayerMove: PropTypes.func.isRequired,
  onTextLayerRotate: PropTypes.func.isRequired,
  onTextLayerChange: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  onTextLayerScale: PropTypes.func,
  onTextLayerSmartResize: PropTypes.func,
  onVideoLoad: PropTypes.func.isRequired,
  onVideoReady: PropTypes.func.isRequired,
  onSetGuides: PropTypes.func.isRequired,
  onToggleShortcuts: PropTypes.func.isRequired,
  shortcutsOpen: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  highlighted: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onApplyAttribute: PropTypes.func.isRequired,
  activeLayerId: PropTypes.number,
  titleInstances: PropTypes.array,
  // onApplyAnimation: PropTypes.func,
  onTextRemove: PropTypes.func,
  compositionUpdateInitial: PropTypes.func,
  onRemoveShape: PropTypes.func,
  onUpdateShape: PropTypes.func,
  onSelectShape: PropTypes.func,
  onUpdateLayer: PropTypes.func,
  onTriggerNotification: PropTypes.func,
  onDeSelectShape: PropTypes.func,
  selectedShapes: PropTypes.array,
  toolbarActive: PropTypes.bool,
  compositionSource: PropTypes.string,
  sidebarToolbarActive: PropTypes.func,
  textInstanceAdd: PropTypes.func,
};

const videoPreviewTarget = {
  drop(targetProps, monitor) {
    const canvas = document.getElementById('canvas').getBoundingClientRect();
    const sourceProps = monitor.getItem();
    const {
      onDropOverlay, onAddShape, composition, currentTime,
    } = targetProps;
    if (sourceProps.type === 'shape') {
      const newProperties = findEmptySlot(composition, currentTime, currentTime + 3000);
      onAddShape({
        src: sourceProps.src,
        id: getUniqueId(),
        type: 'shape',
        left: monitor.getClientOffset().x - (canvas.x + 100),
        top: monitor.getClientOffset().y - (canvas.y + 100),
        visibleFrom: newProperties.visibleFrom,
        visibleTo: newProperties.visibleTo,
        track: newProperties.track,
        ang: 0,
      });
    } else if (sourceProps.type === 'text') {
      sourceProps.handleAdd({
        duration: 3000,
        visibleFrom: 0,
        visibleTo: 3000,
        track: 4,
        styles: sourceProps.draggingStyles,
        width: 300,
        empty: true,
      });
    } else {
      const logoLayer = getLogoLayer(targetProps, sourceProps.instance, monitor, canvas);
      onDropOverlay({
        ...logoLayer,
        id: getUniqueId(),
      });
      return {
        targetType: 'canvas',
        dropComplete: 'user drop overlay image to canvas',
      };
    }
  },
};

const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
  highlighted: monitor.canDrop(),
});

class VideoPreview extends React.Component {
  videoRefs = {};
  audioRefs = {};
  renderEngine = null;

  // Used to build a event handlers object to be passed
  // forward to helper functions
  layerEventHandlers = {};

  state = {
    canvas: {},
  };

  componentDidMount() {
    const {
      duration,
      backgroundInstances,
      audioInstances,
      // shapeInstances,
      layers,
    } = this.props;

    /**
     * Eventhandlers are given to fabricjs objects and they
     * basically map fabricjs events to redux actions.
     */
    this.layerEventHandlers = {
      modified: this.props.onLayerModify,
      selected: this.props.onLayerEditEnter,
      deselected: () => { }, // this.props.onLayerEditExit, // TODO: recheck with @Marko
    };

    this.backgroundImageHandlers = {
      modified: this.props.onBgModify,
    };

    this.backgroundVideoHandlers = {
      modified: this.props.onBgModify,
    };

    this.engineEventHandlers = {
      onEnd: this.props.onTogglePlay,
    };

    const renderEngine = renderHelper.init(duration, this.engineEventHandlers);

    this.addRenderEngineLayers(
      renderEngine,
      backgroundInstances,
      [],
      audioInstances,
      layers,
    );
    // TODO: seek to beginning
    renderEngine.seek(1);

    window.addEventListener('resize', this.updateCanvasSize);
    window.dispatchEvent(new Event('resize'));

    this.renderEngine = renderEngine;
  }

  componentDidUpdate(prevProps) {
    const {
      backgroundInstances,
      audioInstances,
      duration,
      layers,
      uid,
      shapeInstances,
      currentTime,
      width,
      // activeTextId,
      height,
    } = this.props;

    const isDifferentComposition =
      prevProps.uid !== uid ||
      prevProps.width !== width ||
      prevProps.height !== height;

    if (isDifferentComposition) {
      const compositionChanged = true;
      this.reInitializeRenderEngine({
        backgroundInstances,
        textInstances: [],
        layers,
        shapeInstances,
        audioInstances,
        duration,
        compositionChanged,
      });
      // Let's trigger window resize so the guides will get updated also
      window.dispatchEvent(new Event('resize'));
      return;
    }

    if (
      !isEqual(prevProps.backgroundInstances, backgroundInstances) ||
      !isEqual(prevProps.layers, layers) ||
      !isEqual(prevProps.duration, duration) ||
      !isEqual(prevProps.audioInstances, audioInstances)
    ) {
      this.resetRenderEngine({
        backgroundInstances,
        layers,
        audioInstances,
        duration,
      });
      return;
    }

    // /*
    //  * To avoid flashing, just update layers with their new
    //  * properties like position, angle etc.
    //  */

    if (!isEqual(prevProps.layers, layers)) {
      updateLayers(this.renderEngine, layers, this.layerEventHandlers);
    }

    seekTimelines(currentTime);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateCanvasSize);
  };

  updateCanvasSize = () => {
    this.setState({
      canvas: document.getElementById('canvas').getBoundingClientRect(),
    });
  };

  addBackgroundLayers = (renderEngine, backgroundInstances) =>
    backgroundInstances.map(async (instance) => {
      if (instance.isDragging) return;
      if (instance.type === 'video') {
        const params = getVideoOptions(instance);
        const eventHandlers = {
          modified: evt =>
            this.backgroundVideoHandlers.modified(evt.target, instance.id),
        };

        const videoElement = this.videoRefs[instance.id];
        const fabricObject = new fabric.Image(videoElement, params.options);
        // For other aspect ratios / sizes
        // fabricObject.selectable = false;
        const layer = new VideoLayer(
          fabricObject,
          params.layerOptions,
          eventHandlers,
        );
        return renderEngine.addLayer(layer);
      }

      const imageLayerParams = getImageOptions(instance);

      const eventHandlers = {
        modified: (evt) => {
          if (instance.kenburns) {
            this.props.onTriggerNotification({
              kind: 'warning',
              message: 'Zoom effect was turned off',
            });
          }
          this.backgroundImageHandlers.modified(evt.target, instance.id);
        },
      };

      const layer = await createImageLayer(
        instance.src,
        imageLayerParams.layerOptions,
        imageLayerParams.fabricOptions,
        eventHandlers,
      );
      return renderEngine.addLayer(layer);
    });

  /**
   * Add layers returns an array of promises, or and empty array that should
   * resolve immediately (if no layers were added)
   */
  addLayers = (renderEngine, layers) => {
    if (!layers) return [];

    /**
     * Pass last value (scaleToHeight) if layer has no height
     * and therefore is a newly added layer.
     * Otherwise pass null so that the layer will keep its size
     * and scaling so that it matches the composition data.
     */
    return layers.map(layer =>
      addLayer(renderEngine, layer, this.layerEventHandlers));
  };

  addAudioLayers = (renderEngine, audioInstances) => {
    audioInstances.forEach((instance) => {
      const { id, from, to } = instance;

      const element = this.audioRefs[id];

      const layerOptions = {
        from,
        to,
        playFrom: 0,
      };

      const layer = new AudioLayer(element, layerOptions);
      renderEngine.addAudioLayer(layer);
    });
  };

  /**
   * First adds backgroundlayers and then other layers to make sure
   * backgroundlayers and added first to keep them beneath other layers.
   *
   * TODO: add a loading event that is shown when layers are being added.
   * image layers take a bit longer to load, so now the text layers that
   * are being added after them take a while to be visible.
   *
   * @param {Object} renderEngine - Created render engine instance
   * @param {Array} backgroundInstances - Composition backgroundinstances
   * @param {Array} textInstances - Composition text instances
   */
  addRenderEngineLayers = async (
    renderEngine,
    backgroundInstances,
    textInstances,
    audioInstances,
    layers,
    compositionChanged,
  ) => {
    const { onVideoLoad, onVideoReady } = this.props;
    if (compositionChanged) onVideoLoad();

    await Promise.all(
      this.addBackgroundLayers(renderEngine, backgroundInstances),
    );
    // await Promise.all(this.addLayers(renderEngine, layers));
    // this.addTextLayers(renderEngine, textInstances);
    this.addAudioLayers(renderEngine, audioInstances);

    if (compositionChanged) onVideoReady();
  };

  /**
   * Reinitialize engine whenever a new composition is loaded
   *
   * @param {Object} composition - New composition that gets set into the engine
   */
  reInitializeRenderEngine = ({
    backgroundInstances,
    layers,
    audioInstances,
    duration,
    compositionChanged,
  }) => {
    this.renderEngine = renderHelper.init(duration, this.engineEventHandlers);
    this.addRenderEngineLayers(
      this.renderEngine,
      backgroundInstances,
      [],
      audioInstances,
      layers,
      compositionChanged,
    );
  };

  /**
   * Resets render engine whenever changes to the composition has been made
   *
   * @param {Object} composition - New composition that gets set into the engine
   */
  resetRenderEngine = ({
    backgroundInstances,
    layers,
    audioInstances,
    duration,
    compositionChanged,
  }) => {
    this.renderEngine = renderHelper.resetEngine(duration);
    this.addRenderEngineLayers(
      this.renderEngine,
      backgroundInstances,
      [],
      audioInstances,
      layers,
      compositionChanged,
    );
  };

  /**
   * Adds reference to native <video> element so that it can be passed to render engine
   * for controlling it (play, pause...)
   */
  addVideoRef = (ref, id) => {
    this.videoRefs = { ...this.videoRefs, [id]: ref };
  };

  addAudioRef = (ref, id) => {
    this.audioRefs = { ...this.audioRefs, [id]: ref };
  };

  audioElements = audioInstances =>
    audioInstances.map(instance => (
      <AudioElement
        key={instance.src}
        src={instance.src}
        setRef={this.addAudioRef}
        id={instance.id}
      />
    ));

  videoElements = backgroundInstances =>
    backgroundInstances
      .filter(instance => instance.type === 'video')
      .map(instance => (
        <VideoElement
          src={instance.src}
          setRef={this.addVideoRef}
          id={instance.id}
          key={instance.id}
          muted={false}
          audioSrc={instance.audioSrc}
          width={instance.width}
          height={instance.height}
        />
      ));

  render() {
    const {
      backgroundInstances,
      audioInstances,
      layers,
      connectDropTarget,
      isOver,
      highlighted,
      playing,
      showGuideX,
      showGuideY,
      shortcutsOpen,
      onToggleShortcuts,
      onTextLayerMove,
      onTextLayerRotate,
      onTextLayerChange,
      onTextLayerEditEnter,
      onTextLayerScale,
      onTextLayerSmartResize,
      currentTime,
      activeTextId,
      onApplyAttribute,
      onSetGuides,
      textInstances,
      titleInstances,
      shapeInstances,
      onRemoveShape,
      onUpdateShape,
      onLayerModify,
      onLayerEditEnter,
      // onLayerEditExit,
      onSelectShape,
      onDeSelectShape,
      selectedShapes,
      activeLayerId,
      // onApplyAnimation,
      onSidebarAdjusters,
      onTextRemove,
      toolbarActive,
      sidebarToolbarActive,
      buffering,
      compositionSource,
    } = this.props;

    let { width, height } = this.props;
    const { canvas } = this.state;

    if (!width || !height) {
      width = 1920;
      height = 1080;
    }

    const classes = classNames('VideoPreview', {
      'VideoPreview--hovered': isOver,
      'VideoPreview--highlighted': highlighted,
    });

    const overlayClasses = classNames(null, {
      VideoPreview__pauseOverlay: playing,
    });

    const calculateScale = () => {
      if (width === 1920 && height === 1080) {
        return canvas.width / 1920;
      }
      if (width === 1080 && height === 1080) {
        return canvas.width / 1080;
      }
      if (width === 1080 && height === 1920) {
        return canvas.height / 1920;
      }
      if (width === 1080 && height === 1350) {
        return canvas.height / 1350;
      }
    };

    const isTextSelected = (textId) => {
      try {
        return activeTextId.find(text => text === textId);
      } catch (e) {
        return textId === activeTextId;
      }
    };

    const overlays = textInstances.concat(shapeInstances, layers)
      .filter(element => element !== undefined);
    const overlayTracks = [[], [], [], [], []];
    overlays.forEach((instance, i) => {
      let type;
      if (instance.type === 'text') {
        type = (
          <TitleLayer
            index={i}
            textInstance={instance}
            key={`htmlTextLayer-${instance.id}`}
            playing={playing}
            selected={!playing && isTextSelected(instance.id)}
            visible={
              instance.visibleFrom <= currentTime &&
              instance.visibleTo >= currentTime
            }
            canvas={canvas}
            scaleFactor={calculateScale()}
            onSetGuides={onSetGuides}
            onTextLayerMove={onTextLayerMove}
            onTextLayerRotate={onTextLayerRotate}
            onTextLayerSelect={onTextLayerEditEnter}
            onTextLayerChange={onTextLayerChange}
            onTextLayerScale={onTextLayerScale}
            onTextLayerSmartResize={onTextLayerSmartResize}
            onApplyAttribute={onApplyAttribute}
            // onApplyAnimation={onApplyAnimation}
            onTextRemove={onTextRemove}
            toolbarActive={toolbarActive}
            compositionDimention = {{ width, height }}
            compositionSource = {compositionSource}
          />
        );
      } else if (instance.type === 'shape') {
        type = (
          <ShapeLayer
            {...instance}
            key={`shapeLayer-${instance.id}`}
            visible={
              instance.visibleFrom <= currentTime &&
              instance.visibleTo >= currentTime
            }
            selected={
              instance.id === selectedShapes.find(s => s === instance.id)
            }
            canvas={canvas}
            playing={playing}
            scaleFactor={calculateScale()}
            onUpdate={onUpdateShape}
            onSelect={onSelectShape}
            onRemoveShape={onRemoveShape}
          />
        );
      } else if (instance.type === 'image' || instance.type === 'upload' || instance.type === 'logo') {
        type = (
          <ImageLayer
            {...instance}
            key={`imageLayer-${instance.id}`}
            width={instance.width}
            height={instance.height}
            top={instance.top}
            left={instance.left}
            visible={
              instance.visibleFrom <= currentTime &&
              instance.visibleTo >= currentTime
            }
            selected={instance.id === activeLayerId}
            canvas={canvas}
            playing={playing}
            scaleFactor={calculateScale()}
            onUpdate={onLayerModify}
            onSelect={onLayerEditEnter}
            onRemoveShape={onRemoveShape}
          />
        );
      }
      if (overlayTracks[instance.track]) {
        overlayTracks[instance.track].push(type);
      } else {
        overlayTracks[4].push(type);
      }
    });

    buildAnimeTimelines({
      playing,
      layers,
      shapeInstances,
      titleInstances,
      textInstances,
      backgroundInstances,
      currentTime,
    });

    return connectDropTarget(
      <div
        className={classes}
        onClick={(e) => {
          sidebarToolbarActive(false);
          const shouldExitText = shouldExitTextEdit(activeTextId, e);
          const shouldExitShape = shouldExitShapeEdit(selectedShapes, e);
          if (shouldExitText) this.props.onTextLayerEditExit();
          // unselect all shapes if clicking on background
          if (shouldExitShape) {
            onSidebarAdjusters(null);
            onDeSelectShape();
          }
        }}
      >
        <div className={overlayClasses} onClick={this.props.onTogglePlay} />
        <ShortcutsModal
          visible={shortcutsOpen}
          title={'Keyboard Shortcuts'}
          confirmButton={
            <Button color="pink" onClick={onToggleShortcuts}>
              OK
            </Button>
          }
          onClose={onToggleShortcuts}
        />
        <Guides
          showGuideX={showGuideX}
          showGuideY={showGuideY}
          canvasState={canvas}
        />
        <canvas id="canvas" width={width} height={height}></canvas>
        {this.videoElements(backgroundInstances)}
        {this.audioElements(audioInstances)}
        {(
          <div
            className="previewContainer"
            style={{
              transform: `scale(${calculateScale()})`,
              transformOrigin: '0 0',
              position: 'fixed',
              width,
              height,
              top: canvas.top,
              left: canvas.left,
              pointerEvents: 'none',
            }}
          >
            {buffering && <div className="videoPreviewSpinner">
              <Spinner visible={true} />
            </div>}
            <div
              style={{ width: '100%', height: '100%', position: 'relative' }}
            >
              {overlayTracks[4]}
              {overlayTracks[3]}
              {overlayTracks[2]}
              {overlayTracks[1]}
              {overlayTracks[0]}
            </div>
          </div>
        )}
      </div>,
    );
  }
}

VideoPreview.propTypes = propTypes;

export default DropTarget(
  dndTypes.VIDEO_PREVIEW,
  videoPreviewTarget,
  targetCollect,
)(VideoPreview);
