import React from 'react';
import PropTypes from 'prop-types';

import NavigationItem from './NavigationItem';

import './Navigation.scss';

const Navigation = (props) => {
  const {
    items,
    onTabToggle,
    disabledTabs,
    onSidebarAdjusters,
  } = props;

  const itemElements = (navitems) => {
    if (navitems.length === 0) return null;

    return navitems.map((item) => {
      let disabled = false;
      if (disabledTabs.includes(item.name)) disabled = true;

      return (
        <NavigationItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          active={item.active}
          name={item.name}
          onClick={onTabToggle}
          disabled={disabled}
          onSidebarAdjusters={onSidebarAdjusters}
        />);
    });
  };

  return (
    <div className="Navigation_tabs">
      {itemElements(items)}
    </div>
  );
};

Navigation.defaultProps = {
  items: [],
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
  onExpandToggle: PropTypes.func,
  onTabToggle: PropTypes.func.isRequired,
  disabledTabs: PropTypes.array,
  onSidebarAdjusters: PropTypes.func,
};

export default Navigation;
