const CANVAS = {
  X: 1920,
  Y: 1080,
};

const animationOptions = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Fade",
    value: "fadein",
  },
  {
    label: "Zoom",
    value: "zoomInOut",
  },
  {
    label: "Bounce",
    value: "bounceInOut",
  },
  {
    label: "Slide\rleft-right",
    value: "slideLeftRight",
  },
  {
    label: "Slide\rright-left",
    value: "slideRightLeft",
  },
  {
    label: "Slide\rup-down",
    value: "slideUpDown",
  },
  {
    label: "Slide\rdown-up",
    value: "slideDownUp",
  },
  {
    label: "Bounce\rHorizontal",
    value: "bounceHorizontal",
  },
  {
    label: "Bounce\rVertical",
    value: "bounceVertical",
  },
  {
    label: "Sweep\rLetters",
    value: "sweepinchars",
  },
  {
    label: "Roll Letters",
    value: "rollinoutchars",
  },
  {
    label: "Rising\rLetters",
    value: "risinginoutchars",
  },
  {
    label: "Dropping\rWords",
    value: "droppinginoutwords",
  },
  {
    label: "Fade\rLines",
    value: "fadeinoutlines",
  },
  {
    label: "Blur Letters",
    value: "blurChars",
  },
  {
    label: "Roll\rLines",
    value: "rollLines",
  },
  {
    label: "Run Number",
    value: "runNumber",
  },
];

const buildFadeInAnimation = (textInstance) => {
  const animationLength = 500;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "fadein",
    duration: animationLength,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLength,
        values: {
          opacity: 0,
        },
        easing: "easeInQuart",
      },
    ],
    out: [
      {
        from: visibleTo - animationLength,
        to: visibleTo,
        values: {
          opacity: 0,
        },
        easing: "easeInQuart",
      },
    ],
  };
};

const buildFadeInOutChars = (textInstance) => {
  const animationLength = 1600;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "fadeinoutchars",
    split: "SPLIT_CHARS",
    duration: animationLength,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLength,
        values: {
          opacity: 0,
        },
        easing: "easeInQuart",
      },
    ],
    out: [
      {
        from: visibleTo - animationLength,
        to: visibleTo,
        values: {
          opacity: 0,
        },
        easing: "easeInQuart",
      },
    ],
  };
};

const buildFadeInOutLines = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "fadeinoutlines",
    split: "SPLIT_LINES",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        values: {
          opacity: 0,
        },
        easing: "easeInQuart",
        overlap: 0.3,
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        values: {
          opacity: 0,
        },
        easing: "easeInQuart",
        overlap: 0.3,
      },
    ],
  };
};

const buildSweepInChars = (textInstance) => {
  const animationLengthIn = 1600;
  const animationLengthOut = 1600;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "sweepinchars",
    split: "SPLIT_CHARS",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        values: {
          opacity: 0,
        },
        easing: "easeInQuad",
        overlap: 0.5,
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        values: {
          opacity: 0,
        },
        easing: "easeOutQuad",
        overlap: 0.5,
      },
    ],
  };
};

const buildRollInOut = (textInstance) => {
  const animationLengthIn = 1600;
  const animationLengthOut = 1600;
  const { visibleFrom, visibleTo, left } = textInstance;

  return {
    name: "rollinoutchars",
    split: "SPLIT_CHARS",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        values: {
          opacity: 0,
          left: left + CANVAS.X * 0.9,
        },
        easing: "easeInExpo",
        overlap: 0.4,
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        values: {
          opacity: 0,
          left: left - CANVAS.X * 0.5,
        },
        easing: "easeOutExpo",
        overlap: 0.4,
      },
    ],
  };
};

const buildRisingInOut = (textInstance) => {
  const animationLengthIn = 1600;
  const animationLengthOut = 1600;
  const { visibleFrom, visibleTo, top } = textInstance;

  return {
    name: "risinginoutchars",
    split: "SPLIT_CHARS",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        values: {
          opacity: 0,
          top: top + CANVAS.Y * 0.8,
        },
        easing: "easeInExpo",
        overlap: 0.3,
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        values: {
          opacity: 0,
          top: top - CANVAS.Y * 0.5,
        },
        easing: "easeOutExpo",
        overlap: 0.3,
      },
    ],
  };
};

const buildDroppingWordsInOut = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo, top } = textInstance;

  return {
    name: "droppinginoutwords",
    split: "SPLIT_WORDS",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        values: {
          opacity: 0,
          top: top - CANVAS.Y * 0.3,
        },
        easing: "easeInExpo",
        overlap: 0.3,
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        values: {
          opacity: 0,
        },
        easing: "easeOutExpo",
        overlap: 0.3,
      },
    ],
  };
};

const buildBlurChars = (textInstance) => {
  const animationLengthIn = 1600;
  const animationLengthOut = 1600;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "blurChars",
    split: "SPLIT_CHARS",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildRollLines = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "rollLines",
    split: "SPLIT_LINES",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildRunNumber = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "runNumber",
    split: "SPLIT_LINES",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildZoomInOut = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "zoomInOut",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildSlideLeftRight = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "slideLeftRight",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildSlideRightLeft = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "slideRightLeft",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildSlideUpDown = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "slideUpDown",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildSlideDownUp = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "slideDownUp",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildBounceInOut = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "bounceInOut",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildBounceHorizontal = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "bounceHorizontal",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildBounceVertical = (textInstance) => {
  const animationLengthIn = 800;
  const animationLengthOut = 800;
  const { visibleFrom, visibleTo } = textInstance;

  return {
    name: "bounceVertical",
    duration: animationLengthIn + animationLengthOut,
    in: [
      {
        from: visibleFrom,
        to: visibleFrom + animationLengthIn,
        easing: "easeOutExpo",
      },
    ],
    out: [
      {
        from: visibleTo - animationLengthOut,
        to: visibleTo,
        easing: "easeInExpo",
      },
    ],
  };
};

const buildAnimationSettings = (animationName, textInstance) => {
  switch (animationName) {
    case "fadein":
      return buildFadeInAnimation(textInstance);
    case "fadeinoutchars":
      return buildFadeInOutChars(textInstance);
    case "fadeinoutlines":
      return buildFadeInOutLines(textInstance);
    case "sweepinchars":
      return buildSweepInChars(textInstance);
    case "rollinoutchars":
      return buildRollInOut(textInstance);
    case "risinginoutchars":
      return buildRisingInOut(textInstance);
    case "droppinginoutwords":
      return buildDroppingWordsInOut(textInstance);
    // NEW ANIMS
    case "zoomInOut":
      return buildZoomInOut(textInstance);
    case "slideLeftRight":
      return buildSlideLeftRight(textInstance);
    case "slideRightLeft":
      return buildSlideRightLeft(textInstance);
    case "slideUpDown":
      return buildSlideUpDown(textInstance);
    case "slideDownUp":
      return buildSlideDownUp(textInstance);
    case "bounceInOut":
      return buildBounceInOut(textInstance);
    case "bounceHorizontal":
      return buildBounceHorizontal(textInstance);
    case "bounceVertical":
      return buildBounceVertical(textInstance);
    case "blurChars":
      return buildBlurChars(textInstance);
    case "rollLines":
      return buildRollLines(textInstance);
    case "runNumber":
      return buildRunNumber(textInstance);
    case "none":
      return false;
    default:
      return false;
  }
};

// Can be used with re-resizable modifications, but prefer syncAnimationPosition
const checkAnimationPosition = (instance, deltaTime, direction) => {
  const animationInFrom =
    direction === "left"
      ? instance.animation.in[0].from - deltaTime
      : instance.animation.in[0].from + deltaTime;

  const animationInTo =
    direction === "left"
      ? instance.animation.in[0].to - deltaTime
      : instance.animation.in[0].to + deltaTime;

  const animatioOutFrom =
    direction === "left"
      ? instance.animation.out[0].from - deltaTime
      : instance.animation.out[0].from + deltaTime;

  const animatioOutTo =
    direction === "left"
      ? instance.animation.out[0].to - deltaTime
      : instance.animation.out[0].to + deltaTime;

  const animation = {
    ...instance.animation,
    in: [
      {
        ...instance.animation.in[0],
        from: animationInFrom,
        to: animationInTo,
      },
    ],
    out: [
      {
        ...instance.animation.out[0],
        from: animatioOutFrom,
        to: animatioOutTo,
      },
    ],
  };

  return {
    ...instance,
    animation,
  };
};

// Should be used for textInstances with corrected timestamps
// =====  NOTE: legacy code only used with previous animation settings, delete after test === //

const syncAnimationPosition = (instance, prevDuration) => {
  const {
    animation, visibleFrom, visibleTo, duration,
  } = instance;
  if (!animation) return instance;
  // TODO: animation in.from and animation in.to,
  // should be reduced together with resized textInstances
  // reduction should only be used when textInstance is too short for applied animation
  // revert back to original duration if text duration is more than original animation duration
  const originalAnimation = buildAnimationSettings(animation.name, instance);
  const { duration: originalAnimationDuration } = originalAnimation;

  const { duration: appliedAnimationDuration } = animation;
  const durationAspect = duration / prevDuration;
  let animationInDuration = animation.in[0].to - animation.in[0].from;
  let animationOutDuration = animation.out[0].to - animation.out[0].from;

  if (originalAnimationDuration > duration) {
    animationInDuration *= durationAspect;
    animationOutDuration *= durationAspect;
  }

  const animationInFrom = visibleFrom;
  const animationInTo = visibleFrom + animationInDuration;
  const animatioOutFrom = visibleTo - animationOutDuration;
  const animatioOutTo = visibleTo;

  // when text is too small for animation delete animation;
  if (animationInTo > animatioOutFrom) {
    return {
      ...instance,
      animation: null,
    };
  }

  const syncAnimation = {
    ...animation,
    duration: appliedAnimationDuration * durationAspect,
    in: [
      {
        ...animation.in[0],
        from: animationInFrom,
        to: animationInTo,
      },
    ],
    out: [
      {
        ...animation.out[0],
        from: animatioOutFrom,
        to: animatioOutTo,
      },
    ],
  };

  return {
    ...instance,
    animation: syncAnimation,
  };
};

const calculateZoom = (video) => {
  const zoomEffect = {
    X: 0,
    Y: 0,
    scale: 1,
  };
  const scaleMultiplier = 0.01;

  // Calculate zoom position
  const startX = Math.floor(Math.random() * 10);
  const startY = Math.floor(Math.random() * 10);
  const posXDurationCompensation = (video.duration / 1000) * startX;
  const posYdurationCompensation = (video.duration / 1000) * startY;

  zoomEffect.X =
    posXDurationCompensation < 500 ? posXDurationCompensation : 500;
  zoomEffect.Y =
    posYdurationCompensation < 500 ? posYdurationCompensation : 500;

  // Calculate Zoom movement
  const scaleDurationCompensation =
    1 + ((video.duration / 1000) * scaleMultiplier);
  zoomEffect.scale =
    scaleDurationCompensation < 1.5 ? scaleDurationCompensation : 1.5;
  return zoomEffect;
};

export {
  animationOptions,
  buildAnimationSettings,
  checkAnimationPosition,
  syncAnimationPosition,
  calculateZoom,
};
