import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchDesigns, unPublishDesign, filterDesigns } from '@actions/designs';
import { sidebarProjectSelect } from '@actions/sidebar';
import { toggleDashboard } from '@actions/dashboard';
import DesignTab from '@components/DesignTab';
import { addComposition, addCompositionWithDesign, addProject } from '@actions/projects';
import { templateButtons } from '@constants/sidebar';
import { filterByAspectRatio } from '@helpers/designs';

const propTypes = {
  fetchDesigns: PropTypes.func,
};
const mapStateToProps = (state) => {
  const {
    projects, sidebar, user, dashboard,
  } = state;
  const { activeTab: view, dashboardView } = dashboard;
  const { designs, designFilter: aspectRatio } = state.designs;
  const { activeProjectUid } = sidebar;
  // NOTE: : code below is removed because
  // New video from template runs over the latest video, even thogh "New video" was selected.
  // const composition = state.composition.present;
  // const { backgroundInstances, textInstances } = composition;
  // const isNewComposition = backgroundInstances.length === 0 && textInstances.length === 0;
  const getAspectFilterDesigns = () => {
    if (view === 'dashboard') {
      return designs.filter(design => filterByAspectRatio(design, 'square', view)).slice(0, 5);
    }
    return designs.filter(design => filterByAspectRatio(design, aspectRatio || 'facebook_vertical', view));
  };
  const aspectFilteredDesigns = getAspectFilterDesigns();
  const firstProject = projects.entries.length > 0 ? projects.entries[0].uid : '';

  return {
    firstProject,
    designs: aspectFilteredDesigns,
    activeProjectUid,
    dashboardView,
    // isNewComposition,
    userRole: user.role,
    filterButtons: templateButtons,
    aspectRatio,
  };
};

const DesignTabContainer = (props) => {
  useEffect(() => {
    props.fetchDesigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <DesignTab {...props} />;
};

DesignTabContainer.propTypes = propTypes;
export default connect(
  mapStateToProps,
  {
    fetchDesigns,
    filterAspectRatio: filterDesigns,
    onAddComposition: addComposition,
    onAddCompositionWithDesign: addCompositionWithDesign,
    onSelectProject: sidebarProjectSelect,
    onToggleSidebarGallery: toggleDashboard,
    handleRemovePublished: unPublishDesign,
    onAddProject: addProject,
  },
)(DesignTabContainer);
