import { DraggableImageFootage } from '@components/DraggableFootage';
import ImageThumbnail from '@components/ImageThumbnail';
import { DEFAULT_FOOTAGE_DURATION } from '@constants/composition';
import { getNewInstanceTimeslot } from '@helpers/composition';
import React from 'react';

const ImageElements = ({
  imagePages,
  filter,
  onDownload,
  onAdd,
  onCancel,
  onRemoveUploadImage,
  composition,
  onAddToFavorite,
  onProgressJump,
  onRemoveFromFavorite,
  onSelect,
  expandPool,
  formatBackgroundInstances,
}) => {
  // adds image to favorite
  const handleFavorite = (event, image) => {
    const type = 'image';
    event.stopPropagation();
    onAddToFavorite(image, type);
  };
  const handleRemoveFavorite = (event, favoriteId) => {
    const type = 'image';
    event.stopPropagation();
    onRemoveFromFavorite(favoriteId, type);
  };

  // adds image to timeline
  const handleAdd = async (index, sourceImage, isDragging) => {
    const timeslot = getNewInstanceTimeslot(
      composition.backgroundInstances,
      DEFAULT_FOOTAGE_DURATION,
    );
    // if (filter === 'stock') onDownload(sourceImage.id);
    const sourceItem = {
      ...sourceImage,
      id: isDragging ? sourceImage.id : Date.now(),
      duration: DEFAULT_FOOTAGE_DURATION,
      playFrom: 0,
      src: sourceImage.src,
      top: 0,
      left: 0,
      type: 'image',
      scaleToHeight: null,
      scaleToWidth: null,
      isDragging,
      visibleFrom: timeslot.visibleFrom,
    };
    onAdd(index, sourceItem);
    window.post.message("video-add-image")
    if (!isDragging) {
      onSelect(sourceItem.id);
      onProgressJump(timeslot.visibleFrom + 1);
    }
  };
  return imagePages.map(image => {
    const { isFav } = image;
    const handleRemoveUpload = (e) => {
      e.stopPropagation();
      onRemoveUploadImage(image.uid);
    };
    // all tabs need delete button except stock
    if (filter === 'logo' && image.type !== 'logo') return null;
    const showDelete = filter !== 'stock';
    return (
      <div
        key={!isFav ? image.id : image.favoriteId}
        className="content-grid-col"
        onClick={() => handleAdd(composition.backgroundInstances.length, image)}
      >
        <DraggableImageFootage
          // onDragStart = {isDragging => handleAdd(-1, image, isDragging)}
          onCancel={onCancel}
          list="gallery"
          instance={image}
          type="image"
          expandPool={expandPool}
          duration={composition.duration}
          handleAdd={handleAdd}
          formatBackgroundInstances={formatBackgroundInstances}
        >
          <ImageThumbnail
            filename={image.thumbnail.small}
            showDelete={showDelete}
            filter={filter}
            onDelete={
              filter !== ('stock' || 'favorite')
                ? handleRemoveUpload
                : event => handleRemoveFavorite(event, image.favoriteId)
            }
            isFav={image.isFav}
            onFavoriteImage={event => handleFavorite(event, image)}
          />
        </DraggableImageFootage>
      </div>
    );
  });
};

export default ImageElements;
