import React, { useState, useEffect, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import flow from 'lodash.flow';
import InPoint from '@components/NewTimeline/InPoint';
import OutPoint from '@components/NewTimeline/OutPoint';
import { TextIcon, Shapes, Video, Photo } from '@components/Icons';
import classNames from 'classnames';
import { getEmptyImage } from 'react-dnd-html5-backend';
import './NewTimeline.scss';

const instanceSource = {
  beginDrag(props) {
    // Return the data describing the dragged item
    props.changeTrackRef(props.trackIndex, props.trackRef);
    props.changeIndex(props.index, props.onIndex);
    props.setTrashVisible(true);
    document.getElementsByClassName('Trash')[0].style.pointerEvents = 'auto';
    // document.getElementById(props.instance.id).style.opacity = 0;
    const item = {
      compositionDuration: props.compositionDuration,
      type: props.instance.type,
      id: props.instance.id,
      visibleFrom: props.instance.visibleFrom,
      visibleTo: props.instance.visibleTo,
      index: props.index,
      onDelete: props.onDelete,
      trackIndex: props.trackIndex,
      poolIndex: props.poolIndex,
      msToPixel: props.msToPixel,
      dimensions: document.getElementById(props.instance.id).getBoundingClientRect(),
      trackHeight: document.getElementsByClassName('track')[0].getBoundingClientRect().height,
      backgroundImage: props.instance.thumbnail,
      instances: props.instances,
      tracks: props.tracks,
      backgroundTrack: props.backgroundTrack,
      poolType: props.poolType,
    };
    return item;
  },

  isDragging(props, monitor) {
    return props.instance.id === monitor.getItem().id;
  },

  endDrag() {
    document.getElementsByClassName('Trash')[0].style.opacity = 0;
    document.getElementsByClassName('Trash')[0].style.pointerEvents = 'none';
  },
};

const sourceCollect = (connect, monitor) => ({
  // Call this function inside render()
  // to let React DnD handle the drag events:
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  // You can ask the monitor about the current drag state:
  isDragging: monitor.isDragging(),
});

const instanceTarget = {
  hover(props) {
    props.toggleHovering(true, props.hoveringInstance);
    const { index } = props;
    let instances = document
      .getElementsByClassName('pool')[1]
      .getElementsByClassName('track')[0]
      .getElementsByClassName('instance');
    instances = Array.prototype.slice.call(instances);
    instances.forEach((instance) => {
      if (instance.type === 'video' || instance.type === 'image') {
        instance.style.opacity = 1;
      }
    });
    const hoveredElement = instances[index];
    if (hoveredElement) hoveredElement.style.opacity = 0.5;
  },

  drop(props, monitor) {
    const { instance } = monitor.getItem();
    const newItem = {
      ...instance,
      playFrom: 0,
      visibleFrom: props.instance.visibleFrom,
      visibleTo: props.instance.visibleTo,
      duration: props.instance.visibleTo - props.instance.visibleFrom,
    };
    const targetId = props.instance.id;
    props.onReplace(targetId, newItem);
  },
};

const instanceCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
  canDrop: monitor.canDrop(),
});

const Instance = (props) => {
  const {
    instance,
    msToPixel,
    timelineWidth,
    poolType,
    // trackIndex,
    // poolIndex,
    onVideoResize,
    onTextResize,
    onTextLayerSelect,
    onSelectShape,
    onUpdateShape,
    onUpdateLayer,
    onLayerSelect,
    onSelect,
    activeBackgroundId,
    activeTextId,
    activeLayerId,
    activeShapeId,
    // expanded,
  } = props;

  const { visibleTo, visibleFrom } = instance;
  const [state, _setState] = useState({});
  const stateRef = useRef(state);
  const setState = (data) => {
    stateRef.current = data;
    _setState(data);
  };
  useEffect(() => {
    const { connectDragPreview } = props;
    connectDragPreview(getEmptyImage());
    const widthOnTimeline = (visibleTo - visibleFrom) * msToPixel;
    setState({
      trackHeight: document.getElementsByClassName('track')[0].getBoundingClientRect().height,
      trackY: document.getElementsByClassName('track')[0].getBoundingClientRect().top,
      left: visibleFrom * msToPixel,
      widthOnTimeline,
      isResizingIn: false,
      isResizingOut: false,
      isMoving: false,
      initialOut: null,
      initialIn: null,
      diffX: null,
      newTrack: false,
      timelineWidth,
      dragStart: {
        x: null,
        y: null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleClick = (instanceId, event) => {
    const mouseClickCoords = event.pageX;
    const timelineCoords = document.getElementsByClassName('pool')[0].getBoundingClientRect();
    const mouseCoordToTimelineRatio = (mouseClickCoords - timelineCoords.left) / timelineCoords.width;
    const seekPoint = Math.floor(props.duration * mouseCoordToTimelineRatio);
    console.log("clicked")
    props.onProgressJump(seekPoint);

    switch (instance.type) {
      case 'text':
        onTextLayerSelect(instanceId);
        break;
      case 'shape':
        onSelectShape(instanceId);
        break;
      case 'video':
        onSelect(instanceId);
        break;
      case 'image':
        if (poolType === 'background') {
          onSelect(instanceId);
        } else {
          onLayerSelect(instanceId);
        }
        break;
      case 'logo':
        if (poolType === 'background') {
          onSelect(instanceId);
        } else {
          onLayerSelect(instanceId);
        }
        break;
      default:
    }
  };

  let instanceIcon;
  switch (instance.type) {
    case 'text':
      instanceIcon = <TextIcon />;
      break;
    case 'shape':
      instanceIcon = <Shapes />;
      break;
    case 'video':
      instanceIcon = <Video />;
      break;
    case 'image':
      instanceIcon = <Photo />;
      break;
    case 'layer':
      instanceIcon = <Photo />;
      break;
    default:
  }
  let backgroundImage;
  switch (instance.type) {
    case 'video':
      // console.log('check thumbnail', instance);
      backgroundImage = instance.thumbnail.hd;
      break;
    case 'image':
      if (poolType === 'background') {
        backgroundImage = instance.thumbnail.hd;
      }
      break;
    default:
  }

  const { isDragging, connectDragSource, connectDropTarget } = props;

  const selectedClass = classNames('', {
    highlighted:
      instance.id === activeBackgroundId ||
      instance.id === activeTextId ||
      instance.id === activeLayerId ||
      instance.id === activeShapeId,
  });
  return (
      <div
        onClick={event => handleClick(instance.id, event)}
        id={instance.id}
        className={`instance ${props.transitionActive ? 'transition' : ''}`}
        style={{
          opacity: `${isDragging ? 0 : 1}`,
          top: `${props.expanded ? 0 : -2}px`,
          left: `${stateRef.current.left}px`,
          width: `${stateRef.current.widthOnTimeline}px`,
        }}
      >
        <div
          className={`${instance.type} dragHandles ${selectedClass}`}
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "contain", opacity: `${isDragging ? 0 : 1}` }}>
          {stateRef.current.widthOnTimeline > 30 && <div className={'instanceIcon'}>{instanceIcon}</div>}
          <InPoint
            poolType = {poolType}
            instance={props.instance}
            instances={props.instances}
            duration={props.duration}
            msToPixel={props.msToPixel}
            timelineWidth={props.timelineWidth}
            index={props.index}
            onIndex={props.onIndex}
            backgroundTrack={props.backgroundTrack}
            trackIndex={props.trackIndex}
            trackHeight={props.trackHeight}
            poolIndex={props.poolIndex}
            onVideoResize={onVideoResize}
            onUpdateLayer={onUpdateLayer}
            onUpdateShape={onUpdateShape}
            onDrag={props.onDrag}
            changeIndex={props.changeIndex}
            onTextResize={onTextResize}
            toggleHovering={props.toggleHovering}
            hoveringInstance={props.hoveringInstance}
            activeBackgroundId={props.activeBackgroundId}
            activeTextId={props.activeTextId}
            activeLayerId={props.activeLayerId}
            activeShapeId={props.activeShapeId}
            toggleTransition={props.toggleTransition}
            backgroundAddPosition = {props.backgroundAddPosition}
            changeBackgroundAddPosition = {props.changeBackgroundAddPosition}
          />
          {stateRef.current.widthOnTimeline > 50 && connectDropTarget(
            connectDragSource(
              <div
                className="dragHandle"
                style={{
                  flexGrow: stateRef.current.widthOnTimeline > 5 ? 6 : 1,
                }}
              >
              </div>,
            ),
          )}
          <OutPoint
            poolType = {poolType}
            instance={props.instance}
            instances={props.instances}
            duration={props.duration}
            msToPixel={props.msToPixel}
            timelineWidth={props.timelineWidth}
            index={props.index}
            onIndex={props.onIndex}
            backgroundTrack={props.backgroundTrack}
            trackIndex={props.trackIndex}
            trackHeight={props.trackHeight}
            poolIndex={props.poolIndex}
            onVideoResize={onVideoResize}
            onUpdateLayer={onUpdateLayer}
            onUpdateShape={onUpdateShape}
            onDrag={props.onDrag}
            changeIndex={props.changeIndex}
            onTextResize={onTextResize}
            toggleHovering={props.toggleHovering}
            hoveringInstance={props.hoveringInstance}
            activeBackgroundId={props.activeBackgroundId}
            activeTextId={props.activeTextId}
            activeLayerId={props.activeLayerId}
            activeShapeId={props.activeShapeId}
            toggleTransition={props.toggleTransition}
            backgroundAddPosition = {props.backgroundAddPosition}
            changeBackgroundAddPosition = {props.changeBackgroundAddPosition}
          />
        </div>
      </div>
  );
};

Instance.propTypes = {
  toggleHovering: PropTypes.func,
  isDragging: PropTypes.bool,
  instance: PropTypes.object,
  timelineWidth: PropTypes.number,
  msToPixel: PropTypes.number,
  index: PropTypes.number,
  instances: PropTypes.array,
  backgroundTrack: PropTypes.array,
  tracks: PropTypes.array,
  trackIndex: PropTypes.number,
  trackHeight: PropTypes.number,
  trackY: PropTypes.number,
  modifyComposition: PropTypes.func,
  poolIndex: PropTypes.number,
  onUpdateLayer: PropTypes.func,
  onDrag: PropTypes.func,
  changeIndex: PropTypes.func,
  hoveringInstance: PropTypes.object,
  poolType: PropTypes.string,
  duration: PropTypes.number,
  onIndex: PropTypes.number,
};

Instance.defaultProps = {};

export default flow(
  DropTarget(['video', 'image'], instanceTarget, instanceCollect),
  DragSource('instance', instanceSource, sourceCollect),
)(Instance);
