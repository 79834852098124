import * as types from '@constants/actionTypes';

const initialState = {
  activeTab: 'dashboard',
  dashboardSections: [
    {
      name: 'Dashboard',
    },
    {
      name: 'Folders',
    },
    {
      name: 'Templates',
    },
    {
      name: 'BrandKit',
    },
    {
      name: 'Generator',
    },
  ],
  dashboardView: true,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case types.APP_DASHBOARD_TOGGLE:
      return {
        ...state,
        dashboardView: !state.dashboardView,
      };
    case types.DASHBOARD_SET_CURRENT_VIEW: {
      const { view } = action.payload;
      return {
        ...state,
        activeTab: view,
      };
    }
    case types.PROJECTS_ADD_RECEIVE:
    case types.COMPOSITION_LOAD_DESIGN:
    case types.COMPOSITION_LOAD_GENERATED: {
      const { dashboardView } = state;
      return {
        ...state,
        dashboardView: dashboardView ? !dashboardView : dashboardView,
      };
    }
    default:
      return state;
  }
}
