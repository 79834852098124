const getResizeHandle = (e) => {
  const { classList } = e.target;
  if (classList.contains('inPoint')) {
    return 'left';
  } else if (classList.contains('outPoint')) {
    return 'right';
  }
  return false;
};

export {
  getResizeHandle,
};
