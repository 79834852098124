export const DASHBOARD_SET_CURRENT_VIEW = 'DASHBOARD_SET_CURRENT_VIEW';
export const COMPOSITION_RESET = 'COMPOSITION_RESET';
export const COMPOSITION_UPDATE_SIZE = 'COMPOSITION_UPDATE_SIZE';
export const COMPOSITION_SHOW_VIDEO_CONTEXT = 'COMPOSITION_SHOW_VIDEO_CONTEXT';
export const COMPOSITION_SHOW_VISIBLITY_HANDLER = 'COMPOSITION_SHOW_VISIBLITY_HANDLER';
export const COMPOSITION_VIDEO_INSTANCE_ADD = 'COMPOSITION_VIDEO_INSTANCE_ADD';
export const COMPOSITION_VIDEO_INSTANCE_MOVE = 'COMPOSITION_VIDEO_INSTANCE_MOVE';
export const COMPOSITION_VIDEO_INSTANCE_DRAG_TO_TIMELINE = 'COMPOSITION_VIDEO_INSTANCE_DRAG_TO_TIMELINE';
export const COMPOSITION_VIDEO_INSTANCE_REMOVE = 'COMPOSITION_VIDEO_INSTANCE_REMOVE';
export const COMPOSITION_VIDEO_INSTANCE_REPLACE = 'COMPOSITION_VIDEO_INSTANCE_REPLACE';
export const COMPOSISION_VIDEO_INSTANCE_SELECT = 'COMPOSISION_VIDEO_INSTANCE_SELECT';
export const COMPOSISION_VIDEO_INSTANCE_UNSELECT = 'COMPOSISION_VIDEO_INSTANCE_UNSELECT';
export const COMPOSISION_SHAPELAYER_UNSELECT = 'COMPOSISION_SHAPELAYER_UNSELECT';
export const COMPOSITION_BACKGROUND_INSTANCE_UPDATE_TIMESTAMPS =
  'COMPOSITION_BACKGROUND_INSTANCE_UPDATE_TIMESTAMPS';
export const COMPOSITION_TEXT_INSTANCE_UPDATE_TIMESTAMPS =
  'COMPOSITION_TEXT_INSTANCE_UPDATE_TIMESTAMPS';
export const COMPOSITION_LAYER_ALWAYS_VISIBLE = 'COMPOSITION_LAYER_ALWAYS_VISIBLE';
export const COMPOSITION_LAYER_INSTANCE_UPDATE_TIMESTAMPS = 'COMPOSITION_LAYER_INSTANCE_UPDATE_TIMESTAMPS';
export const COMPOSITION_SHAPE_INSTANCE_UPDATE_TIMESTAMPS = 'COMPOSITION_SHAPE_INSTANCE_UPDATE_TIMESTAMPS';
export const COMPOSITION_BACKGROUND_INSTANCE_MOVE = 'COMPOSITION_BACKGROUND_INSTANCE_MOVE';
export const COMPOSITION_VIDEO_GENERATE_UNIQUEID = 'COMPOSITION_VIDEO_GENERATE_UNIQUEID';
export const COMPOSITION_VIDEO_INSTANCE_SPLIT = 'COMPOSITION_VIDEO_INSTANCE_SPLIT';
export const COMPOSITION_VIDEO_INSTANCE_RESIZE = 'COMPOSITION_VIDEO_INSTANCE_RESIZE';
export const COMPOSITION_TEXT_INSTANCE_RESIZE = 'COMPOSITION_TEXT_INSTANCE_RESIZE';
export const COMPOSITION_TEXT_INSTANCE_RESIZE_START = 'COMPOSITION_TEXT_INSTANCE_RESIZE_START';
export const COMPOSITION_TEXT_INSTANCE_MOVE = 'COMPOSITION_TEXT_INSTANCE_MOVE';
export const COMPOSITION_TEXT_INSTANCE_SWAP = 'COMPOSITION_TEXT_INSTANCE_SWAP';
export const COMPOSITION_TEXT_INSTANCE_ADD = 'COMPOSITION_TEXT_INSTANCE_ADD';
export const COMPOSITION_TEXT_INSTANCE_REMOVE = 'COMPOSITION_TEXT_INSTANCE_REMOVE';
export const COMPOSITION_TEXT_INSTANCE_ROTATE = 'COMPOSITION_TEXT_INSTANCE_ROTATE';
export const COMPOSITION_TEXT_MODIFY = 'COMPOSITION_TEXT_MODIFY';
export const COMPOSITION_TEXT_LAYER_ADD = 'COMPOSITION_TEXT_LAYER_ADD';
export const FORMAT_BACKGROUND_INSTANCES = 'FORMAT_BACKGROUND_INSTANCES';
export const COMPOSITION_GENERATE_REQUEST = 'COMPOSITION_GENERATE_REQUEST';
export const COMPOSITION_SET_TAG = 'COMPOSITION_SET_TAG';
export const COMPOSITION_SET_LOGO = 'COMPOSITION_SET_LOGO';
export const COMPOSITION_SET_OPTIONS = 'COMPOSITION_SET_OPTIONS';
export const COMPOSITION_REMOVE_TAG = 'COMPOSITION_REMOVE_TAG';
export const COMPOSITION_GENERATE_RECEIVE = 'COMPOSITION_GENERATE_RECEIVE';
export const COMPOSITION_GENERATE_ADD_FILTER = 'COMPOSITION_GENERATE_ADD_FILTER';
export const COMPOSITION_GENERATE_REMOVE_FILTER = 'COMPOSITION_GENERATE_REMOVE_FILTER';
export const COMPOSITION_GENERATE_EDIT_MOOD = 'COMPOSITION_GENERATE_EDIT_MOOD';
export const COMPOSITION_GENERATE_EDIT_LENGTH = 'COMPOSITION_GENERATE_EDIT_LENGTH';
export const COMPOSITION_GENERATE = 'COMPOSITION_GENERATE';
export const COMPOSITION_LOAD_DESIGN = 'COMPOSITION_LOAD_DESIGN';
export const COMPOSITION_LOAD_DESIGNS = 'COMPOSITION_LOAD_DESIGNS';
export const DESIGN_FILTER_DESIGNS = 'DESIGN_FILTER_DESIGNS';
export const COMPOSITION_LOAD_REVERSION = 'COMPOSITION_LOAD_REVERSION';
export const COMPOSITION_RECEIVE_DESIGNS = 'COMPOSITION_RECEIVE_DESIGNS';
export const COMPOSITION_PUBLISH_DESIGN = 'COMPOSITION_PUBLISH_DESIGN';
export const COMPOSITION_UNPUBLISH_DESIGN = 'COMPOSITION_UNPUBLISH_DESIGN';
export const COMPOSITION_LOAD = 'COMPOSITION_LOAD';
export const COMPOSITION_LOAD_REQUEST = 'COMPOSITION_LOAD_REQUEST';
export const COMPOSITION_LOAD_SUCCESS = 'COMPOSITION_LOAD_SUCCESS';
export const COMPOSITION_SAVE_REQUEST = 'COMPOSITION_SAVE_REQUEST';
export const COMPOSITION_SAVE_SUCCESS = 'COMPOSITION_SAVE_SUCCESS';
export const COMPOSITION_DOWNLOAD_SET = 'COMPOSITION_DOWNLOAD_SET';
export const COMPOSITION_DOWNLOAD_CLEAR = 'COMPOSITION_DOWNLOAD_CLEAR';
export const COMPOSITION_RENAME_REQUEST = 'COMPOSITION_RENAME_REQUEST';
export const COMPOSITION_RENAME_SUCCESS = 'COMPOSITION_RENAME_SUCCESS';
export const COMPOSITION_DOWNLOAD = 'COMPOSITION_DOWNLOAD';
export const COMPOSITION_LAYER_ADD = 'COMPOSITION_LAYER_ADD';
export const COMPOSITION_LAYER_MODIFY = 'COMPOSITION_LAYER_MODIFY';
export const COMPOSITION_LAYER_EDIT_ENTER = 'COMPOSITION_LAYER_EDIT_ENTER';
export const COMPOSITION_BACKGROUND_INSTANCE_MODIFY = 'COMPOSITION_BACKGROUND_INSTANCE_MODIFY';
export const COMPOSITION_LAYER_EDIT_EXIT = 'COMPOSITION_LAYER_EDIT_EXIT';
export const COMPOSITION_LAYER_REMOVE = 'COMPOSITION_LAYER_REMOVE';
export const COMPOSITION_LAYER_MOVE = 'COMPOSITION_LAYER_MOVE';
export const COMPOSITION_LAYER_TIMESLOT_CHANGE = 'COMPOSITION_LAYER_TIMESLOT_CHANGE';
export const COMPOSITION_MOVE = 'COMPOSITION_MOVE';
export const COMPOSITION_MOVE_REQUEST = 'COMPOSITION_MOVE_REQUEST';
export const COMPOSITION_MOVE_SUCCESS = 'COMPOSITION_MOVE_SUCCESS';
export const COMPOSITION_DUPLICATE_INSTANCES = 'COMPOSITION_DUPLICATE_INSTANCES';
export const COMPOSITION_LOAD_GENERATED = 'COMPOSITION_LOAD_GENERATED';
export const COMPOSITION_NOT_GENERATED = 'COMPOSITION_NOT_GENERATED';
export const COMPOSITION_TOGGLE_CLIP_AUDIO = 'COMPOSITION_TOGGLE_CLIP_AUDIO';
export const COMPOSITION_SELECT_TITLE = 'COMPOSITION_SELECT_TITLE';
export const COMPOSITION_SHAPE_LAYER_ADD = 'COMPOSITION_SHAPE_LAYER_ADD';
export const COMPOSITION_SHAPE_LAYER_REMOVE = 'COMPOSITION_SHAPE_LAYER_REMOVE';
export const COMPOSITION_SHAPE_LAYER_UPDATE = 'COMPOSITION_SHAPE_LAYER_UPDATE';

export const TIMELINE_PROGRESS_JUMP = 'TIMELINE_PROGRESS_JUMP';
export const TIMELINE_PROGRESS_SEEK = 'TIMELINE_PROGRESS_SEEK';
export const TIMELINE_TOGGLE_PLAY = 'TIMELINE_TOGGLE_PLAY';
export const TIMELINE_TOGGLE_FULLSCREEN = 'TIMELINE_TOGGLE_FULLSCREEN';
export const TIMELINE_REQUEST_PLAY = 'TIMELINE_REQUEST_PLAY';
export const TIMELINE_REQUEST_SEEK = 'TIMELINE_REQUEST_SEEK';

export const APP_TOGGLE_CHAT = 'APP_TOGGLE_CHAT';
export const APP_TOGGLE_SIDE_MENU = 'APP_TOGGLE_SIDE_MENU';
export const APP_TOGGLE_SHORTCUTS = 'APP_TOGGLE_SHORTCUTS';
export const APP_SET_AUTOSAVING = 'APP_SET_AUTOSAVING';
export const APP_SET_GUIDES = 'APP_SET_GUIDES';
export const APP_HIDE_CHAT = 'APP_HIDE_CHAT';
export const APP_TOGGLE_WELCOME_SCREEN = 'APP_TOGGLE_WELCOME_SCREEN';
export const APP_TOGGLE_USER_ACCOUNT_MANAGER = 'APP_TOGGLE_USER_ACCOUNT_MANAGER';
export const APP_TOGGLE_LIGHT_THEME = 'APP_TOGGLE_LIGHT_THEME';
export const APP_TRIGGER_NOTIFICATION = 'APP_TRIGGER_NOTIFICATION';
export const APP_DASHBOARD_TOGGLE = 'APP_DASHBOARD_TOGGLE';
export const APP_UPDATE = 'APP_UPDATE';

export const TUTORIAL_TOGGLE = 'TUTORIAL_TOGGLE';

export const SIDEBAR_TAB_TOGGLE = 'SIDEBAR_TAB_TOGGLE';
export const SIDEBAR_EXPAND_TOGGLE = 'SIDEBAR_EXPAND_TOGGLE';
export const SIDEBAR_CATEGORIES_TOGGLE = 'SIDEBAR_CATEGORIES_TOGGLE';
export const SIDEBAR_OVERLAY_TOGGLE = 'SIDEBAR_OVERLAY_TOGGLE';
export const SIDEBAR_UPLOADS_FILTER_TOGGLE = 'SIDEBAR_UPLOADS_FILTER_TOGGLE';
export const SIDEBAR_ITEM_FILTER_TOGGLE = 'SIDEBAR_ITEM_FILTER_TOGGLE';
export const SIDEBAR_PROJECT_SELECT = 'SIDEBAR_PROJECT_SELECT';
export const SIDEBAR_SHAPE_SELECT = 'SIDEBAR_SHAPE_SELECT';
export const SIDEBAR_SHAPE_DESELECT = 'SIDEBAR_SHAPE_DESELECT';
export const SIDEBAR_SHOW_ADJUSTERS = 'SIDEBAR_SHOW_ADJUSTERS';
export const EXPAND_POOL = 'EXPAND_POOL';

export const UPDATE_FOOTAGE = 'UPDATE_FOOTAGE';
export const FOOTAGE_REQUEST_IMAGES = 'FOOTAGE_REQUEST_IMAGES';
export const FOOTAGE_RECEIVE_IMAGES = 'FOOTAGE_RECEIVE_IMAGES';
export const FOOTAGE_RECEIVE_SVG = 'FOOTAGE_RECEIVE_SVG';
export const FOOTAGE_RECEIVE_UNSPLASH_IMAGES = 'FOOTAGE_RECEIVE_UNSPLASH_IMAGES';
export const FOOTAGE_RELATED_TAGS_RECEIVE = 'FOOTAGE_RELATED_TAGS_RECEIVE';
export const RELATED_TAGS_NOTFOUND = 'RELATED_TAGS_NOTFOUND';
export const FOOTAGE_REQUEST_VIDEOS = 'FOOTAGE_REQUEST_VIDEOS';
export const FOOTAGE_ADD_TO_FAVORITE = 'FOOTAGE_ADD_TO_FAVORITE';
export const FOOTAGE_REMOVE_FROM_FAVORITE = 'FOOTAGE_REMOVE_FROM_FAVORITE';
export const FOOTAGE_RECEIVE_VIDEOS = 'FOOTAGE_RECEIVE_VIDEOS';
export const FOOTAGE_SEARCH_REQUEST = 'FOOTAGE_SEARCH_REQUEST';
export const FOOTAGE_SEARCH_RECEIVE = 'FOOTAGE_SEARCH_RECEIVE';
export const FOOTAGE_INVALIDATE_IMAGES = 'FOOTAGE_INVALIDATE_IMAGES';
export const FOOTAGE_INVALIDATE_VIDEOS = 'FOOTAGE_INVALIDATE_VIDEOS';
export const FOOTAGE_TAG_SEARCH_REQUEST = 'FOOTAGE_TAG_SEARCH_REQUEST';
export const FOOTAGE_TAG_SEARCH_RECEIVE = 'FOOTAGE_TAG_SEARCH_RECEIVE';
export const FOOTAGE_TAG_SEARCH_CLEAR = 'FOOTAGE_TAG_SEARCH_CLEAR';
export const PROJECTS_REQUEST = 'PROJECTS_REQUEST';
export const PROJECTS_RECEIVE_SINGLE = 'PROJECTS_RECEIVE_SINGLE';
export const FOOTAGE_CHANGE_SORTING = 'FOOTAGE_CHANGE_SORTING';

export const PROJECTS_RECEIVE = 'PROJECTS_RECEIVE';
export const PROJECTS_INVALIDATE = 'PROJECTS_INVALIDATE';
export const PROJECTS_REQUEST_RENAME = 'PROJECTS_REQUEST_RENAME';
export const PROJECTS_RECEIVE_RENAME = 'PROJECTS_RECEIVE_RENAME';
export const PROJECTS_ADD_COMPOSITION_REQUEST = 'PROJECTS_ADD_COMPOSITION_REQUEST';
export const PROJECTS_ADD_COMPOSITION_SUCCESS = 'PROJECTS_ADD_COMPOSITION_SUCCESS';
export const PROJECTS_DUPLICATE_COMPOSITION_REQUEST = 'PROJECTS_DUPLICATE_COMPOSITION_REQUEST';
// export const PROJECTS_DUPLICATE_COMPOSITION_SUCCESS = 'PROJECTS_DUPLICATE_COMPOSITION_SUCCESS';
export const PROJECTS_REVERSION_COMPOSITION_SUCCESS = 'PROJECTS_REVERSION_COMPOSITION_SUCCESS';
export const PROJECTS_REMOVE_COMPOSITION_REQUEST = 'PROJECTS_REMOVE_COMPOSITION_REQUEST';
export const PROJECTS_REMOVE_COMPOSITION_SUCCESS = 'PROJECTS_REMOVE_COMPOSITION_SUCCESS';
export const PROJECTS_REMOVE_REQUEST = 'PROJECTS_REMOVE_REQUEST';
export const PROJECTS_REMOVE_SUCCESS = 'PROJECTS_REMOVE_SUCCESS';
export const PROJECTS_ADD = 'PROJECTS_ADD';
export const PROJECTS_ADD_RECEIVE = 'PROJECTS_ADD_RECEIVE';
export const PROJECTS_MOVE_COMPOSITION = 'PROJECTS_MOVE_COMPOSITION';
export const PROJECTS_MOVE_COMPOSITION_REQUEST = 'PROJECTS_MOVE_COMPOSITION_REQUEST';
export const PROJECTS_MOVE_COMPOSITION_SUCCESS = 'PROJECTS_MOVE_COMPOSITION_SUCCESS';
export const PROJECTS_MOVE_COMPOSITION_FAIL = 'PROJECTS_MOVE_COMPOSITION_FAIL';
export const PROJECTS_MOVE_TOGGLE_MODAL = 'PROJECTS_MOVE_TOGGLE_MODAL';

export const MUSIC_REQUEST = 'MUSIC_REQUEST';
export const MUSIC_RECEIVE = 'MUSIC_RECEIVE';
export const MUSIC_INVALIDATE = 'MUSIC_INVALIDATE';
export const MUSIC_TOGGLE_CURRENT_TRACK = 'MUSIC_TOGGLE_CURRENT_TRACK';

export const SHAPE_LAYER_APPLY_ANIMATION = 'SHAPE_LAYER_APPLY_ANIMATION';
export const SIDEBAR_TOOLBAR_ACTIVE = 'SIDEBAR_TOOLBAR_ACTIVE';

export const TEXTLAYER_MOVE = 'TEXTLAYER_MOVE';
export const TEXTLAYER_SCALE = 'TEXTLAYER_SCALE';
export const TEXTLAYER_ROTATE = 'TEXTLAYER_ROTATE';
export const TEXTLAYER_RESIZE = 'TEXTLAYER_RESIZE';
export const TEXTLAYER_SAVE_TEXTPRESET = 'COMPOSITION_SAVE_TEXTPRESET';
export const TEXTLAYER_RECEIVE_TEXTPRESETS = 'TEXTLAYER_RECEIVE_TEXTPRESETS';
export const TEXTLAYER_DELETE_TEXTPRESET = 'TEXTLAYER_DELETE_TEXTPRESET';
export const TEXTLAYER_APPLY_SELECTION_STYLES = 'TEXTLAYER_APPLY_SELECTION_STYLES';
export const TEXTLAYER_CHANGE_TEXT = 'TEXTLAYER_CHANGE_TEXT';
export const TEXTLAYER_EDIT_ENTER = 'TEXTLAYER_EDIT_ENTER';
export const TEXTLAYER_EDIT_EXIT = 'TEXTLAYER_EDIT_EXIT';
export const TEXTLAYER_APPLY_ACTIVE_STYLES = 'TEXTLAYER_APPLY_ACTIVE_STYLES';
export const TEXTLAYER_APPLY_ATTRIBUTE = 'TEXTLAYER_APPLY_ATTRIBUTE';
export const TEXTLAYER_APPLY_ATTRIBUTES = 'TEXTLAYER_APPLY_ATTRIBUTES';
export const TEXTLAYER_APPLY_ACTIVE_ATTRIBUTE = 'TEXTLAYER_APPLY_ACTIVE_ATTRIBUTE';
export const TEXTLAYER_APPLY_ACTIVE_ATTRIBUTES = 'TEXTLAYER_APPLY_ACTIVE_ATTRIBUTES';
export const TEXTLAYER_CHANGE = 'TEXTLAYER_CHANGE';
export const TEXTLAYER_APPLY_ANIMATION = 'TEXTLAYER_APPLY_ANIMATION';
export const TEXTLAYER_APPLY_ATTRIBUTES_ALL = 'TEXTLAYER_APPLY_ATTRIBUTES_ALL';
export const TEXTLAYER_REORDER_ZINDEXES = 'TEXTLAYER_REORDER_ZINDEXES';
export const TEXTLAYER_REORDER_ANIMATION_ORDER = 'TEXTLAYER_REORDER_ANIMATION_ORDER';
export const TEXTLAYER_TOGGLE_VISIBLE = 'TEXTLAYER_TOGGLE_VISIBLE';
export const TEXTLAYER_SEEK_TO_NON_ANIMATING = 'TEXTLAYER_SEEK_TO_NON_ANIMATING';
export const TEXTPRESET_SET_DRAGGING_STATUS = 'TEXTPRESET_SET_DRAGGING_STATUS';

export const SHAPELAYER_APPLY_ANIMATION = 'SHAPELAYER_APPLY_ANIMATION';
export const TITLE_SET_SEQUENCE_DELAY = 'TITLE_SET_SEQUENCE_DELAY';

export const PAYMENT_CREATE = 'PAYMENT_CREATE';
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_RECEIVE = 'PAYMENT_RECEIVE';

export const RENDER_REQUEST = 'RENDER_REQUEST';
export const RENDER_RECEIVE = 'RENDER_REVEIVE';
export const RENDER_SOCKET_PROGRESS_UPDATE = 'RENDER_SOCKET_PROGRESS_UPDATE';
export const RENDER_SOCKET_STARTED = 'RENDER_SOCKET_STARTED';
export const RENDER_INTERRUPTED = 'RENDER_INTERRUPTED';
export const RENDER_SOCKET_READY = 'RENDER_SOCKET_READY';
export const SET_PUBLISHABLE_TEMPLATE = 'SET_PUBLISHABLE_TEMPLATE';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_RECEIVE = 'USER_RECEIVE';
export const USER_RESET = 'USER_RESET';
export const USER_UPDATE_SUBSCRIPTION = 'USER_UPDATE_SUBSCRIPTION';
export const USER_INVALIDATE = 'USER_INVALIDATE';
export const USER_CANCEL_SUBSCRIPTION = 'USER_CANCEL_SUBSCRIPTION';
export const USER_CANCEL_REQUEST = 'USER_CANCEL_REQUEST';
export const USER_SET_BRANDKIT = 'USER_SET_BRANDKIT';

export const UPLOAD_SOCKET_PROGRESS_UPDATE = 'UPLOAD_SOCKET_PROGRESS_UPDATE';
export const UPLOAD_SOCKET_READY = 'UPLOAD_SOCKET_READY';
export const UPLOAD_REQUEST_NEW = 'UPLOAD_REQUEST_NEW';
export const UPLOAD_REQUEST_SUCCESS = 'UPLOAD_REQUEST_SUCCESS';
export const UPLOAD_DELETE_REQUEST = 'UPLOAD_DELETE_REQUEST';
export const UPLOAD_DELETE_IMAGE_SUCCESS = 'UPLOAD_DELETE_IMAGE_SUCCESS';
export const UPLOAD_DELETE_VIDEO_SUCCESS = 'UPLOAD_DELETE_VIDEO_SUCCESS';
export const UPLOAD_DELETE_FAIL = 'UPLOAD_DELETE_FAIL';
export const UPLOAD_DELETE_SUCCESS = 'UPLOAD_DELETE_SUCCESS';

export const ANALYTICS_PAGE_LOAD = 'ANALYTICS_PAGE_LOAD';
export const ANALYTICS_SEND_EVENT = 'ANALYTICS_SEND_EVENT';
export const BRAND_SET_FONT = 'BRAND_SET_FONT';
export const BRAND_SETTING_SAVED = 'BRAND_SETTING_SAVED';
export const BRAND_UPDATE_COLOR = 'BRAND_UPDATE_COLOR';
export const BRAND_ADD_PALLET = 'BRAND_ADD_PALLET';
export const BRAND_ADD_COLOR = 'BRAND_ADD_COLOR';
export const BRAND_REMOVE_COLOR = 'BRAND_REMOVE_COLOR';
export const BRAND_REMOVE_PALLET = 'BRAND_REMOVE_PALLET';
export const BRAND_SET_LOGO = 'BRAND_SET_LOGO';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';