import React from 'react';
import { connect } from 'react-redux';

import {
  textLayerMove,
  textLayerApplyAttribute,
  textLayerApplyAttributes,
  applyAnimation,
  applyAttributesAll,
  textLayerEditEnter,
  textLayerReorderZindexes,
  textLayerReorderAnimationOrder,
  textLayerToggleVisible,
  textLayerChange,
} from '@actions/textlayer';
import { sidebarShowAdjusters, sidebarToolbarActive } from '@actions/sidebar';
import { saveText } from '@actions/textpresets';
import { triggerNotification } from '@actions/app';
import { compositionTextInstanceRemove, compositionTextInstaceAdd, compositionTextInstanceUpdateTimestamps } from '@actions/composition';

import ToolbarText from '@components/ToolbarText';

const ToolbarTextContainer = props => <ToolbarText {...props} />;

const mapStateToProps = (state) => {
  const {
    sidebar, user, timeline, brandkit,
  } = state;

  const composition = state.composition.present;
  const { activeTextId } = sidebar;

  const checkActiveText = () => (composition.textInstances.length > 0 && composition.titleInstances
    .find(titles => titles.visibleFrom <= timeline.currentTime &&
      titles.visibleTo >= timeline.currentTime));
  const activeText = (activeTextId && (composition.textInstances && composition.textInstances
    .find(instance => instance.id === activeTextId[0]))) ||
  (checkActiveText() && checkActiveText().textInstances[0]);

  const {
    textInstances,
    titleInstances,
    width,
    height,
    uid,
  } = composition;

  return {
    brandkit,
    activeText,
    activeTextId,
    sidebar,
    textInstances,
    titleInstances,
    // animation,
    compositionWidth: width,
    compositionHeight: height,
    compositionUid: uid,
    user,
  };
};

export default connect(mapStateToProps, {
  onApplyAttribute: textLayerApplyAttribute,
  onApplyAttributes: textLayerApplyAttributes,
  onTriggerNotification: triggerNotification,
  onMove: textLayerMove,
  onApplyAnimation: applyAnimation,
  onSidebarAdjusters: sidebarShowAdjusters,
  onAddTextLayer: compositionTextInstaceAdd,
  onTextLayerEditEnter: textLayerEditEnter,
  onApplyAttributesToAll: applyAttributesAll,
  onRemove: compositionTextInstanceRemove,
  onSaveAsPreset: saveText,
  onUpdateTextLayerDepths: textLayerReorderZindexes,
  onUpdateTextLayerOrders: textLayerReorderAnimationOrder,
  onUpdateTimestamps: compositionTextInstanceUpdateTimestamps,
  onLayerToggleVisible: textLayerToggleVisible,
  textLayerChange,
  sidebarToolbarActive,
})(ToolbarTextContainer);
