import * as types from '@constants/actionTypes';

const initialState = {
  titleStyles: [
    {
      preset: {
        visibleFrom: 0,
        visibleTo: 4000,
        textInstances: [
          {
            id: 157260612648311439,
            top: 540,
            fill: 'rgba(255,255,255,1)',
            left: 986.5512249443208,
            text: 'Hello Beautiful',
            type: 'text',
            scaleX: 1,
            scaleY: 1,
            styles: {},
            duration: 4000,
            fontSize: 150,
            resizing: false,
            animation: false,
            fontStyle: 'normal',
            textAlign: 'left',
            visibleTo: 4000,
            fontFamily: 'Pacifico',
            fontWeight: 'normal',
            textShadow: {
              blur: 3,
              color: 'rgba(0,0,0,0.5)',
              preset: true,
              offsetX: 1,
              offsetY: 1,
            },
            borderColor: '{a:0,b:0,g:0,r:0}',
            borderWidth: 6,
            visibleFrom: 0,
            borderRadius: 18,
            backgroundColor: 'rgba(0,0,0,0)',
            ang: 0,
          },
        ],
      },
      id: Date.now(),
    },
  ],
  dragging: false,
};

export default function loadTemp(state = initialState, action) {
  switch (action.type) {
    case types.TEXTLAYER_RECEIVE_TEXTPRESETS: {
      const { presetCollection } = action.payload;
      const savedPresets = presetCollection.map(preset => ({
        preset: preset.content,
        id: preset.id,
      }));
      return {
        ...state,
        presetId: savedPresets.id,
        titleStyles: [...state.titleStyles, ...savedPresets],
      };
    }
    case types.TEXTLAYER_SAVE_TEXTPRESET: {
      return {
        ...state,
        titleStyles: [...state.titleStyles, { preset: action.payload.newPreset }],
      };
    }
    case types.TEXTPRESET_SET_DRAGGING_STATUS: {
      return { ...state, dragging: action.payload.status };
    }
    case types.TEXTLAYER_DELETE_TEXTPRESET: {
      const { presetId } = action.payload;
      const newTitleList = state.titleStyles.filter(preset => preset.id !== presetId);
      return { titleStyles: newTitleList };
    }

    default:
      return state;
  }
}
