import axios from 'axios';

axios.interceptors.response.use(
  response => response,
  (error) => {
    const httpError = /\b(4[0-9]{2})\b/g;

    if (error.response && httpError.test(error.response.status)) {
      // window.location.href = '/';
    }
  },
);
const createAxios = axios.create();

export default createAxios;
