import { actions as notifActions } from 'redux-notifications';

const { notifSend } = notifActions;

const notifySuccess = (text, time = 3000) => notifSend({
  message: text,
  kind: 'success',
  dismissAfter: time,
});

const notifyInfo = (text, time = 3000) => notifSend({
  message: text,
  kind: 'info',
  dismissAfter: time,
});

const notifyWarning = (text, time = 3000) => notifSend({
  message: text,
  kind: 'warning',
  dismissAfter: time,
});

const notifyDanger = (text, time = 3000) => notifSend({
  message: text,
  kind: 'danger',
  dismissAfter: time,
});

export {
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyDanger,
};

