import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';

const propTypes = {
  shortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      keys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      fn: PropTypes.func.isRequired,
    }),
  ),
};

const defaultProps = {
  shortcuts: [],
};

function KeyboardShortcuts(props) {
  const bindShortcuts = () => {
    props.shortcuts.forEach((shortcut) => {
      Mousetrap.bind(shortcut.keys, shortcut.fn);
    });
  };
  const unBindShortcuts = () => {
    props.shortcuts.forEach((shortcut) => {
      Mousetrap.unbind(shortcut.keys, shortcut.fn);
    });
  };

  useEffect(() => {
    bindShortcuts();
    return () => unBindShortcuts();
  });

  return null;
}

KeyboardShortcuts.propTypes = propTypes;
KeyboardShortcuts.defaultProps = defaultProps;

export default KeyboardShortcuts;
