import PropTypes from 'prop-types';
import './infoStyle.scss';

const BroserDetector = ({ browserInfo, notifyInfo }) => {
  if (!browserInfo) return null;
  const isChrome = browserInfo.name === 'chrome';

  if (!isChrome) {
    notifyInfo(
      ` We noticed that you are using
      ${browserInfo.name}, please consider using chrome for a
    better experience.`,
      10000,
    );
  }
  return null;
};

BroserDetector.propTypes = {
  browserInfo: PropTypes.object,
};

export default BroserDetector;
