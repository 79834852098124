import * as types from '@constants/actionTypes';
import omit from 'lodash.omit';

const initialState = {
  activeJobs: {},
};

export default function render(state = initialState, action) {
  switch (action.type) {
    case types.COMPOSITION_DOWNLOAD_SET: {
      const { composition } = action.payload;
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [composition.uid]: {
            ...composition,
            status: composition.status === 'rendering' ? null : composition.status,
          },
        },
      };
    }
    case types.COMPOSITION_DOWNLOAD_CLEAR: {
      return {
        ...state,
        activeJobs: omit(state.activeJobs, action.payload.uid),
      };
    }
    case types.RENDER_REQUEST:
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [action.payload.compositionUid]: {
            ...state.activeJobs[action.payload.compositionUid],
            uid: action.payload.compositionUid,
            isLoading: true,
          },
        },
      };
    case types.RENDER_RECEIVE:
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [action.payload.compositionUid]: {
            ...state.activeJobs[action.payload.compositionUid],
            isLoading: false,
            status: 'rendering',
            percent: 0,
          },
        },
      };
    case types.RENDER_SOCKET_STARTED:
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [action.payload.compositionUid]: {
            ...state.activeJobs[action.payload.compositionUid],
            status: 'rendering',
            percent: 0,
          },
        },
      };

    case types.RENDER_SOCKET_PROGRESS_UPDATE:
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [action.payload.compositionUid]: {
            ...state.activeJobs[action.payload.compositionUid],
            status: 'rendering',
            percent: action.payload.percent,
          },
        },
      };
    case types.RENDER_INTERRUPTED:
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [action.payload.compositionUid]: {
            ...state.activeJobs[action.payload.compositionUid],
            status: 'interrupted',
          },
        },
      };

    case types.RENDER_SOCKET_READY:
      return {
        ...state,
        activeJobs: {
          ...state.activeJobs,
          [action.payload.compositionUid]: {
            ...state.activeJobs[action.payload.compositionUid],
            status: 'rendered',
            filename: action.payload.filename,
            src: action.payload.src,
            rendered: new Date().toISOString(),
          },
        },
      };
    default:
      return state;
  }
}
