/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import { getResizeHandle } from '@helpers/newtimeline';
import './NewTimeline.scss';

const inPointTarget = {
  hover(props, monitor) {
    props.toggleTransition(false);
    props.toggleHovering(false, props.hoveringInstance);
    if (monitor.getItem().list && props.poolIndex === 1) {
      const isBlocked = (
        (props.index === 0 && props.instances[0].visibleFrom > 10) ||
        (props.backgroundTrack[props.backgroundTrack.length - 1].visibleTo === props.duration && props.backgroundTrack.length - 1 === props.index) ||
        (props.instances[props.index - 1] &&
        props.instances[props.index - 1].visibleTo <
        props.instances[props.index].visibleFrom - 3
        )
      );
      if (!isBlocked) {
        props.changeIndex(props.index, props.onIndex);
        props.onDrag(props.instance.id, 'inPoint', props.backgroundAddPosition.current);
        props.changeBackgroundAddPosition(props.backgroundAddPosition, props.index);
      }
    }
  },
};

const inPointCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
  canDrop: monitor.canDrop(),
});

const InPoint = (props) => {
  const {
    instance,
    msToPixel,
    index,
    trackIndex,
    composition,
    poolIndex,
    onVideoResize,
    onTextResize,
    onUpdateShape,
    onUpdateLayer,
    instances,
    poolType,
    backgroundTrack,
  } = props;

  const { id, visibleTo, visibleFrom } = instance;
  const [state, _setState] = useState({});
  const [handleVisible, toggleHandleVisible] = useState(false);
  const [selected, select] = useState(false);
  const stateRef = useRef(state);
  const setState = (data) => {
    stateRef.current = data;
    _setState(data);
  };

  useEffect(() => {
    if (props.instance.id === props.activeBackgroundId ||
      props.instance.id === props.activeShapeId ||
      props.instance.id === props.activeTextId ||
      props.instance.id === props.activeLayerId) {
      toggleHandleVisible(true);
      select(true);
    } else {
      toggleHandleVisible(false);
      select(false);
    }
    const widthOnTimeline = (visibleTo - visibleFrom) * msToPixel;
    setState({
      // trackHeight: document
      //   .getElementsByClassName('track-4')[0]
      //   .getBoundingClientRect().height,
      // trackY: document
      //   .getElementsByClassName('track-4')[0]
      //   .getBoundingClientRect().top,
      left: visibleFrom * msToPixel,
      widthOnTimeline,
      isResizingIn: false,
      isMoving: false,
      initialOut: null,
      initialIn: null,
      dragStart: {
        x: null,
        y: null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const mouseMove = (e) => {
    const {
      initialIn,
      initialOut,
      isResizingIn,
      dragStart,
    } = stateRef.current;
    // Check whether there are other clips on the same track
    if (isResizingIn) {
      const prevClip = instances[index - 1];
      const prevClipOut =
        prevClip ? instances[index - 1].visibleTo * msToPixel : 0;
      const maxInPoint = visibleTo - 350;
      const diffX = e.pageX - dragStart.x;
      let newLeft;
      if (initialIn + diffX > prevClipOut && initialIn + diffX < (maxInPoint * msToPixel)) {
        newLeft = initialIn + diffX;
      } else if (initialIn + diffX > (maxInPoint * msToPixel)) {
        newLeft = maxInPoint * msToPixel;
      } else {
        newLeft = (poolType === 'background' && (instance.type === 'video' || instance.type === 'image')) ? initialIn + diffX : prevClipOut;
      }
      if (poolType === 'background' && (instance.type === 'video' || instance.type === 'image')) {
        document.getElementById(props.instance.id).style.width = `${
          initialOut - newLeft
        }px`;
        backgroundTrack.forEach((clip) => {
          if (clip.visibleTo > visibleTo) {
            document.getElementById(clip.id).style.left = `${
              (clip.visibleFrom * msToPixel) - (newLeft - initialIn)
            }px`;
          }
        });
        setState({
          ...stateRef.current,
          widthOnTimeline: initialOut - newLeft,
        });
      } else {
        document.getElementById(props.instance.id).style.left = `${newLeft}px`;
        document.getElementById(props.instance.id).style.width = `${
          initialOut - newLeft
        }px`;
        setState({
          ...stateRef.current,
          widthOnTimeline: initialOut - newLeft,
          left: newLeft,
        });
      }
    }
  };

  const mouseUp = () => {
    if (document.getElementById(instance.id).getElementsByClassName('dragHandle').length > 0) {
      document.getElementById(instance.id).getElementsByClassName('instanceIcon')[0].style.opacity = 1;
      document.getElementById(instance.id).getElementsByClassName('dragHandle')[0].style.pointerEvents = 'auto';
    }
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', mouseUp);
    const { widthOnTimeline, left } = stateRef.current;
    const newOutPoint = Math.floor((left + widthOnTimeline) / msToPixel);
    const newInstance = {
      inPoint: Math.floor(left / msToPixel),
      outPoint: newOutPoint,
      id: instance.id,
      type: instance.type,
      playFrom: instance.playFrom,
      track: instance.track,
    };
    props.toggleTransition(true);
    switch (instance.type) {
      case 'video':
        onVideoResize(
          newInstance.id,
          newInstance.inPoint,
          newInstance.outPoint,
          newInstance.playFrom,
        );
        break;
      case 'text':
        onTextResize(newInstance.id, newInstance.inPoint, newInstance.outPoint);
        break;
      case 'shape':
        onUpdateShape({
          id: newInstance.id,
          visibleFrom: newInstance.inPoint,
          visibleTo: newInstance.outPoint,
          track: newInstance.track,
        });
        break;
      case 'image':
        if (poolType === 'background') {
          onVideoResize(
            newInstance.id,
            newInstance.inPoint,
            newInstance.outPoint,
            newInstance.playFrom,
          );
        } else {
          onUpdateLayer(
            {
              id: newInstance.id,
              visibleFrom: newInstance.inPoint,
              visibleTo: newInstance.outPoint,
              track: newInstance.track,
            },
            newInstance.id,
          );
        }
        break;
      case 'logo':
        if (poolType === 'background') {
          onVideoResize(
            newInstance.id,
            newInstance.inPoint,
            newInstance.outPoint,
            newInstance.playFrom,
          );
        } else {
          onUpdateLayer(
            {
              id: newInstance.id,
              visibleFrom: newInstance.inPoint,
              visibleTo: newInstance.outPoint,
              track: newInstance.track,
            },
            newInstance.id,
          );
        }
        break;
      default:
    }
  };

  const mouseDown = (e) => {
    if (document.getElementById(instance.id).getElementsByClassName('dragHandle').length > 0) {
      document.getElementById(instance.id).getElementsByClassName('instanceIcon')[0].style.opacity = 0;
      document.getElementById(instance.id).getElementsByClassName('dragHandle')[0].style.pointerEvents = 'none';
    }
    props.toggleTransition(false);
    const { widthOnTimeline, left } = stateRef.current;
    const resizeHandle = getResizeHandle(e);
    if (resizeHandle === 'left') {
      setState({
        ...stateRef.current,
        isResizingIn: true,
        initialIn: left,
        initialOut: visibleTo * msToPixel,
        dragStart: { x: e.pageX, y: e.pageY },
        initialWidth: widthOnTimeline,
      });
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
  };

  const { connectDropTarget } = props;

  return connectDropTarget(
    <div
      className="resizeHandler inPoint"
      onMouseEnter={() => toggleHandleVisible(true)}
      onMouseLeave={
        () => {
          if (!selected) {
            toggleHandleVisible(false);
          }
        }
      }
      onMouseDown={mouseDown}
      style={{ opacity: handleVisible ? 1 : 0 }} />,
  );
};

InPoint.propTypes = {
  isDragging: PropTypes.bool,
  instance: PropTypes.object,
  timelineWidth: PropTypes.number,
  msToPixel: PropTypes.number,
  index: PropTypes.number,
  instances: PropTypes.array,
  backgroundTrack: PropTypes.array,
  tracks: PropTypes.array,
  trackIndex: PropTypes.number,
  modifyComposition: PropTypes.func,
  composition: PropTypes.object,
  poolIndex: PropTypes.number,
  onUpdateLayer: PropTypes.func,
};

InPoint.defaultProps = {};

export default DropTarget(
  ['video', 'image'],
  inPointTarget,
  inPointCollect,
)(InPoint);
