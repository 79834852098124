import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Ruler.scss';

class Ruler extends React.Component {
  state = {
    isActive: false,
  }

  componentDidUpdate = (newProps) => {
    const durationHasChanged = newProps.duration !== this.props.duration;
    if (durationHasChanged) {
      this.setState({
        isActive: true,
      });
    }
  }
  render() {
    const {
      duration,
      isPlaying,
      currentTime,
      internalTime,
    } = this.props;

    const rulertimeClass = classNames('Ruler__time Ruler__time--total', {
      isActive: this.state.isActive,
    });
    if (this.state.isActive) {
      setTimeout(() => {
        this.setState({
          isActive: false,
        });
      }, 1000);
    }
    const time = isPlaying ? internalTime : currentTime;

    const currentTimeMinutes = Math.floor((time / 1000) / 60);
    const currentTimeSeconds = Math.floor((time / 1000) % 60);
    const paddedCurrentMinutes = `0${currentTimeMinutes}`.slice(-2);
    const paddedCurrentSeconds = `0${currentTimeSeconds}`.slice(-2);
    const durationMinutes = Math.floor((duration / 1000) / 60);
    const durationSeconds = Math.floor((duration / 1000) % 60);
    const paddedDurationMinutes = `0${durationMinutes}`.slice(-2);
    const paddedDurationSeconds = `0${durationSeconds}`.slice(-2);
    const displayedCurrentTime = `${paddedCurrentMinutes}:${paddedCurrentSeconds}`;
    const displayedDuration = `${paddedDurationMinutes}:${paddedDurationSeconds}`;

    return (
      <div className="Ruler">
        <div className="Ruler__division">
          <span className="Ruler__time Ruler__time--played">00s</span>
        </div>

        <div className="Ruler__division"></div>
        <div className="Ruler__division"></div>

        <div className="Ruler__division">
          <span className={rulertimeClass}>{displayedCurrentTime}/{displayedDuration}</span>
        </div>
      </div>
    );
  }
}

Ruler.defaultProps = {
  currentTime: 0,
  duration: 0,
};

Ruler.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  isPlaying: PropTypes.bool,
  internalTime: PropTypes.number,
};

export default Ruler;
