import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd'
import * as dndTypes from '@constants/dndTypes';
import classNames from 'classnames';
import './EmptyComposition.scss';

// const emptyCompositionTarget = {
//   drop(targetProps, monitor, component) {
//     const {
//       onDrop,
//       compositionUid,
//       onNotify,
//       sidebarTabToggle,
//     } = component.props;
// // TODO:  find a better place if necessary, not used anywhere anymore
//     if (!compositionUid) {
//       sidebarTabToggle('projects');
//       onNotify('Please select an active project and video first', 5000);
//       return;
//     }
//
//     const sourceProps = monitor.getItem();
//     const sourceId = sourceProps.id;
//
//     onDrop({
//       sourceId,
//       ...sourceProps,
//     });
//   },
// };


const EmptyComposition = (props) => {
  const [collectedProps, drop] = useDrop({
    accept: dndTypes.TIMELINE_EMPTY,
    drop: (draggingItem) => {
      const sourceId = draggingItem.id;
      props.onDrop({
        sourceId,
        ...draggingItem,
      });
      return {
        dropComplete: 'user drop item to empty composition',
      };
    },

    collect: monitor => ({
      isOver: monitor.isOver(),
      highlighted: monitor.canDrop(),
    }),
  });
  const { isOver, highlighted } = collectedProps;

  const classes = classNames(
    'EmptyComposition',
    {
      'EmptyComposition--hovered': isOver,
      'EmptyComposition--highlighted': highlighted,
    },
  );

  return <div ref={drop} className={classes}></div>;
};

EmptyComposition.propTypes = {
  isOver: PropTypes.bool,
  onDrop: PropTypes.func,
  highlighted: PropTypes.bool,
};

export default EmptyComposition;
