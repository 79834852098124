import * as types from '@constants/actionTypes';
import { en } from "../store/texts/en";

const initialState = {
    languages: [
        { code: "ar", name: "Arabic", pronounce: "العربية" },
        { code: "bn", name: "Bengali", pronounce: "বাংলা" },
        { code: "zh", name: "Chinese", pronounce: "中文" },
        { code: "cs", name: "Czech", pronounce: "Ceskè" },
        { code: "da", name: "Danish", pronounce: "Danske" },
        { code: "nl", name: "Dutch", pronounce: "Nederlandse" },
        { code: "et", name: "Estonian", pronounce: "Eesti" },
        { code: "en", name: "English", pronounce: "English" },
        { code: "fi", name: "Finnish", pronounce: "Suomi" },
        { code: "fr", name: "French", pronounce: "Française" },
        { code: "de", name: "German", pronounce: "Deutsch" },
        { code: "el", name: "Greek", pronounce: "ελληνικά" },
        { code: "hi", name: "Hindi", pronounce: "मानक हिन्दी" },
        { code: "hu", name: "Hungarian", pronounce: "Magyar" },
        { code: "id", name: "Indonesian", pronounce: "Indonesia" },
        { code: "it", name: "Italian", pronounce: "Italiano" },
        { code: "ja", name: "Japanese", pronounce: "日本人" },
        { code: "ko", name: "Korean", pronounce: "한국어" },
        { code: "ms", name: "Malay", pronounce: "Bahasa Melayu" },
        { code: "no", name: "Norwegian", pronounce: "Norske" },
        { code: "fa", name: "Persian", pronounce: "فارسی" },
        { code: "pl", name: "Polish", pronounce: "Polska" },
        { code: "pt", name: "Portuguese", pronounce: "Portugese" },
        { code: "ru", name: "Russian", pronounce: "русские" },
        { code: "es", name: "Spanish", pronounce: "Español" },
        { code: "sv", name: "Swedish", pronounce: "Svenska" },
        { code: "tl", name: "Tagalog", pronounce: "ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
        { code: "th", name: "Thai", pronounce: "ภาษาไทย" },
        { code: "tr", name: "Turkish", pronounce: "Türk" },
        { code: "vi", name: "Vietnamese", pronounce: "Tiếng Việt" }
    ],
    currentLanguage: "en",
    strings: en
};

export default function languages(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_MODAL:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}
