import * as types from '@constants/actionTypes';

const templateMock = [
  {
    uid: '03b728b5-ca20-42a6-96c2-4c9fb3a9d9c2',
    width: 1920,
    height: 1080,
    layers: [],
    loading: false,
    duration: 11991,
    textInstances: [
      {
        id: 1558435551646,
        top: 540,
        fill: '#4a90e2',
        left: 960,
        text: 'AWESOME\nDESIGN',
        scaleX: 1,
        scaleY: 1,
        styles: {},
        duration: 4023,
        fontSize: 300,
        resizing: false,
        animation: {
          in: [
            {
              to: 1341,
              from: 841,
              easing: 'easeInQuart',
              values: { opacity: 0 },
            },
          ],
          out: [
            {
              to: 4864,
              from: 4364,
              easing: 'easeInQuart',
              values: { opacity: 0 },
            },
          ],
          name: 'fadein',
        },
        fontStyle: 'normal',
        textAlign: 'center',
        visibleTo: 4864,
        fontFamily: 'Gochi Hand',
        fontWeight: 'bold',
        visibleFrom: 841,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      {
        id: 1558435666260,
        top: 540,
        fill: '#4a90e2',
        left: 960,
        text: 'LOOK',
        scaleX: 1,
        scaleY: 1,
        styles: {},
        duration: 917,
        fontSize: 300,
        resizing: false,
        fontStyle: 'normal',
        textAlign: 'center',
        visibleTo: 8057,
        fontFamily: 'Gochi Hand',
        fontWeight: 'bold',
        visibleFrom: 7140,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      {
        id: 1558437668896,
        top: 540,
        fill: '#4a90e2',
        left: 960,
        text: 'AT',
        scaleX: 1,
        scaleY: 1,
        styles: {},
        duration: 834,
        fontSize: 300,
        resizing: false,
        fontStyle: 'normal',
        textAlign: 'center',
        visibleTo: 8888,
        fontFamily: 'Gochi Hand',
        fontWeight: 'bold',
        visibleFrom: 8054,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      {
        id: 1558437671940,
        top: 540,
        fill: '#4a90e2',
        left: 960,
        text: 'THIS',
        scaleX: 1,
        scaleY: 1,
        styles: {},
        duration: 906,
        fontSize: 300,
        resizing: false,
        fontStyle: 'normal',
        textAlign: 'center',
        visibleTo: 9791,
        fontFamily: 'Gochi Hand',
        fontWeight: 'bold',
        visibleFrom: 8885,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      {
        id: 1558437673362,
        top: 540,
        fill: '#4a90e2',
        left: 960,
        text: 'VIEW',
        scaleX: 1,
        scaleY: 1,
        styles: {},
        duration: 1100,
        fontSize: 300,
        resizing: false,
        fontStyle: 'normal',
        textAlign: 'center',
        visibleTo: 10888,
        fontFamily: 'Gochi Hand',
        fontWeight: 'bold',
        visibleFrom: 9788,
        backgroundColor: 'rgba(0,0,0,0)',
      },
    ],
    audioInstances: [],
    backgroundInstances: [
      {
        id: 1558435543469,
        src:
          'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/399cc47c28eb491b8f42892676d25f014526c128_preview_hd.mp4',
        top: 0,
        left: 0,
        type: 'video',
        videoId: '399cc47c28eb491b8f42892676d25f014526c128',
        duration: 5913,
        playFrom: 0,
        thumbnail: {
          hd:
            'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/399cc47c28eb491b8f42892676d25f014526c128_thumb_hd.jpeg',
          nano:
            'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/399cc47c28eb491b8f42892676d25f014526c128_thumb_nano.jpeg',
          video:
            'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/399cc47c28eb491b8f42892676d25f014526c128_preview_nano.mp4',
        },
        visibleTo: 5913,
        visibleFrom: 0,
        sourceDuration: 59989,
        thumbnailImageSrc:
          'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/399cc47c28eb491b8f42892676d25f014526c128_thumb_nano.jpeg',
      },
      {
        id: 1558435546351,
        src:
          'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/d50c12d74ddf50bc6f7daef75a2745ae7cdd5850_preview_hd.mp4',
        top: 1,
        left: 0,
        type: 'video',
        angle: 0,
        width: 1920,
        height: 1080,
        scaleX: 1,
        scaleY: 1,
        videoId: 'd50c12d74ddf50bc6f7daef75a2745ae7cdd5850',
        duration: 6078,
        playFrom: 0,
        thumbnail: {
          hd:
            'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/d50c12d74ddf50bc6f7daef75a2745ae7cdd5850_thumb_hd.jpeg',
          nano:
            'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/d50c12d74ddf50bc6f7daef75a2745ae7cdd5850_thumb_nano.jpeg',
          video:
            'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/d50c12d74ddf50bc6f7daef75a2745ae7cdd5850_preview_nano.mp4',
        },
        visibleTo: 11991,
        visibleFrom: 5913,
        sourceDuration: 14389,
        thumbnailImageSrc:
          'https://s3-eu-west-1.amazonaws.com/adlaunch-media/video/d50c12d74ddf50bc6f7daef75a2745ae7cdd5850_thumb_nano.jpeg',
      },
    ],
  },
];
const initialState = {
  horizontal: templateMock,
  publishables: [],
};

export default function loadTemp(state = initialState, action) {
  switch (action.type) {
    case types.SET_PUBLISHABLE_TEMPLATE: {
      const { composition } = action.payload;
      return {
        ...state,
        publishables: [
          ...state.publishables,
          composition,
        ],
      };
    }
    case types.COMPOSITION_PUBLISH_DESIGN: {
      const { design } = action.payload;
      const { uid } = design;
      return {
        ...state,
        publishables: state.publishables.filter(template => template.uid !== uid),
      };
    }
    default:
      return state;
  }
}
