import WebFont from 'webfontloader';

const fontOptions = [
  { value: 'Open Sans', label: 'Open Sans', className: 'font-openSans' },
  { value: 'Droid Sans', label: 'Droid Sans', className: 'font-droidSans' },
  { value: 'PT Sans', label: 'PT Sans', className: 'font-ptSans' },
  { value: 'Montserrat', label: 'Montserrat', className: 'font-montserrat' },
  { value: 'Lato', label: 'Lato', className: 'font-lato' },
  { value: 'Oswald', label: 'Oswald', className: 'font-oswald' },
  { value: 'Baloo Bhai', label: 'Baloo Bhai', className: 'font-balooBhai' },
  { value: 'Pavanam', label: 'Pavanam', className: 'font-pavanam' },
  { value: 'Raleway', label: 'Raleway', className: 'font-raleway' },
  { value: 'Roboto Condensed', label: 'Roboto Condensed', className: 'font-robotoCondensed' },
  { value: 'Roboto Slab', label: 'Roboto Slab', className: 'font-robotoSlab' },
  { value: 'Josefin Slab', label: 'Josefin Slab', className: 'font-josefinSlab' },
  { value: 'Ubuntu', label: 'Ubuntu', className: 'font-ubuntu' },
  { value: 'Yatra One', label: 'Yatra One', className: 'font-yatraOne' },
  { value: 'Arimo', label: 'Arimo', className: 'font-arimo' },
  { value: 'Arvo', label: 'Arvo', className: 'font-arvo' },
  { value: 'Bitter', label: 'Bitter', className: 'font-bitter' },
  { value: 'Dosis', label: 'Dosis', className: 'font-dosis' },
  { value: 'Fjalla One', label: 'Fjalla One', className: 'font-fjallaOne' },
  { value: 'Inconsolata', label: 'Inconsolata', className: 'font-inconsolata' },
  { value: 'Indie Flower', label: 'Indie Flower', className: 'font-indiaFlower' },
  { value: 'Modak', label: 'Modak', className: 'font-modak' },
  { value: 'Muli', label: 'Muli', className: 'font-muli' },
  { value: 'PT Serif', label: 'PT Serif', className: 'font-ptSerif' },
  { value: 'Playfair Display', label: 'Playfair Display', className: 'font-playfairDisplay' },
  { value: 'Titillium Web', label: 'Titillium Web', className: 'font-titilliumWeb' },
  { value: 'Lobster', label: 'Lobster', className: 'font-lobster' },
  { value: 'Lobster Two', label: 'Lobster Two', className: 'font-lobsterTwo' },
  { value: 'Pacifico', label: 'Pacifico', className: 'font-Pacifico' },
  { value: 'Varela Round', label: 'Varela Round', className: 'font-varelaRound' },
  { value: 'Berkshire Swash', label: 'Berkshire Swash', className: 'font-berkshireSwash' },
  { value: 'Shrikhand', label: 'Shrikhand', className: 'font-shrikhand' },
  { value: 'Permanent Marker', label: 'Permanent Marker', className: 'font-permanentMarker' },
  { value: 'Righteous', label: 'Righteous', className: 'font-righteous' },
  { value: 'Kaushan Script', label: 'Kaushan Script', className: 'font-kaushanScript' },
  { value: 'Orbitron', label: 'Orbitron', className: 'font-orbitron' },
  { value: 'Alfa Slab One', label: 'Alfa Slab One', className: 'font-alfaSlabOne' },
  { value: 'Tangerine', label: 'Tangerine', className: 'font-tangerine' },
  { value: 'Monoton', label: 'Monoton', className: 'font-monoton' },
  { value: 'Rock Salt', label: 'Rock Salt', className: 'font-rockSalt' },
  { value: 'VT323', label: 'VT323', className: 'font-VT323' },
  { value: 'Marck Script', label: 'Marck Script', className: 'font-marckScript' },
  { value: 'Homemade Apple', label: 'Homemade Apple', className: 'font-homemadeApple' },
  { value: 'Gochi Hand', label: 'Gochi Hand', className: 'font-gochiHand' },
  { value: 'Cabin Sketch', label: 'Cabin Sketch', className: 'font-cabinSketch' },
  { value: 'Special Elite', label: 'Special Elite', className: 'font-specialElite' },
  { value: 'Merienda', label: 'Merienda', className: 'font-merienda' },
  { value: 'Ceviche One', label: 'Ceviche One', className: 'font-cevicheOne' },
  { value: 'Fredericka the Great', label: 'Fredericka the Great', className: 'font-frederickaTheGreat' },
  { value: 'Nixie One', label: 'Nixie One', className: 'font-nixieOne' },
  { value: 'Love Ya Like A Sister', label: 'Love Ya Like A Sister', className: 'font-loveYaLikeASister' },
  { value: 'Bungee', label: 'Bungee', className: 'font-bungee' },
  { value: 'Bungee Shade', label: 'Bungee Shade', className: 'font-bungeeShade' },
  { value: 'Lemon', label: 'Lemon', className: 'font-lemon' },
  { value: 'Kranky', label: 'Kranky', className: 'font-kranky' },
  { value: 'Londrina Outline', label: 'Londrina Outline', className: 'font-londrinaOutline' },
  { value: 'Frijole', label: 'Frijole', className: 'font-frijole' },
  { value: 'Lily Script One', label: 'Lily Script One', className: 'font-lilyScriptOne' },
  { value: 'Emilys Candy', label: 'Emilys Candy', className: 'font-emilysCandy' },
  { value: 'Codystar', label: 'Codystar', className: 'font-codystar' },
  { value: 'Rye', label: 'Rye', className: 'font-rye' },
  { value: 'Sancreek', label: 'Sancreek', className: 'font-sancreek' },
  { value: 'Ranchers', label: 'Ranchers', className: 'font-ranchers' },
  { value: 'Faster One', label: 'Faster One', className: 'font-fasterOne' },
  { value: 'League Script', label: 'League Script', className: 'font-leagueScript' },
  { value: 'Plaster', label: 'Plaster', className: 'font-plaster' },
  { value: 'Monofett', label: 'Monofett', className: 'font-monofett' },
  { value: 'Akronim', label: 'Akronim', className: 'font-akronim' },
  { value: 'Ribeye Marrow', label: 'Ribeye Marrow', className: 'font-ribeyeMarrow' },
  { value: 'Jacques Francois Shadow', label: 'Jacques Francois Shadow', className: 'font-jacquesFrancoisShadow' },
  { value: 'Quicksand', label: 'Quicksand', className: 'font-quicksand' },
  { value: 'Quattrocento', label: 'Quattrocento', className: 'font-quattrocento' },
  { value: 'Merriweather', label: 'Merriweather', className: 'font-merriweather' },
  { value: 'Crimson Text', label: 'Crimson Text', className: 'font-crimsonText' },
  { value: 'Courgette', label: 'Courgette', className: 'font-courgette' },
  { value: 'Oleo Script', label: 'Oleo Script', className: 'font-oleoScript' },
  { value: 'Candal', label: 'Candal', className: 'font-candal' },
  { value: 'Covered By Your Grace', label: 'Covered By Your Grace', className: 'font-coveredByYourGrace' },
  { value: 'Galada', label: 'Galada', className: 'font-galada' },
  { value: 'Press Start 2P', label: 'Press Start 2P', className: 'font-pressStart2P' },
  { value: 'Damion', label: 'Damion', className: 'font-damion' },
  { value: 'Parisienne', label: 'Parisienne', className: 'font-parisienne' },
  { value: 'Sigmar One', label: 'Sigmar One', className: 'font-sigmarOne' },
  { value: 'Fugaz One', label: 'Fugaz One', className: 'font-fugazOne' },
  { value: 'Armata', label: 'Armata', className: 'font-armata' },
  { value: 'Chivo', label: 'Chivo', className: 'font-chivo' },
  { value: 'DM Serif Text', label: 'DM Serif Text', className: 'font-dmSerifText' },
  { value: 'Mansalva', label: 'Mansalva', className: 'font-mansalva' },
  { value: 'Bangers', label: 'Bangers', className: 'font-bangers' },
  { value: 'Ultra', label: 'Ultra', className: 'font-ultra' },
  { value: 'Paytone One', label: 'Paytone One', className: 'font-paytoneOne' },
  { value: 'Srisakdi', label: 'Srisakdi', className: 'font-srisakdi' },
  { value: 'Russo One', label: 'Russo One', className: 'font-russoOne' },
  { value: 'Viga', label: 'Viga', className: 'font-viga' },
  { value: 'Passion One', label: 'Passion One', className: 'font-passionOne' },
  { value: 'Lalezar', label: 'Lalezar', className: 'font-lalezar' },
  { value: 'Luckiest Guy', label: 'Luckiest Guy', className: 'font-luckiestGuy' },
  { value: 'Fredoka One', label: 'Fredoka One', className: 'font-fredokaOne' },
  { value: 'Satisfy', label: 'Satisfy', className: 'font-satisfy' },
  { value: 'Amatic SC', label: 'Amatic SC', className: 'font-amaticSC' },
  { value: 'Fira Sans Condensed', label: 'Fira Sans Condensed', className: 'font-firaSansCondensed' },
  { value: 'Fascinate Inline', label: 'Fascinate Inline', className: 'font-fascinateInline' },
  { value: 'Anton', label: 'Anton', className: 'font-anton' },
  { value: 'Staatliches', label: 'Staatliches', className: 'font-staatliches' },
  { value: 'Big Shoulders Text', label: 'Big Shoulders Text', className: 'font-bigShouldersText' },
];

const getFontOptions = (brandFonts) => {
  const groupedFonts = [];
  const fonts = [];
  fontOptions.forEach((font) => {
    if (font.value === brandFonts.primary) {
      groupedFonts[0] = {
        label: `Heading - ${brandFonts.primary}`,
        value: font.value,
      };
    } else if (font.value === brandFonts.secondary) {
      groupedFonts[1] = {
        label: `Sub Heading - ${brandFonts.secondary}`,
        value: font.value,
      };
    } else if (font.value === brandFonts.tertiary) {
      groupedFonts[2] = {
        label: `Body - ${brandFonts.tertiary}`,
        value: font.value,
      };
    } else {
      fonts.push(font);
    }
  });
  return groupedFonts.concat(fonts);
};

const fontSizeAndOptionStyles = (brandFonts, option) => ({
  control: provided => ({
    ...provided,
    color: 'var(--color2)',
    backgroundColor: "var(--color4)",
    fontSize: '12px',
    boxShadow: 'none',
    height: 32,
    minHeight: "none",
    border: "none",
    ':hover': {
      backgroundColor: "var(--color3)"
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px 8px',
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "var(--color5)",
    boxShadow: "none",
  }),
  menuList: styles => ({
    ...styles,
    color: "var(--color2)",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "var(--color1)" : styles[":isSelected"],
    ':hover': {
      backgroundColor: "var(--color4)"
    },
  }),
  placeholder: styles => ({
    ...styles,
    color: 'var(--color2)',
  }
  ),
  singleValue: provided => ({
    fontFamily: option,
    ...provided,
    fontSize: '14px',
    color: 'var(--color2)',


  }),

  input: (provided, props) => {
    const fontFamily = props.value;
    return {
      ...provided,
      fontFamily,
      color: 'var(--color2)',
    };
  },


});

const fontSizeOptions = [
  { value: 48, label: '48' },
  { value: 60, label: '60' },
  { value: 72, label: '70' },
  { value: 96, label: '96' },
  { value: 110, label: '110' },
  { value: 150, label: '150' },
  { value: 200, label: '200' },
  { value: 250, label: '250' },
  { value: 300, label: '300' },
  { value: 350, label: '350' },
  { value: 400, label: '400' },
];

const colorStyles = {
  control: styles => ({
    ...styles,
    color: '#111',
    fontSize: '12px',
    maxWidth: '4.5rem',
    boxShadow: 'none',
  }),

  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  option: styles => ({
    ...styles,
    color: '#111',
    fontSize: '12px',
  }),
  menu: styles => ({
    ...styles,
    maxWidth: '4.5rem',
  }),

  placeholder: styles => ({
    ...styles,
    color: '#111',
  }),
};

const getFonts = fontArray => fontArray.map(fontObject => fontObject.value);

const loadWebFonts = () => {
  const families = getFonts(fontOptions);

  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families,
      },
      timeout: 10000,
      // loading: () => console.log('All Fonts loading'),
      active: () => {
        resolve(true);
      },
      inactive: () => reject(),
      // fontloading: (familyName, fvd) => console.log('Font loading', familyName, fvd),
      // fontactive: (familyName, fvd) => console.log('Font active', familyName, fvd),
      // fontinactive: (familyName, fvd) => console.log('Font inactive', familyName, fvd),
    });
  });
};

export {
  loadWebFonts,
  fontOptions,
  fontSizeAndOptionStyles,
  fontSizeOptions,
  getFontOptions,
  colorStyles,
};
