import React from 'react';
import PropTypes from 'prop-types';
import { Dots } from '@components/Icons';

import VideoActionsMenu from './VideoActionsMenu';

import './VideoActions.scss';

const propTypes = {
  onClickReversion: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickMove: PropTypes.func,
  onClickRename: PropTypes.func,
  active: PropTypes.bool,
};

const defaultProps = {};

class VideoActions extends React.Component {
  state = {
    open: false,
  }

  toggleVisibility = () => {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { open } = this.state;
    const {
      onClickDelete,
      onClickReversion,
      onClickMove,
      onClickRename,
    } = this.props;

    return (
      <div
        className='VideoActions'
        onClick={this.toggleVisibility}
      >
        <Dots/>
        { open &&
            <VideoActionsMenu
              onClickOutside={this.toggleVisibility}
              onClickReversion={onClickReversion}
              onClickDelete={onClickDelete}
              onClickMove={onClickMove}
              onClickRename={onClickRename}
              active={this.props.active}
            />
        }
      </div>
    );
  }
}

VideoActions.propTypes = propTypes;
VideoActions.defaultProps = defaultProps;

export default VideoActions;
