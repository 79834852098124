module.export = {
  /**
   *  Names of tabs displayed in the sidebar.
   */
  tabs: {
    projects: 'projects',
    videos: 'videos',
    images: 'images',
    shapes: 'shapes',
    text: 'text',
    music: 'music',
    generate: 'generate',
    template: 'template',
    uploads: 'uploads',
  },
  /**
   * Situational overlay context menus that open on top of
   * sidebar (user selects text or a clip from timeline).
   */
  contextOverlays: {
    text: 'text',
    videoInstance: 'videoInstance',
    layer: 'layer',
    shape: 'shape',
  },
  /**
   * Filter buttons displayed in the sidebar in footage
   * tabs (videos, images).
   */
  filterButtons: [
    {
      label: 'My Videos',
      value: 'upload',
    },
    {
      label: 'Stock',
      value: 'stock',
    },
    {
      label: 'Favorites',
      value: 'favorite',
    },
  ],
  imagesFilterButtons: [
    {
      label: 'My Images',
      value: 'upload',
    },
    {
      label: 'Stock',
      value: 'stock',
    },
    {
      label: 'Favorites',
      value: 'favorite',
    },
  ],

  uploadsFilterButtons: [
    // {
    //   label: 'All',
    //   value: 'all',
    // },
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'Image',
      value: 'image',
    },
    {
      label: 'Logo',
      value: 'logo',
    },
    // {
    //   label: 'Music',
    //   value: 'music',
    // },
  ],

  // button used to filter templates
  templateButtons: [
    {
      label: '4:5',
      value: 'facebook_vertical',
    },
    {
      label: 'Horizontal',
      value: 'horizontal',
    },
    {
      label: 'Vertical',
      value: 'vertical',
    },
    {
      label: 'Square',
      value: 'square',
    },
    {
      label: 'All',
      value: 'all',
    },
  ],
  shapeButtons: [
    {
      label: 'Shapes',
      value: 'shapes',
    },
    {
      label: 'Emojis',
      value: 'emojis',
    },
    /* {
      label: 'Stickers',
      value: 'stickers',
    },
    {
      label: 'Favorites',
      value: 'favorite',
    }, */
  ],

  // if commented out, it means it was empty
  footageCategories: [
    // 'Abstract',
    'Animals/Wildlife',
    'Backgrounds/Textures',
    // 'Beauty/Fashion',
    'Buildings/Landmarks',
    'Business/Finance',
    // 'Celebrities',
    // 'Editorial',
    'Education',
    'Food and Drink',
    // 'Healthcare/Medical',
    'Holidays',
    // 'Illustrations/Clip-Art',
    'Industrial',
    // 'Interiors',
    // 'Miscellaneous',
    'Nature',
    'Objects',
    // 'Parks/Outdoor',
    'People',
    'Religion',
    'Science',
    'Signs/Symbols',
    'Sports/Recreation',
    'Technology',
    // 'The Arts',
    'Transportation',
    // 'Vectors',
    // 'Vintage',
  ],
  imageUploadOptions: {
    fromSources: [
      'local_file_system',
      'url',
      'imagesearch',
      'googledrive',
      'dropbox',
      'onedrive',
      'box',
      'facebook',
      'instagram',
      'picasa',
    ],
    transformations: {
      crop: {
        force: false,
        aspectRatio: 1.778,
      },
      rotate: true,
    },
    accept: ['image/png', 'image/jpeg'],
    maxFiles: 10,
    maxSize: 262144000, // 250Mb (250 * 1024 * 1024)
    storeTo: {
      path: 'images/',
    },
  },
};
