/* eslint-disable no-unused-vars */
import { Button } from "@material-ui/core";
import { Modal } from '@sumo-apps/core';
import Axios from "axios";
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toggleModal } from '../../actions/modals';
import VideoPoller from "../GenerateTab/VideoPoller";
import VideoPreview from "../../actions/VideoPreview";
import AdsComponent from "../UI/AdsComponent";

const VIDEOEDITOR_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://video.dev.sumo.app";
const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://video-engine.sumo.app";

const cloudProps = {
    fileName: "Test"
}
const user = null;

function ExportVideo({
    onToggleModal,
    composition,
    strings,
    languages
}) {
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [exportedVideo, setExportedVideo] = useState(null);
    const timeourRef = useRef();
    function closeModal() {
        onToggleModal({
            exportVideo: false
        })
    }
    async function generate() {
        try {
            setLoading(true)
            setMessage("Preparing export...");
            const response = await Axios.post(`${API_URL}/api/puppeteer`, {
                composition: composition.present,
            });
            const S3Key = response.data.S3Key;
            console.log({ response }, response.data, response.data.S3Key)
            window.localStorage.setItem("Sumovideo_last_export", S3Key)
            timeourRef.current = setInterval(() => {
                Axios.post(`${API_URL}/api/poller?S3Key=${S3Key}`).then((res) => {
                    if (!res) {
                        return
                    }
                    if (res.status !== 200) {
                        setMessage("Exporting video...")
                    } else {
                        setExportedVideo(res.data.signedUrl)
                        setMessage("Video export complete!")
                        window.localStorage.removeItem("Sumovideo_last_export")
                        clearInterval(timeourRef.current)
                        setLoading(false)
                    }
                }).catch((err) => {
                    setLoading(false)
                    console.log({ err })
                })
            }, 5000)
        } catch (err) {
            console.log({ err })
        }
    }
    console.log({ exportedVideo })
    return (
        <Modal
            appName="paint"
            title={strings._export}
            style={{ minWidth: 240 }}
            buttons={!loading && !exportedVideo ? [
                { action: closeModal, text: "Cancel" },
                { action: generate, text: "Export" },
            ] : null}
            closeFunc={closeModal}>
            {
                message ?
                    <p>{message}</p>
                    :
                    <p>Do you want to export the video?</p>
            }
            {
                loading && (
                    <div className="video-export-ads flex-center flex-col">
                        <AdsComponent dataAdSlot={"8704675230"} />
                        <p>Watch ads to gain credits or leave the rendering in the background and continue working.</p>
                        <button className='sumo-btn sumo-btn-video sumo-btn-colored' onClick={closeModal}>
                            Leave in the background
                        </button>
                    </div>
                )
            }
            {
                exportedVideo && (
                    <div className="flex-center flex-col video-export-result">
                        <VideoPreview videoUrl={exportedVideo} />
                        <br />
                        <a href={exportedVideo}>Link to video</a>
                    </div>
                )
            }
        </Modal>
    )
}

const mapStateToProps = ({ modals, composition, languages }) => {
    return {
        modals,
        composition,
        strings: languages.strings,
        languages: languages.languages
    };
};
export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
})(ExportVideo);
