import React from 'react';
import PropTypes from 'prop-types';
import Snap from 'snapsvg-cjs';
import isEqual from 'lodash.isequal';
import LayerControls from './LayerControls';
import './ShapeLayer.scss';

const defaultProps = {
  shapeLibrary: [],
  // default shape layer dimentions
  width: 200,
  height: 200,
  background: 'transparent',
};

const propTypes = {
  imhere: PropTypes.string,
  id: PropTypes.number,
  src: PropTypes.string,
  type: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  ang: PropTypes.number,
  background: PropTypes.string,
  selected: PropTypes.bool,
  visible: PropTypes.bool,
  colors: PropTypes.array,
  settings: PropTypes.array,
  scaleFactor: PropTypes.number,
  canvas: PropTypes.object,
  onUpdate: PropTypes.func,
  onSelect: PropTypes.func,
  animationIn: PropTypes.bool,
  animationOut: PropTypes.bool,
  playing: PropTypes.bool,
};

class ShapeLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svgData: null,
      x: null,
      y: null,
      ang: null,
      counterVisible: false,
      width: null,
      height: null,
      isHovering: false,
      isMoving: false,
      isResizing: false,
      isRotating: false,
      dragStartX: null,
      dragStartY: null,
      offsetX: null,
      offsetY: null,
      diffX: null,
      diffY: null,
      rotateHandleAngle: null,
      centerX: null,
      centerY: null,
    };
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.resizeStart = this.resizeStart.bind(this);
    this.resizeMove = this.resizeMove.bind(this);
    this.resizeEnd = this.resizeEnd.bind(this);
    this.rotateStart = this.rotateStart.bind(this);
    this.rotateMove = this.rotateMove.bind(this);
    this.rotateEnd = this.rotateEnd.bind(this);
  }

  svgRender = (src, settings) => {
    const element = Snap(this.svgDiv);
    element.children().forEach(child => child.remove());
    Snap.load(src, (data) => {
      if (element) {
        if (settings) {
          data.selectAll('*').items.forEach((child, index) => {
            const appliedSetting = settings.find(item => item.index === index);
            if (appliedSetting) {
              child.attr(appliedSetting.attr);
            }
          });
        }
        element.append(data);
        const { vb } = element.getBBox();
        element.attr({ viewBox: vb });
      }
      return data;
    });
    return element;
  }

  componentDidMount() {
    const {
      src,
      left,
      top,
      settings,
      width,
      height,
      ang,
    } = this.props;
    this.svgRender(src, settings);
    this.setState({
      x: left,
      y: top,
      ang,
      width,
      height,
      scale: window.innerWidth / 1920,
      centerX: left + (width / 2),
      centerY: top + (height / 2),
      rotateHandleAngle: ang,
    });
    // const canvas = document.getElementsByClassName('upper-canvas')[0].getBoundingClientRect();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ang !== this.props.ang) {
      this.setState({ ang: this.props.ang });
    }
    const { settings, src } = this.props;
    if (!isEqual(prevProps.settings, settings)) {
      this.svgRender(src, settings);
    }
  }

  mouseDown(e) {
    if (e.target.classList.contains('resizer-br') ||
      e.target.classList.contains('resizer-tr') ||
      e.target.classList.contains('resizer-bl') ||
      e.target.classList.contains('resizer-tr') ||
      e.target.classList.contains('rotateHandle')
    ) {
      this.setState({
        x: this.props.left,
        y: this.props.top,
      });
      return;
    }
    // const { shiftKey } = e;

    this.setState({
      isPressing: true,
      isMoving: false,
      x: this.props.left,
      y: this.props.top,
      dragStartX: e.pageX,
      dragStartY: e.pageY,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    });
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
  }

  mouseMove(e) {
    const {
      isPressing,
      isMoving,
      dragStartX,
      dragStartY,
      offsetX,
      offsetY,
      width,
      height,
    } = this.state;
    const { canvas, scaleFactor } = this.props;

    const diffX = e.pageX - dragStartX;
    const diffY = e.pageY - dragStartY;

    if (isPressing && (diffX !== 0 || diffY !== 0)) {
      this.setState({ isMoving: true, isPressing: false });
    }
    const left = ((e.pageX - canvas.left) / scaleFactor) - offsetX;
    const top = ((e.pageY - canvas.top) / scaleFactor) - offsetY;
    if (isMoving) {
      this.setState({
        x: left,
        y: top,
        centerX: left + (width / 2),
        centerY: top + (height / 2),
        diffX,
        diffY,
      });
    }
  }

  mouseUp(e) {
    const { shiftKey } = e;
    const { id, onUpdate, onSelect } = this.props;
    const {
      x,
      y,
      isMoving,
    } = this.state;
    if (isMoving) {
      onUpdate({
        id,
        left: Math.floor(x),
        top: Math.floor(y),
      });
    }
    onSelect(id, shiftKey);
    this.resetState();
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
  }

  resizeStart(e) {
    this.setState({
      isResizing: true,
      dragStartX: e.pageX,
      dragStartY: e.pageY,
    });
    window.addEventListener('mousemove', this.resizeMove);
    window.addEventListener('mouseup', this.resizeEnd);
  }

  rotateStart(e) {
    this.setState({
      counterVisible: true,
      isResizing: false,
      isRotating: true,
      dragStartX: e.pageX,
      dragStartY: e.pageY,
    });
    window.addEventListener('mousemove', this.rotateMove);
    window.addEventListener('mouseup', this.rotateEnd);
  }

  resizeMove(e) {
    const {
      isResizing,
      dragStartX,
      dragStartY,
    } = this.state;
    const { width, height, scaleFactor, left, top, canvas } = this.props;
    if (isResizing) {
      // const diffX = (e.pageX - dragStartX) / scaleFactor;
      // const diffY = (e.pageY - dragStartY) / scaleFactor;
      // const newSize = width / (width + diffX);


      const shapeCenterX = Math.floor(canvas.left + ((left + (width / 2)) * scaleFactor));
      const shapeCenterY = Math.floor(canvas.top + ((top + (height / 2)) * scaleFactor));

      if (isResizing) {
        const diffXStart = ((dragStartX - shapeCenterX));
        const diffYStart = ((dragStartY - shapeCenterY));
        const diffX = ((e.pageX - shapeCenterX));
        const diffY = ((e.pageY - shapeCenterY));
        const pythagorasEnd = Math.sqrt((diffY ** 2) + (diffX ** 2));
        const pythagorasStart = Math.sqrt((diffXStart ** 2) + (diffYStart ** 2));
        const newScale = (pythagorasEnd / pythagorasStart);
        const offSetX = width - (newScale * width);
        this.setState({
          diffX,
          diffY,
          width: width * newScale,
          height: height * newScale,
          x: left + (offSetX / 2),
          y: top + (offSetX / 2),
        });
      }
    }
  }

  rotateMove(e) {
    const {
      isRotating,
      centerX,
      centerY,
      height,
    } = this.state;
    const { scaleFactor } = this.props;
    if (isRotating) {
      const canvasCoords = document.getElementsByClassName('upper-canvas')[0].getBoundingClientRect();
      const shapeCenterX = canvasCoords.x + (centerX * scaleFactor);
      const shapeCenterY = canvasCoords.y + (centerY * scaleFactor);
      // Calculate the vectors from center to straight down and e.page coordinates
      const vectorS = { x: 0, y: (height * scaleFactor) / 2 };
      const vectorE = { x: (e.pageX - shapeCenterX), y: (e.pageY - shapeCenterY) };
      const dotProduct = ((vectorS.x * vectorE.x) + (vectorS.y * vectorE.y));
      const crossProduct = ((vectorS.x * vectorE.y) + (vectorS.y * vectorE.x));
      const radian = Math.atan2(Math.abs(crossProduct), dotProduct) / Math.PI;
      const multiplier = e.pageX > shapeCenterX ? -1 : 1;
      let angle = radian * (180 * multiplier);
      // SNAP logic
      
      if (Math.round(angle) % 45 === 0) { angle = Math.round(angle); }
      this.setState({
        ang: angle,
        rotateHandleAngle: angle * -1,
      });
    }
  }

  resizeEnd() {
    const { id, onUpdate } = this.props;
    const {
      x,
      y,
      width,
      height,
    } = this.state;
    this.setState({
      isResizing: false,
      isMoving: false,
      isPressing: false,
      centerX: Math.floor(x) + (width / 2),
      centerY: Math.floor(y) + (height / 2),
    });
    if (onUpdate) {
      onUpdate({
        id,
        left: Math.floor(x),
        top: Math.floor(y),
        width: Math.floor(width),
        height: Math.floor(height),
      });
    }
    this.resetState();
    window.removeEventListener('mousemove', this.resizeMove);
    window.removeEventListener('mouseup', this.resizeEnd);
  }

  rotateEnd() {
    const { id, onUpdate } = this.props;
    this.setState({
      isRotating: false,
      isResizing: false,
      isMoving: false,
      isPressing: false,
      isVisible: false,
      counterVisible: false,
    });
    if (onUpdate) {
      onUpdate({
        id,
        ang: this.state.ang,
      });
    }
    this.resetState();
    window.removeEventListener('mousemove', this.rotateMove);
    window.removeEventListener('mouseup', this.rotateEnd);
  }

  resetState() {
    this.setState({
      isMoving: false,
      isPressing: false,
      isResizing: false,
      dragStartX: null,
      dragStartY: null,
      offsetX: null,
      offsetY: null,
      diffX: null,
      diffY: null,
    });
  }

  mouseEnter() {
    this.setState({ isHovering: true });
  }

  mouseLeave() {
    this.setState({ isHovering: false });
  }

  render() {
    const {
      // svgData,
      x,
      y,
      ang,
      isHovering,
      isMoving,
      isResizing,
      isRotating,
    } = this.state;
    const {
      id,
      width,
      height,
      left,
      top,
      // colors,
      selected,
      visible,
      // background,
      playing,
      // scaleFactor,
      animationIn,
      // animationOut,
      // from,
      // to,
      // deg,
      svg,
    } = this.props;

    return (
      <div
        className={`shapeLayer shapeLayer-${id}`}
        onMouseEnter={this.mouseEnter.bind(this)}
        onMouseLeave={this.mouseLeave.bind(this)}
        onMouseDown={this.mouseDown.bind(this)}
        style={{
          position: 'absolute',
          pointerEvents: visible ? 'all' : 'none',
          cursor: 'move',
          width: isResizing ? `${this.state.width}px` : `${width}px`,
          height: isResizing ? `${this.state.height}px` : `${height}px`,
          left: isMoving || isResizing ? `${x}px` : `${left}px`,
          top: isMoving || isResizing ? `${y}px` : `${top}px`,
          transform: `rotate(${ang}deg)`,
          opacity: animationIn ? '0' : '1',
        }}
      >
        <svg
          ref={(node) => { this.svgDiv = node; }}
          style={{
            width: '100%',
            height: '100%',
          }} />
        <LayerControls
          isResizing={isResizing}
          isRotating={isRotating}
          isMoving={isMoving}
          visible={!playing && (isHovering || selected)}
          selected={!playing && selected}
          onResizeStart={this.resizeStart.bind(this)}
          onRotateStart={this.rotateStart.bind(this)}
          rotateHandleRight={this.state.width / -2}
          angle={ang}
          counterVisible={this.state.counterVisible}
        />
      </div>
    );
  }
}

ShapeLayer.propTypes = propTypes;
ShapeLayer.defaultProps = defaultProps;

export default ShapeLayer;
