import { connect, useDispatch } from "react-redux";
import { updateApp } from "../../actions/app";
import { timelineProgressJump } from '@actions/timeline';

function NavbarElements({
    handleNewComposition,
    composition,
    updateApp
}) {
    const isLeoblocks = new URLSearchParams(window.location.search).get("mode") === "leoblocks";
    const dispatch = useDispatch();
    const elements = [
        {
            text: "New composition",
            func: async () => {
                dispatch(timelineProgressJump(0))
                handleNewComposition()
            }
        },
        {
            text: "Save work",
            func: () => {
                console.log(composition)
                window.post.message("work-data", composition.present)
            }
        },
        {
            text: "Play video",
            func: () => {
                console.log(updateApp)
                // append query param render=1 to current url without refreshing the page.
                updateApp({
                    isRendering: true
                })
            }
        },
    ].filter((item, index) => (
        index === 1 ? isLeoblocks : true
    ))
    return (
        <div className="shortcuts-inner-wrapper">
            {
                elements.map((shortcut, index) => (
                    <button key={`shortcut-${index}`} onClick={shortcut.func} className="single-shortcut">
                        {shortcut.text}
                    </button>
                ))
            }
            {
                isLeoblocks && (
                    <div>

                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = ({ composition }) => {
    return {
        composition
    }
}
export default connect(mapStateToProps, { updateApp })(NavbarElements)