const shouldStartAutomaticRender = (composition) => {
  // Composition has not been rendered yet
  if (composition.status === null && !composition.rendered) return true;

  // Has been rendered, check if re-rendering is required
  if (composition.status === 'rendered') {
    const renderStamp = new Date(composition.rendered).getTime();
    const modifiedStamp = new Date(composition.modified).getTime();

    // Composition has been edited after previous render
    if (renderStamp < modifiedStamp) {
      return true;
    }
  }

  return false;
};

const getNewInstanceTimeslot = (textInstances, instanceDuration) => {
  const textVisibleToArray = textInstances.map(text => text.visibleTo);
  const maxVisibleTo = Math.max(0, ...textVisibleToArray) + 1;

  return {
    visibleFrom: maxVisibleTo,
    visibleTo: maxVisibleTo + instanceDuration,
    duration: instanceDuration,
  };
};

const updateTitleInstances = (textInstances) => {
  const newTitleInstances = [];
  // group text instances with same from/to values
  textInstances.forEach((textInstance) => {
    const thisInstanceExists =
      textInstance &&
      newTitleInstances.find(title => title.visibleFrom === textInstance.visibleFrom);
    if (thisInstanceExists) {
      newTitleInstances
        .find(title => title.visibleFrom === textInstance.visibleFrom)
        .textInstances.push(textInstance);
    } else {
      newTitleInstances.push({
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        appearingDelay: textInstance.appearingDelay,
        textInstances: [textInstance],
      });
    }
  });
  return newTitleInstances;
};

const feedTextToTracks = (textInstances, titleInstances) => {
  const newTextInstances = textInstances.map((instance) => {
    let track;
    titleInstances.forEach((title) => {
      title.textInstances.forEach((text, index) => {
        if (text.id === instance.id) {
          track = index;
        }
      });
    });
    if (!track && instance.track === null) { 
      track = 0;
    } else if (!track) {
      track = instance.track;
    }
    if (track > 4) track = 4;
    return {
      ...instance,
      visibleFrom: instance.visibleFrom,
      visibleTo: instance.visibleTo,
      type: instance.type,
      track: track !== undefined ? track : 0,
      id: instance.id,
    };
  });
  return newTextInstances;
};

export {
  shouldStartAutomaticRender,
  getNewInstanceTimeslot,
  updateTitleInstances,
  feedTextToTracks,
};
