import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchMusic, toggleCurrentTrack } from '@actions/music';
import { timelineTogglePlay } from '@actions/timeline';
import MusicTab from '@components/MusicTab';

const mapStateToProps = (state) => {
  const { music, timeline } = state;
  const composition = state.composition.present;
  const { audioInstances } = composition;
  const { playing } = timeline;

  return {
    entries: music.entries,
    audioInstances,
    compositionIsPlaying: playing,
  };
};

class MusicTabContainer extends React.Component {
  componentDidMount() {
    const {
      fetchMusic: onLoad,
    } = this.props;
    onLoad();
  }

  render() {
    return (
      <MusicTab {...this.props} />
    );
  }
}

MusicTabContainer.propTypes = {
  fetchMusic: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchMusic,
  onToggleCurrentTrack: toggleCurrentTrack,
  timelineTogglePlay,
})(MusicTabContainer);
