import React from 'react';
import { connect } from 'react-redux';

import {
  compositionVideoInstanceUnselect,
  compositionVideoInstanceRemove,
  compositionVideoInstanceResize,
  compositionBackgroundInstanceMove,
  compositionBackgroundInstanceModify,
  compositionBackgroundInstanceUpdateTimestamps,
  compositionShowVideoContext,
  duplicateInstances,
  // compositionUpdateSize,
  compositionToggleClipAudio,
} from '@actions/composition';
import { handleAddToFavorite, handleRemoveFavorite } from '@actions/footage';
import { triggerNotification } from '@actions/app';

import ToolbarBackground from '@components/ToolbarBackground';

const ToolbarVideoContainer = props => <ToolbarBackground {...props} />;

const mapStateToProps = (state) => {
  const { sidebar } = state;
  const composition = state.composition.present;
  const { activeBackgroundId } = sidebar;

  const {
    backgroundInstances,
    width,
    height,
    showContext,
  } = composition;

  const activeBackgroundInstance = backgroundInstances.find(i => i.id === activeBackgroundId);

  return {
    sidebar,
    backgroundInstances,
    activeBackgroundId,
    compositionWidth: width,
    compositionHeight: height,
    showContext,
    videoContextVisible: sidebar.activeBackgroundId !== null,
    enableClipAudio: (activeBackgroundInstance
      && activeBackgroundInstance.enableClipAudio) || false,
  };
};

export default connect(mapStateToProps, {
  onMove: compositionBackgroundInstanceMove,
  onVideoResize: compositionVideoInstanceResize,
  onClose: compositionVideoInstanceUnselect,
  onRemove: compositionVideoInstanceRemove,
  onScale: compositionBackgroundInstanceModify,
  onModify: compositionBackgroundInstanceModify,
  onUpdateTimestamps: compositionBackgroundInstanceUpdateTimestamps,
  onTriggerNotification: triggerNotification,
  onShowContext: compositionShowVideoContext,
  onDuplicate: duplicateInstances,
  onAddToFavorite: handleAddToFavorite,
  onRemoveFromFavorite: handleRemoveFavorite,
  onToggleClipAudio: compositionToggleClipAudio,
})(ToolbarVideoContainer);
