export default class AudioLayer {
  playing = false
  playPromise = null

  constructor(audioElement, { from, to, playFrom = 0 } = {}) {
    this.from = from
    this.to = to
    this.audioElement = audioElement
    this.currentTime = playFrom
    this.playFrom = playFrom
    this.volume = 0.5
  }

  // eslint-disable-next-line getter-return
  get currentTime() {
    if (this.audioElement) {
      return this.audioElement.currentTime * 1000
    }
  }

  set currentTime(value) {
    if (this.currentTime === value) {
      return
    }

    if (this.audioElement) {
    this.canPlay = new Promise(resolve => {
      const fn = () => {
        this.audioElement.removeEventListener('canplaythrough', fn)
        resolve()
      }
        this.audioElement.addEventListener('canplaythrough', fn)
    })
    this.audioElement.currentTime = value / 1000
  }

  }
  update(timestamp) {
    // If there is 2s video left, start fading out and stop updating other stuff here
    if (timestamp > this.to - 1490 && !this.fading) {
      this.fading = true
      this.fadeOut()
      return
    }

    if (!this.playing && timestamp >= this.from && timestamp <= this.to) {
      this.playPromise = this.play()
    }

    if (this.playing && timestamp > this.to) {
      this.pause()
    }
  }

  seek(timestamp) {
    if (timestamp >= this.from && timestamp <= this.to) {
      this.currentTime = (timestamp - this.from + this.playFrom)
    } else {
      this.currentTime = this.playFrom
    }
  }

  play() {
    this.playing = true
    this.audioElement.play()
    // If playback starts from the beginning, do fade in
    if (this.currentTime <= 1) {
      this.fadeIn()
    } else {
      // Make sure volume is full (in case playback had been paused while fading was happening)
      this.audioElement.volume = this.volume;
    }
  }

  async pause() {
    if (!this.playing) {
      return
    }
    this.playing = false
    await this.playPromise
    this.audioElement.pause()
  }

  fadeIn() {
    // 2s fade by increasing volume 0.05 every 100ms
    this.audioElement.volume = 0;
    const fadeAudio = setInterval(() => {
      const interval = 50;
      if (this.audioElement.volume < this.volume) {
        // Format to integer to avoid decimal calculations
        this.audioElement.volume = ((this.audioElement.volume * 1000) + interval) / 1000; 
      } else {
        this.audioElement.volume = this.volume;
        clearInterval(fadeAudio);
      }
    }, 75);
  }

  fadeOut() {
    // 2s fade by increasing volume 0.05 every 100ms
    this.audioElement.volume = this.volume;
    const fadeAudio = setInterval(() => {
      if (this.audioElement.volume > 0.05) {
        this.audioElement.volume -= 0.05;
      } else {
        this.audioElement.volume = 0;
        this.fading = false;
        clearInterval(fadeAudio);
      }
    }, 75);
  }

  ready = () => {
    return this.canPlay
  }
}
