import React from 'react';
import { connect } from 'react-redux';
// import { determineCanvasType } from '@helpers/backgroundInstance';
import PropTypes from 'prop-types';
import TextTab from '@components/TextTab';
import { compositionTextInstaceAdd } from '@actions/composition';
import { timelineProgressJump } from '@actions/timeline';
import { expandPool } from '@actions/sidebar';
import { applyAttributes } from '@actions/textlayer';
import { fetchTextPresets, setDraggingStatus, deleteTextPreset } from '@actions/textpresets';

const TextTabContainer = (props) => {
  // useEffect(() => {
  //   const { composition, textpresets } = props;
  //   const { titleStyles } = textpresets;
  //   // TODO: implement filtering using canvas type in the api
  //   const canvasType = determineCanvasType(composition.width, composition.height);
  //   if (titleStyles && titleStyles.length <= 1) {
  //     props.fetchTextPresets(canvasType);
  //   }
  //   // only refetch if composition change
  // }, [props.composition.width, props.composition.height]);
  return <TextTab {...props} />;
};

const mapStateToProps = (state) => {
  const {
    textpresets, sidebar, user, brandkit, timeline,
  } = state;
  const { expandedPool, currentTime } = timeline;
  const composition = state.composition.present;
  return {
    composition,
    textpresets,
    expandedPool,
    currentTime,
    expanded: sidebar.expanded,
    role: user.role,
    brandFont: brandkit.brandfont,
  };
};

TextTabContainer.propTypes = {
  textpresets: PropTypes.object,
  composition: PropTypes.object,
  fetchTextPresets: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    onAdd: compositionTextInstaceAdd,
    onProgressJump: timelineProgressJump,
    onSetDraggingStatus: setDraggingStatus,
    fetchTextPresets,
    deleteTextPreset,
    applyAttributes,
    expandPool,
  },
)(TextTabContainer);
