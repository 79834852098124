import { compress } from '@lib/zip';
import { Modal } from '@sumo-apps/core';
import download from 'downloadjs';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toggleModal } from '../../actions/modals';

function SaveProject({
    onToggleModal,
    composition,
    strings
}) {
    const formatRef = useRef(null)
    const nameRef = useRef(null)
    console.log({ composition })


    // const queryString = window.location.search
    // const urlParams = new URLSearchParams(queryString)
    // const targetParameter = urlParams.get('target')
    // const optParameter = urlParams.get('opt') || ''

    useEffect(() => {
        // Autofocus
        if (document.querySelector('.modal-inputs-wrapper input.sumo-input')) {
            document.querySelector('.modal-inputs-wrapper input.sumo-input').focus()
            document.querySelector('.modal-inputs-wrapper input.sumo-input').select()
            window.preventKeyboardShortcuts = true
            window.addEventListener('keydown', handleKeyboard)
        }
        // Save/Update text in the button before typing
        // handleKeyPress()
        // eslint-disable-next-line
    }, [])


    const handleKeyboard = (e) => {
        if (e.code === 'Escape') {
            closeModal()
        }
        if (e.code === 'Enter') {
            saveProject()
        }
    }
    function closeModal() {
        window.preventKeyboardShortcuts = false
        window.removeEventListener('keydown', handleKeyboard)
        onToggleModal({
            saveProject: false
        })
    }
    function validateText(e) {
        e.target.value = e.target.value.replace('.', '')
        if (!/[a-zA-ZäÄöÖåÅ0-9_-\s+*'^~¨`´<>|?!="#¤%&@£$§½\\/{}()\][]/.test(e.key)) {
            e.preventDefault()
        }
    }
    function saveProject() {
        compress({
            files: [
                {
                    name: 'data.json',
                    data: JSON.stringify(composition.present)
                }
            ]
        }).then((data) => {
            download(data, `${"test"}.sumo`, 'application/sumo')
            closeModal()
        })
    }
    return (
        <Modal
            appName="paint"
            title={strings._saveproject}
            buttons={[
                { action: closeModal, text: "Cancel" },
                { action: saveProject, text: "Save" },
            ]}
            closeFunc={closeModal}>
            <div className="modal-inputs-wrapper">
                <label>Name:</label>
                <input
                    type='text'
                    className="sumo-input"
                    name='fileName'
                    ref={nameRef}
                    onKeyPress={validateText}
                    placeholder={"Untitled"}
                    defaultValue={"Untitled"}
                />
                <br />
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ modals, composition, languages }) => {
    return {
        modals,
        composition,
        strings: languages.strings
    };
};
export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
})(SaveProject);
