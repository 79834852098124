import { combineReducers } from 'redux';
import { reducer as notifReducer } from 'redux-notifications';
import undoable from 'redux-undo';

import activeText from './activeText';
import app from './app';
import brandkit from './brandkit';
import composition from './composition';
import dashboard from './dashboard';
import designs from './designs';
import footage from './footage';
import generate from './generate';
import languages from './languages';
import modals from './modals';
import music from './music';
import plans from './plans';
import projects from './projects';
import render from './render';
import sidebar from './sidebar';
import template from './template';
import textpresets from './textpresets';
import timeline from './timeline';
import tutorial from './tutorial';
import upload from './upload';
import user from './user';

function undoEnabled(action) {
  // Enable this to debug which actions support undo/redo
  // if (action.undo) {
  //   console.log(action);
  // }
  return action.undo;
}

export default combineReducers({
  activeText: undoable(activeText, {
    limit: 10,
    filter: undoEnabled,
  }),
  app,
  composition: undoable(composition, {
    limit: 10,
    filter: undoEnabled,
  }),
  footage,
  generate,
  music,
  languages,
  modals,
  plans,
  projects,
  sidebar,
  timeline,
  tutorial,
  user,
  render,
  upload,
  notifs: notifReducer,
  designs,
  template,
  textpresets,
  brandkit,
  dashboard,
});
