import { mapTo, tap, ignoreElements } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import * as types from '@constants/actionTypes';
import { deselectActiveObject, getLayerById } from '@lib/fabricHelper';
import { setTextAttribute } from '@helpers/textLayer';

/**
 * Discards active selection on canvas. Used when calling a
 * redux action directly that needs to have an side-effect
 * on the canvas. E.g close text context tools -> deselect text
 * on canvas.
 */
const discardActive = action$ => action$.pipe(
  ofType(
    types.TEXTLAYER_EDIT_EXIT,
    types.COMPOSITION_LAYER_EDIT_EXIT,
  ),
  mapTo(() => {
    deselectActiveObject();
  }),
);

/**
 * Discard selections before certain actions.
 */
const prepareForPlay = action$ => action$.pipe(
  ofType(
    types.TIMELINE_REQUEST_PLAY,
    types.TIMELINE_REQUEST_SEEK,
  ),
  mapTo(() => {
    deselectActiveObject();
  }),
);

const syncCoordinates = action$ => action$.pipe(
  ofType(
    types.TEXTLAYER_MOVE,
  ),
  tap((action) => {
    const engineLayer = getLayerById(action.payload.itemId);
    const fabricLayer = engineLayer.getAnimatableObject();
    const {
      left,
      top,
    } = action.payload;

    fabricLayer.left = left;
    fabricLayer.top = top;

    fabricLayer.setCoords();
    engineLayer.storeState();

    if (engineLayer.split) {
      engineLayer.generate();
    }
  }),
  ignoreElements(),
);

const syncAttributes = action$ => action$.pipe(
  ofType(
    types.TEXTLAYER_APPLY_ATTRIBUTE,
  ),
  tap((action) => {
    const engineLayer = getLayerById(action.payload.itemId);
    const fabricLayer = engineLayer.getAnimatableObject();

    setTextAttribute(
      action.payload.attributeObject,
      fabricLayer,
    );
  }),
  ignoreElements(),

);

export {
  discardActive,
  prepareForPlay,
  syncCoordinates,
  syncAttributes,
};
