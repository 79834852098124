/* eslint-disable react-hooks/rules-of-hooks */
import Button from '@components/Button';
import {
  AlignCenter, AlignLeft, AlignRight, Bold, Dots, Italic, Lineheight, StopWatch, TextBox, TextIcon, Time, TrashCan
} from '@components/Icons';
import InputNumber from '@components/InputNumber';
import PositionGrid from '@components/PositionGrid';
import ToolTip from '@components/ToolTip';
import { getMaxDuration } from '@constants/composition';
import { determineCanvasType } from '@helpers/backgroundInstance';
import { getNewInstanceTimeslot } from '@helpers/composition';
import {
  fontOptions, fontSizeAndOptionStyles, fontSizeOptions, getFontOptions
} from '@lib/webFonts';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import './ToolbarText.scss';

const propTypes = {
  brandkit: PropTypes.object,
  activeTextId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  activeText: PropTypes.object,
  animation: PropTypes.string,
  sidebar: PropTypes.object,
  onTriggerNotification: PropTypes.func,
  textInstances: PropTypes.array,
  titleInstances: PropTypes.array,
  onTextLayerEditEnter: PropTypes.func,
  onApplyStyles: PropTypes.func,
  onApplyAttribute: PropTypes.func,
  onApplyAttributes: PropTypes.func, // new, takes more than one key/value
  onApplyAttributesToAll: PropTypes.func,
  onMove: PropTypes.func,
  onRemove: PropTypes.func,
  onSaveAsPreset: PropTypes.func,
  onRotate: PropTypes.func,
  onClose: PropTypes.func,
  onSidebarAdjusters: PropTypes.func,
  onAddTextLayer: PropTypes.func,
  onApplyAnimation: PropTypes.func,
  onUpdateTextLayerDepths: PropTypes.func,
  onUpdateTextLayerOrders: PropTypes.func,
  onUpdateTimestamps: PropTypes.func,
  styles: PropTypes.object,
  textAlign: PropTypes.string,
  stroke: PropTypes.string,
  shadow: PropTypes.object,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  compositionUid: PropTypes.string,
  user: PropTypes.object,
  onDuplicate: PropTypes.func,
  fill: PropTypes.string,
  onLayerToggleVisible: PropTypes.func,
  textLayerChange: PropTypes.func,
  onUpdateAppearingSequence: PropTypes.func,
  sidebarToolbarActive: PropTypes.func,
};

const ToolbarText = ({
  brandkit,
  activeText,
  activeTextId,
  sidebar,
  onTriggerNotification,
  onApplyAttribute,
  onSidebarAdjusters,
  onMove,
  onRemove,
  onAddTextLayer,
  titleInstances,
  textInstances,
  onApplyAttributes,
  onApplyAttributesToAll,
  onUpdateTimestamps,
  compositionWidth,
  compositionHeight,
  textLayerChange,
  sidebarToolbarActive,
  onSaveAsPreset,
  user,
}) => {
  if (!activeText || !activeTextId) {
    return null;
  }

  const {
    fontWeight,
    fontStyle,
    fontSize,
    fontFamily,
    textTransform,
    visibleFrom,
    visibleTo,
    lineHeight,
    scaleX,
  } = activeText;

  const initCopiedStyles = () => {
    try {
      const newStyles = JSON.parse(window.localStorage.getItem('copiedStyles'));
      if (newStyles) {
        return newStyles;
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  const [newTo, setNewTo] = useState(null);
  const [newFrom, setNewFrom] = useState(null);
  const [copiedStyles, setCopiedStyles] = useState(initCopiedStyles());
  const [pasteEnabled, togglePaste] = useState(false);
  const [dropDowns, toggleDropDowns] = useState(null);
  const [screenWidth, updateScreenWidth] = useState(window.innerWidth);
  const notifications = {
    maxExceeded: 'The length of the text has to be minimum .3 sec',
    minDuration: 'min length is .3 sec',
  };

  const toggleTransform = textTransform === 'uppercase' ? 'original' : 'uppercase';

  const isBold = fontWeight === 'bold';
  const isItalic = fontStyle === 'italic';
  const isUpperCase = textTransform === 'uppercase';
  const boldToggleValue = isBold ? 'normal' : 'bold';
  const italicToggleValue = isItalic ? 'normal' : 'italic';

  const handleResize = () => {
    updateScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  let alignIcon;
  if (activeText.textAlign === 'left') {
    alignIcon = <AlignLeft />;
  } else if (activeText.textAlign === 'center') {
    alignIcon = <AlignCenter />;
  } else if (activeText.textAlign === 'right') {
    alignIcon = <AlignRight />;
  }

  const handleAttributeChange = (attr, value) => {
    const attributeObject = { key: attr, value };
    try {
      sidebar.activeTextId.forEach(textId => onApplyAttribute(textId, attributeObject));
    } catch (e) {
      const textId = sidebar.activeTextId;
      onApplyAttribute(textId, attributeObject);
    }
  };

  const handleTextLayerMove = (position) => {
    const { left, top } = position;
    onMove(activeTextId, left, top);
  };

  const handleSavePreset = () => {
    const title = titleInstances.find(titles => titles.visibleFrom === activeText.visibleFrom);
    const preset = {
      ...title,
      canvasType: determineCanvasType(compositionWidth, compositionHeight),
    };
    onSaveAsPreset(preset, user.uid);
    // window.localStorage.setItem('myTitle1', JSON.stringify(title));
  };

  const handleDuplicate = () => {
    const title = titleInstances.find(titles =>
      titles.textInstances.find(ti => ti.id === activeText.id));
    const timeslot = getNewInstanceTimeslot(titleInstances, title.visibleTo - title.visibleFrom);
    title.textInstances.forEach((textInstance) => {
      onAddTextLayer({
        ...textInstance,
        id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
        duration: timeslot.visibleTo - timeslot.visibleFrom,
        visibleFrom: timeslot.visibleFrom,
        visibleTo: timeslot.visibleTo,
      });
    });
  };

  const handleFontFamilyChange = ({ value }) => handleAttributeChange('fontFamily', value);
  const handleFontSizeChange = ({ value }) => handleAttributeChange('fontSize', value);
  const handleFontWeightChange = value => handleAttributeChange('fontWeight', value);
  const handleFontStyleChange = value => handleAttributeChange('fontStyle', value);
  const handleLineHeightChange = value => handleAttributeChange('lineHeight', value);
  const handleAlign = alignment => handleAttributeChange('textAlign', alignment);
  const handleCaseChange = (value) => {
    if (value === 'original') {
      handleAttributeChange('textTransform', '');
      return textLayerChange(sidebar.activeTextId, activeText.initial);
    }
    textLayerChange(sidebar.activeTextId, activeText.text.toUpperCase());
    return handleAttributeChange('textTransform', value);
  };

  const multipleSelected = activeTextId.length > 1;

  const handleCopyStyles = () => {
    if (!activeText) return;
    const newStyles = {
      fill: activeText.fill,
      fontSize: activeText.fontSize,
      fontStyle: activeText.fontStyle,
      textAlign: activeText.textAlign,
      fontFamily: activeText.fontFamily,
      fontWeight: activeText.fontWeight,
      backgroundColor: activeText.backgroundColor,
      stroke: activeText.stroke,
      strokeWidth: activeText.strokeWidth,
      textShadow: activeText.textShadow,
      angle: activeText.angle,
      padding: activeText.padding,
      borderWidth: activeText.borderWidth,
      borderColor: activeText.borderColor,
      borderRadius: activeText.borderRadius,
      boxShadow: activeText.boxShadow,
    };
    setCopiedStyles(newStyles);
    window.localStorage.setItem('copiedStyles', JSON.stringify(newStyles));
    togglePaste(true);
    onTriggerNotification({
      kind: 'info', // success, info, warning, danger
      message: 'Text styles were copied',
    });
  };

  const handlePasteStyles = () => {
    if (copiedStyles && multipleSelected) {
      activeTextId.forEach(textId => onApplyAttributes(textId, copiedStyles));
    } else if (copiedStyles && !multipleSelected) {
      onApplyAttributes(activeText.id, copiedStyles);
    }
    togglePaste(false);
  };

  const handleApplyToAll = () => {
    const textId = sidebar.activeTextId;
    onApplyAttributesToAll(textId);
  };

  const handleDelete = () => {
    const title = titleInstances.find(titles => titles.visibleFrom === activeText.visibleFrom);
    if (title) {
      title.textInstances.forEach((instance) => {
        onRemove(instance.id);
      });
    } else {
      onRemove(activeText.id);
    }
  };

  const onChangeFunction = (e) => {
    if (e.target.name === 'newTo') {
      setNewTo(Number(e.target.value * 1000));
    } else {
      setNewFrom(Number(e.target.value * 1000));
    }
  };
  let maxTo = getMaxDuration();
  let minFrom = 0;
  const checkOtherInstances = (active) => {
    let nextInstance;
    let prevInstance;
    const instances = textInstances.filter(instance => instance.id !== active.id);
    if (instances) {
      instances.forEach((instance) => {
        if (instance.visibleFrom > active.visibleTo) {
          if (instance.visibleFrom < nextInstance || !nextInstance) {
            nextInstance = instance.visibleFrom;
          }
        } else if (instance.visibleTo > prevInstance || !prevInstance) {
          prevInstance = instance.visibleTo;
        }
      });
      return { nextInstance, prevInstance };
    }
    return null;
  };

  const onBlurFunction = (e) => {
    const otherInstances = checkOtherInstances(activeText);
    maxTo = otherInstances.nextInstance ? otherInstances.nextInstance : maxTo;
    minFrom = otherInstances.prevInstance ? otherInstances.prevInstance : 0;

    const title = titleInstances.find(titles => titles.visibleFrom === activeText.visibleFrom);
    if (e.target.value !== null) {
      if (e.target.name === 'newTo') {
        const checkedForMin = newTo - visibleFrom > 300;
        const checkedForMax = newTo < maxTo;
        let checkedForLimit;
        if (checkedForMin && checkedForMax) {
          checkedForLimit = newTo;
        } else if (!checkedForMin) {
          checkedForLimit = visibleFrom + 300;
        } else if (!checkedForMax) {
          checkedForLimit = maxTo;
        }
        title.textInstances.forEach((instance) => {
          onUpdateTimestamps(
            {
              visibleFrom,
              visibleTo: checkedForLimit,
              duration: checkedForLimit - visibleFrom,
            },
            instance.id,
          );
        });
      } else {
        const checkedForMin = newFrom > minFrom;
        const checkedForMax = visibleTo - newFrom > 300;
        let checkedForLimit;
        if (checkedForMin && checkedForMax) {
          checkedForLimit = newFrom;
        } else if (!checkedForMin) {
          checkedForLimit = minFrom;
        } else if (!checkedForMax) {
          checkedForLimit = visibleTo - 300;
        }
        title.textInstances.forEach((instance) => {
          onUpdateTimestamps(
            {
              visibleFrom: checkedForLimit,
              visibleTo,
              duration: visibleTo - checkedForLimit,
            },
            instance.id,
          );
        });
      }
    }
  };

  const textStylesGroup = screenWidth < 1300 ? 'ToolbarGroup Toolbar__dropDown row' : 'ToolbarGroup';
  const outerStylesDiv = screenWidth ? 'ToolbarGroup' : '';
  const handleTextClick = () => {
    const { textAlign } = activeText;
    switch (textAlign) {
      case 'left':
        handleAlign('center');
        break;
      case 'center':
        handleAlign('right');
        break;
      case 'right':
        handleAlign('left');
        break;
      default:
        break;
    }
  };

  return (
    <section className="Toolbar__Text">
      <div className="ToolbarGroup">
        <div className="ToolbarThumbnail__TypeText">
          <TextIcon />
        </div>
      </div>
      <div className="ToolbarGroup">
        <ToolTip message="Adjust text duration">
          <div className="ToolbarItem duration">
            <StopWatch />
            <InputNumber
              name="newFrom"
              onFocus={sidebarToolbarActive}
              onBlurFunction={onBlurFunction}
              onChangeFunction={onChangeFunction}
              initialInput={Number(visibleFrom / 1000).toFixed(1)}
              onTriggerNotification={onTriggerNotification}
              notifications={notifications}
              max={visibleTo - 300}
              min={minFrom}
            />
            <Time />
            <InputNumber
              name="newTo"
              onFocus={sidebarToolbarActive}
              onBlurFunction={onBlurFunction}
              onChangeFunction={onChangeFunction}
              initialInput={Number(visibleTo / 1000).toFixed(1)}
              onTriggerNotification={onTriggerNotification}
              notifications={notifications}
              max={maxTo}
              min={visibleFrom + 300}
            />
          </div>
        </ToolTip>
      </div>

      <div className="ToolbarGroup">
        <div className="ToolbarItem--dropDown">
          <CreatableSelect
            onMenuOpen={() => sidebarToolbarActive(true)}
            onMenuClose={() => sidebarToolbarActive(false)}
            formatCreateLabel={value => value}
            createOptionPosition="first"
            className="ContextSettings__FontOptions-select--toolbar"
            styles={fontSizeAndOptionStyles(brandkit.brandfont, fontFamily)}
            options={getFontOptions(brandkit.brandfont)}
            onChange={handleFontFamilyChange}
            value={fontOptions.find(opt => opt.value === fontFamily)}
          />
          <CreatableSelect
            onMenuOpen={() => sidebarToolbarActive(true)}
            onMenuClose={() => sidebarToolbarActive(false)}
            formatCreateLabel={value => value}
            createOptionPosition={'first'}
            className="ContextSettings__FontSize-select--toolbar"
            options={fontSizeOptions}
            onChange={handleFontSizeChange}
            value={Math.round(fontSize)}
            placeholder={Math.round(fontSize * scaleX)}
            styles={fontSizeAndOptionStyles(brandkit.brandfont, fontFamily)}
          />
        </div>
      </div>
      <div className="ToolbarGroup">
        <div
          className={`ToolbarItem ${dropDowns === 'animations' && 'active'}`}
          onClick={() => {
            onSidebarAdjusters('animations');
            toggleDropDowns('animations');
          }}
        >
          <Button iconBtn={true}>
            <span>Add animations</span>
          </Button>
        </div>
      </div>
      <div className={outerStylesDiv}>
        <ToolTip message="Open more setting">
          {screenWidth < 1300 && (
            <div
              className="ToolbarItem dots"
              onClick={() => {
                if (dropDowns !== 'textStyles') {
                  toggleDropDowns('textStyles');
                } else {
                  toggleDropDowns('');
                }
              }}
            >
              <span>
                <Dots />
              </span>
            </div>
          )}
        </ToolTip>
        <ToolTip message="Text appearance settings">
          {(screenWidth > 1300 || (screenWidth < 1300 && dropDowns === 'textStyles')) && (
            <div className={textStylesGroup}>
              <div
                className={`ToolbarItem ${isBold && 'active'}`}
                onClick={() => {
                  toggleDropDowns('bold');
                  handleFontWeightChange(boldToggleValue);
                }}
              >
                <Button iconBtn={true}>
                  <Bold />
                </Button>
              </div>
              <div
                className={`ToolbarItem ${isItalic && 'active'}`}
                onClick={() => {
                  toggleDropDowns('italic');
                  handleFontStyleChange(italicToggleValue);
                }}
              >
                <Button iconBtn={true}>
                  <Italic />
                </Button>
              </div>
              <div
                className={`ToolbarItem ${isUpperCase && 'active'}`}
                onClick={() => {
                  toggleDropDowns('caps');
                  handleCaseChange(toggleTransform);
                }}
              >
                <Button iconBtn={true}>
                  <span>aA</span>
                </Button>
              </div>
              <div
                className="ToolbarItem"
                onClick={() => {
                  if (dropDowns !== 'lineHeight') {
                    toggleDropDowns('lineHeight');
                  } else {
                    toggleDropDowns('');
                  }
                  onSidebarAdjusters('');
                }}
              >
                <Button iconBtn={true}>
                  <Lineheight />
                </Button>
                {dropDowns === 'lineHeight' && (
                  <div className="Toolbar__dropDown row">
                    <div className="ToolbarItem--lineHeight">
                      <span style={{ fontSize: '12px', fontWeight: '600' }}>Lineheight</span>
                      <Slider
                        defaultValue={lineHeight}
                        value={lineHeight}
                        onChange={height => handleLineHeightChange(height)}
                        min={0.3}
                        max={6}
                        step={0.1}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`ToolbarItem ${dropDowns === 'fontProps' && 'active'}`}
                onClick={() => {
                  toggleDropDowns('fontProps');
                  onSidebarAdjusters('color');
                }}
              >
                <Button>
                  <span>A</span>
                  <div
                    className="fontColor"
                    style={{
                      background: activeText.fill,
                      borderRadius: '2px',
                      width: '15px',
                      height: '6px',
                      border: '1px solid #c9c9c9',
                    }}
                  ></div>
                </Button>
              </div>
              <div
                className={`ToolbarItem ${dropDowns === 'boxProps' && 'active'}`}
                onClick={() => {
                  toggleDropDowns('boxProps');
                  onSidebarAdjusters('box');
                }}
              >
                <Button>
                  <TextBox />
                </Button>
              </div>
            </div>
          )}
        </ToolTip>
      </div>
      <section className="Toolbar__right">
        <div className="ToolbarGroup">
          <div className="ToolbarItem">
            <Button
              iconBtn={true}
              onClick={() => {
                if (dropDowns !== 'position') {
                  toggleDropDowns('position');
                } else {
                  toggleDropDowns('');
                }
                onSidebarAdjusters('');
              }}>
              Position
            </Button>
            {dropDowns === 'position' && (
              <div className="Toolbar__dropDown row">
                <div className="ToolbarItem" onClick={handleTextClick}>
                  <Button iconBtn={true}>{alignIcon}</Button>
                </div>
                <PositionGrid
                  onSelect={handleTextLayerMove}
                  activeText={activeText}
                  context={'text'}
                  compositionWidth={compositionWidth}
                  compositionHeight={compositionHeight}
                />
              </div>
            )}
          </div>
        </div>
        <div className="ToolbarGroup CopyAndDelete">
          {screenWidth > 1550 && user.role === 2 && (
            <div className="ToolbarItem" onClick={handleSavePreset}>
              <Button iconBtn={true}>Save as preset</Button>
            </div>
          )}
          {screenWidth > 1350 && !pasteEnabled && (
            <div
              className="ToolbarItem"
              onClick={() => {
                handleCopyStyles();
                togglePaste(!pasteEnabled);
              }}
            >
              <Button iconBtn={true}>Copy</Button>
            </div>
          )}
          {pasteEnabled && (
            <div className="ToolbarItem">
              <Button iconBtn={true} onClick={() => toggleDropDowns('paste')}>
                Paste
              </Button>
            </div>
          )}
          {dropDowns === 'paste' && (
            <div className="Toolbar__dropDown">
              <div
                className="ToolbarItem"
                onClick={() => {
                  handlePasteStyles();
                  toggleDropDowns('');
                }}
              >
                <Button iconBtn={true} disabled={multipleSelected}>
                  Paste to active title
                </Button>
              </div>
              <div
                className="ToolbarItem"
                onClick={() => {
                  handleApplyToAll();
                  toggleDropDowns('');
                }}
              >
                <Button iconBtn={true} disabled={multipleSelected}>
                  Apply to all titles
                </Button>
              </div>
            </div>
          )}
          {screenWidth > 1430 && (
            <div className="ToolbarItem" onClick={handleDuplicate}>
              <Button iconBtn={true}>Duplicate</Button>
            </div>
          )}
          <div className="ToolbarItem delete" onClick={() => handleDelete()}>
            <Button iconBtn={true}>
              <TrashCan />
            </Button>
          </div>
        </div>
      </section>
    </section>
  );
};

ToolbarText.propTypes = propTypes;
export default ToolbarText;
