import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConfirmDialog from '@components/ConfirmDialog';
import Button from '@components/Button';
import ProjectActions from './ProjectActions';
import './ProjectCard.scss';

const ProjectCard = ({
  project, onClick, onDelete, onRename, view,
}) => {
  const { name, uid: projectUid, thumbnail } = project;
  const [showDeleteDialog, toggleDialog] = useState(false);

  const handleClick = () => {
    onClick(projectUid);
  };

  const handleDelete = () => {
    // display dialog
    toggleDialog(true);
  };

  const cancelDelete = () => {
    // hide dialog
    toggleDialog(false);
  };
  const videosAmount = project.videos.length;

  const thumbnails = videosAmount ? project.videos.map((video, index) => {
    const aspectRatio = video.width / video.height;
    const thumbClasses = classNames('thumb', {
      'thumb--landscape': aspectRatio === 1.7777777777777777,
      'thumb--square': aspectRatio === 1,
      'thumb--vertical': aspectRatio === 0.5625,
    });
    const thumb = video.content.backgroundInstances.length > 0
      && video.content.backgroundInstances[0].thumbnail && video.content.backgroundInstances[0].thumbnail.nano;
    if (thumb) {
      return (<div key={index} className={thumbClasses} style={{ backgroundImage: `url(${thumb})` }} />);
    }
    return <div key={index} className='thumb--missing' />;
  }) : null;

  return (
    <div
      className="ProjectCard"
      style={{ backgroundImage: `url(${thumbnail})` }}
    >
      <div className="ProjectCard__content">
        <div onClick={handleClick}>
          <p className="ProjectCard__project-name">
            {name}
          </p>
          <p className="ProjectCard__versions-amount">
            {videosAmount !== 1 ? `${videosAmount} videos` : `${videosAmount} video`}
          </p>
          <div className="ProjectCard__videoThumbnails">
            {thumbnails}
          </div>
        </div>
      </div>
      {view !== 'dashboard' && <ProjectActions
        onClickDelete={handleDelete}
        onClickRename={onRename}
      />}
      <ConfirmDialog
        visible={showDeleteDialog}
        title={`Delete project ${project.name}?`}
        onClose={cancelDelete}
        cancelButton={
          <Button onClick={cancelDelete}>
            Cancel
          </Button>
        }
        confirmButton={
          <Button
            color="red"
            onClick={() => {
              onDelete(project);
            }}
          >
            Delete
          </Button>
        }
      >
        Are you sure you want to delete &quot;{project.name}&quot; ? Undoing is not possible!
      </ConfirmDialog>
    </div>
  );
};

ProjectCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  project: PropTypes.object,
  view: PropTypes.string,
};

export default ProjectCard;
