import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
// import ToolTip from '@components/ToolTip';
// import { getSeekPoint } from '@helpers/timeline';
import { Pause, Play, ToBegin } from '@components/Icons';
import Spinner from '@components/Spinner';
import '../NewTimeline/NewTimeline.scss';

const VideoControls = (props) => {
  const {
    timeline,
    // requestSeek,
  } = props;

  const toggleIcon = timeline.playing ? <Pause /> : <Play />;

  const PlayPauseButton = ({ buttons }) => {
    if (!buttons.play) return null;
    const togglePlay = () => {
      window.post.message("video-play")
      props.timelineTogglePlay();
    };

    return (
      <div style={{ height: '40px', width: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Button lightBg={true} className="TimeLine__icons--play" onClick={togglePlay} disabled={timeline.buffering} >
          {timeline.buffering ? <Spinner visible={true} /> : toggleIcon}
        </Button>
      </div>
    );
  };

  const ToBeginButton = () => {
    return (
      <Button lightBg={true} className="TimeLine__icons--play" onClick={() => {
        props.onProgressJump(0);
        window.post.message("video-rewind")
      }} disabled={timeline.buffering} >
        <ToBegin />
      </Button>
    );
  };

  const ToEndButton = () => {
    // backgroundInstances
    return (
      <Button lightBg={true} className="TimeLine__icons--play" onClick={() => {
        props.onProgressJump(props.compositionDuration)
        window.post.message("video-forward")
      }} disabled={timeline.buffering} >
        <ToBegin />
      </Button>
    );
  };

  return (
    <div className="TimeLine__icons">
      {/* <ToolTip message="Play / Pause (Spacebar)" delay={1500} place="right"> */}
      <div className="VideoControls toBegin">
        <ToBeginButton />
      </div>
      <div className="VideoControls">
        <PlayPauseButton buttons={props.buttonConfig} />
      </div>
      <div className="VideoControls toEnd">
        <ToEndButton />
      </div>
      {/* </ToolTip> */}
    </div>
  );
};

VideoControls.propTypes = {
  timeline: PropTypes.object,
  timelineTogglePlay: PropTypes.func,
  requestSeek: PropTypes.func,
  onProgressJump: PropTypes.func,
  compositionDuration: PropTypes.number,
};

VideoControls.defaultProps = {
  buttonConfig: {
    play: true,
  },
};

export default VideoControls;
