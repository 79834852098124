import * as types from '@constants/actionTypes';

const initialState = {
    openFromURL: false,
    saveProject: false,
    saveToCloud: false,
    saveToTarget: false,
    exportVideo: false,
    about: false,
};

export default function modals(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_MODAL:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}
