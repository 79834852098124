import cloneDeep from 'lodash.clonedeep';

const getVideoForTimestamp = (timestamp, instances) => {
  const [equivalentVideo] = instances.filter(videoInstance =>
    videoInstance.visibleFrom < timestamp && videoInstance.visibleTo > timestamp);
  return equivalentVideo;
};

const determineCanvasType = (canvasWidth, canvasHeight) => {
  if (canvasWidth === 1920 && canvasHeight === 1080) return 'horizontal';
  if (canvasWidth === 1080 && canvasHeight === 1920) return 'vertical';
  if (canvasWidth === 1080 && canvasHeight === 1080) return 'square';
  if (canvasWidth === 1080 && canvasHeight === 1350) return 'facebook_vertical';
  // TODO: custom is not yet supported when creating videos,
  // but if possible in the future, a correct aspect ratio numbers
  // should be calculated to the thumbnail
  return 'custom';
};

const determineDimentions = (format) => {
  switch (format) {
    case 'vertical':
      return {
        width: 1080,
        height: 1920,
      };
    case 'square':
      return {
        width: 1080,
        height: 1080,
      };
    case 'facebook_vertical':
      return {
        width: 1080,
        height: 1350,
      };

    default:
      return {
        width: 1920,
        height: 1080,
      };
  }
};

const determineAxisLocks = async (canvasWidth, canvasHeight, aspectNumber) => {
  let lockX;
  let lockY;
  const canvasType = await determineCanvasType(canvasWidth, canvasHeight);

  if (
    (aspectNumber >= 1.7 && canvasType === 'horizontal') ||
    (aspectNumber < 1 && (canvasType === 'vertical' || canvasType === 'facebook_vertical')) ||
    (aspectNumber === 1 && canvasType === 'square')
  ) {
    lockY = true;
    lockX = true;
  }

  if (
    (aspectNumber < 1 && canvasType === 'horizontal') ||
    (aspectNumber === 1 && (canvasType === 'vertical' || canvasType === 'facebook_vertical')) ||
    (aspectNumber < 1 && canvasType === 'square')
  ) {
    lockY = false;
    lockX = true;
  }
  if (
    (aspectNumber === 1 && canvasType === 'horizontal') ||
    (aspectNumber >= 1.7 && (canvasType === 'vertical' || canvasType === 'facebook_vertical')) ||
    (aspectNumber >= 1.7 && canvasType === 'square')
  ) {
    lockY = true;
    lockX = false;
  }

  return {
    lockX,
    lockY,
  };
};

const reversionArray = name => ({
  vertical: [{
    name: `${name} : horizontal`,
    dimensions: determineDimentions('horizontal'),
  }, {
    name: `${name} : square`,
    dimensions: determineDimentions('square'),
  }, {
    name: `${name} : 4:5`,
    dimensions: determineDimentions('facebook_vertical'),
  }],
  horizontal: [{
    name: `${name} : vertical`,
    dimensions: determineDimentions('vertical'),
  }, {
    name: `${name} : square`,
    dimensions: determineDimentions('square'),
  }, {
    name: `${name} : 4:5`,
    dimensions: determineDimentions('facebook_vertical'),
  }],
  square: [{
    name: `${name} : horizontal`,
    dimensions: determineDimentions('horizontal'),
  }, {
    name: `${name} : vertical`,
    dimensions: determineDimentions('vertical'),
  }, {
    name: `${name} : 4:5`,
    dimensions: determineDimentions('facebook_vertical'),
  }],
  facebook_vertical: [{
    name: `${name} : square`,
    dimensions: determineDimentions('square'),
  }, {
    name: `${name} : vertical`,
    dimensions: determineDimentions('vertical'),
  }, {
    name: `${name} : horizontal`,
    dimensions: determineDimentions('horizontal'),
  }],
});

const getReversionedComposition = (composition) => {
  const canvasType = determineCanvasType(composition.width, composition.height);
  return reversionArray(composition.name)[canvasType];
};

const calculateImageDimentions = async (sourceImage, composition) => {
  const { width, height } = sourceImage;
  const imageVertical = width < height;
  const imageLandscape = width > height;
  const imageRatio = height / width;
  const canvasType = await determineCanvasType(composition.width, composition.height);

  let dimensions;
  // Work out the desired size for Unsplash image
  if (imageVertical && canvasType === 'vertical') {
    dimensions = { w: 1080, h: 1920 };
  } else if (imageVertical && canvasType === 'horizontal') {
    dimensions = { w: 1920, h: Math.ceil(1920 * imageRatio) };
  } else if (imageLandscape && canvasType === 'vertical') {
    dimensions = { w: Math.ceil(1920 / imageRatio), h: 1920 };
  } else if (imageLandscape && canvasType === 'horizontal') {
    dimensions = { w: 1920, h: 1080 };
  } else if (imageVertical && canvasType === 'square') {
    dimensions = { w: 1080, h: Math.ceil(1920 * imageRatio) };
  } else if (imageLandscape && canvasType === 'square') {
    dimensions = { w: 1920, h: 1080 };
  } else {
    dimensions = { w: 1080, h: 1080 };
  }

  return dimensions;
};

// the following function is used to scale/modify background instances in different ways,
// how it modify instances depends on the type of working canvas being used
const modifySourceItem = (sourceItem, composition, contain) => {
  const sourceImage = cloneDeep(sourceItem);
  const { width: CW, height: CH } = composition;
  const { width: SW, height: SH } = sourceImage;
  const aspectRatio = SW / SH;
  const getScaleX = w => w / SW;
  const getScaleY = h => h / SH;
  const scaledWidth = CH * aspectRatio;
  const scaledHeight = CW / aspectRatio;
  const scaleY = getScaleY(CH);
  const scaleX = getScaleX(CW);
  const trueScale = contain ? Math.min(scaleX, scaleY) : Math.max(scaleX, scaleY);

  if (trueScale === scaleX) {
    sourceImage.scaleToWidth = true;
    sourceImage.scaleX = getScaleX(CW);
    sourceImage.scaleY = getScaleY(scaledHeight);
    sourceImage.top = (CH - scaledHeight) / 2;
    sourceImage.left = 0;
    sourceImage.lockY = true;
    sourceImage.lockX = true;
    sourceImage.lockMovementY = true;
    sourceImage.lockMovementX = true;
    sourceImage.minY = contain ? 0 : CH - (sourceImage.height * getScaleY(scaledHeight));
    sourceImage.maxY = contain ? CH - (sourceImage.height * getScaleY(scaledHeight)) : 0;
    sourceImage.maxX = 0;
    sourceImage.minX = 0;
    // movement locks on bg instances, initially moving instances
    // is locked and will be open on double click
    sourceImage.moveLocks = {
      y: false,
      x: true,
    };
  } else {
    sourceImage.scaleToHeight = true;
    sourceImage.left = (CW - scaledWidth) / 2;
    sourceImage.top = 0;
    sourceImage.scaleY = getScaleY(CH);
    sourceImage.scaleX = getScaleX(scaledWidth);
    sourceImage.lockY = true;
    sourceImage.lockX = true;
    sourceImage.lockMovementY = true;
    sourceImage.lockMovementX = true;
    sourceImage.minX = CW - (sourceImage.width * getScaleX(scaledWidth));
    sourceImage.maxX = 0;
    sourceImage.minY = 0;
    sourceImage.maxY = 0;
    // movement locks on bg instances, initially moving instances
    // is locked and will be open on double click
    sourceImage.moveLocks = {
      y: true,
      x: false,
    };
  }
  return sourceImage;
};


const getVideoPosition = (video, compositionDimention) => {
  if (!video) return {};
  const {
    lockY, lockX, top, left, width, height, scaleX, scaleY,
  } = video;

  const videoWidth = width * scaleX;
  const videoHeight = height * scaleY;
  const { compositionWidth, compositionHeight } = compositionDimention;
  if ((lockX && top === 0) || (lockY && left === 0)) {
    return 'leftTop';
  }
  if ((lockX && top === (compositionHeight - videoHeight)) ||
  (lockY && left === (compositionWidth - videoWidth))) {
    return 'rightBottom';
  }
  if ((lockX && top === (compositionHeight - videoHeight) / 2) ||
  (lockY && left === (compositionWidth - videoWidth) / 2)) {
    return 'center';
  }
  return null;
};

export {
  determineAxisLocks,
  calculateImageDimentions,
  determineCanvasType,
  modifySourceItem,
  getVideoForTimestamp,
  determineDimentions,
  getReversionedComposition,
  // modifyReversioned,
  getVideoPosition,
};
