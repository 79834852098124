import React from 'react';
import PropTypes from 'prop-types';
// import ButtonGroup from '@components/ButtonGroup';
import Spinner from '@components/Spinner';
// import { shapeButtons } from '@constants/sidebar';
import { DEFAULT_SHAPE_DURATION } from '@helpers/shapes';
import { getNewInstanceTimeslot } from "@helpers/composition";
import classNames from 'classnames';
import { DraggableShapeFootage } from '@components/DraggableFootage';
import './ShapesTab.scss';

const propTypes = {
  filter: PropTypes.string,
  loading: PropTypes.bool,
  onAddShape: PropTypes.func,
  expandPool: PropTypes.func,
  shapeLibrary: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  composition: PropTypes.object,
  onFilterToggle: PropTypes.func.isRequired,
};

const defaultProps = {
  shapeLibrary: [],
};

const ShapesTab = (props) => {
  const {
    // filter,
    // TODO: use when fetching shapes from api is setup
    loading,
    onAddShape,
    shapeLibrary,
    onCancel,
    composition,
    // onFilterToggle,
    expandPool,
  } = props;

  const handleOnDragStart = () => {
  };

  const { shapeInstances } = composition;

  const handleAddShape = async (shape) => {
    const timeslot = getNewInstanceTimeslot(shapeInstances, DEFAULT_SHAPE_DURATION);
    const sourceShape = {
      src: shape.src,
      id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
      type: 'shape',
      left: Math.floor(composition.width / 2),
      top: Math.floor(composition.height / 2),
      ang: 0,
      visibleFrom: timeslot.visibleFrom,
      visibleTo: timeslot.visibleTo,
      duration: timeslot.duration,
      track: 4,
    };
    window.post.message("video-add-shape")
    onAddShape(sourceShape);
  };

  const spinnerClasses = classNames('Curtain', {
    'Curtain--loading': true,
  });

  const shapes = shapeLibrary && shapeLibrary.map((shape, index) => (
    <div
      key={shape.id || index}
      className="content-grid-col"
      onClick={() => { handleAddShape(shape); }}
    >
      <DraggableShapeFootage
        key={index}
        type='shape'
        list="gallery"
        duration={composition.duration}
        expandPool={expandPool}
        onDragStart={handleOnDragStart} // () => handleOnDragStart(-1, image, image.id)}
        onCancel={onCancel}
        instance={shape}
        {...shape}
      >
        <div key={index} className="ShapeThumbnail">
          <img src={shape.src} alt='shape' />
        </div>
      </DraggableShapeFootage>
    </div>
  ));

  return (
    <div className="ShapesTab">
      {/*<section className="VideosTab__heading--section VideosTabHeading">
         <div className="Search">
          <ButtonGroup
            items={shapeButtons}
            selected={filter}
            onSelect={(tab) => {
              // TODO filter toggling is set up in reducer, change this when true svg filtering ready
              onFilterToggle('shapeFilter', tab);
            }}
            images={true}
          />
        </div> 
      </section>*/}
      <section className="ShapesTab__list--section">
        {loading && (
          <div className={spinnerClasses}>
            <Spinner visible={true} />
          </div>
        )}
        <div
          className="scroll-y ShapesTab__list"
        >
          {shapes}
        </div>
      </section>
    </div>
  );
};

ShapesTab.propTypes = propTypes;
ShapesTab.defaultProps = defaultProps;

export default ShapesTab;
