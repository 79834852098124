import { combineEpics } from 'redux-observable';

import {
  autosave,
  savePreference,
  seekToBeginningOnLoad,
} from '@epics/composition';
import {
  discardActive,
  prepareForPlay,
  // syncCoordinates,
  // syncAttributes,
} from '@epics/fabric';

import { notify } from '@epics/notifications';

export default combineEpics(
  notify,
  autosave,
  savePreference,
  // removeTextInstance,
  discardActive,
  prepareForPlay,
  // syncCoordinates,
  // syncAttributes,
  seekToBeginningOnLoad,
);
