import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getPositionsForTextDirection, getPositionsForLayerDirection, getPositionsForVideoDirection } from '@lib/fabricHelper';

import {
  PositionLeft,
  PositionCenter,
  PositionRight,
  PositionTop,
  PositionMiddle,
  PositionBottom,
} from '@components/Icons';
import Button from '@components/Button';

import './PositionGrid.scss';

const propTypes = {
  onSelect: PropTypes.func,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  highlightGrid: PropTypes.bool,
  context: PropTypes.string,
  activeBackgroundId: PropTypes.number,
  activeText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  activeLayer: PropTypes.object,
  lockX: PropTypes.bool,
};

const PositionGrid = (props) => {
  const [horPos, toggleHorPos] = useState(null);
  const [verPos, toggleVerPos] = useState(null);
  useEffect(() => {
    const pos = `${verPos + horPos}`;
    if (props.context === 'layer' && horPos !== null && verPos !== null) {
      props.onSelect(
        getPositionsForLayerDirection(pos, props.compositionWidth, props.compositionHeight),
      );
    } else if (props.context === 'text' && horPos !== null && verPos !== null) {
      props.onSelect(
        getPositionsForTextDirection(
          pos,
          props.activeText,
          (props.compositionWidth / 20),
          props.compositionWidth,
          props.compositionHeight,
        ),
      );
    } else if (props.context === 'video' && horPos !== null && verPos !== null) {
      props.onSelect(
        getPositionsForVideoDirection(
          pos,
          props.compositionWidth,
          props.compositionHeight,
          props.activeText,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horPos, verPos]);

  let horIcon;
  let verIcon;
  if (horPos === 'Left') { horIcon = <PositionLeft/>; } else if (horPos === 'Center') { horIcon = <PositionCenter/>; } else if (horPos === 'Right') { horIcon = <PositionRight/>; } else if (horPos === null) { horIcon = <PositionLeft/>; }
  if (verPos === 'top') { verIcon = <PositionTop/>; } else if (verPos === 'middle') { verIcon = <PositionMiddle/>; } else if (verPos === 'bottom') { verIcon = <PositionBottom/>; } else if (verPos === null) { verIcon = <PositionTop/>; }

  const displayVer = (props.context === 'video' && props.lockX) ? 'none' : 'block';
  const displayHor = (props.context === 'video' && !props.lockX) ? 'none' : 'block';

  return (
      <Fragment>
               <div className="ToolbarItem"
                onClick={() => {
                  if (horPos === 'Left') { toggleHorPos('Center'); } else if (horPos === 'Center') { toggleHorPos('Right'); } else if (horPos === 'Right') { toggleHorPos('Left'); } else if (horPos === null) { toggleHorPos('Center'); toggleVerPos('middle'); }
                }}
                style={{ display: displayVer }}
                >
                <Button>
                  {horIcon}
                </Button>
              </div>
              <div className="ToolbarItem"
                onClick={() => {
                  if (verPos === 'top') { toggleVerPos('middle'); } else if (verPos === 'middle') { toggleVerPos('bottom'); } else if (verPos === 'bottom') { toggleVerPos('top'); } else if (verPos === null) { toggleVerPos('middle'); toggleHorPos('Center'); }
                }}
                style={{ display: displayHor }}
                >
                <Button>
                  {verIcon}
                </Button>
              </div>
      </Fragment>
  );
};

PositionGrid.propTypes = propTypes;

export default PositionGrid;
