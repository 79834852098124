import { renderHelper } from '@lib/renderHelper';
import { layerPositions } from '@constants/backgroundInstance';
import cloneDeep from 'lodash.clonedeep';

/**
 * Checks if there is an active text selection in the given
 * fabricjs object
 *
 * @param {Object} activeObject - Active fabricjs text object
 * @returns {Bool}
 */

// TODO: Why activeObject is sometimes null?
const hasSelectedText = activeObject =>
  activeObject && activeObject.selectionStart !== activeObject.selectionEnd;


/**
 * Gets the full range of text object so that
 * styles can ba applied to the full text when no
 * selection has been made
 *
 * @param {Object} activeObject - Active fabricjs text object
 * @returns {Object}
 */
const fullTextRange = activeObject => ({
  start: 0,
  end: activeObject && activeObject.text ? activeObject.text.length : null,
});

/**
 * Checks if a text is fully selected so that styles can be
 * applied in a certain way.
 * @param {object} activeObject active fabric text object
 */
const hasTextFullySelected = (activeObject) => {
  if (!activeObject) return false;

  const { selectionStart, selectionEnd } = activeObject;
  const range = fullTextRange(activeObject);

  if (selectionStart === range.start && selectionEnd === range.end) {
    return true;
  }

  return false;
};

/**
 * List of styling related attributes that needs to be
 * set to fabric text object in addition with "styles" object.
 *
 * These attributes affect the entire text, when "styles" object
 * affect parts of the text.
 */
const textStyleAttributes = [
  'backgroundColor',
  'fontSize',
  'fontWeight',
  'fontFamily',
  'fontStyle',
  'fill',
  // 'linetrough',
  // 'overline',
  'stroke',
  'strokeWidth',
  // 'textBackgroundColor',
  // 'underline',
  // 'lineHeight',
  // 'text',
  // 'charSpacing',
  'shadow',
  'textAlign',
  // 'styles',
];

const settableStyleAttributes = [
  'backgroundColor',
];

/**
 * Shared styles used for having the same style of transform
 * controls in all of the canvas layers
 */
const borderAndCornerStyles = {
  transparentCorners: false,
  cornerColor: '#2ebf91',
  cornerStrokeColor: 'var(--color4)',
  cornerStyle: 'circle',
  borderColor: '#2ebf91',
  cornerSize: 22,
  lockUniScaling: true,
  lockScalingFlip: true,
  lockRotation: false,
  hasRotatingPoint: true,
  centeredScaling: false,
  hoverCursor: 'pointer',
};

/**
 * Gets an instance and returns a subset of that instances
 * attributes. This subset is a list of attributes that can
 * be updated by user (position, opacity, scale...).
 *
 * Use this to easily grab "safe" attributes from an instance
 * and apply to another object or redux update.
 *
 * @param{object} instance - Render engine layer instance
 *
 */
const getModifiableLayerAttributes = (layer) => {
  const wantedAttributes = [
    'visibleFrom',
    'src',
    'visibleTo',
    'id',
    'top',
    'left',
    'height',
    'width',
    'scaleX',
    'scaleY',
  ];

  Object.keys(layer).reduce((acc, key) => {
    if (wantedAttributes.includes(key)) {
      acc[key] = layer[key];
    }
    return acc;
  }, {});
};

const clearAttributesFromStylesObject = (stylesObject, newStyles) => {
  const keysToRemove = Object.keys(newStyles);
  const cloned = cloneDeep(stylesObject);

  const removeKey = (obj, keys) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        removeKey(obj[key], keys);
      }

      if (keys.includes(key)) {
        delete obj[key]; // eslint-disable-line no-param-reassign
      }
    });

    return obj;
  };

  return removeKey(cloned, keysToRemove);
};

/**
 * Applies styles to an active fabricjs object.
 * If there is an active selection, styles will be applied to it, if not
 * styles will be applied to the full text length.
 *
 * @param {Object} styles - Style object, example: { fill: 'red' }
 *
 * @returns {Object} - { styles, attributes } shaped object where
 * styles will include a Fabric.js style object and attributes
 * will include a key,value pair or a style attribute that is set later.
 *
 * attributes key is present if a complete text selection is made
 * and therefore styles object is cleared of a style key with the
 * intention of it being added as a root level style property to the
 * text.
 *
 * example:
 * whole text is selected and text "fill" is changed to rgba(0,0,0,1) In order to do this
 * the text object is fetched (activeText), and it's style object is
 * cleaned so that any "fill" attributes are removed. After this
 * a cleaned styles object is returned with a attributes object (that
 * has the fill key/value pari in it) so that later in redux, the
 * state would be:
 * { styles: newStye object, fill: 'rbga(0,0,0,1)' }
 */
const applyStylesToSelectedText = (styles) => {
  const activeText = renderHelper.engine.canvas.getActiveObject();

  if (hasSelectedText(activeText) && !hasTextFullySelected(activeText)) {
    activeText.setSelectionStyles(styles);
    renderHelper.engine.canvas.renderAll();
    return {
      styles: activeText.styles,
      attributes: {},
    };
  }

  /**
   * Clear style attributes that collide with previously
   * set attribute. If styles has 'fill' key, that
   * would overwrite the base 'fill' attribute
   */
  const clearedStyles = clearAttributesFromStylesObject(activeText.styles, styles);
  activeText.styles = clearedStyles;
  /**
   * If no selection is made, apply styles as attributes
   * to the whole text
   */
  activeText.set(styles);

  /**
   * renderAll() needs to be called after .set() to make changes
   * visible on canvas
   */
  renderHelper.engine.canvas.renderAll();

  return {
    styles: clearedStyles,
    attributes: styles,
  };
};

/**
 * Fetches active text selection styles, or if a selection
 * has not been made, full styles
 *
 * @returns {Object} Fabricjs style object
 */
const getActiveTextSelectionStyles = () => {
  const activeText = renderHelper.engine.canvas.getActiveObject();
  let styles;

  if (hasSelectedText(activeText)) {
    const start = activeText.selectionStart;
    const end = activeText.selectionEnd;
    // get selection styles with complete set to true, so that
    // also attributes outside "styles" object are returned
    styles = activeText && activeText.getSelectionStyles(start, end, true);
  } else {
    const { start, end } = fullTextRange(activeText);
    styles = activeText && activeText.getSelectionStyles(start, end, true);
  }

  return styles;
};

const getActiveTextSelectionAttributes = () => {
  const activeText = renderHelper.engine.canvas.getActiveObject();

  return textStyleAttributes.reduce((acc, attribute) => {
    acc[attribute] = activeText[attribute];
    return acc;
  }, {});
};

/**
 * When selecting a position from a position grid (text
 * context tools), top and left positions for that selection
 * is calculated and fetched.
 *
 * Positions point represents a single point in the canvas
 * to where a specific edge point of a text should be
 * aligned.
 *
 * Edges edge represents an edge point or corner that should
 * be aligned with the previously calculated position.
 *
 * Example: text is moved to top right
 *    Left: 1080 (fullhd canvas) - padding
 *    Top: padding (if any)
 *
 * After that edges 'top' and 'right' are being set, since
 * that is corner of the textlayer that is aligned to that point.
 *
 * @param {String} direction - key for the direction. example: 'topLeft'
 * @param {Number} padding - amount of padding in px
 * @returns {Object} positions - { top, left }
 */
const getPositionsForTextDirection = (
  direction,
  activeText,
  padding,
  compositionWidth,
  compositionHeight,
) => {
  const positions = {
    topLeft: {
      left: padding,
      top: padding,
    },
    topCenter: {
      left: compositionWidth / 2,
      top: padding,
    },
    topRight: {
      left: compositionWidth - padding,
      top: padding,
    },
    middleLeft: {
      left: padding,
      top: compositionHeight / 2,
    },
    middleCenter: {
      left: compositionWidth / 2,
      top: compositionHeight / 2,
    },
    middleRight: {
      left: compositionWidth - padding,
      top: compositionHeight / 2,
    },
    bottomLeft: {
      left: padding,
      top: compositionHeight - padding,
    },
    bottomCenter: {
      left: compositionWidth / 2,
      top: compositionHeight - padding,
    },
    bottomRight: {
      left: compositionWidth - padding,
      top: compositionHeight - padding,
    },
  };

  const edges = {
    topLeft: {
      x: 'left',
      y: 'top',
    },
    topCenter: {
      x: 'center',
      y: 'top',
    },
    topRight: {
      x: 'right',
      y: 'top',
    },
    middleLeft: {
      x: 'left',
      y: 'center',
    },
    middleCenter: {
      x: 'center',
      y: 'center',
    },
    middleRight: {
      x: 'right',
      y: 'center',
    },
    bottomLeft: {
      x: 'left',
      y: 'bottom',
    },
    bottomCenter: {
      x: 'center',
      y: 'bottom',
    },
    bottomRight: {
      x: 'right',
      y: 'bottom',
    },
  };

  const points = positions[direction];
  const edge = edges[direction];

  const translatePoint = () => {
    const activetextWidth = document.querySelector(`.htmlTextInstance-${activeText.id}`).offsetWidth;
    const activetextHeight = document.querySelector(`.htmlTextInstance-${activeText.id}`).offsetHeight;
    let left;
    let top;
    if (edge.x === 'left') {
      left = points.left + (activetextWidth / 2);
    }
    if (edge.x === 'right') {
      left = points.left - (activetextWidth / 2);
    }
    if (edge.x === 'center') {
      // eslint-disable-next-line prefer-destructuring
      left = points.left;
    }
    if (edge.y === 'top') {
      top = points.top + (activetextHeight / 2);
    }
    if (edge.y === 'bottom') {
      top = points.top - (activetextHeight / 2);
    }
    if (edge.y === 'center') {
      // eslint-disable-next-line prefer-destructuring
      top = points.top;
    }
    return {
      left,
      top,
    };
  };

  return translatePoint({ points, edge });
};

const getPositionsForLayerDirection = (
  direction,
  compositionWidth,
  compositionHeight,
) => {
  // const activeObject = renderHelper.engine.layers.find(layer =>
  //   layer.id === activeLayer.id).object;
  // const trueWidth = activeObject.width * activeObject.scaleX;
  // const trueHeight = activeObject.height * activeObject.scaleY;
  // raw value used for positioning logo layers
  const positions = {
    topLeft: {
      left: 250,
      top: 200,
    },
    topCenter: {
      left: (compositionWidth / 2),
      top: 200,
    },
    topRight: {
      left: compositionWidth - 250,
      top: 200,
    },
    middleLeft: {
      left: 250,
      top: (compositionHeight / 2),
    },
    middleCenter: {
      left: (compositionWidth / 2),
      top: (compositionHeight / 2),
    },
    middleRight: {
      left: compositionWidth - 250,
      top: (compositionHeight / 2),
    },
    bottomLeft: {
      left: 250,
      top: compositionHeight - 200,
    },
    bottomCenter: {
      left: (compositionWidth / 2),
      top: compositionHeight - 200,
    },
    bottomRight: {
      left: compositionWidth - 250,
      top: compositionHeight - 200,
    },
  };

  const points = positions[direction];
  return {
    left: points.left,
    top: points.top,
  };
};

const getPositionsForVideoDirection = (
  direction,
  compositionWidth,
  compositionHeight,
  activeBackgroundId,
) => {
  const activeObject = renderHelper.engine.layers.find(layer =>
    layer.id === activeBackgroundId).object;
  const trueWidth = activeObject.width * activeObject.scaleX;
  const trueHeight = activeObject.height * activeObject.scaleY;
  const dimensions = {
    compositionWidth, compositionHeight, trueWidth, trueHeight,
  };
  const { positions } = layerPositions(dimensions);
  let points = positions[direction].cover;
  if (activeObject.lockMovementY) {
    points = positions[direction].contain;
  }
  return {
    left: points.left,
    top: points.top,
  };
};

const deselectActiveObject = () => {
  if (renderHelper.engine) {
    renderHelper.engine.canvas
      .discardActiveObject()
      .renderAll();
  }
};

const selectObject = (object) => {
  if (renderHelper.engine && object) {
    renderHelper.engine.canvas.setActiveObject(object);
  }
};

const getFabricObjectByTextLayerId = (layerId) => {
  const [foundLayer] = renderHelper.engine.layers
    .filter(layer => layer.id && layer.id === layerId);

  return foundLayer && foundLayer.textElement;
};

const getLayerById = (layerId) => {
  const [foundLayer] = renderHelper.engine && renderHelper.engine.layers
    .filter(layer => layer.id && layer.id === layerId);

  return foundLayer;
};

export {
  deselectActiveObject,
  selectObject,
  applyStylesToSelectedText,
  getLayerById,
  getActiveTextSelectionStyles,
  getActiveTextSelectionAttributes,
  getFabricObjectByTextLayerId,
  getPositionsForTextDirection,
  getPositionsForLayerDirection,
  getPositionsForVideoDirection,
  textStyleAttributes,
  settableStyleAttributes,
  getModifiableLayerAttributes,
  borderAndCornerStyles,
};
