let zipperReady = false

export function initZipper() {
    return new Promise((resolve, reject) => {
        if (zipperReady) {
            resolve()
        } else {
            const script = document.createElement('script')
            script.src = process.env.PUBLIC_URL + '/zip.min.js'
            script.async = true
            script.onload = () => {
                zipperReady = true
                resolve()
            }
            script.onerror = (e) => reject(e)
            document.body.appendChild(script)
        }
    })
}

export function compress(config) {
    return new Promise((resolve, reject) => {
        initZipper().then(async () => {
            const zip = window.zip;
            const zipWriter = new zip.ZipWriter(new zip.Data64URIWriter('application/zip'))
            for await (const file of config.files) {
                const fileObject = new Blob([file.data], {
                    type: 'application/octet-stream'
                })
                await zipWriter.add(file.name, new zip.BlobReader(fileObject))
            }
            const dataURI = await zipWriter.close()
            resolve(dataURI)
        })
    })
}

export function extract(file) {
    return new Promise((resolve, reject) => {
        initZipper().then(async () => {
            const zip = window.zip
            const reader = new zip.ZipReader(new zip.BlobReader(file))
            try {
                const entries = await reader.getEntries()
                let results = []
                if (entries.length) {
                    for await (const item of entries) {
                        // Old Sumopaint files contain .txt file with the data
                        if (item.filename.includes('.txt')) {
                            const data = await item.getData(
                                // writer
                                new zip.TextWriter(), {
                                onprogress: (index, max) => {
                                    // onprogress callback
                                }
                            }
                            )
                            results.push(JSON.parse(data))
                        }
                        // Read .voi images with blob writer
                        if (item.filename.includes('.data')) {
                            const data = await item.getData(
                                // writer
                                new zip.BlobWriter('application/octet-stream'), {
                                onprogress: (index, max) => {
                                    // onprogress callback
                                }
                            }
                            )
                            results.push(data)
                        }
                        // Read .json data with text writer
                        if (item.filename.includes('.json')) {
                            const data = await item.getData(
                                // writer
                                new zip.TextWriter(), {
                                onprogress: (index, max) => {
                                    // onprogress callback
                                }
                            }
                            )
                            results.push(JSON.parse(data))
                        }
                    }
                }
                // close the ZipReader
                await reader.close()
                resolve(results)
            } catch (e) {
                reject(e)
            }
        })
    })
}

