import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line import/no-extraneous-dependencies
// import { createTracker } from 'redux-segment';

import rootReducer from '@reducers';
import rootEpic from '@epics';

// const tracker = createTracker();
const epicMiddleware = createEpicMiddleware();

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(thunk, epicMiddleware),
    ),
  );

  epicMiddleware.run(rootEpic);

  return store;
}
