/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import { setThumbnailAnimation } from '@components/VideoPreview/animations';

const propTypes = {
  anim: PropTypes.object,
  value: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  onHandleAnimationChange: PropTypes.func,
};

class TextAnimThumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  // eslint-disable-next-line class-methods-use-this
  splitTextToLinesWordsAndChars(text) {
    return text.replace(/[^\r\n]+/g, (line) => {
      let lineHtml = '';
      const words = line.split(' ');
      words.forEach((word) => {
        lineHtml += `<span class="word">${word.replace(/\S/g, char => `<span class='letter'>${char}</span>`)}</span>&nbsp;`;
      });
      return `<span class='line'>${lineHtml}</span>`;
    });
  }

  resetAnimation() {
    // Reset all CSS properties
    document.querySelectorAll('.ContextSettings__TextAnimations__Thumb .line').forEach((element) => {
      element.style.opacity = 1;
      element.style.scale = 1;
      element.style.filter = 'none';
      element.style.transform = 'none';
    });
    document.querySelectorAll('.ContextSettings__TextAnimations__Thumb .word').forEach((element) => {
      element.style.opacity = 1;
      element.style.scale = 1;
      element.style.filter = 'none';
      element.style.transform = 'none';
    });
    document.querySelectorAll('.ContextSettings__TextAnimations__Thumb .letter').forEach((element) => {
      element.style.opacity = 1;
      element.style.scale = 1;
      // element.style.width = 'auto';
      element.style.filter = 'none';
      element.style.transform = 'none';
    });
  }

  render() {
    const { selected, onHandleAnimationChange } = this.props;
    const { value, label } = this.props.anim;

    return (
      <div
        onMouseEnter={() => {
          window.thumbnailAnimController = anime.timeline({
            loop: true,
            autoplay: true,
          });
          setThumbnailAnimation({
            name: this.props.anim.value,
            selector: `.ContextSettings__TextAnimations__Thumb.${this.props.anim.value}`,
          });
        }}
        onMouseLeave={() => {
          window.thumbnailAnimController.pause();
          this.resetAnimation();
        }}
        onClick={() => {
            onHandleAnimationChange(value);
          }
        }
        className={selected ?
          `ContextSettings__TextAnimations__Thumb ${value} ContextSettings__TextAnimations__Thumb--Selected` :
          `ContextSettings__TextAnimations__Thumb ${value}`
        }
      ><div dangerouslySetInnerHTML={{ __html: this.splitTextToLinesWordsAndChars(label) }}></div>
      </div>
    );
  }
}

TextAnimThumb.propTypes = propTypes;

export default TextAnimThumb;
