import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
import Adjuster from './Adjuster';
import './SidebarContext.scss';
import './SidebarTextOverlay.scss';

const propTypes = {
  brandkit: PropTypes.object,
  activeTextId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  activeText: PropTypes.object,
  animation: PropTypes.string,
  sidebar: PropTypes.object,
  onTriggerNotification: PropTypes.func,
  textInstances: PropTypes.array,
  titleInstances: PropTypes.array,
  onTextLayerEditEnter: PropTypes.func,
  onApplyStyles: PropTypes.func,
  onApplyAttribute: PropTypes.func,
  onApplyAttributes: PropTypes.func, // new, takes more than one key/value
  onApplyAttributesToAll: PropTypes.func,
  onMove: PropTypes.func,
  onRemove: PropTypes.func,
  onSaveAsPreset: PropTypes.func,
  onRotate: PropTypes.func,
  onClose: PropTypes.func,
  onAddTextLayer: PropTypes.func,
  onApplyAnimation: PropTypes.func,
  onUpdateTextLayerDepths: PropTypes.func,
  onUpdateTextLayerOrders: PropTypes.func,
  onUpdateTimestamps: PropTypes.func,
  onSideBarAdjusters: PropTypes.func,
  styles: PropTypes.object,
  textAlign: PropTypes.string,
  stroke: PropTypes.string,
  shadow: PropTypes.object,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  compositionUid: PropTypes.string,
  openAdjusters: PropTypes.string,
  user: PropTypes.object,
  onDuplicate: PropTypes.func,
  fill: PropTypes.string,
  onLayerToggleVisible: PropTypes.func,
  textLayerChange: PropTypes.func,
  onUpdateAppearingSequence: PropTypes.func,
};

let scrollInitialized = false;

const SidebarTextOverlay = ({
  brandkit,
  activeText,
  activeTextId,
  animation,
  sidebar,
  onApplyAttribute,
  onApplyAnimation,
  onSideBarAdjusters,
  textInstances,
  onTextLayerEditEnter,
  openAdjusters,
}) => {
  if (!openAdjusters) return null;

  const {
    strokeWidth,
    stroke,
    fill,
    textShadow,
    backgroundColor,
    borderRadius,
    padding,
    borderWidth,
    borderColor,
    boxShadow,
  } = activeText;

  const sidebarContextOverlay = document.querySelector('.SidebarContextOverlay');

  if (sidebarContextOverlay) {
    setTimeout(() => {
      if (!scrollInitialized) {
        sidebarContextOverlay.scrollTop = 1;
        scrollInitialized = true;
      }
    }, 3000);
  }

  const handleAttributeChange = (attr, value) => {
    const attributeObject = { key: attr, value };
    try {
      sidebar.activeTextId.forEach(textId => onApplyAttribute(textId, attributeObject));
    } catch (e) {
      const textId = sidebar.activeTextId;
      onApplyAttribute(textId, attributeObject);
    }
  };
  // fill change
  const handleFillChange = value =>
    handleAttributeChange('fill', `rgba(${value.r},${value.g},${value.b},${value.a})`);
  // background color change
  const handleBgColorChange = value =>
    handleAttributeChange('backgroundColor', `rgba(${value.r},${value.g},${value.b},${value.a})`);
  // stroke size
  const handleStrokeSize = strokeSize => handleAttributeChange('strokeWidth', strokeSize);
  // stroke color
  const handleStrokeColor = strokeColor =>
    handleAttributeChange(
      'stroke',
      `rgba(${strokeColor.r},${strokeColor.g},${strokeColor.b},${strokeColor.a})`,
    );
  // text resize
  const handleTextResize = (confirmation) => {
    // activeTextId is now an array of active texts
    if (confirmation) onApplyAnimation(sidebar.activeTextId[0]);
  };

  const renderAdjuster = (activeAdjuster) => {
    switch (activeAdjuster) {
      case 'color':
        return (
          <Adjuster
            label="Text Properties"
            type={activeAdjuster}
            fill={fill}
            onFillChange={handleFillChange}
            pallets={brandkit && brandkit.brandcolor}
            strokeColor={stroke}
            onSideBarAdjusters={onSideBarAdjusters}
            textShadow={textShadow}
            strokeWidth={strokeWidth}
            onStrokeChange={(options) => {
              if (options.size) {
                handleStrokeSize(options.size);
              }
              if (options.color) {
                handleStrokeColor(options.color);
              }
            }}
            onTextShadowChange={(value) => {
              if (value.preset) {
                handleAttributeChange('textShadow', value);
                return;
              }
              if (value.offsetX) {
                handleAttributeChange('textShadow', {
                  ...textShadow,
                  offsetX: value.offsetX,
                });
              }
              if (value.offsetY) {
                handleAttributeChange('textShadow', {
                  ...textShadow,
                  offsetY: value.offsetY,
                });
              }
              if (value.blur) {
                handleAttributeChange('textShadow', {
                  ...textShadow,
                  blur: value.blur,
                });
              }
              if (value.color) {
                const { color } = value;
                handleAttributeChange('textShadow', {
                  ...textShadow,
                  color: `rgba(${color.r},${color.g},${color.b},${color.a})`,
                });
              }
            }}
          />
        );
      case 'animations':
        return (
          <Adjuster
            label="Text Animations"
            type={activeAdjuster}
            onSideBarAdjusters={onSideBarAdjusters}
            onApplyAnimation={onApplyAnimation}
            activeTextId={activeTextId}
            activeText={activeText}
            onTextLayerEditEnter={onTextLayerEditEnter}
            textInstances={textInstances}
            animation={animation}
            sidebar={sidebar}
            cancelButton={<Button onClick={() => handleTextResize(false)}>Cancel</Button>}
            confirmButton={
              <Button color="red" onClick={() => handleTextResize(true)}>
                Resize text and apply animation.
              </Button>
            }
          />
        );
      case 'box':
        return (
          <Adjuster
            label="Box Properties"
            type={activeAdjuster}
            boxFill={backgroundColor}
            onBoxFillChange={handleBgColorChange}
            pallets={brandkit && brandkit.brandcolor}
            boxPadding={padding}
            onSideBarAdjusters={onSideBarAdjusters}
            onPaddingChange={(size) => {
              handleAttributeChange('padding', size);
            }}
            boxBorder={{
              borderWidth,
              borderColor,
            }}
            onBorderChange={(options) => {
              if (options.size) {
                handleAttributeChange('borderWidth', options.size);
              }
              if (options.color) {
                handleAttributeChange('borderColor', options.color);
              }
            }}
            boxCornerRadius={borderRadius}
            onRadiusChange={(size) => {
              handleAttributeChange('borderRadius', size);
            }}
            boxShadow={boxShadow}
            onShadowChange={(value) => {
              if (value.preset) {
                handleAttributeChange('boxShadow', value);
                return;
              }
              if (value.offsetX) {
                handleAttributeChange('boxShadow', {
                  ...boxShadow,
                  offsetX: value.offsetX,
                });
              }
              if (value.offsetY) {
                handleAttributeChange('boxShadow', {
                  ...boxShadow,
                  offsetY: value.offsetY,
                });
              }
              if (value.blur) {
                handleAttributeChange('boxShadow', {
                  ...boxShadow,
                  blur: value.blur,
                });
              }
              if (value.color) {
                const { color } = value;
                handleAttributeChange('boxShadow', {
                  ...boxShadow,
                  color: `rgba(${color.r},${color.g},${color.b},${color.a})`,
                });
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="styleOptions" style={{ width: '100%' }}>
      <div className="ContextSettingsGrid--TextStyles">
        <div className="ContextSettingsGrid__Row" style={{ flexDirection: 'column' }}>
          {renderAdjuster(openAdjusters)}
        </div>
      </div>
    </div>
  );
};

SidebarTextOverlay.propTypes = propTypes;

export default SidebarTextOverlay;
