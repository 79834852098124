import * as types from '@constants/actionTypes';
// import { EventTypes } from 'redux-segment';

const initialState = {
  uid: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case types.USER_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.USER_RESET:
      return initialState;

    case types.USER_UPDATE_SUBSCRIPTION: {
      const { subscription, loading } = action.payload;
      return {
        ...state,
        subscription: {
          ...subscription,
          loading,
        },
        role: action.payload.role || state.role,
      };
    }
    case types.PAYMENT_RECEIVE:
      return {
        ...state,
        hasSubscription: true,
      };
    case types.TUTORIAL_TOGGLE:
      if (state.isTutorial) {
        return {
          ...state,
          isTutorial: !state.isTutorial,
          meta: {
            // analytics: {
            //   eventType: EventTypes.track,
            //   eventPayload: {
            //     event: 'Onboarding Completed',
            //   },
            // },
          },
        };
      }
      return {
        ...state,
        isTutorial: !state.isTutorial,
        meta: {
          // analytics: {
          //   eventType: EventTypes.track,
          //   eventPayload: {
          //     event: 'Onboarding Restarted',
          //   },
          // },
        },
      };

    case types.USER_CANCEL_SUBSCRIPTION: {
      const {
        cancelAt,
        loading,
        canceled,
        // cancellationReason,
      } = action.payload;
      // const { is_trial, end_time, start_time } = state.subscription;
      if (canceled) {
        return {
          ...state,
          subscription: {
            ...state.subscription,
            cancel_at: cancelAt,
            canceled,
            loading,
          },
          meta: {
            // analytics: {
            //   eventType: EventTypes.track,
            //   eventPayload: {
            //     event: 'User Cancelled Subscription',
            //     start_time,
            //     end_time,
            //     cancelAt,
            //     is_trial,
            //     cancellationReason,
            //   },
            // },
          },
        };
      }
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancel_at: cancelAt,
          canceled,
          loading,
        },
        meta: {
          // analytics: {
          //   eventType: EventTypes.track,
          //   eventPayload: {
          //     event: 'User Re-activated Subscription',
          //     start_time,
          //     end_time,
          //     cancelAt,
          //     is_trial,
          //   },
          // },
        },
      };
    }
    case types.USER_CANCEL_REQUEST: {
      const { canceled, loading } = action.payload;
      return {
        ...state,
        subscription: {
          ...state.subscription,
          canceled,
          loading,
        },
      };
    }
    default:
      return state;
  }
}
