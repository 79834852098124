const {
  SOCKET_URL,
} = process.env;

const BASE_URL = process.env.API_BASE_URL;
// const BASE_URL = 'https://api.adlaunch.com/api';
// const SOCKET_URL = 'https://api.adlaunch.com';
const SOCKET_PATH = '/api/socket';

/**
 * Material URL's
 */
const MUSIC_URL = `${BASE_URL}/audio`;
const FOOTAGE_URL = `${BASE_URL}/footage`;
const FAVORITES_URL = `${BASE_URL}/favorites`;
const IMAGES_URL = `${BASE_URL}/footage/image`;
const VIDEOS_URL = `${BASE_URL}/footage/video`;
const COMPOSITION_URL = `${BASE_URL}/compositions`;
const PAYMENTS_URL = `${BASE_URL}/payments`;
const PURCHASE_URL = `${BASE_URL}/purchases`;
const FREEWORKSHOP_URL = `${BASE_URL}/trial/workshop`;
const FREETRIAL_URL = `${BASE_URL}/trial/twoweeks`;
const FREETVC_URL = `${BASE_URL}/trial/tvcpass`;
const FREESEEDRS_URL = `${BASE_URL}/trial/seedrspass`;
const APPSUMO_CHECK_URL = `${BASE_URL}/codes`;
const PROJECTS_URL = `${BASE_URL}/projects`;
const USER_URL = `${BASE_URL}/users`;
const UPLOAD_URL = `${BASE_URL}/uploads`;
const TAGS_URL = `${BASE_URL}/tags`;
const DESIGN_URL = `${BASE_URL}/templates`;
const TEXTPRESET_URL = `${BASE_URL}/textpresets`;
const SUBSCRIPTIONS_URL = `${BASE_URL}/subscriptions`;

/**
 * Use case specific materials
 */
const STOCK_IMAGES_URL = `${IMAGES_URL}?type=stock`;

const COMPOSITION_GENERATE_URL = `${COMPOSITION_URL}/generate`;
const COMPOSITION_RENDER_URL = `${COMPOSITION_URL}/render`;
const TUTORIAL_URL = `${USER_URL}/tutorial`;

export {
  SOCKET_URL,
  SOCKET_PATH,
  FOOTAGE_URL,
  MUSIC_URL,
  VIDEOS_URL,
  IMAGES_URL,
  STOCK_IMAGES_URL,
  COMPOSITION_GENERATE_URL,
  COMPOSITION_RENDER_URL,
  COMPOSITION_URL,
  PAYMENTS_URL,
  PURCHASE_URL,
  FREEWORKSHOP_URL,
  FREETRIAL_URL,
  PROJECTS_URL,
  USER_URL,
  UPLOAD_URL,
  TUTORIAL_URL,
  TAGS_URL,
  DESIGN_URL,
  TEXTPRESET_URL,
  FAVORITES_URL,
  SUBSCRIPTIONS_URL,
  APPSUMO_CHECK_URL,
  FREETVC_URL,
  FREESEEDRS_URL,
};
