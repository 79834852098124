import * as types from '@constants/actionTypes';
import axios from 'axios';
// import { EventTypes } from 'redux-segment';
import {
  fetchVideos,
  fetchImages,
  invalidateVideos,
  invalidateImages,
} from '@actions/footage';

import { UPLOAD_URL } from '@constants/api';

const requestNewUpload = () => ({
  type: types.UPLOAD_REQUEST_NEW,
});

const newUploadSuccess = filestackResponse => ({
  type: types.UPLOAD_REQUEST_SUCCESS,
  payload: {
    filestackResponse,
  },
  meta: {
    // analytics: {
    //   eventType: EventTypes.track,
    //   eventPayload: {
    //     event: 'User uploaded footage',
    //     properties: {
    //       filestackResponse,
    //     },
    //   },
    // },
  },
});

const requestUploadDelete = () => ({
  type: types.UPLOAD_DELETE_REQUEST,
});

const successVideoUploadDelete = uploadUid => ({
  type: types.UPLOAD_DELETE_VIDEO_SUCCESS,
  payload: {
    uploadUid,
  },
  notify: {
    message: 'Upload deleted successfully',
    kind: 'success',
  },
});

const successImageUploadDelete = uploadUid => ({
  type: types.UPLOAD_DELETE_IMAGE_SUCCESS,
  payload: {
    uploadUid,
  },
  notify: {
    message: 'Upload deleted successfully',
    kind: 'success',
  },
});

const failedUploadDelete = () => ({
  type: types.UPLOAD_DELETE_FAIL,
  notify: {
    message: 'Upload delete failed',
    kind: 'danger',
  },
});

const uploadSocketReady = (data) => {
  const { uploadId, filename, src } = data;

  return {
    type: types.UPLOAD_SOCKET_READY,
    payload: {
      uploadId,
      filename,
      src,
    },
  };
};

const newUpload = (filestackResponse, isLogo) => async (dispatch) => {
  dispatch(requestNewUpload());
  await axios.post(UPLOAD_URL, { filestackResponse, isLogo })
    .catch(err => console.log('err', err)); // eslint-disable-line no-console

  dispatch(newUploadSuccess(filestackResponse));
};

const uploadProgress = (data) => {
  const { uploadId, percent } = data;

  return {
    type: types.UPLOAD_SOCKET_PROGRESS_UPDATE,
    payload: {
      uploadId,
      percent,
    },
  };
};

const videoUploadReady = data => async (dispatch) => {
  dispatch(uploadSocketReady(data));
  dispatch(invalidateVideos('upload'));
  dispatch(fetchVideos('upload'));
};

const imageUploadReady = data => async (dispatch) => {
  dispatch(uploadSocketReady(data));
  dispatch(invalidateImages('upload'));
  dispatch(fetchImages('upload'));
};

const removeVideo = uploadUid => async (dispatch) => {
  dispatch(requestUploadDelete());
  const url = `${UPLOAD_URL}/video/${uploadUid}`;

  const response = await axios.delete(url)
    .catch(() => dispatch(failedUploadDelete()));

  if (response.data.status === 'success') {
    dispatch(successVideoUploadDelete(uploadUid));
  } else {
    dispatch(failedUploadDelete());
  }
};

const removeImage = uploadUid => async (dispatch) => {
  dispatch(requestUploadDelete());
  const url = `${UPLOAD_URL}/image/${uploadUid}`;

  const response = await axios.delete(url)
    .catch(() => dispatch(failedUploadDelete()));

  if (response.data.status === 'success') {
    dispatch(successImageUploadDelete(uploadUid));
  } else {
    dispatch(failedUploadDelete());
  }
};

export {
  newUpload,
  uploadProgress,
  videoUploadReady,
  imageUploadReady,
  removeVideo,
  removeImage,
};
