import React from 'react';
import PropTypes from 'prop-types';
import LayerControls from '../ShapeLayer/LayerControls';

const propTypes = {
  imhere: PropTypes.string,
  id: PropTypes.number,
  src: PropTypes.string,
  thumbnail: PropTypes.object,
  type: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.number,
  scaleX: PropTypes.number,
  scaleY: PropTypes.number,
  height: PropTypes.number,
  ang: PropTypes.number,
  background: PropTypes.string,
  selected: PropTypes.bool,
  visible: PropTypes.bool,
  colors: PropTypes.array,
  settings: PropTypes.array,
  scaleFactor: PropTypes.number,
  canvas: PropTypes.object,
  onUpdate: PropTypes.func,
  onSelect: PropTypes.func,
  animationIn: PropTypes.bool,
  animationOut: PropTypes.bool,
  playing: PropTypes.bool,
};

class ImageLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      x: null,
      y: null,
      ang: null,
      counterVisible: false,
      width: null,
      initialWidth: null,
      height: null,
      isHovering: false,
      isMoving: false,
      isResizing: false,
      isRotating: false,
      dragStartX: null,
      dragStartY: null,
      scaleX: null,
      scaleY: null,
      offsetX: null,
      offsetY: null,
      diffX: null,
      diffY: null,
      rotateHandleAngle: null,
      centerX: null,
      centerY: null,
    };
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.resizeStart = this.resizeStart.bind(this);
    this.resizeMove = this.resizeMove.bind(this);
    this.resizeEnd = this.resizeEnd.bind(this);
    this.rotateStart = this.rotateStart.bind(this);
    this.rotateMove = this.rotateMove.bind(this);
    this.rotateEnd = this.rotateEnd.bind(this);
  }

  componentDidMount() {
    const {
      left,
      top,
      scaleX,
      scaleY,
    } = this.props;
    const width = this.props.width && this.props.width > 0 ? this.props.width : 200;
    const height = this.props.height && this.props.height > 0 ? this.props.height : 200;
    const ang = this.props.ang && this.props.ang > 0 ? this.props.ang : 0;
    this.setState({
      x: left,
      y: top,
      ang,
      width,
      initialWidth: null,
      initialHeight: null,
      height,
      scaleX,
      scaleY,
      centerX: left + (width / 2),
      centerY: top + (height / 2),
      rotateHandleAngle: ang,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ang !== this.props.ang) {
      this.setState({ ang: this.props.ang });
    }
    // const { settings } = this.props;
    // if (!isEqual(prevProps.settings, settings)) {
    // }
  }

  mouseDown(e) {
    if (
      e.target.classList.contains('resizer-br') ||
      e.target.classList.contains('resizer-bl') ||
      e.target.classList.contains('resizer-tl') ||
      e.target.classList.contains('resizer-tr') ||
      e.target.classList.contains('rotateHandle')
    ) {
      return;
    }

    this.setState({
      isPressing: true,
      isMoving: false,
      x: this.props.left,
      y: this.props.top,
      dragStartX: e.pageX,
      dragStartY: e.pageY,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    });
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
  }

  mouseMove(e) {
    const {
      isPressing,
      dragStartX,
      dragStartY,
      offsetX,
      offsetY,
      width,
      height,
      scaleX,
      scaleY,
    } = this.state;
    const { canvas, scaleFactor } = this.props;

    const diffX = e.pageX - dragStartX;
    const diffY = e.pageY - dragStartY;
    if (isPressing && (diffX !== 0 || diffY !== 0)) {
      this.setState({ isMoving: true, isPressing: false });
    }
    const left = ((e.pageX - canvas.left) / scaleFactor) - offsetX;
    const top = ((e.pageY - canvas.top) / scaleFactor) - offsetY;
    this.setState({
      x: left + ((width * scaleX) / 2),
      y: top + ((height * scaleY) / 2),
      centerX: left + ((width * scaleX) / 2),
      centerY: top + ((height * scaleY) / 2),
      diffX,
      diffY,
    });
  }

  mouseUp() {
    const { id, onUpdate, onSelect } = this.props;
    const {
      x,
      y,
      ang,
      width,
      height,
    } = this.state;
    onUpdate({
      id,
      ang,
      width,
      height,
      left: x,
      top: y,
    }, id);
    onSelect(id);
    this.resetState();
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
  }

  resizeStart(e) {
    this.setState({
      isResizing: true,
      dragStartX: e.pageX,
      dragStartY: e.pageY,
      initialWidth: this.state.width * this.state.scaleX,
      initialHeight: this.state.height * this.state.scaleY,
    });
    window.addEventListener('mousemove', this.resizeMove);
    window.addEventListener('mouseup', this.resizeEnd);
  }

  rotateStart(e) {
    this.setState({
      counterVisible: true,
      isResizing: false,
      isRotating: true,
      dragStartX: e.pageX,
      dragStartY: e.pageY,
      initialWidth: this.state.width * this.state.scaleX,
      initialHeight: this.state.height * this.state.scaleY,
    });
    window.addEventListener('mousemove', this.rotateMove);
    window.addEventListener('mouseup', this.rotateEnd);
  }

  resizeMove(e) {
    const {
      dragStartX,
      dragStartY,
      // initialWidth,
      isResizing,
      // initialHeight,
    } = this.state;
    const { width, height, scaleFactor, left, top, canvas } = this.props;
    if (isResizing) {
      const shapeCenterX = Math.floor(canvas.left + (left * scaleFactor));
      const shapeCenterY = Math.floor(canvas.top + (top * scaleFactor));

      if (isResizing) {
        const diffXStart = ((dragStartX - shapeCenterX));
        const diffYStart = ((dragStartY - shapeCenterY));
        const diffX = ((e.pageX - shapeCenterX));
        const diffY = ((e.pageY - shapeCenterY));
        const pythagorasEnd = Math.sqrt((diffY ** 2) + (diffX ** 2));
        const pythagorasStart = Math.sqrt((diffXStart ** 2) + (diffYStart ** 2));
        const newScale = (pythagorasEnd / pythagorasStart);
        // const offSetX = initialWidth - (newScale * initialWidth);
        this.setState({
          diffX,
          diffY,
          width: width * newScale,
          height: height * newScale,
          // x: left + (offSetX / 2),
          // y: top + (offSetX / 2),
        });
      }
    }
  }

  rotateMove(e) {
    const {
      isRotating,
      dragStartY,
    } = this.state;
    const { scaleFactor, canvas, left, top } = this.props;
    if (isRotating) {
      
      const shapeCenterX = Math.floor(canvas.left + (left * scaleFactor));
      const shapeCenterY = Math.floor(canvas.top + (top * scaleFactor));
      // Calculate the vectors from center to straight down and e.page coordinates
      const vectorS = { x: 0, y: dragStartY - shapeCenterY };
      const vectorE = { x: (e.pageX - shapeCenterX), y: (e.pageY - shapeCenterY) };
      const dotProduct = ((vectorS.x * vectorE.x) + (vectorS.y * vectorE.y));
      const crossProduct = ((vectorS.x * vectorE.y) + (vectorS.y * vectorE.x));
      const radian = Math.atan2(Math.abs(crossProduct), dotProduct) / Math.PI;
      const multiplier = e.pageX > shapeCenterX ? -1 : 1;
      let angle = radian * (180 * multiplier);
      // SNAP logic
      if (Math.round(angle) % 45 === 0) { angle = Math.round(angle); }
      this.setState({
        ang: angle,
        rotateHandleAngle: angle * -1,
      });
    }
  }

  resizeEnd() {
    const { id, onUpdate } = this.props;
    const {
      x,
      y,
      width,
      height,
    } = this.state;
    this.setState({
      isResizing: false,
      isMoving: false,
      isPressing: false,
      centerX: Math.floor(x) + (width / 2),
      centerY: Math.floor(y) + (height / 2),
    });
    onUpdate({
      id,
      left: Math.floor(x),
      top: Math.floor(y),
      width: Math.floor(width),
      height: Math.floor(height),
    });
    this.resetState();
    window.removeEventListener('mousemove', this.resizeMove);
    window.removeEventListener('mouseup', this.resizeEnd);
  }

  rotateEnd() {
    const { id, onUpdate } = this.props;
    this.setState({
      isRotating: false,
      isResizing: false,
      isMoving: false,
      isPressing: false,
      isVisible: false,
      counterVisible: false,
    });
    onUpdate({
      id,
      ang: Math.floor(this.state.ang),
    });
    this.resetState();
    window.removeEventListener('mousemove', this.rotateMove);
    window.removeEventListener('mouseup', this.rotateEnd);
  }

  resetState() {
    this.setState({
      isMoving: false,
      isPressing: false,
      isResizing: false,
      dragStartX: null,
      dragStartY: null,
      offsetX: null,
      offsetY: null,
      // scaleX: null,
      // scaleY: null,
      diffX: null,
      diffY: null,
    });
  }

  mouseEnter() {
    this.setState({ isHovering: true });
  }

  mouseLeave() {
    this.setState({ isHovering: false });
  }

  render() {
    const {
      x,
      y,
      width,
      height,
      ang,
      isHovering,
      isMoving,
      scaleX,
      scaleY,
    } = this.state;
    const {
      id,
      left,
      top,
      selected,
      visible,
      playing,
      src,
      thumbnail,
      // animationOut,
      // from,
      // to,
      // deg,
    } = this.props;
    return (
      <div
        className={`imageLayer imageLayer-${id}`}
        onMouseEnter={this.mouseEnter.bind(this)}
        onMouseLeave={this.mouseLeave.bind(this)}
        onMouseDown={this.mouseDown.bind(this)}
        style={{
          backgroundImage: `url(${thumbnail ? thumbnail.small : src})`,
          backgroundSize: 'contain',
          position: 'absolute',
          pointerEvents: visible ? 'all' : 'none',
          cursor: 'move',
          width: `${Math.abs(width * scaleX)}px`,
          height: `${Math.abs(height * scaleY)}px`,
          left: `${isMoving ? x - ((width * scaleX) / 2) : left - ((width * scaleX) / 2)}px`,
          top: `${isMoving ? y - ((height * scaleY) / 2) : top - ((height * scaleY) / 2)}px`,
          transform: `rotate(${ang}deg)`,
          opacity: visible ? '1' : '0',
        }}
      >
        <LayerControls
          visible={!playing && (isHovering || selected)}
          selected={!playing && selected}
          onResizeStart={this.resizeStart.bind(this)}
          onRotateStart={this.rotateStart.bind(this)}
          rotateHandleRight={(this.state.width * scaleX) / -2}
          angle={ang}
          counterVisible={this.state.counterVisible}
        />
      </div>
    );
  }
}

ImageLayer.propTypes = propTypes;

export default ImageLayer;
