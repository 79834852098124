import { compositionLoad, compositionReset } from '@actions/composition';
import { loadDesign } from '@actions/designs';
import { sidebarProjectSelect } from '@actions/sidebar';
import * as types from '@constants/actionTypes';
import { COMPOSITION_URL, PROJECTS_URL } from '@constants/api';
import axios from 'axios';

function requestProjects() {
  return {
    type: types.PROJECTS_REQUEST,
  };
}

const fetchSingleProject = projectUid => async (dispatch) => {
  // alert("FETCH")
  const response = await axios.get(`${PROJECTS_URL}/${projectUid}`)
    // eslint-disable-next-line no-console
    .catch(err => console.log('err', err));
  // TODO: handle errors?
  if (response && response.data) {
    dispatch({
      type: types.PROJECTS_RECEIVE_SINGLE,
      payload: {
        project: response.data,
        id: projectUid,
      },
    });
  }
};

function receiveProjects(entries) {
  return {
    type: types.PROJECTS_RECEIVE,
    payload: {
      entries,
      receivedAt: Date.now(),
    },
  };
}

function projectsRequestRename(newName, projectUid) {
  return {
    type: types.PROJECTS_REQUEST_RENAME,
    payload: {
      newName,
      projectUid,
    },
  };
}

function projectsReceiveRename(newName, projectUid) {
  return {
    type: types.PROJECTS_RECEIVE_RENAME,
    payload: {
      newName,
      projectUid,
    },
  };
}

function invalidateProjects() {
  return {
    type: types.PROJECTS_INVALIDATE,
  };
}
function setPublishable(composition) {
  return {
    type: types.SET_PUBLISHABLE_TEMPLATE,
    payload: {
      composition,
    },
  };
}

const projectsAddCompositionRequest = () => ({
  type: types.PROJECTS_ADD_COMPOSITION_REQUEST,
});

const projectsAddCompositionSuccess = composition => ({
  type: types.PROJECTS_ADD_COMPOSITION_SUCCESS,
  payload: {
    composition,
  },
});

const projectsReversionCompositionRequest = () => ({
  type: types.PROJECTS_DUPLICATE_COMPOSITION_REQUEST,
});

const projectsReversionCompositionSuccess = composition => ({
  type: types.PROJECTS_REVERSION_COMPOSITION_SUCCESS,
  payload: {
    composition,
  },
});

const projectsRemoveCompositionRequest = () => ({
  type: types.PROJECTS_REMOVE_COMPOSITION_REQUEST,
});

const projectsRemoveCompositionSuccess = compositionUid => ({
  type: types.PROJECTS_REMOVE_COMPOSITION_SUCCESS,
  payload: {
    compositionUid,
  },
  notify: {
    message: 'Video deleted successfully',
    kind: 'danger',
  },
});

// remove request sent
const projectRemoveRequest = () => ({
  type: types.PROJECTS_REMOVE_REQUEST,
});
// project remove is a success
const projectRemoveSuccess = projectUid => ({
  type: types.PROJECTS_REMOVE_SUCCESS,
  payload: {
    projectUid,
  },
  notify: {
    message: 'Project deleted successfully',
    kind: 'danger',
  },
});

const compositionLoadReversioned = (composition, prevAspectRatio) => ({
  type: types.COMPOSITION_LOAD_REVERSION,
  autosave: true,
  payload: {
    composition,
    prevAspectRatio,
  },
});


function shouldFetchProjects(state) {
  const { projects } = state;
  if (projects.entries.length === 0) {
    return true;
  } else if (projects.isFetching) {
    return false;
  }

  return projects.didInvalidate;
}

const addComposition = (
  projectUid,
  name,
  dimensions,
) => async (dispatch) => {
  dispatch(projectsAddCompositionRequest());
  const composition = {
    name,
    projectUid,
    dimensions,
  };

  const response = await axios.post(COMPOSITION_URL, composition)
    .catch(err => console.log('err', err)); // eslint-disable-line no-console

  // TODO: handle errors?
  if (response && response.data.status === 'success') {
    dispatch(projectsAddCompositionSuccess(response.data.data));
    dispatch(compositionLoad(response.data.data));
  }
};

const addCompositionWithDesign = (
  project,
  name,
  dimensions,
  design,
) => async (dispatch) => {
  dispatch(projectsAddCompositionRequest());
  const composition = {
    name,
    projectUid: project,
    dimensions,
  };

  const response = await axios.post(COMPOSITION_URL, composition)
    .catch(err => console.log('err', err)); // eslint-disable-line no-console

  // TODO: handle errors?
  if (response.data.status === 'success') {
    const { uid } = response.data.data;
    const newFromTemplate = { ...design, content: { ...design.content, uid }, uid };
    dispatch(projectsAddCompositionSuccess(newFromTemplate));
    dispatch(loadDesign(newFromTemplate));
  }
};

const reversionComposition = (reversionOptions, prevAspectRatio, forTemplating) =>
  async (dispatch) => {
    dispatch(projectsReversionCompositionRequest());

    const response = await axios.post(`${COMPOSITION_URL}/reversion`, { reversionOptions })
      .catch(err => console.log('err', err)); // eslint-disable-line no-console

    if (response.data.status === 'success') {
      dispatch(projectsReversionCompositionSuccess(response.data.data));
      if (!forTemplating) {
        dispatch(compositionLoadReversioned(response.data.data, prevAspectRatio));
      } else {
        dispatch(setPublishable(response.data.data));
      }
    }
  };

const resetComposition = () => async (dispatch) => {
  dispatch(compositionReset());
}
const removeComposition = (compositionUid, projectUid) => async (dispatch, getState) => {
  dispatch(projectsRemoveCompositionRequest());
  const { projects } = getState();
  const project = projects.entries.find(proj => proj.uid === projectUid);
  const response = await axios.delete(`${COMPOSITION_URL}/${compositionUid}`)
    .catch(err => console.log('err', err)); // eslint-disable-line no-console

  // TODO: handle errors?
  if (response.data.status === 'success') {
    dispatch(projectsRemoveCompositionSuccess(compositionUid));
    if (project && project.videos.length <= 1) {
      dispatch(compositionReset());
    }
  }
};

// remove project from  ProjectsTab

const removeProject = projectInfo => async (dispatch) => {
  dispatch(projectRemoveRequest(projectInfo));

  const response = await axios.delete(`${PROJECTS_URL}/${projectInfo.uid}`)
    // eslint-disable-next-line no-console
    .catch(err => console.log('err', err));
  // TODO: handle errors?
  if (response && response.data.status === 'success') {
    dispatch(projectRemoveSuccess(projectInfo.uid));
  }
};

function fetchProjects() {
  return async (dispatch, getState) => {
    if (shouldFetchProjects(getState())) {
      dispatch(requestProjects());

      const response = await axios.get(PROJECTS_URL)
        .catch(err => console.log('err', err)); // eslint-disable-line no-console
      if (response) dispatch(receiveProjects(response.data.data));
    }
  };
}

function renameProject(newName, projectUid) {
  return async (dispatch) => {
    dispatch(projectsRequestRename(newName, projectUid));

    const project = { name: newName };
    const response = await axios.put(`${PROJECTS_URL}/${projectUid}`, { project })
      .catch(err => console.log('err', err)); // eslint-disable-line no-console

    if (response.data.status === 'success') {
      dispatch(projectsReceiveRename(newName, projectUid));
    }
  };
}

function projectsAddReceive(project) {
  return {
    type: types.PROJECTS_ADD_RECEIVE,
    payload: {
      receivedAt: Date.now(),
      project,
    },
  };
}

function addProject(projectName, compLoad) {
  return async (dispatch) => {
    const response = await axios.post(PROJECTS_URL, { projectName })
      .catch(err => console.log('err', err)); // eslint-disable-line no-console
    if (response.data.status === 'success') {
      dispatch(projectsAddReceive(response.data.data));
      dispatch(sidebarProjectSelect(response.data.data.uid));
      //  adding design/ generating when there is no project forlder selected/active causes isssue,
      // addProject is called when user tries to generate of load design in such cases
      if (compLoad) {
        dispatch(addCompositionWithDesign(
          response.data.data.uid,
          compLoad.name, {
          width: compLoad.width,
          height: compLoad.height,
        },
          compLoad,
        ));
      }
    }
  };
}

const toggleCompositionMoveModal = () => ({
  type: types.PROJECTS_MOVE_TOGGLE_MODAL,
});

const moveCompositionRequest = () => ({
  type: types.PROJECTS_MOVE_COMPOSITION_REQUEST,
});

const moveCompositionSuccess = (destinationProjectUid, compositionUid) => ({
  type: types.PROJECTS_MOVE_COMPOSITION_SUCCESS,
  payload: {
    destinationProjectUid,
    compositionUid,
  },
  notify: {
    message: 'Video moved successfully',
    kind: 'success',
  },
});

const moveCompositionFail = () => ({
  type: types.PROJECTS_MOVE_COMPOSITION_FAIL,
  notify: {
    message: 'Error occured while moving video',
    kind: 'danger',
  },
});

const moveComposition = (destinationProjectUid, compositionUid) => async (dispatch) => {
  dispatch(moveCompositionRequest());
  const data = {
    destinationProjectUid,
    compositionUid,
  };

  const response = await axios.post(`${COMPOSITION_URL}/move`, data)
    .catch(err => console.log('err', err)); // eslint-disable-line no-console

  if (response && response.data && response.data.status === 'success') {
    dispatch(moveCompositionSuccess(destinationProjectUid, compositionUid));
  } else {
    dispatch(moveCompositionFail());
  }
};


export {
  requestProjects,
  receiveProjects,
  invalidateProjects,
  fetchProjects,
  fetchSingleProject,
  renameProject,
  addComposition,
  addCompositionWithDesign,
  addProject,
  reversionComposition,
  removeComposition,
  removeProject,
  moveComposition,
  toggleCompositionMoveModal,
  resetComposition
};
