import { v4 as uuidv4 } from 'uuid';

export const sampleComposition = {
	uid: "c3afb6c3-01ee-4ab7-8f6c-2d92053edb21",
	// compositionVersion: 2,
	width: 1920,
	height: 1080,
	layers: [],
	duration: 12000,
	textInstances: [],
	audioInstances: [],
	shapeInstances: [],
	titleInstances: [
	],
	backgroundInstances: [
	]
}
export const emptyComposition = {
	uid: uuidv4(),
	width: 1920,
	height: 1080,
	layers: [],
	duration: 12000,
	textInstances: [],
	audioInstances: [],
	shapeInstances: [],
	titleInstances: [],
	backgroundInstances: []
}