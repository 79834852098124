import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal';
import KeyboardShortcuts from '@components/KeyboardShortcuts';
import './ConfirmDialog.scss';

const propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  confirmButton: PropTypes.element.isRequired,
  cancelButton: PropTypes.element,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  visible: false,
};

function ConfirmDialog(props) {
  const shortcuts = [
    {
      keys: 'esc',
      fn: props.onClose,
    },

  ];
  return (
    <Modal
      visible={props.visible}
      title={props.title}
      onClose={props.onClose}
    >
    <KeyboardShortcuts shortcuts={shortcuts} />
      {props.children}
      <div className="ConfirmDialog__button-container">
        {props.cancelButton}
        {props.confirmButton}
      </div>
    </Modal>
  );
}

ConfirmDialog.propTypes = propTypes;
ConfirmDialog.defaultProps = defaultProps;

export default ConfirmDialog;
