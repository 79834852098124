import * as types from '@constants/actionTypes';

function toggleModal(data) {
    return {
        type: types.TOGGLE_MODAL,
        payload: data,
    };
}

export {
    toggleModal,
};

