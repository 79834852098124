import React from 'react';
import { connect } from 'react-redux';

import {
  compositionLayerRemove,
  compositionLayerTimeslotChange,
  compositionLayerEditExit,
  compositionShowVisiblityHandler,
  compositionLayerInstanceUpdateTimestamps,
  compositionLayerMove,
  compositionLayerModify,
  compositionLayerToggleAlwaysVisible,
} from '@actions/composition';
import { triggerNotification } from '@actions/app';
import ToolbarLayer from '@components/ToolbarLayer';

const ToolbarLayerContainer = props => <div id="layer" className="ToolbarContent"><ToolbarLayer {...props} /></div>;

const mapStateToProps = (state) => {
  const { sidebar } = state;
  const composition = state.composition.present;
  const { activeLayerId } = sidebar;
  const { backgroundInstances, duration, showVisibilityHandler } = composition;

  const activeLayer = composition.layers
    .find(layer => layer.id === activeLayerId);

  const visibilityTimes = {
    visibleFrom: activeLayer ? activeLayer.visibleFrom : null,
    visibleTo: activeLayer ? activeLayer.visibleTo : null,
  };

  const backgroundInstanceTimes = backgroundInstances.map(instance => instance.visibleFrom);
  return {
    activeLayer,
    activeLayerId,
    backgroundInstanceTimes,
    compositionDuration: duration,
    visibilityTimes,
    showVisibilityHandler,
    compositionWidth: composition.width,
    compositionHeight: composition.height,
  };
};

export default connect(mapStateToProps, {
  onRemove: compositionLayerRemove,
  onClose: compositionLayerEditExit,
  onTimeChange: compositionLayerTimeslotChange,
  onShowVisiblityHandler: compositionShowVisiblityHandler,
  onUpdateTimestamps: compositionLayerInstanceUpdateTimestamps,
  onTriggerNotification: triggerNotification,
  onMove: compositionLayerMove,
  onModify: compositionLayerModify,
  toggleAlwaysVisible: compositionLayerToggleAlwaysVisible,
})(ToolbarLayerContainer);
