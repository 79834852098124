import * as types from '@constants/actionTypes';
import {
  applyStylesToSelectedText,
  getActiveTextSelectionStyles,
  getFabricObjectByTextLayerId,
  selectObject,
} from '@lib/fabricHelper';

import { renderHelper } from '@lib/renderHelper';

import { getNearestNonAnimatedTime } from '@helpers/textLayer';

import { timelineProgressJump } from '@actions/timeline';

function textLayerMove(itemId, left, top) {
  return {
    type: types.TEXTLAYER_MOVE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      left,
      top,
    },
  };
}

function textLayerScale(itemId, scaleX, scaleY, isGenerated) {
  return {
    type: types.TEXTLAYER_SCALE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      scaleX,
      scaleY,
      isGenerated,
    },
  };
}

function textLayerRotate(itemId, ang) {
  return {
    type: types.TEXTLAYER_ROTATE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      ang,
    },
  };
}

function textLayerSmartResize(itemId, width, fontSize) {
  return {
    type: types.TEXTLAYER_RESIZE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      width,
      fontSize,
    },
  };
}

function layerEditEnter(itemId) {
  return {
    type: types.TEXTLAYER_EDIT_ENTER,
    payload: {
      itemId,
    },
  };
}

function applyActiveStyles(styles, attributes) {
  return {
    type: types.TEXTLAYER_APPLY_ACTIVE_STYLES,
    payload: {
      styles,
      attributes,
    },
  };
}

function applyActiveAttributes(attributes) {
  return {
    type: types.TEXTLAYER_APPLY_ACTIVE_ATTRIBUTES,
    payload: {
      attributes,
    },
  };
}

function applyActiveAttribute(attributeObject) {
  return {
    type: types.TEXTLAYER_APPLY_ACTIVE_ATTRIBUTE,
    payload: {
      attributeObject,
    },
  };
}

function textLayerChange(itemId, newText) {
  return {
    type: types.TEXTLAYER_CHANGE,
    autosave: true,
    undo: true,
    payload: {
      newText,
      itemId,
    },
  };
}

function textLayerRefreshActiveStyles() {
  /**
   * getActiveTextSelectionStyles fetches full
   * text selection styles, meaning that Fabricjs returns
   * a Fabric.js style object that has also any root style
   * attributes added to it
   */
  const styles = getActiveTextSelectionStyles();
  return applyActiveStyles(styles);
}

// function textLayerRefreshActiveAttributes() {
//   const attributes = getActiveTextSelectionAttributes();
//   return applyActiveAttributes(attributes);
// }

/**
 * Get styles in reducer and make it listen to multiple
 * different actions for the refresh event to happen
 */
function textLayerEditEnter(itemId) {
  return (dispatch) => {
    // dispatch(textLayerRefreshActiveStyles());
    // dispatch(textLayerRefreshActiveAttributes());
    dispatch(layerEditEnter(itemId));
  };
}

function textLayerEditExit() {
  return {
    type: types.TEXTLAYER_EDIT_EXIT,
    autosave: true,
  };
}

function compositionTextModify(item, id) {
  return {
    type: types.COMPOSITION_TEXT_MODIFY,
    autosave: true,
    undo: true,
    payload: {
      item,
      id,
    },
  };
}

function applyStyles(itemId, styles, attributes) {
  return {
    type: types.TEXTLAYER_APPLY_SELECTION_STYLES,
    autosave: true,
    undo: true,
    payload: {
      styles,
      attributes,
      itemId,
    },
  };
}

function applyAttribute(itemId, attributeObject) {
  return {
    type: types.TEXTLAYER_APPLY_ATTRIBUTE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      attributeObject,
    },
  };
}

// used when pasting styles
function applyAttributes(itemId, attributesObject) {
  return {
    type: types.TEXTLAYER_APPLY_ATTRIBUTES,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      attributesObject,
    },
  };
}

function applyAnimation(itemId, animationSetting, confirmResizing) {
  return {
    type: types.TEXTLAYER_APPLY_ANIMATION,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      animationSetting,
      confirmResizing,
    },
    notify: {
      message: animationSetting ? 'Animation applied successfully' : 'Animation removed successfully',
      kind: animationSetting ? 'success' : 'danger',
    },
  };
}

function applyAttributesAll(itemId) {
  return {
    type: types.TEXTLAYER_APPLY_ATTRIBUTES_ALL,
    autosave: true,
    undo: true,
    payload: {
      itemId,
    },
    notify: {
      message: 'Title styles have applied to all Titles',
      kind: 'success',
    },
  };
}

function textLayerReorderZindexes(config) {
  return {
    type: types.TEXTLAYER_REORDER_ZINDEXES,
    autosave: true,
    undo: true,
    payload: {
      config,
    },
  };
}

function textLayerReorderAnimationOrder(config) {
  return {
    type: types.TEXTLAYER_REORDER_ANIMATION_ORDER,
    autosave: true,
    undo: true,
    payload: {
      config,
    },
  };
}

function textLayerToggleVisible(layerItem) {
  return {
    type: types.TEXTLAYER_TOGGLE_VISIBLE,
    autosave: true,
    undo: true,
    payload: {
      layerItem,
    },
  };
}

function textLayerSeekToNonAnimating(textInstanceId) {
  return async (dispatch, getState) => {
    const { currentTime } = getState().timeline;
    const { textInstances } = getState().composition;
    // The following fixes an error: .filter of undefined
    if (!textInstances) {
      return;
    }
    const [currentTextInstance] = textInstances
      .filter(instance => instance.id === textInstanceId);

    const currentEngineLayer = renderHelper.engine.layers
      .filter(layer => layer.id === currentTextInstance.id)[0];

    const isActivelyAnimating = currentEngineLayer.isActivelyAnimating();
    // If text is animating, jump to nearest time when animation is over
    if (isActivelyAnimating) {
      const newTime = getNearestNonAnimatedTime(currentTextInstance, currentTime);
      await renderHelper.seek(newTime);
      dispatch(timelineProgressJump(newTime));
      const object = getFabricObjectByTextLayerId(currentTextInstance.id);
      selectObject(object);
    }
    // If we're not editing the text, then update position grid state to indicate text is moving
    if (!currentEngineLayer.textElement.isEditing) {
      dispatch(applyActiveAttributes({ isMoving: true }));
    }
  };
}

/**
 * Apply a attribute (that cant be achieved trough style object)
 * to a text object. (textAlign...)
 *
 * @param {String} itemId - Textobject id in composition
 * @param {Object} attributeObject - example: {key: 'textAlign', value: 'left'}
 */
function textLayerApplyAttribute(itemId, attributeObject) {
  return (dispatch) => {
    dispatch(applyAttribute(itemId, attributeObject));
    dispatch(applyActiveAttribute(attributeObject));
  };
}

function textLayerApplyAttributes(itemId, attributesObject) {
  return (dispatch) => {
    dispatch(applyAttributes(itemId, attributesObject));
  };
}

/**
 * Sets selection styles to text, then fetches the end result (style object) and
 * updates it to redux store, which triggers re-render of the canvas.
 * Note: relies on the fact that canvas doesn't re-render on changes (fabricjs settings)
 *
 * @param {Object} styles - Fabricjs styles object
 */
function textLayerApplySelectionStyles(textLayerId, styles) {
  return (dispatch) => {
    // apply styles object or properties and return a
    // styles object (cleared in case attributes were
    // set)
    const {
      styles: newStyles,
      attributes: newAttributes,
    } = applyStylesToSelectedText(styles);

    dispatch(applyStyles(textLayerId, newStyles, newAttributes));
    dispatch(textLayerRefreshActiveStyles(newStyles, newAttributes));
  };
}

export {
  textLayerMove,
  textLayerScale,
  textLayerRotate,
  textLayerSmartResize,
  textLayerEditEnter,
  textLayerEditExit,
  textLayerSeekToNonAnimating,
  textLayerApplySelectionStyles,
  textLayerApplyAttribute,
  textLayerApplyAttributes,
  textLayerRefreshActiveStyles,
  textLayerChange,
  compositionTextModify,
  applyAnimation,
  applyAttributes,
  applyAttributesAll,
  textLayerReorderZindexes,
  textLayerReorderAnimationOrder,
  textLayerToggleVisible,
};
