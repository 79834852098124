import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.scss';

const propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ProgressBar = ({
  max,
  value,
}) => {
  const style = {
    width: `${(value / max) * 100}%`,
  };

  return (
    <div className="ProgressBar">
      <div
        className="ProgressBar__progress"
        style={style}
      >
      </div>
    </div>
  );
};

ProgressBar.propTypes = propTypes;

export default ProgressBar;
