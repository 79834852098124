import React from 'react';
import { connect } from 'react-redux';

import { timelineTogglePlay } from '@actions/timeline';
import {
  textLayerMove,
  textLayerScale,
  textLayerRotate,
  textLayerSmartResize,
  textLayerEditEnter,
  textLayerEditExit,
  textLayerRefreshActiveStyles,
  textLayerChange,
  textLayerApplyAttribute,
  applyAnimation,
} from '@actions/textlayer';
import { sidebarSelectShape, sidebarDeSelectShape, sidebarToolbarActive, sidebarShowAdjusters } from '@actions/sidebar';
import {
  compositionLayerAdd,
  compositionLayerModify,
  compositionLayerEditEnter,
  compositionLayerEditExit,
  compositionBackgroundInstanceModify,
  requestCompositionLoad,
  compositionLoadSuccess,
  compositionTextInstanceRemove,
  // compositionUpdateInitial,
  compositionUpdateShapeLayer,
  compositionRemoveShapeLayer,
  compositionAddShapeLayer,
  // textInstanceAdd,
} from '@actions/composition';
import { setGuides, appToggleShortcuts, triggerNotification } from '@actions/app';

import VideoPreview from '@components/VideoPreview';

const VideoPreviewContainer = props => <VideoPreview {...props} />;

const mapStateToProps = (state) => {
  const { timeline, app, sidebar } = state;
  const { toolbarActive } = sidebar;
  const composition = state.composition.present;
  const { showGuideX, showGuideY, shortcutsOpen } = app;
  const { buffering, playing, currentTime } = timeline;
  const {
    backgroundInstances,
    audioInstances,
    textInstances,
    titleInstances,
    shapeInstances,
    duration,
    uid,
    layers,
    width,
    height,
    source,
  } = composition;

  return {
    composition,
    buffering,
    playing,
    currentTime,
    backgroundInstances,
    audioInstances,
    toolbarActive,
    textInstances,
    titleInstances,
    shapeInstances,
    duration,
    activeTextId: sidebar.activeTextId,
    activeLayerId: sidebar.activeLayerId,
    selectedShapes: sidebar.selectedShapes,
    uid,
    layers,
    width,
    height,
    showGuideX,
    showGuideY,
    shortcutsOpen,
    compositionSource: source,
  };
};

export default connect(mapStateToProps, {
  onTogglePlay: timelineTogglePlay,
  onTextLayerMove: textLayerMove,
  onTextLayerScale: textLayerScale,
  onTextLayerRotate: textLayerRotate,
  onTextLayerSmartResize: textLayerSmartResize,
  onTextLayerEditEnter: textLayerEditEnter,
  onTextLayerEditExit: textLayerEditExit,
  onTextLayerChange: textLayerChange,
  onApplyAttribute: textLayerApplyAttribute,
  onLayerEditEnter: compositionLayerEditEnter,
  onLayerEditExit: compositionLayerEditExit,
  onLayerModify: compositionLayerModify,
  onBgModify: compositionBackgroundInstanceModify,
  onTriggerNotification: triggerNotification,
  onSelectionChanged: textLayerRefreshActiveStyles,
  onDropOverlay: compositionLayerAdd,
  onVideoLoad: requestCompositionLoad,
  onVideoReady: compositionLoadSuccess,
  onSetGuides: setGuides,
  onToggleShortcuts: appToggleShortcuts,
  onApplyAnimation: applyAnimation,
  onTextRemove: compositionTextInstanceRemove,
  // compositionUpdateInitial,
  onUpdateShape: compositionUpdateShapeLayer,
  onSelectShape: sidebarSelectShape,
  onDeSelectShape: sidebarDeSelectShape,
  onRemoveShape: compositionRemoveShapeLayer,
  onAddShape: compositionAddShapeLayer,
  onSidebarAdjusters: sidebarShowAdjusters,
  sidebarToolbarActive,
  // textInstanceAdd,
})(VideoPreviewContainer);
