import * as types from '@constants/actionTypes';

const initialState = {
  brandcolor: [
    {
      id: Date.now(),
      name: 'Pallet 1',
      colors: ['#f5336d'],
    },
  ],
  brandlogo: 'initial',
  brandfont: {},
};

export default function brandKitReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_SET_BRANDKIT: {
      const { brandkit } = action.payload;
      if (brandkit) {
        return {
          ...state,
          ...brandkit,
        };
      }
      return state;
    }
    case types.BRAND_ADD_PALLET: {
      const newPallet = {
        ...initialState.brandcolor[0],
        name: `Palette ${state.brandcolor.length + 1}`,
        id: Date.now(),
      };

      return {
        ...state,
        brandcolor: [...state.brandcolor, newPallet],
        change: 'brandcolor',
      };
    }

    case types.BRAND_REMOVE_PALLET: {
      const { palletId } = action.payload;
      const newPallets = state.brandcolor.filter(pallet => pallet.id !== palletId);
      return {
        ...state,
        brandcolor: newPallets,
        change: 'brandcolor',
      };
    }

    case types.BRAND_ADD_COLOR: {
      const { palletId } = action.payload;
      const newPallets = state.brandcolor.map((pallet) => {
        if (pallet && pallet.id === palletId && pallet.colors.length < 6) {
          return {
            ...pallet,
            colors: [...pallet.colors, pallet.colors[0]],
          };
        }
        return pallet;
      });
      return {
        ...state,
        brandcolor: newPallets,
        change: 'brandcolor',
      };
    }

    case types.BRAND_UPDATE_COLOR: {
      const { color, colorIndex, palletId } = action.payload;
      const newPallets = state.brandcolor.map((pallet) => {
        if (pallet.id !== palletId) return pallet;
        const newColors = pallet.colors.map((clr, i) => {
          if (i !== colorIndex) return clr;
          const {
            r, g, b, a,
          } = color;
          return `rgba(${r}, ${g}, ${b},${a})`;
        });
        return {
          ...pallet,
          colors: newColors,
        };
      });
      return {
        ...state,
        brandcolor: newPallets,
        change: 'brandcolor',
      };
    }

    case types.BRAND_REMOVE_COLOR: {
      const { colorIndex, palletId } = action.payload;
      const newColors = state.brandcolor.map((pallet) => {
        if (pallet.id !== palletId) return pallet;
        return {
          ...pallet,
          colors: pallet.colors.filter((color, index) => index !== colorIndex),
          change: 'brandcolor',
        };
      });
      return {
        ...state,
        brandcolor: newColors,
        change: 'brandcolor',
      };
    }

    case types.BRAND_SET_FONT: {
      const { brandFonts } = action.payload;
      return {
        ...state,
        brandFonts,
        change: 'brandfont',
      };
    }

    case types.BRAND_SET_LOGO: {
      const { brandLogo } = action.payload;
      return {
        ...state,
        brandlogo: brandLogo,
        change: 'brandlogo',
      };
    }
    case types.USER_RECEIVE: {
      const { brandkit } = action.payload;
      return {
        ...state,
        ...brandkit,
      };
    }

    default:
      return state;
  }
}
