import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@components/ProgressBar';
import './DesignThumbnail.scss';

const propTypes = {
  onDesignLoad: PropTypes.func.isRequired,
  design: PropTypes.object.isRequired,
  // isNewComposition: PropTypes.bool,
  activeProjectUid: PropTypes.string,
  onAddComposition: PropTypes.func,
  getDimensions: PropTypes.func,
  muted: PropTypes.bool,
  dimensions: PropTypes.object,
};

function DesignThumbnail(props) {
  const {
    onDesignLoad, design, muted, getDimensions,
  } = props;
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState();
  const [dimensions, setDimentions] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const videoElementRef = useRef();
  let throttleTimer;
  const resizeThumbnails = () => {
    setDimentions(getDimensions(design.content));
  };

  const handleLoadedMetadata = () => {
    if (videoElementRef && videoElementRef.current) {
      setDuration(videoElementRef.current.duration);
      videoElementRef.current.currentTime = '3';
    }
  };

  const handleTimeUpdate = () => {
    if (videoElementRef && videoElementRef.current) {
      setCurrentTime(videoElementRef.current.currentTime);
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(throttleTimer);
    throttleTimer = setTimeout(() => {
      setShowPreview(true);
    }, 500);
  };

  const handleMouseLeave = async () => {
    clearTimeout(throttleTimer);
    if (videoElementRef && videoElementRef.current) {
      setShowPreview(false);
    }
  };

  const renderProgressBar = () => {
    if (!duration) return null;
    return <ProgressBar max={duration} value={currentTime} />;
  };

  useEffect(() => {
    setDimentions(getDimensions(design.content));
    if (videoElementRef.current) {
      videoElementRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElementRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    window.addEventListener('resize', resizeThumbnails, false);
    // resize video thumbnails
    return () => {
      if (videoElementRef.current) {
        videoElementRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoElementRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }

      window.removeEventListener('resize', resizeThumbnails);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="DesignThumbnail"
      style={{ width: dimensions && dimensions.width, height: dimensions && dimensions.height }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onDesignLoad()}
    >
      <div className="DesignThumbnail__videoContainer">
        {showPreview && (
          <video
            muted={muted}
            loop={true}
            autoPlay={true}
            preload="auto"
            className="DesignThumbnail__video"
            ref={videoElementRef}
            width="100%"
            height="100%"
          >
            <source src={design.filename} type="video/mp4" />
          </video>
        )}
        <img src={`${design.filename.split('_')[0]}_thumb_sd.jpeg`} width="100%" height="100%" alt="thumb" />
        {renderProgressBar()}
        {!showPreview && <p className="DesignThumbnail_name">{design.name || 'Untitled'}</p>}
      </div>
    </div>
  );
}

DesignThumbnail.propTypes = propTypes;

export default DesignThumbnail;
