import { RenderEngine } from '@lib/render-engine';
import { fabric } from 'fabric';

export const round = (value, decimals) =>
  Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);


export class RenderHelper {
  constructor() {
    this.engine = null;
    this.canvas = null;
  }

  init(compositionDuration, eventHandlers = {}) {
    if (this.canvas) {
      this.canvas.dispose();
      this.canvas = null;
      this.engine = null;
    }

    this.canvas = new fabric.Canvas('canvas', {
      preserveObjectStacking: true,
      backgroundColor: 'black',
      enableRetinaScaling: false,
    });

    this.canvas.setDimensions(
      {
        width: 'auto',
        height: 'auto',
        top: 'auto',
        left: 'auto',
      },
      {
        cssOnly: true,
      },
    );

    this.canvas.observe('object:moving', (e) => {
      const obj = e.target;
      obj.setCoords();
      const b = obj.getBoundingRect();
      const {
        minX, minY, maxX, maxY,
      } = obj;
      if (minY >= b.top) obj.top = minY;
      if (maxY <= b.top) obj.top = maxY;
      if (minX >= b.left) obj.left = minX;
      if (maxX <= b.left) obj.left = maxX;
    });
    this.canvas.observe('mouse:dblclick', (e) => {
      if (e.target && e.target.moveLocks) {
        e.target.lockMovementX = e.target.moveLocks.x;
        e.target.lockMovementY = e.target.moveLocks.y;
      }
    });

    this.engine = new RenderEngine(this.canvas, compositionDuration, eventHandlers);

    // TODO: remove
    window.engine = this.engine;

    return this.engine;
  }

  resetEngine(compositionDuration) {
    this.engine.clear();
    this.engine.duration = compositionDuration;
    return this.engine;
  }

  async play() {
    await this.engine.play();
  }

  async pause() {
    await this.engine.pause();
  }

  time() {
    return this.engine.currentTimestamp;
  }

  async seek(time) {
    if (this.engine) {
      await this.engine.seek(time);
    }
  }
}

export const renderHelper = new RenderHelper();
