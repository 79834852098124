/**
 * Removes an entry by its uid
 * @param {array} entries array of reducer entries
 * @param {string} uid entry to be removed
 */
const removeEntryByUid = (entries, uid) => entries
  .map(page =>
    page.filter(entry => entry.uid !== uid));

export {
  removeEntryByUid,
};
