import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
// import classNames from 'classnames';
import { TrashCan } from '@components/Icons';


import './Trash.scss';

const propTypes = {
  visible: PropTypes.bool,
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
};
const defaultProps = {
  visible: false,
};

const instanceTarget = {
  hover(props, monitor) {
    document.getElementsByClassName('Trash')[0].style.opacity = 1;
  },
  drop(targetProps, monitor) {
    document.getElementsByClassName('Trash')[0].style.opacity = 0;
    const sourceProps = monitor.getItem();
    const { id } = sourceProps;
    if (sourceProps.onDelete) {
      sourceProps.onDelete(id);
    }
  },
};

const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
});

class TrashArea extends React.Component {
  render() {
    const {
      // visible,
      connectDropTarget,
      // isOver,
    } = this.props;

    return connectDropTarget(
      <div className='Trash'>
        <TrashCan/>
      </div>,
    );
  }
}

TrashArea.propTypes = propTypes;
TrashArea.defaultProps = defaultProps;

export default DropTarget(['instance'], instanceTarget, targetCollect)(TrashArea);
