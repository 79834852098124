import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Pool from '@components/NewTimeline/Pool';
import Ticker from '@components/Ticker';
import { getUniqueId } from '@helpers/footage';
import { getNewInstanceTimeslot } from '@helpers/composition';
import { Scissors } from '@components/Icons';
import KeyboardShortcuts from '@components/KeyboardShortcuts';
import Ruler from '../Timeline/Ruler';
import Scrubber from '../Timeline/Scrubber';
import './NewTimeline.scss';
import CustomDragLayer from '../DraggableFootage/CustomDragLayer';

const propTypes = {
  composition: PropTypes.object,
  activeBackgroundId: PropTypes.number,
  activeTextId: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
  ]),
  activeLayerId: PropTypes.number,
  selectedShapes: PropTypes.array,
  onRemove: PropTypes.func,
  onShapeRemove: PropTypes.func,
  onLayerRemove: PropTypes.func,
  onProgressJump: PropTypes.func,
  onTextRemove: PropTypes.func,
  onLayerSelect: PropTypes.func,
  onTextMove: PropTypes.func,
  onAddTextLayer: PropTypes.func,
  onAddLayer: PropTypes.func,
  onAddShape: PropTypes.func,
  expandPool: PropTypes.func,
  onProgressSeek: PropTypes.func,
  onUpdateShape: PropTypes.func,
  onDuplicateInstances: PropTypes.func,
  onRazorVideo: PropTypes.func,
  timeline: PropTypes.object,
  formatBackgroundInstances: PropTypes.func,
};

const NewTimeline = (props) => {
  const {
    composition,
    timeline,
    activeBackgroundId,
    activeTextId,
    activeLayerId,
    selectedShapes,
    onRemove,
    onShapeRemove,
    onLayerRemove,
    onTextRemove,
    onLayerSelect,
    onAddTextLayer,
    onProgressJump,
    onAddLayer,
    onAddShape,
    onSelectShape,
    onDuplicateInstances,
    expandPool,
  } = props;


  const handleDuplicateInstances = () => {
    const uniqueId = getUniqueId();
    if (activeTextId) {
      activeTextId.forEach((selectedId) => {
        const txt = composition.textInstances.find(t => t.id === selectedId);
        const timeslot = getNewInstanceTimeslot(
          composition.titleInstances,
          txt.visibleTo - txt.visibleFrom,
        );
        onAddTextLayer({
          ...txt,
          id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
          duration: timeslot.visibleTo - timeslot.visibleFrom,
          visibleFrom: timeslot.visibleFrom,
          visibleTo: timeslot.visibleTo,
        });
      });
    }
    if (activeBackgroundId) {
      onDuplicateInstances([activeBackgroundId]);
    }
    if (selectedShapes.length > 0) {
      const shape = composition.shapeInstances.find(s => s.id === selectedShapes[0]);
      onAddShape({
        ...shape,
        src: shape.src,
        id: uniqueId,
        type: 'shape',
        left: shape.left + 10,
        top: shape.top + 15,
        visibleFrom: shape.visibleTo + 5,
        visibleTo: shape.visibleTo + 2 + (shape.visibleTo - shape.visibleFrom),
        ang: 0,
      });
      onSelectShape(uniqueId);
    }
    if (activeLayerId) {
      const layer = composition.layers.find(l => l.id === activeLayerId);
      onAddLayer({
        ...layer,
        id: getUniqueId(),
        visibleFrom: layer.visibleTo + 1,
        visibleTo: layer.visibleTo + 1 + layer.duration,
        duration: layer.duration,
        top: layer.top,
        left: layer.left,
        thumbnail: layer.thumbnail,
        src: layer.src,
        type: layer.type,
        width: layer.width,
        height: layer.height,
        scaleY: layer.scaleY,
        scaleX: layer.scaleX,
        originX: 'center',
        originY: 'center',
        alwaysVisible: true,
      });
    }
  };

  const slice = () => {
    const { currentTime } = props.timeline;
    if (activeBackgroundId) {
      const activeBackground = composition.backgroundInstances.filter(
        s => s.id === activeBackgroundId,
      );
      if ((activeBackground[0].visibleFrom > currentTime) ||
      (activeBackground[0].visibleTo < currentTime)) {
        return;
      }
      props.onRazorVideo();
    } else if (selectedShapes.length > 0) {
      const activeShape = composition.shapeInstances.filter(s => s.id === selectedShapes[0]);
      const slicePoint = props.timeline.currentTime;
      if (activeShape.visibleFrom > currentTime || activeShape.visibleTo < currentTime) {
        return;
      }
      onAddShape({
        src: activeShape[0].src,
        id: getUniqueId(),
        type: 'shape',
        left: activeShape[0].left,
        top: activeShape[0].top,
        track: activeShape[0].track ? activeShape[0].track : 4,
        visibleFrom: Math.floor(slicePoint) + 1,
        visibleTo: Math.floor(activeShape[0].visibleTo),
        ang: 0,
      });
      props.onUpdateShape({
        id: activeShape[0].id,
        visibleFrom: activeShape[0].visibleFrom,
        visibleTo: Math.floor(slicePoint),
        track: activeShape[0].track,
      });
    } else if (activeLayerId) {
      const activeLayer = composition.layers.filter(s => s.id === activeLayerId);
      if (activeLayer[0].visibleFrom > currentTime || activeLayer[0].visibleTo < currentTime) {
        return;
      }
      const slicePoint = props.timeline.currentTime;
      props.onUpdateLayer(
        {
          id: activeLayer[0].id,
          visibleFrom: activeLayer[0].visibleFrom,
          visibleTo: slicePoint,
          track: activeLayer[0].track,
        },
        activeLayer[0].id,
      );
      onAddLayer({
        id: getUniqueId(),
        visibleFrom: slicePoint,
        visibleTo: activeLayer[0].visibleTo,
        duration: activeLayer[0].visibleTo - activeLayer[0].visibleFrom,
        top: activeLayer[0].top,
        left: activeLayer[0].left,
        thumbnail: activeLayer[0].thumbnail,
        src: activeLayer[0].src,
        type: activeLayer[0].type,
        width: activeLayer[0].width,
        height: activeLayer[0].height,
        scaleY: activeLayer[0].scaleY,
        scaleX: activeLayer[0].scaleX,
        originX: 'center',
        originY: 'center',
        alwaysVisible: true,
        track: activeLayer[0].track,
      });
    }
  };

  const handleBackspaceDeletion = () => {
    if (props.activeLayerId) {
      onLayerRemove(activeLayerId);
    }
    if (props.activeTextId) {
      activeTextId.forEach(id => onTextRemove(id));
    }
    if (props.activeBackgroundId) {
      onRemove(activeBackgroundId);
    }
    if (props.selectedShapes.length > 0) {
      selectedShapes.forEach((s) => {
        onShapeRemove(s);
      });
    }
    // deSelectInstances();
  };

  const trackRef = useRef(null);
  const changeTrackRef = (newTrack, ref) => {
    ref.current = newTrack;
  };

  const [tlWidth, setTlWidth] = useState(null);
  const [tlLeft, setTlLeft] = useState(null);
  const [trashVisible, setTrashVisible] = useState(false);
  const shortcuts = [
    {
      keys: 'backspace',
      fn: handleBackspaceDeletion,
    },
    {
      keys: 'd',
      fn: handleDuplicateInstances,
    },
  ];


  const renderScrubber = (msPxRatio, time) => (
      <Scrubber
        onProgressJump={onProgressJump}
        currentTime={timeline.currentTime}
        onProgressSeek={props.onProgressSeek}
        internalTime={time}
        duration={composition.duration}
        msPxRatio={msPxRatio}
        isPlaying={timeline.playing}
      />
  );
  // if (!duration) { return null; }
  return (
    <div className="timeline">
      <KeyboardShortcuts shortcuts={shortcuts} />
      <CustomDragLayer
        trackRef={trackRef}
        expandPool={expandPool}
        expandedPool={timeline.expandedPool}
        />
      <div className="poolContainer" style={{ maxHeight: '25px' }}>
        <div className="pool-icon" onClick={() => { slice(); }}>
          <div className="scissors">
            <Scissors />
          </div>
        </div>
        <div className="pool-wrapper ticker" style={{ left: '6%' }}>
          <Ticker
            currentTime={props.timeline.currentTime}
            isPlaying={props.timeline.playing}
            compositionDuration={composition.duration}
            onProgressJump={props.onProgressJump}
            msPxRatio={tlWidth / composition.duration}
            render={tickerTime => (
              <React.Fragment>
                <div className="Timeline__row">
                  <div
                    className="Timeline__cell--fullwidth"
                    ref={tlWidth}
                  >
                    <Ruler
                      duration={composition.duration}
                      currentTime={props.timeline.currentTime}
                      internalTime={tickerTime}
                      isPlaying={props.timeline.playing}
                    />
                  </div>
                </div>
                <div className="Timeline__row">
                  <div className="Timeline__cell--fullwidth">
                    {renderScrubber(
                      tlWidth / composition.duration,
                      tickerTime,
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}>
          </Ticker>
        </div>
      </div>
          {/* overlays pool */}
          <Pool
            composition={composition}
            timelineWidth={tlWidth}
            timelineLeft={tlLeft}
            setTlWidth={setTlWidth}
            setTlLeft={setTlLeft}
            setTrashVisible={setTrashVisible}
            trashVisible={trashVisible}
            trackRef={trackRef}
            changeTrackRef={changeTrackRef}
            index={0}
            type='overlays'

            onClick={() => timeline.expandedPool !== 0 && props.expandPool(0)}
            expanded={timeline.expandedPool === 0}

            onTextResize={props.onTextResize}
            onOverlayMove={props.onTextMove}
            onTextRemove={props.onTextRemove}
            onAddTextLayer={props.onAddTextLayer}
            onTextLayerSelect={props.onTextLayerSelect}
            onAddShape={props.onAddShape}
            onAddLayer={props.onAddLayer}
            onReplace={props.onReplace}
            onSelectShape={props.onSelectShape}
            onLayerSelect={onLayerSelect}
            onLayerRemove={onLayerRemove}
            onSelect={props.onSelect}
            onUpdateShape={props.onUpdateShape}
            onUpdateLayer={props.onUpdateLayer}
            onProgressJump={onProgressJump}
            onDelete={props.onRemove}
            onShapeRemove={onShapeRemove}
            onDrag={props.onDrag}

            activeBackgroundId={activeBackgroundId}
            activeTextId={activeTextId && activeTextId[0]}
            activeLayerId={activeLayerId}
            activeShapeId={selectedShapes[0]}
            formatBackgroundInstances={props.formatBackgroundInstances}
          />
          {/* background pool */}
          <Pool
            composition={composition}
            timelineWidth={tlWidth}
            timelineLeft={tlLeft}
            setTlWidth={setTlWidth}
            setTlLeft={setTlLeft}
            setTrashVisible={setTrashVisible}
            trashVisible={trashVisible}
            trackRef={trackRef}
            changeTrackRef={changeTrackRef}
            index={1}
            type='background'

            onClick={() => timeline.expandedPool !== 1 && props.expandPool(1)}
            expanded={timeline.expandedPool === 1}
            onVideoResize={props.onVideoResize}
            onBackgroundMove={props.onMove}
            onAdd={props.onAdd}
            onReplace={props.onReplace}
            onSelect={props.onSelect}
            onProgressJump={onProgressJump}
            onDelete={props.onRemove}
            onDrag={props.onDrag}

            activeBackgroundId={activeBackgroundId}
            activeTextId={activeTextId && activeTextId[0]}
            activeLayerId={activeLayerId}
            activeShapeId={selectedShapes[0]}
            formatBackgroundInstances={props.formatBackgroundInstances}
          />
    </div>
  );
};

NewTimeline.propTypes = propTypes;

NewTimeline.defaultProps = {
};

export default NewTimeline;
