import React, { useState, useEffect } from "react";

const IMAGE_DURATION = 100; // Duration of each image in milliseconds

export default function VideoPreview({ videoUrl }) {
    return (
        <video controls style={{ width: 480, borderRadius: 10, marginTop: 15 }}>
            <source src={videoUrl} type="video/mp4" />
        </video>
    );
}
