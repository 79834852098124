import { seekTimelines } from '@lib/animeTimelines';
import TextLayer from './TextLayer';

export default class RenderEngine {
  layers = []
  audioLayers = []
  playing = false
  currentTimestamp = 0

  constructor(canvas, duration, eventHandlers = {}) {
    this.canvas = canvas;
    this.duration = duration;
    this.eventHandlers = eventHandlers;
  }

  get allLayers() {
    return [...this.layers, ...this.audioLayers];
  }

  async addLayer(layer) {
    this.layers.push(layer);
    layer.renderEngine = this;
    const objects = layer.getObjects();
    if (objects.length > 0) {
      this.canvas.add(...objects);
      await this.seek(this.currentTimestamp);
    }
  }

  async addAudioLayer(layer) {
    this.audioLayers.push(layer);
    await this.seek(this.currentTimestamp);
  }

  async removeLayer(layer) {
    if (!layer) return;
    this.layers = this.layers.filter(l => l !== layer);
    this.canvas.remove(...layer.getObjects());
    await this.seek(this.currentTimestamp);
  }

  update(timestamp) {
    for (const layer of this.allLayers) {
      if (timestamp <= this.duration) {
        layer.update(timestamp);
      }
    }
    if (this.canvas) this.canvas.renderAll();
  }

  async seek(timestamp) {
    this.currentTimestamp = timestamp;
    await Promise.all(this.allLayers.map(layer => layer.seek(timestamp)));
    this.canvas && this.canvas.renderAll();
    if (window.audioController && window.audioTracks) {
      const audioObj = window.audioTracks.find(clip => clip.audibleFrom <= timestamp && clip.audibleTo >= timestamp);
      if (audioObj) {
        audioObj.audioFile.currentTime = timestamp / 1000;
      }
    }
    seekTimelines(this.currentTimestamp);
  }

  async refresh() {
    await Promise.all(this.allLayers.map(layer => layer.seek(this.currentTimestamp)))
    this.canvas.renderAll()
  }

  async refreshTextLayers() {
    await Promise.all(this.allLayers
      .filter(layer => layer instanceof TextLayer)
      .map(textLayer => textLayer.seek(this.currentTimestamp))
    )
    this.canvas.renderAll()
  }

  step = (renderTimestamp) => {
    if (!this.renderStart) {
      this.renderStart = renderTimestamp - this.currentTimestamp
    }

    const timestamp = renderTimestamp - this.renderStart
    this.currentTimestamp = timestamp

    this.update(timestamp)

    if (timestamp < this.duration) {
      this.handle = window.requestAnimationFrame(this.step)
    } else {
      this.currentTimestamp = this.duration
      this.pause()

      if (this.eventHandlers.onEnd) {
        this.eventHandlers.onEnd()
      }
    }
  }

  async play() {
    if (this.playing) {
      return
    }

    await Promise.all(this.allLayers.map(l => l.ready()))

    // https://github.com/fabricjs/fabric.js/wiki/Optimizing-performance
    this.canvas.selection = false
    this.canvas.skipTargetFind = true
    this.playing = true
    this.handle = window.requestAnimationFrame(this.step)
  }

  async pause() {
    if (!this.playing) {
      return
    }
    seekTimelines(this.currentTimestamp);
    window.engine.canvas.renderAll();
    this.renderStart = null
    this.canvas.selection = true
    this.canvas.skipTargetFind = false
    await Promise.all(this.allLayers.map(layer =>
      layer.pause && layer.pause()
    ))

    this.playing = false
    cancelAnimationFrame(this.handle)
  }

  clear() {
    if (this.canvas) {
      this.canvas.clear()
      this.layers = []
      this.audioLayers = []
    }
  }

  updateStack() {
    //this.canvas.clear()
    for (const layer of this.layers) {
      const objects = layer.getObjects()
      if (objects.length > 0) {
        this.canvas.add(...objects)
      }
    }
  }
}
