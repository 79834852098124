/* eslint-disable no-mixed-operators */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
// easeOutElastic(3, 0.3)
import { getLayerById } from '@lib/fabricHelper';
import './animations.scss';

export function noAnimation({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  window[animeJsController].add({
    targets: `${selector} .backgroundColorLayer`,
    duration: 1,
    opacity: [0, 1],
    easing: 'linear',
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .letter`,
    duration: 1,
    opacity: [0, 1],
    easing: 'linear',
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .letter`,
    duration: 1,
    opacity: [1, 0],
    easing: 'linear',
  }, visibleTo);
  window[animeJsController].add({
    targets: `${selector} .backgroundColorLayer`,
    duration: 1,
    opacity: [1, 0],
    easing: 'linear',
  }, visibleTo);
}
const getIdealAnimationDuration = (duration, transitionLength) => {
  if (duration > 2 * transitionLength) {
    return transitionLength;
  }
  return 0.25 * duration;
};

// FADE
export function fadeIn({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 500);

  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      easing: 'linear',
      round: 100,
    }, visibleFrom - transitionLength);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    easing: 'linear',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    easing: 'linear',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      easing: 'linear',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// SWEEP CHARS
export function sweepInChars({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      width: ['0%', '100%'],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [0, 1],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [1, 0],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleTo - (transitionLength * 2));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      width: ['100%', '0%'],
      marginLeft: ['0%', '100%'],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// ROLL LETTERS
export function rollInOutChars({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      width: ['0%', '100%'],
      marginLeft: ['100%', '0%'],
      easing: 'easeOutExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [0, 1],
    translateX: [500, 0],
    easing: 'easeOutExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [1, 0],
    translateX: [0, -500],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleTo - (transitionLength * 2));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      width: ['100%', '0%'],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// RISING LETTERS
export function risingInOutChars({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      translateY: [100, 0],
      opacity: [0, 1],
      easing: 'easeOutExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [0, 1],
    translateY: [300, 0],
    easing: 'easeOutExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [1, 0],
    translateY: [0, -300],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleTo - (transitionLength * 2));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      translateY: [0, -100],
      opacity: [1, 0],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// DROPPING WORDS
export function droppingInOutWords({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      translateY: [-300, 0],
      opacity: [0, 1],
      easing: 'easeOutElastic(3, 0.3)',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .word`,
    opacity: [0, 1],
    translateY: [-300, 0],
    easing: 'easeOutElastic(1, 0.3)',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .word`,
    opacity: [1, 0],
    translateY: [0, 0],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// FADE LINES
export function fadeInOutLines({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [0, 1],
      easing: 'easeOutExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    opacity: [0, 1],
    easing: 'easeOutExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    opacity: [1, 0],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleTo - (transitionLength * 2));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// ZOOM IN-OUT
export function zoomInOut({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.5, 1],
      easing: 'easeOutCubic',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0, 1],
    easing: 'easeOutCubic',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0],
    easing: 'easeInCubic',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.5],
      easing: 'easeInCubic',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// SLIDE LEFT-RIGHT
export function slideLeftRight({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.3, 1],
      translateX: [-2500, 0],
      easing: 'easeOutCubic',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0.3, 1],
    translateX: [-2500, 0],
    easing: 'easeOutCubic',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0.3],
    translateX: [0, 2500],
    easing: 'easeInCubic',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.3],
      translateX: [0, 2500],
      easing: 'easeInCubic',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// SLIDE RIGHT-LEFT
export function slideRightLeft({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.3, 1],
      translateX: [2500, 0],
      easing: 'easeOutCubic',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0.3, 1],
    translateX: [2500, 0],
    easing: 'easeOutCubic',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0.3],
    translateX: [0, -2500],
    easing: 'easeInCubic',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.3],
      translateX: [0, -2500],
      easing: 'easeInCubic',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// SLIDE UP-DOWN
export function slideUpDown({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.3, 1],
      translateY: [-2000, 0],
      easing: 'easeOutCubic',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0.3, 1],
    translateY: [-2000, 0],
    easing: 'easeOutCubic',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0.3],
    translateY: [0, 2000],
    easing: 'easeInCubic',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.3],
      translateY: [0, 2000],
      easing: 'easeInCubic',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// SLIDE DOWN-UP
export function slideDownUp({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.3, 1],
      translateY: [2000, 0],
      easing: 'easeOutCubic',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0.3, 1],
    translateY: [2000, 0],
    easing: 'easeOutCubic',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0.3],
    translateY: [0, -2000],
    easing: 'easeInCubic',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.3],
      translateY: [0, -2000],
      easing: 'easeInCubic',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// BOUNCE IN-OUT
export function bounceInOut({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0, 1],
      easing: 'easeOutElastic(2, 0.3)',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0, 1],
    easing: 'easeOutElastic(2, 0.3)',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0],
    easing: 'easeInElastic(2, 0.3)',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0],
      easing: 'easeInElastic(2, 0.3)',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// BOUNCE HORIZONTAL
export function bounceHorizontal({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.3, 1],
      translateX: [-1500, 0],
      easing: 'easeOutElastic(2, 0.3)',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0.3, 1],
    translateX: [-1500, 0],
    easing: 'easeOutElastic(2, 0.3)',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0.3],
    translateX: [0, 1500],
    easing: 'easeInElastic(2, 0.3)',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.3],
      translateX: [0, 1500],
      easing: 'easeInElastic(2, 0.3)',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// BOUNCE VERTICAL
export function bounceVertical({
  selector, visibleFrom, visibleTo, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      duration: transitionLength,
      opacity: [0, 1],
      scale: [0.3, 1],
      translateY: [-2000, 0],
      easing: 'easeOutElastic(2, 0.3)',
      round: 100,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [0, 1],
    scale: [0.3, 1],
    translateY: [-2000, 0],
    easing: 'easeOutElastic(2, 0.3)',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    duration: transitionLength,
    opacity: [1, 0],
    scale: [1, 0.3],
    translateY: [0, 2000],
    easing: 'easeInElastic(2, 0.3)',
    round: 100,
  }, visibleTo - transitionLength);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.3],
      translateY: [0, 2000],
      easing: 'easeInElastic(2, 0.3)',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// BLUR CHARS
export function blurChars({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [0, 1],
      scale: [0.1, 1],
      filter: ['blur(64px)', 'blur(0px)'],
      easing: 'easeOutExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [0, 1],
    scale: [3, 1],
    filter: ['blur(32px)', 'blur(0px)'],
    easing: 'easeOutExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .letter`,
    opacity: [1, 0],
    scale: [1, 3],
    filter: ['blur(0px)', 'blur(32px)'],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleTo - (transitionLength * 2));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      opacity: [1, 0],
      scale: [1, 0.1],
      filter: ['blur(0px)', 'blur(64px)'],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// ROLL LINES
export function rollLines({
  selector, visibleFrom, visibleTo, length, controller,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      scaleX: [0, 1],
      opacity: [0, 1],
      easing: 'easeOutExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: `${selector} .line`,
    opacity: [0, 1],
    translateX: [-200, 0],
    easing: 'easeOutExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleFrom);
  window[animeJsController].add({
    targets: `${selector} .line`,
    opacity: [1, 0],
    translateX: [0, 200],
    easing: 'easeInExpo',
    round: 100,
    duration: transitionLength,
    delay: (el, i) => (transitionLength / length) * i,
  }, visibleTo - (transitionLength * 2));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      scaleX: [1, 0],
      opacity: [1, 0],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

// RUN NUMBER
export function runNumber({
  selector, visibleFrom, visibleTo, controller, textContent,
}) {
  const animeJsController = controller || 'animationController';
  const notThumbnail = animeJsController === 'animationController';
  const instanceDuration = visibleTo - visibleFrom;
  const transitionLength = getIdealAnimationDuration(instanceDuration, 800);
  const animatedObject = {
    number: '0',
  };
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      scaleX: [0, 1],
      opacity: [0, 1],
      easing: 'easeOutExpo',
      round: 100,
      duration: transitionLength,
    }, visibleFrom);
  }
  window[animeJsController].add({
    targets: selector,
    duration: 1,
    opacity: [0, 1],
    easing: 'linear',
    round: 100,
  }, visibleFrom);
  window[animeJsController].add({
    targets: animatedObject,
    number: notThumbnail ? textContent : '1000',
    duration: transitionLength * 2,
    easing: 'linear',
    round: 100,
    update: () => {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(Math.floor(animatedObject.number)) && document.querySelector(`${selector} .line`)) {
        document.querySelector(`${selector} .line`).innerHTML = Math.floor(animatedObject.number);
      }
    },
  }, visibleFrom);
  window[animeJsController].add({
    targets: selector,
    duration: transitionLength,
    opacity: [1, 0],
    easing: 'linear',
    round: 100,
  }, visibleTo - (transitionLength));
  if (notThumbnail) {
    window[animeJsController].add({
      targets: `${selector} .backgroundColorLayer`,
      scaleX: [1, 0],
      opacity: [1, 0],
      easing: 'easeInExpo',
      round: 100,
      duration: transitionLength,
    }, visibleTo - transitionLength);
  }
}

export function setThumbnailAnimation({ name, selector }) {
  switch (name) {
    // LEGACY ANIMS STARTS
    case 'fadein':
      fadeIn({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'sweepinchars':
      sweepInChars({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 10,
      });
      break;
    case 'rollinoutchars':
      rollInOutChars({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 10,
      });
      break;
    case 'risinginoutchars':
      risingInOutChars({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 10,
      });
      break;
    case 'droppinginoutwords':
      droppingInOutWords({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 2,
      });
      break;
    case 'fadeinoutlines':
      fadeInOutLines({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 2,
      });
      break;
    // LEGACY ANIMS END
    case 'zoomInOut':
      zoomInOut({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'slideLeftRight':
      slideLeftRight({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'slideRightLeft':
      slideRightLeft({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'slideUpDown':
      slideUpDown({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'slideDownUp':
      slideDownUp({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'bounceInOut':
      bounceInOut({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'bounceHorizontal':
      bounceHorizontal({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'bounceVertical':
      bounceVertical({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
    case 'blurChars':
      blurChars({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 10,
      });
      break;
    case 'rollLines':
      rollLines({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 2,
      });
      break;
    case 'runNumber':
      runNumber({
        controller: 'thumbnailAnimController',
        selector,
        textContent: '1000',
        visibleFrom: 0,
        visibleTo: 4000,
        length: 2,
      });
      break;
    default:
      noAnimation({
        controller: 'thumbnailAnimController',
        selector,
        visibleFrom: 0,
        visibleTo: 4000,
        length: 1,
      });
      break;
  }
}

export function fabricRender({ type }) {
  const layers = [];
  switch (type) {
    case 'video':
      window.engine.layers.forEach((layer) => {
        if (layer.videoElement) {
          layers.push(layer.object);
        }
      });
      break;
    case 'layer':
      window.engine.layers.forEach((layer) => {
        if (layer.type === 'image') {
          layers.push(layer.object);
        }
      });
      break;
    case 'all':
    default:
      window.engine.layers.forEach((layer) => {
        layers.push(layer.object);
      });
      break;
  }
  window.engine.canvas.renderCanvas(window.engine.canvas.contextContainer, layers);
}

export function createFabricLayerFades({ instances }) {
  instances.forEach((instance) => {
    if (!instance.animationIn && !instance.animationOut) {
      return;
    }
    const layerProperties = {
      opacity: '0',
    };
    const durationIn = instance.animationIn ? 500 : 1;
    const durationOut = instance.animationOut ? 500 : 1;
    // In
    window.fabricLayerController.add({
      targets: layerProperties,
      opacity: '1',
      duration: durationIn,
      easing: 'linear',
      round: 100,
      update: () => {
        const fabricObj = window.engine && window.engine.layers
          .find(layer => layer.id && layer.id === instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({ opacity: layerProperties.opacity });
        }
      },
    }, instance.visibleFrom);
    // Out
    window.fabricLayerController.add({
      targets: layerProperties,
      opacity: '0',
      duration: durationOut,
      easing: 'linear',
      round: 100,
      update: () => {
        const fabricObj = window.engine && window.engine.layers
          .find(layer => layer.id && layer.id === instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({ opacity: layerProperties.opacity });
        }
      },
    }, instance.visibleTo - durationOut);
  });
}

export function createShapeLayerFades({ instances }) {
  instances.forEach((instance) => {
    if (!instance.animationIn && !instance.animationOut) {
      return;
    }
    const layerProperties = {
      opacity: '0',
    };
    const durationIn = instance.animationIn ? 500 : 1;
    const durationOut = instance.animationOut ? 500 : 1;
    // In
    window.shapeLayerController.add({
      targets: `.shapeLayer-${instance.id}`,
      opacity: [0, 1],
      duration: durationIn,
      easing: 'linear',
      update: () => {
        const fabricObj = getLayerById(instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({ opacity: layerProperties.opacity });
        }
      },
    }, instance.visibleFrom);
    // Out
    window.shapeLayerController.add({
      targets: `.shapeLayer-${instance.id}`,
      opacity: [1, 0],
      duration: durationOut,
      easing: 'linear',
      update: () => {
        const fabricObj = getLayerById(instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({ opacity: layerProperties.opacity });
        }
      },
    }, instance.visibleTo - durationOut);
  });
}

export function createFabricVideoFades({ instances }) {
  instances.forEach((instance) => {
    if (!instance.animationIn && !instance.animationOut) {
      return;
    }
    const layerProperties = {
      opacity: '0',
    };
    const durationIn = instance.animationIn ? 500 : 1;
    const durationOut = instance.animationOut ? 500 : 1;
    // In
    window.fabricVideoController.add({
      targets: layerProperties,
      opacity: '1',
      duration: durationIn,
      easing: 'linear',
      round: 100,
      update: () => {
        const fabricObj = getLayerById(instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({ opacity: layerProperties.opacity });
        }
      },
    }, instance.visibleFrom);
    // Out
    window.fabricVideoController.add({
      targets: layerProperties,
      opacity: '0',
      duration: durationOut,
      easing: 'linear',
      round: 100,
      update: () => {
        const fabricObj = getLayerById(instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({ opacity: layerProperties.opacity });
        }
      },
    }, instance.visibleTo - durationOut);
  });
}

export function createKenburns({ instances }) {
  instances.forEach((instance) => {
    if (instance.type !== 'image' || !instance.kenburns || !instance.zoomEffect) {
      return;
    }
    const { zoomEffect } = instance;

    const layerProperties = {
      scaleX: instance.scaleX,
      scaleY: instance.scaleY,
      top: instance.top,
      left: instance.left,
    };

    window.kenburnsController.add({
      targets: layerProperties,
      scaleX: instance.scaleX * zoomEffect.scale,
      scaleY: instance.scaleY * zoomEffect.scale,
      left: instance.left - zoomEffect.X,
      top: instance.top - zoomEffect.Y,
      duration: instance.duration,
      easing: 'linear',
      update: () => {
        const fabricObj = getLayerById(instance.id);
        if (fabricObj && fabricObj.object) {
          fabricObj.object.set({
            scaleX: layerProperties.scaleX,
            scaleY: layerProperties.scaleY,
            top: layerProperties.top,
            left: layerProperties.left,
          });
        }
      },
    }, instance.visibleFrom);
  });
}

export function insertToTimeline(options) {
  const {
    textInstance,
  } = options;

  // Reset all CSS properties
  document.querySelectorAll('.previewContainer .text .line').forEach((element) => {
    element.style.opacity = 1;
    element.style.scale = 1;
    element.style.transform = 'none';
    element.style.filter = 'none';
  });
  document.querySelectorAll('.previewContainer .text .word').forEach((element) => {
    element.style.opacity = 1;
    element.style.scale = 1;
    element.style.transform = 'none';
    element.style.filter = 'none';
  });
  document.querySelectorAll('.previewContainer .text .letter').forEach((element) => {
    element.style.opacity = 1;
    element.style.scale = 1;
    element.style.transform = 'none';
    element.style.filter = 'none';
  });
  document.querySelectorAll('.previewContainer .backgroundColorLayer').forEach((element) => {
    element.style.opacity = 1;
    element.style.scale = 1;
    element.style.width = '100%';
    element.style.transform = 'none';
    element.style.filter = 'none';
    element.style.margin = 0;
  });

  // const speed = appearingDelay || 0;
  // const sequenceDelay = speed > 0 ? order * speed : 0;
  switch (options.name) {
    // LEGACY ANIMS STARTS
    case 'fadein':
      fadeIn({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'sweepinchars':
      sweepInChars({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .letter`).length,
      });
      break;
    case 'rollinoutchars':
      rollInOutChars({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .letter`).length,
      });
      break;
    case 'risinginoutchars':
      risingInOutChars({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .letter`).length,
      });
      break;
    case 'droppinginoutwords':
      droppingInOutWords({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .word`).length,
      });
      break;
    case 'fadeinoutlines':
      fadeInOutLines({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .line`).length,
      });
      break;
    // LEGACY ANIMS END
    case 'zoomInOut':
      zoomInOut({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'slideLeftRight':
      slideLeftRight({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'slideRightLeft':
      slideRightLeft({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'slideUpDown':
      slideUpDown({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'slideDownUp':
      slideDownUp({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'bounceInOut':
      bounceInOut({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'bounceHorizontal':
      bounceHorizontal({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'bounceVertical':
      bounceVertical({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    case 'blurChars':
      blurChars({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .letter`).length,
      });
      break;
    case 'rollLines':
      rollLines({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .line`).length,
      });
      break;
    case 'runNumber':
      runNumber({
        selector: `.htmlTextInstance-${textInstance.id}`,
        textContent: textInstance.text,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
        length: document.querySelectorAll(`.htmlTextInstance-${textInstance.id} .line`).length,
      });
      break;
    case 'none':
      noAnimation({
        selector: `.htmlTextInstance-${textInstance.id}`,
        visibleFrom: textInstance.visibleFrom,
        visibleTo: textInstance.visibleTo,
      });
      break;
    default:
  }
}

export function createTitleAnimations({ titleInstances }) {
  titleInstances.sort((a, b) => a.visibleFrom - b.visibleFrom).forEach((title, titleIndex) => {
    if (titleIndex === 0) {
      if (titleIndex === 0) {
        window.animationController.add({
          duration: title.visibleFrom,
        });
      }
    }
    if (titleIndex > 0) {
      window.animationController.add({
        duration: title.visibleFrom - titleInstances[titleIndex - 1].visibleTo,
      });
    }
    title.textInstances.forEach((instance) => {
      if (instance.animation) {
        insertToTimeline({
          name: instance.animation.name,
          textInstance: instance,
          title,
          order: instance.animationOrder,
          appearingDelay: instance.appearingDelay || false,
        });
      } else {
        insertToTimeline({
          name: 'none',
          textInstance: instance,
          title,
        });
      }
    });
  });
}

export function createAudioTimeline({ backgroundInstances }) {
  backgroundInstances.forEach((backgroundInstance) => {
    const audibleFrom = backgroundInstance.visibleFrom;
    const audibleTo = backgroundInstance.visibleTo;
    const audioProperties = {
      volume: '0',
    };
    if (backgroundInstance.audioSrc && backgroundInstance.enableClipAudio) {
      window.audioTracks.push({
        id: backgroundInstance.id,
        audioFile: new window.Audio(backgroundInstance.audioSrc), // 'https://demo.adlaunch.com/test-audio.mp3'
        audibleFrom,
        audibleTo,
        volume: 0,
        ready: false,
      });
      const audioObj = window.audioTracks.find(clip => clip.id === backgroundInstance.id);
      // audioObj.audioFile.crossOrigin = 'anonymous';
      audioObj.audioFile.addEventListener('canplaythrough', () => {
        audioObj.ready = true;
      });
      window.audioController.add({
        targets: audioProperties,
        volume: '100',
        duration: 50,
        easing: 'linear',
        round: 100,
        update: () => {
          audioObj.audioFile.volume = audioProperties.volume / 100;
        },
      }, audibleFrom).add({
        targets: audioProperties,
        volume: '0',
        duration: 50,
        easing: 'linear',
        round: 100,
        update: () => {
          audioObj.audioFile.volume = audioProperties.volume / 100;
        },
      }, audibleTo);
    }
  });
}
