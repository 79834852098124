import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setRef: PropTypes.func.isRequired,
};

const defaultProps = {
};

class AudioElement extends React.Component {
  setElementRef = (ref) => {
    const { setRef, id } = this.props;
    setRef(ref, id);
  }
  render() {
    const {
      src,
    } = this.props;

    return (
      <div className="AudioElement">
        <audio
          ref={this.setElementRef}
        >
          <source src={src} type="audio/mp3"/>
        </audio>
      </div>
    );
  }
}

AudioElement.propTypes = propTypes;
AudioElement.defaultProps = defaultProps;

export default AudioElement;
