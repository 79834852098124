import React from 'react';
import { connect } from 'react-redux';

import {
  textLayerMove,
  textLayerApplyAttribute,
  textLayerApplyAttributes,
  textLayerEditExit,
  applyAnimation,
  applyAttributesAll,
  textLayerEditEnter,
  textLayerReorderZindexes,
  textLayerReorderAnimationOrder,
  textLayerToggleVisible,
  textLayerChange,
} from '@actions/textlayer';
import { sidebarShowAdjusters } from '@actions/sidebar';
import { saveText } from '@actions/textpresets';
import { triggerNotification } from '@actions/app';
import { compositionTextInstanceRemove, compositionTextInstaceAdd, compositionTextInstanceUpdateTimestamps } from '@actions/composition';

import SidebarTextOverlay from '@components/Sidebar/SidebarTextOverlay';

const SidebarTextOverlayContainer = props => <SidebarTextOverlay {...props} />;

const mapStateToProps = (state) => {
  const {
    sidebar, user, timeline, brandkit,
  } = state;

  const composition = state.composition.present;
  const { activeTextId, openAdjusters } = sidebar;

  const checkActiveText = () => (composition.textInstances.length > 0 && composition.titleInstances
    .find(titles => titles.visibleFrom <= timeline.currentTime &&
      titles.visibleTo >= timeline.currentTime));

  const activeText = (composition.textInstances && composition.textInstances
    .find(instance => instance.id === activeTextId[0] || instance.id === activeTextId)) ||
  (checkActiveText() && checkActiveText().textInstances[0]);

  const animation = composition.textInstances
    .filter(text => text.id === activeTextId[0] || text.id === activeTextId)
    .map(text => (text.animation && text.animation.name) || 'none')
    .shift();

  const {
    textInstances,
    titleInstances,
    width,
    height,
    uid,
  } = composition;

  return {
    brandkit,
    activeText,
    activeTextId,
    sidebar,
    textInstances,
    titleInstances,
    animation,
    compositionWidth: width,
    compositionHeight: height,
    compositionUid: uid,
    user,
    openAdjusters,
  };
};

export default connect(mapStateToProps, {
  onApplyAttribute: textLayerApplyAttribute,
  onApplyAttributes: textLayerApplyAttributes,
  onTriggerNotification: triggerNotification,
  onMove: textLayerMove,
  onApplyAnimation: applyAnimation,
  onClose: textLayerEditExit,
  onAddTextLayer: compositionTextInstaceAdd,
  onTextLayerEditEnter: textLayerEditEnter,
  onApplyAttributesToAll: applyAttributesAll,
  onRemove: compositionTextInstanceRemove,
  onSaveAsPreset: saveText,
  onUpdateTextLayerDepths: textLayerReorderZindexes,
  onUpdateTextLayerOrders: textLayerReorderAnimationOrder,
  onUpdateTimestamps: compositionTextInstanceUpdateTimestamps,
  onLayerToggleVisible: textLayerToggleVisible,
  textLayerChange,
  onSideBarAdjusters: sidebarShowAdjusters,
})(SidebarTextOverlayContainer);
