import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash.flow';
import classNames from 'classnames';
import * as dndTypes from '@constants/dndTypes';
import Resizable from 're-resizable';
import {
  RESIZE_CONSTRAINTS,
  VIDEO_ROW_HEIGHT,
  HANDLE_STYLES,
} from '@constants/timeline';
import InstanceBackground from './InstanceBackground';

import './TimelineVideoInstance.scss';

const propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  videoId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool,
  onMove: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  onResizeStart: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  toggleIntercom: PropTypes.func.isRequired,
  getItem: PropTypes.object,
  duration: PropTypes.number,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  filename: PropTypes.string,
  msRatio: PropTypes.number,
  visibleFrom: PropTypes.number,
  thumbnail: PropTypes.object,
  type: PropTypes.string.isRequired,
  sourceDuration: PropTypes.number, // should be required but cant or legacy
  playFrom: PropTypes.number,
  maxWidth: PropTypes.number,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  selected: PropTypes.bool,
};

const defaultProps = {
  selected: false,
  onClick: () => {},
};


const timelineVideoInstanceSource = {
  beginDrag(props) {
    // Used to show trashcan
    props.onDragStart();
    return {
      ...props,
      originalIndex: props.findInstance(props.id, props.list).index,
    };
  },

  endDrag(props, monitor) {
    const { id, originalIndex, list } = monitor.getItem();
    const didDrop = monitor.didDrop();
    if (!didDrop) {
      props.onCancel({ id, originalIndex, list });
    }
    // Used to hide trashcan
    props.onDragEnd();
  },

  isDragging(props, monitor) {
    return props.id === monitor.getItem().id;
  },
};

const timelineVideoInstanceTarget = {
  hover(targetProps, monitor, component) {
    const targetId = targetProps.id;
    const sourceProps = monitor.getItem();
    const sourceId = sourceProps.id;
    const offset = monitor.getClientOffset();

    if (sourceId !== targetId) {
      targetProps.onMove({
        sourceId,
        targetId,
        component,
        offset,
        ...sourceProps,
      });
    }
  },

  drop(targetProps, monitor) {
    const sourceProps = monitor.getItem();
    const sourceId = sourceProps.id;
    const targetId = targetProps.id;
    const sourceList = sourceProps.list;

    if (sourceId !== targetId && sourceList === 'gallery') {
      targetProps.onReplace({
        sourceId,
        targetId,
      });
      return {
        dropComplete: 'user replaced a video background instance',
      };
    } else if (sourceList === 'gallery') {
      targetProps.onAdd({
        sourceId,
        targetId,
      });
      return {
        dropComplete: 'user replaced a video background instance',
      };
    }
    return {
      dropComplete: 'user swapped two background instances',
    };
  },
};

// Define props that are passed to the component via props
const sourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});


const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
});

class TimelineVideoInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msRatio: props.msRatio,
      maxWidth: props.maxWidth,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.msRatio !== this.props.msRatio) {
      this.setState({
        msRatio: this.props.msRatio,
      });
    }
  }

  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      isOver,
      onResize,
      onResizeStart,
      getItem,
      duration,
      id,
      thumbnail,
      sourceDuration,
      playFrom,
      onClick,
      selected,
      toggleIntercom,
    } = this.props;

    const { msRatio } = this.state;

    const classes = classNames(
      'TimelineVideoInstance',
      {
        'TimelineVideoInstance--replace': isOver && getItem.list === 'gallery',
        'TimelineVideoInstance--placeholder': isDragging,
        'TimelineVideoInstance--selected': selected,
      },
    );
    const indicator = classNames(
      {
        'indicator--selected': selected,
      },
    );

    /**
     * Define max width if sourceDuration is set but leave it undefined
     * for backwards compatibility for compositions that might have
     * videos that doesn't have duration defined.
     *
     * This could be removed in the future when its believed that
     * there is no longer compositions with videos in them without duration.
     */

    const getMaxWidth = (direction) => {
      if (direction === 'left') {
        const calculateWidth = ((duration + playFrom) * msRatio);
        this.setState({ maxWidth: calculateWidth });
        return;
      }

      const calculateWidth = sourceDuration ? ((sourceDuration - playFrom) * msRatio) : undefined;
      this.setState({ maxWidth: calculateWidth });
    };

    const handleResizeStart = (dir) => {
      toggleIntercom('shutdown');
      getMaxWidth(dir);
      onResizeStart(dir);
    };

    const handleResizeStop = (params) => {
      toggleIntercom('boot');
      onResize(...params, id, msRatio);
      this.setState({ maxWidth: 'none' });
    };
    const minWidth = 1000 * msRatio;

    return connectDropTarget(
      <div className={indicator} onDoubleClick={this.props.onDoubleClick}>
        <Resizable
          onClick={onClick}
          className={classes}
          size={{ height: VIDEO_ROW_HEIGHT, width: duration * msRatio }}
          minWidth={minWidth}
          maxWidth={this.state.maxWidth}
          enable={RESIZE_CONSTRAINTS}
          onResizeStop={(...params) => handleResizeStop(params)}
          onResizeStart={(e, dir) => handleResizeStart(dir)}
          handleStyles={HANDLE_STYLES}
        >
          {connectDragSource(
            <div ref={(node) => { this.node = node; }} style={{ height: '100%' }}>
              <InstanceBackground imageSrc={thumbnail.hd} />
            </div>,
          )}
        </Resizable>
      </div>,
    );
  }
}

TimelineVideoInstance.propTypes = propTypes;
TimelineVideoInstance.defaultProps = defaultProps;

export default flow(
  DropTarget(dndTypes.TIMELINE_VIDEO, timelineVideoInstanceTarget, targetCollect),
  DragSource('video', timelineVideoInstanceSource, sourceCollect),
)(TimelineVideoInstance);
