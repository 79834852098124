const handleUnAuthorizedResponse = (err) => {
  if (!err.response || err.response.status !== 401) return;

  if (err.response.status === 401) {
    window.location.pathname = '/login';
  }
};


export {
  handleUnAuthorizedResponse,
};
