import React from 'react';
import { connect } from 'react-redux';
import { sidebarItemFilterToggle, sidebarUploadsFilterToggle, expandPool } from '@actions/sidebar';
import PropTypes from 'prop-types';
import {
  compositionPlaceholderRemove,
  compositionVideoInstanceSelect,
  compositionAddShapeLayer,
} from '@actions/composition';
import { fetchShape } from '@actions/footage';
import { stateKeys } from '@constants/footage';
import ShapesTab from '@components/ShapesTab';

const propTypes = {
  // TODO: use on load when shapes api is ready
  // onLoad: PropTypes.func.isRequired,
  filter: PropTypes.string,
  shapeLibrary: PropTypes.array,
  onLoadShape: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    footage,
    sidebar,
  } = state;
  const composition = state.composition.present;
  const { shapeFilter, selectedShapes } = sidebar;
  const { currentTime } = state.timeline;

  const selectedFilterData = footage[stateKeys.shapes[shapeFilter]];
  return {
    shapeLibrary: selectedFilterData.entries,
    loading: selectedFilterData.isFetching,
    filter: shapeFilter,
    composition,
    currentTime,
    selectedShapes,
  };
};

const ShapesTabContainer = (props) => {
// COMBAK:
  /* useEffect(() => {
    const { shapeLibrary, onLoadShape, filter } = props;
    if (shapeLibrary) {
      shapeLibrary.forEach((shape) => {
        onLoadShape(shape.src, filter);
      });
    }
   }, []); */

  return <ShapesTab {...props} />;
};

ShapesTabContainer.propTypes = propTypes;

export default connect(mapStateToProps, {
  onFilterUploadsToggle: sidebarUploadsFilterToggle,
  onFilterToggle: sidebarItemFilterToggle,
  onCancel: compositionPlaceholderRemove,
  onSelect: compositionVideoInstanceSelect,
  onAddShape: compositionAddShapeLayer,
  onLoadShape: fetchShape,
  expandPool,
})(ShapesTabContainer);
