import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Track from '@components/NewTimeline/Track';
import { Shapes, Video } from '@components/Icons';
import './NewTimeline.scss';

const Pool = (props) => {
  const { composition } = props;
  const onIndex = useRef(null);
  const changeIndex = (newIndex, ref) => {
    ref.current = newIndex;
  };
  const backgroundAddPosition = useRef();
  const changeBackgroundAddPosition = (ref, index) => {
    ref.current = index;
  };
  const hoveringInstance = useRef(false);
  const toggleHovering = (boolean, ref) => {
    ref.current = boolean;
  };
  const [msToPixel, calculateMsToPixel] = useState(null);
  const [tracks, setTracks] = useState(null);
  const putOnTracks = () => {
    if (props.type === 'overlays') {
      const newTracks = [[], [], [], [], []];
      const instances = [
        ...composition.shapeInstances,
        ...composition.textInstances,
        ...composition.layers,
      ];
      instances.forEach((instance) => {
        newTracks[instance.track].push(instance);
      });
      newTracks.forEach((track) => {
        track.sort((a, b) => a.visibleFrom - b.visibleFrom);
      });
      setTracks(newTracks);
    } else {
      const newTracks = [...composition.backgroundInstances];
      setTracks([newTracks]);
    }
  };
  useEffect(() => {
    // console.log('RERENDER');
    calculateMsToPixel(props.timelineWidth / composition.duration);
    putOnTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  if (!tracks) return null;
  const renderTracks = tracks.map((track, index) => <Track
      setTlWidth={props.setTlWidth}
      setTlLeft={props.setTlLeft}
      poolType={props.type}
      key={`track-${index}`}
      tracks={tracks}
      backgroundAddPosition = {backgroundAddPosition}
      changeBackgroundAddPosition = {changeBackgroundAddPosition}
      backgroundTrack={[...composition.backgroundInstances]}
      instances={tracks[index]}
      expanded={props.expanded}
      msToPixel={msToPixel}
      timelineWidth={props.timelineWidth}
      timelineLeft={props.timelineLeft}
      trackIndex={index}
      composition={props.composition}
      visible={track.length > 0}
      modifyComposition={props.modifyComposition}
      poolIndex={props.index}
      onVideoResize={props.onVideoResize}
      onTextResize={props.onTextResize}
      onOverlayMove={props.onOverlayMove}
      onBackgroundMove={props.onBackgroundMove}
      duration={composition.duration}
      trackRef={props.trackRef}
      changeTrackRef={props.changeTrackRef}
      onIndex={onIndex}
      changeIndex={changeIndex}
      onVideoRemove={props.onVideoRemove}
      onTextRemove={props.onTextRemove}
      onAddTextLayer={props.onAddTextLayer}
      onTextLayerSelect={props.onTextLayerSelect}
      onAddShape={props.onAddShape}
      onAddLayer={props.onAddLayer}
      onSelectShape={props.onSelectShape}
      onAddVideo={props.onAddVideo}
      onSelect={props.onSelect}
      onUpdateShape={props.onUpdateShape}
      onUpdateLayer={props.onUpdateLayer}
      compositionDuration={composition.duration}
      changeSelected={props.changeSelected}
      compositionWidth={composition.width}
      compositionHeight={composition.height}
      onDelete={props.onDelete}
      onShapeRemove={props.onShapeRemove}
      setTrashVisible={props.setTrashVisible}
      trashVisible={props.trashVisible}
      onDrag={props.onDrag}
      onAdd={props.onAdd}
      activeBackgroundId={props.activeBackgroundId}
      activeTextId={props.activeTextId}
      activeLayerId={props.activeLayerId}
      activeShapeId={props.activeShapeId}
      onReplace={props.onReplace}
      onLayerSelect={props.onLayerSelect}
      onLayerRemove={props.onLayerRemove}
      onProgressJump={props.onProgressJump}
      toggleHovering={toggleHovering}
      hoveringInstance={hoveringInstance}
      formatBackgroundInstances={props.formatBackgroundInstances}
    />);

  let poolIcon;
  switch (props.type) {
    case 'overlays':
      poolIcon = <Shapes />;
      break;
    case 'background':
      poolIcon = <Video />;
      break;
    default:
  }

  const classNames = !props.expanded ? 'poolContainer' : 'poolContainer poolContainer--expanded';
  return (
    <div className={classNames} onClick={() => props.onClick(props.index)}>
      <div className="pool-icon">
        <div className="icon">
          {poolIcon}
        </div>
      </div>
      <div className="pool-wrapper">
        <div className={`pool ${props.type}`}>
          {renderTracks}
        </div>
      </div>
    </div>
  );
};

Pool.propTypes = {
  timelineWidth: PropTypes.number,
  timelineLeft: PropTypes.number,
  tracks: PropTypes.array,
  index: PropTypes.number,
  poolIndex: PropTypes.number,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  modifyComposition: PropTypes.func,
  composition: PropTypes.object,
  onOverlayMove: PropTypes.func,
};

export default Pool;
