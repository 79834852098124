// import React from 'react';
import PropTypes from 'prop-types';
// import ReactDOM from 'react-dom';

import './Modal.scss';

const propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

function Modal(props) {
  // if (!props.visible) return null;

  // return ReactDOM.createPortal(
  //   <div className="Modal">
  //     <div className="Modal__backdrop" onClick={props.onClose}></div>
  //     <div className="Modal__container">
  //       <div className="Modal__top">
  //         <h6 className="Modal__title">{props.title}</h6>
  //         <div className="Modal__close" onClick={props.onClose}>&times;</div>
  //       </div>
  //       <div className="Modal__content">
  //         {props.children}
  //       </div>
  //     </div>
  //   </div>,
  //   document.querySelector('#modal'),
  // );
  return null
}

Modal.propTypes = propTypes;

export default Modal;
