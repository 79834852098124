module.export = {
  /**
   * Precision used when rounding current and total time
   * of the composition to be displayed to the user.
   * e.g. currentTime is 2455ms, it is displayed 2.46s in
   * timeline.
   */
  MS_TO_SECONDS_ROUNDING_PRECISION: 1,

  /**
   * Constraints that are given to the resizable element
   * that is used to handle instance resizing on the timeline
   */
  RESIZE_CONSTRAINTS: {
    left: true,
    right: true,
    top: false,
    bottom: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  },

  /**
   * Different row heights
   */
  TEXT_ROW_HEIGHT: 18,
  VIDEO_ROW_HEIGHT: 44,

  HANDLE_STYLES: {
    left: {
      left: '-2px',
      height: 'calc(100% + 4px)',
      top: '-2px',
      userSelect: 'none',
      maxWidth: '15px',
      minWidth: '5px',
      width: '15%',
      borderTopLeftRadius: '.125rem',
      borderBottomLeftRadius: '.125rem',
    },
    right: {
      right: '-2px',
      height: 'calc(100% + 4px)',
      top: '-2px',
      userSelect: 'none',
      maxWidth: '15px',
      minWidth: '5px',
      width: '15%',
      borderTopRightRadius: '.125rem',
      borderBottomRightRadius: '.125rem',
    },
  },
};
