/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  textInstance: PropTypes.object.isRequired,
  canvas: PropTypes.object.isRequired,
  scaleFactor: PropTypes.number.isRequired,
};

const defaultProps = {
};

class TitleItem extends React.Component {
  state = {
    isEditing: false,
    isMoving: false,
    isResizing: false,
    clickHappening: false,
    savedWidth: null,
    scaleX: null,
    scaleY: null,
    selectedTextWidth: null,
    selectedTextHeight: null,
  }

  getElementSize(element) {
    return {
      width: element.offsetWidth + 10,
      height: element.offsetHeight,
    };
  }

  splitTextToLinesWordsAndChars(text) {
    // Splits text string into lines, words and letters that can be animated individually
    return text.replace(/[^\r\n]+/g, (line) => {
      let lineHtml = '';
      const words = line.split(' ');
      words.forEach((word) => {
        lineHtml += `<span class="word">${word.replace(/\S/g, char => `<span class='letter'>${char}</span>`)}</span> `;
      });
      return `<span class='line'>${lineHtml}</span>`;
    });
  }

  createMarkup(text) {
    // Return HTML string for text element
    const { textInstance } = this.props;
    const { isEditing } = this.state;
    if (textInstance.animation && textInstance.animation.split) {
      if (!isEditing) {
        return { __html: this.splitTextToLinesWordsAndChars(text) };
      }
    }
    return { __html: text.replace('\n', '<br/>').replace('\r', '<br/>') };
  }

  render() {
    const {
      canvas,
      scaleFactor,
      textInstance,
    } = this.props;

    const {
      isMoving, isResizing, scaleX,
    } = this.state;

    const centerX = (canvas.width / scaleFactor) / 2;
    const centerY = (canvas.height / scaleFactor) / 2;

    const borderColor = textInstance.borderWidth && textInstance.borderColor && `rgba(${textInstance.borderColor.r}, ${textInstance.borderColor.g}, ${textInstance.borderColor.b}, ${textInstance.borderColor.a})`;
    const boxShadow = textInstance.boxShadow && `${textInstance.boxShadow.offsetX}px ${textInstance.boxShadow.offsetY}px ${textInstance.boxShadow.blur}px ${textInstance.boxShadow.color}`;

    const shadowConstructor = () => {
      let shadowString;
      if (borderColor && textInstance.borderWidth && !boxShadow) {
        shadowString = `${borderColor} 0px 0px 0px ${textInstance.borderWidth}px inset`;
      }
      if (borderColor && textInstance.borderWidth && boxShadow) {
        shadowString = `${borderColor} 0px 0px 0px ${textInstance.borderWidth}px inset, ${boxShadow}`;
      }
      if ((!borderColor || !textInstance.borderWidth) && boxShadow) {
        shadowString = `${boxShadow}`;
      }
      return shadowString;
    };

    return (
      <div
        className={`titleThumbnailInstance-${textInstance.id}`}
        style={{
          position: 'absolute',
          color: textInstance.fill,
          fontFamily: textInstance.fontFamily,
          fontSize: `${textInstance.fontSize}px`,
          fontStyle: textInstance.fontStyle,
          fontWeight: textInstance.fontWeight,
          lineHeight: textInstance.lineHeight || '1em',
          left: isMoving ? `${this.state.x}px` : `${textInstance.left || centerX}px`,
          top: isMoving ? `${this.state.y}px` : `${textInstance.top || centerY}px`,
          textAlign: textInstance.textAlign,
          WebkitTextStrokeWidth: `${textInstance.strokeWidth}px`,
          WebkitTextStrokeColor: `${textInstance.stroke}`,
          borderRadius: `${textInstance.borderRadius}px`,
          border: 'none',
          textShadow: textInstance.textShadow ? `${textInstance.textShadow.offsetX}px ${textInstance.textShadow.offsetY}px ${textInstance.textShadow.blur}px ${textInstance.textShadow.color}` : null,
          transform: `scaleX(${isResizing ? scaleX : textInstance.scaleX}) scaleY(${isResizing ? scaleX : textInstance.scaleX}) translate(-50%, -50%)`,
          transformOrigin: '0 0',
          margin: '-2px 0',
          whiteSpace: 'nowrap',
          display: 'flex',
        }}
      >
        <div
          className="backgroundColorLayer"
          style={{
            boxShadow: shadowConstructor(),
            backgroundColor: textInstance.backgroundColor,
            borderRadius: `${textInstance.borderRadius / 1.2}px`,
          }}
        ></div>
        <div style={{
          display: 'flex',
          width: '100%',
          padding: textInstance.padding || '30px 40px',
          transform: `rotate(${textInstance.angle || 0}deg)`,
        }}>
          <div
            className="text"
            dangerouslySetInnerHTML={this.createMarkup(textInstance.text)}
          ></div>
        </div>
      </div>
    );
  }
}

TitleItem.propTypes = propTypes;
TitleItem.defaultProps = defaultProps;

export default TitleItem;
