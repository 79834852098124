import React from 'react';
import PropTypes from 'prop-types';

import './VideoElement.scss';

const propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  muted: PropTypes.bool,
  audioSrc: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setRef: PropTypes.func.isRequired,
};

const defaultProps = {
  width: 1920,
  height: 1080,
  muted: true,
};

class VideoElement extends React.Component {
  setElementRef = (ref) => {
    const { setRef, id } = this.props;
    setRef(ref, id);
  }
  render() {
    const {
      width,
      height,
      muted,
      src,
      // audioSrc,
    } = this.props;

    return (
      <div className="VideoElement">
        <video
          className="VideoElement__video"
          muted={muted}
          width={width}
          height={height}
          ref={this.setElementRef}
          crossOrigin="Anonymous"
        >
          <source src={src}/>
        </video>
      </div>
    );
  }
}

VideoElement.propTypes = propTypes;
VideoElement.defaultProps = defaultProps;

export default VideoElement;
