import VideoThumbnail from '@components/VideoThumbnail';
import {
  DEFAULT_FOOTAGE_DURATION,
  MAX_VIDEO_DURATION
} from '@constants/composition';
import { getNewInstanceTimeslot } from '@helpers/composition';
import React from 'react';
import './VideosTab.scss';

const VideoElements = ({
  videoPages,
  filter,
  onAdd,
  // handleAdd,
  onCancel,
  onRemoveUploadVideo,
  composition,
  onAddToFavorite,
  onProgressJump,
  onRemoveFromFavorite,
  onSelect,
  onShowContext,
  expandPool,
  backgroundInstances,
  formatBackgroundInstances,
}) => {
  // all tabs need delete button except stock
  const showDelete = filter !== 'stock';

  const handleFavorite = (event, video) => {
    const type = 'video';
    event.stopPropagation();
    onAddToFavorite(video, type);
  };

  const handleRemoveFavorite = (event, favoriteId) => {
    const type = 'video';
    event.stopPropagation();
    onRemoveFromFavorite(favoriteId, type);
  };

  const handleAdd = (targetIndex, sourceVideo, isDragging) => {
    const {
      thumbnail,
      src,
      sourceDuration,
      videoId,
      aspectRatio,
      aspectNumber,
      width,
      height,
      audio,
      audioSrc,
      // visibleFrom,
      duration,
    } = sourceVideo;
    const timeslot = getNewInstanceTimeslot(
      composition.backgroundInstances,
      DEFAULT_FOOTAGE_DURATION
    );

    let finalDuration = duration;
    if (
      sourceDuration <= DEFAULT_FOOTAGE_DURATION ||
      filter === "upload" ||
      "video"
    ) {
      if (sourceDuration <= MAX_VIDEO_DURATION) {
        finalDuration = sourceDuration;
      } else {
        finalDuration = MAX_VIDEO_DURATION;
      }
    }
    if (!finalDuration) {
      finalDuration = 3000;
    }
    const sourceItem = {
      id: isDragging ? sourceVideo.id : Date.now(),
      type: "video",
      duration: 3000,
      playFrom: 0,
      thumbnail,
      src,
      sourceDuration,
      videoId,
      top: 0,
      left: 0,
      aspectRatio,
      aspectNumber,
      width,
      height,
      audio,
      audioSrc,
      visibleFrom: timeslot.visibleFrom,
    };
    window.post.message("video-add-video")
    onAdd(targetIndex, sourceItem);
    if (!isDragging) {
      onSelect(sourceItem.id);
      onShowContext(true);
      onProgressJump(sourceItem.visibleFrom + 1);
    }
  };
  const handleRemoveUpload = (e, uid) => {
    e.stopPropagation();
    onRemoveUploadVideo(uid);
  };
  return videoPages.map((video, index) => {
    if (!video.type) {
      // video.type = 'video';
    }
    // Skip those images with green screen
    // These should be excluded already in SSTK API call
    if (video.keywords && video.keywords.includes("chroma key")) return null;
    return (
      <div
        key={video.id}
        className="content-grid-col"
        onClick={() => handleAdd(videoPages.length - 1, video)}
      >
        {/* <DraggableVideoFootage
          onDragStart={() => {}}
          handleAdd={handleAdd}
          expandPool={expandPool}
          onCancel={onCancel}
          type="video"
          list="gallery"
          backgroundInstances={backgroundInstances}
          formatBackgroundInstances={formatBackgroundInstances}
          duration={composition.duration}
          instance={video}
          parent={document.getElementsByClassName("content-grid-col")[index]}
        > */}
        <VideoThumbnail
          thumbnailSrc={video.thumbnail.hd}
          duration={video.sourceDuration}
          src={video.thumbnail.video}
          showDelete={showDelete}
          onDelete={
            filter === 'favorite'
              ? event => handleRemoveFavorite(event, video.favoriteId)
              : e => handleRemoveUpload(e, video.uid)
          }
          onFavorite={event => handleFavorite(event, video)}
          isFav={video.isFav}
          filter={filter}
        />
        {/* </DraggableVideoFootage> */}
      </div>
    );
  });
};

export default VideoElements;
