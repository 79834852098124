import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import ReactFilestack from 'filestack-react';
// import Button from '@components/Button';
import Spinner from '@components/Spinner';
// import { UploadCloud } from '@components/Icons';
// import { FILESTACK_PUBLIC_API_KEY } from '@config';

const propTypes = {
  onUpload: PropTypes.func,
  visible: PropTypes.bool,
  item: PropTypes.string,
  imageUploadOptions: PropTypes.object,
};

const defaultProps = {
  visible: false,
};

const Uploads = ({
  onUpload, activeUploads, item, imageUploadOptions,
}) => (
  <Fragment>
    <div className="ImagesTab__uploads--section">
    {/*
      brand logo section uses this component. item is flag to tell
      that this is being rendered for logo brand section
      has room for improvement, be it separating this section to it's own component section
      */}
      {/* {item === 'logo' && <ReactFilestack
        apikey={FILESTACK_PUBLIC_API_KEY}
        options={imageUploadOptions}
        onSuccess={filestackResponse => onUpload(filestackResponse, item)}
        render={({ onPick }) => (
          <Button className="VideosTab__upload-btn" color="pink" onClick={onPick} withIcon={true}>
            Upload logos
            <UploadCloud />
          </Button>
        )}
      />} */}
    </div>
    {activeUploads
      .filter(upload => upload.type === 'image')
      .map(upload => (
        <div key={upload.uploadId} className="content-grid-col">
          <div key={upload.uploadId} className="Active-upload">
            <Spinner visible={true} />
          </div>
        </div>
      ))}
  </Fragment>
);

Uploads.propTypes = {
  activeUploads: PropTypes.array,
};

Uploads.propTypes = propTypes;
Uploads.defaultProps = defaultProps;

export default Uploads;
