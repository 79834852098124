import * as types from '@constants/actionTypes';
import flatmap from 'lodash.flatmap';


const initialState = {
  entries: [],
  showMoveModal: false,
  isMovingComposition: false,
};

const addCompositionToProject = (entries, composition) => entries.map((project) => {
  if (project.uid !== composition.project) return project;
  return {
    ...project,
    videos: [
      composition,
      ...project.videos.slice(0),
    ],
  };
});

const removeComposition = (entries, compositionUid) => entries.map(project => ({
  ...project,
  videos: project.videos.filter(video => video.uid !== compositionUid),
}));

const removeProject = (entries, projectUid) => {
  const newProjects = entries.filter(project => project.uid !== projectUid);
  return newProjects;
};


const renameProject = (entries, newName, uid) => entries.map((project) => {
  if (project.uid !== uid) return project;

  return {
    ...project,
    name: newName,
  };
});
const renameVideo = (entries, newName, projectUid, videoUid) => entries.map((project) => {
  if (project.uid !== projectUid) return project;
  const { videos } = project;

  const newVideo = videos.map((video) => {
    if (video.uid !== videoUid) return video;
    return {
      ...video,
      name: newName,
    };
  });

  return { ...project, videos: newVideo };
});

const moveComposition = (entries, destinationProjectUid, compositionUid) => {
  const composition = flatmap(entries, project => project.videos)
    .find(({ uid }) => uid === compositionUid);

  const newComp = {
    ...composition,
    project: destinationProjectUid,
  };

  const entriesWithoutOldComp = removeComposition(entries, compositionUid);
  return addCompositionToProject(entriesWithoutOldComp, newComp);
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.PROJECTS_RECEIVE:
      return {
        ...state,
        entries: action.payload.entries,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.payload.receivedAt,
      };

    case types.PROJECTS_RECEIVE_SINGLE: {
      const { project, id } = action.payload;
      const { entries } = state;
      const newEntries = entries.map((proj) => {
        if (proj.uid !== id) return proj;
        return {
          ...proj,
          videos: project.data,
        };
      });
      return {
        ...state,
        entries: newEntries,
      };
    }

    case types.PROJECTS_ADD_RECEIVE:
      return {
        ...state,
        entries: [
          action.payload.project,
          ...state.entries,
        ],
      };

    case types.PROJECTS_ADD_COMPOSITION_SUCCESS: {
      const { composition } = action.payload;
      return {
        ...state,
        entries: addCompositionToProject(state.entries, composition),
      };
    }

    case types.PROJECTS_REVERSION_COMPOSITION_SUCCESS: {
      const { composition } = action.payload;
      return {
        ...state,
        entries: addCompositionToProject(state.entries, composition),
      };
    }

    case types.PROJECTS_RECEIVE_RENAME:
      return {
        ...state,
        entries: renameProject(state.entries, action.payload.newName, action.payload.projectUid),
      };

    case types.COMPOSITION_RENAME_SUCCESS: {
      const { videoUid, projectUid, newName } = action.payload;
      return {
        ...state,
        entries: renameVideo(state.entries, newName, projectUid, videoUid),
      };
    }

    case types.PROJECTS_REMOVE_COMPOSITION_SUCCESS:
      return {
        ...state,
        entries: removeComposition(state.entries, action.payload.compositionUid),
      };

    case types.PROJECTS_REMOVE_SUCCESS:
      return {
        ...state,
        entries: removeProject(state.entries, action.payload.projectUid),
      };

    case types.PROJECTS_MOVE_TOGGLE_MODAL:
      return {
        ...state,
        showMoveModal: !state.showMoveModal,
      };

    case types.PROJECTS_MOVE_COMPOSITION_REQUEST:
      return {
        ...state,
        isMovingComposition: true,
      };

    case types.PROJECTS_MOVE_COMPOSITION_SUCCESS:
      return {
        ...state,
        isMovingComposition: false,
        showMoveModal: false,
        entries: moveComposition(
          state.entries,
          action.payload.destinationProjectUid,
          action.payload.compositionUid,
        ),
      };

    case types.PROJECTS_MOVE_COMPOSITION_FAIL:
      return {
        ...state,
        isMovingComposition: false,
        showMoveModal: false,
      };

    default:
      return state;
  }
}
