import React from 'react';
import { connect } from 'react-redux';
import {
  compositionRemoveShapeLayer,
  compositionUpdateShapeLayer,
} from '@actions/composition';
import SidebarShapeOverlay from '@components/Sidebar/SidebarShapeOverlay';
// import { compositionShapelayerUnselect } from '@actions/app';

const SidebarShapeOverlayContainer = props => <SidebarShapeOverlay {...props} />;

const mapStateToProps = (state) => {
  const { sidebar, brandkit } = state;
  const pallets = brandkit && brandkit.brandcolor;
  const composition = state.composition.present;
  const { selectedShapes, openAdjusters } = sidebar;

  const activeShape = composition.shapeInstances
    .find(shape => shape.id === (selectedShapes && selectedShapes[0]));
  const { shapeInstances } = composition;


  return {
    activeShape,
    shapeInstances,
    selectedShapes,
    colorSetting: openAdjusters,
    pallets,
  };
};

export default connect(mapStateToProps, {
  onRemove: compositionRemoveShapeLayer,
  onModifyShape: compositionUpdateShapeLayer,
  // onClose: compositionShapelayerUnselect,
})(SidebarShapeOverlayContainer);
