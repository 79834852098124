import { fabric } from 'fabric'
import Layer from './Layer'

class TextLayer extends Layer {
  static SPLIT_CHARS = 'SPLIT_CHARS';
  static SPLIT_WORDS = 'SPLIT_WORDS';
  static SPLIT_LINES = 'SPLIT_LINES';

  layers = []

  constructor(textElement, { visibleFrom, visibleTo, split, animationFactory, id } = {}, eventHandlers = {}) {
    super({ visibleFrom, visibleTo })
    this.textElement = textElement
    this.split = split
    this.animationFactory = animationFactory
    this.id = id
    this.eventHandlers = eventHandlers;
    this.type = 'text';

    textElement.on('editing:exited', this.onExitEdit)
    textElement.on('moved', this.onExitEdit)

    this.bindEventHandlers(eventHandlers)

    if (split) {
      this.generate()
    }
  }

  onExitEdit = () => {
    if (this.split) {
      this.generate()
    }
  }

  getObjects() {
    return [
      ...this.layers.reduce((acc, layer) => [...acc, ...layer.getObjects()], []),
      this.textElement
    ]
  }

  getAnimatableObject() {
    return this.textElement
  }

  /**
   * Used to update letter by letter etc animations
   * without having to remove/add the whole layer
   *
   * @param {Array} animationFactory
   * @param {Symbol} split
   */
  updateComplexAnimations(animationFactory, split) {
    this.split = split
    this.animationFactory = animationFactory
    this.generate()
  }

  clearComplexAnimations() {
    this.split = undefined
    this.animationFactory = undefined
    this.layers = []
    this.restoreState();
  }

  /**
   * Checks if if any of the animations are currently ongoing
   *
   * @returns {boolean}
   */
  isActivelyAnimating() {
    let isAnimating = false;
    const factory = this.animationFactory || [];
    const anims = this.animations || [];
    const allAnimations = [...anims, ...factory];

    if (allAnimations.length === 0) return isAnimating;

    if (this.renderEngine) {
      const ts = this.renderEngine.currentTimestamp;
      isAnimating = allAnimations
        .map(anim => anim.from < ts && anim.to > ts)
        .some(flag => flag);
    }

    return isAnimating;
  }

  /**
   * Checks if textlayer has animations assigned to it
   * @returns {boolean} - true if there are animations
   */
  hasAnimations() {
    const factory = this.animationFactory || [];
    const anims = this.animations || [];
    const allAnimations = [...anims, ...factory];

    return allAnimations.length > 0;
  }

  generate() {
    if (this.renderEngine) {
      const { canvas } = this.renderEngine;
      this.layers.forEach((layer) => {
        canvas.remove(layer.getAnimatableObject());
      });
      this.layers = [];
      // To make sure there are no extra layers left (with staggered animations)
      // it seems to require clearing the fabric's _objects array as well!
      canvas._objects = [];
    }

    const textElement = this.textElement;
    const scale = textElement.scaleX;
    const textBoundingBoxLeft = textElement.left;
    const textBoundingBoxTop = textElement.top;
    const textBoundingBoxWidth = textElement.width * scale;
    const textBoundingBoxHeight = textElement.height * scale;
    const textAlign = textElement.textAlign;

    let left = textBoundingBoxLeft - (textBoundingBoxWidth / 2);
    let top = textBoundingBoxTop - (textBoundingBoxHeight / 2);
    let charCounter = 0;
    let wordCounter = 0;

    let total;
    switch (this.split) {
      case TextLayer.SPLIT_CHARS:
        /**
         * Only non-space characters are added to the canvas
         * to prevent animations rendering empty letters and making
         * it seem as if the animation is lagging.
         *
         * Because of this, the length of the characters needs to be
         * stripped of spaces, so that the times calculated to
         * these characters are correct.
         *
         * Eg. string with one space would have on "timeslot" too
         * much in its calculations, making its animation end too
         * soon and the text would blink in the end.
         */
        total = textElement._textLines
          .reduce((acc, line) => acc + line.filter(char => char !== ' ').length, 0);
        break
      case TextLayer.SPLIT_WORDS:
        total = textElement.text.split(/\s+/).length
        break
      case TextLayer.SPLIT_LINES:
        total = textElement._textLines.length
        break
      default:
        break
    }

    textElement._textLines.forEach((line, lineIndex) => {
      const lineHeight = textElement.getHeightOfLine(lineIndex) / textElement.lineHeight / textElement._fontSizeMult;
      const lineWidth = textElement.__lineWidths[lineIndex];

      /**
       * If text is aligned center or right, the text left amount needs
       * to be adjusted for rows that are shorter than the longest row.
       * textBoundingBoxWidth should be equal to the longest row.
       */
      if (lineWidth < textElement.width) {
        if (textAlign === 'center') {
          left += (textBoundingBoxWidth - (lineWidth * scale)) / 2;
        } else if (textAlign === 'right') {
          left += (textBoundingBoxWidth - (lineWidth * scale));
        }
      }

      line.forEach((char, charIndex) => {
        const style = textElement.getCompleteStyleDeclaration(lineIndex, charIndex)
        const prevChar = charIndex > 0 ? line[charIndex - 1] : null
        const prevStyle = charIndex > 0 ? textElement.getCompleteStyleDeclaration(lineIndex, charIndex - 1) : {}
        const { kernedWidth: width } = textElement._measureChar(char, style, prevChar, prevStyle)

          
        if (char !== ' ') {
          const charHeight = textElement.getHeightOfChar(lineIndex, charIndex)

          const charElement = new fabric.Text(char, {
            left,
            top: top + (lineHeight - charHeight) / textElement._fontSizeMult,
            ...style,
            scaleX: textElement.scaleX ? textElement.scaleX : 1,
            scaleY: textElement.scaleY ? textElement.scaleY : 1,
            shadow: textElement.shadow,
            // evented: false,
            selectable: false,
            parentId: this.id,
          })

          /**
           * handles situations where user clicks on a text
           * that is still animating and the render engine
           * timestamp is changed to the first non-animating
           * point so that the user can make changes to the text.
           */
          // TODO: make mousedown more general that doesn't care about animation times
          const { mousedown } = this.eventHandlers;
          if (mousedown && mousedown instanceof Function) {
            charElement.on('mousedown', (options) => {
              if (this.isActivelyAnimating()) {
                mousedown(options);
              }
            });
          }

          let index
          switch (this.split) {
            case TextLayer.SPLIT_CHARS:
              index = charCounter
              break
            case TextLayer.SPLIT_WORDS:
              index = wordCounter
              break
            case TextLayer.SPLIT_LINES:
              index = lineIndex
              break
            default:
              break
          }

          const charLayer = new TextLayer(charElement)
          this.addLayer(charLayer)
          if (this.animationFactory) {
            let visibleFrom = this.visibleFrom
            let visibleTo = this.visibleTo
            for (const factory of this.animationFactory) {
              const animation = factory.getAnimation(index, total)
              visibleFrom = Math.min(visibleFrom, factory.from)
              visibleTo = Math.max(visibleTo, factory.to)
              charLayer.addAnimation(animation)
            }
            charLayer.visibleFrom = visibleFrom
            charLayer.visibleTo = visibleTo
          }
        }

        left += width * scale
        if (char === ' ') {
          wordCounter++
        } else {
          charCounter++
        }
      })

      left = textBoundingBoxLeft - (textBoundingBoxWidth / 2)
      top += textElement.getHeightOfLine(lineIndex) * scale
      wordCounter++
    })

    if (this.renderEngine) {
      this.renderEngine.updateStack()
      this.updateState(this.renderEngine.currentTimestamp)
    }
  }

  addLayer(layer) {
    this.layers.push(layer)
  }

  storeState() {
    super.storeState()
    for (const layer of this.layers) {
      layer.storeState()
    }
  }

  updateState(timestamp) {
    super.updateState(timestamp)
    for (const layer of this.layers) {
      layer.updateState(timestamp)
    }

    const hasSubAnimations = this.layers.some(layer => layer.animating)
    this.textElement.visible = this.textElement.visible && !hasSubAnimations
    if (this.textElement.visible) {
      this.layers.forEach(layer =>
        layer.getObjects().forEach(subObject => subObject.visible = false)
      )
    }
  }

  bindEventHandlers(eventHandlers) {
    const {
      eventChanged,
      changed,
      moving,
      moved,
      modified,
      selected,
      deselected,
      selectionChanged,
      editingEntered,
      editingExited,
      mousedown,
      mouseup,
    } = eventHandlers

    if (eventChanged && eventChanged instanceof Function) {
      this.textElement.on('event:changed', eventChanged)
    }

    if (selected && selected instanceof Function) {
      this.textElement.on('selected', selected)
    }

    if (deselected && deselected instanceof Function) {
      this.textElement.on('deselected', deselected)
    }

    if (changed && changed instanceof Function) {
      this.textElement.on('changed', changed)
    }

    if (moving && moving instanceof Function) {
      this.textElement.on('moving', moving)
    }

    if (moved && moved instanceof Function) {
      this.textElement.on('moved', moved)
    }

    if (modified && modified instanceof Function) {
      this.textElement.on('modified', modified)
    }

    if (selectionChanged && selectionChanged instanceof Function) {
      this.textElement.on('selection:changed', selectionChanged)
    }

    if (editingEntered && editingEntered instanceof Function) {
      this.textElement.on('editing:entered', editingEntered)
    }

    if (editingExited && editingExited instanceof Function) {
      this.textElement.on('editing:exited', editingExited)
    }

    if (mousedown && mousedown instanceof Function) {
      this.textElement.on('mousedown', mousedown)
    }

    if (mouseup && mouseup instanceof Function) {
      this.textElement.on('mouseup', mouseup)
    }
  }
}

export default TextLayer
