/**
 * Gets a desired timeslot for newly added text from a list of timeslots
 *
 * @param {Array} textInstances - List of textInstances from composition
 * @returns {(Object)} - Returns a timeslot
 */

const checkPushingRequired = (textInstances, currentTextId, animationSetting) => {
  const { duration: neededDuration } = animationSetting;
  const currentTextIndex = textInstances.findIndex(textObj => textObj.id === currentTextId);
  const currentText = textInstances[currentTextIndex];
  const nextText = textInstances[currentTextIndex + 1];
  const pushNextText = nextText && nextText.visibleFrom < neededDuration + currentText.visibleFrom;
  const deltaTime = (neededDuration + currentText.visibleFrom) - currentText.visibleTo;

  const newTextInstances = textInstances.map((text, index) => {
    if (currentTextIndex >= index) return text;
    if (text.depth > 0 && currentText.visibleFrom === text.visibleFrom) {
      return {
        ...text,
        duration: animationSetting.duration,
        visibleFrom: text.visibleFrom,
        visibleTo: text.visibleTo + deltaTime,
        animation: {
          ...animationSetting,
          out: [
            {
              ...animationSetting.out[0],
              from: animationSetting.out[0].from + deltaTime,
              to: animationSetting.out[0].to + deltaTime,
            },
          ],
        },
      };
    }

    const instance = {
      ...text,
      visibleFrom: pushNextText ? text.visibleFrom + deltaTime : text.visibleFrom,
      visibleTo: pushNextText || (deltaTime && index === textInstances.length) ?
        text.visibleTo + deltaTime : text.visibleTo,
      animation: pushNextText
        ? text.animation && {
          ...text.animation,
          in: [
            {
              ...animationSetting.in[0],
              from: pushNextText
                ? animationSetting.in[0].from + deltaTime
                : animationSetting.in[0].from,
              to: pushNextText
                ? animationSetting.in[0].to + deltaTime
                : animationSetting.in[0].to,
            },
          ],
          out: [
            {
              ...animationSetting.out[0],
              from: pushNextText
                ? animationSetting.out[0].from + deltaTime
                : animationSetting.out[0].from,
              to: pushNextText
                ? animationSetting.out[0].to + deltaTime
                : animationSetting.out[0].to,
            },
          ],
        }
        : text.animation,
    };
    return instance;
  });
  return { newTextInstances, deltaTime };
};

function getCurrentRowInTextarea({ rows, cursorPos }) {
  let sum = 0;
  let result = 0;
  let found = false;
  rows.forEach((row, index) => {
    if (!found) {
      sum += row.length;
      if (cursorPos <= sum + 1) {
        result = index;
        found = true;
      } else {
        result = rows.length - 1;
      }
    }
  });
  return result;
}

const getLinkableTextInstances = (textInstances, resiszedInstance) =>
  textInstances.filter(text => (text.visibleFrom > resiszedInstance.visibleFrom &&
      text.visibleFrom <= resiszedInstance.visibleTo
  )).map(linkedText => linkedText.id);

const checkOverlapingText = (options) => {
  const {
    nextText, prevText, text, deltaTime,
  } = options;

  const overlapsFromRight = nextText &&
    (nextText.visibleFrom + deltaTime) - text.visibleTo < 0;
  const overlapsFromLeft = prevText &&
    (text.visibleFrom + deltaTime) - prevText.visibleTo < 0;

  return {
    overlapsFromLeft,
    overlapsFromRight,
  };
};

const getStateObjectFromEvent = (event) => {
  switch (event.type) {
    case 'resizing':
      return {
        isRotating: false,
        isMoving: false,
        isResizing: true,
        isSmartResizing: false,
      };
    case 'smartResizing': {
      return {
        isRotating: false,
        isMoving: false,
        isResizing: false,
        isSmartResizing: true,
        smartResizeDirection: event.direction,
      };
    }
    case 'rotating':
      return {
        isRotating: true,
        isMoving: false,
        isResizing: false,
        isSmartResizing: false,
        counterVisible: true,
      };
    default:
      return {
        isRotating: false,
        isMoving: true,
        isResizing: false,
        isSmartResizing: false,
      };
  }
};

const modifyTextInstances = (textInstances, composition) => textInstances.map((text) => {
  let width = null;
  const { width: compositionWidth } = composition;
  const { width: textWidth } = text;
  if (textWidth && textWidth + 100 > compositionWidth) {
    width = compositionWidth - 100;
  }

  return ({
    ...text,
    initial: text.text,
    id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
    whiteSpace: 'normal',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    angle: 0,
    width,
  });
});

export {
  // getAvailableTextTimeslots,
  modifyTextInstances,
  getStateObjectFromEvent,
  checkOverlapingText,
  checkPushingRequired,
  getCurrentRowInTextarea,
  getLinkableTextInstances,
};
