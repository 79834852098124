import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './VideoPreview.scss';

const propTypes = {
  showGuideX: PropTypes.bool.isRequired,
  showGuideY: PropTypes.bool.isRequired,
  canvasState: PropTypes.object.isRequired,
};

class Guides extends React.Component {
  render() {
    const {
      showGuideX, showGuideY, canvasState,
    } = this.props;
    const horizontalClasses = classNames(
      'VideoPreview__Guides',
      {
        VideoPreview__xGuide: showGuideX,
      },
    );

    const verticalClasses = classNames(
      'VideoPreview__Guides',
      {
        VideoPreview__yGuide: showGuideY,
      },
    );

    return (
      <div>
        <div className={horizontalClasses} style={{
          width: `${canvasState.width}px`,
          left: `${canvasState.left}px`,
          top: `${canvasState.top + (canvasState.height / 2)}px`,
        }}/>
        <div className={verticalClasses} style={{
          height: canvasState.height,
          left: `${canvasState.left + (canvasState.width / 2)}px`,
          top: `${canvasState.top}px`,
        }}/>
      </div>
    );
  }
}

Guides.propTypes = propTypes;

export default Guides;
