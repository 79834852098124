import PropTypes from 'prop-types';
import React from 'react';
import Creatable from 'react-select/creatable';
import './MusicTab.scss';
import TrackRow from './TrackRow';

const propTypes = {
  entries: PropTypes.array.isRequired,
  audioInstances: PropTypes.array,
  onToggleCurrentTrack: PropTypes.func.isRequired,
  timelineTogglePlay: PropTypes.func,
  compositionIsPlaying: PropTypes.bool.isRequired,
};

const genreOptions = [
  { value: null, label: 'All Genres' },
  { value: 'Electronica & Dance', label: 'Electronica & Dance' },
  { value: 'Pop', label: 'Pop' },
  { value: 'Acoustic', label: 'Acoustic' },
  { value: 'Latin', label: 'Latin' },
  { value: 'Rock', label: 'Rock' },
  { value: 'Jazz', label: 'Jazz' },
  { value: 'Reggae', label: 'Reggae' },
  { value: 'Hip Hop', label: 'Hip Hop' },
  { value: 'Rnb & Soul', label: 'Rnb & Soul' },
  { value: 'Funk', label: 'Funk' },
  { value: 'Film', label: 'Film' },
  { value: 'Comedy', label: 'Comedy' },
];

const energyOptions = [
  { value: null, label: 'All Energies' },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
];

const optionStyles = {
  control: provided => ({
    ...provided,
    backgroundColor: 'var(--color4)',
    border: 'none',
    ':hover': {
      boxShadow: "var(--color1)"
    },
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "var(--color5)",
    boxShadow: "none",
  }),
  menuList: styles => ({
    ...styles,
    color: "var(--color2)",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "var(--color1)" : styles[":isSelected"],
    ':hover': {
      backgroundColor: "var(--color4)"
    },
  }),
  singleValue: (provided, props) => {
    const fontFamily = props.label;
    return ({
      ...provided,
      fontFamily,
      color: 'orange',
    });
  },

  input: provided => ({
    ...provided,
    color: 'orange',
  }),
};

class MusicTab extends React.Component {
  state = {
    currentlyPlaying: null,
    filteredGenre: null,
    filteredEnergy: null,
  }

  handleTrackPlay = (event, uid) => {
    event.target.blur();
    const { compositionIsPlaying, timelineTogglePlay } = this.props;
    if (compositionIsPlaying) timelineTogglePlay();
    this.setState({
      currentlyPlaying: uid,
    });
  };

  handleTrackPause = () => {
    this.setState({
      currentlyPlaying: null,
    });
  };

  liftSelectedToTop = (rows, index, item) => {
    rows.splice(index, 1);
    rows.unshift(item);
  }

  // Check if track uid is present in composition data's
  // audio instances if so, set active
  isActive = (uid, instances) => instances
    .map(instance => instance.uid)
    .includes(uid);

  componentDidMount = () => {
    const musicList = document.querySelector('.MusicTab__list');
    if (musicList) {
      setTimeout(() => {
        musicList.scrollTop = 1;
      }, 3000);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // User starts playing composition, but a track preview is in progress.
    // Set currentlyPlaying to null so that when user stops composition play,
    // the track preview wont continue automatically.

    if (this.props.compositionIsPlaying && prevState.currentlyPlaying !== null) {
      this.setState({
        currentlyPlaying: null,
      });
    }
  }

  render() {
    const {
      entries,
      audioInstances,
      onToggleCurrentTrack,
      compositionIsPlaying,
    } = this.props;
    const {
      currentlyPlaying, filteredGenre, filteredEnergy,
    } = this.state;

    // let activeTrack;
    // if (audioInstances.length > 0) {
    //   activeTrack = entries.find(entry => entry.id === audioInstances[0].id);
    // }

    const trackRows = entries.map((track) => {
      if (filteredGenre && !track.genre.includes(filteredGenre)) {
        return null;
      }
      if (filteredEnergy && !track.energy.includes(filteredEnergy)) {
        return null;
      }
      const playing = (track.uid === currentlyPlaying) && !compositionIsPlaying;

      // eslint-disable-next-line consistent-return
      return (
        <TrackRow
          trackRows={entries}
          key={track.uid}
          active={this.isActive(track.uid, audioInstances)}
          onSelect={onToggleCurrentTrack}
          track={track}
          onPlay={(event, uid) => this.handleTrackPlay(event, uid)}
          onPause={this.handleTrackPause}
          playing={playing}
        />
      );
    });

    // TODO: this was causing some lag when scrolling music list, has no value
    // expect showing/ hidding scroll icon
    // const handleScroll = (e) => {
    //   if (this.state.currentlyPlaying) {
    //     return;
    //   }
    //   const element = e.target;
    //   if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //     this.setState({ showScroll: false });
    //   } else {
    //     this.setState({ showScroll: true });
    //   }
    // };

    return (
      <div className="MusicTab">
        <div className="MusicTab__list scroll-y">
          {trackRows}
        </div>
      </div>
    );
  }
}

MusicTab.propTypes = propTypes;

export default MusicTab;
