import { mapTo, filter, debounceTime, tap, ignoreElements } from 'rxjs/operators';
import { saveComposition } from '@actions/composition';
import { saveUserPreference } from '@actions/brandkit';
import { ofType } from 'redux-observable';
import * as types from '@constants/actionTypes';
import { renderHelper } from '@lib/renderHelper';
import { getLayerById } from '@lib/fabricHelper';
import { timelineProgressJump } from '@actions/timeline';

const autosave = action$ => action$.pipe(
  filter(action => action.autosave),
  /**
   * autosave might be called often, for example on text edit.
   * Rather than debouncing the action itself, the save
   * composition is debunced.
   */
  debounceTime(1000),
  mapTo(saveComposition()),
);
const savePreference = (action$, state$) => action$.pipe(
  filter(action => action.savePreference),
  /**
   debounce used here, no need to make request too often for
   every change(color/logo/font change) in brandkit
   */
  debounceTime(1000),
  mapTo(saveUserPreference(state$)),
);

/**
 * Remove engine layers whenever a text element is removed from
 * the canvas
 */
const removeTextInstance = action$ => action$.pipe(
  ofType(
    types.COMPOSITION_TEXT_INSTANCE_REMOVE,
  ),
  tap((action) => {
    renderHelper.engine.removeLayer(getLayerById(action.payload.sourceId));
  }),
  ignoreElements(),
);

/**
 * Automatically seek to beginning of composition when
 * loading a new one.
 */
const seekToBeginningOnLoad = action$ => action$.pipe(
  ofType(
    types.COMPOSITION_LOAD, types.COMPOSITION_LOAD_GENERATED,
  ),
  mapTo(timelineProgressJump(1)),
);

export {
  autosave,
  removeTextInstance,
  seekToBeginningOnLoad,
  savePreference,
};
