import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash.flow';
import classNames from 'classnames';
import { TEXT_ROW_HEIGHT } from '@constants/timeline';
import './TimelineTextInstance.scss';

const propTypes = {
  msRatio: PropTypes.number.isRequired,
  originalTranslate: PropTypes.number.isRequired, // used to set text position in timeline
  onResize: PropTypes.func,
  onMoveStop: PropTypes.func,
  textInstances: PropTypes.array,
  backgroundInstances: PropTypes.array,
  visibleTo: PropTypes.number,
  visibleFrom: PropTypes.number,
  id: PropTypes.number,
  empty: PropTypes.bool,
  isLinked: PropTypes.bool,
  duration: PropTypes.number,
  compositionDuration: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  onSwapPreview: PropTypes.func,
  swapWithId: PropTypes.number,
  swapDirection: PropTypes.string,
  onPerformSwap: PropTypes.func,
  onProgressSeek: PropTypes.func,
  onStartSeek: PropTypes.func,
  onEndSeek: PropTypes.func,
  onShowTrash: PropTypes.func,
  onHideTrash: PropTypes.func,
  canDelete: PropTypes.number,
  onDelete: PropTypes.func,
  toggleIntercom: PropTypes.func.isRequired,
  onApplyAttributes: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
  selected: false,
};

const timelineTextInstanceSource = {
  beginDrag(props) {
    // Used to show trashcan
    props.onDragStart();
    return {
      ...props,
    };
  },

  endDrag(props, monitor) {
    const { id, originalIndex, list } = monitor.getItem();
    const didDrop = monitor.didDrop();
    if (!didDrop) {
      props.onCancel({ id, originalIndex, list });
    }
    // Used to hide trashcan
    props.onDragEnd();
  },

  isDragging(props, monitor) {
    return props.id === monitor.getItem().id;
  },
};

const timelineTextInstanceTarget = {

  drop(targetProps, monitor) {
    const sourceProps = monitor.getItem();
    sourceProps.handleAdd({
      duration: targetProps.duration,
      originalTranslate: targetProps.originalTranslate,
      visibleFrom: targetProps.visibleFrom,
      visibleTo: targetProps.visibleTo,
      width: targetProps.width,
      empty: targetProps.empty,
      titleInstance: targetProps.titleInstance,
      styles: sourceProps.draggingStyles,
    });
  },
};

// Define props that are passed to the component via props
const sourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});


const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
});

class TimelineTextInstance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msRatio: props.msRatio,
      translate: props.originalTranslate,
      resizing: false,
      moving: false,
      width: props.duration * props.msRatio,
    };
    this.handleDragging = this.handleDragging.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('mouseup', this.handleDragEnd.bind(this));
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.msRatio !== this.props.msRatio) {
      this.setState({
        msRatio: this.props.msRatio,
        width: this.props.duration * this.props.msRatio,
      });
    }

    if (prevProps.originalTranslate !== this.props.originalTranslate) {
      this.setState({
        translate: this.props.originalTranslate,
      });
    }

    if (prevProps.duration !== this.props.duration) {
      this.setState({
        translate: this.props.originalTranslate,
        width: this.props.duration * this.props.msRatio,
      });
    }

    if (prevProps.visibleFrom !== this.props.visibleFrom) {
      this.setState({
        visibleFrom: this.props.visibleFrom,
      });
    }

    if (prevProps.visibleTo !== this.props.visibleTo) {
      this.setState({
        visibleTo: this.props.visibleTo,
      });
    }
  }

  /**
   * Gets the closest text instances of the given text instance (id)
   * and their properties needed for performing moving text instances.
   * Returns an object with information needed to move, resize, swap and snap
   *
   * @param {id} textInstance ID
   * @param {event} mouse event
   * @param {parent} parent node (when event comes from resizing handler
   * parent is the actual text instance we need to work with)
   *
   */

  findBounds(id, event, parent) {
    // Find out the indexes of previous and next text instances
    const {
      textInstances, msRatio, backgroundInstances, compositionDuration,
    } = this.props;
    const thisIndex = textInstances.map(text => text.id).indexOf(id);
    const prevIndex = thisIndex - 1;
    const nextIndex = thisIndex + 1;

    // Compose an object to be used in handleDragging to work out the boundaries
    const thisText = textInstances[thisIndex];
    const prevText = textInstances[prevIndex];
    const nextText = textInstances[nextIndex];
    const timeline = document.querySelector('.Timeline__row--textrow');
    const parentLeft = parent && parent.getBoundingClientRect().left - timeline.offsetLeft;
    const parentWidth = parent && parent.getBoundingClientRect().width;
    const previousTextId = prevText && prevText.id;
    const nextTextId = nextText && nextText.id;
    const previousTextEnds = prevText &&
      (prevText.visibleFrom + prevText.duration) * msRatio;
    const nextTextBegins = nextText && nextText.visibleFrom * msRatio;
    const previousTextDuration = prevText && (prevText.duration) * msRatio;
    const nextTextDuration = nextText && (nextText.duration) * msRatio;
    // Get start and end points of all video clips (for snapping with them)
    const videoClips = backgroundInstances.map(clip => ({
      visibleFrom: clip.visibleFrom,
      visibleTo: clip.visibleTo,
    }));

    return {
      thisId: thisText.id,
      prevId: previousTextId,
      nextId: nextTextId,
      previousTextEnds: Math.floor(previousTextEnds),
      nextTextBegins: Math.floor(nextTextBegins),
      prevJump: Math.floor(previousTextEnds - (previousTextDuration / 2)),
      nextJump: Math.floor(nextTextBegins + (nextTextDuration / 2)),
      timelineLeft: timeline.getBoundingClientRect().left,
      compositionDuration,
      compositionWidth: compositionDuration * msRatio,
      instanceWidth: Math.floor(parentWidth || event.target.offsetWidth),
      instanceLeft: parentLeft,
      instanceVisibleFrom: thisText.visibleFrom,
      backgroundInstances: videoClips,
    };
  }

  /**
   * Compares given value against video clip position and returns the snapped value
   * When moving an instance the value is translate value, when resizing it's width
   * If snapping doesn't happen then it returns just the given value as it was.
   *
   * @param {value}
   * @param {textDuration}
   *
   */

  shouldSnapToVideo(value, textDuration) {
    const snapDistance = 10;
    const { msRatio } = this.props;
    const { instanceLeft, backgroundInstances } = this.boundaries;
    let returnValue = value;
    if (this.state.moving) {
      backgroundInstances.forEach((clip) => {
        if (value > ((clip.visibleFrom * msRatio) - snapDistance) &&
          value < ((clip.visibleFrom * msRatio) + snapDistance)) {
          returnValue = clip.visibleFrom * msRatio;
        } else if (value > ((clip.visibleTo * msRatio) - textDuration - snapDistance) &&
          value < ((clip.visibleTo * msRatio) - textDuration) + snapDistance) {
          returnValue = (clip.visibleTo * msRatio) - textDuration;
        }
      });
    }
    if (this.state.resizing) {
      backgroundInstances.forEach((clip) => {
        if (value > ((clip.visibleTo * msRatio) - instanceLeft - snapDistance) &&
          value < (((clip.visibleTo * msRatio) - instanceLeft) + snapDistance)) {
          returnValue = (clip.visibleTo * msRatio) - instanceLeft;
        }
      });
    }
    return Math.floor(returnValue);
  }

  handleDragging(event) {
    const {
      thisId,
      compositionWidth,
      instanceWidth,
      previousTextEnds,
      nextTextBegins,
      timelineLeft,
      prevJump,
      nextJump,
      prevId,
      nextId,
      instanceLeft,
    } = this.boundaries;
    const {
      msRatio, textInstances, id, onSwapPreview, canDelete,
    } = this.props;

    if (this.state.moving) {
      const dragDistanceX = (event.pageX - this.startX);
      const dragDistanceY = this.startY - event.pageY;
      const newTranslate = this.startTranslate + dragDistanceX;
      const leftLimit = previousTextEnds || 0;
      const rightLimit = nextTextBegins - instanceWidth || (compositionWidth - instanceWidth);
      //
      // Keep the text instance inside the given boundaries
      //
      if (newTranslate < leftLimit) {
        this.setState({
          translate: leftLimit,
        });
        // If dragging outside the boundaries, prepare for swapping
        if (event.pageX - timelineLeft < prevJump) {
          onSwapPreview(thisId, prevId, 'left');
        }
      } else if (newTranslate > rightLimit) {
        this.setState({
          translate: rightLimit,
        });
        if (event.pageX - timelineLeft > nextJump) {
          onSwapPreview(thisId, nextId, 'right');
        }
      } else if (!canDelete) {
        // If we're inside the boundaries set the position, with snapping
        const snappedPosition = this.shouldSnapToVideo(newTranslate, instanceWidth);
        this.setState({
          translate: snappedPosition,
        });
        onSwapPreview(null, null);
        // Also update the playback position
        // this.props.onProgressSeek(Math.floor(snappedPosition / msRatio));
      }
      // If we drag upwards more than 80 pixels, prepare to delete the instance
      if (dragDistanceY > 80) {
        this.props.onShowTrash(thisId);
      } else {
        this.props.onHideTrash();
      }
    }
    //
    // Resizing the instance
    //
    if (this.state.resizing) {
      const instance = textInstances &&
        textInstances.find(i => i.id === id);
      const dragDistanceX = this.startX - event.pageX;
      const newWidth = this.startWidth - dragDistanceX;
      const leftLimit = previousTextEnds || 0;
      const rightLimit = nextTextBegins || compositionWidth;
      //
      // Keep the size inside the given boundaries, with snap
      //
      const newTranslate = this.startTranslate - dragDistanceX;
      const snappedWidth = this.shouldSnapToVideo(newWidth, instanceWidth);
      if (this.state.left) {
        if (this.state.width >=
          this.calculateTextInstanceMinWidth(instance, msRatio)
         && newTranslate > leftLimit) {
          this.setState({
            width: instanceWidth + dragDistanceX,
            translate: instanceLeft - dragDistanceX,
          });
        }
      } else if (instanceLeft + snappedWidth <= rightLimit &&
        snappedWidth > this.calculateTextInstanceMinWidth(instance, msRatio)) {
        this.setState({
          width: snappedWidth,
          translate: instanceLeft,
        });
      }
      // Also update the playback position
      if (this.state.left) {
        // this.props.onProgressSeek(Math.floor(instanceVisibleFrom));
      } else {
        // this.props.onProgressSeek(Math.floor(instanceVisibleFrom + (snappedWidth / msRatio)));
      }
    }
  }

  /**
   * calculates minimum physical width for text element based on its
   * animations so that it cannot be resized to smaller than its
   * animations combined length. This should prevent animations
   * from ending prematurely / breaking.
   *
   * @param {Object} instance - text instance from composition data
   * @param {Number} msRatio
   */
  calculateTextInstanceMinWidth = (instance, msRatio) => {
    let minWidth;

    if (instance && instance.animation) {
      const { in: inAnimations, out: outAnimations } = instance.animation;

      const inAnimationsStartTimes = inAnimations.map(anim => anim.from);
      const inAnimationsEndTimes = inAnimations.map(anim => anim.to);

      const outAnimationsStartTimes = outAnimations.map(anim => anim.from);
      const outAnimationsEndTimes = outAnimations.map(anim => anim.to);

      const earliestInStartTime = Math.min(...inAnimationsStartTimes);
      const latestInEndTime = Math.max(...inAnimationsEndTimes);

      const earliestOutStartTime = Math.min(...outAnimationsStartTimes);
      const latestOutEndTime = Math.max(...outAnimationsEndTimes);

      minWidth = Math.floor(((latestInEndTime - earliestInStartTime) +
        (latestOutEndTime - earliestOutStartTime)) * msRatio);
    } else {
      minWidth = Math.floor(400 * msRatio);
    }

    return minWidth;
  }

  handleSwap() {
    if (this.props.swapWithId !== null) {
      this.props.onPerformSwap();
    }
  }

  handleDragEnd(event) {
    window.removeEventListener('mousemove', this.handleDragging);
    window.removeEventListener('mouseup', this.handleDragEnd);
    if (this.state.moving) {
      // Stop updating playback head
      this.props.onEndSeek();
      if (this.props.canDelete) {
        this.props.onDelete();
        return;
      }
      this.setState({ moving: false });
      if (this.props.swapWithId !== null) {
        this.props.onPerformSwap();
        return;
      }
      // Stop preparing for swap
      this.props.onSwapPreview(null, null);
      // Determine which direction user was moving the element
      const direction = this.startX < event.pageX ? 'right' : 'left';
      const delta = Math.abs(this.state.translate - this.startTranslate);
      if (delta > 1) {
        // Call above for posting the updated position into store
        this.props.onMoveStop(event, delta, this.props.id, direction);
      }
    }
    // If user stopped resizing text instance
    if (this.state.resizing) {
      const delta = this.state.width - this.startWidth;
      const direction = delta < 0 ? 'left' : 'right';
      // Call above for posting the updated size into store
      if (this.state.left) {
        this.props.onMoveStop(event, -delta, this.props.id, 'right');
      }
      this.props.toggleIntercom('boot');
      this.setState({ resizing: false, left: false });
      this.props.onResize(event, direction, event.target, delta, this.props.id, this.props.msRatio);
    }
  }

  handleMouseDown = (event) => {
    const { id } = this.props;
    window.addEventListener('mousemove', this.handleDragging);
    window.addEventListener('mouseup', this.handleDragEnd);
    if (!event.target.classList.contains('TimelineTextInstance__resizeHandle')) {
      this.props.onStartSeek();
      this.startX = event.pageX;
      this.startY = event.pageY;
      this.startTranslate = this.state.translate;
      this.setState({ moving: true });
      this.boundaries = this.findBounds(id, event);
    } else {
      this.setState({ moving: false });
    }
  }

  render() {
    const {
      connectDropTarget,
      isOver,
      id,
      empty,
      onClick,
      selected,
      swapWithId,
      canDelete,
      swapDirection,
      msRatio,
    } = this.props;
    const { moving, resizing } = this.state;
    const instance =
      this.props.textInstances && this.props.textInstances.find(i => i.id === this.props.id);
    const minWidth = this.calculateTextInstanceMinWidth(instance, msRatio);
    const classes = classNames(
      'TimelineTextInstance',
      {
        'TimelineTextInstance--selected': selected,
        'TimelineTextInstance--swapLeft': swapWithId === id && swapDirection === 'left',
        'TimelineTextInstance--swapRight': swapWithId === id && swapDirection === 'right',
        'TimelineTextInstance--canDelete': canDelete === id,
        'TimelineTextInstance--isEmpty': empty,
        'TimelineTextInstance--isOver': isOver,
      },
    );

    return connectDropTarget(
      <div
        className="Timeline__cell"
        style={{ width: '100%' }}
      >
        <div
          onClick={onClick}
          className={classes}
          style={{
            height: TEXT_ROW_HEIGHT,
            minWidth: `${minWidth}px`,
            width: `${resizing ? this.state.width : this.props.duration * this.props.msRatio}px`,
            transform: `translate(${Math.floor(this.state.translate)}px, ${canDelete === id ? '-30px' : '0'}) `,
            transition: moving || resizing ? 'none' : '0.2s all ease',
          }}
          onMouseDown={event => this.handleMouseDown(event)}
        >
          <div
            className='TimelineTextInstance__resizeHandle left'
            onMouseDown={(event) => {
              this.props.onStartSeek();
              this.startX = event.pageX;
              this.startTranslate = this.state.translate;
              this.startWidth = this.props.duration * this.props.msRatio;
              this.props.toggleIntercom('shutdown');
              this.setState({ resizing: true, left: true });
              // Get the boundaries of the parent element,
              // which is the actual text element.
              const parent = event.target.parentNode;
              this.boundaries = this.findBounds(id, event, parent);
            }}
          />
          <div
            className='TimelineTextInstance__resizeHandle right'
            onMouseDown={(event) => {
              this.props.onStartSeek();
              this.startX = event.pageX;
              this.startWidth = this.props.duration * this.props.msRatio;
              this.props.toggleIntercom('shutdown');
              this.setState({ resizing: true });
              const parent = event.target.parentNode;
              this.boundaries = this.findBounds(id, event, parent);
            }}
          />
        </div>
      </div>,
    );
  }
}

TimelineTextInstance.propTypes = propTypes;
TimelineTextInstance.defaultProps = defaultProps;

export default flow(
  DropTarget(['text'], timelineTextInstanceTarget, targetCollect),
  DragSource('text', timelineTextInstanceSource, sourceCollect),
)(TimelineTextInstance);
