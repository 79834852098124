import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { search, searchTags, clearTagSearch, searchRelatedTags, fetchImages } from '@actions/footage';
import { newUpload } from '@actions/upload';
import { stateKeys } from '@constants/footage';
import {
  compositionGenerate,
  compositionSetGenerateTag,
  compositionSetGenerateLogo,
  compositionRemoveGenerateTag,
  compositionSetOptions,
} from '@actions/composition';
import { addComposition } from '@actions/projects';

import GenerateTab from '@components/GenerateTab';

const GenerateTabContainer = (props) => {
  useEffect(() => {
    const { onLoad, footagePages } = props;

    if (footagePages.length === 0) {
      const sorting = 'popular';
      onLoad('upload', 0, sorting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <GenerateTab {...props} />;
};

const mapStateToProps = (state) => {
  const {
    footage, generate, sidebar, upload,
  } = state;
  const selectedFilterData = footage[stateKeys.images.upload];

  const composition = state.composition.present;
  const { activeProjectUid } = sidebar;
  const {
    backgroundInstances, textInstances, width, height,
  } = composition;
  const isNewComposition = backgroundInstances.length === 0 && textInstances.length === 0;
  const { searchSuggestions } = footage;
  return {
    composition,
    generate,
    loading: generate.loading,
    searchSuggestions,
    relatedTags: footage.relatedTags,
    tags: generate.tags,
    logoData: generate.logoData,
    options: generate.optionalParams, // call to action input
    activeProjectUid,
    isNewComposition,
    footagePages: selectedFilterData.entries,
    activeUploads: upload.processing,
    dimensions: { width, height },
  };
};

export default connect(
  mapStateToProps,
  {
    onGenerate: compositionGenerate,
    onLoad: fetchImages,
    onUpload: newUpload,
    onSearch: search,
    onSearchTags: searchTags,
    onClearTagsSearch: clearTagSearch,
    onSearchRelatedTags: searchRelatedTags,
    onAddComposition: addComposition,
    setTag: compositionSetGenerateTag,
    setLogo: compositionSetGenerateLogo,
    setOptions: compositionSetOptions,
    removeTag: compositionRemoveGenerateTag,
  },
)(GenerateTabContainer);
