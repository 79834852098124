function filterByAspectRatio(design, aspectRatio, view) {
  const { width, height } = design.content;

  if (aspectRatio === 'all' && view !== 'dashboard') {
    return true;
  }
  if (aspectRatio === 'horizontal' && width === 1920 && height === 1080 && view !== 'dashboard') {
    return true;
  }
  if (aspectRatio === 'square' && width === 1080 && height === 1080) {
    return true;
  }
  if (aspectRatio === 'vertical' && width === 1080 && height === 1920 && view !== 'dashboard') {
    return true;
  }
  if (
    aspectRatio === 'facebook_vertical' &&
    width === 1080 &&
    height === 1350 &&
    view !== 'dashboard'
  ) {
    return true;
  }
  return false;
}

export {
  filterByAspectRatio,
};
