import React from 'react';
import { Sumovideo } from '@components/Icons';

import './FullscreenLoader.scss';
import { Spinner } from '../Icons';

const FullscreenLoader = () => (
  <div className="FullscreenLoader">
    <div className="FullscreenLoader__wrapper">
      <Sumovideo className="FullscreenLoader__logo" />
      <p>Sumovideo</p>
      <Spinner className="spinner" />
    </div>
  </div>
);

export default FullscreenLoader;
