import React from 'react';
import { connect } from 'react-redux';
import { sidebarTabToggle, sidebarExpandToggle, sidebarShowAdjusters } from '@actions/sidebar';

import Sidebar from '@components/Sidebar';

const SidebarContainer = props => <Sidebar {...props} />;

const mapStateToProps = (state) => {
  const { footage, sidebar } = state;
  const composition = state.composition.present;
  const { backgroundInstances, textInstances, uid } = composition;

  return {
    footage,
    sidebar,
    textInstances,
    backgroundInstances,
    compositionUid: uid,
  };
};

export default connect(mapStateToProps, {
  onTabToggle: sidebarTabToggle,
  onExpandToggle: sidebarExpandToggle,
  onSidebarAdjusters: sidebarShowAdjusters,
})(SidebarContainer);
