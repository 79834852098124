import axios from 'axios';
// import { EventTypes } from 'redux-segment';
// import { authService } from '@services/auth';
import * as types from '@constants/actionTypes';
import { USER_URL } from '@constants/api';

function requestUser() {
  return {
    type: types.USER_REQUEST,
    payload: {},
  };
}

function resetUser() {
  return {
    type: types.USER_RESET,
    payload: {},
  };
}
function receiveUser(user) {
  return {
    type: types.USER_RECEIVE,
    payload: user,
    // we have the most reliable info about new users on the API side.
    // event is better tracked on the server side using the identify method of segment.
    meta: {
      // analytics: {
      //   eventType: EventTypes.identify,
      //   eventPayload: {
      //     userId: user.uid,
      //     traits: {
      //       email: user.email,
      //     },
      //   },
      // },
    },
  };
}


function updateUserSubscription({ subscription, role }) {
  return {
    type: types.USER_UPDATE_SUBSCRIPTION,
    payload: {
      subscription,
      role,
    },
  };
}

function shouldFetchUser(state) {
  const { user } = state;

  if (!user.uid) {
    return true;
  } else if (user.isFetching) {
    return false;
  }

  return user.didInvalidate;
}

function invalidateUser() {
  return {
    type: types.USER_INVALIDATE,
    payload: {},
  };
}

function fetchUser() {
  return async (dispatch, getState) => {
    if (shouldFetchUser(getState())) {
      dispatch(requestUser());

      // await authService.setAuthHeader();

      let fullUrl = USER_URL;

      /**
       * If a user follows a promo link, the code is stored in local storage
       * for the duration of the sign up and sent to the server on the
       * first app load, after which it is cleared.
       */
      const promoCode = localStorage.getItem('promoCode');

      if (promoCode) {
        fullUrl += `?promoCode=${promoCode}`;
      }

      const response = await axios.get(fullUrl)
        .catch(err => console.log('err', err)); // eslint-disable-line no-console
      if (response) {
        dispatch(receiveUser(response.data.data));
      }
    }
  };
}

export {
  fetchUser,
  invalidateUser,
  updateUserSubscription,
  receiveUser,
  resetUser
};
