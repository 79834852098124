import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Spinner from '@components/Spinner';
import ProgressBar from '@components/ProgressBar';


const propTypes = {
  onUpload: PropTypes.func,
  activeUploads: PropTypes.array,
  visible: PropTypes.bool,
};

const defaultProps = {
  visible: false,
};

const Uploads = ({ activeUploads }) => (
  <Fragment>
    {activeUploads
      .filter(upload => upload.type === 'video')
      .map(upload => (
        <div key={upload.uploadId} className="content-grid-col">
          <div className="Active-upload">
            <ProgressBar value={upload.percent} max={100} />
            <Spinner visible={true} />
          </div>
        </div>
      ))}
    </Fragment>
);

Uploads.propTypes = propTypes;
Uploads.defaultProps = defaultProps;

export default Uploads;
