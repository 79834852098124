import React from 'react';
import PropTypes from 'prop-types';

const Text = (props) => {
  const { content } = props;

  function splitTextToLinesWordsAndChars(text) {
    // Splits text string into lines, words and letters that can be animated individually
    return text.replace(/[^\r\n]+/g, (line) => {
      let lineHtml = '';
      const words = line.split(' ');
      words.forEach((word) => {
        lineHtml += `<span class="word">${word.replace(/\S/g, char => `<span class='letter'>${char}</span>`)}</span> `;
      });
      return `<span class='line'>${lineHtml}</span>`;
    });
  }

  function createMarkup(text) {
    // Return splitted HTML string for text element
    return { __html: splitTextToLinesWordsAndChars(text) };
    // if (!isEditing) {
    //   return { __html: splitTextToLinesWordsAndChars(text) };
    // }
    // return { __html: text.replace('\n', '<br/>').replace('\r', '<br/>') };
  }

  return (
    <div
      className="text"
      dangerouslySetInnerHTML={createMarkup(content)}
    ></div>
  );
};

Text.propTypes = {
  content: PropTypes.string,
};

export default Text;
