import * as types from '@constants/actionTypes';
import { stateKeys } from '@constants/footage';
import { removeEntryByUid } from '@reducers/utils';

const initialState = {
  searchTerm: '',
  searchSuggestions: [],
  relatedTags: [],
  searchIsFetching: false,
  sorting: 'popular',
  stockImages: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      {
        "id": 1593527780220851500,
        "src": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ",
        "top": -100.15429122468665,
        "left": 0,
        "maxX": 0,
        "maxY": 0,
        "minX": 0,
        "minY": -200.3085824493733,
        "type": "image",
        "color": "#94694D",
        "isFav": true,
        "likes": 3050,
        "lockX": true,
        "lockY": false,
        "width": 2074,
        "height": 1383,
        "scaleX": 0.9257473481195756,
        "scaleY": 0.9257473481195756,
        "duration": 1351,
        "kenburns": true,
        "playFrom": 0,
        "renderSrc": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjM4NjEyfQ",
        "thumbnail": {
          "hd": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjM4NjEjpg",
          "nano": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjM4NjEjpg",
          "small": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ"
        },
        "visibleTo": 7350,
        "favoriteId": "f79d1a18-c69e-4e18-82e1-a52a801b1d42",
        "zoomEffect": {
          "X": 8,
          "Y": 4,
          "scale": 1.04
        },
        "visibleFrom": 5999,
        "scaleToWidth": true,
        "lockMovementX": true,
        "lockMovementY": false,
        "scaleToHeight": true,
        "thumbnailImageSrc": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ"
      }
    ],
  },
  stockVideos: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      {
        videoId: "hallmark",
        id: 1,
        src: `https://cdn.siteadmin.app/projects/1818cd46-95dd-4204-8550-af77b11c91b2/assets/paint-layers-1.mp4`,
        sourceDuration: 12000,
        thumbnail: {
          nano: "https://d128k4sqj0do41.cloudfront.net/306a8649-d632-474d-8270-1da58c950a1b/images/home-section-2.png",
          hd: "https://d128k4sqj0do41.cloudfront.net/306a8649-d632-474d-8270-1da58c950a1b/images/home-section-2.png",
          video: "https://d128k4sqj0do41.cloudfront.net/306a8649-d632-474d-8270-1da58c950a1b/images/home-section-2.png",
        },
        width: 1920,
        height: 1080,
        keywords: ['hallmark', 'jerome isma-ae'],
        aspectRatio: 1.7777777778,
        aspectNumber: 1,
        type: 'video',
      }
    ],
    relatedTags: [],
  },
  uploadImages: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
  },
  uploadVideos: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
    relatedTags: [],
  },
  stockEmojis: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_happy_1325173.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_love_1325176.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_embarrass_1325177.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_cry_1325179.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_surprise_1325174.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_dead_1325175.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_shy_1325169.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_sleepy_1325168.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_angry_1325170.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_sad_1325172.svg',
      },
    ],
  },
  stockShapes: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      { src: "sample-shapes/accessories.svg" }, { src: "sample-shapes/bathroom.svg" }, { src: "sample-shapes/car.svg" }, { src: "sample-shapes/condiments.svg" }, { src: "sample-shapes/electronics.svg" }, { src: "sample-shapes/flowers.svg" }, { src: "sample-shapes/hardware.svg" }, { src: "sample-shapes/insects.svg" }, { src: "sample-shapes/livingroom.svg" }, { src: "sample-shapes/places.svg" }, { src: "sample-shapes/reptiles.svg" }, { src: "sample-shapes/stones.svg" }, { src: "sample-shapes/travelling.svg" }, { src: "sample-shapes/wedding.svg" },
      { src: "sample-shapes/accomodations.svg" }, { src: "sample-shapes/beach.svg" }, { src: "sample-shapes/carparts.svg" }, { src: "sample-shapes/confetti2.svg" }, { src: "sample-shapes/entertainment.svg" }, { src: "sample-shapes/food.svg" }, { src: "sample-shapes/hardware2.svg" }, { src: "sample-shapes/insects2.svg" }, { src: "sample-shapes/market.svg" }, { src: "sample-shapes/plants.svg" }, { src: "sample-shapes/restaurant.svg" }, { src: "sample-shapes/street.svg" }, { src: "sample-shapes/travelling2.svg" }, { src: "sample-shapes/weights.svg" },
      { src: "sample-shapes/airport.svg" }, { src: "sample-shapes/beach2.svg" }, { src: "sample-shapes/church.svg" }, { src: "sample-shapes/construction.svg" }, { src: "sample-shapes/fabrics.svg" }, { src: "sample-shapes/football.svg" }, { src: "sample-shapes/health.svg" }, { src: "sample-shapes/instruments.svg" }, { src: "sample-shapes/market3.svg" }, { src: "sample-shapes/police.svg" }, { src: "sample-shapes/school.svg" }, { src: "sample-shapes/subjects.svg" }, { src: "sample-shapes/tv.svg" }, { src: "sample-shapes/workplace.svg" },
      { src: "sample-shapes/animals.svg" }, { src: "sample-shapes/beautysalon.svg" }, { src: "sample-shapes/cinema.svg" }, { src: "sample-shapes/conversation.svg" }, { src: "sample-shapes/fantasticanimals.svg" }, { src: "sample-shapes/fruits.svg" }, { src: "sample-shapes/historiceras.svg" }, { src: "sample-shapes/jobs.svg" }, { src: "sample-shapes/mathgeometry.svg" }, { src: "sample-shapes/police2.svg" }, { src: "sample-shapes/science.svg" }, { src: "sample-shapes/supermarket.svg" }, { src: "sample-shapes/universe.svg" },
      { src: "sample-shapes/animalworld.svg" }, { src: "sample-shapes/bedroom.svg" }, { src: "sample-shapes/city.svg" }, { src: "sample-shapes/cooking.svg" }, { src: "sample-shapes/fashion.svg" }, { src: "sample-shapes/garden.svg" }, { src: "sample-shapes/history.svg" }, { src: "sample-shapes/kitchen.svg" }, { src: "sample-shapes/meat.svg" }, { src: "sample-shapes/politics.svg" }, { src: "sample-shapes/senses.svg" }, { src: "sample-shapes/tastes.svg" }, { src: "sample-shapes/vegetables.svg" },
      { src: "sample-shapes/architecture.svg" }, { src: "sample-shapes/beverages.svg" }, { src: "sample-shapes/cleaning.svg" }, { src: "sample-shapes/cosmetics.svg" }, { src: "sample-shapes/fashion2.svg" }, { src: "sample-shapes/gardening.svg" }, { src: "sample-shapes/hobbies.svg" }, { src: "sample-shapes/landscape.svg" }, { src: "sample-shapes/mechanic.svg" }, { src: "sample-shapes/port.svg" }, { src: "sample-shapes/shapes.svg" }, { src: "sample-shapes/tennis.svg" }, { src: "sample-shapes/vegetables2.svg" },
      { src: "sample-shapes/aroundthehouse.svg" }, { src: "sample-shapes/birds.svg" }, { src: "sample-shapes/clothes.svg" }, { src: "sample-shapes/dessert.svg" }, { src: "sample-shapes/festivity.svg" }, { src: "sample-shapes/gasstation.svg" }, { src: "sample-shapes/home.svg" }, { src: "sample-shapes/landscape2.svg" }, { src: "sample-shapes/metals.svg" }, { src: "sample-shapes/prison.svg" }, { src: "sample-shapes/shoes.svg" }, { src: "sample-shapes/terms.svg" }, { src: "sample-shapes/volleyball.svg" },
      { src: "sample-shapes/art.svg" }, { src: "sample-shapes/bodyparts.svg" }, { src: "sample-shapes/colors.svg" }, { src: "sample-shapes/dessert2.svg" }, { src: "sample-shapes/festivity2.svg" }, { src: "sample-shapes/gasstation2.svg" }, { src: "sample-shapes/hospital.svg" }, { src: "sample-shapes/law.svg" }, { src: "sample-shapes/music.svg" }, { src: "sample-shapes/prison2.svg" }, { src: "sample-shapes/shopping.svg" }, { src: "sample-shapes/tobacco.svg" }, { src: "sample-shapes/watersports.svg" },
      { src: "sample-shapes/baby.svg" }, { src: "sample-shapes/bones.svg" }, { src: "sample-shapes/colors2.svg" }, { src: "sample-shapes/disco.svg" }, { src: "sample-shapes/firemen.svg" }, { src: "sample-shapes/geography.svg" }, { src: "sample-shapes/hotel.svg" }, { src: "sample-shapes/lexicon.svg" }, { src: "sample-shapes/news.svg" }, { src: "sample-shapes/professions.svg" }, { src: "sample-shapes/shops.svg" }, { src: "sample-shapes/tools.svg" }, { src: "sample-shapes/weapons.svg" },
      { src: "sample-shapes/bank.svg" }, { src: "sample-shapes/breakfast.svg" }, { src: "sample-shapes/computer.svg" }, { src: "sample-shapes/economy.svg" }, { src: "sample-shapes/fish.svg" }, { src: "sample-shapes/groceriesshops.svg" }, { src: "sample-shapes/house.svg" }, { src: "sample-shapes/library.svg" }, { src: "sample-shapes/nicknames.svg" }, { src: "sample-shapes/realestate.svg" }, { src: "sample-shapes/smartphone.svg" }, { src: "sample-shapes/tools2.svg" }, { src: "sample-shapes/weather.svg" },
      { src: "sample-shapes/bar.svg" }, { src: "sample-shapes/calendar.svg" }, { src: "sample-shapes/computerscience.svg" }, { src: "sample-shapes/economy2.svg" }, { src: "sample-shapes/fishes.svg" }, { src: "sample-shapes/gym.svg" }, { src: "sample-shapes/icecreamparlor.svg" }, { src: "sample-shapes/life.svg" }, { src: "sample-shapes/pharmacy.svg" }, { src: "sample-shapes/relations.svg" }, { src: "sample-shapes/sports.svg" }, { src: "sample-shapes/toys.svg" }, { src: "sample-shapes/weather2.svg" },
      { src: "sample-shapes/basketball.svg" }, { src: "sample-shapes/calendar2.svg" }, { src: "sample-shapes/concepts.svg" }, { src: "sample-shapes/education.svg" }, { src: "sample-shapes/fishing.svg" }, { src: "sample-shapes/hairsalon.svg" }, { src: "sample-shapes/ideas.svg" }, { src: "sample-shapes/life2.svg" }, { src: "sample-shapes/photography.svg" }, { src: "sample-shapes/religions.svg" }, { src: "sample-shapes/stadium.svg" }, { src: "sample-shapes/trainstation.svg" }, { src: "sample-shapes/web.svg" },
    ],
  },
  favoriteImages: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
  },
  favoriteVideos: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
    relatedTags: [],
  },
};

export default function footage(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_FOOTAGE: {
      const key = action.payload.key;
      return {
        ...state,
        [key]: {
          ...state[key],
          entries: [action.payload.data, ...state[key].entries],
        }
      };
    }
    case types.FOOTAGE_REQUEST_IMAGES: {
      const key = stateKeys.images[action.payload.filter];

      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: true,
          searchTerm: '',
        },
      };
    }

    case types.FOOTAGE_RECEIVE_IMAGES: {
      const {
        filter,
        entries,
        searchTerm,
        page,
        receivedAt,
      } = action.payload;

      const imageFootageKey = stateKeys.images[filter];

      /**
       * New page number is set into place only if there are
       * results returned from the call.
       * At the moment API server returns a status 200 with
       * empty entries array if no footage is found for the
       * offset / pagenumber.
       * If pagenumber would be always set, the state would
       * be full of empty arrays and currentpage would be wrong.
       */
      const oldEntries = state[imageFootageKey].entries;
      const newEntries = page > 0 ? [...oldEntries, entries] : [entries];

      return {
        ...state,
        [imageFootageKey]: {
          entries: newEntries,
          isFetching: false,
          didInvalidate: false,
          lastUpdated: receivedAt,
          currentPage: page,
          searchTerm,
        },
      };
    }

    case types.FOOTAGE_RELATED_TAGS_RECEIVE: {
      return {
        ...state,
        relatedTags: action.payload.relatedTags,
      };
    }
    case types.FOOTAGE_RECEIVE_SVG: {
      const { filter, svgData, src } = action.payload;
      if (filter === 'emojis') {
        const { entries } = state.stockEmojis;
        const newEntries = entries.map((shape) => {
          if (shape.src !== src) return shape;
          return {
            ...shape,
            svgData,
          };
        });
        return {
          ...state,
          stockEmojis: {
            ...state.stockEmojis,
            entries: newEntries,
          },
        };
      }
      const { entries } = state.stockShapes;
      const newEntries = entries.map((shape) => {
        if (shape.src !== src) return shape;
        return {
          ...shape,
          svgData,
        };
      });
      return {
        ...state,
        stockEmojis: {
          ...state.stockEmojis,
          entries: newEntries,
        },
      };
    }

    case types.FOOTAGE_REQUEST_VIDEOS: {
      const key = stateKeys.videos[action.payload.filter];

      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: true,
          searchTerm: '',
        },
      };
    }
    case types.FOOTAGE_ADD_TO_FAVORITE: {
      const { favFootage, type, favoriteId } = action.payload;
      if (type === 'video') {
        const { entries } = state.favoriteVideos;
        const alreadyFavorited = entries[0] || [];
        return {
          ...state,
          favoriteVideos: {
            ...state.favoriteVideos,
            entries: [[...alreadyFavorited, { ...favFootage, favoriteId }]],
          },
        };
      }
      const { entries } = state.favoriteImages;
      const alreadyFavorited = entries[0] || [];

      return {
        ...state,
        favoriteImages: {
          ...state.favoriteImages,
          entries: [[...alreadyFavorited, { ...favFootage, favoriteId, isFav: true }]],
        },
      };
    }
    case types.FOOTAGE_REMOVE_FROM_FAVORITE: {
      const { favoriteId, type } = action.payload;
      if (type === 'video') {
        const { entries } = state.favoriteVideos;
        const newEntries = entries.map(page =>
          page.filter(entry => entry.favoriteId !== favoriteId));

        return {
          ...state,
          favoriteVideos: {
            ...state.favoriteVideos,
            entries: newEntries,
          },
        };
      }
      const { entries } = state.favoriteImages;
      const newEntries = entries.map(page =>
        page.filter(entry => entry.favoriteId !== favoriteId));
      return {
        ...state,
        favoriteImages: {
          ...state.favoriteImages,
          entries: newEntries,
        },
      };
    }

    case types.FOOTAGE_CHANGE_SORTING: {
      return {
        ...state,
        sorting: action.payload.type,
      };
    }

    case types.FOOTAGE_RECEIVE_VIDEOS: {
      const videoFootageKey = stateKeys.videos[action.payload.filter];
      const stateData = state[videoFootageKey];

      /**
       * New page number is set into place only if there are
       * results returned from the call.
       * At the moment API server returns a status 200 with
       * empty entries array if no footage is found for the
       * offset / pagenumber.
       * If pagenumber would be always set, the state would
       * be full of empty arrays and currentpage would be wrong.
       */
      let newPageNumber = stateData.currentPage;

      const newEntries = [...stateData.entries];

      if (action.payload.entries.length > 0) {
        newEntries[action.payload.page] = action.payload.entries;
        newPageNumber = action.payload.page;
      }

      const newState = {
        ...state,
        [videoFootageKey]: {
          ...state[videoFootageKey],
          entries: newEntries,
          isFetching: false,
          didInvalidate: false,
          lastUpdated: action.payload.receivedAt,
          currentPage: newPageNumber,
        },
      };


      return newState;
    }

    case types.FOOTAGE_SEARCH_REQUEST: {
      const {
        filter,
        type,
      } = action.payload;
      const videoFootageKey = stateKeys[type][filter];
      return {
        ...state,
        [videoFootageKey]: {
          ...state[videoFootageKey],
          isFetching: true,
        },
      };
    }

    case types.FOOTAGE_SEARCH_RECEIVE: {
      const {
        filter,
        type,
        entries,
        relatedTags,
        searchTerm,
        page,
      } = action.payload;
      const videoFootageKey = stateKeys[type][filter];

      const oldEntries = state[videoFootageKey].entries;
      const newEntries = page > 0 ? [...oldEntries, entries] : [entries];

      const newState = {
        ...state,
        [videoFootageKey]: {
          ...state[videoFootageKey],
          entries: newEntries,
          relatedTags,
          isFetching: false,
          didInvalidate: true,
          lastUpdated: action.payload.receivedAt,
          searchTerm,
          currentPage: page,
        },
      };

      return newState;
    }

    case types.FOOTAGE_INVALIDATE_VIDEOS: {
      const footageKey = stateKeys.video[action.payload.filter];
      return {
        ...state,
        [footageKey]: {
          ...state[footageKey],
          didInvalidate: true,
        },
      };
    }

    case types.FOOTAGE_INVALIDATE_IMAGES: {
      const footageKey = stateKeys.image[action.payload.filter];
      return {
        ...state,
        [footageKey]: {
          ...state[footageKey],
          didInvalidate: true,
        },
      };
    }

    case types.FOOTAGE_TAG_SEARCH_REQUEST:
      return {
        ...state,
        searchSuggestions: [],
        searchIsFetching: true,
      };

    case types.FOOTAGE_TAG_SEARCH_RECEIVE:
      return {
        ...state,
        searchSuggestions: action.payload.entries,
        searchIsFetching: false,
      };

    case types.FOOTAGE_TAG_SEARCH_CLEAR:
      return {
        ...state,
        searchSuggestions: [],
        searchIsFetching: false,
      };

    case types.UPLOAD_DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        uploadVideos: {
          ...state.uploadVideos,
          entries: removeEntryByUid(state.uploadVideos.entries, action.payload.uploadUid),
        },
      };

    case types.UPLOAD_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImages: {
          ...state.uploadImages,
          entries: removeEntryByUid(state.uploadImages.entries, action.payload.uploadUid),
        },
      };

    default:
      return state;
  }
}
