const initialState = {
  steps: [
    {
      target: '.NavigationItem:nth-of-type(1)',
      title: 'Project Menu',
      content: 'Here you can create new projects and videos. Individual videos are stored within projects.',
      placement: 'right',
      event: 'click',
    },
    {
      target: '.NavigationItem:nth-of-type(2)',
      title: 'Video Menu',
      content: 'Browse beautiful video footage or upload your own videos. To add them to your video just drag & drop the video clips on to the timeline.',
      placement: 'right',
      event: 'click',
    },
    {
      target: '.NavigationItem:nth-of-type(3)',
      title: 'Image Menu',
      content: 'Browse photos and styled backgrounds. You can also upload your own images and logo.',
      placement: 'right',
      event: 'click',
    },
    {
      target: '.NavigationItem:nth-of-type(4)',
      title: 'Title Menu',
      content: 'Drag & drop text styles in to the timeline. You can then trim the text’s duration. To edit the title’s text simply click the text to activate it for editing.',
      placement: 'right',
      event: 'click',
    },
    {
      target: '.NavigationItem:nth-of-type(5)',
      title: 'Music Menu',
      content: 'What would a video be without music? Listen to our music catalogue and select a song of your liking.',
      placement: 'right',
      event: 'click',
    },
    {
      target: '.Timeline',
      title: 'Timeline',
      content: 'This is where the magic happens! You can rearrange, trim and add video clips.',
      placement: 'top',
      event: 'click',
    },
    {
      target: '.upper-canvas',
      title: 'Canvas',
      content: 'This is canvas where you can edit texts and logos. You will preview your changes live in canvas when playing the video.',
      placement: 'bottom',
      event: 'click',
    },
    {
      target: '.tutorial-play',
      title: 'Play / Pause',
      content: 'Press play to play your video - simple isn’t it? You can also preview your video in fullscreen mode. Pro tip: You can also toggle Play / Pause from your keyboard’s Spacebar.',
      placement: 'top',
      event: 'click',
    },
    {
      target: '.tutorial-help',
      title: 'Help / Chat',
      content: 'If you need help with anything just open a chat and we are happy to help you!',
      placement: 'top',
      event: 'click',
    },
    {
      target: '.tutorial-download',
      title: 'Download',
      content: 'When you are ready with your video go to the download page and after quick rendering you will see your finished video.',
      placement: 'top',
      event: 'click',
    },
    {
      target: '.ProjectCard',
      title: 'Project Folder',
      content: 'Start by choosing your first project and rename video inside to what you want to create!',
      placement: 'top-end',
      event: 'click',
    },
  ],
};

export default function tutorial(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
