import React from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from '@components/ConfirmDialog';
import Button from '@components/Button';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  resourceName: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  visible: false,
  resourceName: '',
};

function ConfirmDeleteDialog(props) {
  return (
    <ConfirmDialog
      visible={props.visible}
      title={props.title}
      onClose={props.onCancel}
      cancelButton={
        <Button onClick={props.onCancel}>
          Cancel
        </Button>
      }
      confirmButton={
        <Button
          color="red"
          onClick={props.onDelete}
        >
          Delete
        </Button>
      }
    >
      Are you sure you want to delete &quot;{props.resourceName}&quot; ? Undoing is not possible!
    </ConfirmDialog>
  );
}

ConfirmDeleteDialog.propTypes = propTypes;
ConfirmDeleteDialog.defaultProps = defaultProps;

export default ConfirmDeleteDialog;
