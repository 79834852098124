import * as types from '@constants/actionTypes';
import { SUBSCRIPTIONS_URL } from '@constants/api';
import axios from 'axios';
import { ActionCreators } from 'redux-undo';

const doUndo = () => (dispatch) => {
  console.log("--undo")
  dispatch(ActionCreators.undo());
};

const doRedo = () => (dispatch) => {
  console.log("--redo")
  dispatch(ActionCreators.redo());
};

const triggerNotification = ({ message, kind }) => ({
  type: types.APP_TRIGGER_NOTIFICATION,
  notify: {
    message,
    kind,
  },
});
function updateApp(state) {
  return {
    type: types.APP_UPDATE,
    payload: state,
  };
}
function appToggleChat() {
  return {
    type: types.APP_TOGGLE_CHAT,
  };
}


function appToggleSideMenu() {
  return {
    type: types.APP_TOGGLE_SIDE_MENU,
  };
}

function appToggleShortcuts() {
  return {
    type: types.APP_TOGGLE_SHORTCUTS,
  };
}

function setAutosaving(state) {
  return {
    type: types.APP_SET_AUTOSAVING,
    payload: {
      saving: state,
    },
  };
}

function toggleWelcomeScreen() {
  return {
    type: types.APP_TOGGLE_WELCOME_SCREEN,
  };
}

function toggleSetting() {
  return {
    type: types.APP_TOGGLE_USER_ACCOUNT_MANAGER,
  };
}

function toggleLightTheme() {
  return {
    type: types.APP_TOGGLE_LIGHT_THEME,
  };
}

function setGuides(state) {
  return {
    type: types.APP_SET_GUIDES,
    payload: {
      showGuideX: state.showGuideX,
      showGuideY: state.showGuideY,
    },
  };
}

function hideChat() {
  return {
    type: types.APP_HIDE_CHAT,
  };
}

function toggleSubscriptionSuccess(res, canceled, cancellationReason) {
  const { cancelAt } = res;
  return {
    type: types.USER_CANCEL_SUBSCRIPTION,
    payload: {
      canceled,
      loading: false,
      cancelAt,
      cancellationReason,
    },
  };
}

function requestToggleSubscription(cancel) {
  return {
    type: types.USER_CANCEL_REQUEST,
    payload: {
      canceled: cancel,
      loading: true,
    },
  };
}

const toggleSubscription = (planId, cancel, cancellationReason) => async (dispatch) => {
  const payload = {
    planId,
    cancel,
  };
  dispatch(requestToggleSubscription(cancel));
  const url = `${SUBSCRIPTIONS_URL}/cancel`;
  const response = await axios.post(url, payload)
    .catch(err => console.log(err)); // eslint-disable-line no-console
  dispatch(toggleSubscriptionSuccess(response.data, cancel, cancellationReason));
};

export {
  doUndo,
  doRedo,
  triggerNotification,
  appToggleChat,
  appToggleSideMenu,
  appToggleShortcuts,
  setAutosaving,
  toggleWelcomeScreen,
  toggleSetting,
  setGuides,
  hideChat,
  toggleLightTheme,
  toggleSubscription,
  updateApp,
};
