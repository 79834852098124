/* eslint no-param-reassign: "error" */
const scaleForContain = (instance, compositionDimention) => {
  const { orientation, compositionWidth, compositionHeight } = compositionDimention;

  if (orientation === 'vertical' || orientation === 'facebook_vertical') {
    const scaledLayer = instance.scaleToWidth(1080);
    const { scaleX, scaleY, height } = scaledLayer;
    instance.scaleX = scaleX;
    instance.scaleY = scaleY;
    instance.top = (compositionHeight - (height * scaleY)) / 2;
    instance.left = 0;
    instance.lockMovementY = false;
    instance.lockMovementX = true;
    instance.minX = 0;
    instance.maxY = compositionHeight - (height * scaleY);
    instance.maxX = 0;
    instance.minY = 0;
  } else {
    const scaledLayer = instance.scaleToHeight(1080);
    const { scaleX, scaleY, width } = scaledLayer;
    instance.scaleX = scaleX;
    instance.scaleY = scaleY;
    instance.left = (compositionWidth - (width * scaleX)) / 2;
    instance.top = 0;
    instance.lockMovementY = true;
    instance.lockMovementX = false;
    instance.minX = 0;
    instance.minY = 0;
    instance.maxX = compositionWidth - (width * scaleX);
    instance.maxY = 0;
  }
  return instance;
};

const colorSwatches = [
  'transparent',
  '#ffffff',
  '#000000',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export {
  scaleForContain,
  colorSwatches,
};
