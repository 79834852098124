import * as types from '@constants/actionTypes';

const redirectUrlSuccess = process.env.STRIPE_SUCCESS_URL;
const redirectUrlCancel = process.env.STRIPE_REDIRECT_URL;
const monthlyPlan = process.env.STRIPE_PLAN_MONTHLY;
const annualPlan = process.env.STRIPE_PLAN_ANNUAL;
// const oneVideo = process.env.STRIPE_SKU_ONE_VIDEO;
const lifetimeAccess = process.env.STRIPE_SKU_LIFETIME;
const lifetimePromo70 = process.env.STRIPE_SKU_LIFETIME_PROMO70;
const annualPromo70 = process.env.STRIPE_PLAN_ANNUAL_PROMO70;
const lifetimePromo50 = process.env.STRIPE_SKU_LIFETIME_PROMO50;
const annualPromo50 = process.env.STRIPE_PLAN_ANNUAL_PROMO50;
const monthlyPromo50 = process.env.STRIPE_PLAN_MONTHLY_PROMO50;
const lifetimePromo30 = process.env.STRIPE_SKU_LIFETIME_PROMO30;
const annualPromo30 = process.env.STRIPE_PLAN_ANNUAL_PROMO30;
const monthlyPromo30 = process.env.STRIPE_PLAN_MONTHLY_PROMO30;
// const earlyAccess = process.env.STRIPE_SKU_EARLYACCESS;
// const lifetimeAppsumo = process.env.STRIPE_SKU_LIFETIME_APPSUMO;

const initialState = {
  entries: [
    {
      items: [
        {
          plan: annualPlan,
          quantity: 1,
        },
      ],
      title: 'Yearly',
      features: [
        'No watermark',
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      priceShown: '19',
      normal: '49',
      amount: 22800,
      priceDescription: '/mo.',
      isLoading: false,
      active: true,
      buttonDescription: 'Save now',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Yearly',
      analyticsEventSuccess: 'Purchase Yearly',
      billedAnually: true,
    },
    {
      items: [
        {
          plan: monthlyPlan,
          quantity: 1,
        },
      ],
      title: 'Monthly',
      features: [
        'No watermark',
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      priceShown: '49',
      amount: 4900,
      priceDescription: '/mo.',
      isLoading: false,
      active: true,
      buttonDescription: 'Yes, I want this',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Monthly',
      analyticsEventSuccess: 'Purchase Monthly',
    },
  ],
  entriesPromo50: [
    {
      items: [
        {
          plan: monthlyPromo50,
          quantity: 1,
        },
      ],
      title: 'Monthly',
      features: [
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      priceShown: '24.50',
      amount: 2450,
      normal: '49',
      priceDescription: '/mo.',
      isLoading: false,
      active: true,
      promo: true,
      promoDescription: 'DISCOUNT -50%',
      buttonDescription: 'Subscribe',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Monthly',
      analyticsEventSuccess: 'Purchase Monthly',
    },
    {
      items: [
        {
          plan: annualPromo50,
          quantity: 1,
        },
      ],
      title: 'Yearly',
      features: [
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      normal: '19',
      priceShown: '9.50',
      amount: 11400,
      priceDescription: '/mo.',
      isLoading: false,
      active: false,
      limited: true,
      promo: true,
      promoDescription: 'DISCOUNT -50%',
      buttonDescription: 'Subscribe',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Yearly',
      analyticsEventSuccess: 'Purchase Yearly',
      billedAnually: true,
    },
    {
      items: [
        {
          sku: lifetimePromo50,
          quantity: 1,
        },
      ],
      title: 'Lifetime access',
      features: [
        'Create videos up to 4 min long',
        '50 video downloads / month',
      ],
      normal: '499',
      priceShown: '249',
      amount: 24900,
      priceDescription: '',
      isLoading: false,
      active: false,
      limited: true,
      promo: true,
      promoDescription: 'DISCOUNT -50%',
      buttonDescription: 'Buy now',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Lifetime',
      analyticsEventSuccess: 'Purchase Lifetime',
    },
  ],
  entriesPromo70: [
    {
      items: [
        {
          plan: annualPromo70,
          quantity: 1,
        },
      ],
      title: 'Yearly',
      features: [
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      normal: '228',
      priceShown: '5.70',
      amount: 6840,
      priceDescription: '/mo',
      isLoading: false,
      active: false,
      limited: false,
      promo: true,
      promoDescription: 'DISCOUNT 70%',
      buttonDescription: 'Subscribe',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Yearly',
      analyticsEventSuccess: 'Purchase Yearly',
      billedAnually: true,
    },
    {
      items: [
        {
          sku: lifetimePromo70,
          quantity: 1,
        },
      ],
      title: 'Lifetime access',
      features: [
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      normal: '499',
      priceShown: '149.70',
      amount: 14970,
      priceDescription: '/Lifetime',
      isLoading: false,
      active: false,
      limited: false,
      promo: true,
      promoDescription: 'DISCOUNT 70%',
      buttonDescription: 'Buy now',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Lifetime',
      analyticsEventSuccess: 'Purchase Lifetime',
    },
  ],
  entriesPromo30: [
    {
      items: [
        {
          plan: monthlyPromo30,
          quantity: 1,
        },
      ],
      title: 'Monthly',
      features: [
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      normal: '49',
      priceShown: '34',
      amount: 3400,
      priceDescription: '/month',
      isLoading: false,
      active: true,
      limited: true,
      promo: true,
      promoDescription: 'DISCOUNT -30%',
      buttonDescription: 'Subscribe',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Monthly',
      analyticsEventSuccess: 'Purchase Monthly',
    },
    {
      items: [
        {
          plan: annualPromo30,
          quantity: 1,
        },
      ],
      title: 'Yearly',
      features: [
        'Create videos up to 4 min long',
        'Unlimited downloads',
      ],
      normal: '19',
      priceShown: '13',
      amount: 15900,
      priceDescription: '/mo.',
      isLoading: false,
      active: true,
      limited: true,
      promo: true,
      promoDescription: 'DISCOUNT -30%',
      buttonDescription: 'Subscribe',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Yearly',
      analyticsEventSuccess: 'Purchase Yearly',
      billedAnually: true,
    },
    {
      items: [
        {
          sku: lifetimePromo30,
          quantity: 1,
        },
      ],
      title: 'Lifetime access',
      features: [
        'Create videos up to 4 min long',
        '50 video downloads / month',
      ],
      normal: '499',
      priceShown: '349',
      amount: 34900,
      priceDescription: '',
      isLoading: false,
      active: true,
      limited: true,
      promo: true,
      promoDescription: 'DISCOUNT -30%',
      buttonDescription: 'Subscribe',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Lifetime',
      analyticsEventSuccess: 'Purchase Lifetime',
    },
  ],
  freeWorkshop: [
    {
      items: [
        {
          plan: monthlyPromo50,
          quantity: 1,
        },
      ],
      title: 'Workshop Pass',
      features: [
        'Create videos up to 4 min long',
        'Expires after workshop',
      ],
      priceShown: '0',
      amount: '000',
      normal: '149',
      priceDescription: '/workshop',
      isLoading: false,
      active: true,
      buttonDescription: 'Start',
      // successUrl: redirectUrlSuccess,
      // cancelUrl: redirectUrlCancel,
    },
  ],
  freeTrial: [
    {
      items: [
        {
          plan: monthlyPlan,
          quantity: 1,
        },
      ],
      title: 'Two Week Pass',
      features: [
        'Create videos up to 4 min long',
        'Expires after two weeks',
      ],
      priceShown: '0',
      amount: '000',
      normal: '49',
      priceDescription: '',
      isLoading: false,
      active: true,
      buttonDescription: 'Start',
      // successUrl: redirectUrlSuccess,
      // cancelUrl: redirectUrlCancel,
    },
  ],
  freeTVC: [
    {
      items: [
        {
          plan: monthlyPlan,
          quantity: 1,
        },
      ],
      title: 'TVC - One Year Pass',
      features: [
        'Create videos up to 4 min long',
        '10 video downloads / month',
      ],
      priceShown: '0',
      amount: '000',
      normal: '49',
      priceDescription: '',
      isLoading: false,
      active: true,
      buttonDescription: 'Start',
      // successUrl: redirectUrlSuccess,
      // cancelUrl: redirectUrlCancel,
    },
  ],
  freeSeedrs: [
    {
      items: [
        {
          plan: monthlyPlan,
          quantity: 1,
        },
      ],
      title: 'Seedrs - Two Year Pass',
      features: [
        'Create videos up to 4 min long',
        '10 video downloads / month',
      ],
      priceShown: '0',
      amount: '000',
      normal: '49',
      priceDescription: '',
      isLoading: false,
      active: true,
      buttonDescription: 'Start',
      // successUrl: redirectUrlSuccess,
      // cancelUrl: redirectUrlCancel,
    },
  ],
  earlyAccess: [
    {
      items: [
        {
          sku: lifetimeAccess,
          quantity: 1,
        },
      ],
      title: 'Lifetime Subscription',
      features: [
        'Create videos up to 4 min long',
        'Unlimited video downloads',
      ],
      priceShown: '399',
      amount: 39900,
      priceDescription: '/Lifetime',
      isLoading: false,
      active: false,
      limited: true,
      buttonDescription: 'Buy Now',
      successUrl: redirectUrlSuccess,
      cancelUrl: redirectUrlCancel,
      analyticsEventName: 'Purchase Attempt Lifetime',
      analyticsEventSuccess: 'Purchase Lifetime',
    },
  ],
  appsumoEntries: [
    {
      title: 'Appsumo access',
      features: [
        'Real time video preview',
        '100 000 HD video clips',
        '800´000 high resolution photos',
        'Support for user media uploads',
        '200 high quality songs',
        'Patent pending AI powered automatic video generation tool(currently in beta)',
        'Ready-made video templates',
        'Easy to use text editing tools',
        'Ready-made text designs',
      ],
      limited: true,
      buttonDescription: 'REDEEM CODE',
    },
  ],
  testimonial: [
    {
      content:
        'The editor is very ROBUST and FAST! I haven’t quite found something so simple and fast.',
      author: '- Mike Lester Rosales',
      jobTitle: 'Head of Design and Technology, Mrdzyn',
    },
    {
      content:
        'If you are looking for the easiest way to create videos AdLaunch is definitely your answer.',
      author: '- Elina',
      jobTitle: 'Freelance Marketer',
    },
    {
      content:
        'We were looking for a professional video with a little something more, and AdLaunch made it happen.',
      author: '- Celia Ogna',
      jobTitle: 'Sales Representative, Triprepublic',
    },
    {
      content:
        'With AdLaunch we got a really nice video we can use when pitching or promoting our company.',
      author: '- Annika Järvelin',
      jobTitle: 'Co-Founder, Duara',
    },
  ],
  commons: [
    '1 000 000 Unsplash photos',
    'Text presets',
    'Upload your own content',
    'Multiple video formats',
    'Brand kit',
    '100 000 Shutterstock videos',
    'Plenty of text animations',
    'Ready-made video templates',
    'Different shapes and emojis',
    'No watermark',
    '200 Commercially licensed tracks',
    'Beautiful fonts',
    'AI-powered video generator',
    'Instant Preview',
    'Reseller rights',
  ],
};

const setLoading = (entries, name) =>
  entries.map((entry) => {
    if (entry.name === name) {
      return {
        ...entry,
        isLoading: true,
      };
    }

    return entry;
  });

const clearLoading = entries =>
  entries.map(entry => ({
    ...entry,
    isLoading: false,
  }));

export default function plans(state = initialState, action) {
  switch (action.type) {
    case types.PAYMENT_RECEIVE:
      return {
        ...state,
        entries: clearLoading(state.entries),
      };

    case types.PAYMENT_REQUEST:
      return {
        ...state,
        entries: setLoading(state.entries, action.payload.name),
      };

    default:
      return state;
  }
}
