import BezierEasing from 'bezier-easing';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bounce: BezierEasing(0.68, -0.55, 0.265, 1.55),
  easeOutQuad: BezierEasing(0.25, 0.46, 0.45, 0.94),
  easeInQuart: BezierEasing(0.895, 0.03, 0.685, 0.22),
  easeOutQuart: BezierEasing(0.165, 0.84, 0.44, 1),
  easeInOutQuad: BezierEasing(0.455, 0.03, 0.515, 0.955),
};
