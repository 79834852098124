import React from 'react';
import PropTypes from 'prop-types';
import { Plus, Delete, FavoriteIcon } from '@components/Icons';
import classnames from 'classnames';
import './ImageThumbnail.scss';

const propTypes = {
  filename: PropTypes.string.isRequired,
  filter: PropTypes.string,
  showDelete: PropTypes.bool,
  isFav: PropTypes.bool,
  isList: PropTypes.bool,
  onFavoriteImage: PropTypes.func,
  onDelete: PropTypes.func,
};

const defaultProps = {
  showDelete: false,
};

class ImageThumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.elementRef = React.createRef();
    /**
     * Safari doesn't support IntersectionObserver yet (17.9.2018)
     * and since lazyloading images is only a enhancement, it is disabled
     * in Safari
     */
    this.supportsObserver = 'IntersectionObserver' in window;
  }
  componentDidMount() {
    const { filename } = this.props;

    if (!this.supportsObserver) return;

    const config = {
      threshold: 0,
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry;

        if (isIntersecting && this.elementRef.current) {
          this.elementRef.current.style.backgroundImage = `url(${filename})`;
          this.observer = this.observer.disconnect();
        }
      });
    }, config);

    this.observer.observe(this.elementRef.current);
  }

  renderDeleteButton = (visible) => {
    if (!visible) return null;

    return (
      <div className="ImageThumbnail__delete" onClick={this.props.onDelete}>
        <Delete />
      </div>
    );
  };

  render() {
    const {
      filename, isFav, showDelete, isList,
    } = this.props;
    const style = {
      backgroundColor: 'var(--color4)',
    };

    if (!this.supportsObserver) {
      style.backgroundImage = `url(${filename})`;
    }
    const favoriteIconClass = classnames('ImageThumbnail__favorite-icon', {
      isFavorited: isFav,
    });

    return (
      <div className="ImageThumbnail">
        <div style={style} ref={this.elementRef} className="ImageThumbnail__image" />
        {!isList && (
          <div className="ImageThumbnail__controls">
            <div className="ImageThumbnail__controls-content">
              <Plus />
              {!isFav && this.props.filter === 'stock' && (
                <button className={favoriteIconClass} onClick={e => this.props.onFavoriteImage(e)}>
                  <FavoriteIcon />
                </button>
              )}
            </div>
          </div>
        )}
        {this.renderDeleteButton(showDelete)}
      </div>
    );
  }
}

ImageThumbnail.propTypes = propTypes;
ImageThumbnail.defaultProps = defaultProps;

export default ImageThumbnail;
