import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@components/ButtonGroup';
import DesignThumbnail from '@components/DesignThumbnail';
import Button from '@components/Button';
import ToolTip from '@components/ToolTip';
import { Mute, Unmute } from '@components/Icons';
import './designTab.scss';

const defaultProps = {
  type: 'horizontal',
};

const propTypes = {
  onAddComposition: PropTypes.func,
  onAddCompositionWithDesign: PropTypes.func,
  handleRemovePublished: PropTypes.func,
  designs: PropTypes.array,
  activeProjectUid: PropTypes.string,
  firstProject: PropTypes.string,
  onSelectProject: PropTypes.func,
  filterAspectRatio: PropTypes.func,
  onAddProject: PropTypes.func,
  aspectRatio: PropTypes.string,
  view: PropTypes.string,
  userRole: PropTypes.number,
  filterButtons: PropTypes.array,
};

const DesignTab = (props) => {
  const {
    designs,
    onAddCompositionWithDesign,
    activeProjectUid,
    filterButtons,
    handleRemovePublished,
    onSelectProject,
    firstProject,
    aspectRatio,
    userRole,
    view,
    filterAspectRatio,
  } = props;
  const designTabRef = useRef(null);
  const storedMuteStatus = () => {
    try {
      return JSON.parse(window.localStorage.getItem('preview_muted'));
    } catch (e) {
      return false;
    }
  };
  const [muted, setMute] = useState(storedMuteStatus);

  // 4 columns, 10px margin
  const calcWidth = () =>
    designTabRef.current && Math.floor(designTabRef.current.clientWidth / 5) - 20;

  const thumbnailSize = (content) => {
    const { width, height } = content;
    if (width === 1080 && height === 1080) {
      return {
        width: `${calcWidth()}px`,
        height: `${calcWidth()}px`,
      };
    }
    if (width === 1080 && height === 1920) {
      return {
        width: `${calcWidth()}px`,
        height: `${Math.floor(calcWidth() * 1.777777778)}px`,
      };
    }
    if (width === 1080 && height === 1350) {
      return {
        width: `${calcWidth()}px`,
        height: `${Math.floor(calcWidth() * 1.25)}px`,
      };
    }
    return {
      width: `${calcWidth()}px`,
      height: `${Math.floor(calcWidth() / 1.777777778)}px`,
    };
  };

  const onLoadDesign = async (template) => {
    if (!activeProjectUid) {
      if (!firstProject) {
        await props.onAddProject('My new Project', template);
      } else {
        onSelectProject(firstProject);
        onAddCompositionWithDesign(
          firstProject,
          `Template: ${template.name}`,
          {
            width: template.content.width || 1920,
            height: template.content.height || 1080,
          },
          template,
        );
      }
    } else {
      onAddCompositionWithDesign(
        activeProjectUid,
        `Template: ${template.name}`,
        {
          width: template.content.width || 1920,
          height: template.content.height || 1080,
        },
        template,
      );
    }
  };

  return (
    <React.Fragment>
      <div className="DesignTab" ref={designTabRef}>
          <Button
            className="DesignTab__heading__mute"
            onClick={() => {
              setMute(!muted);
              window.localStorage.setItem('preview_muted', !muted);
            }}
          >
          <ToolTip message={muted ? 'Enable preview audio' : 'Disable preview audio'}>
            {muted ? <Mute /> : <Unmute />}
          </ToolTip>
          </Button>
        {view !== 'dashboard' && (
          <div className="Designs__AspectRadio___filter">
            <ButtonGroup
              items={filterButtons}
              selected={aspectRatio || 'facebook_vertical'}
              onSelect={filterAspectRatio}
            />
          </div>
        )}
        <div className="masonry-tile scroll-y" ref={designTabRef}>
          {designs.map(design => (
            <div key={design.uid} className="Thumbnail_container">
              <DesignThumbnail
                getDimensions={content => thumbnailSize(content)}
                onDesignLoad={() => onLoadDesign(design)}
                muted={muted}
                design={design}
                // NOTE: : code below is removed because
                // New video from template runs over the latest video,
                // even thogh "New video" was selected.
                // isNewComposition={isNewComposition}
                // onAddComposition={onAddComposition}
                // activeProjectUid={activeProjectUid}
              />
              {userRole === 2 && (
                <Button onClick={() => handleRemovePublished(design.uid)}> Remove </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

DesignTab.propTypes = propTypes;
DesignTab.defaultProps = defaultProps;

export default DesignTab;
