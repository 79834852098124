import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { imagesFilterButtons, imageUploadOptions } from '@constants/sidebar';
import { sidebarItemFilterToggle, sidebarUploadsFilterToggle, expandPool } from '@actions/sidebar';
import { fetchImages, getImages, search, handleAddToFavorite, updateFootage, handleRemoveFavorite } from '@actions/footage';
import { newUpload, removeImage } from '@actions/upload';
import {
  compositionVideoInstanceAdd,
  compositionVideoGenerateUniqueId,
  compositionPlaceholderRemove,
  compositionVideoInstanceSelect,
  formatBackgroundInstances,
} from '@actions/composition';
import { stateKeys } from '@constants/footage';
import { timelineProgressJump } from '@actions/timeline';
import ImagesTab from '@components/ImagesTab';

const mapStateToProps = (state) => {
  const {
    footage,
    sidebar,
    upload,
    app
  } = state;
  const composition = state.composition.present;
  const { stockImagesLoaded } = app;
  const { imageFootageFilter: filter } = sidebar;

  const selectedFilterData = footage[stateKeys.images[filter]];

  return {
    footagePages: selectedFilterData.entries,
    loading: footage.stockImages.isFetching,
    currentPage: selectedFilterData.currentPage,
    stockImagesLoaded,
    searchTerm: selectedFilterData.searchTerm,
    activeUploads: upload.processing,
    filter,
    updateFootage,
    composition,
    filterButtons: imagesFilterButtons,
    imageUploadOptions,
  };
};

class ImagesTabContainer extends React.Component {
  componentDidMount() {
    const {
      onLoad,
      filter,
      footagePages,
    } = this.props;

    if (footagePages.length === 0) {
      const sorting = 'latest';
      onLoad(filter, 0, sorting);
    }
  }
  componentDidUpdate(prevProps) {
    const {
      onLoad,
      filter,
    } = this.props;
    if (prevProps.filter !== this.props.filter) {
      const sorting = 'latest';
      onLoad(filter, 0, sorting);
    }
  }

  render() {
    return (
      <ImagesTab {...this.props} />
    );
  }
}

ImagesTabContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
  filter: PropTypes.string,
  footagePages: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {
  onFilterToggle: sidebarItemFilterToggle,
  onUploadsFilterToggle: sidebarUploadsFilterToggle,
  onLoad: fetchImages,
  onSearch: search,
  onDownload: getImages,
  onUpload: newUpload,
  updateFootage,
  onAdd: compositionVideoInstanceAdd,
  handleAdd: compositionVideoGenerateUniqueId,
  onCancel: compositionPlaceholderRemove,
  onRemoveUploadImage: removeImage,
  onAddToFavorite: handleAddToFavorite,
  onSelect: compositionVideoInstanceSelect,
  onProgressJump: timelineProgressJump,
  onRemoveFromFavorite: handleRemoveFavorite,
  formatBackgroundInstances,
  expandPool,
})(ImagesTabContainer);
