import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Spinner from '@components/Spinner';

// import GenerateTabContainer from '@containers/GenerateTabContainer';

import ProjectList from './ProjectList';
import SingleProject from './SingleProject';

import './ProjectsTab.scss';

const propTypes = {
  composition: PropTypes.object,
  projects: PropTypes.object,
  activeProjectUid: PropTypes.string,
  activeCompositionUid: PropTypes.string,
  onRenameProject: PropTypes.func,
  onCloseVideo: PropTypes.func,
  onCloseLayer: PropTypes.func,
  onCloseShapelayer: PropTypes.func,
  onReversionComposition: PropTypes.func,
  onRemoveComposition: PropTypes.func,
  onRenameComposition: PropTypes.func,
  onMoveComposition: PropTypes.func,
  onToggleMoveModal: PropTypes.func,
  onAddProject: PropTypes.func,
  onSelectProject: PropTypes.func,
  onSelectComposition: PropTypes.func,
  onAddComposition: PropTypes.func,
  isLoading: PropTypes.bool,
  isMovingComposition: PropTypes.bool,
  showMoveModal: PropTypes.bool,
  onRemoveProject: PropTypes.func,
  sidebarTabToggle: PropTypes.func,
  dashboardUpdateView: PropTypes.func,
  onToggleSidebarGallery: PropTypes.func,
  dashboardView: PropTypes.bool,
  activeTab: PropTypes.string,
};

const defaultProps = {
  isLoading: false,
};

class ProjectsTab extends React.Component {
  state = {
    selectedTab: 'myProjects',
    showProjectModal: false,
    projectName: 'My new folder',
    editProject: false,
  };

  getSelectedProject = (projects, activeProjectUid) =>
    projects.find(project => project.uid === activeProjectUid);

  selectProject = (projectUid) => {
    const { onSelectProject, onToggleSidebarGallery } = this.props;
    onToggleSidebarGallery();
    onSelectProject(projectUid);
    const projectTab = document.querySelector('.ProjectsTab');
    if (projectTab) projectTab.scrollIntoView();
  };

  handleReturn = () => {
    const { onSelectProject, onToggleSidebarGallery } = this.props;
    onToggleSidebarGallery();
    onSelectProject();
  };

  handleSelectTab = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };
  onSidebarTabToggle = (tab, showtemplates, asRatio) => {
    if (showtemplates) {
      this.setState({
        selectedTab: 'templates',
        asRatio,
      });
    }
    this.props.sidebarTabToggle(tab);
  };

  onCloseModal = () => {
    this.setState({
      showProjectModal: false,
      editProject: false,
      projectName: 'My new folder',
    });
  };

  handleProjectNameChange = (e, projectToEdit) => {
    if (projectToEdit) {
      this.setState({
        editProject: true,
        showProjectModal: true,
        projectName: projectToEdit.name,
        projectUid: projectToEdit.uid,
      });
    } else {
      this.setState({
        projectName: e.target.value,
      });
    }
  };

  handleRenameProject = () => {
    const { projectUid, projectName } = this.state;
    this.props.onRenameProject(projectName, projectUid);
    this.onCloseModal();
  };

  handleAddProject = () => {
    this.props.onAddProject(this.state.projectName);
    this.onCloseModal();
  };

  handleRemoving = (uid) => {
    const { projects, activeProjectUid } = this.props;
    const thisProject = projects.entries.find(project => project.uid === activeProjectUid);
    // If there is just one video, remove it and reset storage
    if (thisProject.videos.length === 1) {
      this.props.onRemoveComposition(uid, activeProjectUid);
    } else {
      // See which index we're about to remove
      const removedIndex = thisProject.videos.findIndex(video => video.uid === uid);
      // Remove it for real
      this.props.onRemoveComposition(uid);
      // Then cut the same video out from our temporary object
      thisProject.videos.splice(removedIndex, 1);
      // Then determine which index we'll select after removing
      let selectVideo;
      if (removedIndex === 0) {
        // If we removed the first video, select the first one from remaining videos
        selectVideo = thisProject.videos[removedIndex].uid;
      } else {
        // Otherwise select previous (one before the one that was removed)
        selectVideo = thisProject.videos[removedIndex - 1].uid;
      }
      // Do the select
      const { onSelectComposition } = this.props;
      onSelectComposition(selectVideo);
    }
  };

  enterPressed = (e) => {
    if (e.charCode === 13) {
      if (!this.state.editProject) {
        this.handleAddProject();
      } else {
        this.handleRenameProject();
      }
    }
  };

  projectsView = (projects, onAddProject, composition) => {
    const { activeTab } = this.props;
    return (
      <React.Fragment>
          <ProjectList
            projects={
              activeTab === 'dashboard' ? projects.entries.slice(0, 2) : projects.entries || []
            }
            onSelect={this.selectProject}
            onDelete={this.props.onRemoveProject}
            onRename={project => this.handleProjectNameChange('', project)}
            composition={composition}
            showProjectModal={() => {
              this.setState({
                showProjectModal: true,
              });
            }}
          />

        <Modal
          visible={this.state.showProjectModal}
          onClose={this.onCloseModal}
          title={!this.state.editProject ? 'New folder' : 'Rename folder'}
        >
          <span className="Modal__label"> Name </span>
          <input
            onChange={this.handleProjectNameChange}
            placeholder={this.state.projectName}
            className="NewCompositionModal__name-input"
            onKeyPress={this.enterPressed}
            autoFocus={true}
          />
          <div className="MoveDialog__button-container">
            <Button
              onClick={!this.state.editProject ? this.handleAddProject : this.handleRenameProject}
              color="pink"
            >
              Save
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  };

  renderVideos = () => {
    const {
      projects,
      activeProjectUid,
      onSelectComposition,
      onAddComposition,
      onReversionComposition,
      onRenameComposition,
      onRenameProject,
      onCloseVideo,
      onCloseLayer,
      onCloseShapelayer,
      onAddProject,
      onMoveComposition,
      isMovingComposition,
      activeCompositionUid,
      showMoveModal,
      onToggleMoveModal,
      onRemoveProject,
      onSelectProject,
      composition,
      dashboardView,
      dashboardUpdateView,
      onToggleSidebarGallery,
    } = this.props;
    if (activeProjectUid && !dashboardView) {
      const selectedProject = this.getSelectedProject(projects.entries, activeProjectUid);
      if (!selectedProject) return null;
      return (
        <SingleProject
          isMovingComposition={isMovingComposition}
          onToggleMoveModal={onToggleMoveModal}
          showMoveModal={showMoveModal}
          project={selectedProject}
          projects={projects.entries}
          onReturn={this.handleReturn}
          onSelectComposition={onSelectComposition}
          onRenameComposition={onRenameComposition}
          onAddComposition={onAddComposition}
          onReversionVideo={onReversionComposition}
          onRemoveComposition={this.handleRemoving}
          onMoveComposition={onMoveComposition}
          onRename={onRenameProject}
          onCloseVideo={onCloseVideo}
          onCloseLayer={onCloseLayer}
          onCloseShapelayer={onCloseShapelayer}
          activeCompositionUid={activeCompositionUid}
          sidebarTabToggle={this.onSidebarTabToggle}
          dashboardUpdateView={dashboardUpdateView}
          onToggleSidebarGallery={onToggleSidebarGallery}
          onSelectProject={onSelectProject}
          onRemoveProject={onRemoveProject}
          composition={composition}
        />
      );
    }

    return this.projectsView(projects, onAddProject, composition);
  };

  render() {
    const { isLoading } = this.props;

    const classes = classNames('Curtain', {
      'Curtain--loading': isLoading,
      'Curtain--hidden': !isLoading,
    });

    return (
      <div className="ProjectsTab">
        {this.renderVideos()}
        <div className={classes}>
          <Spinner visible={true} />
        </div>
      </div>
    );
  }
}

ProjectsTab.propTypes = propTypes;
ProjectsTab.defaultProps = defaultProps;

export default ProjectsTab;
