import axios from 'axios';
import * as types from '@constants/actionTypes';
import { TUTORIAL_URL } from '@constants/api';

function tutorialToggle() {
  return {
    type: types.TUTORIAL_TOGGLE,
  };
}

function toggleTutorial() {
  return async (dispatch) => {
    await axios.post(TUTORIAL_URL, {})
      .catch(err => console.log('err', err)); // eslint-disable-line no-console
    dispatch(tutorialToggle());
  };
}

export {
  toggleTutorial,
};
