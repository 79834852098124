import * as types from '@constants/actionTypes';
// import { EventTypes } from 'redux-segment';

function sidebarTabToggle(tab) {
  return {
    type: types.SIDEBAR_TAB_TOGGLE,
    payload: {
      tab,
    },
  };
}

function sidebarExpandToggle() {
  return {
    type: types.SIDEBAR_EXPAND_TOGGLE,
  };
}

function sidebarCategoriesToggle() {
  return {
    type: types.SIDEBAR_CATEGORIES_TOGGLE,
  };
}

function sidebarItemFilterToggle(itemName, filter) {
  return {
    type: types.SIDEBAR_ITEM_FILTER_TOGGLE,
    payload: {
      name: itemName,
      filter,
    },
  };
}

function sidebarUploadsFilterToggle(filter) {
  return {
    type: types.SIDEBAR_UPLOADS_FILTER_TOGGLE,
    payload: {
      filter,
    },
  };
}

function sidebarProjectSelect(projectUid) {
  return {
    type: types.SIDEBAR_PROJECT_SELECT,
    payload: {
      projectUid,
    },
    meta: {
      // analytics: {
      //   eventType: EventTypes.track,
      //   eventPayload: {
      //     event: 'Opened Project',
      //   },
      // },
    },
  };
}

function sidebarSelectShape(shapeId, shiftKey) {
  return {
    type: types.SIDEBAR_SHAPE_SELECT,
    payload: {
      shapeId,
      shiftKey,
    },
  };
}

function sidebarDeSelectShape() {
  return {
    type: types.SIDEBAR_SHAPE_DESELECT,
  };
}

function sidebarShowAdjusters(label) {
  return {
    type: types.SIDEBAR_SHOW_ADJUSTERS,
    payload: label,
  };
}

function expandPool(poolNumber) {
  return {
    type: types.EXPAND_POOL,
    payload: poolNumber,
  };
}

function sidebarToolbarActive(active) {
  return {
    type: types.SIDEBAR_TOOLBAR_ACTIVE,
    payload: active,
  };
}

export {
  sidebarTabToggle,
  sidebarExpandToggle,
  sidebarCategoriesToggle,
  sidebarItemFilterToggle,
  sidebarUploadsFilterToggle,
  sidebarProjectSelect,
  sidebarSelectShape,
  sidebarDeSelectShape,
  sidebarShowAdjusters,
  sidebarToolbarActive,
  expandPool,
};
