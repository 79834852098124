export const stateKeys = {
  images: {
    stock: 'stockImages',
    upload: 'uploadImages',
    favorite: 'favoriteImages',
  },
  videos: {
    stock: 'stockVideos',
    upload: 'uploadVideos',
    favorite: 'favoriteVideos',
  },
  shapes: {
    shapes: 'stockShapes',
    emojis: 'stockEmojis',
  },
  // singular references should be used in the future,
  // because they match server api routes better
  image: {
    stock: 'stockImages',
    upload: 'uploadImages',
    favorite: 'favoriteImages',
  },
  video: {
    stock: 'stockVideos',
    upload: 'uploadVideos',
    favorite: 'favoriteVideos',
  },
}
