import React from 'react';
import PropTypes from 'prop-types';

/**
 * Ticker is used to create a "fake" timestamp for components.
 *
 * When a video is playing, rather than updating the actual currentTime
 * to redux multiple times in a second, we grab the isPlaying value
 * and start increasing the time value internally with setInterval.
 *
 * Components listening to this time also takes the redux currentTime
 * and isPlaying options in their props, and depending on the isPlaying
 * value displays either the created "fake" time, or the actual time
 * from the redux store.
 */

const propTypes = {
  isPlaying: PropTypes.bool,
  render: PropTypes.func.isRequired,
  compositionDuration: PropTypes.number.isRequired,
  currentTime: PropTypes.number,
};

const defaultProps = {
  isPlaying: false,
};

class Ticker extends React.Component {
  start = null;

  constructor(props) {
    super(props);
    this.state = {
      internalTime: this.props.currentTime,
    };
    this.containerElement = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying === this.props.isPlaying) return;

    if (this.props.isPlaying) {
      this.handle = window.requestAnimationFrame(this.step);
    } else {
      this.start = null;
      window.cancelAnimationFrame(this.handle);
    }
  }

  getAccurateOffset = (clientX) => {
    const containerOffset = this.containerElement.current.offsetLeft;
    return clientX - containerOffset;
  }

  handleProgressJump = (event) => {
    const { msPxRatio, onProgressJump } = this.props;

    const offset = this.getAccurateOffset(event.nativeEvent.clientX);
    const time = Math.round(offset / msPxRatio);
    onProgressJump(time);
  };

  step = (timestamp) => {
    if (!this.start) this.start = timestamp;
    const progress = timestamp - this.start;

    const { compositionDuration } = this.props;
    const newTime = this.props.currentTime + progress;

    // REVIEW: the next if statement is never running, due to the fact that newTime is
    // never equal to composition duration, revise this when there is more time.

    // Stop ticker at the end of composition
    if (newTime >= compositionDuration) {
      window.cancelAnimationFrame(this.handle);
      return;
    }

    this.setState({ internalTime: newTime });

    this.handle = window.requestAnimationFrame(this.step);
  }

  render() {
    const { internalTime: time } = this.state;

    return (
      <div className="Ticker" ref={this.containerElement} onClick={(e) => { this.handleProgressJump(e); }}>
        {this.props.render(time)}
      </div>
    );
  }
}

Ticker.defaultProps = defaultProps;
Ticker.propTypes = propTypes;

export default Ticker;
