import cloneDeep from 'lodash.clonedeep';
import { fabric } from 'fabric';

import { SimpleLayer } from '@lib/render-engine';
import { borderAndCornerStyles } from '@lib/fabricHelper';

import { DEFAULT_OVERLAY_LAYER_SCALE, DEFAULT_OVERLAY_LAYER_MARGIN } from '@constants/layer';
import { bringTextsFront } from '@helpers/textLayer';
import { findEmptySlot } from '@helpers/timeline';

const createImageLayer = (
  src,
  layerOptions,
  options = {},
  eventHandlers = {},
) => new Promise((resolve) => {
  fabric.Image.fromURL(src, (object) => {
    resolve(new SimpleLayer(object, layerOptions, eventHandlers));
  }, options);
});


/**
 * Adds single layer to renderengine based on its composition data
 * @param {object} renderEngine - Render engine instance
 * @param {object} layer - Layer instance data (from composition)
  */
const addLayer = async (renderEngine, layer, handlers, scaleToHeight) => {
  const {
    visibleFrom,
    src,
    visibleTo,
    top,
    left,
    height,
    width,
    id,
    scaleX = 1,
    scaleY = 1,
    type,
    animationIn,
    animationOut,
  } = layer;

  const layerOptions = {
    visibleFrom,
    visibleTo,
    type,
    id,
    animationIn,
    animationOut,
  };

  const fabricOptions = {
    ...borderAndCornerStyles,
    height,
    width,
    top,
    left,
    scaleX,
    scaleY,
    hasControls: true,
    hoverCursor: 'move',
    originX: 'center',
    originY: 'center',
    opacity: layer.animationIn ? 0 : 1,
  };

  const eventHandlers = {
    modified: evt => handlers.modified(evt.target, id),
    selected: () => handlers.selected(id),
    deselected: () => handlers.deselected(),
  };

  const fabricLayer = await createImageLayer(
    src,
    layerOptions,
    fabricOptions,
    eventHandlers,
    scaleToHeight,
  );

  await renderEngine.addLayer(fabricLayer);
  bringTextsFront(renderEngine);
};

/**
 * Updates generic layers
 *
 * TODO: look into updating only the layer that has changed
 * currently all layers are updated.
 *
 * @param {object} renderEngine - Render engine instance
 * @param {array} layerInstances - List of layers (from composition data)
 * @param {object} eventHandlers - Event handlers that gets bound
 */
const updateLayers = async (renderEngine, layerInstances, eventHandlers) => {
  const { layers } = renderEngine;
  const currentLayers = [];

  // Go trough layers represented in composision data and compare
  // to layers that are in renderEngine. If layer is found, update it.
  // If a layer is in composition data but not in engine, it means its been
  // just added.
  layerInstances.forEach((instance) => {
    // clone instance so that changes to it aren't effecting
    // the fabricjs instance.
    const clonedInstance = cloneDeep(instance);

    const alreadyExistingLayer = layers
      .filter(layer => layer.id === instance.id)
      .shift();

    // If layer is updated, matching layer is found otherwise add it
    if (alreadyExistingLayer) {
      // Keep track of still existing layers so layers
      // that are deleted can be cleaned from the canvas
      currentLayers.push(alreadyExistingLayer.id);

      // set attributes to both layers fabric object and the
      // layer itself
      alreadyExistingLayer.object.styles = clonedInstance.styles;
      alreadyExistingLayer.object.top = clonedInstance.top;
      alreadyExistingLayer.object.left = clonedInstance.left;

      alreadyExistingLayer.left = clonedInstance.left;
      alreadyExistingLayer.top = clonedInstance.top;
      alreadyExistingLayer.visibleFrom = clonedInstance.visibleFrom;
      alreadyExistingLayer.visibleTo = clonedInstance.visibleTo;
      alreadyExistingLayer.duration = clonedInstance.duration;
    } else {
      currentLayers.push(instance.id);
      addLayer(renderEngine, instance, eventHandlers);
    }
  });

  /**
   * If a layer is deleted, its not present in currentLayers
   * and should be removed from canvas.
   */
  layers
    .filter(layer => layer.type === 'image')
    .filter(layer => !currentLayers.includes(layer.id))
    .map(matched => renderEngine.canvas.remove(matched.object));

  // renderEngine.refreshTextLayers();
  renderEngine.canvas.renderAll();
};

const getLogoLayer = (composition, layer, monitor, canvas) => {
  const getScaleX = w => w / layer.width;
  const normalize = (val, max, min) => ((val - min) / (max - min));
  const scale = getScaleX(DEFAULT_OVERLAY_LAYER_SCALE * composition.width);
  let top = DEFAULT_OVERLAY_LAYER_MARGIN;
  let left = (composition.width - (layer.width * scale)) - DEFAULT_OVERLAY_LAYER_MARGIN;
  const visibleTo = composition.duration || layer.visibleTo;
  let emptySlot;
  if (monitor) {
    const coords = monitor.getSourceClientOffset();
    // get the normalize min and max values
    const minX = canvas.left;
    const maxX = canvas.left + canvas.width;
    const minY = canvas.top;
    const maxY = canvas.top + canvas.height;
    // normalize coordinates
    const correctX = normalize(coords.x, maxX, minX) * composition.width;
    const correctY = normalize(coords.y, maxY, minY) * composition.height;
    top = correctY;
    left = correctX;
    emptySlot = findEmptySlot(composition, 0, visibleTo);
  } else {
    emptySlot = findEmptySlot(composition.content, 0, visibleTo);
  }


  const layerMock = {
    visibleFrom: emptySlot.visibleFrom,
    visibleTo: emptySlot.visibleTo,
    top: layer.type === 'image' || layer.type === 'shape' ? top + ((layer.height * scale) / 2) : 540,
    left: layer.type === 'image' || layer.type === 'shape' ? left + ((layer.width * scale) / 2) : 960,
    sourceId: layer.id,
    thumbnail: layer.thumbnail,
    src: layer.src,
    type: layer.type,
    width: layer.width,
    height: layer.height,
    scaleY: scale,
    scaleX: scale,
    originX: 'center',
    originY: 'center',
    alwaysVisible: true,
    track: emptySlot.track,
  };
  if (layer.type === 'image' && layerMock.track < 0) return null;
  return layerMock;
};


export {
  createImageLayer,
  updateLayers,
  addLayer,
  getLogoLayer,
};
