import {
  compositionPlaceholderRemove, compositionShowVideoContext, compositionVideoGenerateUniqueId, compositionVideoInstanceAdd, compositionVideoInstanceSelect, formatBackgroundInstances
} from '@actions/composition';
import {
  clearTagSearch, fetchVideos, footageChangeSorting,
  handleAddToFavorite,
  handleRemoveFavorite, search,
  searchTags, updateFootage
} from '@actions/footage';
import { expandPool, sidebarCategoriesToggle, sidebarItemFilterToggle, sidebarUploadsFilterToggle } from '@actions/sidebar';
import { timelineProgressJump } from '@actions/timeline';
import { newUpload, removeVideo } from '@actions/upload';
import VideosTab from '@components/VideosTab';
import { stateKeys } from '@constants/footage';
import { filterButtons, footageCategories } from '@constants/sidebar';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { footage, sidebar, upload, app } = state;
  const composition = state.composition.present;
  const { videoFootageFilter: filter, showCategories } = sidebar;
  const { stockVideoLoaded } = app;

  const { searchSuggestions, sorting } = footage;
  const selectedFilterData = footage[stateKeys.videos[filter]];

  return {
    // better method to check if footage has been chunked or not might be valuable,
    // now favorite footages are not chunked.
    footagePages: selectedFilterData.entries,
    relatedTags: selectedFilterData.relatedTags,
    loading: selectedFilterData.isFetching,
    activeUploads: upload.processing,
    expanded: sidebar.expanded,
    backgroundInstances: composition.backgroundInstances,
    updateFootage,
    filter,
    sorting,
    showCategories,
    searchSuggestions,
    composition,
    filterButtons,
    footageCategories,
    stockVideoLoaded,
  };
};

class VideosTabContainer extends React.Component {
  componentDidMount() {
    const {
      onSearch, footagePages, filter, sorting,
    } = this.props;
    if (footagePages.length === 0 && filter === 'stock') {
      onSearch('video', '*', filter, false, sorting);
    }
  }

  render() {
    return <VideosTab {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  {
    onFilterToggle: sidebarItemFilterToggle,
    onUploadsFilterToggle: sidebarUploadsFilterToggle,
    onCategoriesToggle: sidebarCategoriesToggle,
    onLoad: fetchVideos,
    onSearch: search,
    expandPool,
    updateFootage,
    onSearchTags: searchTags,
    onClearTagsSearch: clearTagSearch,
    onProgressJump: timelineProgressJump,
    onUpload: newUpload,
    onAdd: compositionVideoInstanceAdd,
    handleAdd: compositionVideoGenerateUniqueId,
    onCancel: compositionPlaceholderRemove,
    onRemoveUploadVideo: removeVideo,
    onChangeSorting: footageChangeSorting,
    onAddToFavorite: handleAddToFavorite,
    onRemoveFromFavorite: handleRemoveFavorite,
    onSelect: compositionVideoInstanceSelect,
    onShowContext: compositionShowVideoContext,
    formatBackgroundInstances,
  },
)(VideosTabContainer);
