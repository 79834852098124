import React from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';

const getItemStyles = (props) => {
  const { initialOffset, currentOffset, item } = props;
  if (!initialOffset || !currentOffset) {
    return { display: 'none' };
  }
  if (!item) return { display: 'none' };
  const { visibleFrom, visibleTo, id, trackIndex, backgroundImage, instances, backgroundTrack } = item;
  const timelineDimensions = document.getElementsByClassName('timeline')[0].getBoundingClientRect();
  const backgroundPoolDimensions = document.getElementsByClassName('background')[0].getBoundingClientRect();
  const trackDimensions = document.getElementsByClassName('track')[0].getBoundingClientRect();
  const trackLeft = document.getElementsByClassName('pool-icon')[0].getBoundingClientRect().width + 2;
  const msToPixel = item.msToPixel ? item.msToPixel : trackDimensions.width / item.duration;
  const x = currentOffset ? currentOffset.x : 100;
  const initialX = initialOffset ? initialOffset.x : 0;
  const dimensions = item.dimensions ? item.dimensions : { x: currentOffset.x, width: 3000 * msToPixel, y: currentOffset.y, height: 19 }
  const draggedCoords = {
    visibleFrom: Math.floor(visibleFrom + ((x - initialX) / msToPixel)),
    visibleTo: Math.floor(visibleTo + ((x - initialX) / msToPixel)),
  };
  // CHECK BACKGROUND POOL AND THE CLOSEST INPOINT TO SNAP ON
  let inPointToSnapIndex = 0;
  if (backgroundTrack) {
    let shortestDistance = 10000000000000;
    backgroundTrack.forEach((instance, index) => {
      const distance = instance.visibleFrom - draggedCoords.visibleFrom;
      if (Math.abs(distance) < shortestDistance) {
        shortestDistance = Math.abs(distance);
        inPointToSnapIndex = index;
      }
    });
  }
  const inPointToSnap = backgroundTrack ? backgroundTrack[inPointToSnapIndex].visibleFrom : 10000000000;
  const inBound = Math.floor(trackDimensions.x);
  const outBound = Math.floor((trackDimensions.x + trackDimensions.width) - dimensions.width);
  let top = props.itemType === 'instance' ? Math.floor(dimensions.y) + 2 : Math.floor(dimensions.y) - 5;
  let height = currentOffset.y < backgroundPoolDimensions.y ? `${Math.floor(dimensions.height - 2)}px` : `${Math.floor(backgroundPoolDimensions.height) - 5}px`;
  let left = props.itemType === 'instance' ? `${Math.floor((draggedCoords.visibleFrom > inBound) ? dimensions.x + (x - initialX) : inBound)}px` : dimensions.x;
  if (draggedCoords.visibleFrom < 0 && props.itemType === 'instance') {
    left = `${inBound}`;
  } else if (draggedCoords.visibleTo > item.compositionDuration && props.itemType === 'instance') {
    left = `${outBound}`;
  } else if (props.itemType === 'instance') {
    left = `${dimensions.x + (x - initialX)}`;
  } else {
    left = `${dimensions.x}`;
  }

  if (Math.abs(draggedCoords.visibleFrom - inPointToSnap) < 150) {
    left = `${(inBound + (inPointToSnap * msToPixel))}`;
  }

  let width = `${Math.floor(dimensions.width)}`;
  const { trackHeight } = item;
  let transform = `translate(0px, ${(props.trackRef.current - trackIndex) * (trackHeight + 2)}px)`;
  let opacity = 0.8;
  let boxShadow = '2px 2px 2px rgba(0,0,0,.1)';
  let backgroundColor = '#e9236e';
  if (timelineDimensions.y > currentOffset.y && item.type !== 'video') {
    top = Math.floor(currentOffset.y);
    opacity = 0.3;
    backgroundColor = 'red';
  } else if (item.type === 'video' && currentOffset.y < backgroundPoolDimensions.y) {
    top = Math.floor(currentOffset.y);
    opacity = 0.3;
    backgroundColor = 'red';
  }

  // SNAP TO TRACKS
  if (currentOffset.y - trackDimensions.y > 0 && item.list) {
    if (item.type !== 'video') {
      const trackOffset = Math.abs(Math.floor((trackDimensions.y - currentOffset.y) / (trackDimensions.height + 2))) - 1;
      top = Math.floor(trackDimensions.y + 2 + (trackOffset * (trackDimensions.height + 2)));
    }
  }
  if (currentOffset.y - backgroundPoolDimensions.y > 0 && (item.type === 'video' || item.type === 'image')) {
    top = backgroundPoolDimensions.y + 4;
    height = `${Math.floor(backgroundPoolDimensions.height) - 5}px`;
  }

  // check if overlaps
  if (instances) {
    instances.forEach((instance) => {
      if (
        (instance.id !== id && draggedCoords.visibleFrom < instance.visibleTo && draggedCoords.visibleFrom > instance.visibleTo - 200)
      ) {
        left = `${(instance.visibleTo * msToPixel) + trackLeft}`;
      }
      if (
        (instance.id !== id && draggedCoords.visibleTo < instance.visibleFrom + 100 && draggedCoords.visibleTo > instance.visibleFrom)
      ) {
        left = `${(((instance.visibleFrom * msToPixel)) - dimensions.width) + trackLeft}`;
      }
    });
    // handle swapping style
    instances.forEach((instance) => {
      if (
        (instance.id !== id && draggedCoords.visibleFrom < instance.visibleTo - 200 && draggedCoords.visibleFrom > instance.visibleFrom + 200) ||
        (instance.id !== id && draggedCoords.visibleTo < instance.visibleTo - 200 && draggedCoords.visibleTo > instance.visibleFrom + 200)
      ) {
        transform = `translate(0px, ${(props.trackRef.current - trackIndex) * (trackHeight + 2)}px) scale(1.15)`;
        opacity = 0.85;
        boxShadow = '10px 0px 10px rgba(0,0,0,.5)';
      }
    });
  }

  if (timelineDimensions.y < currentOffset.y) {
    if (item.list && item.type === 'image') {
      if (currentOffset.y > backgroundPoolDimensions.y) {
        if (props.expandedPool !== 1) {
          props.expandPool(1);
        }
      } else if (props.expandedPool !== 0) {
        props.expandPool(0);
      }
    }
    let bgImage = backgroundImage;
    switch (item.type) {
      case 'image':
        bgImage = backgroundImage ? backgroundImage.nano : item.instance.thumbnail.nano;
        break;
      case 'video':
        if (item.list) {
          bgImage = item.instance.thumbnail.nano;
        } else {
          bgImage = backgroundImage.nano;
        }
        break;
      default:
        bgImage = backgroundImage;
    }
    if (item.list && currentOffset.y > backgroundPoolDimensions.y && (item.type === 'video' || item.type === 'image')) {
      bgImage = item.instance.thumbnail.nano;
    }

    if (item.list && item.type === 'video' && currentOffset.y < backgroundPoolDimensions.y) {
      width = item.instance.width * 0.1;
      height = item.instance.height * 0.1;
    }

    return {
      containerStyles: {
        transition: 'transform .3s cubic-bezier(.7,0,0,.7)',
        position: 'absolute',
        pointerEvents: 'none',
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        zIndex: 10,
        left: `${item.list === 'gallery' ? left - (width / 2) : left}px`,
        top: `${top}px`,
        height: `${height}`,
        width: `${width}px`,
        backgroundColor,
        borderRadius: '.2em',
        opacity,
        boxShadow,
        transform,
        cursor: 'grabbing',
      },
      titleStyles: {},
      title: '',
    };
  }

  let srcImage;
  let containerWidth;
  let containerHeight;
  switch (item.type) {
    case 'image':
      if (item.poolType === 'background' || item.list) {
        srcImage = item.list && item.instance.thumbnail ? item.instance.thumbnail.nano : item.backgroundImage;
      }
      containerWidth = item.list ? item.instance.width * 0.043 : item.dimensions.width;
      containerHeight = item.list ? item.instance.height * 0.043 : item.dimensions.height;
      break;
    case 'video':
      srcImage = item.list && item.instance.thumbnail ? item.instance.thumbnail.nano : item.backgroundImage.nano;
      containerWidth = item.list && currentOffset.y < backgroundPoolDimensions.y ? item.instance.width * 0.1 : 3000 * msToPixel;
      containerHeight = item.list && currentOffset.y < backgroundPoolDimensions.y ? item.instance.height * 0.1 : 20;
      break;
    case 'text':
      srcImage = '';
      containerWidth = item.list && currentOffset.y < backgroundPoolDimensions.y ? 100 : 3000 * msToPixel;
      containerHeight = item.list && currentOffset.y < backgroundPoolDimensions.y ? 100 : 20;
      break;
    case 'shape':
      if (item.list) {
        srcImage = item.instance.src;
        containerWidth = 73;
        containerHeight = 75;
      }
      break;
    default:
  }

  return {
    containerStyles: {
      transition: 'transform .3s cubic-bezier(.7,0,0,.7)',
      position: 'absolute',
      pointerEvents: 'none',
      backgroundImage: `url(${srcImage})`,
      backgroundSize: 'cover',
      backgroundColor: item.list && item.type === 'text' ? '' : backgroundColor,
      zIndex: 10,
      left: `${item.list === 'gallery' ? left - (containerWidth / 2) : left}px`,
      top: `${top - (containerHeight / 2)}px`,
      height: item.list && item.type === 'text' ? '' : `${containerHeight}px`,
      width: item.list && item.type === 'text' ? '' : `${containerWidth}px`,
      borderRadius: '.2em',
      opacity: item.list && item.type === 'text' ? 1 : opacity,
      boxShadow,
      transform,
      cursor: 'grabbing',
    },
    titleStyles: item.list && item.type === 'text' ? { ...item.instance.preset.textInstances[0], color: 'white' } : {},
    title: item.list && item.type === 'text' ? item.instance.preset.textInstances[0].text : '',
  };
};
class CustomDragLayer extends React.Component {
  render() {
    if (!this.props.isDragging) {
      return null;
    }
    return (
      <div style={getItemStyles(this.props).containerStyles}>
        <p style={getItemStyles(this.props).titleStyles}>{getItemStyles(this.props).title}</p>
      </div>
    );
  }
}

CustomDragLayer.propTypes = {
  item: PropTypes.object,
};

function collect(monitor) {
  return {
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
  };
}

export default DragLayer(collect)(CustomDragLayer);
