import * as types from '@constants/actionTypes';

const initialState = {
  loading: false,
  tags: [],
  logoData: null,
  generatorConfigs: {
    mood: 'calm',
    length: 'short',
    format: 'horizontal',
    content: 'videos',
  },
  optionalParams: {
    website: 'www.adlaunch.com',
    message: 'Short message',
    cta: '',
  },
};

export default function generate(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case types.COMPOSITION_GENERATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.COMPOSITION_LOAD_DESIGN:
    case types.COMPOSITION_LOAD_GENERATED:
      return {
        ...state,
        loading: false,
      };
    case types.COMPOSITION_NOT_GENERATED:
      return {
        ...state,
        loading: false,
      };
    case types.COMPOSITION_SET_TAG:
      return {
        ...state,
        tags: [...state.tags, action.payload.tag],
      };
    case types.COMPOSITION_SET_LOGO: {
      const { logoData } = action.payload;
      return {
        ...state,
        logoData,
      };
    }
    case types.COMPOSITION_SET_OPTIONS: {
      const { newOptions } = action.payload;
      return {
        ...state,
        optionalParams: newOptions,
      };
    }
    case types.COMPOSITION_REMOVE_TAG:
      return {
        ...state,
        tags: state.tags.filter(tag => tag !== action.payload.removableTag),
      };
  }
}
