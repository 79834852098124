import React from 'react';
import { connect } from 'react-redux';
import {
  compositionRemoveShapeLayer,
  compositionLayerTimeslotChange,
  compositionShowVisiblityHandler,
  compositionUpdateShapeLayer,
  compositionShapeInstanceUpdateTimestamps,
  compositionShapelayerUnselect,
} from '@actions/composition';
// import { applyShapeAnimation } from '@actions/textlayer';
import ToolbarShapelayer from '@components/ToolbarShapelayer';
import { sidebarSelectShape, sidebarShowAdjusters } from '@actions/sidebar';
import { triggerNotification } from '@actions/app';

const ToolbarShapelayerContainer = props => <ToolbarShapelayer {...props} />;

const mapStateToProps = (state) => {
  const { sidebar } = state;
  const composition = state.composition.present;
  const { selectedShapes } = sidebar;
  const { backgroundInstances, duration, showVisibilityHandler } = composition;

  const activeShape = composition.shapeInstances
    .find(shape => shape.id === (selectedShapes.length > 0 && selectedShapes[0]));
  const { shapeInstances } = composition;
  const visibilityTimes = {
    visibleFrom: activeShape ? activeShape.visibleFrom : null,
    visibleTo: activeShape ? activeShape.visibleTo : null,
  };
  const backgroundInstanceTimes = backgroundInstances.map(instance => instance.visibleFrom);

  return {
    activeShape,
    shapeInstances,
    selectedShapes,
    backgroundInstanceTimes,
    compositionDuration: duration,
    visibilityTimes,
    showVisibilityHandler,
    compositionWidth: composition.width,
    compositionHeight: composition.height,
  };
};

export default connect(mapStateToProps, {
  onRemove: compositionRemoveShapeLayer,
  onTimeChange: compositionLayerTimeslotChange,
  onShowVisiblityHandler: compositionShowVisiblityHandler,
  onUpdateTimestamps: compositionShapeInstanceUpdateTimestamps,
  onSidebarAdjusters: sidebarShowAdjusters,
  onTriggerNotification: triggerNotification,
  onModifyShape: compositionUpdateShapeLayer,
  // onApplyAnimation: applyShapeAnimation,
  onSelect: sidebarSelectShape,
  onClose: compositionShapelayerUnselect,
})(ToolbarShapelayerContainer);
