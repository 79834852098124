import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { Plus } from '@components/Icons';
import Button from '@components/Button';
import MoveCompositionModal from '@components/MoveCompositionModal';
import AspectModal from '@components/AspectModal';

import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import Video from './Video';

import './SingleProject.scss';

const propTypes = {
  isMovingComposition: PropTypes.bool,
  onToggleMoveModal: PropTypes.func.isRequired,
  onToggleSidebarGallery: PropTypes.func,
  showMoveModal: PropTypes.bool,
  project: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  onReturn: PropTypes.func.isRequired,
  onSelectComposition: PropTypes.func.isRequired,
  onRenameComposition: PropTypes.func.isRequired,
  onMoveComposition: PropTypes.func.isRequired,
  onAddComposition: PropTypes.func.isRequired,
  onReversionVideo: PropTypes.func.isRequired,
  onRemoveComposition: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  activeCompositionUid: PropTypes.string,
  onRemoveProject: PropTypes.func,
  sidebarTabToggle: PropTypes.func.isRequired,
  dashboardUpdateView: PropTypes.func,
  onSelectProject: PropTypes.func.isRequired,
  onCloseVideo: PropTypes.func.isRequired,
  onCloseLayer: PropTypes.func.isRequired,
  onCloseShapelayer: PropTypes.func,
};

export default class SingleProject extends React.Component {
  state = {
    projectName: '',
    showNewCompositionModal: false,
    movableCompositionUid: null,
    deleteVideo: null,
    deleteProject: null,
    showScroll: false,
  };

  componentDidMount = () => {
    const pvl = document.querySelector('.project-versions-list');
    const { videos } = this.props.project;
    if (pvl) {
      setTimeout(() => {
        pvl.scrollTop = 1;
      }, 3000);
    }
    if (videos.length === 0) {
      this.setState({
        // showNewCompositionModal: true,
      });
    }
  };

  handleDuplicateModalOpen = (movableCompositionUid) => {
    const { onToggleMoveModal } = this.props;
    this.setState({
      movableCompositionUid,
    });
    onToggleMoveModal();
  };

  handleDeleteCancel = () => {
    this.setState({
      deleteVideo: null,
      deleteProject: null,
    });
  };

  handleDelete = () => {
    const { deleteVideo, deleteProject } = this.state;
    if (deleteVideo) {
      this.props.onRemoveComposition(deleteVideo.uid);
      this.setState({
        deleteVideo: null,
      });
    } else {
      this.props.onRemoveProject(deleteProject);
      this.setState({
        deleteProject: null,
      });
      this.props.onReturn();
    }
  };

  handleProjectDeletion=(project) => {
    this.setState({
      deleteProject: project,
    });
  }

  projectVideos = (allVideos, activeCompositonUid) => allVideos
    .map((video) => {
      const handleVideoLoad = () => {
        this.props.onSelectComposition(video.uid);
        this.props.onCloseVideo();
        this.props.onCloseLayer();
        this.props.onCloseShapelayer();
      };

      const openDeleteModal = () => {
        this.setState({
          deleteVideo: video,
        });
      };
      return (
        <Video
          projectuid= {video.project}
          key={video.uid}
          data={video}
          onClick={handleVideoLoad}
          onReversion={this.props.onReversionVideo}
          onMove={this.handleDuplicateModalOpen}
          onRemove={openDeleteModal}
          onRename={this.props.onRenameComposition}
          active={video.uid === activeCompositonUid}
        />
      );
    });

  handleCompositionAddToggle = () => {
    this.setState({
      showNewCompositionModal: !this.state.showNewCompositionModal,
    });
  };

  handleCompositionAdd = (name, dimensions) => {
    const { onAddComposition, project } = this.props;

    onAddComposition(project.uid, name, dimensions);

    // TODO: set this to reducer and show spinner
    this.setState({
      showNewCompositionModal: false,
    });
  };

  debouncedRename = debounce(this.props.onRename, 500);

  handleProjectRename = (event) => {
    const { project } = this.props;

    this.setState({
      projectName: event.target.value,
    });

    this.debouncedRename(event.target.value, project.uid);
  };

  handleCompositionMove = (selectedProjectUid) => {
    const { onMoveComposition } = this.props;
    const { movableCompositionUid } = this.state;
    onMoveComposition(selectedProjectUid, movableCompositionUid);
  };

  handleScroll = (e) => {
    const element = e.target;
    if (element && element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.setState({ showScroll: false });
    } else {
      this.setState({ showScroll: true });
    }
  };

  render() {
    const { videos, uid, name } = this.props.project;
    const {
      activeCompositionUid,
      projects,
      isMovingComposition,
      onToggleMoveModal,
      showMoveModal,
      sidebarTabToggle,
      dashboardUpdateView,
      onSelectProject,
    } = this.props;

    const {
      projectName, deleteVideo, deleteProject, showScroll,
    } = this.state;
    // Filter current project from list because you cant move a video to
    // a project where it already is.
    const projectList = projects
      .filter(singleProject => singleProject.uid !== uid)
      .map(singleProject => ({
        label: singleProject.name,
        value: singleProject.uid,
      }));
    return (
      <div
        className="SingleProject"
      >
        <section className="VideosTab__heading--section">
            <div className="ContextSettingsGrid__Row">
              <h5>Project name</h5>
              <input
                className="SingleProject__name-input"
                value={projectName || name}
                onChange={this.handleProjectRename}
              />
            </div>
          <div className="ContextSettingsGrid__Row">
            <Button
              className="SingleProject__button"
              onClick={this.handleCompositionAddToggle}
              color='pink'
            >
              <span className="flex-1">New video</span>
              <Plus/>
            </Button>
          </div>
        </section>
        <div className="project-versions-list scroll-y" onScroll={this.handleScroll}>
          {this.projectVideos(videos, activeCompositionUid)}
          <div className="scroll-more" style={{ opacity: showScroll ? '1' : '0' }}></div>
        </div>
        <MoveCompositionModal
          visible={showMoveModal}
          loading={isMovingComposition}
          projects={projectList}
          onClose={onToggleMoveModal}
          onMove={this.handleCompositionMove}
        />
        <ConfirmDeleteDialog
          resourceName={(deleteVideo && deleteVideo.name) || (deleteProject && deleteProject.name)}
          visible={!!deleteVideo || !!deleteProject }
          onCancel={this.handleDeleteCancel}
          onDelete={this.handleDelete}
          title={`Confirm ${(deleteVideo && 'Video') || (deleteProject && 'Project')} delete`}
        />
      <AspectModal
          isNewVideo = {this.props.project.videos.length === 0 || !activeCompositionUid}
          visible={this.state.showNewCompositionModal}
          title = 'New Video'
          onClose={this.handleCompositionAddToggle}
          onAdd={this.handleCompositionAdd}
          sidebarTabToggle={sidebarTabToggle}
          dashboardUpdateView={dashboardUpdateView}
          onToggleSidebarGallery={this.props.onToggleSidebarGallery}
          onSelectProject={onSelectProject}
        />
      </div>
    );
  }
}

SingleProject.propTypes = propTypes;
