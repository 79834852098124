/**
 * Calculates composition duration based on bgInstances or textInstaces
 * depending which goes further.
 *
 * @param {array} bgInstances - Composition background instances
 * @param {array} textInstances - Composition text instances
 * @returns {number} duration - Duration for composition
 */

const calculateCompositionDuration = (bgInstances, textInstances, shapeInstances, layers) => {
  const bgInstanceDuration = bgInstances
    .reduce((acc, curr) => curr && (acc + curr.duration), 0);

  const overlays = textInstances.concat(shapeInstances, layers);

  const overlayDuration = overlays
    .reduce((highest, curr) => (curr && highest > curr.visibleTo ? highest : curr.visibleTo), 0);
  if (bgInstanceDuration < overlayDuration) {
    return overlayDuration;
  }

  return bgInstanceDuration;
};

/**
 * Calculates visible values for composition instances.
 *
 * When a new instance is added / deleted, all the instances are looped trough
 * and their times are shifted to take in account the add / delete.
 *
 * @param {array} instances - Composition background instances
 *
 */
const calculateCompositionInstanceStartTimes = (instances) => {
  let calculatedStart = 0;
  const returnable = [];
  instances.forEach((instance) => {
    const instanceObj = {
      ...instance,
      visibleFrom: calculatedStart,
      visibleTo: calculatedStart + instance.duration,
    };
    returnable.push(instanceObj);
    calculatedStart += instance.duration;
  });
  return returnable;
};

// TODO: If duplicates are wanted in the middle of an array this can be calculated better
const calculateTextInstanceStartTimes = (currentInstances, newInstances) => {
  let calculatedStart = currentInstances.slice(-1)[0] && currentInstances.slice(-1)[0].visibleTo;
  const returnable = [];
  newInstances.forEach((instance) => {
    const { animation } = instance;
    if (animation) {
      const newAnimInstance = {
        ...instance,
        visibleFrom: calculatedStart,
        visibleTo: calculatedStart + instance.duration,
      };

      returnable.push({
        ...newAnimInstance,
      });
    } else {
      returnable.push({
        ...instance,
        visibleFrom: calculatedStart,
        visibleTo: calculatedStart + instance.duration,
      });
    }

    calculatedStart += instance.duration;
  });

  return currentInstances.concat(...returnable);
};

/**
 * Splits a video at given timestamp and returns new background instances
 * @param {number} timestamp timestamp from where a video is splitted
 * @param {array} instances backgroundinstances
 */
const splitVideoAtTimestamp = (timestamp, instances, video) => {
  if (!video) return instances;

  const index = instances.findIndex(instance => instance.id === video.id);
  const instancesWithoutOriginal = instances.filter(instance => instance.id !== video.id);

  const splittedClone = {
    ...video,
    id: Date.now(),
    visibleFrom: timestamp,
    duration: video.visibleTo - timestamp,
    playFrom: video.playFrom + (timestamp - video.visibleFrom),
  };

  const modifiedOriginal = {
    ...video,
    visibleTo: timestamp,
    duration: timestamp - video.visibleFrom,
  };

  return [
    ...instancesWithoutOriginal.slice(0, index),
    modifiedOriginal,
    splittedClone,
    ...instancesWithoutOriginal.slice(index),
  ];
};

/**
 * Goes trough instances starting from a specific index and
 * checks the index number of the next instances that is not
 * present in the instanceId's array.
 * @param {number} startIndex
 * @param {array} instances text, bg instances...
 * @param {array} instanceIds array of instance id's
 */
const findNextNotSelected = (startIndex, instances, instanceIds) => {
  let nextNotSelected = startIndex + 1;

  for (let i = startIndex; i <= instances.length; i += 1) {
    const instance = instances[i];

    if (i === instances.length) {
      nextNotSelected = instances.length;
      break;
    }

    if (!instanceIds.includes(instance.id)) {
      nextNotSelected = i;
      break;
    }
  }

  return nextNotSelected;
};

/**
 * Duplicates instances that are represented in the instanceId's
 * array. If two or more instances are selected so that they are
 * visually next to each other in the timeline, they are duplicated
 * as a "group"
 *
 * Example: A, B, C, D (where A and B is copied) would become:
 * A, B, A, B, C, D rather than A, A, B, B, C, D
 * @param {array} instanceIds array of instance ids that are duplicated
 * @param {array} instances instances (text, bg...)
 */
const duplicateInstances = (instanceIds, instances) => {
  const newInstances = [...instances];
  let alreadyInserted = 0;

  instances.forEach((instance, index) => {
    if (instanceIds.includes(instance.id)) {
      const nextNotSelected = findNextNotSelected(index, instances, instanceIds);

      const insertIndex = nextNotSelected + alreadyInserted;

      newInstances.splice(insertIndex, 0, {
        ...instance,
        id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
      });

      // Add to alreadyInserted so that index number
      // take in account the items that are inserted
      // before them
      alreadyInserted += 1;
    }
  });

  return calculateCompositionInstanceStartTimes(newInstances);
};


const duplicateTextInstances = (instanceIds, instances) => {
  const currentInstances = instances;
  if (currentInstances.length <= 0) return currentInstances;
  const newInstances = [];
  let alreadyInserted = currentInstances.length;

  instances.forEach((instance, index) => {
    if (instanceIds.includes(instance.id)) {
      const nextNotSelected = findNextNotSelected(index, instances, instanceIds);

      const insertIndex = nextNotSelected + alreadyInserted;

      newInstances.splice(insertIndex, 0, {
        ...instance,
        id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
      });

      // Add to alreadyInserted so that index number
      // take in account the items that are inserted
      // before them
      alreadyInserted += 1;
    }
  });

  return calculateTextInstanceStartTimes(currentInstances, newInstances);
};

/**
 * Toggles audio track. In the future, multiple audios might
 * be supported, so and array is used, but for now it only
 * has one value so it can be either cleared or an array with
 * one element can be returned.
 * @param {array} instances composition audioInstances
 * @param {object} track track to be toggled
 */
const toggleAudio = (instances, track) => {
  const isToggled = instances
    .map(instance => instance.uid)
    .includes(track.uid);

  if (isToggled) {
    return [];
  }

  return [track];
};


export {
  calculateCompositionDuration,
  calculateCompositionInstanceStartTimes,
  splitVideoAtTimestamp,
  duplicateInstances,
  duplicateTextInstances,
  toggleAudio,
};
