import axios from 'axios';
// import { EventTypes } from 'redux-segment';
import * as types from '@constants/actionTypes';
import {
  FOOTAGE_URL,
  // IMAGES_URL,
  TAGS_URL,
  VIDEOS_URL
} from '@constants/api';
import { stateKeys } from '@constants/footage';
import { getFormattedFavorite } from '@helpers/footage';
import Snap from 'snapsvg-cjs';
import { compositionNotGenerated, compositionRemoveGenerateTag } from './composition';


function updateFootage(data, key) {
  return {
    type: types.UPDATE_FOOTAGE,
    payload: {
      data,
      key
    },
  };
}
function requestImages(filter) {
  return {
    type: types.FOOTAGE_REQUEST_IMAGES,
    payload: {
      filter,
    },
  };
}


function receiveImages(entries, filter, page) {
  return {
    type: types.FOOTAGE_RECEIVE_IMAGES,
    payload: {
      entries,
      filter,
      receivedAt: Date.now(),
      page,
    },
  };
}

function receiveSvgData(svgData, filter, src) {
  return {
    type: types.FOOTAGE_RECEIVE_SVG,
    payload: {
      svgData,
      filter,
      src,
      receivedAt: Date.now(),
    },
  };
}

function invalidateImages(filter) {
  return {
    type: types.FOOTAGE_INVALIDATE_IMAGES,
    payload: {
      filter,
    },
  };
}

function requestVideos(filter) {
  return {
    type: types.FOOTAGE_REQUEST_VIDEOS,
    payload: {
      filter,
    },
  };
}

function receiveVideos(entries, filter, page) {
  return {
    type: types.FOOTAGE_RECEIVE_VIDEOS,
    payload: {
      entries,
      filter,
      receivedAt: Date.now(),
      page,
    },
  };
}

function invalidateVideos(filter) {
  return {
    type: types.FOOTAGE_INVALIDATE_VIDEOS,
    payload: {
      filter,
    },
  };
}

function requestSearch(type, filter) {
  return {
    type: types.FOOTAGE_SEARCH_REQUEST,
    payload: {
      type,
      filter,
    },
  };
}

function receiveSearchFootage(type, filter, entries, relatedTags, searchTerm, page) {
  return {
    type: types.FOOTAGE_SEARCH_RECEIVE,
    payload: {
      filter,
      type,
      entries,
      relatedTags,
      searchTerm,
      page,
    },
    meta: {
      // analytics: {
      //   eventType: EventTypes.track,
      //   eventPayload: {
      //     event: 'User searched footage',
      //     properties: {
      //       filter,
      //       type,
      //       entries,
      //       searchTerm,
      //     },
      //   },
      // },
    },
  };
}

function receiveRelatedTags(relatedTags, tag) {
  if (relatedTags.length === 0) {
    return {
      type: types.RELATED_TAGS_NOTFOUND,
      notify: {
        message: `${tag} not supported`,
        kind: 'danger',
      },
    };
  }

  return {
    type: types.FOOTAGE_RELATED_TAGS_RECEIVE,
    payload: {
      relatedTags,
    },
  };
}

function shouldFetchVideos(state, filter, page) {
  const key = stateKeys.videos[filter];
  const data = state.footage[key];
  let entries;
  if (filter === 'stock') {
    entries = data.entries && data.entries[page - 1] && data.entries[page - 1].entries;
    if (!entries) return true;
  } else {
    entries = data.entries && data.entries[page];
    return true;
  }
  if (data.isFetching) {
    return false;
  }
  return data.didInvalidate;
}

function shouldFetchImages(state, filter, page) {
  const key = stateKeys.images[filter];
  const data = state.footage[key];
  let entries;
  if (filter === 'stock') {
    entries = data.entries && data.entries[page - 1] && data.entries[page - 1].entries;
  } else {
    entries = data.entries && data.entries[page];
  }

  if (!entries || (entries.length === 0)) {
    return true;
  } else if (data.isFetching) {
    return false;
  }

  return data.didInvalidate;
}


function fetchImages(filter, page = 0, sorting) {
  return async (dispatch, getState) => {
    if (shouldFetchImages(getState(), filter, page)) {
      dispatch(requestImages(filter, page, sorting));
      dispatch(receiveImages([], filter, page))
      return
      // commenting out because we can't fetch assets
      // let url = `${IMAGES_URL}?filter=${filter}&page=${page}`;
      // if (filter !== 'stock') {
      //   url += `&type=${filter}`;
      // } else {
      //   url += `&sorting=${sorting}`;
      // }
      // const response = await axios.get(url);
      // if (response && response.status === 200) {
      //   dispatch(receiveImages(response.data.data.entries, filter, page));
      // }
    }
  };
}

function getImages(unsplashId) {
  return async () => {
    const url = `${FOOTAGE_URL}/image/download`;
    const data = {
      unsplashId,
    };

    const response = await axios.post(url, data);

    return response.data.results;
  };
}

function fetchVideos(filter, page = 0, limit = 50) {
  return async (dispatch, getState) => {
    if (shouldFetchVideos(getState(), filter, page)) {
      dispatch(requestVideos(filter));
      let url = `${VIDEOS_URL}?limit=${limit}&page=${page}`;

      if (filter !== 'stock') {
        url += `&type=${filter}`;
      }

      const response = await axios.get(url)
        .catch(err => console.log('err', err)); // eslint-disable-line no-console
      if (response && response.status === 200) {
        dispatch(receiveVideos(response.data.data.entries, filter, page));
      }
    }
  };
}

function requestTagSearch(searchTerm) {
  return {
    type: types.FOOTAGE_TAG_SEARCH_REQUEST,
    payload: {
      searchTerm,
    },
  };
}

function receiveTagSearch(entries) {
  return {
    type: types.FOOTAGE_TAG_SEARCH_RECEIVE,
    payload: {
      entries,
    },
  };
}

function clearTagSearch() {
  return {
    type: types.FOOTAGE_TAG_SEARCH_CLEAR,
    payload: {},
  };
}

function searchTags(searchTerm) {
  return async (dispatch) => {
    dispatch(requestTagSearch());
    const url = `${TAGS_URL}/search`;

    const data = { query: searchTerm };

    const response = await axios.post(url, data)
      .catch(err => console.log('err', err)); // eslint-disable-line no-console
    if (response) dispatch(receiveTagSearch(response.data.data));
  };
}

function search(type, searchTerm, filter, categoryName, sorting, page) {
  return async (dispatch) => {
    // REVIEW: the following code until the line, may not be necessary, recheck how it is used.
    dispatch(requestSearch(type, filter));
    if (searchTerm.trim() === '') {
      if (type === 'video') {
        dispatch(fetchVideos(filter, 50, 0));
      } else {
        dispatch(fetchImages(filter, 0));
      }
      return;
    }

    // =======

    const url = `${FOOTAGE_URL}/${type}/search`;
    let data;
    if (!categoryName && searchTerm) {
      // Keyword search
      data = {
        queryParams: {
          query: searchTerm,
          resolution: 'high_definition',
          per_page: 50,
          sort: sorting,
          page,
        },
        // tags: [searchTerm]
      };
    } else if (categoryName) {
      // List category
      data = {
        queryParams: {
          category: categoryName,
          resolution: 'high_definition',
          per_page: 50,
          sort: sorting,
        },
        // tags: [searchTerm]
      };
    }
    const response = await axios.post(url, data)
      .catch(err => console.log('err', err)); // eslint-disable-line no-console
    const { data: reponseData } = response && response.data;
    if (reponseData) {
      dispatch(receiveSearchFootage(
        type,
        filter,
        response.data.data.entries,
        response.data.data.relatedTags,
        searchTerm,
        page,
      ));
    }
  };
}

function searchRelatedTags(searchTerm) {
  return async (dispatch) => {
    dispatch(requestSearch('video', 'stock'));
    dispatch(fetchVideos('stock', 50, 0));

    const url = `${FOOTAGE_URL}/video/search`;
    const data = {
      queryParams: {
        query: searchTerm,
        resolution: 'high_definition',
        per_page: 50,
        sort: 'popular',
      },
    };

    const response = await axios.post(url, data)
      .catch(err => console.log('err', err)); // eslint-disable-line no-console
    const { data: relatedTagData } = response && response.data;
    if (relatedTagData && relatedTagData.relatedTags.length === 0) {
      dispatch(compositionNotGenerated(searchTerm));
      dispatch(compositionRemoveGenerateTag(searchTerm));
    } else {
      dispatch(receiveRelatedTags(response.data.data.relatedTags));
    }
  };
}

function footageChangeSorting(type) {
  return {
    type: types.FOOTAGE_CHANGE_SORTING,
    payload: {
      type,
    },
  };
}

const addToFavorite = (favFootage, type, favoriteId) => ({
  type: types.FOOTAGE_ADD_TO_FAVORITE,
  payload: {
    favFootage,
    type,
    favoriteId,
  },
  notify: {
    message: `${type} was added to favorite`,
    kind: 'success',
  },
});
const removeFromFavorite = (favoriteId, type) => ({
  type: types.FOOTAGE_REMOVE_FROM_FAVORITE,
  payload: {
    favoriteId,
    type,
  },
  notify: {
    message: `${type} was removed from favorite`,
    kind: 'danger',
  },
});

const handleAddToFavorite = (footage, type) => async (dispatch) => {
  const url = `${FOOTAGE_URL}/${type}/favorite/?type=${type}`;
  const data = { footage: getFormattedFavorite(footage, type), type };

  const response = await axios.post(url, data)
    .catch(err => console.log('err', err)); // eslint-disable-line no-console
  if (response.data.add === 'success') dispatch(addToFavorite(footage, type, response.data.favoriteId));
};


const handleRemoveFavorite = (favoriteId, type) => async (dispatch) => {
  const url = `${FOOTAGE_URL}/${type}/favorite/?type=${type}&favoriteId=${favoriteId}`;
  const response = await axios.delete(url)
    .catch(err => console.log('err', err)); // eslint-disable-line no-console
  if (response.data.remove === 'success') dispatch(removeFromFavorite(favoriteId, type));
};

const fetchShape = (src, filter) => (dispatch) => {
  Snap.load(src, (data) => {
    dispatch(receiveSvgData(data, filter, src));
  });
};

export {
  updateFootage,
  requestImages,
  fetchImages,
  getImages,
  invalidateImages,
  fetchVideos,
  invalidateVideos,
  search,
  searchTags,
  searchRelatedTags,
  clearTagSearch,
  footageChangeSorting,
  handleAddToFavorite,
  handleRemoveFavorite,
  fetchShape,
};
