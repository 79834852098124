import { Modal } from '@sumo-apps/core'
import React from 'react'
import { connect } from 'react-redux'
import { toggleModal } from '../../actions/modals'

function About({
    onToggleModal,
    strings
}) {
    function closeModal() {
        onToggleModal({
            about: false
        })
    }
    return (
        <Modal
            appName="video"
            buttons={[
                { action: closeModal, text: strings._ok },
            ]}
            title={strings._about}
            closeFunc={closeModal}
            about
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <p>For bugfixes and ideas, please contact <a href='https://community.sumo.app/support' style={{ color: 'white' }}>support</a>.</p>
            </div>
        </Modal>
    )
}


const mapStateToProps = ({ modals, languages }) => {
    return {
        modals,
        strings: languages.strings
    };
};
export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
})(About);