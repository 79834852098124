const getMaxDuration = userRole =>
  // For now all compositions have max duration 4min
  //
  // if (userRole && userRole >= 204) {
  //   return 500000;
  // }
  240001;

const compositionAspect = (type) => {
  switch (type) {
    case 'vertical':
      return {
        width: 1080,
        height: 1920,
      };
    case 'square':
      return {
        width: 1080,
        height: 1080,
      };
    case 'facebook_vertical':
      return {
        width: 1080,
        height: 1350,
      };
    default:
      return {
        width: 1920,
        height: 1080,
      };
  }
};

/**
 * When adding footage to the timeline, a default duration is used.
 * Default duration is not used if a more suitable one is
 * calculated depending on on the situation.
 */

export const DEFAULT_FOOTAGE_DURATION = 3000

export const DEFAULT_TEXT_DURATION = 3000

export const MAX_VIDEO_DURATION = getMaxDuration()

export {
  getMaxDuration,
  compositionAspect,
};
