/* eslint-disable no-unused-vars */
import { Modal } from '@sumo-apps/core';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toggleModal } from '../../actions/modals';

const figureOutCommunityUrl = () => {
    let URL = ''
    if (process.env.NODE_ENV === "production") {
        switch (window.location.hostname) {
            case 'video.sumo.app':
                URL = 'https://community.sumo.app'
                break
            case 'video.staging.sumo.app':
                URL = 'https://community.staging.sumo.app'
                break
            case 'video.dev.sumo.app':
            default:
                URL = 'https://community.dev.sumo.app'
                break
        }
    } else {
        URL = 'http://localhost:8888'
    }
    return URL
}

const cloudProps = {
    fileName: "Test"
}
const user = null;

function SaveToCloud({
    onToggleModal,
    composition,
    strings,
    languages
}) {
    const nameRef = useRef(null)
    const statusRef = useRef(null)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const targetParameter = urlParams.get('target')
    const optParameter = urlParams.get('opt') || ''
    console.log({ composition })
    useEffect(() => {
        // Autofocus
        if (document.querySelector('.modal-inputs-wrapper input.sumo-input')) {
            document.querySelector('.modal-inputs-wrapper input.sumo-input').focus()
            document.querySelector('.modal-inputs-wrapper input.sumo-input').select()
            window.preventKeyboardShortcuts = true
            window.addEventListener('keydown', handleKeyboard)
        }
        // Save/Update text in the button before typing
        //   handleKeyPress()
        // eslint-disable-next-line
    }, [])

    const handleKeyboard = (e) => {
        if (e.code === 'Escape') {
            closeModal()
        }
        if (e.code === 'Enter') {
            saveToCloud()
        }
    }


    function saveToCloud() {
        const nameValue = nameRef.current.value
        const statusValue = statusRef.current.value
        // dispatch(updateCanvasProps({
        //   loading: true,
        //   loadingText: strings._saving,
        //   imageName: nameValue
        // }))
        onToggleModal({
            saveToCloud: false
        })
        setTimeout(async () => {
            //   dispatch(updateCloudProps({
            //     status: statusValue
            //   }))
            try {
                // wasm.saveCurrentImage('jpg')
                const mockPromise = new Promise((res, rej) => res());
                mockPromise.then(async (blob) => {
                    // Let's make thumbnail out of it
                    //   let imageUrl = window.URL.createObjectURL(blob)
                    //   const thumbnail = await makeThumbnail({ image: imageUrl })
                    //   window.URL.revokeObjectURL(imageUrl);
                    // const thumbnail = await window.sumocloud.makeThumbnail(composition.present.backgroundInstances.thumbnailImageSrc)
                    const dataToSend = {
                        name: nameValue,
                        app_id: 7,
                        description: '',
                        // imagedata: thumbnail,
                        status: statusValue,
                        data: composition.present,
                        opt: optParameter,
                    }
                    console.log(JSON.stringify(dataToSend))
                    if (cloudProps.slug === '' || nameValue !== cloudProps.fileName) {
                        if (cloudProps.slug !== '' && cloudProps.targetId !== null) {
                            dataToSend.target_id = cloudProps.targetId
                        }
                        // SAVE AS NEW
                        window.sumocloud.saveWork({
                            data: dataToSend,
                            onUploadProgress: (p) => {
                                // dispatch(progressModal({
                                //     title: strings._savingToCloud,
                                //     percent: Math.round((p.loaded / p.total) * 100),
                                // }))
                                console.log(p.loaded / p.total)
                            }
                        })
                            .catch(e => {
                                // dispatch(toggleModal({
                                //     type: "progress",
                                //     show: false
                                // }))
                                // dispatch(updateCanvasProps({
                                //     loading: false,
                                //     loadingText: null,
                                // }))
                            })
                            .then(response => {
                                console.log("Saved to cloud", { response })
                                if (targetParameter) {
                                    window.location.href = targetParameter
                                    return
                                }
                                // dispatch(toggleModal({
                                //     type: "progress",
                                //     show: false
                                // }))
                                // dispatch(updateCloudProps({
                                //     refreshWorks: true,
                                //     libraryOpen: false,
                                //     slug: response.data.slug,
                                //     targetId: response.data.id,
                                //     fileName: nameValue,
                                //     status: response.data.status
                                // }))
                                // // dispatch(createNotification({ type: 'success', message: strings._projectwassavedtocloud }))
                                // dispatch(updateCanvasProps({
                                //     loading: false,
                                //     loadingText: null,
                                //     dirty: false,
                                // }))
                            })
                        window.parent.postMessage({
                            post: {
                                type: 'Works',
                                name: nameValue,
                                dataToSend,
                                saveMode: 'new'
                            }
                        }, '*')
                    } else {
                        // SAVE AS UPDATE
                        window.sumocloud.updateWork({
                            data: dataToSend,
                            slug: cloudProps.slug,
                            onUploadProgress: (p) => {
                                // dispatch(progressModal({
                                //     title: strings._savingToCloud,
                                //     percent: Math.round((p.loaded / p.total) * 100),
                                // }))
                                // console.log(p); 
                                console.log(p.loaded / p.total)
                            }
                        })
                            .then(response => {
                                // dispatch(toggleModal({
                                //     type: "progress",
                                //     show: false
                                // }))
                                if (response.status === 404) {
                                    // SAVE AS NEW
                                    window.sumocloud.saveWork({
                                        data: dataToSend,
                                        onUploadProgress: (p) => {
                                            // dispatch(progressModal({
                                            //     title: strings._savingToCloud,
                                            //     percent: Math.round((p.loaded / p.total) * 100),
                                            // }))
                                            // console.log(p); 
                                            console.log(p.loaded / p.total)
                                        }
                                    })
                                        .then(response => {
                                            // dispatch(toggleModal({
                                            //     type: "progress",
                                            //     show: false
                                            // }))
                                            // dispatch(updateCloudProps({
                                            //     refreshWorks: true,
                                            //     libraryOpen: false,
                                            //     slug: response.data.slug,
                                            //     targetId: response.data.id,
                                            //     fileName: nameValue,
                                            //     status: response.data.status
                                            // }))
                                            // dispatch(updateCanvasProps({
                                            //     loading: false,
                                            //     loadingText: null,
                                            //     dirty: false,
                                            // }))
                                            // dispatch(createNotification({
                                            //     type: 'confirm',
                                            //     message: strings._projectwassavedtocloud,
                                            //     confirmButtonText: strings._viewWork,
                                            //     func: () => {
                                            //         window.open(`${figureOutCommunityUrl()}/works/${response.data.slug}`)
                                            //     },
                                            // }))
                                        })
                                } else {
                                    // dispatch(updateCloudProps({
                                    //     refreshWorks: true,
                                    //     libraryOpen: false,
                                    //     slug: response.data.slug,
                                    //     targetId: response.data.id,
                                    //     fileName: nameValue,
                                    //     status: response.data.status
                                    // }))
                                    // dispatch(updateCanvasProps({
                                    //     loading: false,
                                    //     loadingText: null,
                                    // }))
                                    // dispatch(createNotification({
                                    //     type: 'confirm',
                                    //     message: strings._projectwassavedtocloud,
                                    //     confirmButtonText: strings._viewWork,
                                    //     func: () => {
                                    //         window.open(`${figureOutCommunityUrl()}/works/${response.data.slug}`)
                                    //     },
                                    // }))
                                }
                            })
                    }
                })
            } catch (e) {
                // dispatch(createNotification({ type: 'error', message: strings._couldnotsavetocloud }))
                // dispatch(updateCanvasProps({
                //     loading: false,
                //     loadingText: null,
                // }))
            }
        })
    }


    function closeModal() {
        window.preventKeyboardShortcuts = false
        window.removeEventListener('keydown', handleKeyboard)
        onToggleModal({
            saveToCloud: false
        })
    }
    function validateText(e) {
        e.target.value = e.target.value.replace('.', '')
        if (!/[a-zA-ZäÄöÖåÅ0-9_-\s+*'^~¨`´<>|?!="#¤%&@£$§½\\/{}()\][]/.test(e.key)) {
            e.preventDefault()
        }
    }

    function getActiveLanguage() {
        console.log({ languages })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const languageParam = urlParams.get("lang")
        const matchingLanguage = languages.find((lang) => lang.code === languageParam)

        if (matchingLanguage) {
            return matchingLanguage
        } else {
            return languages.find((lang) => lang.code === 'en')
        }
    }
    const activeLanguage = getActiveLanguage()

    if (false) {
        // if (!user || !user.license || user.license === "basic") {
        return (
            <div className="splash">
                <Modal
                    appName="video"
                    title={'title'}
                    buttons={[
                        // { action: closeModal, text: strings._cancel },
                        { action: closeModal, text: strings._later },
                        {
                            action: () => {
                                const redirectUrl = `?redirect=video${encodeURIComponent('?lang=' + activeLanguage.code)}`
                                window.open(figureOutCommunityUrl() + '/pricing')
                            }, text: strings._upgrade
                        },
                    ]}
                    closeFunc={closeModal}
                    style={{
                        width: 580 + 'px',
                        height: 400 + 'px',
                        maxWidth: 'unset',
                        maxHeight: 'unset',
                        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/pop-up-bg.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div
                        className="splash-content"
                    // dangerouslySetInnerHTML={createMarkup()}
                    >
                        <img className="logo" src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
                        <div className="text-content">
                            You have discovered a SumoPro feature!
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <Modal
            appName="paint"
            title={strings._savetocloud}
            buttons={[
                { action: closeModal, text: "Cancel" },
                { action: saveToCloud, text: "Save" },
            ]}
            closeFunc={closeModal}>
            <div className="modal-inputs-wrapper">
                <label>{strings._name}:</label>
                <input
                    id='save-work-input'
                    ref={nameRef}
                    type='text'
                    className="sumo-input"
                    name='fileName'
                    onKeyPress={validateText}
                    //   onInput={handleKeyPress}
                    placeholder={cloudProps.fileName || strings["_untitled"] || 'Untitled'}
                    defaultValue={cloudProps.fileName || strings["_untitled"] || 'Untitled'}
                />
            </div>
            <div className="modal-inputs-wrapper">
                <label>{strings._status}:</label>
                <div className='sumo-select' >
                    <select
                        id='save-work-select'
                        ref={statusRef}
                        style={{ paddingRight: 20 }}
                        defaultValue={2}
                    >
                        <option value={1}>{strings._private}</option>
                        <option value={2}>{strings._public}</option>
                        <option value={3}>{strings._remixable}</option>
                    </select>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ modals, composition, languages }) => {
    return {
        modals,
        composition,
        strings: languages.strings,
        languages: languages.languages
    };
};
export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
})(SaveToCloud);
