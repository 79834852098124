import * as types from '@constants/actionTypes';
import axios from 'axios';
import { USER_URL } from '@constants/api';

// const settingSaved = () => ({
//   type: types.BRAND_SETTING_SAVED,
//   notify: {
//     message: 'Saved',
//     kind: 'info',
//     dismissAfter: 2000,
//   },
// });

const saveUserPreference = () => async (dispatch, getState) => {
  const { brandkit } = getState();
  const preference = {
    name: brandkit.change,
    value: brandkit[brandkit.change],
  };
  await axios.post(`${USER_URL}/updatebrandkit`, { preference });
  // TODO: uncomment if necessary
  // if (response) dispatch(settingSaved());
};

const setDefaultBrandkit = async (brandkit) => {
  await axios.post(`${USER_URL}/setbrandkit`, { brandkit });
};

const updateColor = (color, colorIndex, palletId) => ({
  type: types.BRAND_UPDATE_COLOR,
  payload: {
    color,
    colorIndex,
    palletId,
  },
  savePreference: true,
});
const addNewPallet = () => ({
  type: types.BRAND_ADD_PALLET,
  savePreference: true,
});

const addNewBrandColor = palletId => ({
  type: types.BRAND_ADD_COLOR,
  payload: {
    palletId,
  },
  savePreference: true,
});
const removeBrandPallet = palletId => ({
  type: types.BRAND_REMOVE_PALLET,
  payload: {
    palletId,
  },
  savePreference: true,
});
const removeBrandColor = (colorIndex, palletId) => ({
  type: types.BRAND_REMOVE_COLOR,
  payload: {
    colorIndex,
    palletId,
  },
  savePreference: true,
});

const setBrandLogo = brandLogo => ({
  type: types.BRAND_SET_LOGO,
  payload: {
    brandLogo,
  },
  savePreference: true,
});
const setBrandFont = brandFonts => ({
  type: types.BRAND_SET_FONT,
  payload: {
    brandFonts,
  },
  savePreference: true,
});

export {
  updateColor,
  setBrandFont,
  setBrandLogo,
  setDefaultBrandkit,
  addNewPallet,
  addNewBrandColor,
  removeBrandPallet,
  removeBrandColor,
  saveUserPreference,
};
