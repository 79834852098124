import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@components/Button';
import ToolTip from '@components/ToolTip';
import { DEFAULT_FOOTAGE_DURATION } from '@constants/composition';
import { deselectActiveObject, getLayerById } from '@lib/fabricHelper';
import { getSeekPoint } from '@helpers/timeline';
import { renderHelper } from '@lib/renderHelper';
import { getNewInstanceTimeslot } from '@helpers/composition';
import { Pause, Play } from '@components/Icons';
import Ticker from '@components/Ticker';
import Spinner from '@components/Spinner';
import KeyboardShortcuts from '@components/KeyboardShortcuts';
import Scrubber from './Scrubber';
import Ruler from './Ruler';
import TimelineVideoInstance from './TimelineVideoInstance';
import TimelineImageInstance from './TimelineImageInstance';
import TimelineTextInstance from './TimelineTextInstance';
import EmptyComposition from './EmptyComposition';
import Trash from './Trash';
import TimeTooltip from './TimeTooltip';
import VideoContext from './VideoContext';
import Overlay from './Overlay';
import './Timeline.scss';

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.containerElement = null;

    this.setContainerWidth = (element) => {
      this.containerElement = element;
    };

    // TODO: msRatio is no longer used, so change this to "firstRatioCalculated: true"
    // or similar to trigger the first re-render after element ref is fetched
    this.state = {
      msRatio: null,
      resizeDirection: null,
      canDelete: null,
      selected: [],
      swapWithId: null,
      seeking: false,
    };
  }

  componentDidMount() {
    // TODO: calculate offset and set state
    if (this.containerElement) {
      // msRatio should update when resizing window
      window.addEventListener('resize', this.setMsRatio);
      this.setMsRatio();
    }
  }
  componentDidUpdate() {
    const { selected } = this.state;
    if (this.props.activeLayerId && selected.length > 0) {
      this.setState({
        selected: [],
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMsRatio);
  }
  // stop playing timeline when necessary
  stopPlaying() {
    const { playing } = this.props.timeline;
    if (playing) {
      this.props.timelineTogglePlay();
    }
  }

  setMsRatio = () => {
    const containerWidth = this.containerElement.offsetWidth;
    const { compositionDuration } = this.props;
    const msRatio = containerWidth / compositionDuration;
    this.setState({
      msRatio,
    });
  }

  calculateMsRatio = (containerWidth, compositionDuration) =>
    (containerWidth / compositionDuration);

  /**
   * Gets called when something is hovered over the timeline.
   * If the dragged item originates from the timeline moveInstance will re-organize
   * the items while the user drags it across the timeline.
   * If the dragged item originates from a footagelist (uploads, favorites...) it first
   * gets added to the timeline (this.props.onAdd) and after that it gets moved since
   * it then "originates from the timeline".
   *
   * @param {string} sourceId - ID of the source element (used to find the element data)
   * @param {string} targetID - ID of the target element
   * @param {component} component - Dragged React component
   * @param {number} offset - Clientoffset in pixels (provided by React DND)
   * @param {string} filename - Dragged footages filename.
   */
  moveInstance = ({
    sourceId,
    targetId,
    component,
    offset,
  }) => {
    const { backgroundInstances } = this.props;
    let sourceItem;

    const sourceTimelineIndex = backgroundInstances.findIndex(item => item.id === sourceId);
    const targetTimelineIndex = backgroundInstances.findIndex(item => item.id === targetId);

    const sourceInTimeline = sourceTimelineIndex > -1;
    const targetInTimeline = targetTimelineIndex > -1;

    if (sourceInTimeline) {
      sourceItem = backgroundInstances[sourceTimelineIndex];
    }

    // Moving within timeline
    if (sourceInTimeline && targetInTimeline) {
      const hoverBoundingRect = component.node.getBoundingClientRect();
      const targetMiddle = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = offset;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (sourceTimelineIndex < targetTimelineIndex && hoverClientX < targetMiddle) {
        return;
      }

      if (sourceTimelineIndex > targetTimelineIndex && hoverClientX > targetMiddle) {
        return;
      }

      this.props.onMove(sourceTimelineIndex, targetTimelineIndex, sourceItem);
    }
  }

  addToEmptyComposition = ({
    filename,
    videoId,
    src,
    sourceId,
    type,
    thumbnail,
    sourceDuration,
    width,
    height,
  }) => {
    const sourceItem = {
      filename,
      id: sourceId,
      duration: DEFAULT_FOOTAGE_DURATION,
      sourceDuration,
      playFrom: 0,
      thumbnail,
      src,
      videoId,
      top: 0,
      left: 0,
      type,
      width,
      height,
    };

    this.props.onAdd(0, sourceItem);
  }

  /**
   *  When replacing an instance, source is always outside of the timeline
   *  and target is some clip on the timeline. (replaceInstance is not called
   *  when re-organazing clips in timeline).
   *
   *  However, when user drags something on top of the timeline, moveInstance
   *  is called. Since the source is different from the target, the item is
   *  added to timeline (as a see trough placeholder). Because this happens, at
   *  the time of actual replaceInstance, the source and destination items are
   *  both already in timeline (backgroundInstances).
   *
   *  @param {string} sourceId - ID of the source element
   *  @param {string} targetId - ID of the target element
   */

   toggleIntercom = (toggle) => {
     /* Timeline - Trimming text/video/image over Intercom icon doesn't release the trim.
     this function is added since intercome launcher is sacking events happening on top of it.
     intercome is now shutdown when users are trimming timeline instances and booted again when
     resizing stops
     */
     if (window.Intercom) window.Intercom(toggle);
   };

  replaceInstance = ({ sourceId, targetId }) => {
    const { backgroundInstances } = this.props;
    const sourceIndex = backgroundInstances.findIndex(item => item.id === sourceId);
    const targetIndex = backgroundInstances.findIndex(item => item.id === targetId);

    /**
     * Old element gets removed first so we need to correct the index number depending
     * on if the element gets removed before or after target index (removing of the element
     * changes the index numbers of the other elements, so a correction needs to be made).
     */
    let replaceIndex = 0;
    if (sourceIndex > targetIndex) {
      replaceIndex = sourceIndex - 1;
    } else {
      replaceIndex = sourceIndex;
    }

    const sourceItem = backgroundInstances.filter(item => item.id === sourceId)[0];

    this.props.onReplace(sourceIndex, replaceIndex, sourceItem);
  }

  findInstance = (id, list) => {
    const { backgroundInstances } = this.props;
    if (list !== 'timeline') {
      return {
        index: null,
      };
    }

    const instanceIndex = backgroundInstances.map(item => item.id).indexOf(id);
    const instance = backgroundInstances[instanceIndex];

    return {
      instance,
      index: instanceIndex,
    };
  }

  handleTimelineClick = (e) => {
    const { classList } = e.target;
    if (!classList.contains('InstanceBackground')) {
      this.deSelectInstances();
    }
  }

  toggleInstanceSelect = (inst) => {
    const { selected } = this.state;
    const isSelected = selected.find(instance => instance.id === inst.id);
    if (isSelected) {
      const removed = selected.filter(instance => instance.id !== inst.id);
      this.setState({
        selected: removed,
      });
    } else {
      this.setState({
        selected: [...selected, inst],
      });
    }
  }

  deSelectInstances = () => {
    const { activeBackgroundId } = this.props;
    if (!activeBackgroundId) return;
    const layer = renderHelper.engine.layers.find(l => l.id === activeBackgroundId);
    const { object } = layer;
    object.lockMovementX = true;
    object.lockMovementY = true;
    this.props.onSelect(null);
    // deselectActiveObject(layer);
    this.setState({
      selected: [],
      selectedInstances: [],
    });
  }
  selectInstance = (instance) => {
    this.setState({
      selected: [instance],
    });
  }

  handleAdd = ({ targetId }) => {
    this.props.generateUniqueId(targetId);
  }

  handleCancel = ({ id, originalIndex, list: originalList }) => {
    if (!id) return;
    /* BUG: this code seems to be something added trying to
     fix undefined id sentry bug, not sure if it is needed anymore
    since the fix was incorrect.
    */
    const { backgroundInstances } = this.props;
    let item;

    const indexInTimeline = backgroundInstances.findIndex(instance => instance.id === id);
    const isInTimeline = indexInTimeline > -1;

    if (isInTimeline) {
      item = backgroundInstances[indexInTimeline];
    }

    if (originalList === 'timeline') {
      this.props.onMove(indexInTimeline, originalIndex, item);
    }

    if (originalList === 'gallery' && isInTimeline) {
      this.props.onRemove(indexInTimeline);
    }
  }

  handleDragStart = () => {
    this.stopPlaying();
    this.setState({
      canDelete: true,
    });
  }

  handleDragEnd = () => {
    this.setState({
      canDelete: null,
    });
  }

  handleDelete = (id) => {
    const { index } = this.findInstance(id, 'timeline');
    this.props.onRemove(index);
    this.props.onDeletedVideo();
    this.stopPlaying();
  }

  handleTextDelete = (id) => {
    const {
      titleInstances,
      // timeline
    } = this.props;
    this.setState({ canDelete: null });
    this.stopPlaying();
    /* const title = titleInstances
      .find(t => t.visibleFrom <= timeline.currentTime && t.visibleTo >= timeline.currentTime); */
    titleInstances.forEach((title) => {
      if (title && title.textInstances.find(t => t.id === id)) {
        title.textInstances.forEach((instance) => {
          this.props.onTextRemove(instance.id);
        });
      }
    });
  }

  handleBackspaceDeletion = () => {
    const { selected } = this.state;
    const {
      activeBackgroundId,
      activeTextId,
      activeLayerId,
      compositionLayerRemove,
      selectedShapes,
      onRemoveShape,
      onRemove,
    } = this.props;
    // if logo layer is selected, this component unselects
    // everything in componentDidUpdate thus we only delete the selected logo layer
    if (activeLayerId) {
      compositionLayerRemove(activeLayerId);
    }
    if (activeTextId) {
      activeTextId.forEach(id => this.props.onTextRemove(id));
    }
    if (activeBackgroundId) {
      onRemove(activeBackgroundId);
    }
    if (selected.length > 0) {
      // otherwise map through selected items and
      // delete bg instances
      if (activeBackgroundId) {
        this.handleDelete(activeBackgroundId);
      } else {
        selected.forEach((selectedItem) => {
          this.handleDelete(selectedItem.id);
        });
      }
    }
    if (selectedShapes.length > 0) {
      selectedShapes.forEach((s) => {
        onRemoveShape(s);
      });
    }
    this.deSelectInstances();
  };

  handleTextClick = (instance, event, alreadySelected) => {
    event.stopPropagation();
    this.deSelectInstances();
    // if already seclected, unselect everything and select only that text
    if (alreadySelected) this.props.onSelectTitle([]);
    // if sidebar is on expanded view revert back
    if (this.props.sidebarExpanded) this.props.sidebarExpandToggle();
    this.handleProgressJump(event);

    const { id } = instance;
    const { titleInstances, activeTextId } = this.props;
    const title = titleInstances && titleInstances
      .find(t => t.textInstances.find(text => text.id === id));
    const selectedTexts = title.textInstances.map(text => text.id);

    if (!event.shiftKey) {
      this.props.onSelectTitle(selectedTexts);
    } else {
      this.props.onSelectTitle([...activeTextId, ...selectedTexts]);
    }
  };

  handleBackgroundInstanceClick = (instance, onProgressJump, selected, event) => {
    if (this.props.sidebarExpanded) {
      this.props.sidebarExpandToggle();
    }
    const layer = getLayerById(instance.id);
    const { id, type } = instance;

    if (!event.shiftKey) {
      this.deSelectInstances();
      this.selectInstance({ id, type });
      this.props.onSelect(instance.id);
      deselectActiveObject(layer);
    } else {
      this.toggleInstanceSelect({ id, type });
      this.props.onSelect(null);
    }
  }

  handleProgressJump = (event) => {
    const seekPoint = getSeekPoint(event, this.containerElement, this.props.compositionDuration);
    this.props.onProgressJump(seekPoint);
  };

  contextTrimmer = (backgroundInstances, onVideoResize, showContext) => {
    if (!this.containerElement) {
      return null;
    }
    const bgInstance = backgroundInstances.find(instance => instance.id
      === this.props.activeBackgroundId);
    if (bgInstance) {
      return (
        <VideoContext
          show={showContext}
          instance={bgInstance}
          width={this.containerElement.offsetWidth}
          onResize={onVideoResize}
          onResizeStart={this.handleResizeDirection}
        />
      );
    }
    return null;
  };

  timelineBackgroundInstances = (
    backgroundInstances,
    onVideoResize,
    compositionDuration,
    compositionUid,
    notifyInfo,
    sidebarTabToggle,
    onProgressJump,
    onTextMove,
  ) => {
    if (!this.containerElement) {
      return null;
    }
    if (backgroundInstances.length === 0) {
      return (<EmptyComposition
        compositionUid={compositionUid}
        onDrop={this.addToEmptyComposition}
        onNotify={notifyInfo}
        sidebarTabToggle={sidebarTabToggle}
      />);
    }

    const width = this.containerElement.offsetWidth;
    const msRatio = this.calculateMsRatio(width, compositionDuration);

    return backgroundInstances.map((instance) => {
      const selectedInstance = this.state.selected.find(item => item.id === instance.id);
      const selected = selectedInstance ? !selectedInstance : selectedInstance;

      if (instance.type === 'video') {
        return (
          <div
            className="Timeline__cell"
            key={instance.id}
          >
          <div className="cell_container">
            <TimelineVideoInstance
              list="timeline"
              type={'video'}
              index={instance.id}
              onDragStart={this.handleDragStart}
              onDragEnd={this.handleDragEnd}
              onMove={this.moveInstance}
              onResize={onVideoResize}
              onResizeStart={dir => this.handleResizeDirection(dir, instance)}
              onAdd={this.handleAdd}
              onReplace={this.replaceInstance}
              onDelete={this.handleDelete}
              findInstance={this.findInstance}
              toggleIntercom={this.toggleIntercom}
              onCancel={this.handleCancel}
              msRatio={msRatio}
              onDoubleClick = {() => {
                if (this.props.sidebarExpanded) this.props.sidebarExpandToggle();
                this.props.onShowContext(!this.props.showContext);
              }}
              onClick={event => this.handleBackgroundInstanceClick(
                instance,
                onProgressJump,
                selected,
                event,
              )}
              selected={this.props.activeBackgroundId === instance.id || selected}
              {...instance}
            />
            <TimeTooltip
              time={this.state.resizeTime}
              visible={selected && this.state.resizeTime}
              />
           </div>
          </div>
        );
      }

      return (
        <div
          className="Timeline__cell"
          key={instance.id}
        >
        <div className="cell_container">
          <TimelineImageInstance
            type='image'
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
            onDragCancel={this.handleCancel}
            onResizeStart={dir => this.handleResizeDirection(dir, instance)}
            onResizeStop={onVideoResize}
            onMove={this.moveInstance}
            onTextMove={onTextMove}
            onAdd={this.handleAdd}
            onReplace={this.replaceInstance}
            onDelete={this.handleDelete}
            findInstance={this.findInstance}
            toggleIntercom={this.toggleIntercom}
            msRatio={msRatio}
            onClick={event => this.handleBackgroundInstanceClick(
              instance,
              onProgressJump,
              selected,
              event,
            )}
            selected={this.props.activeBackgroundId === instance.id || selected}
            {...instance}
          />
          <TimeTooltip
            time={this.state.resizeTime}
            visible={selected && this.state.resizeTime}
            />
          </div>
        </div>
      );
    });
  }

  timelineEmptyInstances = (titleInstances) => {
    if (!this.containerElement) {
      return null;
    }
    const { compositionDuration } = this.props;
    const width = this.containerElement.offsetWidth;
    const msRatio = this.calculateMsRatio(width, compositionDuration);
    const sortedInstances = titleInstances
      .sort((a, b) => a.visibleFrom - b.visibleFrom);
    const emptyInstances = [];

    if (sortedInstances[0] && sortedInstances[0].visibleFrom !== 0) {
      const startGap = sortedInstances[0].visibleFrom;
      if (startGap > 999) {
        emptyInstances.push({
          visibleFrom: 0,
          visibleTo: sortedInstances[0].visibleFrom - 1,
          duration: startGap - 1,
          width: startGap * msRatio,
        });
      }
    }

    // See where the existing instances are located and create instances to fill in the gaps
    sortedInstances.forEach((textInstance, index) => {
      if (sortedInstances.length > 1) {
        const startFrom = sortedInstances[index] ? sortedInstances[index].visibleTo + 1 : 0;
        const continueTo = sortedInstances[index + 1] ?
          sortedInstances[index + 1].visibleFrom - 1 : compositionDuration;
        const duration = continueTo - startFrom;
        if (duration > 200) {
          emptyInstances.push({
            duration,
            visibleFrom: startFrom,
            visibleTo: continueTo,
            width: (continueTo - startFrom) * msRatio,
          });
        }
      } else if (sortedInstances.length === 1) {
        const startFrom = sortedInstances[0].visibleTo + 1;
        const continueTo = sortedInstances[0].visibleTo + 4000;
        const duration = continueTo - startFrom;
        emptyInstances.push({
          duration,
          visibleFrom: startFrom,
          visibleTo: continueTo,
          width: (continueTo - startFrom) * msRatio,
        });
      }
    });
    if (sortedInstances.length === 0) {
      const startFrom = 0;
      const continueTo = 4000;
      const duration = continueTo - startFrom;
      emptyInstances.push({
        duration,
        visibleFrom: startFrom,
        visibleTo: continueTo,
        width: (continueTo - startFrom) * msRatio,
      });
    }

    return emptyInstances.map((instance, index) =>
      (<TimelineTextInstance
        key={index}
        msRatio={msRatio}
        canDelete={null}
        selected={false}
        empty={true}
        visibleFrom={instance.visibleFrom}
        originalTranslate={msRatio * instance.visibleFrom}
        compositionDuration={compositionDuration}
        {...instance}
      />));
  }

  timelineTextInstances = (
    textInstances,
    onTextResize,
    onTextResizeStart,
    onTextMove,
    compositionDuration,
  ) => {
    if (!this.containerElement) return null;
    const { activeTextId } = this.props;

    const width = this.containerElement.offsetWidth;
    const msRatio = this.calculateMsRatio(width, compositionDuration);
    const checkIfTextIsSelected = id => activeTextId && activeTextId.includes(id);

    const layerFiltered = [];
    textInstances.forEach((instance) => {
      if (!layerFiltered.find(layer => layer.visibleFrom === instance.visibleFrom)) {
        layerFiltered.push(instance);
      }
    });

    return layerFiltered
      .sort((a, b) => a.visibleFrom - b.visibleFrom)
      .map((instance, index) => {
        return (<TimelineTextInstance
        canDelete={this.state.canDelete}
        onShowTrash={(id) => {
          this.setState({ canDelete: id });
        }}
        onHideTrash={() => {
          this.setState({ canDelete: null });
        }}
        onStartSeek={() => {
          this.setState({ seeking: true });
        }}
        onEndSeek={() => {
          this.setState({ seeking: false });
        }}
        onDelete={() => {
          this.handleTextDelete(instance.id);
        }}
        onResize={(event, direction, element, delta) => {
          onTextResize(event, direction, element, delta, instance.id, msRatio);
        }}
        onMoveStop={(event, delta, id, direction) => {
          onTextMove(delta, instance.id, msRatio, direction);
        }}
        onSwapPreview={(thisId, withThisId, direction) => {
          this.setState({
            swapWithId: withThisId,
            swapDirection: direction,
          });
        }}
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        onCancel={this.handleDragEnd}
        onPerformSwap={() => {
          const swapWith = this.state.swapWithId;
          this.props.onTextSwap(instance.id, swapWith, this.state.swapDirection);
          this.setState({
            swapWithId: null,
          });
        }}
        onProgressSeek={this.props.onProgressSeek}
        textInstances={layerFiltered}
        titleInstance={this.props.titleInstances[index]}
        backgroundInstances={this.props.backgroundInstances}
        swapWithId={this.state.swapWithId}
        swapDirection={this.state.swapDirection}
        visibleFrom={instance.visibleFrom}
        key={instance.id}
        msRatio={msRatio}
        originalTranslate={msRatio * instance.visibleFrom}
        compositionDuration={compositionDuration}
        onClick={event => this.handleTextClick(instance, event, checkIfTextIsSelected(instance.id))}
        selected={checkIfTextIsSelected(instance.id)}
        toggleIntercom={this.toggleIntercom}
        {...instance}
      />);
      });
  }
  renderScrubber = (
    onProgressJump,
    onProgressSeek,
    timeline,
    compositionDuration = 0,
    playing,
    time,
    seeking,
  ) => {
    if (!this.containerElement) {
      return null;
    }

    const width = this.containerElement.offsetWidth;
    const msPxRatio = this.calculateMsRatio(width, compositionDuration);
    return (
      <Scrubber
        onProgressJump={onProgressJump}
        currentTime={timeline.currentTime}
        onProgressSeek={onProgressSeek}
        internalTime={time}
        duration={compositionDuration}
        msPxRatio={msPxRatio}
        isPlaying={playing}
        tooltipVisible={seeking}
      />
    );
  }

  showResizingTooltip = (e) => {
    const { instance } = this.showResizingTooltip;
    // const { resizeDirection } = this.showResizingTooltip;
    // if (resizeDirection === 'left') this.handleResizeStop();
    // const { compositionDuration: duration } = this.props;
    const offset = e.clientX - this.containerElement.offsetLeft;
    const width = this.containerElement.offsetWidth;
    const msPxRatio = this.calculateMsRatio(width, this.props.compositionDuration);
    let time = Math.round(offset / msPxRatio);

    if (instance && time < instance.visibleFrom + 1000) {
      time = instance.visibleFrom + 1000;
    }

    if (instance && instance.type !== 'image'
    && time >= instance.visibleFrom + instance.duration) {
      time = instance.visibleFrom + instance.duration;
    }
    this.setState({
      resizeTime: time,
    });
  }

  handleResizeStop = () => {
    this.setState({
      resizeTime: null,
    });
    document.removeEventListener('mousemove', this.showResizingTooltip);
    document.removeEventListener('mouseup', this.handleResizeStop);
  }

  handleResizeDirection = (resizeDirection, instance) => {
    this.stopPlaying();
    this.setState({
      resizeDirection,
    });
    this.selectInstance(instance);
    document.addEventListener('mousemove', this.showResizingTooltip);
    this.showResizingTooltip.instance = instance;
    this.showResizingTooltip.resizeDirection = resizeDirection;
    document.addEventListener('mouseup', this.handleResizeStop);
  }

  backgroundInstancesDuration = (backgroundInstances) => {
    const bgInstanceDuration = backgroundInstances
      .reduce((acc, curr) => (acc + curr.duration), 0);

    return bgInstanceDuration;
  }

  isVideoLonger = (backgroundInstances, compositionDuration) => {
    const bgInstanceDuration = this.backgroundInstancesDuration(backgroundInstances);

    return bgInstanceDuration >= compositionDuration;
  }

  calculateVideoMaxWidth = (backgroundInstances, compositionDuration) => {
    if (!this.containerElement) {
      const maxWidth = 'none';
      return maxWidth;
    }
    const width = this.containerElement.offsetWidth;
    const msRatio = this.calculateMsRatio(width, compositionDuration);
    const bgInstanceDuration = this.backgroundInstancesDuration(backgroundInstances);
    const maxWidth = !this.isVideoLonger(backgroundInstances, compositionDuration) ? (bgInstanceDuration * msRatio) : 'none';
    return maxWidth;
  }

  handleDuplicateInstances = () => {
    const {
      activeTextId, onAddTextLayer, onSelectTitle, textInstances,
    } = this.props;
    let timeslot;
    if (activeTextId) {
      onSelectTitle([]);
      activeTextId.forEach((selectedId, index) => {
        const txt = textInstances.find(t => t.id === selectedId);
        const prevTxt = textInstances.find(t => t.id === activeTextId[index - 1]);
        if (!prevTxt || prevTxt.visibleFrom !== txt.visibleFrom) {
          timeslot = getNewInstanceTimeslot(this.props.titleInstances, txt.duration);
        }
        onAddTextLayer({
          ...txt,
          id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
          duration: timeslot.visibleTo - timeslot.visibleFrom,
          visibleFrom: timeslot.visibleFrom,
          visibleTo: timeslot.visibleTo,
        });
      });
    }

    const selectedInstances = [];
    this.state.selected.forEach((inst) => {
      if (inst.type === 'video' || inst.type === 'image') {
        selectedInstances.push(inst.id);
      }
    });

    if (selectedInstances.length !== 0) {
      this.props.onDuplicateInstances(selectedInstances);
    }
  }

  render() {
    const {
      backgroundInstances,
      textInstances,
      titleInstances,
      shapeInstances,
      onVideoResize,
      onTextResize,
      onTextResizeStart,
      onTextMove,
      compositionDuration,
      compositionUid,
      onProgressJump,
      onProgressSeek,
      timeline,
      notifyInfo,
      sidebarTabToggle,
      onSelect,
      showContext,
      videoContextVisible,
      textPresetDragging,
      selectedShapes,
    } = this.props;

    const {
      resizeDirection,
      canDelete,
      seeking,
    } = this.state;

    const videoRowClasses = classNames(
      'Timeline__row',
      {
        'Timeline__row--resize-left': resizeDirection === 'left',
        'Timeline__row--resize-right': resizeDirection === 'right',
      },
    );

    const shortcuts = [
      {
        keys: 'd',
        fn: this.handleDuplicateInstances,
      },
      {
        keys: 'esc',
        fn: this.deSelectInstances,
      },
      {
        keys: 'backspace',
        fn: this.handleBackspaceDeletion,
      },
    ];

    const maxWidth = this.calculateVideoMaxWidth(backgroundInstances, compositionDuration);

    const toggleIcon = timeline.playing ? <Pause/> : <Play/>;

    /* eslint-disable react/prop-types */
    const PlayPauseButton = ({ buttons }) => {
      if (!buttons.play) return null;
      const togglePlay = () => {
        if (this.props.sidebarExpanded) {
          this.props.sidebarExpandToggle();
        }
        this.props.timelineTogglePlay();
      };

      return (
        <Button lightBg={true} className="TimeLine__icons--play" onClick={togglePlay} disabled={timeline.buffering} >
          {timeline.buffering ? <Spinner visible={true} /> : toggleIcon}
        </Button>
      );
    };

    const { layers, showVisibilityHandler } = this.props;
    const activeLayer = layers && layers.find(layer => layer.id === this.props.activeLayerId);
    const activeShape = shapeInstances && shapeInstances.find(s => s.id === (selectedShapes.length > 0 && selectedShapes[0]));
    const backgroundInstanceTimes = backgroundInstances.map(instance => instance.visibleFrom);
    return (
      <div
        className="Timeline"
        onClick = {e => this.handleTimelineClick(e)}
        style={{ paddingTop: (showContext && videoContextVisible) || showVisibilityHandler ? '50px' : '0px', transition: '0.15s padding ease' }}>
          <div className="TimeLine__icons">
            <ToolTip message="Play / Pause (Spacebar)" delay={1500} place="right">
              <PlayPauseButton buttons={this.props.buttonConfig}/>
            </ToolTip>
          </div>
        <KeyboardShortcuts shortcuts={shortcuts}/>
        <Trash visible={canDelete !== null}/>
        <div className="TimeLine__wrapper">
          <div
          onClick={(e) => {
            if (e.target.classList.contains('overlay-draggable')) return;
            this.handleProgressJump(e);
          }}
          className="TimeLine__wrapper-seekArea">
            <div className={'videoRow-overlay'}>
              {this.props.activeBackgroundId &&
                this.contextTrimmer(backgroundInstances, onVideoResize, showContext)}
                {showVisibilityHandler && this.containerElement && (
                  <Overlay
                    activeLayer={activeLayer}
                    activeShape={activeShape}
                    width={this.containerElement.offsetWidth}
                    onTimeChange={this.props.onTimeChange}
                    onUpdateShape={this.props.onUpdateShape}
                    backgroundInstanceTimes={backgroundInstanceTimes}
                    compositionDuration={compositionDuration}
                    show={showVisibilityHandler}
                  />
                )}
            </div>
          <Ticker
            currentTime={timeline.currentTime}
            isPlaying={timeline.playing}
            compositionDuration={compositionDuration}
            // onProgressJump={this.handle}
            render={tickerTime => (
            <React.Fragment>
              <div className="Timeline__row">
                <div
                  className="Timeline__cell--fullwidth"
                  ref={this.setContainerWidth}
                >
                  <Ruler
                    duration={compositionDuration}
                    currentTime={timeline.currentTime}
                    internalTime={tickerTime}
                    isPlaying={timeline.playing}
                  />
                </div>
              </div>
              <div className="Timeline__row">
                <div className="Timeline__cell--fullwidth">
                  {this.renderScrubber(
                    onProgressJump,
                    onProgressSeek,
                    timeline,
                    compositionDuration,
                    timeline.playing,
                    tickerTime,
                    seeking,
                  )}
                </div>
              </div>
            </React.Fragment>
          )}>
        </Ticker>
          <div className="Timeline__row Timeline__row--textrow">
            { textPresetDragging && this.timelineEmptyInstances(titleInstances)}
            {this.timelineTextInstances(
              textInstances,
              onTextResize,
              onTextResizeStart,
              onTextMove,
              compositionDuration,
              onProgressJump,
            )}
          </div>

          <div className={videoRowClasses} style={{ maxWidth }}>
            {this.timelineBackgroundInstances(
              backgroundInstances,
              onVideoResize,
              compositionDuration,
              compositionUid,
              notifyInfo,
              sidebarTabToggle,
              onProgressJump,
              onSelect,
              onTextMove,
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

Timeline.propTypes = {
  onResize: PropTypes.func,
  onTextMove: PropTypes.func,
  onMove: PropTypes.func,
  onTextSwap: PropTypes.func,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onReplace: PropTypes.func,
  compositionLayerRemove: PropTypes.func,
  textInstances: PropTypes.array,
  titleInstances: PropTypes.array,
  shapeInstances: PropTypes.array,
  onTextLayerSelect: PropTypes.func,
  generateUniqueId: PropTypes.func,
  compositionDuration: PropTypes.number,
  backgroundInstances: PropTypes.array,
  showContext: PropTypes.bool,
  textPresetDragging: PropTypes.bool,
  buttonConfig: PropTypes.object,
  videoContextVisible: PropTypes.bool,
  onAddTextLayer: PropTypes.func,
  onSelectTitle: PropTypes.func,
  onRemoveShape: PropTypes.func,
  selectedShapes: PropTypes.array,
  activeTextId: PropTypes.array,
  activeBackgroundId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  activeLayerId: PropTypes.array,
  onUpdateShape: PropTypes.func,
  sidebarExpandToggle: PropTypes.func,
  onShowContext: PropTypes.func,
  onDuplicateInstances: PropTypes.func,
  onDeletedVideo: PropTypes.func,
  sidebarExpanded: PropTypes.bool,
};

Timeline.defaultProps = {
  buttonConfig: {
    play: true,
  },
};

export default Timeline;
