import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToolTip from '@components/ToolTip';
import './ButtonGroup.scss';

const ButtonGroup = ({
  items,
  selected,
  onSelect,
  images,
  width,
}) => {
  const renderItems = items.map((item) => {
    const { value, info } = item;
    let { label } = item;
    if (label === 'My Videos' && images) label = 'My Images';
    const classes = classNames(
      'ButtonGroup__item',
      {
        'ButtonGroup__item--selected': value === selected,
      },
      {
        'ButtonGroup__item--disabled': item.disabled,
      },
    );

    const handleSelect = () => onSelect(value);
    return (
      <div
        key={value}
        className={classes}
        onClick={handleSelect}
      >
        <ToolTip message = {info}>
          {label}
        </ToolTip>
      </div>
    );
  });

  return (
    <div className="ButtonGroup" style={{ width }}>
      {renderItems}
    </div>
  );
};

ButtonGroup.defaultProps = {
  items: [],
};

ButtonGroup.propTypes = {
  width: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      images: PropTypes.bool,
    }),
  ),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onSelect: PropTypes.func.isRequired,
  images: PropTypes.bool,
};

export default ButtonGroup;
