import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import flow from 'lodash.flow';
import { DragSource, DropTarget } from 'react-dnd';
import Resizable from 're-resizable';
import * as dndTypes from '@constants/dndTypes';

import {
  RESIZE_CONSTRAINTS,
  VIDEO_ROW_HEIGHT,
  HANDLE_STYLES,
} from '@constants/timeline';
import InstanceBackground from './InstanceBackground';

import './TimelineImageInstance.scss';

const propTypes = {
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragCancel: PropTypes.func.isRequired,
  toggleIntercom: PropTypes.func.isRequired,

  onMove: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  onClick: PropTypes.func,

  onResizeStart: PropTypes.func.isRequired,
  onResizeStop: PropTypes.func.isRequired,
  thumbnail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  msRatio: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  /**
   * Added by react dnd when making component draggable
   */
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  getItem: PropTypes.object,
  selected: PropTypes.bool,
};

const defaultProps = {
  selected: false,
  onClick: () => {},
};

const timelineImageInstanceSource = {
  beginDrag(props) {
    // Used to show trashcan or similar
    props.onDragStart();
    return {
      ...props,
      originalIndex: props.findInstance(props.id, props.list).index,
    };
  },

  endDrag(props, monitor) {
    const didDrop = monitor.didDrop();

    if (!didDrop) {
      props.onDragCancel(props);
    }

    // Used to hide trashcan or similar
    props.onDragEnd();
  },

  isDragging(props, monitor) {
    return props.id === monitor.getItem().id;
  },
};


const timelineImageInstanceTarget = {
  hover(targetProps, monitor, component) {
    const targetId = targetProps.id;
    const sourceProps = monitor.getItem();
    const sourceId = sourceProps.id;
    const offset = monitor.getClientOffset();
    if (sourceId !== targetId) {
      targetProps.onMove({
        sourceId,
        targetId,
        component,
        offset,
        ...sourceProps,
      });
    }
  },

  drop(targetProps, monitor) {
    const sourceProps = monitor.getItem();
    const sourceId = sourceProps.id;
    const targetId = targetProps.id;
    const sourceList = sourceProps.list;
    if (sourceId !== targetId && sourceList === 'gallery') {
      targetProps.onReplace({
        sourceId,
        targetId,
      });
      return {
        dropComplete: 'user replaced image background instance',
      };
    }
    targetProps.onAdd({
      sourceId,
      targetId,
    });
    return {
      dropComplete: 'user added image background instance',
    };
  },
};

// Define props that are passed to the component via props
const sourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});


const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
});

class TimelineImageInstance extends React.Component {
  state = {
  }

  constructor(props) {
    super(props);
    this.state = {
      msRatio: props.msRatio,
    };
  }

  // TODO:  check if the follwoing code is necessary
  componentDidUpdate(prevProps) {
    if (prevProps.msRatio !== this.props.msRatio) {
      this.setState({
        msRatio: this.props.msRatio,
      });
    }
  }
  handleResizeStop = (params) => {
    const {
      id, onResizeStop, msRatio,
    } = this.props;
    onResizeStop(...params, id, msRatio);
  }

  render() {
    const {
      connectDragSource,
      connectDropTarget,
      thumbnail,
      duration,
      toggleIntercom,
      onResizeStart,
      isOver,
      isDragging,
      getItem,
      selected,
      onClick,
      // msRatio,
    } = this.props;

    const classes = classNames(
      'TimelineImageInstance',
      {
        'TimelineImageInstance--replace': isOver && getItem.list === 'gallery',
        'TimelineImageInstance--placeholder': isDragging,
        'TimelineImageInstance--selected': selected,
      },
    );

    /**
     * this.node is saved so that moveInstance can
     * calculate dom instance coordinates
     */
    const { msRatio } = this.state;
    const minWidth = 1000 * msRatio;
    const dropEffect = 'copy';

    return connectDropTarget(
      <div>
        <Resizable
          onClick={onClick}
          className={classes}
          size={{
            height: VIDEO_ROW_HEIGHT,
            width: duration * msRatio,
          }}
          minWidth= {minWidth}
          enable={RESIZE_CONSTRAINTS}
          onResizeStop={(...params) => {
            toggleIntercom('boot');
            this.handleResizeStop(params);
          }}
          onResizeStart={(e, dir) => {
            toggleIntercom('shutdown');
            onResizeStart(dir);
          }}
          handleStyles={HANDLE_STYLES}
        >
          {connectDragSource(
            <div
              className="TimelineImageInstance__wrapper"
              ref={(node) => { this.node = node; }}
            >
              <InstanceBackground imageSrc={thumbnail.hd}/>
            </div>, { dropEffect },
          )}
        </Resizable>
      </div>,
    );
  }
}

TimelineImageInstance.propTypes = propTypes;
TimelineImageInstance.defaultProps = defaultProps;

export default flow(
  DropTarget(dndTypes.TIMELINE_IMAGE, timelineImageInstanceTarget, targetCollect),
  DragSource('image', timelineImageInstanceSource, sourceCollect),
)(TimelineImageInstance);
