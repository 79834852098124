import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './style.scss';

const ToolTip = (props) => {
  const {
    children, message, place, disable,
  } = props;
  if (!message) return children;
  return (
    <span data-tip={message} data-for={`global_${message}`} data-delay-show={500}>
      {children}
      <ReactTooltip disable ={disable} effect='solid' type='light' place={place || 'top'} id={`global_${message}`} className='customTheme'/>
    </span>
  );
};

ToolTip.propTypes = {
  message: PropTypes.string,
  disable: PropTypes.bool,
  place: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
export default ToolTip;
