import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Creatable from 'react-select/creatable';
import debounce from 'lodash.debounce';
import { List } from '@components/Icons';
import classNames from 'classnames';
import ToolTip from '../ToolTip';

import './Search.scss';

const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  onClear: PropTypes.func,
  suggestions: PropTypes.array,
  showCategories: PropTypes.bool,
  onCategoriesToggle: PropTypes.func,
  filter: PropTypes.string,
  type: PropTypes.string,
  onChangeSorting: PropTypes.func,
  sorting: PropTypes.string,
  generator: PropTypes.bool,
};
const defaultProps = {};

// TODO: pass these in props for support in other formats
const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = (suggestion, { query }) => {
  const boldText = suggestion.replace(query, '');
  return (
    <div className="suggestionText">
      <span>{query}</span><span className="bold">{boldText}</span>
    </div>
  );
};

function Search(props) {
  const [value, setValue] = useState('');

  if (props.filter && props.filter !== 'stock') return null;
  const onSuggestionsFetchRequested = ({ value: suggestionValue }) => {
    props.onSearch(suggestionValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value === '' && props.onReset) {
      props.onReset();
    } else if (props.onSelect) {
      props.onSelect(value);
    }
    setValue('');
  };

  const handleSelect = (event, { suggestion }) => {
    if (props.onSelect) {
      props.onSelect(suggestion);
    }
    setValue('');
  };

  const debouncedRequest = debounce(onSuggestionsFetchRequested, 1000);

  const {
    // className,
    placeholder,
    suggestions,
    onSelect,
    onCategoriesToggle,
    filter,
    type,
    onChangeSorting,
    generator,
  } = props;

  const newSuggestions = [value, ...suggestions];

  const onChange = (event, { newValue }) => {
    setValue(newValue);

    if (newValue === '') {
      if (props.onReset) {
        props.onReset();
      }
    }
  };

  const classes = 'Search';

  const inputProps = {
    placeholder,
    value,
    onChange,
  };

  const categoryButtonClasses = classNames(
    'BrowseCategoriesButton', {
      'BrowseCategoriesButton--selected': props.showCategories,
    },
  );

  const handleOptionSelect = (event) => {
    onChangeSorting(event.value);
    setTimeout(() => {
      onSelect(inputProps.value);
    });
  };

  const sortingOptionStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.025)',
      border: '1px solid rgba(255, 255, 255, 0.025)',
    }),
    singleValue: provided => ({
      ...provided,
      color: '#fff',
    }),
    input: provided => ({
      ...provided,
      color: '#fff',
    }),
    option: provided => ({
      ...provided,
    }),
  };

  const sortingOptions = [
    { label: 'Relevance', value: 'relevance' },
    { label: 'Popular', value: 'popular' },
    { label: 'Random', value: 'random' },
  ];

  return (
    <div className={classes}>
      <form onSubmit={handleSubmit}>
        {filter === 'stock' && <Autosuggest
          suggestions={newSuggestions}
          onSuggestionsFetchRequested={debouncedRequest}
          onSuggestionSelected={handleSelect}
          onSuggestionsClearRequested={() => {
            // onClear();
          }}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />}
      </form>
      {filter === 'stock' && type !== 'image' && inputProps.value.length > 0 && !generator && <div
        className='SortingOptions'
      >
        <Creatable
          createOptionPosition='first'
          className="sortingOptions-select"
          styles={sortingOptionStyles}
          blurInputOnSelect
          isSearchable={false}
          options={sortingOptions}
          onChange={handleOptionSelect}
          defaultValue={sortingOptions[0]}
        />
      </div>}
      {filter === 'stock' && type !== 'image' && !generator && <div
        className={categoryButtonClasses}
        onClick={onCategoriesToggle}
      >
        <ToolTip message="Browse categories">
          <List />
        </ToolTip>
      </div>}
    </div>
  );
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default Search;
