import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import AspectModal from '@components/AspectModal';
import { determineCanvasType } from '@helpers/backgroundInstance';
import VideoActions from './VideoActions';
import './Video.scss';

const defaultProps = {
  onClick: () => { },
};

const propTypes = {
  active: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onReversion: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  projectuid: PropTypes.string,
};

const thumbnailUrl = data => data.content &&
  data.content.backgroundInstances &&
  data.content.backgroundInstances[0] &&
  data.content.backgroundInstances[0].thumbnail.hd;


const thumbnail = (url) => {
  if (!url) return null;

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img className="Video__thumbnail-image" src={url} />
  );
};


export default class Video extends React.Component {
  state = {
    name: this.props.data.name,
    editing: false,
    showReversionOptions: false,
  }

  debouncedRename = debounce(this.props.onRename, 500);

  setEditing = (isEditing) => {
    this.setState({
      editing: isEditing,
    });
  }

  handleVideoRename = (event) => {
    const name = event.target.value;

    const { uid } = this.props.data;

    this.setState({ name });
    return this.debouncedRename(name, uid, this.props.projectuid);
  }

  openReversionModal = () => {
    this.setState({
      showReversionOptions: true,
      open: false,
    });
  };

  handleReversioning = (name, dimensions) => {
    // get props from current video
    const {
      uid: compositionUid, project, width, height,
    } = this.props.data;
    // prepare reversion parameters
    const reversionParams = {
      compositionUid, projectUid: project, name, dimensions,
    };

    const prevAspectRatio = { width, height };
    // reversion
    this.props.onReversion(reversionParams, prevAspectRatio);
    this.setState({
      showReversionOptions: false,
      open: false,
    });
  };


  render() {
    const {
      data,
      onClick,
      onRemove,
      onMove,
      active,
    } = this.props;
    const {
      duration,
      uid,
      width,
      height,
    } = data;
    const {
      name, editing, showReversionOptions,
    } = this.state;

    const aspectRatio = determineCanvasType(width, height);

    const classes = classNames(
      'Video',
      {
        'Video--active': active,
      },
    );

    const thumbnailClasses = `Video__thumbnail Video__thumbnail--${aspectRatio}`;
    return (
      <div className={classes}>
        <div className="Video__details" onClick={onClick}>
          <div className="Video__thumbnail-container">
            <div className={thumbnailClasses}>
              {thumbnail(thumbnailUrl(data))}
            </div>
          </div>
          <div className="Video__content">
            <div className="Input-container">
              {!editing ? <p className="Video__name-input" onDoubleClick={() => this.setEditing(true)}>{name}</p> :
                <input className="Video__name-input" value={name}
                  onChange={this.handleVideoRename}
                  autoFocus
                  onBlur={() => this.setEditing(false)}
                />}
            </div>
            <span className="Video__duration">{duration}</span>
          </div>
        </div>
        <VideoActions
          active={this.props.active}
          onClickReversion={this.openReversionModal}
          onClickDelete={onRemove}
          onClickMove={() => { onMove(uid); }}
          onClickRename={() => { this.setEditing(true); }}
        />
        {/* reversion option modal */}
        <AspectModal
          visible={showReversionOptions}
          reversion={true}
          title='Duplicate Options'
          name={name}
          onAdd={this.handleReversioning}
          onClose={() => {
            this.setState({
              showReversionOptions: false,
            });
          }}
        />
      </div>
    );
  }
}

Video.defaultProps = defaultProps;
Video.propTypes = propTypes;
