import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sidebarTabToggle, sidebarProjectSelect } from '@actions/sidebar';
import { dashboardUpdateView, toggleDashboard } from '@actions/dashboard';
import {
  fetchProjects,
  renameProject,
  addComposition,
  reversionComposition,
  removeComposition,
  removeProject,
  addProject,
  moveComposition,
  toggleCompositionMoveModal,
} from '@actions/projects';

import {
  fetchAndLoadComposition,
  renameComposition,
  compositionVideoInstanceUnselect,
  compositionLayerEditExit,
  compositionShapelayerUnselect,
} from '@actions/composition';

import ProjectsTab from '@components/ProjectsTab';

const propTypes = {
  onFetchProjects: PropTypes.func,
  onSelectComposition: PropTypes.func,
  onAddComposition: PropTypes.func,
  onAddProject: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    footage,
    projects,
    sidebar,
    dashboard,
  } = state;
  const composition = state.composition.present;
  const { activeProjectUid } = sidebar;

  return {
    footage,
    projects,
    activeProjectUid,
    activeCompositionUid: composition.uid,
    dashboardView: dashboard.dashboardView, // home page or not
    activeTab: dashboard.activeTab, // tells what tab is active in dashboard
    isLoading: composition.loading,
    isMovingComposition: projects.isMovingComposition,
    showMoveModal: projects.showMoveModal,
    composition,
  };
};

class ProjectsTabContainer extends React.Component {
  componentDidMount() {
    const { onFetchProjects } = this.props;
    onFetchProjects();
  }

  render() {
    return (
      <ProjectsTab {...this.props} />
    );
  }
}

ProjectsTabContainer.propTypes = propTypes;

export default connect(mapStateToProps, {
  onFetchProjects: fetchProjects,
  onSelectComposition: fetchAndLoadComposition,
  onCloseVideo: compositionVideoInstanceUnselect,
  onCloseLayer: compositionLayerEditExit,
  onCloseShapelayer: compositionShapelayerUnselect,
  onToggleSidebarGallery: toggleDashboard,
  onAddComposition: addComposition,
  onAddProject: addProject,
  onReversionComposition: reversionComposition,
  onRemoveComposition: removeComposition,
  onRemoveProject: removeProject,
  onRenameComposition: renameComposition,
  onSelectProject: sidebarProjectSelect,
  onRenameProject: renameProject,
  onMoveComposition: moveComposition,
  onToggleMoveModal: toggleCompositionMoveModal,
  sidebarTabToggle,
  dashboardUpdateView,
})(ProjectsTabContainer);
