import axios from 'axios';
import * as types from '@constants/actionTypes';
import { MUSIC_URL } from '@constants/api';

function requestMusic(filter) {
  return {
    type: types.MUSIC_REQUEST,
    payload: {
      filter,
    },
  };
}

function receiveMusic(entries, filter) {
  return {
    type: types.MUSIC_RECEIVE,
    payload: {
      entries,
      filter,
      receivedAt: Date.now(),
    },
  };
}

function invalidateMusic(filter) {
  return {
    type: types.MUSIC_INVALIDATE,
    payload: {
      filter,
    },
  };
}

function toggleCurrentTrack(e, track) {
  return {
    type: types.MUSIC_TOGGLE_CURRENT_TRACK,
    autosave: true,
    payload: {
      track,
    },
  };
}

function shouldFetchMusic(state) {
  const data = state.music;

  if (data.entries.length === 0) {
    return true;
  } else if (data.isFetching) {
    return false;
  }

  return data.didInvalidate;
}

function fetchMusic(filter) {
  return async (dispatch, getState) => {
    if (shouldFetchMusic(getState(), filter)) {
      dispatch(requestMusic());

      const response = await axios.get(MUSIC_URL)
        .catch(err => console.log('err', err)); // eslint-disable-line no-console

      dispatch(receiveMusic(response.data.data));
    }
  };
}

export {
  requestMusic,
  fetchMusic,
  invalidateMusic,
  toggleCurrentTrack,
};
