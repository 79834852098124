import { map, filter } from 'rxjs/operators';
import { actions as notifActions } from 'redux-notifications';

const { notifSend } = notifActions;

const notify = action$ => action$.pipe(
  filter(action => action.notify),
  map(action => notifSend({
    kind: action.notify.kind,
    message: action.notify.message,
    dismissAfter: action.notify.dissmissAfter || 5000,
  })),
);

export {
  notify,
};
