import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadsFilterButtons } from '@constants/sidebar';
import { sidebarItemFilterToggle, sidebarUploadsFilterToggle, expandPool } from '@actions/sidebar';
import { fetchVideos, fetchImages, getImages, handleAddToFavorite, handleRemoveFavorite } from '@actions/footage';
import { newUpload, removeImage, removeVideo } from '@actions/upload';
import {
  compositionVideoInstanceAdd,
  compositionVideoGenerateUniqueId,
  compositionPlaceholderRemove,
  compositionVideoInstanceSelect,
  compositionShowVideoContext,
  formatBackgroundInstances,
} from '@actions/composition';
import { stateKeys } from '@constants/footage';
import { timelineProgressJump } from '@actions/timeline';
import { setBrandLogo } from '@actions/brandkit';
import ImagesTab from '@components/ImagesTab';
import VideosTab from '@components/VideosTab';


const mapStateToProps = (state) => {
  const {
    footage,
    sidebar,
    upload,
    brandkit,
  } = state;
  const composition = state.composition.present;
  const { uploadsFilter: filter } = sidebar;
  const { brandlogo } = brandkit;

  let selectedFilterData;
  if (filter === 'video') {
    selectedFilterData = footage[stateKeys.videos.upload];
  } else {
    selectedFilterData = footage[stateKeys.images.upload];
  }
  return {
    logoImages: selectedFilterData.entries,
    activeUploads: upload.processing,
    defaultLogo: brandkit.brandlogo,
    defaultFont: brandkit.brandfont,
    pallets: brandkit.brandcolor,
    footagePages: selectedFilterData.entries,
    loading: footage.stockImages.isFetching,
    currentPage: selectedFilterData.currentPage,
    searchTerm: selectedFilterData.searchTerm,
    filter,
    composition,
    brandlogo,
    filterButtons: uploadsFilterButtons,
    expanded: sidebar.expanded,
  };
};

class UploadsTabContainer extends React.Component {
  componentDidMount() {
    const {
      fetchVideos: onLoadVideos,
      fetchImages: onLoadImages,
      filter,
      footagePages,
    } = this.props;
    if (footagePages.length === 0) {
      if (filter === 'video') {
        onLoadVideos('upload');
      } else {
        onLoadImages('upload');
      }
    }
  }
  componentDidUpdate(prevProps) {
    const {
      fetchImages: onLoadImages,
      fetchVideos: onLoadVideos,
      filter,
    } = this.props;
    if (prevProps.filter !== filter) {
      if (filter === 'video') {
        onLoadVideos('upload');
      } else {
        onLoadImages('upload');
      }
    }
  }

  render() {
    const {
      fetchImages: onLoadImages,
      fetchVideos: onLoadVideos,
      filter,
      footagePages,
    } = this.props;

    switch (filter) {
      case 'video':
        return <VideosTab {...this.props} onLoad={() => onLoadVideos('upload')} />;
      case 'image' || 'logo':
        return <ImagesTab
          {...this.props}
          footagePages={footagePages}
          onLoad={() => onLoadImages('upload')}
        />;
      default:
        return (
          <Fragment>
            <ImagesTab {...this.props} onLoad={() => onLoadImages('upload')} />
            <VideosTab {...this.props} onLoad={() => onLoadVideos('upload')} />;
          </Fragment>
        );
    }
  }
}

UploadsTabContainer.propTypes = {
  fetchImages: PropTypes.func,
  fetchVideos: PropTypes.func,
  filter: PropTypes.string,
  brandlogo: PropTypes.object,
  footagePages: PropTypes.array,
  logoImages: PropTypes.array,
};

export default connect(mapStateToProps, {
  onFilterToggle: sidebarItemFilterToggle,
  onUploadsFilterToggle: sidebarUploadsFilterToggle,
  fetchImages,
  fetchVideos,
  expandPool,
  onDownload: getImages,
  onUpload: newUpload,
  onAdd: compositionVideoInstanceAdd,
  handleAdd: compositionVideoGenerateUniqueId,
  onCancel: compositionPlaceholderRemove,
  onRemoveLogo: setBrandLogo,
  onAddToFavorite: handleAddToFavorite,
  onSelect: compositionVideoInstanceSelect,
  onProgressJump: timelineProgressJump,
  onRemoveFromFavorite: handleRemoveFavorite,
  onShowContext: compositionShowVideoContext,
  onRemoveUploadVideo: removeVideo,
  onRemoveUploadImage: removeImage,
  formatBackgroundInstances,
})(UploadsTabContainer);
