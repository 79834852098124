import React from 'react';
import PropTypes from 'prop-types';

import { MS_TO_SECONDS_ROUNDING_PRECISION as msRound } from '@constants/timeline';
import './TimeTooltip.scss';

const propTypes = {
  time: PropTypes.number,
  visible: PropTypes.bool,
  duration: PropTypes.number,
};

const defaultProps = {
  visible: false,
};

function TimeTooltip(props) {
  if (!props.visible) return null;

  const seconds = props.time / 1000;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Number.parseFloat(seconds % 60).toFixed(msRound);

  const paddedMinutes = `0${minutes}`.slice(-2);
  const paddedSeconds = `0${remainingSeconds}`.slice(-4);
  const offset = ((props.time / props.duration) * 60);
  const adjustPosition = -55 + (offset || 0);
  return (
    <div className="TimeTooltip" style = {{ marginLeft: paddedMinutes === '00' && paddedSeconds === '00.0' && '.5rem', right: adjustPosition }}>
      {paddedMinutes !== '00' && `${paddedMinutes}:`}{paddedSeconds}s
    </div>
  );
}

TimeTooltip.propTypes = propTypes;
TimeTooltip.defaultProps = defaultProps;
export default TimeTooltip;
