import * as types from '@constants/actionTypes';

const initialState = {
  designs: [],
  designFilter: 'all',
};

export default function designReducer(state = initialState, action) {
  switch (action.type) {
    case types.COMPOSITION_PUBLISH_DESIGN: {
      const newDesigns = [...state.designs, action.payload.design];
      return {
        ...state,
        designs: newDesigns,
      };
    }
    case types.COMPOSITION_UNPUBLISH_DESIGN: {
      const { designUid } = action.payload;
      const newDesigns = state.designs.filter(design => design.uid !== designUid);
      return {
        ...state,
        designs: newDesigns,
      };
    }
    case types.COMPOSITION_LOAD_DESIGNS: {
      return state;
    }
    case types.COMPOSITION_RECEIVE_DESIGNS: {
      return {
        ...state,
        designs: action.payload.designsArray,
      };
    }
    case types.DASHBOARD_SET_CURRENT_VIEW:
    case types.DESIGN_FILTER_DESIGNS: {
      const { aspectRatio } = action.payload;
      return {
        ...state,
        designFilter: aspectRatio,
      };
    }
    default:
      return state;
  }
}
