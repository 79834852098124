import Layer from './Layer';

class SimpleLayer extends Layer {
  /**
   * @param {any} object Fabric.js object
   * @param {{ visibleFrom: number, visibleTo: number }} options
   */
  constructor(object, options, eventHandlers = {}) {
    super(options);
    const { id } = options;

    this.object = object;
    this.id = id;

    this.bindEventHandlers(eventHandlers);
  }

  getObjects() {
    return [this.object];
  }

  getAnimatableObject() {
    return this.object;
  }

  bindEventHandlers(handlers) {
    const { modified, selected, deselected } = handlers;

    if (modified && modified instanceof Function) {
      this.object.on('modified', modified);
    }

    if (selected && selected instanceof Function) {
      this.object.on('selected', selected);
    }

    if (deselected && deselected instanceof Function) {
      this.object.on('deselected', deselected);
    }
  }
}

export default SimpleLayer;
