/* eslint-disable no-unused-vars */
import Instance from '@components/NewTimeline/Instance';
import { DEFAULT_OVERLAY_LAYER_SCALE } from '@constants/layer';
import { getUniqueId } from '@helpers/footage';
import { swap } from '@helpers/timeline';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { DropTarget } from 'react-dnd';
import './NewTimeline.scss';

const trackTarget = {
  canDrop() {
    return true;
  },
  hover(props, monitor) {
    if (props.trashVisible) {
      props.setTrashVisible(false);
    }
    const {
      poolIndex,
      msToPixel,
      trackRef,
      onIndex,
      changeTrackRef,
      changeIndex,
      onOverlayMove,
      onBackgroundMove,
      onUpdateShape,
      onUpdateLayer,
      tracks,
      poolType,
    } = props;

    const offSet = monitor.getDifferenceFromInitialOffset();
    const item = monitor.getItem();
    const dropTrackIndex = props.trackIndex;
    const {
      visibleFrom, visibleTo, id, type,
    } = item;
    let instances = document
      .getElementsByClassName('pool')[1]
      .getElementsByClassName('track')[0]
      .getElementsByClassName('instance');
    instances = Array.prototype.slice.call(instances);
    instances.forEach((instance) => {
      instance.style.opacity = 1;
    });
    let trackElements = document.getElementsByClassName('track');
    trackElements = Array.prototype.slice.call(trackElements);
    trackElements.forEach((element) => {
      element.style.background = 'var(--color4)';
    });
    const trackToHighlight = document
      .getElementsByClassName('pool')[poolIndex].getElementsByClassName('track')[dropTrackIndex];
    trackToHighlight.style.background = '#c9c9c9';
    if (monitor.getItemType() === 'instance') {
      let currentIndex = onIndex.current;
      const draggedCoords = {
        visibleFrom: Math.floor((visibleFrom * msToPixel) + offSet.x),
        visibleTo: Math.floor((visibleTo * msToPixel) + offSet.x),
      };

      const canMoveHere = (track) => {
        let canMove = true;
        tracks[track].forEach((instance) => {
          if (
            (draggedCoords.visibleFrom / msToPixel > instance.visibleFrom &&
              draggedCoords.visibleFrom / msToPixel < instance.visibleTo) ||
            (draggedCoords.visibleTo / msToPixel > instance.visibleFrom &&
              draggedCoords.visibleTo / msToPixel < instance.visibleTo) ||
            (draggedCoords.visibleFrom / msToPixel < instance.visibleFrom &&
              draggedCoords.visibleTo / msToPixel > instance.visibleTo) ||
            (draggedCoords.visibleFrom / msToPixel > instance.visibleFrom &&
              draggedCoords.visibleTo / msToPixel < instance.visibleTo)
          ) {
            canMove = false;
          }
        });
        return canMove;
      };

      // console.log(trackRef, onIndex);
      const oldTrack = trackRef.current;
      if (oldTrack !== dropTrackIndex) {
        // console.log('canMoveHere' + canMoveHere(dropTrackIndex));
        if (tracks[dropTrackIndex].length > 0) {
          tracks[dropTrackIndex].forEach((instance, i) => {
            if (draggedCoords.visibleFrom / msToPixel > instance.visibleFrom && id !== instance.id) {
              currentIndex = i + 1;
            }
          });
        } else {
          currentIndex = 0;
        }
        tracks.forEach((track, trI) =>
          track.forEach((instance, i) => {
            if (instance.id === id) {
              tracks[trI].splice(i, 1);
            }
          }));
        const newInstance = {
          visibleFrom: draggedCoords.visibleFrom / msToPixel,
          visibleTo: draggedCoords.visibleTo / msToPixel,
          id,
          type,
        };
        tracks[dropTrackIndex].splice(currentIndex, 0, newInstance);

        if (canMoveHere(dropTrackIndex) && poolType !== 'background') {
          if (type === 'text') {
            onOverlayMove(
              newInstance.id,
              newInstance.visibleFrom,
              newInstance.visibleTo,
              dropTrackIndex,
            );
          } else if (type === 'shape' && dropTrackIndex) {
            onUpdateShape({
              id: newInstance.id,
              visibleFrom: Math.floor(newInstance.visibleFrom),
              visibleTo: Math.floor(newInstance.visibleTo),
              track: dropTrackIndex,
            });
          } else if (type === 'image') {
            onUpdateLayer(
              {
                id: newInstance.id,
                visibleFrom: newInstance.visibleFrom,
                visibleTo: newInstance.visibleTo,
                track: dropTrackIndex,
              },
              newInstance.id,
            );
          }
          changeTrackRef(dropTrackIndex, props.trackRef);
          if (currentIndex > 0) {
            changeIndex(currentIndex, props.onIndex);
          } else {
            changeIndex(0, props.onIndex);
          }
        }
      }


      // --------- SWAP LOGIC --------------


      let prevClip = null;
      let prevClipIndex;
      let nextClip = null;
      let nextClipIndex;

      tracks[trackRef.current].forEach((instance, i) => {
        // console.log(i, instance.id);
        if (i - onIndex.current === -1) {
          prevClip = instance;
          prevClipIndex = i;
        }
      });
      tracks[trackRef.current].forEach((instance, i) => {
        if (i - onIndex.current === 1) {
          nextClip = tracks[trackRef.current][i];
          nextClipIndex = i;
        }
      });
      const prevClipDuration = prevClip && prevClip.visibleTo - prevClip.visibleFrom;
      const prevClipMidPoint = prevClip && prevClip.visibleFrom + (prevClipDuration / 2);
      const prevClipMidPointToPx = prevClip && Math.floor(prevClipMidPoint * msToPixel);
      const nextClipDuration = nextClip && nextClip.visibleTo - nextClip.visibleFrom;
      const nextClipMidPoint = nextClip && nextClip.visibleFrom + (nextClipDuration / 2);
      const nextClipMidPointToPx = nextClip && Math.floor(nextClipMidPoint * msToPixel);
      const thisDuration = visibleTo - visibleFrom;
      // console.log(draggedCoords.visibleFrom, prevClipMidPointToPx, draggedCoords.visibleTo, nextClipMidPointToPx);
      // console.log(prevClipIndex, onIndex.current);
      if (prevClipIndex < currentIndex && draggedCoords.visibleFrom < prevClipMidPointToPx - 5) {
        // --------- SWAP LEFT ------------
        const newPrevClip = {
          visibleFrom: prevClip.visibleFrom + thisDuration + 1,
          visibleTo: prevClip.visibleTo + thisDuration + 1,
          id: prevClip.id,
          track: trackRef.current,
        };
        currentIndex -= 1;
        const newThisClip = {
          visibleFrom: prevClip.visibleFrom,
          visibleTo: prevClip.visibleFrom + thisDuration,
          id,
          track: trackRef.current,
        };
        // console.log(newPrevClip.visibleFrom, newThisClip.visibleTo);
        changeIndex(currentIndex, props.onIndex);
        swap(item, newThisClip, newPrevClip, prevClip, onOverlayMove, onUpdateShape, onUpdateLayer, onBackgroundMove);
      } else if (nextClipIndex && nextClipIndex > currentIndex && draggedCoords.visibleTo > nextClipMidPointToPx + 5) {
        // --------- SWAP RIGHT ------------
        // console.log('swap right');
        const newNextClip = {
          visibleFrom: nextClip.visibleFrom - thisDuration - 1,
          visibleTo: nextClip.visibleTo - thisDuration - 1,
          id: nextClip.id,
          track: trackRef.current,
        };
        currentIndex += 1;
        const newThisClip = {
          visibleFrom: nextClip.visibleTo - thisDuration,
          visibleTo: nextClip.visibleTo,
          id,
          track: trackRef.current,
        };
        // console.log(newNextClip.visibleTo, newThisClip.visibleFrom);
        changeIndex(currentIndex, props.onIndex);
        swap(item, newThisClip, newNextClip, nextClip, onOverlayMove, onUpdateShape, onUpdateLayer, onBackgroundMove);
      }
    }
  },

  drop(props, monitor /* , component */) {
    if (props.hoveringInstance.current === true) return;
    props.changeBackgroundAddPosition(props.backgroundAddPosition, null);
    const offSet = monitor.getDifferenceFromInitialOffset();
    const clientOffset = monitor.getClientOffset();
    const draggedType = monitor.getItemType();
    // console.log(draggedType);
    const item = monitor.getItem();
    const {
      visibleFrom,
      visibleTo,
      id,
      type,
    } = item;
    const {
      poolIndex,
      msToPixel,
      trackRef,
      onOverlayMove,
      onBackgroundMove,
      onAddShape,
      onAddLayer,
      onUpdateShape,
      onUpdateLayer,
      onAdd,
      compositionWidth,
      compositionHeight,
      tracks,
      backgroundTrack,
      poolType,
    } = props;
    const dropTrackIndex = props.trackIndex;

    // ------ DEHIGHLIGHT --------
    const trackToDeHighlight = document
      .getElementsByClassName('pool')[poolIndex].getElementsByClassName('track')[dropTrackIndex];
    trackToDeHighlight.style.background = 'var(--color4)';

    if (draggedType === 'instance') {
      // --------- IF DRAGGED FROM TIMELINE ----------
      const thisInstanceCoords = {
        visibleFrom: (visibleFrom * msToPixel) + offSet.x,
        visibleTo: (visibleTo * msToPixel) + offSet.x,
      };
      const newInstance = {
        visibleFrom: Math.floor(thisInstanceCoords.visibleFrom / msToPixel),
        visibleTo: Math.floor(thisInstanceCoords.visibleTo / msToPixel),
        id,
        type,
      };
      const newInstanceDuration = newInstance.visibleTo - newInstance.visibleFrom;
      let currentIndex = 0;
      tracks[dropTrackIndex].forEach((instance, i) => {
        if (instance.id !== newInstance.id && newInstance.visibleFrom > instance.visibleFrom) {
          currentIndex = i + 1;
        }
      });

      // ---------- DETERMINE BOUNDARIES ---------------------------------
      if (newInstance.visibleFrom < 0) {
        newInstance.visibleFrom = 0;
        newInstance.visibleTo = newInstance.visibleFrom + newInstanceDuration;
      } else if (newInstance.visibleTo > props.duration) {
        newInstance.visibleTo = props.duration;
        newInstance.visibleFrom = props.duration - (visibleTo - visibleFrom);
      }
      tracks[props.trackRef.current].forEach((instance) => {
        const instanceMidPoint = instance.visibleFrom + ((instance.visibleTo - instance.visibleFrom) / 2);
        if (
          instance.id !== id &&
          newInstance.visibleFrom < instance.visibleTo &&
          newInstance.visibleFrom > instanceMidPoint
        ) {
          newInstance.visibleFrom = instance.visibleTo + 1;
          newInstance.visibleTo = newInstance.visibleFrom + newInstanceDuration;
        }
        if (
          instance.id !== id &&
          newInstance.visibleTo < instanceMidPoint &&
          newInstance.visibleTo > instance.visibleFrom
        ) {
          newInstance.visibleTo = instance.visibleFrom;
          newInstance.visibleFrom = instance.visibleFrom - newInstanceDuration;
        }
      });
      // --------------------------------------------
      tracks.forEach((track, trI) =>
        track.forEach((instance, i) => {
          if (instance.id === id) {
            // console.log('delete same clip');
            tracks[trI].splice(i, 1);
          }
        }));
      // console.log(currentIndex);
      const canMoveHere = (track) => {
        let canMove = true;
        tracks[track].forEach((instance) => {
          if (
            instance &&
            ((thisInstanceCoords.visibleFrom / msToPixel > instance.visibleFrom &&
              thisInstanceCoords.visibleFrom / msToPixel < instance.visibleTo) ||
              (thisInstanceCoords.visibleTo / msToPixel > instance.visibleFrom &&
                thisInstanceCoords.visibleTo / msToPixel < instance.visibleTo) ||
              (thisInstanceCoords.visibleFrom / msToPixel < instance.visibleFrom &&
                thisInstanceCoords.visibleTo / msToPixel > instance.visibleTo))
          ) {
            canMove = false;
          }
        });
        return canMove;
      };
      // tracks[trackRef.current].splice(currentIndex, 0, newInstance);
      // CHECK BACKGROUND POOL AND THE CLOSEST INPOINT TO SNAP ON
      let inPointToSnapIndex = 0;
      let shortestDistance = 10000000000000;
      backgroundTrack.forEach((instance, i) => {
        const distance = (instance.visibleFrom - thisInstanceCoords.visibleFrom) / msToPixel;
        if (Math.abs(distance) < shortestDistance) {
          shortestDistance = Math.abs(distance);
          inPointToSnapIndex = i;
        }
      });
      const inPointToSnap = backgroundTrack[inPointToSnapIndex].visibleFrom;
      if (Math.abs(thisInstanceCoords.visibleFrom / (msToPixel - inPointToSnap)) < 150) {

        newInstance.visibleFrom = inPointToSnap;
      }
      const dropTrack = canMoveHere(dropTrackIndex) ? dropTrackIndex : trackRef.current;
      switch (type) {
        case 'text':
          onOverlayMove(newInstance.id, newInstance.visibleFrom, newInstance.visibleTo, dropTrack);
          break;
        case 'shape':
          onUpdateShape({
            id: newInstance.id,
            visibleFrom: newInstance.visibleFrom,
            visibleTo: newInstance.visibleTo,
            track: dropTrack,
          });
          break;
        case 'video':
          onBackgroundMove(newInstance.id, newInstance.visibleFrom, newInstance.visibleTo, dropTrack);
          break;
        case 'image':
          if (poolType === 'background') {
            onBackgroundMove(newInstance.id, newInstance.visibleFrom, newInstance.visibleTo, dropTrack);
          } else {
            onUpdateLayer(
              {
                id: newInstance.id,
                visibleFrom: newInstance.visibleFrom,
                visibleTo: newInstance.visibleTo,
                track: dropTrack,
              },
              newInstance.id,
            );
          }
          break;
        case 'logo':
          if (poolType === 'background') {
            onBackgroundMove(newInstance.id, newInstance.visibleFrom, newInstance.visibleTo, dropTrack);
          } else {
            onUpdateLayer(
              {
                id: newInstance.id,
                visibleFrom: newInstance.visibleFrom,
                visibleTo: newInstance.visibleTo,
                track: dropTrack,
              },
              newInstance.id,
            );
          }
          break;
        default:
      }
    } else {
      // ----------- ADD TO TIMELINE ---------------------------------------
      const newInPoint = clientOffset ? clientOffset.x - props.timelineLeft : null;
      const thisInstanceCoords = {
        visibleFrom: newInPoint - (1500 * msToPixel) || 0,
        visibleTo: newInPoint + (1500 * msToPixel),
      };
      let newInstance;
      if (poolType !== 'background') {
        newInstance = {
          ...item,
          id: getUniqueId(),
          visibleFrom: Math.floor(thisInstanceCoords.visibleFrom / msToPixel),
          visibleTo: Math.floor(thisInstanceCoords.visibleTo / msToPixel),
        };
      } else {
        newInstance = {
          ...item.instance,
          type,
          id: getUniqueId(),
          duration: 3000,
          visibleFrom: Math.floor(thisInstanceCoords.visibleFrom / msToPixel),
          visibleTo: Math.floor(thisInstanceCoords.visibleTo / msToPixel),
        };
      }
      const newInstanceDuration = newInstance.visibleTo - newInstance.visibleFrom;
      // ------------------ DETERMINE BOUNDARIES ---------------------------------
      if (newInstance.visibleFrom < 0) {
        newInstance.visibleFrom = 0;
        newInstance.visibleTo = 3000;
      }
      const dropTrack = props.trackRef.current ? props.trackRef.current : dropTrackIndex;
      tracks[dropTrack].forEach((instance) => {
        const instanceMidPoint = instance.visibleFrom + ((instance.visibleTo - instance.visibleFrom) / 2);
        if (
          instance.id !== id &&
          newInstance.visibleFrom < instance.visibleTo &&
          newInstance.visibleFrom > instanceMidPoint
        ) {
          // console.log('snap to visibleTo');
          newInstance.visibleFrom = instance.visibleTo + 1;
          newInstance.visibleTo = instance.visibleTo + (visibleTo - visibleFrom) + 1;
        }
        if (
          instance.id !== id &&
          newInstance.visibleTo < instanceMidPoint &&
          newInstance.visibleTo > instance.visibleFrom
        ) {
          newInstance.visibleTo = instance.visibleFrom - 1;
          newInstance.visibleFrom = instance.visibleFrom - newInstanceDuration - 1;
        }
      });
      if (poolType === 'background') {
        onAdd(props.onIndex.current, newInstance);
      } else if (type === 'shape') {
        // console.log(newInstance.visibleFrom, newInstance.visibleTo);
        onAddShape({
          src: item.src,
          id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
          type: 'shape',
          left: 960,
          top: 540,
          track: dropTrackIndex || 4,
          visibleFrom: newInstance.visibleFrom ? Math.floor(newInstance.visibleFrom) : 0,
          visibleTo: Math.floor(newInstance.visibleTo),
          ang: 0,
        });
      } else if (type === 'text') {
        const sourceProps = monitor.getItem();
        sourceProps.handleAdd({
          originalTranslate: msToPixel * newInstance.visibleFrom,
          duration: newInstanceDuration,
          visibleFrom: newInstance.visibleFrom,
          visibleTo: newInstance.visibleTo,
          track: dropTrackIndex,
          styles: sourceProps.draggingStyles,
          width: newInstance.canvas.width,
          empty: true,
        });
      } else if (type === 'image') {
        const getScaleX = w => w / item.instance.width;
        // console.log(DEFAULT_OVERLAY_LAYER_SCALE, composition.width);
        const scale = getScaleX(DEFAULT_OVERLAY_LAYER_SCALE * props.compositionWidth);

        onAddLayer({
          id: getUniqueId(),
          visibleFrom: newInstance.visibleFrom,
          visibleTo: newInstance.visibleTo,
          duration: newInstance.visibleTo - newInstance.visibleFrom,
          top: compositionHeight / 2,
          left: compositionWidth / 2,
          thumbnail: item.instance.thumbnail,
          src: item.instance.src,
          type: item.instance.type ? item.instance.type : item.type,
          width: item.instance.width,
          height: item.instance.height,
          scaleY: scale,
          scaleX: scale,
          originX: 'center',
          originY: 'center',
          alwaysVisible: true,
          track: dropTrackIndex,
        });
      }
    }
    // modifyComposition(newComposition);
  },
};

/**
 * Specifies which props to inject into your component.
 */
const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
  canDrop: monitor.canDrop(),
});

const Track = (props) => {
  useEffect(() => {
    props.changeBackgroundAddPosition(props.backgroundAddPosition, null);
    // TODO: remove unnecessary formatBackgroundInstances
    // props.formatBackgroundInstances(props.backgroundTrack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOver]);
  const [transitionActive, toggleTransition] = useState(true);
  useLayoutEffect(() => {
    props.setTlWidth(document.getElementsByClassName('track')[0].getBoundingClientRect().width);
    props.setTlLeft(document.getElementsByClassName('track')[0].getBoundingClientRect().left);
  }, [props]);

  const instances = props.instances && props.instances.map((instance, index) => {
    const onDelete = (item) => {
      switch (item.type) {
        case 'video':
          return props.onDelete;
        case 'image':
          if (props.poolType === 'background') {
            return props.onDelete;
          }
          return props.onLayerRemove;
        case 'logo':
          if (props.poolType === 'background') {
            return props.onDelete;
          }
          return props.onLayerRemove;
        case 'text':
          return props.onTextRemove;
        case 'shape':
          return props.onShapeRemove;
        default:
          return () => { };
      }
    };

    return (
      <Instance
        expanded={props.expanded}
        transitionActive={transitionActive}
        toggleTransition={toggleTransition}
        backgroundAddPosition={props.backgroundAddPosition}
        changeBackgroundAddPosition={props.changeBackgroundAddPosition}
        poolType={props.poolType}
        key={`instance-${instance.id}`}
        instance={instance}
        timelineWidth={props.timelineWidth}
        msToPixel={props.msToPixel}
        duration={props.duration}
        index={index}
        trackIndex={props.trackIndex}
        instances={props.instances}
        tracks={props.tracks}
        backgroundTrack={props.backgroundTrack}
        composition={props.composition}
        modifyComposition={props.modifyComposition}
        poolIndex={props.poolIndex}
        trackRef={props.trackRef}
        changeTrackRef={props.changeTrackRef}
        onIndex={props.onIndex}
        changeIndex={props.changeIndex}
        onVideoResize={props.onVideoResize}
        onTextResize={props.onTextResize}
        onTextLayerSelect={props.onTextLayerSelect}
        onSelectShape={props.onSelectShape}
        onAddVideo={props.onAddVideo}
        onSelect={props.onSelect}
        onUpdateShape={props.onUpdateShape}
        compositionDuration={props.compositionDuration}
        onUpdateLayer={props.onUpdateLayer}
        changeSelected={props.changeSelected}
        onDelete={onDelete(instance)}
        onDrag={props.onDrag}
        onReplace={props.onReplace}
        activeBackgroundId={props.activeBackgroundId}
        activeTextId={props.activeTextId}
        activeLayerId={props.activeLayerId}
        activeShapeId={props.activeShapeId}
        onLayerSelect={props.onLayerSelect}
        onProgressJump={props.onProgressJump}
        setTrashVisible={props.setTrashVisible}
        trashVisible={props.trashVisible}
        toggleHovering={props.toggleHovering}
        hoveringInstance={props.hoveringInstance}
      />
    );
  });

  const { connectDropTarget } = props;
  return connectDropTarget(
    <div
      className={`track track-${props.trackIndex}`}
      style={{
        pointerEvents: `${!props.expanded ? 'none' : 'auto'}`,
      }}
    >
      {instances}
    </div>,
  );
};

Track.propTypes = {
  instances: PropTypes.array,
  expanded: PropTypes.bool,
  msToPixel: PropTypes.number,
  timelineWidth: PropTypes.number,
  tracks: PropTypes.array,
  poolIndex: PropTypes.number,
  onChangeTrack: PropTypes.func,
  trackIndex: PropTypes.number,
  visible: PropTypes.bool,
  modifyComposition: PropTypes.func,
  composition: PropTypes.object,
  onOverlayMove: PropTypes.func,
  poolType: PropTypes.string,
};

Track.defaultProps = {};

export default DropTarget(
  ['instance', 'text', 'shape', 'image', 'video'],
  trackTarget,
  targetCollect,
)(Track);
