import React from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';

const TextEditor = (props) => {
  const {
    onBlur,
    defaultValue,
    // initialHeight,
  } = props;
  autosize(document.querySelector('.textInstanceEditor'));
  return (
    <textarea
      rows='1'
      autoFocus={true}
      className='textInstanceEditor'
      scrolling='auto'
      onBlur={onBlur}
      defaultValue={defaultValue}
      style={{
        pointerEvents: 'all',
        backgroundColor: 'inherit',
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontStyle: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        overflow: 'hidden',
        outline: 'none',
        width: '100%',
        // height: initialHeight,
        minHeight: '1em',
        textAlign: 'inherit',
        WebkitTextStrokeWidth: 'inherit',
        WebkitTextStrokeColor: 'inherit',
        border: 'none',
        textShadow: 'inherit',
        top: 'inherit',
        left: 'inherit',
        margin: 'inherit',
        whiteSpace: 'break-spaces',
        overflowWrap: 'break-word',
      }}
    />
  );
};

TextEditor.propTypes = {
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  initialHeight: PropTypes.number,
};
export default TextEditor;
