import * as types from '@constants/actionTypes';
import { DESIGN_URL } from '@constants/api';
import axios from 'axios';

const loadDesign = design => ({
  type: types.COMPOSITION_LOAD_DESIGN,
  autosave: true,
  payload: {
    design,
  },
});

export const publishDesign = design => ({
  type: types.COMPOSITION_PUBLISH_DESIGN,
  payload: {
    design,
  },
  notify: {
    message: 'Design was saved',
    kind: 'success',
  },
});
export const designUnpublished = designUid => ({
  type: types.COMPOSITION_UNPUBLISH_DESIGN,
  payload: {
    designUid,
  },
  notify: {
    message: 'Template unpublished',
    kind: 'danger',
  },
});

const saveAsDesign = design => async (dispatch) => {
  const url = `${DESIGN_URL}`;
  const data = { compositionUid: design.uid };
  await axios
    .post(url, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch(publishDesign(design));
      }
    });
};
const unPublishDesign = designUid => async (dispatch) => {
  const url = `${DESIGN_URL}/${designUid}`;
  const response = await axios.patch(url);
  if (response && response.status === 200) {
    dispatch(designUnpublished(designUid));
  }
};

const receiveDesigns = designsArray => ({
  type: types.COMPOSITION_RECEIVE_DESIGNS,
  payload: {
    designsArray,
  },
});

const fetchDesigns = () => async (dispatch) => {
  const url = `${DESIGN_URL}`;
  await axios
    .get(url)
    .then((response) => {
      if (response && response.status === 200) {
        dispatch(receiveDesigns(response.data.templates));
      }
    });
};
const filterDesigns = aspectRatio => ({
  type: types.DESIGN_FILTER_DESIGNS,
  payload: {
    aspectRatio,
  },
});

export {
  fetchDesigns,
  saveAsDesign,
  unPublishDesign,
  loadDesign,
  filterDesigns,
};
