const layerPositions = (dimensions) => {
  const {
    compositionWidth, compositionHeight, trueWidth, trueHeight,
  } = dimensions;

  // Normal horizontal canvas
  const positions = {
    topLeft: {
      contain: {
        top: 0,
        left: 0,
      },
      cover: {
        top: 0,
        left: 0,
      },
    },
    topRight: {
      contain: {
        top: 0,
        left: compositionWidth - trueWidth,
      },
      cover: {
        top: (compositionHeight - trueHeight) / 2,
        left: compositionWidth - trueWidth,
      },
    },
    topCenter: {
      contain: {
        top: 0,
        left: (compositionWidth - trueWidth) / 2,
      },
      cover: {
        top: 0,
        left: (compositionWidth - trueWidth) / 2,
      },
    },
    middleLeft: {
      contain: {
        top: (compositionHeight - trueHeight) / 2,
        left: 0,
      },
      cover: {
        top: (compositionHeight - trueHeight) / 2,
        left: 0,
      },
    },
    middleCenter: {
      contain: {
        top: (compositionHeight - trueHeight) / 2,
        left: (compositionWidth - trueWidth) / 2,
      },
      cover: {
        top: (compositionHeight - trueHeight) / 2,
        left: (compositionWidth - trueWidth) / 2,
      },
    },
    middleRight: {
      contain: {
        top: (compositionHeight - trueHeight) / 2,
        left: compositionWidth - trueWidth,
      },
      cover: {
        top: (compositionHeight - trueHeight) / 2,
        left: compositionWidth - trueWidth,
      },
    },
    bottomLeft: {
      contain: {
        top: compositionHeight - trueHeight,
        left: 0,
      },
      cover: {
        top: compositionHeight - trueHeight,
        left: 0,
      },
    },
    bottomCenter: {
      contain: {
        top: compositionHeight - trueHeight,
        left: 0,
      },
      cover: {
        top: compositionHeight - trueHeight,
        left: 0,
      },
    },
    bottomRight: {
      contain: {
        top: compositionHeight - trueHeight,
        left: compositionWidth - trueWidth,
      },
      cover: {
        top: compositionHeight - trueHeight,
        left: compositionWidth - trueWidth,
      },
    },
  };

  return { positions };
};


export {
  layerPositions,
};
