import React, { useState, Fragment } from 'react';
import Modal from '@components/Modal';
import Button from '@components/Button';
import PropTypes from 'prop-types';
import { compositionAspect } from '@constants/composition';
import AspectRatioOption from './AspectRatioOption';
import './AspectModal.scss';

const propTypes = {
  visible: PropTypes.bool,
  reversion: PropTypes.bool,
  isNewVideo: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func,
  sidebarTabToggle: PropTypes.func,
  dashboardUpdateView: PropTypes.func,
  onToggleSidebarGallery: PropTypes.func,
  onSelectProject: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  visible: false,
};

const descriptions = {
  horizontal: 'Used on Facebook, Youtube and Websites',
  square: 'Used on Instagram, Facebook and Twitter',
  vertical: 'Used on Instagram Stories and Snapchat',
  facebook_vertical: 'Used on Instagram and Facebook Feed',
};

function AspectModal(props) {
  const [name, setName] = useState(props.name ? `${props.name} Copy` : 'Untitled video');
  const [selectedAspectratio, setSelectedAspectratio] = useState('horizontal');

  const handleCreate = () => {
    let finalName = name;
    const dimensions = compositionAspect(selectedAspectratio);

    if (finalName.trim() === '') {
      finalName = 'Untitled video';
    }

    // Set to default for next modal opening
    setName('');
    setSelectedAspectratio('horizontal');

    props.onAdd(finalName, dimensions);
    props.sidebarTabToggle('videos');
  };

  const handleCreateWithGenerator = () => {
    const dimensions = compositionAspect(selectedAspectratio);
    // props.onSelectProject();
    props.onAdd(name, dimensions);
    props.sidebarTabToggle('generate');
  };
  const handleReversioning = () => {
    const dimensions = compositionAspect(selectedAspectratio);
    props.onAdd(name, dimensions);
  };

  const handleCreateFromTemplate = () => {
    if (props.isNewVideo) handleCreate();
    props.dashboardUpdateView('templates', selectedAspectratio);
    props.onToggleSidebarGallery();
  };

  const enterPressed = (e) => {
    if (e.charCode === 13) {
      handleCreate();
    }
  };

  return (
    <Modal visible={props.visible} onClose={props.onClose} title={props.title}>
      <div className="NewCompositionModal">
        <div className="Modal__input-wrapper">
          <span className="Modal__label"> Name your new video </span>
          <input
            className="NewCompositionModal__name-input"
            type="text"
            placeholder={name}
            autoFocus
            onChange={event => setName(event.target.value)}
            onKeyPress = {enterPressed}
          />
        </div>
        <div className="NewCompositionModal__options">
          <AspectRatioOption
            onSelect={setSelectedAspectratio}
            label={'Landscape 16:9'}
            description={descriptions.horizontal}
            type={'horizontal'}
            selected={selectedAspectratio === 'horizontal'}
          />
          <AspectRatioOption
            onSelect={setSelectedAspectratio}
            label={'Square 1:1'}
            description={descriptions.square}
            type={'square'}
            selected={selectedAspectratio === 'square'}
          />
          <AspectRatioOption
            onSelect={setSelectedAspectratio}
            label={'Portrait 4:5'}
            description={descriptions.facebook_vertical}
            type={'facebook_vertical'}
            selected={selectedAspectratio === 'facebook_vertical'}
          />
          <AspectRatioOption
            onSelect={setSelectedAspectratio}
            label={'Portrait 9:16'}
            description={descriptions.vertical}
            type={'vertical'}
            selected={selectedAspectratio === 'vertical'}
          />

        </div>
          {props.reversion ? (
            <div className="MoveDialog__button-container">
              <Button color='pink' onClick={handleReversioning}>
                Duplicate
              </Button>
            </div>
          ) : (
            <div className="NewCompositionModal__button-container">
              <Fragment>
                <Button color="pink" onClick={handleCreate}>
                  New Video
                </Button>
                <Button color="pink" onClick={handleCreateFromTemplate}>
                  Video Templates
                </Button>
                <Button color="pink" onClick={handleCreateWithGenerator}>
                  Video Generator (Beta)
                </Button>
              </Fragment>
            </div>
          )}
      </div>
    </Modal>
  );
}

AspectModal.propTypes = propTypes;
AspectModal.defaultProps = defaultProps;

export default AspectModal;
