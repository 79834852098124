import React from 'react';
import PropTypes from 'prop-types';

import './VideoActionsMenu.scss';

const propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  onClickReversion: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickMove: PropTypes.func.isRequired,
  onClickRename: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

const defaultProps = {};

class VideoActionsMenu extends React.Component {
  state = {}

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.eventListener = document.addEventListener('click', this.handle);
  }

  componentWillUnmount() {
    if (this.eventListener) {
      document.removeEventListener('click', this.eventListener);
    }
  }

  handle = (event) => {
    const { onClickOutside } = this.props;

    if (this.containerRef &&
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      onClickOutside();
    }
  }

  handleReversioning = () => {
    const { onClickReversion } = this.props;
    onClickReversion();
  }

  handleDelete = () => {
    const { onClickDelete } = this.props;
    onClickDelete();
  }

  handleMove = () => {
    const { onClickMove } = this.props;
    onClickMove();
  }
  handleRename = () => {
    const { onClickRename } = this.props;
    onClickRename();
  }

  render() {
    return (
      <div
        className={this.props.active ? 'VideoActionsMenu__active' : 'VideoActionsMenu'}
        ref={this.containerRef}
      >
        <div
          className="VideoActionsMenu__option"
          onClick={this.handleReversioning}
        >
          Duplicate
        </div>
        <div
          className="VideoActionsMenu__option"
          onClick={this.handleMove}
        >
          Move
        </div>
        <div
          className="VideoActionsMenu__option"
          onClick={this.handleDelete}
        >
          Delete
        </div>
        <div
          className="VideoActionsMenu__option"
          onClick={this.handleRename}
        >
          Rename
        </div>
      </div>
    );
  }
}

VideoActionsMenu.propTypes = propTypes;
VideoActionsMenu.defaultProps = defaultProps;

export default VideoActionsMenu;
