export const en = {
	// NEW PAINT-X STRINGS BEGIN
	_acrylicbrushtool: 'Acrylic Brush Tool',
	_action: 'Action',
	_addColorlayer: 'Add Color Layer',
	_adjustmentsWillBeAppliedToImage: 'Adjustments will be applied to image.',
	_advanced: 'Advanced',
	_aim: 'Aim',
	_airbrushtool: 'Airbrush Tool',
	_allLayersWillBeLost: 'All layers will be lost.',
	_angleRandom: 'Angle Random',
	_apply: 'Apply',
	_audioInput: 'Audio Input',
	_autoBackup: 'Auto-Backup',
	_automaticallyResumeOnLaunch: 'Automatically Resume on Launch',
	_backgroundRemovalFailed: 'Background removal failed...',
	_backup: 'Backup',
	_backupNow: 'Backup Now',
	_black: 'Black',
	_bodyText: 'Body text',
	_browse: 'Browse',
	_brushTip: 'Brush Tip',
	_burnTransform: 'Burn Transform',
	_channel: 'Channel',
	_clearCanvas: 'Clear Canvas',
	_cloud: 'Cloud',
	_colder: 'Colder',
	_coloring: 'Coloring',
	_coloringGuide: 'Coloring Guide',
	_colorRandom: 'Color Random',
	_colorSwatches: 'Color Swatches',
	_colorWheel: 'Color Wheel',
	_confirm: 'Confirm',
	_contrastMinus: 'Contrast -',
	_contrastPlus: 'Contrast +',
	_convertToColoring: 'Convert to Coloring',
	_convertToColoringPromptMessage: '<b>Layers will be lost</b> and there is no undo.<br /><br />PS. This conversion works mostly with hand drawings',
	_convertToPainting: 'Convert to Painting',
	_copyMerged: 'Copy Merged',
	_cornerRoundness: 'Corner Roundness',
	_couldNotOpenThatFile: 'Could not open that file...',
	_deleteExistingBackup: 'Delete the existing backup?',
	_deletePreset: 'Delete Preset',
	_drawingAssist: 'Drawing Assist',
	_dynamics: 'Dynamics',
	_editor: 'Editor',
	_emojis: 'Emojis',
	_enabled: 'Enabled',
	_erase: 'Erase',
	_font: 'Font',
	_fontOutlineColor: 'Font Outline Color',
	_fontOutlineWidth: 'Font Outline Width',
	_fontWeight: 'Font Weight',
	_friction: 'Friction',
	_gradientType: 'Gradient Type',
	_heading: 'Heading',
	_imageColors: 'Image Colors',
	_importDrawing: 'Import Drawing',
	_importImage: 'Import Image',
	_importMask: 'Import Mask',
	_importMaskImage: 'Import Mask Image',
	_importPhoto: 'Import Photo',
	_inverseColors: 'Inverse Colors',
	_iterations: 'Iterations',
	_jitter: 'Jitter',
	_landscape: 'Landscape',
	_later: 'Later..',
	_launchWithWelcomeModal: 'Launch app with Welcome modal',
	_liveStroke: 'Live Stroke',
	_loading: 'Loading...',
	_makeLayer: 'Make Layer',
	_mark: 'Mark',
	_mask: 'Mask',
	_noThanks: 'No Thanks',
	_noWorksSavedOnCloudYet: 'No works saved on cloud yet',
	_outline: 'OUTLINE',
	_pasteFromClipboard: 'Paste from Clipboard',
	_pleaseWait: 'Please Wait',
	_pointColor: 'Point Color',
	_portrait: 'Portrait',
	_preferences: 'Preferences',
	_preparing: 'Preparing...',
	_presets: 'Presets',
	_presetsFrom: 'Presets from',
	_pressure: 'Pressure',
	_quality: 'Quality',
	_recursion: 'Recursion',
	_removeBackground: 'Remove Background',
	_removeBackgroundModalText: 'The isolated object will be added as a new layer.',
	_rename: 'Rename',
	_reset: 'Reset',
	_resetTransform: 'Reset Transform',
	_rows: 'Rows',
	_saveAsNewPreset: 'Save as New Preset..',
	_saveBrushPreset: 'Save Brush Preset',
	_savingToCloud: 'Saving to Cloud',
	_scaleRandom: 'Scale Random',
	_selectCurrentColor: 'Select Current Color',
	_selectionType: 'Selection Type',
	_selectTransparency: 'Select Transparency',
	_shapes: 'Shapes',
	_show: 'Show',
	_showFloatingBrushOptionsWhenPanelsAreClosed: 'Show floating brush options when panels are closed',
	_softness: 'Softness',
	_spriteEditor: 'Sprite Editor',
	_stabilize: 'Stabilize',
	_start: 'Start',
	_startColor: 'Start Color',
	_startWithThisModal: 'Start with this modal',
	_stopColor: 'Stop Color',
	_strokeWidth: 'Stroke Width',
	_styles: 'Styles',
	_subHeading: 'Subheading',
	_tapToFill: 'Tap to Fill',
	_templates: 'Templates',
	_thisWasMadeWithOldSumoPaint: 'This was made with old Sumopaint.',
	_tint: 'Tint',
	_tool: 'Tool',
	_toolOptions: 'Tool Options',
	_treetool: 'Tree Tool',
	_trunkWidth: 'Trunk Width',
	_updatePreset: 'Update Preset',
	_upgrade: 'Upgrade',
	_usedColors: 'Used Colors',
	_userInterface: 'User Interface',
	_userInterfaceStyle: 'User Interface Style',
	_userPresets: "User Presets",
	_variousOrientationsWithWhiteBackground: 'Various orientations with white background',
	_warmer: 'Warmer',
	_weHaveAProblem: 'We have a problem',
	_welcome: 'Welcome',
	_yourCurrentWorkWillBeReplaced: 'Your current work will be replaced',
	_viewWork: 'View Work',
	// NEW PAINT-X STRINGS END

	_3deffects: '3D Effects',
	_about: 'About',
	_actualpixels: 'Actual Pixels',
	_add: 'Add',
	_addanewlayer: 'Add a New Layer',
	_addinstrument: 'Add Instrument',
	_addlayer: 'Add Layer',
	_addnewlayer: 'Add New Layer',
	_addnoise: 'Add Noise',
	_addsilence: 'Add Silence',
	_addsongpart: 'Add Song Part',
	_addtoselection: 'Add to Selection',
	_adjust: 'Adjust',
	_adjustments: 'Adjustments',
	_align: 'Align',
	_all: 'All',
	_allowremixing: 'Allow Remixing',
	_allowviewing: 'Allow Viewing',
	_alphamap: 'Alpha Map',
	_alphatest: 'Alpha Test',
	_ambience: 'Ambience',
	_ambientlight: 'Ambient Light',
	_angle: 'Angle',
	_anglegradient: 'Angle Gradient',
	_animations: 'Animations',
	_antialias: 'Anti-alias',
	_aomap: 'AO Map',
	_api: 'API',
	_applytransform: 'Apply Transform',
	_arc: 'Arc',
	_areyousure: 'Are you sure?',
	_attributes: 'Attributes',
	_autocrop: 'Auto Crop',
	_autoheight: 'Auto Height',
	_autolevels: 'Auto Levels',
	_automatic: 'Automatic',
	_autoorient: 'Auto Orient',
	_autorotatefill: 'Auto Rotate Fill',
	_autorotategradient: 'Auto Rotate Gradient',
	_autosave: 'Autosave',
	_autosize: 'Auto Size',
	_autowidth: 'Auto Width',
	_average: 'Average',
	_background: 'Background',
	_backgroundcolor: 'Background Color',
	_bass: 'Bass',
	_beforeclosing: 'before closing',
	_bevel: 'Bevel',
	_bevelquality: 'Bevel Quality',
	_beveltype: 'Bevel Type',
	_bgalpha: 'BG Alpha',
	_bitmapfill: 'Bitmap Fill',
	_blending: 'Blending',
	_blendingmodes: 'Blending Modes',
	_blendmode: 'Blend Mode',
	_blue: 'Blue',
	_blueoffset: 'Blue offset',
	_blur: 'Blur',
	_bluralpha: 'Blur Alpha',
	_blurquality: 'Blur Quality',
	_blurscale: 'Blur Scale',
	_blurtool: 'Blur Tool',
	_border: 'Border',
	_bordercolor: 'Border Color',
	_borderselection: 'Border Selection',
	_bottom: 'Bottom',
	_bottomscale: 'Bottom Scale',
	_bounds: 'Bounds',
	_box: 'Box',
	_boxblur: 'Box Blur',
	_brightness: 'Brightness',
	_brightnesscontrast: 'Brightness / Contrast',
	_bringtofront: 'Bring to Front',
	_brush: 'Brush',
	_brushshape: 'Brush Shape',
	_brushstyle: 'Brush Style',
	_brushtool: 'Brush Tool',
	_bulkystartool: 'Bulky Star Tool',
	_bumpiness: 'Bumpiness',
	_bumpmap: 'Bumpmap',
	_bumpsoftness: 'Bump Softness',
	_burntool: 'Burn tool',
	_camouflage: 'Camouflage',
	_cancel: 'Cancel',
	_canceltransform: 'Cancel Transform',
	_cannotrecordaudioforsomereason: 'Cannot record audio for some reason. Check permissions and try restarting the browser?',
	_canvassize: 'Canvas Size',
	_cast: 'cast',
	_cellsize: 'Cell Size',
	_center: 'Center',
	_centerpoint: 'Center Point',
	_centerscale: 'Center Scale',
	_change: 'Change',
	_changegain: 'Change Gain',
	_circle: 'Circle',
	_circletool: 'Circle Tool',
	_clarity: 'Clarity',
	_clear: 'Clear',
	_clearcoat: 'Clearcoat',
	_clearcoatnormalmap: 'Clearcoat Normal Map',
	_clearcoatroughness: 'Clearcoat Roughness',
	_clearlayer: 'Clear Layer',
	_clearlayereffects: 'Clear Layer Effects',
	_clearpattern: 'Clear Pattern',
	_clone: 'Clone',
	_clonestamptool: 'Clone Stamp Tool',
	_close: 'Close',
	_closeall: 'Close All',
	_closealldocuments: 'Close all documents',
	_closeallopendocuments: 'Close all open documents',
	_closed: 'Closed',
	_clouds: 'Clouds',
	_coldertones: 'Colder Tones',
	_color: 'Color',
	_colorbalance: 'Color Balance',
	_colorburn: 'Color Burn',
	_colorchannels: 'Color Channels',
	_colordodge: 'Color Dodge',
	_colorhalftone: 'Color Halftone',
	_colorize: 'Colorize',
	_coloroverlay: 'Color Overlay',
	_colorpicker: 'Color Picker',
	_colortemperature: 'Color Temperature',
	_community: 'Community',
	_comparetooriginal: 'Compare to Original',
	_complexmode: 'Complex Mode',
	_compressing: 'Compressing',
	_computevertexnormals: 'Compute Vertex Normals',
	_constrainproportions: 'Constrain Proportions',
	_constrainscale: 'Constrain Scale',
	_contiguous: 'Contiguous',
	_contract: 'Contract',
	_contractselection: 'Contract Selection',
	_contractsize: 'Contract Size',
	_contrast: 'Contrast',
	_converttomono: 'Convert to Mono',
	_converttostereo: 'Convert to Stereo',
	_copy: 'Copy',
	_copycmdc: 'Copy (Cmd-C)',
	_copylayereffects: 'Copy Layer Effects',
	_cosine: 'Cosine',
	_couldnotexport: 'Couldn\'t export the file. Try different browser.',
	_couldnotopenfromlocalstorage: 'Couldn\'t open from localStorage',
	_couldnotsaveintolocalstorage: 'Couldn\'t save into localStorage.',
	_couldnotsavesumofile: 'Couldn\'t save Sumo file.',
	_couldnotsavetocloud: 'Couldn\'t save to Cloud',
	_crop: 'Crop',
	_croptool: 'Crop Tool',
	_crossprocess: 'Cross Process',
	_crystallize: 'Crystallize',
	_cubedesigner: 'Cube Designer',
	_currentworkwillbedeleted: 'Current work will be deleted',
	_currentworkwillbelost: 'Current work will be lost',
	_cursorsxcoordinate: 'Cursor\'s x-coordinate',
	_cursorsycoordinate: 'Cursor\'s y-coordinate',
	_curves: 'Curves',
	_curvetool: 'Curve Tool',
	_curvetype: 'Curve Type',
	_customizedprints: 'Customized Prints',
	_customresolution: 'Custom Resolution',
	_customshapetool: 'Custom Shape Tool',
	_cut: 'Cut',
	_cutcmdx: 'Cut (Cmd-X)',
	_cylinder: 'Cylinder',
	_cylinderdesigner: 'Cylinder Designer',
	_darken: 'Darken',
	_darkercolor: 'Darker Color',
	_decay: 'Decay',
	_default: 'Default',
	_defaultcolors: 'Default Colors',
	_deinterlace: 'De-Interlace',
	_delete: 'Delete',
	_deletebackspace: 'Delete (Backspace)',
	_deletelayer: 'Delete Layer',
	_deletepattern: 'Delete Pattern',
	_deletetext: 'Delete Text',
	_denoise: 'Denoise',
	_depth: 'Depth',
	_depthpacking: 'Depth Packing',
	_depthseg: 'Depth Seg',
	_desaturate: 'Desaturate',
	_description: 'Description',
	_deselect: 'Deselect',
	_detail: 'Detail',
	_detectedges: 'Detect Edges',
	_diameter: 'Diameter',
	_diamond: 'Diamond',
	_difference: 'Difference',
	_differenceclouds: 'Difference Clouds',
	_direction: 'Direction',
	_directionallight: 'Directional Light',
	_displacemap: 'Displace Map',
	_dissolve: 'Dissolve',
	_distance: 'Distance',
	_distantsize: 'Distant Size',
	_distort: 'Distort',
	_distorttransform: 'Distort Transform',
	_ditheredposter: 'Dithered Poster',
	_divide: 'Divide',
	_divisions: 'Divisions',
	_documentation: 'Documentation',
	_dodgetool: 'Dodge tool',
	_dotscreen: 'Dot Screen',
	_doublepattern: 'Double Pattern',
	_drawwithclonestamp: 'Draw with Clone Stamp',
	_dropaudiofile: 'Drop audio file',
	_dropaudioorhitrecbutton: 'Drop audio file or hit REC button',
	_dropshadow: 'Drop Shadow',
	_drums: 'Drums',
	_duplicate: 'Duplicate',
	_duplicatelayer: 'Duplicate Layer',
	_duplicatepattern: 'Duplicate Pattern',
	_edgedetection: 'Edge Detection',
	_edgeintensity: 'Edge Intensity',
	_edgequality: 'Edge Quality',
	_edgesalpha: 'Edges Alpha',
	_edgescale: 'Edge Scale',
	_edgethickness: 'Edge Thickness',
	_edgework: 'Edge Work',
	_edit: 'Edit',
	_editfiltersettings: 'Edit filter settings',
	_editpattern: 'Edit Pattern',
	_edu: 'Edu',
	_effects: 'Effects',
	_effectstrength: 'Effect Strength',
	_effecttype: 'Effect Type',
	_elevation: 'Elevation',
	_ellipsetool: 'Ellipse tool',
	_ellipticalmarqueetool: 'Elliptical Marquee tool',
	_ellipticalselection: 'Elliptical Selection',
	_email: 'Email',
	_emailwassentsuccessfully: 'E-mail was sent successfully',
	_emboss: 'Emboss',
	_emissive: 'Emissive',
	_emissivemap: 'Emissive Map',
	_english: 'English',
	_enterimageurl: 'Enter image URL',
	_envmap: 'Environment Map',
	_equalizecolors: 'Equalize Colors',
	_equalizetones: 'Equalize Tones',
	_erasertool: 'Eraser Tool',
	_error: 'Error',
	_ex0: 'Empty Project',
	_ex1: 'Hello Word',
	_ex10: 'Joystick',
	_ex11: 'Gamepad with dummies',
	_ex12: 'Math Dummy Game',
	_ex2: 'Museum',
	_ex3: 'Valley',
	_ex4: 'Molecule',
	_ex5: 'Prefabs',
	_ex6: 'Dummy',
	_ex7: 'Simple Track',
	_ex8: 'Complex Track',
	_ex9: 'Memory Game',
	_example: 'Example',
	_examples: 'Examples',
	_exclusion: 'Exclusion',
	_exit: 'Exit',
	_expand: 'Expand',
	_expandselection: 'Expand Selection',
	_expandsize: 'Expand Size',
	_export: 'Export',
	_exportgltf: 'Export GLTF',
	_exportobject: 'Export Object',
	_exportscene: 'Export Scene',
	_exportselection: 'Export Selection',
	_exposure: 'Exposure',
	_eyedroppertool: 'Eyedropper Tool',
	_facebookcover: 'Facebook Cover',
	_facebookpost: 'Facebook Post',
	_facebookstory: 'Facebook Story',
	_fade: 'Fade',
	_fadein: 'Fade In',
	_fadeoriginal: 'Fade Original',
	_fadeout: 'Fade Out',
	_far: 'Far',
	_feather: 'Feather',
	_featherselection: 'Feather Selection',
	_feathersize: 'Feather Size',
	_file: 'File',
	_filename: 'File name',
	_fill: 'Fill',
	_fillalpha: 'Fill Alpha',
	_filltype: 'Fill Type',
	_filter: 'Filter',
	_filters: 'Filters',
	_fingerpainting: 'Finger Painting',
	_finnish: 'Finnish',
	_fitonscreen: 'Fit on Screen',
	_flatshaded: 'Flat Shaded',
	_flattenimage: 'Flatten Image',
	_fliplayout: 'Flip Layout',
	_fliphorizontal: 'Flip Horizontal',
	_flipvertical: 'Flip Vertical',
	_flow: 'Flow',
	_focallength: 'Focal Length',
	_focalpoint: 'Focal Point',
	_focus: 'Focus',
	_fog: 'Fog',
	_fontfamily: 'Font Family',
	_fontsize: 'Font Size',
	_foregroundcolor: 'Foreground Color',
	_format: 'Format',
	_fov: 'Fov',
	_fractal: 'Fractal',
	_fractaldesigner: 'Fractal Designer',
	_fractalmorpher: 'Fractal Morpher',
	_fractalwaves: 'Fractal Waves',
	_frameshape: 'Frame Shape',
	_freetransform: 'Free Transform',
	_freetransformrotatetool: 'Free Transform / Rotate Tool',
	_from: 'From',
	_frommycomputer: 'From My Computer',
	_fromurl: 'From URL',
	_frustumcull: 'Frustum Cull',
	_fullglow: 'Full Glow',
	_fullscreenmode: 'Full Screen Mode',
	_funstruments: 'Funstruments',
	_gaussianblur: 'Gaussian Blur',
	_geometry: 'Geometry',
	_gifistoobigtobeimported: 'GIF is too big to be imported',
	_glowcolor: 'Glow Color',
	_glowingedges: 'Glowing Edges',
	_glowquality: 'Glow Quality',
	_glowtype: 'Glow Type',
	_goethescolortheory: 'Goethes Color Theory',
	_gradient: 'Gradient',
	_gradientbevel: 'Gradient Bevel',
	_gradientfilltool: 'Gradient Fill Tool',
	_gradientglow: 'Gradient Glow',
	_gradientmap: 'Gradient Map',
	_gradientoptions: 'Gradient Options',
	_gradienttool: 'Gradient Tool',
	_gravity: 'Gravity',
	_gravitylevel: 'Gravity Level',
	_gravityspeed: 'Gravity Speed',
	_grayscale: 'Grayscale',
	_green: 'Green',
	_greenoffset: 'Green offset',
	_gridsize: 'Grid Size',
	_groundcolor: 'Ground Color',
	_group: 'Group',
	_guest: 'Guest',
	_guitar: 'Guitar',
	_halftonepattern: 'Halftone Pattern',
	_handtool: 'Hand tool',
	_hardlight: 'Hard Light',
	_height: 'Height',
	_heightseg: 'Height Seg',
	_heightsegments: 'Height segments',
	_help: 'Help',
	_helpcontents: 'Help Contents',
	_hemispherelight: 'Hemisphere Light',
	_hexagon: 'Hexagon',
	_hexagonalpixelate: 'Hexagonal Pixelate',
	_hidecontrols: 'Hide Controls',
	_hidesource: 'Hide Source',
	_high: 'High',
	_highcontrast: 'High Contrast',
	_highlight: 'Highlight',
	_highlights: 'Highlights',
	_home: 'Home',
	_horisontalfunction: 'Horisontal Function',
	_horizontalmirroring: 'Horizontal Mirroring',
	_howmanypercent: 'How many percent? 50 = half, 200 = double.',
	_howmanyseconds: 'How many seconds?',
	_hue: 'Hue',
	_huesaturation: 'Hue / Saturation',
	_icosahedron: 'Icosahedron',
	_image: 'Image',
	_imagesize: 'Image Size',
	_import: 'Import',
	_importimagefromurl: 'Import Image from URL',
	_importtolayer: 'Import to Layer',
	_incompatiblesamplerate: 'Incompatible sample rate',
	_index: 'index',
	_info: 'Info',
	_infozoom: 'Info / Zoom',
	_ink: 'Ink',
	_inkbevel: 'Ink Bevel',
	_inkbrush: 'Ink Brush',
	_inkflow: 'Ink Flow',
	_inksmoothing: 'Ink Smoothing',
	_inktool: 'Ink Tool',
	_inkwetedges: 'Ink Wet Edges',
	_inner: 'Inner',
	_innerbevel: 'Inner Bevel',
	_innerglow: 'Inner Glow',
	_innerradius: 'Inner radius',
	_innershadow: 'Inner Shadow',
	_input: 'input',
	_instagramfeed: 'Instagram Feed',
	_instagramstory: 'Instagram Story',
	_instrumenttracks: 'Instrument Tracks',
	_intensity: 'Intensity',
	_inverse: 'Inverse',
	_inverseselection: 'Inverse Selection',
	_inversetangent: 'Inverse Tangent',
	_invert: 'Invert',
	_itseemsbrowserdoesntsupportrecording: 'It seems that this browser doesn\'t support recording or recording isn\'t enabled.',
	_kaleidoscope: 'Kaleidoscope',
	_keys: 'Keys',
	_knockout: 'Knockout',
	_language: 'Language',
	_lassotool: 'Lasso Tool',
	_lassotype: 'Lasso Type',
	_lastpickedcolors: 'Last picked colors',
	_lathe: 'Lathe',
	_layer: 'Layer',
	_layereffects: 'Layer Effects',
	_layeroptions: 'Layer Options',
	_layers: 'Layers',
	_layerstyle: 'Layer Style',
	_leading: 'Leading',
	_left: 'Left',
	_lensflare: 'Lens Flare',
	_levels: 'Levels',
	_light: 'Light',
	_lightdistance: 'Light Distance',
	_lighteffects: 'Light Effects',
	_lighten: 'Lighten',
	_lightercolor: 'Lighter Color',
	_lightingeffects: 'Lighting Effects',
	_lightintensity: 'Light Intensity',
	_lightmap: 'Light Map',
	_lightness: 'Lightness',
	_lightning: 'Lightning',
	_ligtningeffects: 'Ligtning Effects',
	_linear: 'Linear',
	_linearblur: 'Linear Blur',
	_linearburn: 'Linear Burn',
	_lineardodge: 'Linear Dodge',
	_lineargradient: 'Linear Gradient',
	_linearreflected: 'Linear Reflected',
	_linearreflectedgradient: 'Linear Reflected Gradient',
	_linearrepeated: 'Linear Repeated',
	_linearrepeatedgradient: 'Linear Repeated Gradient',
	_linearrgb: 'Linear RGB',
	_linediameter: 'Line Diameter',
	_linejointstyle: 'Line Joint Style',
	_lineopacity: 'Line Opacity',
	_lines: 'Lines',
	_linetool: 'Line Tool',
	_liquidwaves: 'Liquid Waves',
	_load: 'Load',
	_local: 'Local',
	_loggedinas: 'Logged in as',
	_loggedinasguest: 'Logged in as guest',
	_login: 'Login',
	_loginregister: 'Login / Register',
	_logout: 'Logout',
	_loopmode: 'Loop Mode',
	_low: 'Low',
	_lowcontrast: 'Low Contrast',
	_luminosity: 'Luminosity',
	_magicwandtool: 'Magic Wand Tool',
	_magneticlassotool: 'Magnetic Lasso tool',
	_map: 'Map',
	_matcapmap: 'Matcap Map',
	_material: 'Material',
	_maximizedpanelmode: 'Maximized Panel Mode',
	_maximum: 'Maximum',
	_medium: 'Medium',
	_mergedown: 'Merge Down',
	_mergelayers: 'Merge layers',
	_mergevisible: 'Merge Visible',
	_mergevisiblelayers: 'Merge visible layers',
	_message: 'Message',
	_metalness: 'Metalness',
	_metalnessmap: 'Metalness Map',
	_midtone: 'Midtone',
	_minimum: 'Minimum',
	_miter: 'Miter',
	_mode: 'Mode',
	_modevalue: 'Mode Value',
	_monochrome: 'Monochrome',
	_mosaic: 'Mosaic',
	_mosaicpattern: 'Mosaic Pattern',
	_motionblur: 'Motion Blur',
	_movelayerdown: 'Move Layer Down',
	_movelayerup: 'Move Layer Up',
	_movetool: 'Move Tool ...',
	_mp3: 'mp3',
	_multiply: 'Multiply',
	_music: 'Music',
	_name: 'Name',
	_narrow: 'Narrow',
	_near: 'Near',
	_nearsize: 'Near Size',
	_negative: 'Negative',
	_nevershowmethisboxagain: 'Never show me this box again',
	_new: 'New',
	_newimage: 'New Image',
	_newproject: 'New Project',
	_newselection: 'New Selection',
	_no: 'No',
	_nofilter: 'No Filter',
	_noise: 'Noise',
	_noiseamount: 'Noise Amount',
	_none: 'None',
	_normal: 'Normal',
	_normalbrowserwindow: 'Normal Browser Window',
	_normalize: 'Normalize',
	_normalmap: 'Normal Map',
	_object: 'Object',
	_objects: 'objects',
	_octahedron: 'Octahedron',
	_offset: 'Offset',
	_offsetlevel: 'Offset Level',
	_ok: 'OK',
	_ononeside: 'On one side',
	_opacity: 'Opacity',
	_open: 'Open',
	_openended: 'Open ended',
	_openfromcloud: 'Open from Cloud',
	_openfromhd: 'Open from HD',
	_openfromsumo: 'Open from Sumo',
	_openfromurl: 'Open from URL',
	_openproject: 'Open Project',
	_orthographiccamera: 'Orthographic Camera',
	_otherfilters: 'Other Filters',
	_outer: 'Outer',
	_outerbevel: 'Outer Bevel',
	_outeredge: 'Outer Edge',
	_outerglow: 'Outer Glow',
	_outerradius: 'Outer radius',
	_output: 'output',
	_overlay: 'Overlay',
	_pageturn: 'Page Turn',
	_paintbuckettool: 'Paint Bucket Tool',
	_pantool: 'Pan Tool',
	_part: 'Part',
	_parts: 'Parts',
	_password: 'Password',
	_paste: 'Paste',
	_pastecmdv: 'Paste (Cmd-V)',
	_pastelayereffects: 'Paste Layer Effects',
	_path: 'Path',
	_patternalpha: 'Pattern Alpha',
	_patternscale: 'Pattern Scale',
	_patternscaleoffset: 'Pattern Scale Offset',
	_patternshape: 'Pattern Shape',
	_patternsize: 'Pattern Size',
	_penciltool: 'Pencil Tool',
	_pentool: 'Pen tool',
	_penumbra: 'Penumbra',
	_percents: 'Percents',
	_perlinnoise: 'Perlin Noise',
	_permissions: 'Permissions',
	_perspective: 'Perspective',
	_perspectivecamera: 'Perspective Camera',
	_perspectivetiling: 'Perspective Tiling',
	_philength: 'Phi lenght',
	_phisegments: 'Phi segments',
	_phistart: 'Phi start',
	_pietool: 'Pie Tool',
	_pinch: 'Pinch',
	_pinchamount: 'Pinch Amount',
	_pinchscale: 'Pinch Scale',
	_pixelate: 'Pixelate',
	_pixelscale: 'Pixel Scale',
	_pixelshape: 'Pixel Shape',
	_plane: 'Plane',
	_plasticedges: 'Plastic Edges',
	_play: 'Play',
	_playback: 'Playback',
	_point: 'Point',
	_pointlight: 'Point Light',
	_points: 'Points',
	_polygonallassotool: 'Polygonal Lasso tool',
	_polygontool: 'Polygon Tool',
	_position: 'Position',
	_posteffect: 'Post Effect',
	_posterize: 'Posterize',
	_posterizelevel: 'Posterize Level',
	_postfxlevel: 'Post fx Level',
	_preset: 'Preset',
	_presetcurves: 'Preset Curves',
	_pressuresensitivity: 'Pressure Sensitivity',
	_preview: 'Preview',
	_privateimage: 'Private Image',
	_private: 'Private',
	_pro: 'You need a Pro plan to use this feature.',
	_pro1: 'You need a Pro plan to use more than 3 layers.',
	_pro2: 'You need a Pro plan to open a Sumo file.',
	_process: 'Process',
	_procodelimit: 'You need a Pro plan to have more than 400 lines of code.',
	_proexportlimit: 'You need a Pro plan to export the project.',
	_profile: 'Profile',
	_profilters: 'Pro Filters',
	_profeature: 'Pro Feature',
	_project: 'Project',
	_projectwassavedlocally: 'Project was saved locally.',
	_projectwassavedtocloud: 'Project was saved to Cloud',
	_prolayerlimit: 'You need a Pro plan to use more than 3 layers.',
	_prolinelimit: 'You need a Pro plan to have more than 10 lines of code on each object.',
	_proobjectlimit: 'You need a Pro plan to add more than 10 objects.',
	_proopenlimit: 'You need a Pro plan to open a Sumo file.',
	_protracklimit: 'You need a Pro plan to use more than 3 instrument tracks.',
	_public: 'Public',
	_publish: 'Publish',
	_quotaexceeded: 'Quota exceeded',
	_radial: 'Radial',
	_radialblur: 'Radial Blur',
	_radialgradient: 'Radial Gradient',
	_radialreflected: 'Radial Reflected',
	_radialreflectedgradient: 'Radial Reflected Gradient',
	_radialrepeated: 'Radial Repeated',
	_radialrepeatedgradient: 'Radial Repeated Gradient',
	_radialsegments: 'Radial segments',
	_radius: 'Radius',
	_radiusbottom: 'Radius bottom',
	_radiustop: 'Radius top',
	_randomcolors: 'Random Colors',
	_randomizegradient: 'Randomize Gradient',
	_randomizepattern: 'Randomize Pattern',
	_randomrotate: 'Random Rotate',
	_range: 'Range',
	_raster: 'Raster',
	_rasterizetext: 'Rasterize Text',
	_rec: 'REC',
	_receive: 'receive',
	_record: 'Record',
	_rectangletool: 'Rectangle Tool',
	_rectangularmarqueetool: 'Rectangular Marquee tool',
	_rectangularselection: 'Rectangular Selection',
	_rectangularselecttool: 'Rectangular Select Tool',
	_red: 'Red',
	_redeyetool: 'Red Eye tool',
	_redo: 'Redo',
	_redoffset: 'Red offset',
	_redotool: 'Redo Tool',
	_reflected: 'Reflected',
	_reflection: 'Reflection',
	_refracted: 'Refracted',
	_register: 'Register',
	_remixable: 'Remixable',
	_remixsumo: 'Remix Sumo',
	_remove: 'Remove',
	_removestatic: 'Remove Static',
	_renamelayer: 'Rename layer',
	_rendercamera: 'Render Camera',
	_renderer: 'Renderer',
	_renderorder: 'Render Order',
	_repeatonallsides: 'Repeat on all sides',
	_resampledto44100: '(resampled to 44100)',
	_resetall: 'Reset All',
	_resumework: 'Resume work?',
	_reverse: 'Reverse',
	_reverseselection: 'Reverse selection',
	_revert: 'Revert',
	_rewind: 'Rewind',
	_rgb: "RGB",
	_right: 'Right',
	_ring: 'Ring',
	_ripple: 'Ripple',
	_ripplelevel: 'Ripple Level',
	_ripplesize: 'Ripple Size',
	_rotate: 'Rotate',
	_rotate180: 'Rotate 180°',
	_rotate90ccw: 'Rotate 90° CCW',
	_rotate90cw: 'Rotate 90° CW',
	_rotatelayer: 'Rotate Layer',
	_rotation: 'Rotation',
	_roughness: 'Roughness',
	_roughnessmap: 'Roughness Map',
	_round: 'Round',
	_roundedrectangletool: 'Rounded Rectangle Tool',
	_roundedstartool: 'Rounded Star Tool',
	_roundness: 'Roundness',
	_rulertool: 'Ruler tool',
	_run: 'Run my code',
	_saturate: 'Saturate',
	_saturation: 'Saturation',
	_save: 'Save',
	_savebeforeclosing: 'Save Before Closing',
	_savechangestothedocument: 'Save changes to the document',
	_savechangestothedocumentbeforec: 'Save changes to the document before closing?',
	_saveimagetosumoaccount: 'Save Image to Sumo Paint',
	_savedtocloud: 'Saved to Cloud!',
	_savedtolibrary: 'File saved to cloud',
	_saveproject: 'Save Project',
	_saveprojectlocally: 'Save Work Locally',
	_savetocloud: 'Save to Cloud',
	_savetooriginals: "Save to Originals",
	_savetohd: 'Save to HD',
	_savetolibrary: 'Save to Library',
	_savetolibraryfailed: 'Saving to Cloud failed..',
	_saving: 'Saving',
	_savingimagepleasewait: 'Saving Image, Please Wait',
	_scale: 'Scale',
	_scattering: 'Scattering',
	_scene: 'Scene',
	_screen: 'Screen',
	_screenmode: 'Screen Mode',
	_script: 'Script',
	_search: 'Search',
	_seedocumentation: 'See Documentation',
	_showcontrols: 'Show Controls',
	_segments: 'Segments',
	_select: 'Select',
	_selectalllayers: 'Select all layers',
	_selectcolor: 'Select Color',
	_selectedcolorhexvalue: 'Selected Color Hex value',
	_selection: 'selection',
	_selectionbordercolor: 'Selection Border Color',
	_selectionmode: 'Selection Mode',
	_selectionshape: 'Selection Shape',
	_selectpixels: 'Select Pixels',
	_send: 'Send',
	_sendfeedback: 'Send Feedback',
	_sendimagetoemail: 'Send Image to E-mail',
	_sendtoback: 'Send to Back',
	_sendtoemail: 'Send to E-mail',
	_sepia: 'Sepia',
	_setasourcepointfortheareatoclone: 'Set a Source Point for the Area to Clone',
	_setbackgroundcolor: 'Set Background Color',
	_setbrush: 'Set Brush',
	_setdiametervalue: 'Set Diameter Value',
	_setforegroundcolor: 'Set Foreground Color',
	_setlayeropacity: 'Set Layer Opacity',
	_setlayersblendingmode: 'Set Layers Blending Mode',
	_setmodevalue: 'Set Mode Value',
	_setopacityvalue: 'Set Opacity Value',
	_settings: 'Settings',
	_setzoomlevel: 'Set Zoom Level',
	_shadow: 'Shadow',
	_shadowcolor: 'Shadow Color',
	_shadowlevel: 'Shadow Level',
	_shadowquality: 'Shadow Quality',
	_shadows: 'shadows',
	_shape: 'Shape',
	_shapeblur: 'Shape Blur',
	_shapetrails: 'Shape Trails',
	_sharpen: 'Sharpen',
	_sharpenlevel: 'Sharpen Level',
	_shininess: 'Shininess',
	_showborder: 'Show Border',
	_showhidecolorpanel: 'Show/Hide Color panel',
	_showhideinfopanel: 'Show/Hide Info panel',
	_showhidelayerspanel: 'Show/Hide Layers panel',
	_showoriginal: 'Show Original',
	_side: 'Side',
	_sides: 'Sides',
	_sine: 'Sine',
	_singlepart: 'Single Part',
	_size: 'Size',
	_sizex: 'Size X',
	_sizey: 'Size Y',
	_skinning: 'Skinning',
	_slot: 'Slot',
	_smoothing: 'Smoothing',
	_smoothness: 'Smoothness',
	_smoothzooming: 'Smooth Zooming',
	_smudgetool: 'Smudge Tool',
	_snaptopixel: 'Snap to Pixel',
	_softlight: 'Soft Light',
	_solarization: 'Solarization',
	_solidcolor: 'Solid Color',
	_song: 'Song',
	_songinfo: 'Song Info',
	_spacing: 'Spacing',
	_specialeffects: 'Special Effects',
	_specular: 'Specular',
	_specularmap: 'Specular Map',
	_sphere: 'Sphere',
	_spheredesigner: 'Sphere Designer',
	_sphereradius: 'Sphere Radius',
	_spiral: 'Spiral',
	_spiralgradient: 'Spiral Gradient',
	_spongetool: 'Sponge tool',
	_spot: 'Spot',
	_spotlight: 'Spot Light',
	_sprite: 'Sprite',
	_square: 'Square',
	_stampmode: 'Stamp Mode',
	_standardpanelmode: 'Standard Panel Mode',
	_startmenuinfo: 'This menu will be hidden automatically after creating or opening any image',
	_startool: 'Star Tool',
	_status: 'Status',
	_stop: 'Stop',
	_stopalpha: 'Stop Alpha',
	_straighten: 'Straighten',
	_strength: 'Strength',
	_stroke: 'Stroke',
	_strokecolor: 'Stroke Color',
	_strokeposition: 'Stroke Position',
	_stylize: 'Stylize',
	_subject: 'Subject',
	_substract: 'Subtract',
	_substractfromselection: 'Substract from Selection',
	_subtractfromselection: 'Subtract From Selection',
	_subtractivefill: 'Subtractive Fill',
	_sumogradients: 'Sumo Gradients',
	_sumopaintpro: 'Sumo Paint Pro',
	_support: 'Support',
	_surfaceblur: 'Surface Blur',
	_swatches: 'Swatches',
	_switchcolors: 'Switch Colors',
	_symmetry: 'Symmetry',
	_symmetrymode: 'Symmetry mode',
	_symmetrypoints: 'Symmetry Points',
	_symmetrytool: 'Symmetry Tool',
	_tangent: 'Tangent',
	_tellafriend: 'Tell a Friend',
	_temperature: 'Temperature',
	_tempo: 'Tempo',
	_tension: 'Tension',
	_testyourmicrophone: 'Test your microphone',
	_tetrahedron: 'Tetrahedron',
	_text: 'Text',
	_texttool: 'Text Tool',
	_texture: 'Texture',
	_theimage: 'The image',
	_thetalength: 'Theta length',
	_thetasegments: 'Theta segments',
	_thetastart: 'Theta start',
	_thisdidntlooklikesumofile: 'This didn\'t look like a Sumo file',
	_threshold: 'Threshold',
	_thresholdlevel: 'Threshold Level',
	_tilesize: 'Tile Size',
	_tilewaves: 'Tile Waves',
	_tiltangle: 'Tilt Angle',
	_tiltshift: 'Tilt Shift',
	_timedisplay: 'Time Display',
	_title: 'Title',
	_to: 'To',
	_toggleslider: 'Toggle Slider',
	_togglesliderrange: 'Toggle Slider Range',
	_tolerance: 'Tolerance',
	_tools: 'Tools',
	_top: 'Top',
	_torus: 'Torus',
	_torusknot: 'Torus Knot',
	_tracking: 'Tracking',
	_transfercodegenereateddataintoeditor: 'Transfer code generated data into editor?',
	_transform: 'Transform',
	_transformresolution: 'Transform Resolution',
	_transformtext: 'Transform Text',
	_transformtype: 'Transform Type',
	_translate: 'Translate',
	_transparency: 'Transparency',
	_transparent: 'Transparent',
	_triangles: 'triangles',
	_triangularpattern: 'Triangular Pattern',
	_trim: 'Trim',
	_trimtoselection: 'Trim to Selection',
	_trypartnertools: 'Try Partner Tools',
	_tube: 'Tube',
	_tubularsegments: 'Tubular segments',
	_twirl: 'Twirl',
	_twirlstrength: 'Twirl Strength',
	_type: 'Type',
	_typeyoursearchtermhere: 'Type your search term here',
	_undo: 'Undo',
	_undoredo: 'Undo/Redo',
	_undotool: 'Undo Tool',
	_unknownerror: 'Unknown error. Try restarting the browser.',
	_unsavedchangeswillbelost: 'Unsaved changes will be lost',
	_unsharpmask: 'Unsharp Mask',
	_untitled: 'Untitled',
	_update: 'Update',
	_updateeditor: 'Update Editor',
	_upgradetopro: 'Upgrade to Pro',
	_url: 'URL',
	_uselayerseffect: 'Use Layers Effect',
	_userdata: 'User data',
	_username: 'Username',
	_uuid: 'UUID',
	_vertexcolors: 'Vertex Colors',
	_verticalfunction: 'Vertical Function',
	_verticalmirroring: 'Vertical Mirroring',
	_vertices: 'vertices',
	_vibrance: 'Vibrance',
	_video: 'Video',
	_view: 'View',
	_vignette: 'Vignette',
	_visible: 'Visible',
	_vr: 'VR',
	_warmertones: 'Warmer Tones',
	_warptransform: 'Warp Transform',
	_wassentsuccesfullyto: 'was sent succesfully to',
	_waterlevel: 'Water Level',
	_wave: 'Wave',
	_waveintensity: 'Wave Intensity',
	_wavelab: 'Wave Lab',
	_waves: 'Waves',
	_websafecolors: 'Web Safe Colors',
	_wetedges: 'Wet Edges',
	_wetorient: 'Wet Orient',
	_white: 'White',
	_width: 'Width',
	_widthseg: 'Width Seg',
	_widthsegments: 'Width segments',
	_wireframe: 'Wireframe',
	_workname: 'Work Name',
	_works: 'Works',
	_wouldyouliketoresample: 'Would you like to resample your work into 44100 kHz?',
	_wraparound: 'Wrap around',
	_wrapping: 'Wrapping',
	_x: 'X',
	_y: 'Y',
	_yes: 'Yes',
	_yinyang: 'Yin / Yang',
	_zoom: 'Zoom',
	_zoomin: 'Zoom In',
	_zoommode: 'Zoom Mode',
	_zoomout: 'Zoom Out',
	_zoomsize: 'Zoom Size',
	_zoomstrength: 'Zoom Strength',
	_zoomtool: 'Zoom Tool',
	_zoomtype: 'Zoom Type',
	_library_showworks: 'Works',
	_library_showassets: 'Assets',
	_library_search: 'Search',
	_library_sortby: 'Sort by',
	_library_sortbynewest: 'Newest',
	_library_sortbyoldest: 'Oldest',
	_library_sortbyaz: 'A - Z',
	_library_sortbyza: 'Z - A',
	_library_sortbysize: 'Size',
	_library_app: 'App',
	_library_all: 'All',
	_library_assetcategory: 'Category',
	_library_assetcategoryimage: 'Image',
	_library_assetcategoryaudio: 'Audio',
	_library_assetcategoryvideo: 'Video',
	_library_assetcategory3d: '3D',
	_library_dropyourfileshere: 'Drop your files here',
	_library_connectionerror: 'No connection',
	_library_localsaveerror: 'Local saving not working',
	_library_cloudonlyforprousers: 'Cloud saving only for Pro users',
	_newversionavailable: 'A new version of the app is available.',
	_refresh: 'Refresh',
	_dismiss: 'Dismiss',
	_resume: 'Resume',
	_installapptitle: 'Install App',
	_installappdescription: 'Install {APP_NAME} for a better user experience and offline use.',
	_install: 'Install',
	_notnow: 'Not now',
	_installiosmessage: 'Tap {SHARE_ICON} on your device and Add to Home Screen.',
	_zoomtomax: 'Zoom to Max',
	_zoomtofit: 'Zoom to Fit',
	_zoomto50: 'Zoom to 50%',
	_zoomto100: 'Zoom to 100%',
	_zoomto200: 'Zoom to 200%',
	_library: 'Library'
}
