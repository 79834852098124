import * as types from '@constants/actionTypes';
import { TEXTPRESET_URL } from '@constants/api';
import axios from 'axios';

const textPresetSaved = newPreset => ({
  type: types.TEXTLAYER_SAVE_TEXTPRESET,
  payload: {
    newPreset,
  },
  notify: {
    message: 'text style saved',
    kind: 'success',
  },
});

const saveText = (textPreset, userId) => async (dispatch) => {
  const url = `${TEXTPRESET_URL}`;
  const data = { textPreset, userId };
  await axios
    .post(url, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch(textPresetSaved(textPreset));
      }
    })
    .catch(err => err);
};

const receiveTextPresets = presetCollection => ({
  type: types.TEXTLAYER_RECEIVE_TEXTPRESETS,
  payload: {
    presetCollection,
  },
});
const deletedStyle = presetId => ({
  type: types.TEXTLAYER_DELETE_TEXTPRESET,
  payload: {
    presetId,
  },
  notify: {
    message: 'text style deleted',
    kind: 'danger',
  },
});

const setDraggingStatus = status => ({
  type: types.TEXTPRESET_SET_DRAGGING_STATUS,
  payload: {
    status,
  },
});

const fetchTextPresets = canvasType => async (dispatch) => {
  const url = `${TEXTPRESET_URL}/${canvasType}`;
  await axios
    .get(url)
    .then((response) => {
      if (response && response.status === 200) {
        dispatch(receiveTextPresets(response.data.data));
      }
    })
    .catch(err => err);
};
const deleteTextPreset = presetId => async (dispatch) => {
  const url = `${TEXTPRESET_URL}/toggle`;
  const data = { presetId };

  await axios
    .post(url, data)
    .then((response) => {
      if (response && response.status === 200) {
        dispatch(deletedStyle(presetId));
      }
    })
    .catch(err => err);
};

export {
  fetchTextPresets,
  deleteTextPreset,
  saveText,
  setDraggingStatus,
};
