import React from "react";
import { connect } from "react-redux";
import { toggleModal } from "../../actions/modals";
import About from "./About";
import Confirm from "./Confirm";
import ExportVideo from "./ExportVideo";
import SaveProject from "./SaveProject";
import SaveToCloud from "./SaveToCloud";


function Modals({ modals }) {
    return (
        <>
            {modals.confirm && <Confirm />}
            {modals.saveProject && <SaveProject />}
            {modals.saveToCloud && <SaveToCloud />}
            {modals.about && <About />}
            {modals.exportVideo && <ExportVideo />}
        </>
    )
}


const mapStateToProps = ({ modals }) => {
    return {
        modals
    };
};

export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
})(Modals);
