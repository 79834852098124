/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
import InputNumber from '@components/InputNumber';
import PositionGrid from '@components/PositionGrid';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { TrashCan, Time, StopWatch } from '@components/Icons';

const propTypes = {
  activeLayer: PropTypes.object,
  activeLayerId: PropTypes.number,
  visibilityTimes: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onShowVisiblityHandler: PropTypes.func.isRequired,
  showVisibilityHandler: PropTypes.bool,
  backgroundInstanceTimes: PropTypes.array.isRequired,
  compositionDuration: PropTypes.number.isRequired,
  onUpdateTimestamps: PropTypes.func,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  onMove: PropTypes.func,
  onModify: PropTypes.func,
  toggleAlwaysVisible: PropTypes.func,
  onTriggerNotification: PropTypes.func,
};

const ToolbarLayer = ({
  activeLayer,
  activeLayerId,
  onRemove,
  onTriggerNotification,
  onUpdateTimestamps,
  compositionWidth,
  compositionHeight,
  compositionDuration,
  onMove,
  onClose,
  onModify,
  toggleAlwaysVisible,
}) => {
  if (!activeLayer) {
    onClose();
    return null;
  }
  const [newFrom, setNewFrom] = useState(null);
  const [newTo, setNewTo] = useState(null);
  const [screenWidth, updateScreenWidth] = useState(window.innerWidth);
  const [dropDowns, toggleDropDowns] = useState(null);
  const notifications = {
    maxExceeded: 'Cannot exceed the length of the composition',
    minDuration: 'The length of the layer must be minimum 1 sec',
  };

  const handleDelete = () => onRemove(activeLayerId);

  const isAlwaysVisible = activeLayer.alwaysVisible &&
    (activeLayer.visibleTo - activeLayer.visibleFrom === compositionDuration);
  const fadeInEnabled = activeLayer.animationIn;
  const fadeOutEnabled = activeLayer.animationOut;

  const handleResize = () => {
    updateScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => { // eslint-disable-line consistent-return
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLayerMove = (position) => {
    const { left, top } = position;
    onMove(activeLayer.id, left, top);
  };

  const handleToggleFadeIn = (e) => {
    e.target.blur();
    if (!activeLayer.animationIn) {
      onModify(
        {
          ...activeLayer,
          animationIn: true,
        },
        activeLayer.id,
      );
    } else {
      onModify(
        {
          ...activeLayer,
          animationIn: false,
        },
        activeLayer.id,
      );
    }
  };

  const handleToggleFadeOut = (e) => {
    e.target.blur();
    if (!activeLayer.animationOut) {
      onModify(
        {
          ...activeLayer,
          animationOut: true,
        },
        activeLayer.id,
      );
    } else {
      onModify(
        {
          ...activeLayer,
          animationOut: false,
        },
        activeLayer.id,
      );
    }
  };
  // user should be able to toggle layer visiblity to always show/ entire video.
  const handleAlwayVisible = (e) => {
    e.target.blur();
    toggleAlwaysVisible(activeLayer.id, e.target.checked);
  };

  const onChangeFunction = (e) => {
    if (e.target.name === 'newTo') {
      setNewTo(Number(e.target.value * 1000));
    } else {
      setNewFrom(Number(e.target.value * 1000));
    }
  };

  const onBlurFunction = (e) => {
    if (e.target.name === 'newTo') {
      const checkedForMin = newTo - activeLayer.visibleFrom > 1000;
      const checkedForMax = newTo < compositionDuration;
      let checkedForLimit;
      if (checkedForMin && checkedForMax) {
        checkedForLimit = newTo;
      } else if (!checkedForMin) {
        checkedForLimit = activeLayer.visibleFrom + 1000;
      } else if (!checkedForMax) {
        checkedForLimit = compositionDuration;
      }
      onUpdateTimestamps(
        {
          visibleFrom: activeLayer.visibleFrom,
          visibleTo: checkedForLimit,
          alwaysVisible: false,
        },
        activeLayerId,
      );
    } else {
      const checkedForMin = newFrom > 0;
      const checkedForMax = activeLayer.visibleTo - newFrom < 1000;
      let checkedForLimit;
      if (checkedForMin && !checkedForMax) {
        checkedForLimit = newFrom;
      } else if (!checkedForMin) {
        checkedForLimit = 0;
      } else if (checkedForMax) {
        checkedForLimit = activeLayer.visibleTo - 1000;
      }
      onUpdateTimestamps(
        {
          visibleFrom: checkedForLimit,
          visibleTo: activeLayer.visibleTo,
          alwaysVisible: false,
        },
        activeLayerId,
      );
    }
  };

  const textStylesGroup = screenWidth < 1360 ? 'Toolbar__dropDown row' : 'ToolbarGroup';

  return (
    <Fragment>
      <div className="ToolbarGroup Thumbnail">
        <div
          className="ToolbarThumbnail"
          style={{
            backgroundImage: `url(${activeLayer.src})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
      </div>
      <div className="ToolbarGroup">
        <div className="ToolbarItem duration">
          <StopWatch />
          <InputNumber
            name="newFrom"
            onBlurFunction={onBlurFunction}
            onChangeFunction={onChangeFunction}
            initialInput={Number(activeLayer.visibleFrom / 1000).toFixed(1)}
            onTriggerNotification={onTriggerNotification}
            notifications={notifications}
            max={activeLayer.visibleTo - 1000}
            min={0}
          />
          <Time />
          <InputNumber
            name="newTo"
            onBlurFunction={onBlurFunction}
            onChangeFunction={onChangeFunction}
            initialInput={Number(activeLayer.visibleTo / 1000).toFixed(1)}
            onTriggerNotification={onTriggerNotification}
            notifications={notifications}
            max={compositionDuration}
            min={activeLayer.visibleFrom + 1000}
          />
        </div>
        <div className="ToolbarItem Switch">
          <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
            <FormControlLabel
              control={
                <Switch size="small" checked={isAlwaysVisible} onChange={handleAlwayVisible} />
              }
              label="Always Visible"
            />
          </FormGroup>
        </div>
        <div className='ToolbarGroup'>
          {screenWidth < 1360 && (
            <div
              className="ToolbarItem"
              onClick={() => {
                if (dropDowns !== 'animations') {
                  toggleDropDowns('animations');
                } else {
                  toggleDropDowns('');
                }
              }}
            >
              <Button iconBtn={true}>
                Animations
              </Button>
            </div>
          )}
          {(screenWidth > 1360 || (screenWidth < 1360 && dropDowns === 'animations')) && (
            <div className={textStylesGroup}>
              <div className="ToolbarItem Switch">
                <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch size="small" checked={fadeInEnabled} onChange={handleToggleFadeIn} />
                    }
                    label="Fade-in"
                  />
                </FormGroup>
              </div>
              <div className="ToolbarItem Switch">
                <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={fadeOutEnabled}
                        onChange={handleToggleFadeOut}
                      />
                    }
                    label="Fade-out"
                  />
                </FormGroup>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="Toolbar__right">
        <div className="ToolbarGroup">
          <PositionGrid
            context={'layer'}
            activeLayer={activeLayer}
            onSelect={handleLayerMove}
            compositionWidth={compositionWidth}
            compositionHeight={compositionHeight}
          />
        </div>
        <div className="ToolbarGroup CopyAndDelete">
          <div className="ToolbarItem delete" onClick={() => handleDelete()}>
            <Button iconBtn={true}>
              <TrashCan />
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ToolbarLayer.propTypes = propTypes;
export default ToolbarLayer;
