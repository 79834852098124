import * as types from '@constants/actionTypes';

const dashboardUpdateView = (view, aspectRatio) => ({
  type: types.DASHBOARD_SET_CURRENT_VIEW,
  payload: {
    view,
    aspectRatio,
  },
});

const toggleDashboard = () => ({
  type: types.APP_DASHBOARD_TOGGLE,
});


export {
  dashboardUpdateView,
  toggleDashboard,
};
