import { Modal } from '@sumo-apps/core'
import React from 'react'
import { connect } from 'react-redux'
import { toggleModal } from '../../actions/modals'

function About({
    modals,
    onToggleModal,
    strings,
}) {
    function closeModal() {
        onToggleModal({
            confirm: false,
            data: null
        })
    }
    return (
        <Modal
            appName="video"
            buttons={[
                { action: modals.data.btnAction1, text: strings[`_${modals.data.btnText1}`] },
                { action: modals.data.btnAction2, text: strings[`_${modals.data.btnText2}`] },
            ]}
            title={modals.data.title}
            style={{ maxWidth: 320 }}
            closeFunc={closeModal}
        >
            <p>By creating a new project, the current one will be erased. Are you sure?</p>
        </Modal>
    )
}


const mapStateToProps = ({ modals, languages }) => {
    return {
        modals,
        strings: languages.strings
    };
};
export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
})(About);