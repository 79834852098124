import Button from '@components/Button';
import { Color } from '@components/Icons';
import { colorSwatches } from '@helpers/sidebar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AlphaPicker, CirclePicker, SketchPicker } from 'react-color';
import './Adjuster.scss';
import './SidebarShapeOverlay.scss';

const SidebarShapeOverlay = (props) => {
  const {
    colorSetting, activeShape, onModifyShape, pallets,
  } = props;
  const [appliedShapeFill, setAppliedShapeFill] = useState(colorSetting?.fill || null);
  const [advanced, toggleAdvanced] = useState(false);

  if (!activeShape || !(colorSetting?.fill)) return null;

  const handleResetColors = () => {
    onModifyShape({
      id: activeShape.id,
      settings: null,
    });
  };

  const updateColorSetting = (setting) => {
    const newSettings = activeShape.settings.map((childSettings) => {
      if (!(setting.index === childSettings.index)) return childSettings;
      let { fillColor } = setting;
      if (typeof setting.fillColor === 'object') {
        fillColor = `rgba(${fillColor.r},${fillColor.g},${fillColor.b},${fillColor.a})`;
      }
      return {
        ...childSettings,
        attr: {
          ...childSettings.attr,
          fill: fillColor,
          'fill-opacity': fillColor.a || 1,
        },
      };
    });
    onModifyShape({
      id: activeShape.id,
      settings: newSettings,
    });
  };

  const renderAdvanced = () => (
    <div className="customColorPicker">
      <SketchPicker
        width={275}
        color={appliedShapeFill}
        presetColors={colorSwatches}
        onChange={(e) => {
          setAppliedShapeFill(e.rgb);
          updateColorSetting({ fillColor: e.rgb, ...colorSetting });
        }}
      />
    </div>
  );

  return (
    <div className="styleOptions" style={{ width: '100%' }}>
      <div className="ContextSettings__Adjuster">
        <div className='details'>
          <div className='content'>
            <div className='name'>Shape colors</div>
          </div>
        </div>
        <div className="svgColorPicker">
          <Button
            className={advanced ? 'selected' : null}
            onClick={() => toggleAdvanced(!advanced)}
            iconBtn={true}
          >
            <Color />
          </Button>
          {advanced && renderAdvanced()}
          <div className="Adjustable__default">
            {pallets &&
              pallets.map((palette, index) => (
                <div className="palette" key={index + palette}>
                  <p>{`palette-${index}`}</p>
                  <CirclePicker
                    width={275}
                    circleSize={21}
                    circleSpacing={5}
                    colors={palette && palette.colors}
                    onChange={(e) => {
                      setAppliedShapeFill(e.rgb);
                      updateColorSetting({ fillColor: e.hex, ...colorSetting });
                    }}
                  />
                </div>
              ))}
          </div>
          <div className="palette">
            <p>Default colors</p>
            <CirclePicker
              width={275}
              circleSize={21}
              circleSpacing={5}
              color={'#fffefe'}
              colors={colorSwatches}
              onChange={(e) => {
                setAppliedShapeFill(e.rgb);
                updateColorSetting({ fillColor: e.hex, ...colorSetting });
              }}
            />
          </div>
          <div className='alphaPickerContainer'>
            <AlphaPicker
              color={(activeShape.settings && activeShape.settings[colorSetting.index].attr.fill) || 'white'}
              width={200}
              onChange={(e) => {
                setAppliedShapeFill(e.rgb);
                updateColorSetting({ fillColor: e.rgb, ...colorSetting });
              }}
            />
          </div>
        </div>
      </div>
      <p className="reset" onClick={handleResetColors}>
        Reset
      </p>
    </div>
  );
};

SidebarShapeOverlay.propTypes = {
  pallets: PropTypes.array,
  colorSetting: PropTypes.oneOfType(['string', 'object']),
  onModifyShape: PropTypes.func,
  activeShape: PropTypes.object,
  advanced: PropTypes.bool,
};

export default SidebarShapeOverlay;
