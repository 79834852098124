import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { fabric } from 'fabric';

import {
  textStyleAttributes,
  borderAndCornerStyles,
} from '@lib/fabricHelper';
import {
  TextLayer,
  Animate,
  easing as easingFunctions,
  StaggeredAnimation,
} from '@lib/render-engine';

/**
 * fabric "set" method to add a padding between text and
 * background color on every fabric text object.
 */
fabric.IText.prototype.set({
  _getNonTransformedDimensions() { // Object dimensions
    return new fabric.Point(this.width, this.height).scalarAdd(50);
  },
});

/**
 * Some of the text attributes needs to be applied with
 * fabric "set" method.
 *
 * @param {Object} attributeObject - object with settable attributes
 * key - value pair
 * @param {Object} textLayerElement - Fabric textlayer object
 */
const setTextAttribute = (
  attributeObject,
  textLayerElement,
) => {
  textLayerElement.set({ [attributeObject.key]: attributeObject.value });
};

/**
 * Returns the closest timestamp in relation to currentTimestamp where
 * the text is no longer actively animating.
 */
const getNearestNonAnimatedTime = (textInstance, currentTimestamp) => {
  const { animation: { in: inAnimations, out: outAnimations } } = textInstance;

  const inTimes = inAnimations
    .reduce((times, animation) => {
      times.push(animation.from, animation.to);
      return times;
    }, []);

  const outTimes = outAnimations
    .reduce((times, animation) => {
      times.push(animation.from, animation.to);
      return times;
    }, []);

  const inMin = Math.min(...inTimes);
  const inMax = Math.max(...inTimes);
  const outMin = Math.min(...outTimes);
  const outMax = Math.max(...outTimes);

  const currentTimeIsDuringInAnimation = currentTimestamp > inMin && currentTimestamp < inMax;
  const currentTimeIsDuringOutAnimation = currentTimestamp > outMin && currentTimestamp < outMax;

  if (currentTimeIsDuringInAnimation) {
    // First non-animated frame is the next one from in animations last timestamp
    return inMax + 1;
  } else if (currentTimeIsDuringOutAnimation) {
    return outMin - 1;
  }

  return inMax + 1;
};

const addSimpleAnimationsToLayer = (layer, animations, direction) => {
  animations.forEach((animation) => {
    const animationEasing = animation.easing ?
      easingFunctions[animation.easing] : easingFunctions.easeInQuart;
    layer.addAnimation(new Animate(animation.from, animation.to, direction, {
      ...animation.values,
    }, animationEasing));
  });

  return layer;
};

// NOT BEING USED ANYMORE!
const addSingleTextLayer = (renderEngine, textInstance, handlers) => {
  const copiedInstance = cloneDeep(textInstance);

  const {
    left,
    top,
    visibleFrom,
    visibleTo,
    styles,
    text,
    textAlign = 'left',
    backgroundColor,
    scaleX,
    scaleY,
    shadow,
    angle = 0,
  } = copiedInstance;

  const baseOptions = {
    ...borderAndCornerStyles,
    left,
    top,
    styles,
    textAlign,
    backgroundColor,
    id: textInstance.id,
    scaleX,
    scaleY,
    originX: 'center',
    originY: 'center',
    shadow,
    angle,
  };

  /**
   * Hydrate baseOptions with any textStyleAttributes
   * that is set in the copiedInstance
   */
  const fabricTextInstanceOptions = textStyleAttributes.reduce((obj, attr) => {
    if (copiedInstance[attr]) {
      obj[attr] = copiedInstance[attr]; // eslint-disable-line no-param-reassign
    }
    return obj;
  }, baseOptions);

  let layerOptions = {
    id: textInstance.id,
    visibleFrom,
    visibleTo,
  };

  const updateTextChange = () => {
    const textObj = renderEngine.canvas.getActiveObject();
    handlers.onChange(textInstance.id, textObj.text);
  };

  const eventHandlers = {
    selected: () => handlers.selected(textInstance.id),
    deselected: () => handlers.deselected(),
    moving: opts => handlers.moving(textInstance.id, opts),
    moved: event => handlers.moved(
      textInstance.id,
      event.target.left,
      event.target.top,
    ),
    selectionChanged: () => handlers.selectionChanged(),
    changed: () => updateTextChange(),
    mousedown: opts => handlers.mousedown(textInstance.id, opts),
    mouseup: opts => handlers.mouseup(textInstance.id, opts),
    modified: () => {
      const textObj = renderEngine.canvas.getActiveObject();
      handlers.modified(textObj, textInstance.id);
    },
  };

  const object = new fabric.IText(text, fabricTextInstanceOptions);

  let layer;
  if (copiedInstance.animation) {
    const {
      split,
      in: inAnimations,
      out: outAnimations,
      easing,
    } = copiedInstance.animation;

    // If split is defined, animations needs to be added trough
    // animationFactory
    if (split) {
      const animationEasing = easing ? easingFunctions[easing] : easingFunctions.easeInQuart;
      layerOptions = {
        ...layerOptions,
        // Grab symbol from TextLayer that is equal to the text value
        split,
        animationFactory: [
          // mode isn't in the animation info, so it needs to be added
          ...inAnimations.map(ani => new StaggeredAnimation({
            ...ani,
            mode: Animate.IN,
            easing: animationEasing,
          })),
          ...outAnimations.map(ani => new StaggeredAnimation({
            ...ani,
            mode: Animate.OUT,
            easing: animationEasing,
          })),
        ],
      };
      layer = new TextLayer(object, layerOptions, eventHandlers);
    } else {
      layer = new TextLayer(object, layerOptions, eventHandlers);

      layer = addSimpleAnimationsToLayer(layer, inAnimations, Animate.IN);
      layer = addSimpleAnimationsToLayer(layer, outAnimations, Animate.OUT);
    }
  } else {
    layer = new TextLayer(object, layerOptions, eventHandlers);
  }
  renderEngine.addLayer(layer);
};

/**
 * Clear layers that are not present in activeLayers
 */
const removeDeletedLayers = (renderEngine, renderEngineLayers, activeLayers) => renderEngineLayers
  .filter(layer => layer instanceof TextLayer)
  .filter(textLayer => !activeLayers.includes(textLayer.id))
  .map(matched => renderEngine.canvas.remove(matched.textElement));

const addAnimationsToLayer = (compositionTextInstance, matchingRenderEngineLayer) => {
  const {
    split,
    in: inAnimations,
    out: outAnimations,
    easing,
  } = compositionTextInstance.animation;

  if (!split) {
    addSimpleAnimationsToLayer(
      matchingRenderEngineLayer,
      inAnimations,
      Animate.IN,
    );

    addSimpleAnimationsToLayer(
      matchingRenderEngineLayer,
      outAnimations,
      Animate.OUT,
    );
  } else {
    const animationEasing = easing ? easingFunctions[easing] : easingFunctions.easeInQuart;
    const animationFactory = [
      // mode isn't in the animation info, so it needs to be added
      ...inAnimations.map(ani => new StaggeredAnimation({
        ...ani,
        mode: Animate.IN,
        easing: animationEasing,
      })),
      ...outAnimations.map(ani => new StaggeredAnimation({
        ...ani,
        mode: Animate.OUT,
        easing: animationEasing,
      })),
    ];
    // TODO: handle different splits
    matchingRenderEngineLayer.updateComplexAnimations(
      animationFactory,
      split,
    );
  }
};

const removeAnimationsFromLayer = (matchingRenderEngineLayer) => {
  matchingRenderEngineLayer.clearAnimations();
  matchingRenderEngineLayer.clearComplexAnimations();
};

const modifyLayerAnimations = (compositionTextInstance, matchingRenderEngineLayer) => {
  matchingRenderEngineLayer.clearAnimations();
  matchingRenderEngineLayer.clearComplexAnimations();
  addAnimationsToLayer(compositionTextInstance, matchingRenderEngineLayer);
};

/**
 * Triggered on text instance changes (also on add and remove)
 *
 * TODO: on grid change
 * TODO: on add
 *
 * @param {object} renderEngine - Render engine instance
 * @param {array} textInstaces - List of textlayers (from composition data)
 * @param {array} previousTextInstances - Textinstances before change that triggered update
 * @param {object} eventHandlers - Event handlers that gets bound
 */
const updateTextLayers = async (
  renderEngine,
  textInstances,
  previousTextInstances,
  eventHandlers,
) => {
  const { layers } = renderEngine;

  // Currentlayers is used to track non-dead layers, and other layers
  // are checked against it and removed if not present in it.
  const activeLayers = [];

  textInstances.forEach((instance) => {
    const compositionTextInstance = cloneDeep(instance);

    const [existingEngineLayer] = layers
      .filter(layer => layer.id === compositionTextInstance.id);

    const [prevCompTextInstance] = previousTextInstances
      .filter(layer => layer.id === compositionTextInstance.id);

    if (!existingEngineLayer) {
      activeLayers.push(instance.id);
      addSingleTextLayer(renderEngine, instance, eventHandlers);
      return;
    }

    const textStylesHaveChanged = !isEqual(
      prevCompTextInstance,
      compositionTextInstance,
    );

    if (textStylesHaveChanged) {
      // Position
      existingEngineLayer.textElement.left = compositionTextInstance.left;
      existingEngineLayer.textElement.top = compositionTextInstance.top;
      existingEngineLayer.textElement.text = compositionTextInstance.text;
      existingEngineLayer.textElement.scaleX = compositionTextInstance.scaleX;
      existingEngineLayer.textElement.scaleY = compositionTextInstance.scaleY;

      // Visibility
      existingEngineLayer.visibleFrom = compositionTextInstance.visibleFrom;
      existingEngineLayer.visibleTo = compositionTextInstance.visibleTo;
      existingEngineLayer.duration = compositionTextInstance.duration;

      // Styles
      existingEngineLayer.backgroundColor = compositionTextInstance.backgroundColor;
      existingEngineLayer.fill = compositionTextInstance.fill;
      existingEngineLayer.fontFamily = compositionTextInstance.fontFamily;
      existingEngineLayer.fontSize = compositionTextInstance.fontSize;
      existingEngineLayer.fontStyle = compositionTextInstance.fontStyle;
      existingEngineLayer.fontWeight = compositionTextInstance.fontWeight;
      existingEngineLayer.textAlign = compositionTextInstance.textAlign;
      existingEngineLayer.shadow = compositionTextInstance.shadow;
      existingEngineLayer.angle = compositionTextInstance.angle;
      existingEngineLayer.stroke = compositionTextInstance.stroke;
      existingEngineLayer.strokeWidth = compositionTextInstance.strokeWidth;

      // Need better handling for individual styles
      existingEngineLayer.styles = compositionTextInstance.styles;

      // Update also Fabric element attributes
      existingEngineLayer.textElement.fill = compositionTextInstance.fill;
      existingEngineLayer.textElement.backgroundColor = compositionTextInstance.backgroundColor;
      existingEngineLayer.textElement.fontFamily = compositionTextInstance.fontFamily;
      existingEngineLayer.textElement.fontSize = compositionTextInstance.fontSize;
      existingEngineLayer.textElement.shadow = compositionTextInstance.shadow;
      existingEngineLayer.textElement.stroke = compositionTextInstance.stroke;
      existingEngineLayer.textElement.angle = compositionTextInstance.angle;

      // Backwards compatible: Add 'normal' if undefined
      existingEngineLayer.textElement.fontStyle = compositionTextInstance.fontStyle ? compositionTextInstance.fontStyle : 'normal';
      existingEngineLayer.textElement.fontWeight = compositionTextInstance.fontWeight ? compositionTextInstance.fontWeight : 'normal';
      existingEngineLayer.textElement.textAlign = compositionTextInstance.textAlign;

      if (existingEngineLayer.split) {
        existingEngineLayer.generate();
      }
    }


    const animationsHaveChanged = !isEqual(
      prevCompTextInstance.animation,
      compositionTextInstance.animation,
    );

    if (animationsHaveChanged) {
      const addedNewAnimation = !prevCompTextInstance.animation &&
        compositionTextInstance.animation;

      const removedAnimation = prevCompTextInstance.animation &&
        !compositionTextInstance.animation;

      const changedAnimation = prevCompTextInstance.animation &&
        compositionTextInstance.animation;

      if (addedNewAnimation) {
        addAnimationsToLayer(compositionTextInstance, existingEngineLayer);
      } else if (removedAnimation) {
        // TODO: check opacity values when removing / or ff to save place
        removeAnimationsFromLayer(existingEngineLayer);
      } else if (changedAnimation) {
        modifyLayerAnimations(compositionTextInstance, existingEngineLayer);
      }
    }

    // Keep track of active layers so that later on
    // non active layers can be deleted safely
    activeLayers.push(existingEngineLayer.id);
  });

  removeDeletedLayers(renderEngine, layers, activeLayers);
  renderEngine.refresh();
};

const bringTextsFront = (renderEngine) => {
  renderEngine.layers
    .filter(layer => layer.type === 'text')
    .map(textLayer => textLayer.getAnimatableObject())
    .map(fabricObject => fabricObject.bringToFront());
};
const shouldExitTextEdit = (activeTextId, e) => {
  if (activeTextId &&
      !e.target.classList.contains('backgroundColorLayer') &&
      !e.target.classList.contains('backgroundColor') &&
      !e.target.classList.contains('text') &&
      !e.target.classList.contains('textInstanceEditor') &&
      !e.target.classList.contains('VideoPreview') &&
      !e.target.classList.contains('resizer')) return true;
  return false;
};
const shouldExitShapeEdit = (activeTextId, e) => {
  if (activeTextId &&
      !e.target.classList.contains('shapeLayer') &&
      !e.target.classList.contains('controls')) return true;
  return false;
};
const checkEventType = (e) => {
  const direction = (element) => {
    if (element.contains('resizer-br')) return 'both';
    if (element.contains('resizerH-right')) return 'horizontal-right';
    if (element.contains('resizerH-left')) return 'horizontal-left';
    if (element.contains('resizerV')) return 'vertical';
  };
  if (e.target.classList.contains('resizer-br') ||
      e.target.classList.contains('resizer-bl') ||
      e.target.classList.contains('resizer-tl') ||
      e.target.classList.contains('resizer-tr') ||
      e.target.classList.contains('resizerV')) {
    return {
      type: 'resizing',
      direction: direction(e.target.classList),
    };
  }
  if (e.target.classList.contains('rotateHandle')) {
    return {
      type: 'rotating',
      direction: direction(e.target.classList),
    };
  }
  if (e.target.classList.contains('resizerH-right') ||
        e.target.classList.contains('resizerH-left')) {
    return {
      type: 'smartResizing',
      direction: direction(e.target.classList),
    };
  }
  return {
    type: 'moving',
    direction: direction(e.target.classList),
  };
};

export {
  updateTextLayers,
  addSingleTextLayer,
  getNearestNonAnimatedTime,
  setTextAttribute,
  bringTextsFront,
  shouldExitTextEdit,
  shouldExitShapeEdit,
  checkEventType,
};
