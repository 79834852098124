import { useEffect, useRef, useState } from "react";

const POLLING_INTERVAL = 4000;

const VideoPoller = ({ S3Key }) => {
    const intervalRef = useRef();
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (S3Key) {
            pollVideo()
            intervalRef.current = setInterval(pollVideo, POLLING_INTERVAL)
            return () => {
                clearInterval(intervalRef.current);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [S3Key])
    async function pollVideo() {
        if (!data?.signedUrl) {
            setIsLoading(true)
            const response = await fetch(`https://video-engine.sumo.app/api/check-video-status?S3Key=${encodeURIComponent(S3Key)}`)
            const newData = await response.json();
            console.log({ newData, S3Key })
            if (newData.signedUrl) {
                clearInterval(intervalRef.current);
            }
            setData(newData)
            setIsLoading(false)
        }
    }
    if (isLoading || (data && !data.signedUrl)) {
        return <div className="py-4 text-amber-400">Rendering video...</div>;
    }
    if (data && data.signedUrl) {
        return (
            <div className="py-4">
                <a
                    className="text-teal-500"
                    href={data.signedUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    Link to video
                </a>
                <video width="320" height="240" controls>
                    <source type="video/mp4" src={data.signedUrl} />
                </video>
            </div>
        );
    }

    return null;
};

export default VideoPoller;
