/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types';
// import { FILESTACK_PUBLIC_API_KEY } from '@config';
// import ReactFilestack from 'filestack-react';
import Search from '@components/Search';
import ButtonGroup from '@components/ButtonGroup';
import Button from '@components/Button';
import Spinner from '@components/Spinner';
import ConfirmDialog from '@components/ConfirmDialog';
import classNames from 'classnames';
// import { UploadCloud } from '@components/Icons';
import InfiniteScroll from '@components/InfiniteScroll';
import ImageElements from './ImageElements';
import UploadSection from './Uploads';
import './ImagesTab.scss';
import { askForFile } from "../../lib/file";
import { useDispatch } from "react-redux";
import { updateApp } from "../../actions/app";

const propTypes = {
  filter: PropTypes.string,
  onFilterToggle: PropTypes.func.isRequired,
  onUploadsFilterToggle: PropTypes.func.isRequired,
  footagePages: PropTypes.array,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  searchTerm: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  activeUploads: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  onRemoveUploadImage: PropTypes.func,
  composition: PropTypes.object,
  onLoad: PropTypes.func.isRequired,
  onAddToFavorite: PropTypes.func.isRequired,
  onRemoveFromFavorite: PropTypes.func,
  onDownload: PropTypes.func.isRequired,
  onProgressJump: PropTypes.func,
  onSelect: PropTypes.func,
  expandPool: PropTypes.func,
  filterButtons: PropTypes.array,
  imageUploadOptions: PropTypes.object,
  formatBackgroundInstances: PropTypes.func,
};

const defaultProps = {
  footage: [],
  filter: 'stock',
  imageUploadOptions: {},
};

const ImagesTab = (props) => {
  const {
    footagePages,
    filter,
    onFilterToggle,
    onUploadsFilterToggle,
    onCancel,
    searchTerm,
    onLoad,
    onSearch,
    onUpload,
    loading,
    activeUploads,
    onAdd,
    handleAdd,
    onRemoveUploadImage,
    stockImagesLoaded,
    composition,
    onDownload,
    onAddToFavorite,
    onProgressJump,
    onRemoveFromFavorite,
    onSelect,
    filterButtons,
    updateFootage,
    imageUploadOptions,
    expandPool,
    formatBackgroundInstances,
  } = props;

  const [warning, setWarning] = useState({ visible: false, uid: null });
  const dispatch = useDispatch();

  const scrollToTop = () => {
    const list = document.querySelector('.ImagesTab__list');
    if (list) {
      list.scrollTo(0, 0);
    }
  };

  const handleFilterToggle = (selectedFilter) => {
    if (selectedFilter === 'image' || selectedFilter === 'video' || selectedFilter === 'logo') {
      onUploadsFilterToggle(selectedFilter);
    } else {
      onFilterToggle('imageFootageFilter', selectedFilter);
    }
  };

  const handleSearch = (value) => {
    const categoryName = null;
    const orientation = null;

    clearTimeout(window.keyboardTimer);
    window.keyboardTimer = setTimeout(() => {
      onSearch('image', value, filter, categoryName, orientation, 0);
    }, 500);
    scrollToTop();
  };

  const handleLoadMore = (page) => {
    console.log("infinite scroll DISABLED")
    // if (searchTerm && searchTerm !== '') {
    //   const categoryName = null;
    //   const orientation = null;
    //   onSearch('image', searchTerm, filter, categoryName, orientation, page + 1);
    // } else {
    //   const sorting = 'popular';
    //   onLoad(filter, page, sorting);
    // }
  };

  const spinnerClasses = classNames('Curtain', {
    'Curtain--loading': true,
  });
  async function handleImageUpload() {
    try {
      const files = await askForFile(".jpg,.png,.gif");
      let reader = new FileReader()
      reader.onload = (e) => {
        let mediaSrc = e.target.result
        console.log({ mediaSrc })
        const obj = {
          "id": 123123,
          "src": e.target.result,
          "top": -100.15429122468665,
          "left": 0,
          "maxX": 0,
          "maxY": 0,
          "minX": 0,
          "minY": -200.3085824493733,
          "type": "image",
          "color": "#94694D",
          "isFav": true,
          "likes": 3050,
          "lockX": true,
          "lockY": false,
          "width": 2074,
          "height": 1383,
          "scaleX": 0.9257473481195756,
          "scaleY": 0.9257473481195756,
          "duration": 1351,
          "kenburns": true,
          "playFrom": 0,
          "renderSrc": e.target.result,
          "thumbnail": {
            "hd": e.target.result,
            "nano": e.target.result,
            "small": e.target.result
          },
          "visibleTo": 7350,
          "favoriteId": nanoid(),
          "zoomEffect": {
            "X": 8,
            "Y": 4,
            "scale": 1.04
          },
          "visibleFrom": 5999,
          "scaleToWidth": true,
          "lockMovementX": true,
          "lockMovementY": false,
          "scaleToHeight": true,
          "thumbnailImageSrc": e.target.result
        }
        updateFootage(obj, "stockImages")

        console.log({ obj })
      }
      reader.readAsDataURL(files[0])
    } catch (err) {
      alert("Could not open Sumo file." + err)
    }
  }
  useEffect(() => {
    if (!stockImagesLoaded) {
      fetchImagesFromCMS()
    }
    return () => {
      dispatch(updateApp({
        stockImagesLoaded: true
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockImagesLoaded])
  async function fetchImagesFromCMS() {
    const response = await fetch("https://cms.sumo.app/api/projects/admins/pages?template=sample-asset");
    const data = await response.json();
    console.log(data.data.filter(media => media.data.asset.type.includes("image")))
    for (const media of data.data.filter(media => media.data.asset.type.includes("image"))) {
      const obj = {
        "id": nanoid(),
        "src": media.data.asset.url,
        "top": -100.15429122468665,
        "left": 0,
        "maxX": 0,
        "maxY": 0,
        "minX": 0,
        "minY": -200.3085824493733,
        "type": "image",
        "color": "#94694D",
        "isFav": true,
        "likes": 3050,
        "lockX": true,
        "lockY": false,
        "width": 2074,
        "height": 1383,
        "scaleX": 0.9257473481195756,
        "scaleY": 0.9257473481195756,
        "duration": 1351,
        "kenburns": true,
        "playFrom": 0,
        "renderSrc": media.data.asset.url,
        "thumbnail": {
          "hd": media.data.asset.url,
          "nano": media.data.asset.url,
          "small": media.data.asset.url
        },
        "visibleTo": 7350,
        "favoriteId": nanoid(),
        "zoomEffect": {
          "X": 8,
          "Y": 4,
          "scale": 1.04
        },
        "visibleFrom": 5999,
        "scaleToWidth": true,
        "lockMovementX": true,
        "lockMovementY": false,
        "scaleToHeight": true,
        "thumbnailImageSrc": media.data.asset.url
      }
      updateFootage(obj, "stockImages")
    };
    dispatch(updateApp({
      stockImagesLoaded: true
    }))
  }

  return (
    <div className="ImagesTab">
      {/* <div className='upload-asset-wrapper'>
        <button className='sumo-btn sumo-btn-video sumo-btn-colored' onClick={handleImageUpload}>
          Upload image
        </button>
        <p>or drag and drop here</p>
      </div> */}
      {/* <section className="VideosTab__heading--section">
        <ButtonGroup
          items={filterButtons}
          selected={filter}
          onSelect={handleFilterToggle}
          images={true}
        />
        {/* {(filter === 'upload' || filter === 'image' || filter === 'logo') && <ReactFilestack
            apikey={FILESTACK_PUBLIC_API_KEY}
            options={imageUploadOptions}
            onSuccess={filestackResponse => onUpload(filestackResponse, filter)}
            render={({ onPick }) => (
              <Button className="VideosTab__upload-btn" color="pink" onClick={onPick} withIcon={true}>
                Upload {filter}
                <UploadCloud />
              </Button>
            )}
          />} 
        <Search
          suggestions={[]}
          onSearch={value => handleSearch(value)}
          filter={filter}
          type='image'
          className="mt(0.5)"
          placeholder="Search from Unsplash"
        />
      </section> */}
      <section className="ImagesTab__list--section">
        {loading && (
          <div className={spinnerClasses}>
            <Spinner visible={true} />
          </div>
        )}


        <InfiniteScroll
          className="scroll-y ImagesTab__list"
          onLoadMore={handleLoadMore}
          page={footagePages.length}
        >
          {(filter !== 'stock' && filter !== 'favorite') &&
            (<UploadSection
              onUpload={onUpload}
              activeUploads={activeUploads}
              imageUploadOptions={imageUploadOptions}
            />)}
          <ImageElements
            expandPool={expandPool}
            imagePages={footagePages}
            filter={filter}
            onDownload={onDownload}
            onAdd={onAdd}
            handleAdd={handleAdd}
            onCancel={onCancel}
            onRemoveUploadImage={uid => setWarning({
              visible: true,
              uid,
            })}
            composition={composition}
            onSelect={onSelect}
            onAddToFavorite={filter === 'stock' && onAddToFavorite}
            onProgressJump={onProgressJump}
            formatBackgroundInstances={formatBackgroundInstances}
            onRemoveFromFavorite={filter === 'favorite' && onRemoveFromFavorite}
          />
        </InfiniteScroll>
        <ConfirmDialog
          visible={warning.visible}
          title={'Confirm deletion'}
          onClose={() => setWarning({ visible: false, uid: null })}
          cancelButton={
            <Button onClick={() => setWarning({ visible: false, uid: null })}>Cancel</Button>
          }
          confirmButton={
            <Button color="red" onClick={() => {
              onRemoveUploadImage(warning.uid);
              setWarning({ visible: false, uid: null });
            }}>
              Yes, delete my uploaded video.
            </Button>
          }
        >
          This action will delete your uploaded image. Undoing is not possible!
        </ConfirmDialog>
      </section>
    </div>
  );
};

ImagesTab.propTypes = propTypes;
ImagesTab.defaultProps = defaultProps;

export default ImagesTab;
