import React from 'react';
import PropTypes from 'prop-types';

import './Scrubber.scss';
import TimeTooltip from './TimeTooltip';

class Scrubber extends React.Component {
  constructor(props) {
    super(props);
    this.containerElement = React.createRef();
    this.state = {
      tooltipVisible: props.tooltipVisible || false,
      cursorTime: null,
    };
  }

  /**
   * Returns a offset that takes in account the parent elements offset.
   * This is used in case if padding is added around the element or at some point
   * its not full screen width anymore.
   */
  getAccurateOffset = (clientX) => {
    const containerOffset = this.containerElement.current.offsetLeft;
    return clientX - containerOffset;
  }
  // TODO:  already handled by Ticker component
  // handleProgressJump = (event) => {
    // console.log('jump click');
    // const { msPxRatio, onProgressJump } = this.props;
    //
    // const offset = this.getAccurateOffset(event.nativeEvent.clientX);
    //
    // const time = Math.round(offset / msPxRatio);
    // onProgressJump(time);
  // };

  handleMouseMove = (event) => {
    const { msPxRatio, duration, onProgressSeek } = this.props;
    const offset = this.getAccurateOffset(event.clientX);

    let time = Math.round(offset / msPxRatio);

    if (time < 0) {
      time = 0;
    }

    if (time > duration) {
      time = duration;
    }
    this.setState({ cursorTime: time * msPxRatio });
    const audioObj = window.audioTracks
      .find(clip => clip.audibleFrom <= time && clip.audibleTo >= time);
    if (audioObj) {
      audioObj.audioFile.currentTime = time / 1000;
      audioObj.audioFile.pause();
      audioObj.audioFile.play();
      setTimeout(() => { audioObj.audioFile.pause(); }, 300);
    }
    onProgressSeek(time);
  };

  handleSeekEnd = () => {
    setTimeout(() => {
      this.setState({
        tooltipVisible: false,
        cursorTime: null,
      });
    }, 500);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleSeekEnd);
  };

  handleSeek = (event) => {
    event.preventDefault();

    this.setState({
      tooltipVisible: true,
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleSeekEnd);
  };

  hideTimeTooltip = () => {
    this.setState({
      tooltipVisible: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tooltipVisible !== this.props.tooltipVisible) {
      this.setState({
        tooltipVisible: this.props.tooltipVisible,
      });
    }
  }

  render() {
    const {
      currentTime,
      msPxRatio,
      isPlaying,
      internalTime,
      duration,
    } = this.props;

    const { tooltipVisible, cursorTime } = this.state;

    const time = isPlaying ? internalTime : currentTime;
    const currentProgressWidth = time * msPxRatio;

    return (
      <div
        className="Scrubber"
        // TODO:  already handled by Ticker component
        // onClick={this.handleProgressJump}
        ref={this.containerElement}
      >
        <div className="Scrubber__Progress" style={{ width: `${cursorTime || currentProgressWidth}px` }}>
          <div
            className="PlayHead"
            onMouseDown={this.handleSeek}
            onMouseUp={this.hideTimeTooltip}
          >
            <div className="PlayHead__Controls">
              <TimeTooltip
                time={currentTime}
                visible={tooltipVisible}
                duration={duration}
              />
              <div className="PlayHead__Handle"/>
            </div>
            <div className="PlayHead__Line"/>
          </div>
        </div>
      </div>
    );
  }
}

Scrubber.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  msPxRatio: PropTypes.number,
  onProgressJump: PropTypes.func.isRequired,
  onProgressSeek: PropTypes.func.isRequired,
  tooltipVisible: PropTypes.bool,
  isPlaying: PropTypes.bool,
  internalTime: PropTypes.number,
};

export default Scrubber;
