import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AspectRatioOption.scss';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['horizontal', 'square', 'vertical', 'facebook_vertical']).isRequired,
  selected: PropTypes.bool,
  description: PropTypes.string,
};

const defaultProps = {
  selected: false,
};

const AspectRatioOption = (props) => {
  const classes = classNames(
    `AspectRatioOption__option ${props.type}`,
    {
      selected: props.selected,
    },
  );

  return (
    <div
      className={classes}
      onClick={() => props.onSelect(props.type)}
    >
      <div className="AspectRatioOption__content">
        <div className="visual"></div>
        <div className="AspectRatioOption__details">
          <p className="AspectRatioOption__label">{props.label}</p>
          <p className="AspectRatioOption__description">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

AspectRatioOption.propTypes = propTypes;
AspectRatioOption.defaultProps = defaultProps;

export default AspectRatioOption;
