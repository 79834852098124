import { modifySourceItem } from './backgroundInstance';

// updates background instances for reversioned duplicates

const updateBgInstancesForNewVersion = (bgInstances, aspectRatio) => bgInstances.map((instance) => {
  const modified = modifySourceItem(instance, aspectRatio);

  return {
    ...modified,
    id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
  };
});

// updates text instances for reversioned duplicates

const updateTextInstancesForNewVersion = (
  textInstances, aspectRatio, prevAspectRatio,
) => textInstances.map(
  text => ({
    ...text,
    top: text.top * (aspectRatio.height / prevAspectRatio.height),
    left: text.left * (aspectRatio.width / prevAspectRatio.width),
    scaleX: text.scaleX * (aspectRatio.width / prevAspectRatio.width),
    scaleY: text.scaleY * (aspectRatio.width / prevAspectRatio.width),
    id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
  }),
);
// updates text instances for reversioned duplicates

const updateShapeInstancesForNewVersion = (
  shapeInstances, aspectRatio, prevAspectRatio,
) => {
  const getShapeScale = shape => ({
    ...shape,
    top: shape.top * (aspectRatio.height / prevAspectRatio.height),
    left: shape.left * (aspectRatio.width / prevAspectRatio.width),
    scaleX: shape.scaleX ?
      shape.scaleX * (aspectRatio.width / prevAspectRatio.width) :
      (aspectRatio.width / prevAspectRatio.width),
    scaleY: shape.scaleY ?
      shape.scaleY * (aspectRatio.width / prevAspectRatio.width) :
      (aspectRatio.width / prevAspectRatio.width),
  });

  return shapeInstances.map(
    shape => ({
      ...getShapeScale(shape),
      id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
    }),
  );
};


// updates layers for reversioned duplicates

const updateLayersForNewVersion = (layers, aspectRatio, prevAspectRatio) => layers.map(
  layer => ({
    ...layer,
    top: layer.top * (aspectRatio.height / prevAspectRatio.height),
    left: layer.left * (aspectRatio.width / prevAspectRatio.width),
    scaleX: layer.scaleX * (aspectRatio.width / prevAspectRatio.width),
    scaleY: layer.scaleY * (aspectRatio.width / prevAspectRatio.width),
  }),
);


const getReversionedComposition = (composition, prevAspectRatio) => {
  const {
    backgroundInstances,
    width,
    height,
    textInstances,
    shapeInstances,
    layers,
  } = composition;

  const reversionBgInstances = updateBgInstancesForNewVersion(
    backgroundInstances, { width, height },
  );

  const reversionTextInstances = updateTextInstancesForNewVersion(textInstances,
    { width, height }, prevAspectRatio);

  // TODO: scaling shape instances should consider scalings of text bgInstances
  // that way we could be able to scale shapes while keeping design with text

  const reversionShapeInstances = updateShapeInstancesForNewVersion(shapeInstances || [],
    { width, height }, prevAspectRatio);

  const reversionLayers = updateLayersForNewVersion(layers,
    { width, height }, prevAspectRatio);


  return {
    ...composition,
    backgroundInstances: reversionBgInstances,
    textInstances: reversionTextInstances,
    shapeInstances: reversionShapeInstances,
    layers: reversionLayers,
  };
};

export {
  getReversionedComposition,
};
