import React from 'react';
import PropTypes from 'prop-types';

import './ProjectActionsMenu.scss';

const propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickRename: PropTypes.func.isRequired,
};

const defaultProps = {};

class ProjectActionsMenu extends React.Component {
  state = {}

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.eventListener = document.addEventListener('click', this.handle);
  }

  componentWillUnmount() {
    if (this.eventListener) {
      document.removeEventListener('click', this.eventListener);
    }
  }

  handle = (event) => {
    const { onClickOutside } = this.props;

    if (this.containerRef &&
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      onClickOutside();
    }
  }

  handleDelete = () => {
    const { onClickDelete } = this.props;
    onClickDelete();
  }
  handleRename = () => {
    const { onClickRename } = this.props;
    onClickRename();
  }

  render() {
    return (
      <div
        className="ProjectActionsMenu"
        ref={this.containerRef}
      >
        <div
          className="ProjectActionsMenu__option"
          onClick={this.handleDelete}
        >
          Delete
        </div>
        <div
          className="ProjectActionsMenu__option"
          onClick={this.handleRename}
        >
          Rename
        </div>
      </div>
    );
  }
}

ProjectActionsMenu.propTypes = propTypes;
ProjectActionsMenu.defaultProps = defaultProps;

export default ProjectActionsMenu;
