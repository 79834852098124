/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import Button from '@components/Button';
import InputNumber from '@components/InputNumber';
import PositionGrid from '@components/PositionGrid';
import VideoContext from '@components/Timeline/VideoContext';
import { getMaxDuration } from '@constants/composition';
import { determineCanvasType, getVideoPosition, modifySourceItem } from '@helpers/backgroundInstance';
import { scaleForContain } from '@helpers/sidebar';
import { renderHelper } from '@lib/renderHelper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import {
  TrashCan, VideoScaleContain,
  VideoScaleCover
} from '@components/Icons';

const propTypes = {
  activeBackgroundId: PropTypes.number,
  onRemove: PropTypes.func,
  onVideoResize: PropTypes.func,
  onClose: PropTypes.func,
  onMove: PropTypes.func,
  onScale: PropTypes.func,
  onShowContext: PropTypes.func,
  onTriggerNotification: PropTypes.func,
  backgroundInstances: PropTypes.array,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  showVisibilityHandler: PropTypes.bool,
  showContext: PropTypes.bool,
  onDuplicate: PropTypes.func,
  videoContextVisible: PropTypes.bool,
  onAddToFavorite: PropTypes.func.isRequired,
  onRemoveFromFavorite: PropTypes.func.isRequired,
  onToggleClipAudio: PropTypes.func,
  enableClipAudio: PropTypes.bool,
  onUpdateTimestamps: PropTypes.func,
  onShowVisiblityHandler: PropTypes.func,
  onModify: PropTypes.func,
  duration: PropTypes.number,
};

let scrollInitialized = false;

const ToolbarBackground = ({
  backgroundInstances,
  onClose,
  onMove,
  onRemove,
  onVideoResize,
  onScale,
  activeBackgroundId,
  onTriggerNotification,
  compositionWidth,
  compositionHeight,
  onShowContext,
  onDuplicate,
  duration,
  onAddToFavorite,
  onToggleClipAudio,
  enableClipAudio,
  showContext,
  onUpdateTimestamps,
  onModify,
}) => {
  const video = backgroundInstances &&
    backgroundInstances.find(instance => instance.id === activeBackgroundId);



  const prevBgCountRef = useRef();
  const prevBgIdRef = useRef();

  useEffect(() => {
    if (video) {
      const bgType = video.type;
      if (bgType === 'video') {
        if ((!prevBgCountRef.current || prevBgCountRef.current < backgroundInstances.length)
          || (showContext && prevBgIdRef.current === video.id)) {
          onShowContext(true);
        } else {
          onShowContext(false);
        }
      } else {
        onShowContext(false);
      }
      prevBgIdRef.current = video.id;
    }
    prevBgCountRef.current = backgroundInstances.length;
  });

  const [instancePos, setPosition] = useState();
  const [newDuration, setNewDuration] = useState(null);
  const [screenWidth, updateScreenWidth] = useState(window.innerWidth);
  const [dropDowns, toggleDropDowns] = useState(null);

  const initialInput = video && Number(video.duration / 1000).toFixed(1);
  const notifications = {
    maxExceeded: 'The output exceeded the length of the clip',
    minDuration: 'The minimum duration of a clip is 1 sec',
  };

  const handleResize = () => {
    updateScreenWidth(window.innerWidth);
  };

  const handleResizeDirection = (resizeDirection, instance) => {
    this.stopPlaying();
    this.setState({
      resizeDirection,
    });
    this.selectInstance(instance);
    document.addEventListener('mousemove', this.showResizingTooltip);
    this.showResizingTooltip.instance = instance;
    this.showResizingTooltip.resizeDirection = resizeDirection;
    document.addEventListener('mouseup', this.handleResizeStop);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    const compositionDimention = { compositionWidth, compositionHeight };
    setPosition(getVideoPosition(video, compositionDimention));
  });
  if (!activeBackgroundId || !video) {
    onClose();
    return null;
  }
  const sidebarContextOverlay = document.querySelector('.SidebarContextOverlay');
  if (sidebarContextOverlay) {
    setTimeout(() => {
      if (!scrollInitialized) {
        sidebarContextOverlay.scrollTop = 1;
        scrollInitialized = true;
      }
    }, 3000);
  }
  const figureoutScaleMode = () => {
    if (!video) {
      return null;
    }
    if (
      (video.width * video.scaleX) > compositionWidth ||
      (video.height * video.scaleY) > compositionHeight
    ) {
      return 'cover';
    }

    return 'contain';
  };

  const contextTrimmer = (backgrounds, resize, show, width) => {
    const bgInstance = backgrounds.find(instance => instance.id
      === activeBackgroundId);
    if (bgInstance) {
      return (
        <VideoContext
          show={show}
          instance={bgInstance}
          onResize={onVideoResize}
          onResizeStart={handleResizeDirection}
        />
      );
    }
    return null;
  };

  const handleImageScaleChange = (event) => {
    let item = renderHelper.engine.layers.find(layer => layer.id === activeBackgroundId);
    if (!item) return;
    item = item.object;
    const orientation = determineCanvasType(compositionWidth, compositionHeight);

    // Setting up scale for, "contain scaling"
    if (event === 'contain') {
      const dimensions = { orientation, compositionWidth, compositionHeight };
      item = scaleForContain(item, dimensions);
      if (orientation === 'square') {
        item = modifySourceItem(item, { width: compositionWidth, height: compositionHeight }, true);
      }
    }

    // Setting up scale for  "cover scaling"
    if (event === 'cover') {
      item = modifySourceItem(item, { width: compositionWidth, height: compositionHeight });
    }

    onScale(item, activeBackgroundId);
  };


  const handleVideoMove = (position) => {
    const { left, top } = position;
    onMove(activeBackgroundId, left, top);
  };

  const handleDelete = () => {
    backgroundInstances.forEach((instance) => {
      if (instance.id === activeBackgroundId) {
        onRemove(instance.id);
        onShowContext(false);
        onClose();
      }
    });
    return false;
  };
  const handleAddToFavorite = () => {
    backgroundInstances.forEach((instance) => {
      if (instance.id === activeBackgroundId) {
        onAddToFavorite(instance, instance.type);
      }
    });
    return false;
  };

  const handleVideoHide = (e) => {
    onShowContext(e.target.checked);
    e.target.blur();
  };

  const handleDuplicate = () => {
    onDuplicate([activeBackgroundId]);
  };

  const handleToggleClipAudio = (e) => {
    onToggleClipAudio(activeBackgroundId);
    e.target.blur();
  };

  const scale = figureoutScaleMode();

  const fadeInEnabled = video && video.animationIn ? video.animationIn : false;
  const fadeOutEnabled = video && video.animationOut ? video.animationOut : false;
  const kenburnsEnabled = video && video.kenburns ? video.kenburns : false;

  const handleToggleFadeIn = (e) => {
    e.target.blur();
    onModify({
      ...video,
      animationIn: (video && !video.animationIn) || false,
    }, video.id);
  };

  const handleToggleFadeOut = (e) => {
    e.target.blur();
    onModify({
      ...video,
      animationOut: (video && !video.animationOut) || false,
    }, video.id);
  };

  const handleToggleKenburns = (e) => {
    e.target.blur();
    onModify({
      ...video,
      kenburns: !video.kenburns || false,
    }, video.id);
  };

  const maxDuration = video &&
    (video.sourceDuration !== undefined) ? video.sourceDuration : getMaxDuration();
  const minDuration = 1000;
  const onBlurFunction = () => {
    const duration = newDuration !== null ? newDuration : video.duration;
    const newValue = (duration < maxDuration) ? duration : maxDuration;
    const exceededMaxDuration = video.playFrom + duration > maxDuration;
    const checkedForLimit = exceededMaxDuration ? maxDuration - video.playFrom : newValue;
    onUpdateTimestamps({
      visibleFrom: video.visibleFrom,
      visibleTo: video.visibleFrom + checkedForLimit,
      duration: checkedForLimit,
    }, video.id);
  };

  const onChangeFunction = (e) => {
    const setDuration = Number(e.target.value).toFixed(1) * 1000;
    setNewDuration(setDuration);
  };

  const onClickSize = (type) => {
    if (video.type === 'video') {
      handleImageScaleChange(type);
    } else {
      handleImageScaleChange(type);
    }
  };

  const textStylesGroup = screenWidth < 1360 ? 'Toolbar__dropDown row' : 'ToolbarGroup';
  const outerStylesDiv = screenWidth ? 'ToolbarGroup' : '';

  return (
    <Fragment>
      <div className="ToolbarGroup Thumbnail">
        {/* <div className="ToolbarThumbnail">
          <img style={{ height: '100%', width: 'auto' }} src={video && video.thumbnail.hd} />
          <Button className='ContextSettingsGrid_addToFavorite'
            onClick={() => handleAddToFavorite()} style={{ outline: 'none', transform: 'scale(.6)' }} tabIndex='-1'>
            <FavoriteIcon />
          </Button>
        </div> */}
      </div>
      <div className="ToolbarGroup">
        <div className="ToolbarItem duration">
          <span style={{ fontSize: '12px' }}>Duration</span>
          <InputNumber
            onBlurFunction={onBlurFunction}
            onChangeFunction={onChangeFunction}
            initialInput={initialInput}
            max={maxDuration - video.playFrom}
            min={minDuration}
            notifications={notifications}
            onTriggerNotification={onTriggerNotification}
          />
        </div>
        {/* {video.type === 'video' && <div className="ToolbarItem Switch">
          <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
            <FormControlLabel
              control={<Switch size="small" checked={showContext} onChange={handleVideoHide} />}
              label="Visual trimmer"
            />
          </FormGroup>
        </div>} */}
        {!showContext && <div className={outerStylesDiv}>
          {screenWidth < 1360 && <div className="ToolbarItem duration" onClick={() => {
            if (dropDowns !== 'animations') {
              toggleDropDowns('animations');
            } else {
              toggleDropDowns('');
            }
          }}>
            <Button
              iconBtn={true}
            >
              <span style={{ fontSize: '12px', position: 'relative', bottom: '2px' }}>Animations</span>
            </Button>
          </div>}
          {(screenWidth > 1360 || (screenWidth < 1360 && dropDowns === 'animations')) && <div className={textStylesGroup}>
            <div className="ToobarItem">
              <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
                <FormControlLabel
                  control={<Switch size="small" checked={fadeInEnabled} onChange={handleToggleFadeIn} />}
                  label="Fade-in"
                />
                <FormControlLabel
                  control={<Switch size="small" checked={fadeOutEnabled} onChange={handleToggleFadeOut} />}
                  label="Fade-out"
                />
                {video && video.type === 'image' && <Fragment>
                  <FormControlLabel
                    control={<Switch size="small" checked={kenburnsEnabled} onChange={handleToggleKenburns} />}
                    label="Toggle Zoom"
                  />
                </Fragment>}
              </FormGroup>
            </div>
          </div>}
        </div>}
      </div>
      {/* {showContext && <div className="ToolbarGroup TrimmerGroup">
        <div className="ToolbarItem trimmer">
          <div className={'videoRow-overlay'}>
            {activeBackgroundId &&
              contextTrimmer(backgroundInstances, onVideoResize, showContext)}
          </div>
        </div>
      </div>} */}
      {/* {video && video.audioSrc && !showContext && <div className="ToolbarGroup">
        <Fragment>
          <FormControlLabel
            control={<Switch size="small" checked={enableClipAudio} onChange={handleToggleClipAudio} />}
            label="Enable audio"
          />
        </Fragment>
      </div>} */}
      {(video.width !== compositionWidth && video.height !== compositionHeight) && !showContext && <div className="ToolbarGroup">
        <div className="ToolbarItem" onClick={() => {
          onClickSize('cover');
          if (video.kenburns) {
            onTriggerNotification({
              kind: 'warning', // success, info, warning, danger
              message: 'zoom effect was turned off',
            });
          }
        }} >
          <VideoScaleCover />
        </div>
        <div className="ToolbarItem" onClick={() => {
          onClickSize('contain');
          if (video.kenburns) {
            onTriggerNotification({
              kind: 'warning', // success, info, warning, danger
              message: 'zoom effect was turned off',
            });
          }
        }} >
          <VideoScaleContain />
        </div>
        {!video.kenburns && <div>
          <PositionGrid
            onSelect={handleVideoMove}
            activeText={activeBackgroundId}
            context={'video'}
            lockX={video.lockX}
            compositionWidth={compositionWidth}
            compositionHeight={compositionHeight}
          />
        </div>}
      </div>}
      <section className="Toolbar__right">
        <div className="ToolbarGroup CopyAndDelete">
          <div className="ToolbarItem">
            <Button iconBtn={true} onClick={handleDuplicate}><span style={{ fontSize: '12px' }}>Duplicate</span></Button>
          </div>
          <div className="ToolbarItem delete" onClick={() => handleDelete()}>
            <Button iconBtn={true}>
              <TrashCan />
            </Button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

ToolbarBackground.propTypes = propTypes;
export default ToolbarBackground;
