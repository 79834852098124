import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

const propTypes = {
  children: PropTypes.element,
  type: PropTypes.string,
  previewSrc: PropTypes.string,
  isDragging: PropTypes.bool,
  onDragStart: PropTypes.func,
  expandPool: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  canvas: PropTypes.object,
  backgroundInstances: PropTypes.array,
  formatBackgroundInstances: PropTypes.func,
};

const DraggableFootage = (props) => {
  // initial state/specification object used for useDrag
  const dragOptions = {
    item: {
      // Required param. properties describing the item being dragged.
      ...props,
    },
    begin: () => {
      // Required. When the dragging starts, begin is called.
      if (props.type === 'text' || props.type === 'shape') {
        props.expandPool(0);
      } else if (props.type === 'video') {
        props.expandPool(1);
      }
      // props.onDragStart(true);
    },
    end: (item, monitor) => {
      // Optional. When the dragging stops, end is called.
      // const dropResult = monitor.getDropResult();
      if (!monitor.didDrop()) {
        if (props.list === 'gallery' && (props.type === 'video' || props.type === 'image')) {
          props.formatBackgroundInstances(props.backgroundInstances);
        }
      }
    },
    // Define collectedProps that are passed to the component via props
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  };

  const [collectedProps, drag, connectDragPreview] = useDrag(dragOptions);

  const {
    type,
    children,
    // previewSrc
  } = props;

  const { isDragging } = collectedProps;

  const classes = classNames('DraggableFootage', {
    'DraggableFootage--isDragging': isDragging,
  });

  // useEffect below seems out of place in this component, remove after timeline rework
  // it is used in TextTab to generates dragpreview
  useEffect(() => {
    connectDragPreview(getEmptyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={classes}
      style={{ height: `${type === 'text' ? props.canvas.height / 15 : 90}px` }}
      ref={drag}
    >
      {children}
    </div>
  );
};

DraggableFootage.propTypes = propTypes;

function DraggableTextFootage(props) {
  return DraggableFootage(props)
}

function DraggableVideoFootage(props) {
  return DraggableFootage(props)
}

function DraggableImageFootage(props) {
  return DraggableFootage(props)
}

function DraggableShapeFootage(props) {
  return DraggableFootage(props)
}

// TODO:  clean the following code in each component that uses it. export only DraggableFootage.
// export default DragSource('footage', draggableFootageSource, sourceCollect)(DraggableFootage);
export {
  DraggableFootage,
  DraggableTextFootage,
  DraggableVideoFootage,
  DraggableImageFootage,
  DraggableShapeFootage
};
