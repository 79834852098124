import { seekTimelines } from '@lib/animeTimelines';
import SimpleLayer from './SimpleLayer';

export default class VideoLayer extends SimpleLayer {
  playing = false
  playPromise = null

  constructor(
    object,
    {
      visibleFrom,
      visibleTo,
      playFrom = 0,
      id,
    } = {},
    eventHandlers = {},
  ) {
    super(object, { visibleFrom, visibleTo });
    this.playFrom = playFrom;
    this.currentTime = playFrom;
    this.eventHandlers = eventHandlers;
    this.object = object;
    this.id = id;
    this.audioPlaying = false;
    this.bindEventHandlers(eventHandlers);
  }

  get videoElement() {
    return this.object.getElement();
  }

  get currentTime() {
    return this.videoElement.currentTime * 1000;
  }

  set currentTime(value) {
    if (this.currentTime === value) return;

    this.canPlay = new Promise((resolve) => {
      const { videoElement } = this;
      if (Object.entries(videoElement).length === 0 && videoElement.constructor === Object) return;
      const fn = () => {
        videoElement.removeEventListener('canplaythrough', fn);
        resolve();
      };
      videoElement.addEventListener('canplaythrough', fn);
    });

    this.canSee = new Promise((resolve) => {
      // NOTE:  removing event listners https://stackoverflow.com/questions/10444077/javascript-removeeventlistener-not-working
      const { videoElement } = this;
      if (Object.entries(videoElement).length === 0 && videoElement.constructor === Object) return;
      const seeked = () => {
        videoElement.removeEventListener('seeked', seeked);
        resolve();
      };

      videoElement.addEventListener('seeked', seeked);
    });
    this.videoElement.currentTime = value / 1000;
  }

  update(timestamp) {
    super.update(timestamp);

    if (!this.playing && timestamp >= this.visibleFrom && timestamp <= this.visibleTo) {
      this.playPromise = this.play();
    }

    if (this.playing && timestamp > this.visibleTo) {
      this.pause();
    }
  }

  seek(timestamp) {
    super.seek(timestamp);
    seekTimelines(timestamp);
    const audioClip = window.audioTracks.find(clip => clip.id === this.id);

    if (this.object.visible) {
      this.currentTime = ((timestamp - this.visibleFrom) + this.playFrom);
      return this.canSee;
    }

    this.currentTime = this.playFrom;
    if (audioClip) audioClip.audioFile.currentTime = timestamp / 1000;
    return Promise.resolve();
  }

  play() {
    const audioClip = window.audioTracks.find(clip => clip.id === this.id);
    this.playing = true;
    if (this.videoElement) {
      this.videoElement.play();
    }
    if (audioClip) {
      audioClip.audioFile.currentTime = this.currentTime / 1000;
      audioClip.audioFile.play();
      this.audioPlaying = true;
    }
  }

  async pause() {
    if (!this.playing) {
      return;
    }
    const audioClip = window.audioTracks.find(clip => clip.id === this.id);

    this.playing = false;
    await this.playPromise;
    if (this.videoElement) {
      this.videoElement.pause();
    }
    const { currentTime } = this;
    this.videoElement.load();
    this.currentTime = currentTime;
    if (audioClip) {
      audioClip.audioFile.pause();
      audioClip.audioFile.currentTime = currentTime;
      this.audioPlaying = false;
    }
  }

  ready = () => this.canPlay;

  bindEventHandlers(eventHandlers) {
    const {
      modified,
      selected,
    } = eventHandlers;

    if (selected && selected instanceof Function) {
      this.object.on('selected', selected);
    }

    if (modified && modified instanceof Function) {
      this.object.on('modified', modified);
    }
  }
}
