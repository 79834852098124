import React from 'react';
import { connect } from 'react-redux';
import {
  timelineProgressJump,
  requestSeek,
  timelineProgressSeek,
  timelineTogglePlay,
} from '@actions/timeline';

import VideoControls from '@components/VideoControls';

const VideoControlsContainer = props => <VideoControls {...props} />;

const mapStateToProps = (state) => {
  const {
    duration,
    backgroundInstances,
  } = state.composition.present;
  const { timeline } = state;
  return {
    compositionDuration: duration,
    timeline,
    backgroundInstances,
  };
};

export default connect(mapStateToProps, {
  onProgressJump: timelineProgressJump,
  onProgressSeek: timelineProgressSeek,
  timelineTogglePlay,
  requestSeek,
})(VideoControlsContainer);
