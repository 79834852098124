import { DndProvider } from "react-dnd";
import Backend from 'react-dnd-html5-backend';
import VideoPreviewContainer from '@containers/VideoPreviewContainer';
import { useEffect, useRef, useState } from "react";
import Timeline from "../Timeline";
import { sampleComposition } from "../../mockdata";
import { Pause, Play, XCircle } from "react-feather";
import { connect } from "react-redux";
import { updateApp } from "../../actions/app";
import { loadCompositionObject } from "../../actions/composition";

function VideoPlayer({
    composition,
    loadCompositionObject,
    updateApp
}) {
    // eslint-disable-next-line no-unused-vars
    const [metadata, setMetadata] = useState({ duration: 0, currentTime: 0, isPlaying: false })
    const [bottomBarVisible, setBottomBarVisible] = useState(true)
    const intervalRef = useRef();
    const bottomBarTimeout = useRef();
    useEffect(() => {
        setTimeout(() => {
            loadCompositionObject(composition.present)
            updateMetadata()
        }, 100);
    }, [])
    useEffect(() => {
        window.addEventListener("keydown", handleKey)
        return () => {
            window.removeEventListener("keydown", handleKey)
        }
    }, [metadata.isPlaying])
    function handleKey(e) {
        if (e.code === "Space") {
            if (metadata.isPlaying) {
                onPause()
            } else {
                onPlay()
            }
        }
    }
    function updateMetadata(forcePlay) {
        setMetadata({
            duration: window.engine.duration,
            currentTime: window.engine.currentTimestamp,
            isPlaying: forcePlay || window.engine.playing
        })
    }
    function onPlay() {
        window.engine.play()
        updateMetadata(true)
        intervalRef.current = setInterval(() => {
            updateMetadata()
        }, 1000);
        bottomBarTimeout.current = setTimeout(() => {
            setBottomBarVisible(false)
        }, 1000);
    }
    function onPause() {
        window.engine.pause();
        clearInterval(intervalRef.current);
        setMetadata(prev => ({ ...prev, isPlaying: false }))
    }
    function onClose() {
        onPause()
        setTimeout(() => {
            updateApp({
                isRendering: false
            })
        }, 10);
    }
    return (

            <div id="render-video-wrapper"
                style={{ width: "100%", height: "100%" }}>
                <div id="render-engine-playback-buttons">
                    <div id="render-engine-progress-bar"
                        style={{
                            width: `${metadata.currentTime / metadata.duration * 100}%`,

                        }}
                    />
                    <button id="render-engine-play" onClick={!metadata.isPlaying ? onPlay : onPause}>
                        {
                            !metadata.isPlaying ?
                                <Play />
                                :
                                <Pause />
                        }
                    </button>
                    <button id="video-player-close-btn" onClick={onClose}>
                        <XCircle />
                    </button>
                </div>
                <VideoPreviewContainer />
        </div>
    )
}

const mapStateToProps = ({ composition }) => {
    return {
        composition
    };
};

export default connect(mapStateToProps, { updateApp, loadCompositionObject })(VideoPlayer);