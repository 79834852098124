import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Modal from '@components/Modal';
import Button from '@components/Button';

import './MoveCompositionModal.scss';

const propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
};

const projectsDropdownStyles = {
  control: provided => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0.025)',
    border: '1px solid rgba(255, 255, 255, 0.025)',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#fff',
  }),
  option: provided => ({
    ...provided,
    color: '#000',
  }),
  input: provided => ({
    ...provided,
    color: '#fff',
  }),
};

export default function MoveCompositionModal(props) {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedProject(selectedOption.value);
  };

  const handleMove = () => {
    props.onMove(selectedProject);
  };

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      title={'Move video'}
    >
      <span className="MoveDialog__label">Destination</span>
      <Select
        options={props.projects}
        styles={projectsDropdownStyles}
        onChange={handleChange}
      />
      <div className="MoveDialog__button-container">
        <Button onClick={props.onClose}>cancel</Button>
        <Button
          onClick={handleMove}
          color="pink"
          loading={props.loading}
        >
          Move
        </Button>
      </div>
    </Modal>
  );
}

MoveCompositionModal.propTypes = propTypes;
