import { seekTimelines } from '@lib/animeTimelines';

const ANIMATABLE_PROPS = ['top', 'left', 'opacity']
// const ANIMATABLE_COORDS = ['top', 'left'];

export default class Layer {
  animations = []
  state = null
  renderEngine = null
  animating = false

  constructor({ visibleFrom = null, visibleTo = null, type } = {}) {
    this.visibleFrom = visibleFrom
    this.visibleTo = visibleTo
    this.type = type;

    // TODO: update state when moving object
    // this.storeState()
  }

  getObjects() {
    return []
  }

  getAnimatableObject() {
    return null
  }

  clearAnimations() {
    this.animations = []
    this.restoreState();
  }

  addAnimation(animation) {
    this.animations.push(animation)
  }

  storeState() {
    const object = this.getAnimatableObject()
    this.state = ANIMATABLE_PROPS.reduce((acc, prop) => Object.assign(acc, { [prop]: object[prop] }), {})
  }

  restoreState() {
    const object = this.getAnimatableObject()
    for (const prop in this.state) {
      object[prop] = this.state[prop]
    }
  }

  updateState(timestamp) {
    const visible = (this.visibleFrom === null || timestamp >= this.visibleFrom) &&
      (this.visibleTo === null || timestamp < this.visibleTo)

    for (const object of this.getObjects()) {
      object.visible = visible
    }
    this.animating = false
    seekTimelines(timestamp);
    const animatableObject = this.getAnimatableObject()
    if (this.animations.length > 0 && animatableObject) {
      if (!this.state) {
        this.storeState()
      } else {
        this.restoreState()
      }

      for (const animation of this.animations) {
        this.animating = animation.update(animatableObject, timestamp) || this.animating
      }
    }
  }

  update(timestamp) {
    this.updateState(timestamp)
  }

  seek(timestamp) {
    this.updateState(timestamp)
  }

  async ready() {
    return true
  }
}
