import * as types from '@constants/actionTypes';

const initialState = {
  processing: [],
  isDeleting: false,
};

const getType = (mimetype) => {
  if (mimetype.includes('video')) {
    return 'video';
  }

  if (mimetype.includes('image')) {
    return 'image';
  }

  return 'audio';
};

export default function upload(state = initialState, action) {
  switch (action.type) {
    case types.UPLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        processing: [
          ...state.processing,
          ...action.payload.filestackResponse.filesUploaded.map(file => ({
            type: getType(file.mimetype),
            uploadId: file.uploadId,
            percent: 0,
          })),
        ],
      };
    case types.UPLOAD_SOCKET_PROGRESS_UPDATE:
      return {
        ...state,
        processing: state.processing.map((file) => {
          if (file.uploadId !== action.payload.uploadId) return file;

          return {
            ...file,
            percent: action.payload.percent,
          };
        }),
      };
    case types.UPLOAD_SOCKET_READY:
      return {
        ...state,
        processing: state.processing.filter(file => file.uploadId !== action.payload.uploadId),
      };
    case types.UPLOAD_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.UPLOAD_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
