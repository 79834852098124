import { RESIZE_CONSTRAINTS } from '@constants/timeline';
import { renderHelper } from '@lib/renderHelper';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Resizable from 're-resizable';
import React, { useEffect, useState } from 'react';
import InstanceBackground from './InstanceBackground';
import './VideoContext.scss';

const VideoContext = ({
  instance, onResize, show,
}) => {
  const commonStyle = {
    height: '36px',
    display: show ? 'block' : 'none',
  };
  const [resizing, setResizing] = useState(false);
  const [delta, setDelta] = useState(0);
  const [maxWidth, setMaxWidth] = useState(undefined);
  const [startX, setStartX] = useState(null);
  const [diff, setDiff] = useState(null);
  const [width, setWidth] = useState(0);
  const [msPxRatio, setMsPxRatio] = useState(0);
  const [overlayBounds, setOverlayBounds] = useState({
    timelineLeft: null,
    timelineWidth: null,
    overlayLeft: null,
    overlayWidth: null,
  });
  const {
    thumbnail, sourceDuration, duration, playFrom, id, visibleFrom, visibleTo,
  } = instance;
  let throttleTimer;


  // eslint-disable-next-line
  useEffect(() => {
    const trimmerElement = document.getElementsByClassName('videoRow-overlay')[0];
    const trimmerElementWidth = trimmerElement ? trimmerElement.clientWidth : 0;
    if (trimmerElementWidth !== width) setWidth(trimmerElementWidth);
    const newMsPxRatio = width / sourceDuration;
    if (trimmerElementWidth !== setMsPxRatio) setMsPxRatio(newMsPxRatio);
  });
  if (instance.type !== 'video') return null;

  const getMaxWidth = (direction) => {
    if (direction === 'left') {
      const calculateWidth = (duration + playFrom) * msPxRatio;
      setMaxWidth(Math.floor(calculateWidth));
      return;
    }

    const calculateWidth = sourceDuration ? (sourceDuration - playFrom) * msPxRatio : undefined;
    setMaxWidth(calculateWidth);
  };

  const handleResizeStop = ({ dir, del }) => {
    const deltaX = del.width;
    const inPoint = dir === 'left' ? Math.round(visibleFrom - (deltaX / msPxRatio)) : visibleFrom;
    const outPoint = dir === 'right' ? Math.round(visibleTo + (deltaX / msPxRatio)) : visibleTo;
    const startPoint = dir === 'left' || dir === 'move' ? Math.round(playFrom - (deltaX / msPxRatio)) : playFrom;
    setResizing(false);
    setDelta(0);
    onResize(id, inPoint, outPoint, startPoint);
  };

  const handleResizeStart = ({ dir, del }) => {
    clearTimeout(throttleTimer);
    const videoElement = renderHelper.engine.layers
      .find(layer => layer.id && layer.id === instance.id).object.getElement();
    setResizing(true);
    const { width: deltaWidth } = del;
    getMaxWidth(dir);
    if (dir === 'left') {
      throttleTimer = setTimeout(() => {
        videoElement.currentTime =
          (instance.playFrom - (deltaWidth / msPxRatio)) / 1000;
        renderHelper.engine.canvas.renderAll();
      }, 200);
      setDelta(deltaWidth);
    }
    if (dir === 'right') {
      throttleTimer = setTimeout(() => {
        videoElement.currentTime =
          ((instance.duration - instance.playFrom) + (deltaWidth / msPxRatio)) / 1000;
        renderHelper.engine.canvas.renderAll();
      }, 200);
    }
  };

  const overlayDraggable = classNames('overlay-draggable', {
    show,
  });

  return (
    <React.Fragment>
      <InstanceBackground
        imageSrc={thumbnail.hd}
        overlay={{
          ...commonStyle,
          width: '100%',
          border: '2px solid white',
          borderRadius: '.125rem',
          backgroundColor: 'white',
        }}
      />
      <div
        className={overlayDraggable}
        style={{
          width: resizing ? null : (msPxRatio * duration) - 20,
          marginLeft: (msPxRatio * playFrom) + 10,
          transform: `translateX(${delta * -1}px)`,
          backgroundImage: `url(${thumbnail.hd})`,
          backgroundSize: '76px 43px',
          backgroundPositionX: `${delta}px`,
          backgroundPositionY: startX ? '85px' : '2px',
          paddingTop: '0px',
        }}
        onMouseDown={(e) => {
          if (!startX) {
            setStartX(e.pageX);
            setDiff(0);
            const overlay = document.querySelector('.overlay-resizer');
            const timelineLeft = document.querySelector('.videoRow-overlay').offsetLeft;
            const timelineWidth = document.querySelector('.videoRow-overlay').offsetWidth;
            setOverlayBounds({
              timelineLeft,
              timelineWidth,
              overlayLeft: overlay.offsetLeft,
              overlayWidth: overlay.offsetWidth,
            });
          }
        }}
        onMouseMove={(e) => {
          if (startX) {
            const {
              timelineLeft, timelineWidth, overlayLeft, overlayWidth,
            } = overlayBounds;
            const insideRightEdge = (-diff + overlayLeft + overlayWidth) <
              (timelineWidth + timelineLeft) - 1;
            setDiff(startX - e.pageX);
            if (e.target.offsetLeft - diff > timelineLeft && insideRightEdge) {
              setDelta(diff);
            }
          }
        }}
        onMouseUp={(e) => {
          if (startX) {
            setStartX(null);
            handleResizeStop({
              dir: 'move',
              del: { width: delta, height: 0 },
            });
          }
        }}
      />
      <Resizable
        defaultSize={{
          width: msPxRatio * duration,
          height: 40,
        }}
        handleStyles={{
          left: {
            borderLeft: '1rem solid var(--color1)',
            borderRadius: '0.125rem',
          },
          right: {
            borderRight: '1rem solid var(--color1)',
            borderRadius: '0.125rem',
          },
        }}
        size={{
          width: msPxRatio * duration,
          height: 40,
        }}
        minWidth={100}
        maxWidth={maxWidth}
        enable={RESIZE_CONSTRAINTS}
        style={{
          ...commonStyle,
          marginLeft: msPxRatio * playFrom,
          transform: `translateX(${delta * -1}px)`,
        }}
        className="overlay-resizer"
        onResizeStop={(ev, dir, el, del) => handleResizeStop({ dir, del })}
        onResize={(ev, dir, el, del) => handleResizeStart({ dir, del })}
      />
    </React.Fragment>
  );
};

VideoContext.propTypes = {
  instance: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  onResize: PropTypes.func,
  onResizeStart: PropTypes.func,
  show: PropTypes.bool,
};

export default VideoContext;
