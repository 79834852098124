import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

const propTypes = {
  throttleTime: PropTypes.number,
  treshold: PropTypes.number,
  onLoadMore: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  page: PropTypes.number,
  showScroll: PropTypes.bool,
};

const defaultProps = {
  throttleTime: 1000,
  treshold: 100,
};

class InfiniteScroll extends React.Component {
  state = {}

  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
  }

  componentDidMount() {
    const { current: wrapperElement } = this.elementRef;
    const { throttleTime } = this.props;

    this.scrollHandler = throttle(this.checkWindowScroll, throttleTime);
    this.resizeHandler = throttle(this.checkWindowScroll, throttleTime);

    wrapperElement.addEventListener('scroll', this.scrollHandler);
    wrapperElement.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    const { current: wrapperElement } = this.elementRef;

    wrapperElement.removeEventListener('scroll', this.scrollHandler);
    wrapperElement.removeEventListener('resize', this.resizeHandler);
  }

  checkWindowScroll = () => {
    const { treshold } = this.props;
    const { current: wrapperElement } = this.elementRef;

    if (!wrapperElement) {
      return;
    }

    const { scrollHeight, scrollTop } = wrapperElement;
    const { height: elementHeight } = wrapperElement.getBoundingClientRect();
    const isBottom = scrollHeight <= (elementHeight + scrollTop + treshold);

    if (
      isBottom
    ) {
      const { page } = this.props;
      this.props.onLoadMore(page);
    }
  }

  render() {
    const classes = `InfiniteScroll ${this.props.className}`;

    return (
      <div
        className={classes}
        ref={this.elementRef}
        onScroll={this.props.onScroll}
      >
        {this.props.children}
        <div className="scroll-more" style={{ opacity: this.props.showScroll ? '1' : '0' }}></div>
      </div>
    );
  }
}

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;

export default InfiniteScroll;
