import * as types from '@constants/actionTypes';
// import { tabs, contextOverlays } from '@constants/sidebar';

const tabs = {
  projects: 'projects',
  videos: 'videos',
  images: 'images',
  shapes: 'shapes',
  text: 'text',
  music: 'music',
  generate: 'generate',
  template: 'template',
  uploads: 'uploads',
}

const contextOverlays = {
  text: 'text',
  videoInstance: 'videoInstance',
  layer: 'layer',
  shape: 'shape',
}

const initialState = {
  tabs: [
    // {
    //   name: tabs.home,
    //   label: 'Home',
    //   active: false,
    // },
    // {
    //   name: tabs.projects,
    //   label: 'My folder',
    //   active: false,
    // },
    // {
    //   name: tabs.template,
    //   label: 'Design Templates',
    //   active: false,
    // },
    // {
    //   name: tabs.uploads,
    //   label: 'Uploads',
    //   active: false,
    // },
    {
      name: tabs.videos,
      label: 'Videos',
      active: false,
    },
    {
      name: tabs.images,
      label: 'Photos',
      active: true,
    },
    {
      name: tabs.shapes,
      label: 'Shapes',
      active: false,
    },
    {
      name: tabs.text,
      label: 'Text',
      active: false,
    },
    {
      name: tabs.music,
      label: 'Music',
      active: false,
    },
    // {
    //   name: tabs.generate,
    //   label: 'Generate',
    //   active: false,
    // },
  ],
  contextOverlays: [
    {
      name: contextOverlays.text,
      active: false,
    },
  ],
  activeTextId: null,
  activeProjectUid: null,
  activeLayerId: null,
  activeBackgroundId: null,
  selectedShapes: [],
  imageFootageFilter: 'stock',
  videoFootageFilter: 'stock',
  shapeFilter: 'shapes',
  uploadsFilter: 'video',
  expanded: false,
  showCategories: false,
  openAdjusters: null,
  toolbarActive: false,
};

const setActiveItem = (allItems, activeItem) => allItems.map(item => ({
  ...item,
  active: item.name === activeItem,
}));

export default function sidebar(state = initialState, action) {
  switch (action.type) {
    case types.SIDEBAR_TAB_TOGGLE:
      if (action.payload.tab === 'home') {
        return {
          ...state,
          expanded: false,
          // TODO: The following code was commented out /
          openAdjusters: null,
          activeProjectUid: null,
          activeLayerId: null,
          activeTextId: null,
          activeBackgroundId: null,
          selectedShapes: [],
          tabs: setActiveItem(state.tabs, 'projects'),
        };
      }
      return {
        ...state,
        tabs: setActiveItem(state.tabs, action.payload.tab),
      };
    case types.SIDEBAR_EXPAND_TOGGLE:
      setTimeout(() => { window.dispatchEvent(new Event('resize')); });
      return {
        ...state,
        expanded: !state.expanded,
      };

    case types.SIDEBAR_CATEGORIES_TOGGLE:
      return {
        ...state,
        showCategories: !state.showCategories,
      };
    case types.SIDEBAR_OVERLAY_TOGGLE:
      return {
        ...state,
        contextOverlays: setActiveItem(state.contextOverlays, action.payload.overlay),
      };

    case types.SIDEBAR_ITEM_FILTER_TOGGLE: {
      const { name, filter } = action.payload;
      return {
        ...state,
        [name]: filter,
      };
    }

    case types.SIDEBAR_UPLOADS_FILTER_TOGGLE:
      return {
        ...state,
        uploadsFilter: action.payload.filter,
      };

    case types.COMPOSISION_VIDEO_INSTANCE_SELECT: {
      const { itemId } = action.payload;
      return {
        ...state,
        activeLayerId: null,
        activeTextId: null,
        activeBackgroundId: itemId,
        selectedShapes: [],
      };
    }

    case types.COMPOSISION_VIDEO_INSTANCE_UNSELECT: {
      return {
        ...state,
        activeBackgroundId: null,
      };
    }

    case types.COMPOSISION_SHAPELAYER_UNSELECT: {
      return {
        ...state,
        selectedShapes: [],
      };
    }

    case types.TEXTLAYER_EDIT_ENTER: {
      const { itemId } = action.payload;
      setTimeout(() => { window.dispatchEvent(new Event('resize')); });
      return {
        ...state,
        activeLayerId: null,
        activeTextId: [itemId],
        activeBackgroundId: null,
        selectedShapes: [],
        expanded: false,
      };
    }

    case types.COMPOSITION_SELECT_TITLE: {
      const { selectedTexts } = action.payload;
      return {
        ...state,
        activeTextId: selectedTexts,
        activeLayerId: null,
        activeBackgroundId: null,
        selectedShapes: [],
        expanded: false,
      };
    }

    case types.TEXTLAYER_EDIT_EXIT:
    case types.COMPOSITION_TEXT_INSTANCE_REMOVE: {
      return {
        ...state,
        activeTextId: null,
        openAdjusters: '',
      };
    }

    case types.COMPOSITION_LAYER_EDIT_ENTER:
      return {
        ...state,
        activeTextId: null,
        activeLayerId: action.payload.itemId,
        activeBackgroundId: null,
        selectedShapes: [],
      };

    case types.COMPOSITION_LAYER_EDIT_EXIT:
    case types.COMPOSITION_LAYER_REMOVE:
      return {
        ...state,
        activeLayerId: null,
      };

    case types.SIDEBAR_SHOW_ADJUSTERS:
      return {
        ...state,
        openAdjusters: action.payload,
      };

    case types.SIDEBAR_PROJECT_SELECT: {
      const { projectUid } = action.payload;
      return {
        ...state,
        activeProjectUid: projectUid,
      };
    }

    case types.SIDEBAR_TOOLBAR_ACTIVE: {
      return {
        ...state,
        toolbarActive: action.payload,
      };
    }

    case types.SIDEBAR_SHAPE_SELECT: {
      const { shapeId, shiftKey } = action.payload;
      let newSelection = state.selectedShapes;
      if (newSelection && newSelection.find(s => s === shapeId)) {
        if (newSelection.length > 1) {
          newSelection = state.selectedShapes.filter(s => s !== shapeId);
        }
      } else {
        newSelection = shiftKey ? [...state.selectedShapes, shapeId] : [shapeId];
      }

      return {
        ...state,
        activeTextId: null,
        activeLayerId: null,
        activeBackgroundId: null,
        selectedShapes: newSelection,
      };
    }

    case types.SIDEBAR_SHAPE_DESELECT: {
      return {
        ...state,
        selectedShapes: [],
      };
    }

    case types.COMPOSITION_SHAPE_LAYER_REMOVE: {
      const newSelection = state.selectedShapes
        .filter(shape => shape.id !== action.payload.shapeId);
      return {
        ...state,
        selectedShapes: newSelection,
      };
    }

    default:
      return state;
  }
}
