import * as types from '@constants/actionTypes';

const initialState = {
  styles: {},
};

export default function activeText(state = initialState, action) {
  switch (action.type) {
    case types.TEXTLAYER_APPLY_ACTIVE_STYLES:
      return {
        ...state,
        styles: action.payload.styles,
      };

    case types.TEXTLAYER_APPLY_ACTIVE_ATTRIBUTE: {
      const { attributeObject } = action.payload;
      return {
        ...state,
        [attributeObject.key]: attributeObject.value,
      };
    }
    case types.TEXTLAYER_APPLY_ACTIVE_ATTRIBUTES:
      return {
        ...state,
        ...action.payload.attributes,
      };
    default:
      return state;
  }
}
