class Animate {
  static IN = Symbol('in')
  static OUT = Symbol('out')

  constructor(from, to, mode, values, easing = null) {
    this.from = from;
    this.to = to;
    this.values = values;
    this.mode = mode;
    this.easing = easing;
  }

  get props() {
    return Object.keys(this.values);
  }

  update(object, timestamp) {
    let ratio;
    if (timestamp < this.from) {
      ratio = 0;
    } else if (timestamp > this.to) {
      ratio = 1;
    } else {
      ratio = (timestamp - this.from) / (this.to - this.from);
    }
    ratio = this.mode === Animate.OUT ? 1 - ratio : ratio;

    if (this.easing) {
      ratio = this.easing(ratio);
    }

    // TODO: Needs a way to handle trimming during animation
    if (object.visible === true) {
      for (const key in this.values) {
        const value = this.values[key];
        const objectValue = object[key];
        const delta = objectValue - value;
        object[key] = value + delta * ratio;
      }
    }

    return timestamp >= this.from && timestamp <= this.to;
  }
}

export default Animate;
