import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CirclePicker, AlphaPicker, SketchPicker } from 'react-color';
import Slider from 'rc-slider';
import { animationOptions, buildAnimationSettings } from '@helpers/animation';
import { colorSwatches } from '@helpers/sidebar';
import Button from '@components/Button';
import { Color, ArrowRight } from '@components/Icons';
import ConfirmDialog from '@components/ConfirmDialog';
import TextAnimThumb from './TextAnimThumb';
import './Adjuster.scss';

const Adjuster = (props) => {
  const {
    label,
    value,
    fill,
    type,
    strokeWidth,
    strokeColor,
    onFillChange,
    onStrokeChange,
    onBoxFillChange,
    onBorderChange,
    onPaddingChange,
    onRadiusChange,
    onShadowChange,
    onTextShadowChange,
    pallets,
    boxBorder,
    boxPadding,
    boxShadow,
    textShadow,
    boxFill,
    boxCornerRadius,
    sidebar,
    onApplyAnimation,
    animation,
    textInstances,
    activeText,
    onSideBarAdjusters,
  } = props;

  const [advanced, toggleAdvanced] = useState({ display: false, type: '' });
  const [needResizing, setNeedResizing] = useState(false);
  const [animationInfo, setAnimationInfo] = useState({
    textToAnimate: null,
    animationSetting: null,
    textNeedResizing: null,
  });
  const handleAnimationChange = (val) => {
    try {
      sidebar.activeTextId.forEach((textId) => {
        const textToAnimate = textInstances.find(instance => instance.id === textId);
        const animationSetting = buildAnimationSettings(val, textToAnimate);
        const textNeedResizing = animationSetting.duration > textToAnimate.duration;
        if (textNeedResizing) {
          setNeedResizing(true);
          setAnimationInfo(animationSetting);
        } else {
          onApplyAnimation(textId, animationSetting);
        }
      });
    } catch (e) {
      const textToAnimate = textInstances.find(instance => instance.id === sidebar.activeTextId);
      const animationSetting = buildAnimationSettings(val, textToAnimate);
      const textNeedResizing = animationSetting.duration > textToAnimate.duration;
      if (textNeedResizing) {
        setNeedResizing(true);
        setAnimationInfo(animationSetting);
      } else {
        onApplyAnimation(sidebar.activeTextId, animationSetting);
      }
    }
  };

  const handleTextResize = (confirmation) => {
    // activeTextId is now an array of active texts
    if (confirmation) onApplyAnimation(sidebar.activeTextId[0], animationInfo, confirmation);
    setNeedResizing(false);
  };

  let animationSelectThumbs;
  if (type === 'animations') {
    animationSelectThumbs = animationOptions.map((anim, index) => {
      // eslint-disable-next-line no-restricted-globals
      if (anim.value === 'runNumber' && isNaN(activeText.text)) {
        return null;
      }
      return (<TextAnimThumb
        key={index}
        anim={anim}
        selected={animation === anim.value}
        onHandleAnimationChange={handleAnimationChange}
      />);
    });
  }

  const minimizeContent = (event, id) => {
    const contentSection = document.getElementById(id);
    contentSection.classList.toggle('hidden');
    event.target.querySelector('.Adjuster__minimize').classList.toggle('up');
  };


  return (
    <div className='ContextSettings__Adjuster'>
      <div className='details' onClick={() => onSideBarAdjusters('')}>
        <div className='content'>
          <div className='name'>{label}</div>
        </div>
      </div>
      {type === 'color' && <div className='adjustableOptions color'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'fill'); }}>
          <h2>Fill</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="fill" className="Adjuster__content hidden">
          <div className='advancedButtonContainer'>
            <Button
              className={(advanced.display && advanced.type === 'textFill') ? 'selected' : null}
              onClick={() => {
                toggleAdvanced({ display: !advanced.display, type: 'textFill' });
              }}
              iconBtn={true}
            ><Color /></Button>
            {advanced.display && advanced.type === 'textFill' &&
              <div>
                <SketchPicker
                  width={230}
                  color={fill}
                  presetColors={colorSwatches}
                  onChange={(event) => {
                    onFillChange(event.rgb);
                  }}
                />
              </div>}
          </div>
          <div className="Adjustable__default">
            {
              pallets.map((palette, index) =>
                <div className="palette" key={index}>
                  <p>{`palette-${index}`}</p>
                  <CirclePicker
                    width={275}
                    circleSize={21}
                    circleSpacing={5}
                    color={fill}
                    colors={pallets && palette && palette.colors}
                    onChange={(event) => {
                      onFillChange(event.rgb);
                    }}
                  />
                </div>)
            }
          </div>
          <CirclePicker
            width={275}
            circleSize={21}
            circleSpacing={5}
            color={fill}
            colors={colorSwatches}
            onChange={(event) => {
              onFillChange(event.rgb);
            }}
          />
          <div className='alphaPickerContainer'>
            <AlphaPicker
              color={fill}
              width={200}
              onChange={(event) => {
                onFillChange(event.rgb);
              }}
            />
          </div>
        </div>
      </div>}
      {type === 'color' && <div className='adjustableOptions stroke'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'stroke'); }}>
          <h2>Stroke</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="stroke" className="Adjuster__content hidden hidden">
          <div style={{ margin: '20px auto 0 auto' }}>
            <div className='presetButtonContainer' style={{ marginBottom: '20px' }}>
              <Button color='red' onClick={() => {
                onStrokeChange({
                  size: '0',
                });
              }}>None</Button>
              <Button color='red' onClick={() => {
                onStrokeChange({
                  size: '2',
                });
              }}>Small</Button>
              <Button color='red' onClick={() => {
                onStrokeChange({
                  size: '4',
                });
              }}>Medium</Button>
              <Button color='red' onClick={() => {
                onStrokeChange({
                  size: '8',
                });
              }}>Large</Button>
            </div>
            <Slider
              value={(strokeWidth && strokeWidth) || 0}
              onChange={size => (onStrokeChange({ size }))}
              min={0}
              max={20}
            />
            <div className="Adjustable__default">
              {
                pallets.map((palette, index) =>
                  <div className="palette" key={index}>
                    <p>{`palette-${index}`}</p>
                    <CirclePicker
                      width={275}
                      circleSize={21}
                      circleSpacing={5}
                      color={(strokeColor && strokeColor) || 'rgba(0,0,0,0)'}
                      colors={pallets && palette && palette.colors}
                      onChange={(event) => {
                        onStrokeChange({ color: event.rgb });
                      }}
                    />
                  </div>)
              }
            </div>
            <CirclePicker
              width={275}
              circleSize={21}
              circleSpacing={5}
              color={(strokeColor && strokeColor) || 'rgba(0,0,0,0)'}
              colors={colorSwatches}
              onChange={(event) => {
                onStrokeChange({ color: event.rgb });
              }}
            />
          </div>
          <div className='advancedButtonContainer'>
            <Button
              className={advanced.display && advanced.type === 'textStroke' ? 'selected' : null}
              onClick={() => toggleAdvanced({ display: !advanced.display, type: 'textStroke' })}
              iconBtn={true}
            ><Color /></Button>
          </div>
          {(advanced.display && advanced.type === 'textStroke') && <div>
            <SketchPicker
              width={230}
              color={(strokeColor && strokeColor) || 'rgba(0,0,0,0)'}
              presetColors={colorSwatches}
              onChange={(event) => {
                onStrokeChange({ color: event.rgb });
              }}
            />
          </div>}
        </div>
      </div>}
      {type === 'color' && <div className='adjustableOptions shadow'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'shadow'); }}>
          <h2>Text Shadow</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="shadow" className="Adjuster__content hidden">
          <div className='presetButtonContainer'>
            <Button color='white' onClick={() => {
              onTextShadowChange({
                preset: true, offsetX: 0, offsetY: 0, blur: 0, color: 'rgba(0,0,0,0)',
              });
            }}>None</Button>
            <Button color='white' onClick={() => {
              onTextShadowChange({
                preset: true, offsetX: 1, offsetY: 1, blur: 3, color: 'rgba(0,0,0,0.5)',
              });
            }}>Small</Button>
            <Button color='white' onClick={() => {
              onTextShadowChange({
                preset: true, offsetX: 0, offsetY: 3, blur: 6, color: 'rgba(0,0,0,0.5)',
              });
            }}>Medium</Button>
            <Button color='white' onClick={() => {
              onTextShadowChange({
                preset: true, offsetX: 6, offsetY: 9, blur: 15, color: 'rgba(0,0,0,0.5)',
              });
            }}>Large</Button>
          </div>
          <Slider
            className='sliderLabel shadow offsetX'
            value={(textShadow && textShadow.offsetX) || 0}
            onChange={valueX => (onTextShadowChange({ offsetX: valueX }))}
            min={0}
            max={64}
          />
          <Slider
            className='sliderLabel shadow offsetY'
            value={(textShadow && textShadow.offsetY) || 0}
            onChange={valueY => (onTextShadowChange({ offsetY: valueY }))}
            min={0}
            max={64}
          />
          <Slider
            className='sliderLabel shadow blur'
            value={(textShadow && textShadow.blur) || 0}
            onChange={valueBlur => (onTextShadowChange({ blur: valueBlur }))}
            min={0}
            max={64}
          />
          <div style={{ padding: '30px 0 10px 0' }}>
            <div className="Adjustable__default">
              {
                pallets.map((palette, index) =>
                  <div className="palette" key={index}>
                    <p>{`palette-${index}`}</p>
                    <CirclePicker
                      width={275}
                      circleSize={21}
                      circleSpacing={5}
                      color={value}
                      colors={pallets && palette && palette.colors}
                      onChange={(event) => {
                        onTextShadowChange({ color: event.rgb });
                      }}
                    />
                  </div>)
              }
            </div>
            <CirclePicker
              width={275}
              circleSize={21}
              circleSpacing={5}
              color={textShadow && (textShadow.color || 'rgba(0,0,0,0)')}
              colors={colorSwatches}
              onChange={(event) => {
                onTextShadowChange({ color: event.rgb });
              }}
            />
          </div>
          <div className='advancedButtonContainer'>
            <Button
              className={advanced.display && advanced.type === 'textShadow' ? 'selected' : null}
              onClick={() => toggleAdvanced({ display: !advanced.display, type: 'textShadow' })}
              iconBtn={true}
            ><Color /></Button>
          </div>
          {(type === 'color' && advanced.display && advanced.type === 'textShadow') && <div>
            <SketchPicker
              width={230}
              color={textShadow && textShadow.color}
              presetColors={colorSwatches}
              onChange={(event) => {
                onTextShadowChange({ color: event.rgb });
              }}
            />
          </div>}
        </div>
      </div>}
      {type === 'box' && <div className='adjustableOptions boxFill'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'fill'); }}>
          <h2>Fill</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="fill" className="Adjuster__content hidden">
          <div className="Adjustable__default">
            {
              pallets.map((palette, index) =>
                <div className="palette" key={index}>
                  <p>{`palette-${index}`}</p>
                  <CirclePicker
                    width={275}
                    circleSize={21}
                    circleSpacing={5}
                    color={boxFill !== undefined ? boxFill : 'rgba(0,0,0,0)'}
                    colors={pallets && palette && palette.colors}
                    onChange={(event) => {
                      onBoxFillChange(event.rgb);
                    }}
                  />
                </div>)
            }
          </div>
          <CirclePicker
            width={275}
            circleSize={21}
            circleSpacing={5}
            color={fill}
            colors={colorSwatches}
            onChange={(event) => {
              onBoxFillChange(event.rgb);
            }}
          />
          <div className='advancedButtonContainer'>
            <Button
              className={advanced.display && advanced.type === 'boxFill' ? 'selected' : null}
              onClick={() => toggleAdvanced({ display: !advanced.display, type: 'boxFill' })}
              iconBtn={true}
            ><Color /></Button>
          </div>
          {(type === 'box' && advanced.display && advanced.type === 'boxFill') && <div>
            <SketchPicker
              width={275}
              color={boxFill !== undefined ? boxFill : 'rgba(0,0,0,0)'}
              presetColors={colorSwatches}
              onChange={(event) => {
                onBoxFillChange(event.rgb);
              }}
            />
          </div>}
        </div>
      </div>}
      {type === 'box' && <div className='adjustableOptions boxBorder'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'border'); }}>
          <h2>Border</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="border" className="Adjuster__content hidden">
          <div className='presetButtonContainer' style={{ marginBottom: '20px' }}>
            <Button color='white' onClick={() => {
              onBorderChange({
                size: '0',
              });
            }}>None</Button>
            <Button color='white' onClick={() => {
              onBorderChange({
                size: '2',
              });
            }}>Small</Button>
            <Button color='white' onClick={() => {
              onBorderChange({
                size: '4',
              });
            }}>Medium</Button>
            <Button color='white' onClick={() => {
              onBorderChange({
                size: '8',
              });
            }}>Large</Button>
          </div>
          <Slider
            value={boxBorder !== undefined ? boxBorder.borderWidth : 0}
            onChange={size => (onBorderChange({ size }))}
            min={0}
            max={20}
          />
          <div>
            <div className="Adjustable__default">
              {
                pallets.map((palette, index) =>
                  <div className="palette" key={index}>
                    <p>{`palette-${index}`}</p>
                    <CirclePicker
                      width={275}
                      circleSize={21}
                      circleSpacing={5}
                      color={boxBorder !== undefined ? boxBorder.borderColor : 'rgba(0,0,0,0)'}
                      colors={pallets && palette && palette.colors}
                      onChange={(event) => {
                        onBorderChange({ color: event.rgb });
                      }}
                    />
                  </div>)
              }
            </div>
            <CirclePicker
              width={275}
              circleSize={21}
              circleSpacing={5}
              color={boxBorder !== undefined ? boxBorder.borderColor : 'rgba(0,0,0,0)'}
              colors={colorSwatches}
              onChange={(event) => {
                onBorderChange({ color: event.rgb });
              }}
            />
            <div className='advancedButtonContainer'>
              <Button
                className={advanced.display && advanced.type === 'boxBorder' ? 'selected' : null}
                onClick={() => toggleAdvanced({ display: !advanced.display, type: 'boxBorder' })}
                iconBtn={true}
              ><Color /></Button>
            </div>
            {(type === 'box' && advanced.display && advanced.type === 'boxBorder') && <div>
              <SketchPicker
                width={230}
                color={boxBorder !== undefined ? boxBorder.borderColor : 'rgba(0,0,0,0)'}
                presetColors={colorSwatches}
                onChange={(event) => {
                  onBorderChange({ color: event.rgb });
                }}
              />
            </div>}
          </div>
        </div>
      </div>}
      {type === 'box' && <div className='adjustableOptions borderRadius'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'radius'); }}>
          <h2>Corner Radius</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="radius" className="Adjuster__content hidden">
          <Slider
            value={boxCornerRadius !== undefined ? boxCornerRadius : 0}
            onChange={size => (onRadiusChange(size))}
            min={0}
            max={255}
          />
        </div>
      </div>}
      {type === 'box' && <div className='adjustableOptions padding'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'padding'); }}>
          <h2>Padding</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="padding" className="Adjuster__content hidden">
          <Slider
            value={boxPadding !== undefined ? boxPadding : 0}
            onChange={size => (onPaddingChange(size))}
            min={0}
            max={255}
          />
        </div>
      </div>}
      {type === 'box' && <div className='adjustableOptions shadow'>
        <div className="Adjuster__header" onClick={(event) => { minimizeContent(event, 'boxShadow'); }}>
          <h2>Box Shadow</h2>
          <div className="Adjuster__minimize">
            <ArrowRight />
          </div>
        </div>
        <div id="boxShadow" className="Adjuster__content hidden">
          <div className='presetButtonContainer'>
            <Button color='white' onClick={() => {
              onShadowChange({
                preset: true, offsetX: 0, offsetY: 0, blur: 0, color: 'rgba(0,0,0,0)',
              });
            }}>None</Button>
            <Button color='white' onClick={() => {
              onShadowChange({
                preset: true, offsetX: 1, offsetY: 1, blur: 3, color: 'rgba(0,0,0,0.5)',
              });
            }}>Small</Button>
            <Button color='white' onClick={() => {
              onShadowChange({
                preset: true, offsetX: 0, offsetY: 3, blur: 6, color: 'rgba(0,0,0,0.5)',
              });
            }}>Medium</Button>
            <Button color='white' onClick={() => {
              onShadowChange({
                preset: true, offsetX: 6, offsetY: 9, blur: 15, color: 'rgba(0,0,0,0.5)',
              });
            }}>Large</Button>
          </div>
          <Slider
            className='sliderLabel shadow offsetX'
            value={(boxShadow && boxShadow.offsetX) || 0}
            onChange={valueX => (onShadowChange({ offsetX: valueX }))}
            min={0}
            max={64}
          />
          <Slider
            className='sliderLabel shadow offsetY'
            value={(boxShadow && boxShadow.offsetY) || 0}
            onChange={valueY => (onShadowChange({ offsetY: valueY }))}
            min={0}
            max={64}
          />
          <Slider
            className='sliderLabel shadow blur'
            value={(boxShadow && boxShadow.blur) || 0}
            onChange={valueBlur => (onShadowChange({ blur: valueBlur }))}
            min={0}
            max={64}
          />
          <div style={{ padding: '30px 0 10px 0' }}>
            <div className="Adjustable__default">
              {
                pallets.map((palette, index) =>
                  <div className="palette" key={index}>
                    <p>{`palette-${index}`}</p>
                    <CirclePicker
                      width={275}
                      circleSize={21}
                      circleSpacing={5}
                      color={value}
                      colors={pallets && palette && palette.colors}
                      onChange={(event) => {
                        onShadowChange({ color: event.rgb });
                      }}
                    />
                  </div>)
              }
            </div>
            <CirclePicker
              width={275}
              circleSize={21}
              circleSpacing={5}
              color={boxShadow && (boxShadow.color || 'rgba(0,0,0,0)')}
              colors={colorSwatches}
              onChange={(event) => {
                onShadowChange({ color: event.rgb });
              }}
            />
          </div>
          <div className='advancedButtonContainer'>
            <Button
              className={advanced.display && advanced.type === 'boxShadow' ? 'selected' : null}
              onClick={() => toggleAdvanced({ display: !advanced.display, type: 'boxShadow' })}
              iconBtn={true}
            ><Color /></Button>
          </div>
          {(type === 'box' && advanced.display && advanced.type === 'boxShadow') && <div>
            <SketchPicker
              width={230}
              color={boxShadow && boxShadow.color}
              presetColors={colorSwatches}
              onChange={(event) => {
                onShadowChange({ color: event.rgb });
              }}
            />
          </div>}
        </div>
      </div>}
      {type === 'animations' && <div className="adjustableOptions" style={{ width: '100%' }}>
        <div className="ContextSettings__TextAnimations">
          {animationSelectThumbs}
        </div>
        <ConfirmDialog
          visible={needResizing}
          title={'Confirm text resizing.'}
          onClose={() => setNeedResizing(false)}
          cancelButton={<Button onClick={() => handleTextResize(false)}>Cancel</Button>}
          confirmButton={
            <Button color="red" onClick={() => handleTextResize(true)}>
              Resize text and apply animation.
            </Button>
          }
        >
          Text duration is too short for this animation. Resizing is needed.
        </ConfirmDialog>
      </div>}
    </div>
  );
};

Adjuster.propTypes = {
  pallets: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  fill: PropTypes.string,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  lineHeight: PropTypes.string,
  boxBorder: PropTypes.string,
  boxPadding: PropTypes.string,
  boxShadow: PropTypes.string,
  textShadow: PropTypes.object,
  boxFill: PropTypes.string,
  boxCornerRadius: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  onToggleOpen: PropTypes.func,
  onChange: PropTypes.func,
  onFillChange: PropTypes.func,
  onStrokeChange: PropTypes.func,
  onBoxFillChange: PropTypes.func,
  onBorderChange: PropTypes.func,
  onPaddingChange: PropTypes.func,
  onRadiusChange: PropTypes.func,
  onShadowChange: PropTypes.func,
  onUpdateTextLayerDepths: PropTypes.func,
  onModifyShape: PropTypes.func,
  onGetColorSettings: PropTypes.func,
  onApplyAttributes: PropTypes.func,
  onTextShadowChange: PropTypes.func,
  onLineHeightChange: PropTypes.func,
  onTextLayerEditEnter: PropTypes.func,
  handleTextResize: PropTypes.func,
  onApplyAnimation: PropTypes.func,
  onSideBarAdjusters: PropTypes.func,
  onUpdateTextLayerOrders: PropTypes.func,
  onRemove: PropTypes.func,
  onAddTextLayer: PropTypes.func,
  onLayerToggleVisible: PropTypes.func,
  sidebar: PropTypes.object,
  textInstances: PropTypes.array,
  titleInstances: PropTypes.array,
  activeText: PropTypes.object,
  activeTextId: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  animation: PropTypes.string,
  activeShape: PropTypes.object,
};

export default Adjuster;
