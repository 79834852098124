import React from 'react';
import { connect } from 'react-redux';
import {
  compositionVideoInstanceMove,
  compositionVideoInstanceDragToTimeline,
  compositionVideoInstanceRemove,
  compositionVideoInstanceAdd,
  compositionVideoInstanceSelect,
  compositionVideoInstanceResize,
  compositionTextInstanceResize,
  compositionTextInstanceResizeStart,
  compositionTextInstanceRemove,
  compositionRemoveShapeLayer,
  compositionTextInstanceMove,
  compositionTextInstanceSwap,
  compositionVideoInstanceReplace,
  compositionVideoGenerateUniqueId,
  compositionVideoInstanceUnselect,
  compositionLayerEditEnter,
  duplicateInstances,
  compositionLayerTimeslotChange,
  compositionShowVideoContext,
  compositionTextInstaceAdd,
  compositionSelectTitle,
  compositionLayerRemove,
  compositionUpdateShapeLayer,
  compositionAddShapeLayer,
  compositionLayerInstanceUpdateTimestamps,
  compositionLayerAdd,
  compositionVideoInstanceSplit,
  formatBackgroundInstances,
} from '@actions/composition';
import { sidebarSelectShape, expandPool, sidebarTabToggle, sidebarExpandToggle } from '@actions/sidebar';
import { textLayerEditEnter } from '@actions/textlayer';
import { notifyInfo } from '@actions/notifs';



import {
  timelineProgressJump,
  timelineProgressSeek,
  timelineTogglePlay,
} from '@actions/timeline';


import NewTimeline from '@components/NewTimeline/index.jsx';

const TimelineContainer = props => <NewTimeline {...props} />;

const mapStateToProps = (state) => {
  const { timeline, sidebar } = state;
  const {
    activeLayerId,
    activeTextId,
    activeBackgroundId,
    selectedShapes,
  } = sidebar;
  const { dragging } = state.textpresets;
  return {
    composition: state.composition.present,
    timeline,
    activeLayerId,
    activeTextId,
    activeBackgroundId,
    selectedShapes,
    videoContextVisible: sidebar.activeBackgroundId !== null,
    textPresetDragging: dragging,
    sidebarExpanded: sidebar.expanded,
  };
};

export default connect(mapStateToProps, {
  onAdd: compositionVideoInstanceAdd,
  onSelect: compositionVideoInstanceSelect,
  onMove: compositionVideoInstanceMove,
  onDrag: compositionVideoInstanceDragToTimeline,
  onRemove: compositionVideoInstanceRemove,
  onTextRemove: compositionTextInstanceRemove,
  onShapeRemove: compositionRemoveShapeLayer,
  onAddTextLayer: compositionTextInstaceAdd,
  onReplace: compositionVideoInstanceReplace,
  onVideoResize: compositionVideoInstanceResize,
  onTextLayerSelect: textLayerEditEnter,
  onTextResize: compositionTextInstanceResize,
  onTextResizeStart: compositionTextInstanceResizeStart,
  onTextMove: compositionTextInstanceMove,
  onTextSwap: compositionTextInstanceSwap,
  generateUniqueId: compositionVideoGenerateUniqueId,
  onRazorVideo: compositionVideoInstanceSplit,
  onProgressJump: timelineProgressJump,
  onProgressSeek: timelineProgressSeek,
  onDuplicateInstances: duplicateInstances,
  onDeletedVideo: compositionVideoInstanceUnselect,
  onTimeChange: compositionLayerTimeslotChange,
  onShowContext: compositionShowVideoContext,
  onSelectTitle: compositionSelectTitle,
  onUpdateShape: compositionUpdateShapeLayer,
  onAddShape: compositionAddShapeLayer,
  onAddLayer: compositionLayerAdd,
  onLayerSelect: compositionLayerEditEnter,
  onLayerRemove: compositionLayerRemove,
  onSelectShape: sidebarSelectShape,
  onUpdateLayer: compositionLayerInstanceUpdateTimestamps,
  formatBackgroundInstances,
  compositionLayerRemove,
  notifyInfo,
  sidebarTabToggle,
  timelineTogglePlay,
  sidebarExpandToggle,
  expandPool,
})(TimelineContainer);
