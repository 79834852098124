import React from 'react';
import PropTypes from 'prop-types';
import { RotateLeft } from '@components/Icons';
import './ShapeLayer.scss';

function LayerControls(props) {
  const {
    visible, selected, onResizeStart, onRotateStart, rotateHandleRight, isResizing, isRotating, isMoving,
  } = props;

  if (!visible) return null;

  const counterVisible = props.counterVisible ? 'block' : 'none';
  const resizingOpacity = (isMoving || isResizing || isRotating) ? 0 : 1;

  return (
    <div
      className="controls"
      style={{
        border: selected ? '2px solid var(--color1)' : '2px dotted var(--color1)',
        opacity: resizingOpacity,
        transition: 'opacity .25s',
      }}
    >
      {/* <div className="resizeHandle" onMouseDown={onResizeStart} /> */}
      <div className="resizer-br" onMouseDown={onResizeStart} />
      <div className="resizer-bl" onMouseDown={onResizeStart} />
      <div className="resizer-tl" onMouseDown={onResizeStart} />
      <div className="resizer-tr" onMouseDown={onResizeStart} />
      <div className="rotateHandle" onMouseDown={onRotateStart} style={{ right: `${-0.8 * rotateHandleRight}px` }}>
        <RotateLeft className="rotateHandle" />
      </div>
      <div className="rotateCounter" onMouseDown={onRotateStart} style={
        {
          right: `${-0.8 * rotateHandleRight}px`,
          transform: `rotate(${props.angle * -1}deg)`,
          display: counterVisible,
        }
      }>
        {Math.round(props.angle)}
      </div>
    </div>
  );
}

LayerControls.propTypes = {
  visible: PropTypes.bool,
  selected: PropTypes.bool,
  counterVisible: PropTypes.bool,
  onResizeStart: PropTypes.func,
  onRotateStart: PropTypes.func,
  rotateHandleTop: PropTypes.number,
  rotateHandleRight: PropTypes.number,
  angle: PropTypes.number,
  isRotating: PropTypes.bool,
  isResizing: PropTypes.bool,
  isMoving: PropTypes.bool,
};

export default LayerControls;
