import * as types from '@constants/actionTypes';
import { renderHelper } from '@lib/renderHelper';

let seekPromise = null;

function togglePlay() {
  return {
    type: types.TIMELINE_TOGGLE_PLAY,
  };
}

function progressSeek(currentTime) {
  return {
    type: types.TIMELINE_PROGRESS_SEEK,
    payload: {
      currentTime,
    },
  };
}

function progressJump(currentTime) {
  return {
    type: types.TIMELINE_PROGRESS_JUMP,
    payload: {
      currentTime,
    },
  };
}

function requestPlay() {
  return {
    type: types.TIMELINE_REQUEST_PLAY,
  };
}

function requestSeek(currentTime) {
  return {
    type: types.TIMELINE_REQUEST_SEEK,
    payload: {
      currentTime,
    },
  };
}

function timelineProgressJump(currentTime) {
  return async (dispatch, getState) => {
    const { playing } = getState().timeline;

    if (playing) {
      dispatch(togglePlay());
      await renderHelper.pause();
      dispatch(requestSeek(currentTime));
      await renderHelper.seek(currentTime);
      dispatch(progressJump(currentTime));
      // await renderHelper.play();
      // dispatch(togglePlay());
    } else {
      dispatch(requestSeek(currentTime));
      await renderHelper.seek(currentTime);
      dispatch(progressJump(currentTime));
    }
  };
}

function timelineProgressSeek(currentTime) {
  return async (dispatch) => {
    if (seekPromise) return;

    dispatch(requestSeek());
    seekPromise = renderHelper
      .seek(currentTime)
      .then(() => {
        dispatch(progressSeek(currentTime));
        seekPromise = null;
      });
  };
}

function timelineTogglePlay() {
  return async (dispatch, getState) => {
    const { playing, buffering } = getState().timeline;
    if (buffering) return;

    dispatch(requestPlay());
    if (playing) {
      await renderHelper.pause();
    } else {
      const composition = getState().composition.present;
      const { timeline } = getState();
      if (timeline.currentTime === composition.duration) {
        await renderHelper.seek(0);
        dispatch(progressSeek(0));
      }
      await renderHelper.play();
    }

    dispatch(togglePlay());
  };
}

function timelineToggleFullscreen() {
  return {
    type: types.TIMELINE_TOGGLE_FULLSCREEN,
  };
}

export {
  timelineProgressJump,
  timelineProgressSeek,
  timelineToggleFullscreen,
  timelineTogglePlay,
  requestSeek,
};
