import { borderAndCornerStyles } from '@lib/fabricHelper';

// video layer options

const getVideoOptions = (instance) => {
  const {
    left, top, visibleFrom, visibleTo, playFrom, id, scaleX = 1,
    scaleY = 1, minY, minX, maxY, maxX, lockX, lockY, width, height, moveLocks,
  } = instance;

  const options = {
    ...borderAndCornerStyles,
    moveLocks,
    left,
    top,
    scaleX,
    scaleY,
    lockMovementY: lockY,
    lockMovementX: lockX,
    hasControls: false,
    minY,
    minX,
    maxY,
    maxX,
    width,
    height,
  };
  const layerOptions = {
    visibleFrom, visibleTo, playFrom, id, moveLocks,
  };
  return {
    layerOptions,
    options,
  };
};

// image layer options

const getImageOptions = (instance) => {
  const {
    visibleFrom, visibleTo, top, left, width, height, scaleX = 1,
    scaleY = 1, angle = 0, id, lockX, lockY, initial, minX, minY, maxX, maxY, moveLocks,
  } = instance;

  const layerOptions = {
    visibleFrom,
    visibleTo,
    top,
    left,
    width,
    height,
    scaleX,
    scaleY,
    angle,
    id,
    initial,
    moveLocks,
  };

  const fabricOptions = {
    ...borderAndCornerStyles,
    height,
    width,
    top,
    left,
    scaleX,
    scaleY,
    hasControls: false,
    angle,
    lockMovementY: lockY,
    lockMovementX: lockX,
    minX,
    minY,
    maxX,
    maxY,
    moveLocks,
  };
  return {
    layerOptions,
    fabricOptions,
  };
};

export {
  getVideoOptions,
  getImageOptions,
};
