
const calculateMsRatio = (containerWidth, compositionDuration) =>
  containerWidth / compositionDuration;

const getSeekPoint = (event, containerElement, compositionDuration) => {
  const offset = event.clientX - containerElement.offsetLeft;
  const msPxRatio = calculateMsRatio(containerElement.offsetWidth, compositionDuration);
  const time = Math.round(offset / msPxRatio);
  return time;
};

const findEmptySlot = (composition, visibleFrom, visibleTo) => {
  const { layers, shapeInstances, textInstances } = composition;
  const overlays = [...layers, ...shapeInstances, ...textInstances];
  const sortedOverlays = overlays.map(entry => ({
    ...entry,
    track: entry.track !== undefined && entry.track !== null ? entry.track : 4,
  }));
  const newProperties = {
    visibleFrom,
    visibleTo,
    track: 4,
  };
  let assigned = false;
  for (let i = 4; i >= -1; i--) {
    if (!assigned) {
      const overlaysOnThisTrack = sortedOverlays.filter(entry => entry.track === i);
      if (overlaysOnThisTrack.length === 0) {
        newProperties.track = i;
        assigned = true;
      } else {
        let roomInThisTrack = true;
        overlaysOnThisTrack.forEach((overlay, index) => {
          if ((visibleFrom <= overlay.visibleTo && visibleFrom >= overlay.visibleFrom) || (visibleTo <= overlay.visibleTo && visibleTo >= overlay.visibleFrom) || (visibleFrom <= overlay.visibleFrom && visibleTo >= overlay.visibleTo)) {
            roomInThisTrack = false;
          }
        });
        if (roomInThisTrack) {
          newProperties.track = i;
          assigned = true;
        }
      }
    }
  }
  return newProperties;
};

const addTrackPropertyToInstances = (overlays, duration) => {
  overlays.sort((a, b) => a.visibleFrom - b.visibleFrom);
  const sortedOverlays = overlays.map((entry) => {
    const track = (entry.track !== undefined && entry.track !== null) ? entry.track : 4;
    return ({ ...entry, track });
  });

  const sortOnTracks = () => {
    for (let i = 4; i >= 0; i--) {
      sortedOverlays.forEach((entry, index) => {
        if (sortedOverlays[index + 1] && entry.track === i && sortedOverlays[index + 1].track === i && entry.visibleTo >= sortedOverlays[index + 1].visibleFrom) {
          entry.track = i - 1;
        }
      });
    }
  };
  const pushOutOfBoundsToEnd = () => {
    // Dont push to end of composition but end of overlay pool
    sortedOverlays.forEach((entry) => {
      if (entry.track < 0) {
        const thisDuration = entry.visibleTo - entry.visibleFrom;
        entry.visibleFrom = duration + 1;
        entry.visibleTo = duration + 1 + thisDuration;
        entry.track = 4;
      }
    });
  };

  sortOnTracks();
  pushOutOfBoundsToEnd();
  sortOnTracks();
  
  const newDuration = sortedOverlays.reduce((acc, curr) => {
    if (curr.visibleTo > acc) {
      return curr.visibleTo;
    }
    return acc;
  }, duration);

  const textInstances = sortedOverlays.filter(entry => entry.type === 'text');
  const shapeInstances = sortedOverlays.filter(entry => entry.type === 'shape');
  const layers = sortedOverlays.filter(entry => entry.type === 'logo' || entry.type === 'image');

  return { textInstances, shapeInstances, layers, newDuration, version: 2 };
};

const swap = (
  item,
  newThisClip,
  newComparisonClip,
  comparisonClip,
  onOverlayMove,
  onUpdateShape,
  onUpdateLayer,
  onBackgroundMove,
) => {
  switch (comparisonClip.type) {
    case 'text':
      onOverlayMove(newComparisonClip.id, newComparisonClip.visibleFrom, newComparisonClip.visibleTo, newComparisonClip.track);
      break;
    case 'shape':
      onUpdateShape(newComparisonClip);
      break;
    case 'video':
      onBackgroundMove(newComparisonClip.id, newComparisonClip.visibleFrom, newComparisonClip.visibleTo);
      break;
    case 'image':
      if (item.poolType === 'background') {
        onBackgroundMove(newComparisonClip.id, newComparisonClip.visibleFrom, newComparisonClip.visibleTo);
      } else {
        onUpdateLayer(newComparisonClip, newComparisonClip.id);
      }
      break;
    default:
  }
  switch (item.type) {
    case 'text':
      onOverlayMove(newThisClip.id, newThisClip.visibleFrom, newThisClip.visibleTo, newThisClip.track);
      break;
    case 'shape':
      onUpdateShape(newThisClip);
      break;
    case 'video':
      onBackgroundMove(newThisClip.id, newThisClip.visibleFrom, newThisClip.visibleTo);
      break;
    case 'image':
      if (item.poolType === 'background') {
        onBackgroundMove(newThisClip.id, newThisClip.visibleFrom, newThisClip.visibleTo);
      } else {
        onUpdateLayer(newThisClip, newThisClip.id);
      }
      break;
    default:
  }
};

export {
  findEmptySlot,
  addTrackPropertyToInstances,
  getSeekPoint,
  calculateMsRatio,
  swap,
};
