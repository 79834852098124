import React from 'react';
import PropTypes from 'prop-types';

import './Spinner.scss';

const propTypes = {
  visible: PropTypes.bool,
};

const Spinner = ({ visible }) => {
  if (!visible) return null;

  return (
    <div className="Spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
};

Spinner.propTypes = propTypes;

export default Spinner;
