/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import anime from "animejs";
import { Delete } from "@components/Icons";
import { setThumbnailAnimation } from "@components/VideoPreview/animations";
import TitleItem from "./TitleItem";
import "./TextTab.scss";

const TitleThumbnail = (props) => {
  const { canvas, title, onLoad, onDeletePreset, role } = props;

  // eslint-disable-next-line consistent-return
  const calculateScale = () => {
    const { width, height } = canvas;
    if (width === 1920 && height === 1080) {
      return canvas.width / 1920;
    }
    if (width === 1080 && height === 1080) {
      return canvas.width / 1080;
    }
    if (width === 1080 && height === 1920) {
      return canvas.height / 1920;
    }
  };

  const titleItems =
    title.preset.textInstances &&
    title.preset.textInstances.map((instance, i) => (
      <TitleItem
        key={i}
        textInstance={instance}
        canvas={canvas}
        scaleFactor={calculateScale()}
      />
    ));

  const resetAnimation = (textInstance) => {
    // Reset all CSS properties
    const lines = document.querySelectorAll(
      `titleThumbnailInstance-${textInstance.id} .line`
    );
    const words = document.querySelectorAll(
      `titleThumbnailInstance-${textInstance.id} .word`
    );
    const letters = document.querySelectorAll(
      `titleThumbnailInstance-${textInstance.id} .letter`
    );

    const reset = (item) =>
      item.forEach((element) => {
        element.style.opacity = 1;
        element.style.scale = 1;
        element.style.filter = "none";
        element.style.transform = "none";
      });
    reset(lines);
    reset(words);
    reset(letters);
  };

  return (
    <React.Fragment>
      <div
        className="TextTab__style-thumbnail"
        style={{
          width: `${canvas.width}px`,
          height: `${canvas.height}px`,
          transform: "scale(0.0675)",
          transformOrigin: "0px 0px",
        }}
        onMouseEnter={() => {
          window.thumbnailAnimController = anime.timeline({
            loop: true,
            autoplay: true,
          });
          if (!title.preset.textInstances) {
            return;
          }
          title.preset.textInstances.forEach((textInstance) => {
            setThumbnailAnimation({
              name: textInstance.animation && textInstance.animation.name,
              // selector: `.ContextSettings__TextAnimations__Thumb.${this.props.anim.value}`,
              selector: `.titleThumbnailInstance-${textInstance.id}`,
            });
          });
        }}
        onMouseLeave={() => {
          window.thumbnailAnimController.pause();
          window.thumbnailAnimController.seek(2000);
          if (!title.textInstances) {
            return;
          }
          title.textInstances.forEach((textInstance) => {
            resetAnimation(textInstance);
          });
        }}
        onClick={() => {
          onLoad(title.preset);
        }}
      >
        {role === 2 && (
          <div
            className="TextTab__delete"
            onClick={(e) => {
              e.stopPropagation();
              onDeletePreset(title.id);
            }}
          >
            <Delete />
          </div>
        )}
        {titleItems}
      </div>
    </React.Fragment>
  );
};

TitleThumbnail.propTypes = {
  canvas: PropTypes.object,
  title: PropTypes.object,
  onLoad: PropTypes.func,
  onDeletePreset: PropTypes.func,
};
export default TitleThumbnail;
