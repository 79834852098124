import * as types from '@constants/actionTypes';
import { renderHelper } from '@lib/renderHelper';
import { playTimelines, pauseTimelines } from '@lib/animeTimelines';

const initialState = {
  currentTime: 0,
  hd: false,
  playing: false,
  fullscreen: false,
  buffering: false,
  expandedPool: 1,
};

export default function timeline(state = initialState, action) {
  switch (action.type) {
    case types.TIMELINE_PROGRESS_JUMP:
    case types.TIMELINE_PROGRESS_SEEK:
      // TODO: Better handling of previewContainer syncing
      setTimeout(() => { window.dispatchEvent(new Event('resize')); });
      return {
        ...state,
        buffering: false,
        currentTime: action.payload.currentTime,
      };

    case types.TIMELINE_TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullscreen: !state.fullscreen,
      };

    case types.TIMELINE_TOGGLE_PLAY:
      if (!state.playing) {
        playTimelines();
      } else {
        pauseTimelines();
      }

      return {
        ...state,
        playing: !state.playing,
        currentTime: renderHelper.time(),
        buffering: false,
      };
    case types.EXPAND_POOL: {
      return {
        ...state,
        expandedPool: action.payload,
      };
    }
    case types.TIMELINE_REQUEST_PLAY:
    case types.TIMELINE_REQUEST_SEEK:
      // TODO: Better handling of previewContainer syncing
      setTimeout(() => { window.dispatchEvent(new Event('resize')); });
      return {
        ...state,
        buffering: true,
      };
    default:
      return state;
  }
}
