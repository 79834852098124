import anime from 'animejs';
import {
  insertToTimeline,
  createFabricLayerFades,
  createFabricVideoFades,
  createKenburns,
} from '@components/VideoPreview/animations';

export function buildAnimeTimelines({
  playing,
  layers,
  shapeInstances,
  titleInstances,
  textInstances,
  backgroundInstances,
}) {
  //
  // Build audio timeline
  //
  if (!playing && backgroundInstances) {
    window.audioController = anime.timeline({
      loop: false,
      autoplay: false,
    });
    window.audioTracks = [];
    backgroundInstances.forEach((backgroundInstance) => {
      const audibleFrom = backgroundInstance.visibleFrom;
      const audibleTo = backgroundInstance.visibleTo;
      const audioProperties = {
        volume: '0',
      };
      if (backgroundInstance.audioSrc && backgroundInstance.enableClipAudio) {
        window.audioTracks.push({
          id: backgroundInstance.id,
          audioFile: new window.Audio(backgroundInstance.audioSrc),
          audibleFrom,
          audibleTo,
          volume: 0,
          ready: false,
        });
        const audioObj = window.audioTracks.find(clip => clip.id === backgroundInstance.id);
        audioObj.audioFile.addEventListener('canplaythrough', () => {
          audioObj.ready = true;
        });
        window.audioController.add({
          targets: audioProperties,
          volume: '100',
          duration: 50,
          easing: 'linear',
          round: 100,
          update: () => {
            audioObj.audioFile.volume = audioProperties.volume / 100;
          },
        }, audibleFrom).add({
          targets: audioProperties,
          volume: '0',
          duration: 50,
          easing: 'linear',
          round: 100,
          update: () => {
            audioObj.audioFile.volume = audioProperties.volume / 100;
          },
        }, audibleTo);
      }
    });
  }

  //
  // Build ken-burns animations for images
  //
  window.kenburnsController = anime.timeline({
    loop: false,
    autoplay: false,
  });
  if (backgroundInstances && backgroundInstances.length > 0) {
    createKenburns({
      instances: backgroundInstances,
    });
  }

  //
  // Build layer fades
  //
  window.fabricLayerController = anime.timeline({
    loop: false,
    autoplay: false,
  });
  if (layers && layers.length > 0) {
    createFabricLayerFades({
      instances: layers,
    });
  }

  //
  // Build shape layer fades
  //
  // PS. For some reason Anime timelines won't pause if they're created before if statement here
  // but this is only when targeting CSS. If targeting fabric objects, then initialize timeline
  // first, then call create function after the if statement.
  if (!playing && shapeInstances && shapeInstances.length > 0) {
    window.shapeLayerController = anime.timeline({
      loop: false,
      autoplay: false,
    });
    shapeInstances.forEach((shape) => {
      const durationIn = shape.animationIn ? 500 : 1;
      const durationOut = shape.animationOut ? 500 : 1;
      window.shapeLayerController.add({
        duration: durationIn,
        targets: `.shapeLayer-${shape.id}`,
        opacity: 0,
        easing: 'linear',
      }, 0).add({
        duration: durationIn,
        targets: `.shapeLayer-${shape.id}`,
        opacity: 1,
        easing: 'linear',
      }, shape.visibleFrom)
        .add({
          duration: durationOut,
          targets: `.shapeLayer-${shape.id}`,
          opacity: 0,
          easing: 'linear',
        }, shape.visibleTo - durationOut);
    });
  }
  // image layer fade animation
  if (layers && layers.length > 0) {
    window.imageLayerController = anime.timeline({
      loop: false,
      autoplay: false,
    });
    layers.forEach((layer) => {
      const durationIn = layer.animationIn ? 500 : 1;
      const durationOut = layer.animationOut ? 500 : 1;
      window.imageLayerController.add({
        duration: durationIn,
        targets: `.imageLayer-${layer.id}`,
        opacity: 0,
        easing: 'linear',
      }, 0).add({
        duration: durationIn,
        targets: `.imageLayer-${layer.id}`,
        opacity: 1,
        easing: 'linear',
      }, layer.visibleFrom)
        .add({
          duration: durationOut,
          targets: `.imageLayer-${layer.id}`,
          opacity: 0,
          easing: 'linear',
        }, layer.visibleTo - durationOut);
    });
  }

  //
  // Build background fades
  //
  window.fabricVideoController = anime.timeline({
    loop: false,
    autoplay: false,
  });
  if (backgroundInstances && backgroundInstances.length > 0) {
    createFabricVideoFades({
      instances: backgroundInstances,
    });
  }

  //
  // Build title animations
  //
  if (!playing && textInstances) {
    window.animationController = anime.timeline({
      loop: false,
      autoplay: false,
    });
    textInstances.sort((a, b) => a.visibleFrom - b.visibleFrom).forEach((instance, textIndex) => {
      if (textIndex === 0) {
        window.animationController.add({
          duration: instance.visibleFrom,
        });
      }
      if (textIndex > 0) {
        window.animationController.add({
          duration: instance.visibleFrom - textInstances[textIndex - 1].visibleTo,
        });
      }
      if (instance.animation) {
        insertToTimeline({
          name: instance.animation.name,
          textInstance: instance,
        });
      } else {
        insertToTimeline({
          name: 'none',
          textInstance: instance,
        });
      }
    });
  }
}

export function playTimelines() {
  if (window.animationController) {
    window.animationController.play();
  }
  window.audioController.play();
  if (window.shapeLayerController) {
    window.shapeLayerController.play();
  }
  window.fabricVideoController.play();
  window.kenburnsController.play();
}

export function pauseTimelines() {
  if (window.animationController) {
    window.animationController.pause();
  }
  window.audioController.pause();
  if (window.shapeLayerController) {
    window.shapeLayerController.pause();
  }
  if (window.imageLayerController) {
    window.imageLayerController.pause();
  }
  window.fabricVideoController.pause();
  window.kenburnsController.pause();
}

export function seekTimelines(timestamp) {
  if (window.fabricVideoController) {
    window.fabricVideoController.seek(timestamp + 1);
  }
  if (window.shapeLayerController) {
    window.shapeLayerController.seek(timestamp + 1);
  }
  if (window.imageLayerController) {
    window.imageLayerController.seek(timestamp + 1);
  }
  if (window.kenburnsController) {
    window.kenburnsController.seek(timestamp + 1);
  }
  if (window.animationController) {
    window.animationController.seek(timestamp + 1);
  }
  if (window.audioController) {
    window.audioController.seek(timestamp + 1);
  }
}
