/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
import InputNumber from '@components/InputNumber';
import Switch from '@material-ui/core/Switch';
import PositionGrid from '@components/PositionGrid';
import 'rc-slider/assets/index.css';
import { Time, TrashCan, StopWatch } from '@components/Icons';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snap from 'snapsvg-cjs';
import ColorSwatches from './Colors';

const propTypes = {
  activeShape: PropTypes.object,
  selectedShapes: PropTypes.array,
  visibilityTimes: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onShowVisiblityHandler: PropTypes.func.isRequired,
  showVisibilityHandler: PropTypes.bool,
  backgroundInstanceTimes: PropTypes.array.isRequired,
  compositionDuration: PropTypes.number.isRequired,
  compositionWidth: PropTypes.number,
  compositionHeight: PropTypes.number,
  onModifyShape: PropTypes.func,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  onUpdateTimestamps: PropTypes.func,
  onApplyAnimation: PropTypes.func,
  onSidebarAdjusters: PropTypes.func,
  onTriggerNotification: PropTypes.func,
  shapeInstances: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const ToolbarShapelayer = ({
  activeShape,
  onRemove,
  onClose,
  onShowVisiblityHandler,
  onSidebarAdjusters,
  compositionWidth,
  compositionHeight,
  compositionDuration,
  onUpdateTimestamps,
  onTriggerNotification,
  onModifyShape,
}) => {
  if (!activeShape) {
    onClose();
    return null;
  }
  const initialFrom = Number(activeShape.visibleFrom / 1000).toFixed(1);
  const initialTo = Number(activeShape.visibleTo / 1000).toFixed(1);
  const [newFrom, setNewFrom] = useState(null);
  const [newTo, setNewTo] = useState(null);
  const [screenWidth, updateScreenWidth] = useState(window.innerWidth);
  const [dropDowns, toggleDropDowns] = useState(null);
  const isAlwaysVisible =
      (activeShape.visibleTo - activeShape.visibleFrom) === compositionDuration;
  const [alwaysVisible, toggleAlwaysVisible] = useState(isAlwaysVisible);

  const notifications = {
    maxExceeded: 'The length of the layer cannot exceed composition duration',
    minDuration: 'The length of the layer must be minimum 1 sec',
  };

  const handleDelete = () => onRemove(activeShape.id);
  const handleResize = () => {
    updateScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => { // eslint-disable-line consistent-return
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const isLongerThanComposition = activeShape.visibleTo > compositionDuration;
    if (activeShape.alwaysVisible || isLongerThanComposition) {
      onUpdateTimestamps({
        visibleFrom: 0,
        visibleTo: compositionDuration,
      }, activeShape.id);
    }
    if (!activeShape.settings) {
      Snap.load(activeShape.src, (data) => {
        // children of the loaded svg
        const childrenProps = [];
        // get all children and their props from loaded svg
        data.selectAll('*').items.forEach((element, index) => {
          // TODO: expand this setting to include gradient coloring,
          // currently it supports only solid fills
          childrenProps.push({
            id: parseInt(`${Date.now()}${Math.round(Math.random() * 1000000)}`, 10),
            index,
            type: element.type,
            attr: element.attr() || null,
          });
        });
        // save modifications to redux state as settings on active shape,
        onModifyShape({
          id: activeShape.id,
          settings: childrenProps,
        });
      });
    }
  });
  // shape's saved settings / atributes applied to each child of svg

  const handleLayerMove = (position) => {
    const { left, top } = position;
    const width = document.querySelector(`.shapeLayer-${activeShape.id}`).offsetWidth;
    const height = document.querySelector(`.shapeLayer-${activeShape.id}`).offsetHeight;
    onModifyShape(activeShape.id, {
      left: left - (width / 2),
      top: top - (height / 2),
    });
  };

  const fadeInEnabled = activeShape.animationIn;
  const fadeOutEnabled = activeShape.animationOut;

  const handleToggleFadeIn = (e) => {
    if (!activeShape.animationIn) {
      onModifyShape(activeShape.id, {
        animationIn: true,
      });
    } else {
      onModifyShape(activeShape.id, {
        animationIn: false,
      });
    }
    e.target.blur();
  };

  const handleToggleFadeOut = (e) => {
    if (!activeShape.animationOut) {
      onModifyShape(activeShape.id, {
        animationOut: true,
      });
    } else {
      onModifyShape(activeShape.id, {
        animationOut: false,
      });
    }
    e.target.blur();
  };


  // user should be able to toggle layer visiblity to always show/ entire video.
  const handleAlwayVisible = (e) => {
    onModifyShape(activeShape.id, {
      alwaysVisible: e.target.checked,
    });
    e.target.blur();
    toggleAlwaysVisible(!alwaysVisible);
  };

  const onChangeFunction = (e) => {
    if (e.target.name === 'newTo') {
      setNewTo(Number(e.target.value) * 1000);
    } else {
      setNewFrom(Number(e.target.value) * 1000);
    }
  };
  const onBlurFunction = (e) => {
    if (e.target.name === 'newTo') {
      const checkedForMin = ((newTo - activeShape.visibleFrom) > 1000);
      const checkedForMax = (newTo < compositionDuration);
      let checkedForLimit;
      if (checkedForMin && checkedForMax) {
        checkedForLimit = newTo;
      } else if (!checkedForMin) {
        checkedForLimit = activeShape.visibleFrom + 1000;
      } else if (!checkedForMax) {
        checkedForLimit = compositionDuration;
      }
      toggleAlwaysVisible(false);
      onUpdateTimestamps({
        visibleFrom: activeShape.visibleFrom,
        visibleTo: checkedForLimit,
        alwaysVisible: false,
      }, activeShape.id);
    } else {
      const checkedForMin = (newFrom > 0);
      const checkedForMax = ((activeShape.visibleTo - newFrom) < 1000);
      let checkedForLimit;
      if (checkedForMin && !checkedForMax) {
        checkedForLimit = newFrom;
      } else if (!checkedForMin) {
        checkedForLimit = 0;
      } else if (checkedForMax) {
        checkedForLimit = activeShape.visibleTo - 1000;
      }
      toggleAlwaysVisible(false);
      onUpdateTimestamps({
        visibleFrom: checkedForLimit,
        visibleTo: activeShape.visibleTo,
        alwaysVisible: false,
      }, activeShape.id);
    }
  };
  const textStylesGroup = screenWidth < 1360 ? 'Toolbar__dropDown row' : 'ToolbarGroup';

  return (
      <Fragment>
          <div className="ToolbarGroup Thumbnail">
            <div className="ToolbarThumbnail">
          <img style={{ height: 36, width: 36, margin: "0 5px", }} src={activeShape.src} alt="toolbar thumbnail" />
            </div>
          </div>
          <div className="ToolbarGroup">
            <div className="ToolbarItem duration">
              <StopWatch/>
              <InputNumber
                name = 'newFrom'
                onBlurFunction = {onBlurFunction}
                onChangeFunction = {onChangeFunction}
                initialInput = {initialFrom}
                onTriggerNotification = {onTriggerNotification}
                notifications = {notifications}
                max = {activeShape.visibleTo - 1000}
                min = {0}
              />
              <Time/>
              <InputNumber
                  name = 'newTo'
                  onBlurFunction = {onBlurFunction}
                  onChangeFunction = {onChangeFunction}
                  initialInput = {initialTo}
                  onTriggerNotification = {onTriggerNotification}
                  notifications = {notifications}
                  max = {compositionDuration}
                  min = {activeShape.visibleFrom + 1000}
              />
            </div>
            {screenWidth < 1360 && (
              <div
                className="ToolbarItem"
                onClick={() => {
                  if (dropDowns !== 'animations') {
                    toggleDropDowns('animations');
                  } else {
                    toggleDropDowns('');
                  }
                }}
              >
                <Button iconBtn={true}>
                    Animations
                </Button>
              </div>
            )}
            {(screenWidth > 1360 || (screenWidth < 1360 && dropDowns === 'animations')) && (
              <div className = {textStylesGroup}>
              <div className="ToolbarItem Switch">
                <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
                  <FormControlLabel
                    control={<Switch size="small" checked={alwaysVisible} onChange={handleAlwayVisible} />}
                    label="Always Visible"/>
                </FormGroup>
              </div>
              <div className="ToolbarItem Switch">
                <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
                  <FormControlLabel
                    control={<Switch size="small" checked={fadeInEnabled} onChange={handleToggleFadeIn} />}
                    label="Fade-in"
                  />
                </FormGroup>
              </div>
              <div className="ToolbarItem Switch">
                <FormGroup className="ContextSettingsGrid__Item-hide" row component="fieldset">
                  <FormControlLabel
                    control={<Switch size="small" checked={fadeOutEnabled} onChange={handleToggleFadeOut} />}
                    label="Fade-out"
                  />
                </FormGroup>
              </div>
            {/* <div className="ToolbarGroup">
                <div className="ToolbarItem">
                  <ColorSwatches
                    currentSettings ={activeShape.settings || []}
                    onSidebarAdjusters={onSidebarAdjusters}
                    toggleColorPicker={() => {}}
                  />
                </div>
              </div> */}
            </div>)}
          </div>
          <section className="Toolbar__right">
            <div className="ToolbarGroup">
              <PositionGrid
                context={'layer'}
                activeLayer={activeShape}
                onSelect={handleLayerMove}
                compositionWidth={compositionWidth}
                compositionHeight={compositionHeight}
              />
            </div>
            <div className="ToolbarGroup CopyAndDelete">
              <div className="ToolbarItem delete" onClick={() => handleDelete()}>
                  <Button
                  iconBtn={true}
                  >
                    <TrashCan/>
                  </Button>
              </div>
            </div>
          </section>
      </Fragment>
  );
};

ToolbarShapelayer.propTypes = propTypes;

export default ToolbarShapelayer;
