import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.scss';

const Button = React.forwardRef(({
  lightBg = false,
  playing,
  className,
  children,
  iconBtn,
  onClick,
  color,
  loading,
  withIcon,
  disabled,
  style,
}, ref) => {
  const classes = classNames(
    'Button',
    className,
    {
      'Button--playing': playing,
      'Button--light-bg': lightBg,
      'Button--icon-only': iconBtn,
      'Button--with-icon': withIcon,
      'Button--disabled': disabled,
      [`Button--${color}`]: color,
      Button__loading: loading,
    },
  );

  const runOnClick = (e) => {
    if (onClick) onClick(e);
    e.target.blur();
  };

  return (
    <button
      className={classes}
      onClick={e => runOnClick(e)}
      disabled={disabled}
      ref={ref}
      style={style}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  lightBg: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  iconBtn: PropTypes.bool,
  withIcon: PropTypes.bool,
  playing: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['white', 'orange', 'pink', 'red', 'purple']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default Button;
