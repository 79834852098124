import React, { useState, useEffect, useRef } from 'react';
import { DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
import { getResizeHandle } from '@helpers/newtimeline';
import './NewTimeline.scss';


const outPointTarget = {
  hover(props, monitor) {
    props.toggleTransition(false);
    props.toggleHovering(false, props.hoveringInstance);
    if (monitor.getItem().list && props.poolIndex === 1) {
      if (
        props.backgroundTrack[props.index - 1] &&
        props.backgroundTrack[props.index - 1].visibleTo <
        props.backgroundTrack[props.index].visibleFrom - 3 &&
        props.index !== props.backgroundTrack.length - 1
      ) {
        props.changeIndex(props.index + 1, props.onIndex);
        props.onDrag(props.instance.id, 'outPoint', props.backgroundAddPosition.current);
        props.changeBackgroundAddPosition(props.backgroundAddPosition, props.index);
      } else if (
        props.index ===
        props.backgroundTrack.length - 1
        && props.duration === props.backgroundTrack[props.backgroundTrack.length - 1].visibleTo
      ) {
        props.changeIndex(props.index + 1, props.onIndex);
        props.onDrag(props.instance.id, 'outPoint', props.backgroundAddPosition.current);
        props.changeBackgroundAddPosition(props.backgroundAddPosition, props.index);
      } else if (props.index === 0 && props.backgroundTrack[0].visibleFrom > 10) {
        props.changeIndex(props.index + 1, props.onIndex);
        props.onDrag(props.instance.id, 'outPoint', props.backgroundAddPosition.current);
        props.changeBackgroundAddPosition(props.backgroundAddPosition, props.index);
      }
    }
  },

  // drop(props, monitor, component) {},
};

const outPointCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  getItem: monitor.getItem(),
  canDrop: monitor.canDrop(),
});

const OutPoint = (props) => {
  const {
    instance,
    // duration,
    msToPixel,
    index,
    onVideoResize,
    onTextResize,
    onUpdateShape,
    onUpdateLayer,
    instances,
    poolType,
    backgroundTrack,
  } = props;

  const { id, visibleTo, visibleFrom } = instance;
  const [state, _setState] = useState({});
  const [handleVisible, toggleHandleVisible] = useState(false);
  const stateRef = useRef(state);
  const [selected, select] = useState(false);
  const setState = (data) => {
    stateRef.current = data;
    _setState(data);
  };
  useEffect(() => {
    if (id === props.activeBackgroundId ||
      id === props.activeShapeId ||
      id === props.activeTextId ||
      id === props.activeLayerId) {
      toggleHandleVisible(true);
      select(true);
    } else {
      toggleHandleVisible(false);
      select(false);
    }
    const widthOnTimeline = (visibleTo - visibleFrom) * msToPixel;
    setState({
      trackHeight: document
        .getElementsByClassName('track')[0]
        .getBoundingClientRect().height,
      trackY: document
        .getElementsByClassName('track')[0]
        .getBoundingClientRect().top,
      left: visibleFrom * msToPixel,
      widthOnTimeline,
      isResizingOut: false,
      isMoving: false,
      initialOut: null,
      initialIn: null,
      dragStart: {
        x: null,
        y: null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const mouseMove = (e) => {
    const {
      initialWidth,
      initialIn,
      isResizingOut,
      dragStart,
    } = stateRef.current;
    // Check whether there are other clips on the same track
    if (isResizingOut) {
      const nextClipIn = instances[index + 1]
        ? instances[index + 1].visibleFrom * msToPixel
        : props.duration * msToPixel;
      const diffX = e.pageX - dragStart.x;
      let newWidth;
      const minOutPoint = initialIn + (350 * msToPixel);
      if (initialIn + initialWidth + diffX < nextClipIn && initialIn + initialWidth + diffX > minOutPoint) {
        newWidth = initialWidth + diffX;
      } else if (initialIn + initialWidth + diffX < minOutPoint) {
        newWidth = minOutPoint - initialIn;
      } else if (poolType === 'background' && (instance.type === 'video' || instance.type === 'image')) {
        newWidth = initialWidth + diffX;
      } else {
        newWidth = nextClipIn - initialIn;
      }


      if (poolType === 'background' && (instance.type === 'video' || instance.type === 'image')) {
        document.getElementById(id).style.width = `${newWidth}px`;
        backgroundTrack.forEach((clip) => {
          if (clip.visibleTo > visibleTo) {
            document.getElementById(clip.id).style.left = `${
              (clip.visibleFrom * msToPixel) - (initialWidth - newWidth)
            }px`;
          }
        });
      } else {
        document.getElementById(id).style.width = `${newWidth}px`;
      }
      setState({
        ...stateRef.current,
        widthOnTimeline: newWidth,
      });
    }
  };

  const mouseUp = () => {
    props.toggleTransition(true);
    if (document.getElementById(id).getElementsByClassName('dragHandle').length > 0) {
      document.getElementById(id).getElementsByClassName('instanceIcon')[0].style.opacity = 1;
      document.getElementById(id).getElementsByClassName('dragHandle')[0].style.pointerEvents = 'auto';
    }
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', mouseUp);
    const { widthOnTimeline, left } = stateRef.current;
    const newOutPoint = Math.floor((left + widthOnTimeline) / msToPixel);
    const newInstance = {
      inPoint: Math.floor(left / msToPixel),
      outPoint: newOutPoint,
      id,
      type: instance.type,
    };
    if (
      poolType === 'background' &&
      (instance.type === 'video' || instance.type === 'image')
    ) {
      onVideoResize(newInstance.id, newInstance.inPoint, newInstance.outPoint);
    } else if (instance.type === 'text') {
      onTextResize(newInstance.id, newInstance.inPoint, newInstance.outPoint);
    } else if (instance.type === 'shape') {
      onUpdateShape({
        id: newInstance.id,
        visibleFrom: newInstance.inPoint,
        visibleTo: newInstance.outPoint,
      });
    } else if (instance.type === 'image') {
      onUpdateLayer(
        {
          id: newInstance.id,
          visibleFrom: newInstance.inPoint,
          visibleTo: newInstance.outPoint,
          track: props.trackIndex,
        },
        newInstance.id,
      );
    } else if (instance.type === 'logo') {
      onUpdateLayer(
        {
          id: newInstance.id,
          visibleFrom: newInstance.inPoint,
          visibleTo: newInstance.outPoint,
          track: props.trackIndex,
        },
        newInstance.id,
      );
    }
  };

  const mouseDown = (e) => {
    props.toggleTransition(false);
    if (document.getElementById(id).getElementsByClassName('dragHandle').length > 0) {
      document.getElementById(id).getElementsByClassName('instanceIcon')[0].style.opacity = 0;
      document.getElementById(id).getElementsByClassName('dragHandle')[0].style.pointerEvents = 'none';
    }
    const { widthOnTimeline } = stateRef.current;
    const resizeHandle = getResizeHandle(e);
    if (resizeHandle === 'right') {
      setState({
        ...stateRef.current,
        isResizingOut: true,
        dragStart: { x: e.pageX, y: e.pageY },
        initialOut: visibleTo * msToPixel,
        initialWidth: widthOnTimeline,
        initialIn: visibleFrom * msToPixel,
      });
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
  };

  const { connectDropTarget } = props;
  if (stateRef.current.isResizingOut) {
    return connectDropTarget(
      <div/>,
    );
  }

  return connectDropTarget(
    <div
      className="resizeHandler outPoint"
      onMouseEnter={() => toggleHandleVisible(true)}
      onMouseLeave={
        () => {
          if (!selected) {
            toggleHandleVisible(false);
          }
        }
      }
      onMouseDown={mouseDown}
      style={{ opacity: handleVisible ? 1 : 0 }} />,
  );
};

OutPoint.propTypes = {
  isDragging: PropTypes.bool,
  instance: PropTypes.object,
  msToPixel: PropTypes.number,
  index: PropTypes.number,
  instances: PropTypes.array,
  tracks: PropTypes.array,
  trackIndex: PropTypes.number,
  trackHeight: PropTypes.number,
  trackY: PropTypes.number,
  modifyComposition: PropTypes.func,
  composition: PropTypes.object,
  poolIndex: PropTypes.number,
  onUpdateLayer: PropTypes.func,
};

OutPoint.defaultProps = {};

export default DropTarget(
  ['video', 'image'],
  outPointTarget,
  outPointCollect,
)(OutPoint);
