import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Resizable from 're-resizable';
import { RESIZE_CONSTRAINTS } from '@constants/timeline';
import 'rc-slider/assets/index.css';
import InstanceBackground from './InstanceBackground';
import './Overlay.scss';

const propTypes = {
  visibilityTimes: PropTypes.object.isRequired,
  activeLayer: PropTypes.object,
  activeShape: PropTypes.object,
  onTimeChange: PropTypes.func.isRequired,
  onUpdateShape: PropTypes.func,
  show: PropTypes.bool,
  backgroundInstanceTimes: PropTypes.array.isRequired,
  compositionDuration: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

const Overlay = ({
  activeLayer,
  activeShape,
  onTimeChange,
  onUpdateShape,
  compositionDuration,
  width,
  show,
}) => {
  const msPxRatio = width / compositionDuration;
  const [maxWidth, setMaxWidth] = useState(undefined);
  const [delta, setDelta] = useState(0);

  if (!activeLayer && !activeShape) return null;

  const visibilityTimes = {
    visibleFrom: (activeLayer && activeLayer.visibleFrom) || (activeShape && activeShape.visibleFrom),
    visibleTo: (activeLayer && activeLayer.visibleTo) || (activeShape && activeShape.visibleTo),
  };
  const { visibleFrom, visibleTo } = visibilityTimes;

  const handleVisibilityChange = (params) => {
    setDelta(0);
    if (activeLayer) {
      onTimeChange(...params, activeLayer.id, msPxRatio);
    }
    if (activeShape) {
      if (params[1] === 'right') {
        const newVisibleTo = Math.floor(activeShape.visibleTo + (params[3].width / msPxRatio));
        onUpdateShape({
          id: activeShape.id,
          visibleTo: newVisibleTo,
        });
      }
      if (params[1] === 'left') {
        const newVisibleFrom = Math.floor(activeShape.visibleFrom - (params[3].width / msPxRatio));
        onUpdateShape({
          id: activeShape.id,
          visibleFrom: newVisibleFrom,
        });
      }
    }
  };
  const duration = visibleTo - visibleFrom;
  const getMaxWidth = (direction) => {
    if (direction === 'left') {
      const calculateWidth = (duration + visibleFrom) * msPxRatio;
      setMaxWidth(calculateWidth);
      return;
    }

    const calculateWidth = compositionDuration ?
      (compositionDuration - visibleFrom) * msPxRatio : undefined;
    setMaxWidth(calculateWidth);
  };

  const commonStyle = {
    position: 'fixed',
    bottom: show ? '123px' : 0,
    height: '47px',
    transition: 'opacity .25s, bottom .25s',
    opacity: show ? 1 : 0,
  };
  // Instance times doesn't have the ending timestamp of composition
  // so that needs to be added

  const handleResizeStart = ({ dir, del }) => {
    const { width: deltaWidth } = del;
    getMaxWidth(dir);
    if (dir === 'left') {
      setDelta(deltaWidth);
    }
  };

  let imageSource;
  if (activeLayer) {
    imageSource = activeLayer.src;
  }
  if (activeShape) {
    imageSource = activeShape.src;
  }

  return (
    <React.Fragment>
      <InstanceBackground
        imageSrc={imageSource}
        overlay={{
          ...commonStyle,
          width,
          border: '2px solid white',
          borderRadius: '.125rem',
          backgroundColor: 'white',
        }}
      />
      <Resizable
        defaultSize={{
          width: msPxRatio * (visibleTo - visibleFrom),
          height: 47,
        }}
        handleStyles={{
          left: {
            borderLeft: '1rem solid var(--color1)',
            borderRadius: '0.125rem',
          },
          right: {
            borderRight: '1rem solid var(--color1)',
            borderRadius: '0.125rem',
          },
        }}
        size={{
          width: msPxRatio * (visibleTo - visibleFrom),
          height: 47,
        }}
        minWidth={100}
        maxWidth={maxWidth}
        enable={RESIZE_CONSTRAINTS}
        style={{
          ...commonStyle,
          marginLeft: msPxRatio * visibleFrom,
          transform: `translateX(${delta * -1}px)`,
        }}
        className="overlay-resizer"
        onResizeStop={(...params) => handleVisibilityChange(params)}
        onResize={(ev, dir, el, del) => handleResizeStart({ dir, del })}
      />
    </React.Fragment>
  );
};

Overlay.propTypes = propTypes;

export default Overlay;
