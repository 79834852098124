import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  StarFolder,
  Video,
  Photo,
  Text,
  Music,
  Rocket,
  Shapes,
  TemplateIcon,
  // Expand,
  // Collapse,
  UploadCloud,
} from '@components/Icons';

// import { tabs, contextOverlays } from '@constants/sidebar';
import ProjectsTabContainer from '@containers/ProjectsTabContainer';
import VideosTabContainer from '@containers/VideosTabContainer';
import ImagesTabContainer from '@containers/ImagesTabContainer';
import ShapesTabContainer from '@containers/ShapesTabContainer';
import TextTabContainer from '@containers/TextTabContainer';
import MusicTabContainer from '@containers/MusicTabContainer';
import GenerateTabContainer from '@containers/GenerateTabContainer';
import DesignTabContainer from '@containers/DesignTabContainer';

import UploadsTabContainer from '@containers/UploadsTabContainer';
import SidebarTextOverlayContainer from '@containers/SidebarTextOverlayContainer';
// import SidebarLayerOverlayContainer from '@containers/SidebarLayerOverlayContainer';
// import SidebarVideoOverlayContainer from '@containers/SidebarVideoOverlayContainer';
import SidebarShapeOverlayContainer from '@containers/SidebarShapeOverlayContainer';
import Navigation from './Navigation';
import './Sidebar.scss';

const tabs = {
  projects: 'projects',
  videos: 'videos',
  images: 'images',
  shapes: 'shapes',
  text: 'text',
  music: 'music',
  generate: 'generate',
  template: 'template',
  uploads: 'uploads',
}

const contextOverlays = {
  text: 'text',
  videoInstance: 'videoInstance',
  layer: 'layer',
  shape: 'shape',
}

const navigationItemIcons = {
  [tabs.projects]: <StarFolder />,
  [tabs.generate]: <Rocket />,
  [tabs.uploads]: <UploadCloud />,
  [tabs.videos]: <Video />,
  [tabs.images]: <Photo />,
  [tabs.shapes]: <Shapes />,
  [tabs.text]: <Text />,
  [tabs.music]: <Music />,
  [tabs.template]: <TemplateIcon />,
};

const getIconForTab = (icons, tab) => ({
  ...tab,
  icon: icons[tab.name],
});

/**
 * Get active item name (tab, overlay).
 * If there is no active item, return null.
 */
const getActiveItemName = items => items
  .filter(item => item.active)
  .map(item => (item.name ? item.name : null))
  .shift();

/**
 * Checks that some overlay is active so that the
 * overlay container will be rendered
 * TODO: pass in other instances to determine other types of overlays
 */
const getActiveOverlay = (sidebarState) => {
  if (sidebarState.activeBackgroundId) {
    return contextOverlays.videoInstance;
  }

  if (sidebarState.activeTextId) {
    return contextOverlays.text;
  }

  if (sidebarState.activeLayerId) {
    return contextOverlays.layer;
  }

  if (sidebarState.selectedShapes.length > 0) {
    return contextOverlays.shape;
  }

  return false;
};

const disabledTabs = ['videos', 'images', 'uploads', 'shapes', 'text', 'music', 'template', 'generate'];

const Sidebar = (props) => {
  const {
    onExpandToggle,
    sidebar,
    textInstances,
    onTabToggle,
    compositionUid,
    onSidebarAdjusters,
  } = props;

  const getDisabledTabs = () => {
    if (compositionUid) return [];
    return disabledTabs;
  };

  const renderActiveTab = (activeTabName) => {
    switch (activeTabName) {
      case tabs.generate:
        return <GenerateTabContainer />;
      case tabs.projects:
        return <ProjectsTabContainer />;
      case tabs.videos:
        return <VideosTabContainer />;
      case tabs.images:
        return <ImagesTabContainer />;
      case tabs.shapes:
        return <ShapesTabContainer />;
      case tabs.text:
        return <TextTabContainer />;
      case tabs.music:
        return <MusicTabContainer />;
      case tabs.template:
        return <DesignTabContainer />;
      case tabs.uploads:
        return <UploadsTabContainer />;
      default:
        return <UploadsTabContainer />;
    }
  };

  const renderActiveOverlay = (activeOverlayName) => {
    switch (activeOverlayName) {
      case contextOverlays.videoInstance:
        return null // <SidebarVideoOverlayContainer />;
      case contextOverlays.text:
        return <SidebarTextOverlayContainer />;
      // NOTE:  layer overlay code is now moved to layer toolbar
      // case contextOverlays.layer:
      //   return <SidebarLayerOverlayContainer/>;
      case contextOverlays.shape:
        return <SidebarShapeOverlayContainer />;
      default:
        return null;
    }
  };

  const navTabs = sidebar.tabs.map(tab => getIconForTab(navigationItemIcons, tab));

  /**
   * Render either active overlay or regular sidebar content
   */
  const renderSidebarContent = (sidebarState) => {
    const activeOverlay = getActiveOverlay(sidebarState);
    const activeTabName = getActiveItemName(sidebar.tabs);

    return (
      <div className="Sidebar__wrapper">
        <div className="ContextSettingsGrid__Row Tabs">
          <div className="Sidebar__nav">
            <Navigation
              items={navTabs}
              onExpandToggle={onExpandToggle}
              onTabToggle={onTabToggle}
              disabledTabs={getDisabledTabs()}
              onSidebarAdjusters={onSidebarAdjusters}
            />
          </div>
          <div className="Sidebar__content">
            {renderActiveOverlay(activeOverlay)}
            {renderActiveTab(activeTabName)}
          </div>
        </div>
      </div>
    );
  };

  const classes = classNames(
    'Sidebar',
    {
      'Sidebar--expanded': sidebar.expanded,
    },
  );

  return (
    <div className={classes}>
      {renderSidebarContent(sidebar, textInstances)}
    </div>
  );
};

Sidebar.propTypes = {
  onExpandToggle: PropTypes.func,
  onTabToggle: PropTypes.func.isRequired,
  onSidebarAdjusters: PropTypes.func.isRequired,
  sidebar: PropTypes.object,
  textInstances: PropTypes.array,
  backgroundInstances: PropTypes.array,
  compositionUid: PropTypes.string,
};
export default Sidebar;
