/* eslint-disable no-unused-vars */
import Button from '@components/Button';
import { RoundPlay } from '@components/Icons';
import { determineCanvasType, determineDimentions } from '@helpers/backgroundInstance';
import Axios from "axios";
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import './GenerateTab.scss';
import VideoPoller from "./VideoPoller";

const propTypes = {
  generate: PropTypes.object,
  onGenerate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  welcome: PropTypes.bool,
  isNewComposition: PropTypes.bool,
  onSearch: PropTypes.func,
  searchSuggestions: PropTypes.array,
  tags: PropTypes.array,
  logoData: PropTypes.object,
  options: PropTypes.object,
  dimensions: PropTypes.object,
  onSearchTags: PropTypes.func,
  onClearTagsSearch: PropTypes.func,
  onCloseWelcome: PropTypes.func,
  relatedTags: PropTypes.array,
  onSearchRelatedTags: PropTypes.func,
  onAddComposition: PropTypes.func,
  activeProjectUid: PropTypes.string,
  setTag: PropTypes.func,
  setLogo: PropTypes.func,
  setOptions: PropTypes.func,
  removeTag: PropTypes.func,
  onUpload: PropTypes.func.isRequired,
  activeUploads: PropTypes.array,
  footagePages: PropTypes.array,
  onSelectProject: PropTypes.func,
  recentProjectUid: PropTypes.string,
  view: PropTypes.string,
};

const GenerateTab = ({
  tags,
  logoData,
  options,
  setTag,
  setLogo,
  setOptions,
  removeTag,
  onGenerate,
  loading,
  searchSuggestions,
  onSearchTags,
  onClearTagsSearch,
  relatedTags,
  onSearchRelatedTags,
  onAddComposition,
  activeProjectUid,
  onUpload,
  activeUploads,
  isNewComposition,
  composition,
  footagePages,
  dimensions: { width, height },
  onSelectProject,
  recentProjectUid,
  view,
}) => {
  let initialFormat = determineCanvasType(width, height);
  if (initialFormat === 'custom') {
    initialFormat = 'horizontal';
  }
  const [mood, setMood] = useState('calm');
  const [length, setLength] = useState('short');
  const [format, setFormat] = useState(initialFormat);
  const [content, setContent] = useState('images');
  const [openModal, setOpenModal] = useState(false);
  const [warningVisible, setWarning] = useState(false);
  const generateButtonRef = useRef(null);
  const stickyBtnBar = classNames('ContextSettingsStickyBottomBar');
  const [pollVideo, setPollVideo] = useState(false);
  const [videoS3Key, setVideoS3Key] = useState("");
  const [requestingRender, setRequestingRender] = useState(false);
  // debounce may not be necessary
  // const debouncedInput = debounce(setOptions, 500);
  const debouncedTagSearch = debounce(onSearchTags, 300);
  const debouncedRelatedSearch = debounce(onSearchRelatedTags, 300);

  const addTag = (addedTag) => {
    debouncedTagSearch(addedTag);
    if (tags.find(tag => tag === addedTag)) return;
    setTag(addedTag);
    debouncedRelatedSearch(addedTag);
  };

  const requestRender = async () => {
    try {
      setRequestingRender(true);
      const res = await Axios.post("https://video-engine.sumo.app/api/render", { duration: composition.duration });
      console.log({ res })
      if (res.status === 200) {
        setPollVideo(true);
        setVideoS3Key(res.data.S3Key);
      }

      setRequestingRender(false);
    } catch (err) {
      console.log({ err })
    }
  };
  const handleGenerate = (overide) => {
    requestRender()

    // if ((view === 'generator' || view === 'dashboard') && recentProjectUid) {
    //   // onSelectProject(recentProjectUid);
    //   if (!overide) {
    //     const dimensions = determineDimentions(format);
    //     onAddComposition(recentProjectUid, 'Generated Video', dimensions);
    //   }
    // }

    // const generatorOptions = {
    //   content, mood, length, tags, format, logoData, options,
    // };
    // onGenerate(generatorOptions, activeProjectUid || recentProjectUid);
    // // Blur button so that if user uses play /pause hotkey, it doesn't
    // // re-generate the composition because of previous button click focus
    // if (generateButtonRef.current) {
    //   generateButtonRef.current.blur();
    // }
  };

  const handleOveride = async (overide) => {
    setWarning(overide);
    const dimensions = determineDimentions(format);
    if (overide) {
      setWarning(false);
      handleGenerate(overide);
    } else {
      await onAddComposition(activeProjectUid, 'Generated Video', dimensions);
      handleGenerate(overide);
    }
  };

  // upload or add logo possible

  const showLogoModal = () => {
    setOpenModal(true);
  };

  const setLogoData = (data) => {
    setLogo(data);
    setOpenModal(false);
  };

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setOptions({
      ...options,
      [name]: value,
    });
  };

  // Remove words already used from the list
  const cleanedRelatedTags = [];
  relatedTags.forEach((tag) => {
    if (!tags.includes(tag)) {
      cleanedRelatedTags.push(tag);
    }
  });

  const relatedTagsItems =
    cleanedRelatedTags.length > 0 &&
    cleanedRelatedTags.map((tag, index) => (
      <li
        key={index}
        className="GenerateTab__RelatedKeywords__Item"
        onClick={() => {
          addTag(tag);
        }}
      >
        {tag}
      </li>
    ));
  return (
    <div className="GenerateTab">
      <div className="VideosTab__heading--section">
        <section>
          <h1 style={{ marginBottom: 15 }}>Export video</h1>
          <Button
            className="sumo-btn sumo-btn-video"
            color="pink"
            loading={loading}
            onClick={handleGenerate}
            ref={generateButtonRef}
          // disabled={tags && tags.length === 0}
          >
            Generate your video <RoundPlay />
          </Button>
        </section>
        <div>{pollVideo && <VideoPoller S3Key={videoS3Key} />}</div>
      </div >
    </div >
  );
};

GenerateTab.propTypes = propTypes;

export default GenerateTab;
