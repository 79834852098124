import React from 'react';
import PropTypes from 'prop-types';
import { Dots } from '@components/Icons';

import ProjectActionsMenu from './ProjectActionsMenu';

import './ProjectActions.scss';

const propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  onClickRename: PropTypes.func.isRequired,
};

const defaultProps = {};

class ProjectActions extends React.Component {
  state = {
    open: false,
  }

  toggleVisibility = () => {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { open } = this.state;
    const {
      onClickDelete,
      onClickRename,
    } = this.props;

    return (
      <div
        className="ProjectActions"
        onClick={this.toggleVisibility}
      >
        <Dots/>
        { open &&
            <ProjectActionsMenu
              onClickOutside={this.toggleVisibility}
              onClickDelete={onClickDelete}
              onClickRename={onClickRename}
            />
        }
      </div>
    );
  }
}

ProjectActions.propTypes = propTypes;
ProjectActions.defaultProps = defaultProps;

export default ProjectActions;
