import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
import { Plus } from '@components/Icons';

import ProjectCard from './ProjectCard';

const ProjectList = ({
  projects,
  onSelect,
  onDelete,
  onRename,
  composition,
  view,
  showProjectModal,
}) => {
  const [isHover, setIsHover] = useState(false);
  const list = allProjects =>
    allProjects.map(project => (
      <div key={project.uid} className="content-grid-col">
        <ProjectCard
          project={project}
          onClick={onSelect}
          onDelete={onDelete}
          onRename={() => onRename(project)}
          composition={composition}
          view={view}
        />
      </div>
    ));

  return (
    <div className="ProjectList content-grid">
      {list(projects)}
      {view !== 'dashboard' && (
        <section className="content-grid-col ProjectsTab__button">
          <section
            className="ProjectCard"
            onMouseOver={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
          >
            <Button
              onClick={showProjectModal}
              className="ProjectsTab__new-project-btn"
              color="white"
              withIcon={true}
            >
              {!isHover ? 'Add New folder' : <Plus />}
            </Button>
          </section>
        </section>
      )}
    </div>
  );
};

ProjectList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  showProjectModal: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  projects: PropTypes.array,
  composition: PropTypes.object,
  view: PropTypes.string,
};

export default ProjectList;
