import React from 'react';
import PropTypes from 'prop-types';

import './InstanceBackground.scss';

const InstanceBackground = ({ imageSrc, overlay }) => {
  const style = {
    backgroundImage: `url(${imageSrc})`,
  };
  if (overlay) {
    return <div className="InstanceBackground" style={{ ...overlay, ...style }} />;
  }

  return <div className="InstanceBackground" style={style} />;
};

InstanceBackground.propTypes = {
  filename: PropTypes.string,
  overlay: PropTypes.object,
  imageSrc: PropTypes.string,
};

export default InstanceBackground;
