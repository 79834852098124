import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal';
import './VideoPreview.scss';

const propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  confirmButton: PropTypes.element.isRequired,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  visible: false,
};

function ShortcutsModal(props) {
  return (
    <Modal
      visible={props.visible}
      title={props.title}
      onClose={props.onClose}
    >
      {props.children}
      <span>Global:</span>
      <ul>
        <li><b>Space</b> - Play/Pause</li>
      </ul>
      <span>Selected text object</span>
      <ul>
        <li><b>Arrow keys</b> - Move (hold Shift to move more)</li>
      </ul>
      <span>Timeline (when text, video or image is selected):</span>
      <ul>
        <li><b>Shift</b> - Select multiple items from timeline </li>
        <li><b>D</b> - Duplicate a video clip or text title</li>
        <li><b>C</b> - Razor tool (divides the video into two)</li>
        <li><b>Backspace </b> - Delete</li>
        <li><b>Ctrl/cmd + Z </b>- Undo </li>
        <li><b>Ctrl/cmd + Shift + Z </b>- Redo</li>
      </ul>
      <div className="ShortcutsModal__button-container">
        {props.confirmButton}
      </div>
    </Modal>
  );
}

ShortcutsModal.propTypes = propTypes;
ShortcutsModal.defaultProps = defaultProps;

export default ShortcutsModal;
