const applyAttributeToText = (instances, itemId, attributeObject) => instances
  .map((instance) => {
    if (instance.id !== itemId) return instance;
    const { key, value } = attributeObject;
    if (key === 'fontSize') {
      return {
        ...instance,
        [key]: value,
        scaleX: 1,
        scaleY: 1,
      };
    }

    return {
      ...instance,
      [key]: value,
    };
  });

// Used when pasting styles
const applyAttributesToText = (instances, itemId, attributesObject) => instances
  .map((instance) => {
    if (instance.id !== itemId) return instance;
    return {
      ...instance,
      ...attributesObject,
    };
  });

const setNewTextValue = (instances, itemId, text) => instances
  .map((instance) => {
    if (instance.id !== itemId) return instance;

    return {
      ...instance,
      text,
      initial: instance.text,
    };
  });

const applyAttributesToAll = (itemId, textInstances) => {
  const item = typeof itemId === 'object' ? itemId[0] : itemId;
  const [currentTextAttributes] = textInstances.filter(instance => instance.id === item);
  return textInstances.map((instance) => {
    if (instance.id === item) return instance;
    return {
      ...instance,
      backgroundColor: currentTextAttributes.backgroundColor,
      fill: currentTextAttributes.fill,
      fontFamily: currentTextAttributes.fontFamily,
      fontSize: currentTextAttributes.fontSize,
      fontStyle: currentTextAttributes.fontStyle,
      fontWeight: currentTextAttributes.fontWeight,
      textAlign: currentTextAttributes.textAlign,
      scaleX: currentTextAttributes.scaleX,
      scaleY: currentTextAttributes.scaleY,
      shadow: currentTextAttributes.shadow,
      stroke: currentTextAttributes.stroke,
      strokeWidth: currentTextAttributes.strokeWidth,
      angle: currentTextAttributes.angle,
      top: currentTextAttributes.top,
      left: currentTextAttributes.left,
      borderWidth: currentTextAttributes.borderWidth,
      borderColor: currentTextAttributes.borderColor,
      borderRadius: currentTextAttributes.borderRadius,
      width: currentTextAttributes.width,
    };
  });
};

export {
  applyAttributeToText,
  applyAttributesToText,
  setNewTextValue,
  applyAttributesToAll,
};
