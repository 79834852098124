import * as types from '@constants/actionTypes';
import { getMaxDuration, MAX_VIDEO_DURATION } from '@constants/composition';
import { calculateZoom } from '@helpers/animation';
import { modifySourceItem } from '@helpers/backgroundInstance';
import { feedTextToTracks, updateTitleInstances } from '@helpers/composition';
import { getLogoLayer } from '@helpers/layer';
import { getReversionedComposition } from '@helpers/reversion';
import {
  checkPushingRequired,
  modifyTextInstances
} from '@helpers/textInstance';
import { addTrackPropertyToInstances } from '@helpers/timeline';
import cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';

import {
  calculateCompositionDuration,
  calculateCompositionInstanceStartTimes, duplicateInstances,
  duplicateTextInstances, splitVideoAtTimestamp, toggleAudio
} from './utils/composition';

import {
  applyAttributesToAll, applyAttributesToText, applyAttributeToText, setNewTextValue
} from './utils/text';

const initialState = {
  uid: null,
  duration: 0,
  maxDuration: MAX_VIDEO_DURATION,
  backgroundInstances: [],
  textInstances: [],
  titleInstances: [],
  shapeInstances: [],
  audioInstances: [],
  layers: [],
  // TODO:   recheck if we need null width and height here
  width: null,
  height: null,
  // TODO:  find a proper place for the follwing props
  showVisibilityHandler: false,
  showContext: false,
};

export default function composition(state = initialState, action) {
  const stateBgInstances = [...state.backgroundInstances];
  const stateTextInstances = [...state.textInstances];
  const stateShapeInstances = [...state.shapeInstances];
  const stateLayers = [...state.layers];
  const { maxDuration } = state;

  switch (action.type) {
    case types.COMPOSITION_RESET: {
      console.log("uuid", uuidv4())
      return {
        ...state,
        ...initialState,
        uid: uuidv4()
      };
    }

    case types.COMPOSITION_SHOW_VIDEO_CONTEXT: {
      return {
        ...state,
        showContext: action.payload.showContext,
      };
    }
    case types.COMPOSITION_SHOW_VISIBLITY_HANDLER: {
      return {
        ...state,
        showVisibilityHandler: action.payload.showVisibilityHandler,
      };
    }

    case types.FORMAT_BACKGROUND_INSTANCES: {
      const newInstances = action.payload || stateBgInstances;
      return {
        ...state,
        duration: calculateCompositionDuration(
          newInstances, stateTextInstances, stateShapeInstances, stateLayers,
        ),
        backgroundInstances: calculateCompositionInstanceStartTimes(newInstances),
      };
    }
    case types.COMPOSITION_VIDEO_INSTANCE_ADD: {
      const { item, targetIndex } = action.payload;
      // Add KenBurns for images
      if (item.type === 'image') {
        item.kenburns = true;
        item.zoomEffect = calculateZoom(item);
      }
      const index = targetIndex || stateBgInstances.length;
      const newInstances = [...stateBgInstances];
      newInstances.splice(index, 0, item);
      const compositionDuration = calculateCompositionDuration(
        newInstances,
        stateTextInstances,
        stateShapeInstances,
        stateLayers,
      );
      if (compositionDuration > maxDuration) return state;

      return {
        ...state,
        duration: compositionDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: compositionDuration,
        })),
        backgroundInstances: calculateCompositionInstanceStartTimes(
          newInstances,
        ),
      };
    }

    case types.COMPOSITION_VIDEO_INSTANCE_MOVE: {
      const {
        itemId, visibleFrom, visibleTo, track,
      } = action.payload;
      const newInstances = stateBgInstances.map((instance) => {
        if (instance.id !== itemId) return instance;
        return {
          ...instance,
          visibleFrom,
          visibleTo,
          track,
        };
      });
      newInstances.sort((a, b) => a.visibleFrom - b.visibleFrom);
      return {
        ...state,
        backgroundInstances: newInstances,
      };
    }

    case types.COMPOSITION_VIDEO_INSTANCE_DRAG_TO_TIMELINE: {
      const { itemId, endPoint, backgroundPosition } = action.payload;
      const targetIndex = stateBgInstances.findIndex(
        instance => instance.id === itemId,
      );
      let newInstances = [...stateBgInstances];
      let newCompositionDuration = state.duration;
      if (endPoint === 'inPoint') {
        newInstances = stateBgInstances.map((instance, index) => {
          const isGap = (stateBgInstances[targetIndex + 1] &&
            stateBgInstances[targetIndex + 1].visibleFrom >
            stateBgInstances[targetIndex].visibleTo + 2 &&
            stateBgInstances[stateBgInstances.length - 1].visibleTo !== newCompositionDuration);
          if ((index === targetIndex) || (index > targetIndex &&
            !isGap && backgroundPosition === null)) {
            if (!isGap && backgroundPosition === null) {
              newCompositionDuration = state.duration + 3000;
            }
            return {
              ...instance,
              visibleFrom: instance.visibleFrom + 3000,
              visibleTo: instance.visibleTo + 3000,
            };
          }
          return instance;
        });
      } else if (
        endPoint === 'outPoint' &&
        targetIndex !== stateBgInstances.length - 1
      ) {
        newInstances = stateBgInstances.map((instance, index) => {
          if (index === targetIndex) {
            return {
              ...instance,
              visibleFrom: instance.visibleFrom - 3000,
              visibleTo: instance.visibleTo - 3000,
            };
          }
          return instance;
        });
      } else if (
        endPoint === 'outPoint' &&
        targetIndex === stateBgInstances.length - 1
      ) {
        if (backgroundPosition === undefined || backgroundPosition === null) {
          newCompositionDuration = state.duration + 3000;
        } else {
          newInstances = stateBgInstances.map((instance, index) => {
            if (index === targetIndex) {
              return {
                ...instance,
                visibleFrom: instance.visibleFrom - 3000,
                visibleTo: instance.visibleTo - 3000,
              };
            }
            return instance;
          });
        }
      }
      return {
        ...state,
        duration: newCompositionDuration,
        backgroundInstances: newInstances,
      };
    }

    case types.COMPOSITION_VIDEO_INSTANCE_REMOVE: {
      const { itemId } = action.payload;
      const newInstances = [
        ...state.backgroundInstances.filter(
          instance => instance.id !== itemId,
        ),
      ];

      return {
        ...state,
        activeBackgroundId: null,
        duration: calculateCompositionDuration(
          newInstances,
          state.textInstances,
          stateShapeInstances,
          stateLayers,
        ),
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: calculateCompositionDuration(
            newInstances, state.textInstances, stateShapeInstances, stateLayers,
          ),
        })),
        backgroundInstances: calculateCompositionInstanceStartTimes(
          newInstances,
        ),
      };
    }

    case types.COMPOSITION_VIDEO_INSTANCE_REPLACE: {
      const { newItem, targetId } = action.payload;
      const { width, height } = state;
      const modifiedItem = modifySourceItem(newItem, { width, height });
      const newInstances = [...stateBgInstances];
      const targetIndex = newInstances.findIndex(
        instance => instance.id === targetId,
      );
      newInstances.splice(targetIndex, 1, modifiedItem);
      return {
        ...state,
        duration: calculateCompositionDuration(
          newInstances,
          state.textInstances,
          stateShapeInstances,
          stateLayers,
        ),
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: calculateCompositionDuration(
            newInstances, stateTextInstances, stateShapeInstances, stateLayers,
          ),
        })),
        backgroundInstances: calculateCompositionInstanceStartTimes(
          newInstances,
        ),
      };
    }

    case types.COMPOSITION_VIDEO_GENERATE_UNIQUEID: {
      const { currentId } = action.payload;

      const newInstances = stateBgInstances.map((instance) => {
        if (instance.id !== currentId) return instance;

        return {
          ...instance,
          id: Date.now(),
          isDragging: false,
        };
      });

      return {
        ...state,
        backgroundInstances: newInstances,
      };
    }

    case types.COMPOSITION_LAYER_TIMESLOT_CHANGE: {
      const {
        itemId, delta, msRatio, direction,
      } = action.payload;
      const deltaTime = Math.floor(delta / msRatio);

      return {
        ...state,
        layers: [
          ...state.layers.map((layer) => {
            if (layer.id !== itemId) return layer;
            let { visibleFrom, visibleTo } = layer;
            if (direction === 'left') {
              visibleFrom -= deltaTime;
            } else {
              visibleTo += deltaTime;
            }
            return {
              ...layer,
              visibleFrom,
              visibleTo,
              alwaysVisible: false,
            };
          }),
        ],
      };
    }

    case types.COMPOSITION_VIDEO_INSTANCE_RESIZE: {
      const {
        itemId, visibleFrom, visibleTo, playFrom,
      } = action.payload;
      const duration = visibleTo - visibleFrom;
      const newInstances = stateBgInstances.map((instance) => {
        if (instance.id !== itemId) return instance;
        return {
          ...instance,
          visibleTo,
          playFrom,
          duration,
        };
      });

      // const resiszedInstance = stateBgInstances.find(instance => instance.id === itemId);
      // const linkedTextInstaces = getLinkableTextInstances(textInstances, resiszedInstance);

      // const newTextInstances = textInstances.map((text, index) => {
      //   const instanceVisibleTo = resiszedInstance.visibleTo + deltaTime;
      //   const maxBoundForLinkedText = instanceVisibleTo < text.visibleFrom;
      //   const nextText = textInstances[index + 1];
      //   const prevText = textInstances[index - 1];
      //   const {
      //     overlapsFromLeft, overlapsFromRight,
      //   } = checkOverlapingText({
      //     prevText, text, nextText, deltaTime,
      //   });

      //   if (linkedTextInstaces.includes(text.id)) {
      //     if (direction === 'left') {
      //       const instancePlayFrom = resiszedInstance.visibleFrom - deltaTime;
      //       const minBoundForLinkedText = instancePlayFrom >= text.visibleFrom;

      //       if (prevText && prevText.visibleFrom
      //         <= resiszedInstance.visibleFrom + 1 && overlapsFromLeft) {
      //         if (text.duration <= 1000) {
      //           return text;
      //         }

      //         return {
      //           ...text,
      //           visibleFrom: prevText.visibleTo + 1,
      //           visibleTo: text.visibleTo + deltaTime,
      //           duration: (text.visibleTo + deltaTime) - (prevText.visibleTo + 1),
      //         };
      //       }

      //       if (minBoundForLinkedText) {
      //         return {
      //           ...text,
      //           visibleFrom: resiszedInstance.visibleFrom + 1,
      //           visibleTo: resiszedInstance.visibleFrom + text.duration,
      //         };
      //       }

      //       return {
      //         ...text,
      //         visibleFrom: text.visibleFrom + deltaTime,
      //         visibleTo: text.visibleTo + deltaTime,
      //       };
      //     }

      //     if (maxBoundForLinkedText) {
      //       return {
      //         ...text,
      //         visibleFrom: instanceVisibleTo - 1, // so that it will stay sticky
      //         visibleTo: instanceVisibleTo + text.duration,
      //       };
      //     }
      //   }

      //   if (text.visibleFrom < resiszedInstance.visibleTo) return text;
      //   return {
      //     ...text,
      //     visibleFrom: text.visibleFrom + deltaTime,
      //     visibleTo: text.visibleTo + deltaTime,
      //   };
      // }).filter(text => text.removeText !== true);

      // const newTitleInstances = updateTitleInstances(newTextInstances);
      const compositionDuration = calculateCompositionDuration(
        newInstances,
        stateTextInstances,
        stateShapeInstances,
        stateLayers,
      );
      if (compositionDuration > maxDuration) return state;
      return {
        ...state,
        duration: compositionDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: compositionDuration,
        })),
        backgroundInstances: calculateCompositionInstanceStartTimes(
          newInstances,
        ),
        // textInstances: newTextInstances,
        // titleInstances: newTitleInstances,
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_RESIZE_START: {
      const { itemId } = action.payload;

      const newInstances = state.textInstances.map((instance) => {
        if (instance.id !== itemId) return instance;

        return {
          ...instance,
          resizing: true,
        };
      });

      return {
        ...state,
        textInstances: newInstances,
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_RESIZE: {
      const { itemId, visibleFrom, visibleTo } = action.payload;

      const newInstances = stateTextInstances.map((instance) => {
        if (itemId !== instance.id) return instance;
        let modifiedInstance = instance;
        const { animation } = instance;
        const newInstanceDuration = visibleTo - visibleFrom;

        if (animation) {
          modifiedInstance = {
            ...instance, visibleFrom, visibleTo, duration: newInstanceDuration,
          };
        }

        return {
          ...modifiedInstance,
          duration: newInstanceDuration,
          visibleFrom,
          visibleTo,
          resizing: false,
        };
      });

      const compositionDuration = calculateCompositionDuration(
        state.backgroundInstances,
        newInstances,
        stateShapeInstances,
        stateLayers,
      );

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        duration: compositionDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: compositionDuration,
        })),
        titleInstances: newTitleInstances,
        textInstances: newInstances,
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_MOVE: {
      const {
        itemId, visibleFrom, visibleTo, track,
      } = action.payload;
      const newInstances = stateTextInstances.map((instance) => {
        if (itemId !== instance.id) return instance;
        if (instance.animation) {
          const newAnimInstances = {
            ...instance,
            visibleFrom,
            visibleTo,
            track,
          };

          return newAnimInstances;
        }

        return {
          ...instance,
          visibleFrom,
          visibleTo,
          track,
        };
      });

      const compositionDuration = calculateCompositionDuration(
        state.backgroundInstances,
        newInstances,
        stateShapeInstances,
        stateLayers,
      );

      // Update title layers
      // const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        duration: compositionDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: compositionDuration,
        })),
        textInstances: newInstances,
        // titleInstances: newTitleInstances,
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_SWAP: {
      const { id, withId, direction } = action.payload;

      const theseInstances = state.titleInstances.find(title =>
        title.textInstances.find(text => text.id === id));
      const thoseInstances = state.titleInstances.find(title =>
        title.textInstances.find(text => text.id === withId));

      if (!theseInstances || !thoseInstances) {
        return false;
      }

      // Swap textInstance positions
      const newInstances = stateTextInstances.map((instance) => {
        let { visibleFrom, visibleTo } = instance;
        const thatFrom = thoseInstances.textInstances[0].visibleFrom;
        const thisDuration = theseInstances.textInstances[0].duration;
        const thatDuration = thoseInstances.textInstances[0].duration;
        const durationOffset = thisDuration - thatDuration;
        if (theseInstances.textInstances.find(i => i.id === instance.id)) {
          // SET NEW FROM/TO FOR THE ELEMENT WE ARE DRAGGING
          if (direction === 'left') {
            visibleFrom = thatFrom;
            visibleTo = visibleFrom + thisDuration;
          }
          if (direction === 'right') {
            visibleFrom = thatFrom - durationOffset;
            visibleTo = visibleFrom + thisDuration;
          }
          if (instance.animation) {
            const newAnimInstances = {
              ...instance,
              visibleFrom,
              visibleTo,
            };

            return newAnimInstances;
          }
        } else if (
          thoseInstances.textInstances.find(i => i.id === instance.id)
        ) {
          // SET NEW FROM/TO FOR THE ELEMENT WE ARE SKIPPING OVER
          if (direction === 'left') {
            visibleFrom = thatFrom + thisDuration;
            visibleTo = visibleFrom + thatDuration;
          }
          if (direction === 'right') {
            visibleFrom = thatFrom - thisDuration;
            visibleTo = visibleFrom + thatDuration;
          }
          if (instance.animation) {
            const newAnimInstances = {
              ...instance,
              visibleFrom,
              visibleTo,
            };

            return newAnimInstances;
          }
        } else {
          return instance;
        }

        return {
          ...instance,
          visibleFrom,
          visibleTo,
        };
      });

      const compositionDuration = calculateCompositionDuration(
        state.backgroundInstances,
        newInstances,
        stateShapeInstances,
        stateLayers,
      );

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        duration: compositionDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: compositionDuration,
        })),
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.COMPOSITION_LAYER_MOVE: {
      const { itemId, left, top } = action.payload;

      const newLayers = state.layers.map((layer) => {
        if (layer.id !== itemId) return layer;

        return {
          ...layer,
          left,
          top,
        };
      });

      return {
        ...state,
        layers: newLayers,
      };
    }

    case types.TEXTLAYER_MOVE: {
      const { itemId, left, top } = action.payload;
      const item = typeof itemId === 'object' ? itemId[0] : itemId;
      const newInstances = state.textInstances.map((instance) => {
        if (instance.id !== item) return instance;

        return {
          ...instance,
          left,
          top,
        };
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_SCALE: {
      const {
        itemId, scaleX, scaleY, isGenerated,
      } = action.payload;

      const newInstances = state.textInstances.map((instance) => {
        if (instance.id !== itemId) return instance;

        return {
          ...instance,
          scaleX,
          scaleY,
        };
      });
      let compositionState = state;
      if (isGenerated) {
        const { source, ...compositionWithoutSource } = state;
        compositionState = compositionWithoutSource;
      }
      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...compositionState,
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_ROTATE: {
      const { itemId, ang } = action.payload;
      const newInstances = state.textInstances.map((instance) => {
        if (instance.id !== itemId) return instance;

        return {
          ...instance,
          ang,
        };
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_RESIZE: {
      const { itemId, width, fontSize } = action.payload;

      const newInstances = state.textInstances.map((instance) => {
        if (instance.id !== itemId) return instance;

        return {
          ...instance,
          width,
          fontSize,
        };
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_APPLY_SELECTION_STYLES: {
      const { itemId, styles, attributes } = action.payload;

      const newInstances = state.textInstances.map((instance) => {
        if (instance.id !== itemId) return instance;

        return {
          ...instance,
          ...attributes,
          styles,
        };
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);

      return {
        ...state,
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_APPLY_ATTRIBUTE: {
      const newTextInstances = applyAttributeToText(
        state.textInstances,
        action.payload.itemId,
        action.payload.attributeObject,
      );

      // Update text layers
      const newTitleInstances = updateTitleInstances(newTextInstances);

      return {
        ...state,
        textInstances: newTextInstances,
        titleInstances: newTitleInstances,
      };
    }

    // Used when pasting styles
    case types.TEXTLAYER_APPLY_ATTRIBUTES: {
      const newTextInstances = applyAttributesToText(
        state.textInstances,
        action.payload.itemId,
        action.payload.attributesObject,
      );

      // Update text layers
      const newTitleInstances = updateTitleInstances(newTextInstances);

      return {
        ...state,
        textInstances: newTextInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.COMPOSITION_LOAD: {
      const { composition, compositionVersion, userRole } = action.payload;
      console.log(action.payload)
      const {
        uid,
        width,
        height,
        duration,
        textInstances,
        titleInstances,
        backgroundInstances,
        audioInstances,
        layers,
        shapeInstances,
      } = composition;
      const newTextInstances = feedTextToTracks(textInstances, titleInstances);

      const overlays = [...newTextInstances, ...layers, ...shapeInstances];
      const sortedInstances = compositionVersion === 2 ? {
        textInstances: newTextInstances,
        layers,
        shapeInstances,
        newDuration: duration,
        version: 2,
      } : addTrackPropertyToInstances(overlays, duration);
      // check composition version
      return {
        ...state,
        duration: sortedInstances.newDuration,
        backgroundInstances: backgroundInstances || [],
        audioInstances,
        textInstances: sortedInstances.textInstances || [],
        // titleInstances,
        shapeInstances: sortedInstances.shapeInstances || [],
        layers: sortedInstances.layers || [],
        uid,
        width,
        height,
        maxDuration: getMaxDuration(userRole),
        version: sortedInstances.version,
      };
    }

    case types.COMPOSITION_LOAD_GENERATED: {
      const { composition: generatedComposition } = action.payload;
      const { width, height } = generatedComposition;
      const {
        duration,
        backgroundInstances,
        audioInstances,
        textInstances: generatedtextInstances,
        layers,
      } = generatedComposition.content;

      const modifiedTextInstances = modifyTextInstances(
        generatedtextInstances,
        generatedComposition,
      );
      const modifyVideos = backgroundInstances.map((instance) => {
        // Add KenBurns for images
        if (instance.type === 'image') {
          return {
            ...modifySourceItem(instance, { width, height }),
            kenburns: true,
            zoomEffect: calculateZoom(instance),
          };
        }
        return modifySourceItem(instance, { width, height });
      });

      let modifiedLayer;
      if (layers.length > 0) {
        modifiedLayer = [
          {
            ...layers[0],
            ...getLogoLayer(generatedComposition, layers[0]),
          },
        ];
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
      return {
        duration,
        backgroundInstances: modifyVideos,
        audioInstances,
        textInstances: modifiedTextInstances,
        layers: modifiedLayer || [],
        shapeInstances: [],
        uid: state.uid,
        width,
        height,
        source: 'Generator',
      };
    }

    case types.COMPOSITION_LOAD_DESIGN: {
      const { design } = action.payload;
      const {
        duration,
        backgroundInstances,
        audioInstances,
        layers,
        width,
        height,
        uid,
        textInstances: txtInstances,
        shapeInstances: shapes,
      } = design.content;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
      const shapeLayers = shapes || [];
      const imageLayers = layers || [];
      const texts = txtInstances || [];
      const overlays = [...texts, ...imageLayers, ...shapeLayers];
      const sortedInstances = addTrackPropertyToInstances(overlays, duration);
      return {
        ...state,
        duration: sortedInstances.newDuration,
        backgroundInstances,
        audioInstances,
        textInstances: sortedInstances.textInstances,
        shapeInstances: sortedInstances.shapeInstances || [],
        layers: sortedInstances.layers || [],
        width,
        height,
        uid,
        version: sortedInstances.version,
      };
    }
    case types.COMPOSITION_LOAD_REVERSION: {
      const { composition: load, prevAspectRatio } = action.payload;
      const modified = getReversionedComposition(load.content, prevAspectRatio);
      const {
        duration,
        backgroundInstances,
        audioInstances,
        layers,
        width,
        height,
      } = modified;

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
      return {
        ...state,
        ...modified,
        duration,
        backgroundInstances,
        audioInstances,
        textInstances: modified.textInstances,
        layers,
        width,
        height,
      };
    }

    case types.COMPOSITION_LOAD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.COMPOSITION_LOAD_SUCCESS: {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
      return {
        ...state,
        loading: false,
      };
    }

    case types.TEXTLAYER_CHANGE:
      return {
        ...state,
        textInstances: setNewTextValue(
          state.textInstances,
          action.payload.itemId,
          action.payload.newText,
        ),
      };

    case types.COMPOSITION_TOGGLE_CLIP_AUDIO: {
      const { itemId } = action.payload;
      const { backgroundInstances } = state;

      const newBgInstances = backgroundInstances.map((instance) => {
        if (instance && instance.id === itemId) {
          return {
            ...instance,
            enableClipAudio: !instance.enableClipAudio,
          };
        }
        return instance;
      });

      return {
        ...state,
        backgroundInstances: newBgInstances,
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_ADD: {
      const newTextInstances = [...state.textInstances, action.payload.item];

      const compositionDuration = calculateCompositionDuration(
        stateBgInstances,
        newTextInstances,
        stateShapeInstances,
        stateLayers,
      );

      return {
        ...state,
        // titleInstances: newTitleInstances,
        textInstances: newTextInstances,
        duration: compositionDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: calculateCompositionDuration(state.backgroundInstances, [
            ...state.textInstances,
            action.payload.item,
          ], stateShapeInstances, stateLayers),
        })),
      };
    }

    case types.COMPOSITION_SHAPE_LAYER_ADD: {
      const newShapeInstances = [...stateShapeInstances, action.payload.layerItem];
      return {
        ...state,
        shapeInstances: newShapeInstances,
        duration: calculateCompositionDuration(
          stateBgInstances, stateTextInstances, newShapeInstances, stateLayers,
        ),
      };
    }

    case types.COMPOSITION_SHAPE_LAYER_REMOVE: {
      const newShapeInstances = state.shapeInstances.filter(
        layer => layer.id !== action.payload.layerId,
      );

      return {
        ...state,
        shapeInstances: newShapeInstances,
      };
    }

    case types.COMPOSITION_SHAPE_LAYER_UPDATE: {
      const { layerId, newProperties } = action.payload;
      const newShapeInstances = state.shapeInstances.map((layer) => {
        if (layer.id !== layerId) return layer;
        return {
          ...layer,
          ...newProperties,
        };
      });

      return {
        ...state,
        shapeInstances: newShapeInstances,
      };
    }

    case types.COMPOSITION_TEXT_LAYER_ADD: {
      const newTextInstances = [
        ...state.textInstances,
        action.payload.layerItem,
      ];

      // Update text layers


      return {
        ...state,
        textInstances: newTextInstances,
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_REMOVE: {
      const { sourceId } = action.payload;

      const newInstances = state.textInstances.filter(
        text => text.id !== sourceId,
      );

      // Update title layers
      const newTitleInstances = updateTitleInstances(newInstances);
      const calcDuration = calculateCompositionDuration(
        stateBgInstances,
        newInstances,
        stateShapeInstances,
        stateLayers,
      );

      return {
        ...state,
        duration: calcDuration,
        audioInstances: state.audioInstances.map(instance => ({
          ...instance,
          to: calcDuration,
        })),
        textInstances: newInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_APPLY_ANIMATION: {
      const { itemId, animationSetting, confirmResizing } = action.payload;

      if (confirmResizing) {
        const { newTextInstances, deltaTime } = checkPushingRequired(
          stateTextInstances,
          itemId,
          animationSetting,
        );

        const resizedTextInstances = newTextInstances.map((text) => {
          if (itemId !== text.id) return text;
          return {
            ...text,
            duration: animationSetting.duration,
            visibleFrom: text.visibleFrom,
            visibleTo: text.visibleTo + deltaTime,
            animation: {
              ...animationSetting,
              out: [
                {
                  ...animationSetting.out[0],
                  from: animationSetting.out[0].from + deltaTime,
                  to: animationSetting.out[0].to + deltaTime,
                },
              ],
            },
          };
        });

        // Update title layers
        const newTitleInstances = updateTitleInstances(resizedTextInstances);

        return {
          ...state,
          textInstances: resizedTextInstances,
          titleInstances: newTitleInstances,
          duration: calculateCompositionDuration(
            state.backgroundInstances,
            newTextInstances,
            stateShapeInstances,
            stateLayers,
          ),
        };
      }

      const newTextInstances = state.textInstances.map((text) => {
        if (text.id !== itemId) return text;

        if (!animationSetting) {
          // remove animation key from text
          const { animation, ...clearedInstance } = text;
          return clearedInstance;
        }
        return {
          ...text,
          animation: animationSetting,
        };
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newTextInstances);

      return {
        ...state,
        textInstances: newTextInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.SHAPELAYER_APPLY_ANIMATION: {
      const { itemId, animationSetting } = action.payload;

      /* if (confirmResizing) {
        const { newTextInstances, deltaTime } = checkPushingRequired(
          textInstances,
          itemId,
          animationSetting,
        );
        const resizedTextInstances = newTextInstances.map((text) => {
          if (itemId !== text.id) return text;
          return {
            ...text,
            duration: animationSetting.duration,
            visibleFrom: text.visibleFrom,
            visibleTo: text.visibleTo + deltaTime,
            animation: {
              ...animationSetting,
              out: [
                {
                  ...animationSetting.out[0],
                  from: animationSetting.out[0].from + deltaTime,
                  to: animationSetting.out[0].to + deltaTime,
                },
              ],
            },
          };
        });

        // Update title layers
        const newTitleInstances = updateTitleInstances(resizedTextInstances);

        return {
          ...state,
          textInstances: resizedTextInstances,
          titleInstances: newTitleInstances,
          duration: calculateCompositionDuration(state.backgroundInstances, newTextInstances),
        };
      } */

      const newShapeInstances = state.shapeInstances.map((shape) => {
        if (shape.id !== itemId) return shape;

        if (!animationSetting) {
          // remove animation key from shape
          const { animation, ...clearedInstance } = shape;
          return clearedInstance;
        }
        return {
          ...shape,
          animation: animationSetting,
        };
      });

      return {
        ...state,
        shapeInstances: newShapeInstances,
      };
    }

    case types.TEXTLAYER_REORDER_ZINDEXES: {
      const { config } = action.payload;

      const newTextInstances = state.textInstances;
      config.forEach((layer) => {
        const foundLayer = newTextInstances.find(
          textInstance => textInstance.id === layer.id,
        );
        if (foundLayer) {
          // eslint-disable-next-line no-param-reassign
          foundLayer.depth = layer.index;
        }
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newTextInstances);

      return {
        ...state,
        textInstances: newTextInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_REORDER_ANIMATION_ORDER: {
      const { config } = action.payload;

      const newTextInstances = state.textInstances;
      config.forEach((layer) => {
        const foundLayer = newTextInstances.find(
          textInstance => textInstance.id === layer.id,
        );
        if (foundLayer) {
          // eslint-disable-next-line no-param-reassign
          foundLayer.animationOrder = layer.index;
        }
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newTextInstances);

      return {
        ...state,
        textInstances: newTextInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.TEXTLAYER_TOGGLE_VISIBLE: {
      const { layerItem } = action.payload;

      const newTextInstances = state.textInstances.map((textInstance) => {
        if (textInstance.id !== layerItem.id) {
          return textInstance;
        }
        if (!layerItem.hidden) {
          return {
            ...textInstance,
            hidden: true,
          };
        }
        return {
          ...textInstance,
          hidden: false,
        };
      });

      // Update title layers
      const newTitleInstances = updateTitleInstances(newTextInstances);

      return {
        ...state,
        textInstances: newTextInstances,
        titleInstances: newTitleInstances,
      };
    }

    case types.MUSIC_TOGGLE_CURRENT_TRACK: {
      return {
        ...state,
        audioInstances: toggleAudio(state.audioInstances, {
          ...action.payload.track,
          from: 0,
          to: state.duration,
        }),
      };
    }

    case types.COMPOSITION_LAYER_ADD: {
      const { item } = action.payload;
      const { visibleFrom, visibleTo } = item;
      // TODO: ask why this is necessary
      if (visibleTo === visibleFrom) return state;
      const newLayes = [
        ...state.layers,
        {
          ...item,
          id: parseInt(
            `${Date.now()}${Math.round(Math.random() * 1000000)}`,
            10,
          ),
        },
      ];
      return {
        ...state,
        duration: calculateCompositionDuration(
          stateBgInstances, stateTextInstances, stateShapeInstances, newLayes,
        ),
        layers: newLayes,
      };
    }

    case types.COMPOSITION_LAYER_REMOVE:
      return {
        ...state,
        layers: state.layers.filter(
          layer => layer.id !== action.payload.itemId,
        ),
      };

    case types.COMPOSITION_LAYER_MODIFY:
      return {
        ...state,
        layers: [
          ...state.layers.map((layer) => {
            if (layer.id !== action.payload.item.id) return layer;
            return {
              ...layer,
              top: action.payload.item.top !== undefined ? action.payload.item.top : layer.top,
              left: action.payload.item.left !== undefined ? action.payload.item.left : layer.left,
              width: action.payload.item.width !== undefined ?
                action.payload.item.width : layer.width,
              height: action.payload.item.height !== undefined ?
                action.payload.item.height : layer.height,
              animationIn:
                action.payload.item.animationIn !== undefined
                  ? action.payload.item.animationIn
                  : layer.animationIn,
              animationOut:
                action.payload.item.animationOut !== undefined
                  ? action.payload.item.animationOut
                  : layer.animationOut,
            };
          }),
        ],
      };

    case types.COMPOSITION_TEXT_MODIFY:
      return {
        ...state,
        textInstances: [
          ...state.textInstances.map((instance) => {
            if (instance.id !== action.payload.id) return instance;
            return {
              ...instance,
              top: action.payload.item.top,
              left: action.payload.item.left,
              width: action.payload.item.width,
              height: action.payload.item.height,
              scaleX: action.payload.item.scaleX,
              scaleY: action.payload.item.scaleY,
              angle: action.payload.item.angle,
            };
          }),
        ],
      };

    case types.COMPOSITION_BACKGROUND_INSTANCE_UPDATE_TIMESTAMPS: {
      const newInstances = state.backgroundInstances.map((instance) => {
        if (instance.id !== action.payload.id) return instance;

        return {
          ...instance,
          visibleFrom: action.payload.item.visibleFrom,
          visibleTo: action.payload.item.visibleTo,
          duration: action.payload.item.duration,
        };
      });

      return {
        ...state,
        duration: calculateCompositionDuration(
          newInstances,
          state.textInstances,
          stateShapeInstances,
          stateLayers,
        ),
        backgroundInstances: calculateCompositionInstanceStartTimes(
          newInstances,
          state.textInstances,
        ),
      };
    }

    case types.COMPOSITION_TEXT_INSTANCE_UPDATE_TIMESTAMPS: {
      const newTextInstances = state.textInstances.map((instance) => {
        if (instance.id !== action.payload.id) return instance;
        const newInstance = {
          ...instance,
          visibleFrom: action.payload.item.visibleFrom,
          visibleTo: action.payload.item.visibleTo,
          duration: action.payload.item.duration,
        };
        return newInstance;
      });

      return {
        ...state,
        duration: calculateCompositionDuration(
          state.backgroundInstances,
          newTextInstances,
          stateShapeInstances,
          stateLayers,
        ),
        textInstances: newTextInstances,
        titleInstances: updateTitleInstances(newTextInstances),
      };
    }

    case types.COMPOSITION_LAYER_ALWAYS_VISIBLE: {
      const { id, alwaysVisible } = action.payload;
      const newLayers = state.layers.map((instance) => {
        if (instance.id !== id) return instance;
        if (alwaysVisible) {
          return {
            ...instance,
            visibleFrom: 0,
            visibleTo: state.duration,
            alwaysVisible,
          };
        }
        return {
          ...instance,
          alwaysVisible,
        };
      });
      return {
        ...state,
        layers: newLayers,
      };
    }

    case types.COMPOSITION_LAYER_INSTANCE_UPDATE_TIMESTAMPS: {
      return {
        ...state,
        layers: [
          ...state.layers.map((layer) => {
            if (layer.id !== action.payload.id) return layer;
            return {
              ...layer,
              visibleFrom: action.payload.item.visibleFrom,
              visibleTo: action.payload.item.visibleTo,
              track: action.payload.item.track,
              alwaysVisible:
                action.payload.item.alwaysVisible !== undefined
                  ? action.payload.item.alwaysVisible
                  : layer.alwaysVisible,
            };
          }),
        ],
      };
    }

    case types.COMPOSITION_SHAPE_INSTANCE_UPDATE_TIMESTAMPS: {
      return {
        ...state,
        shapeInstances: [
          ...state.shapeInstances.map((shape) => {
            if (shape.id !== action.payload.id) return shape;
            return {
              ...shape,
              visibleFrom: action.payload.item.visibleFrom,
              visibleTo: action.payload.item.visibleTo,
              alwaysVisible:
                action.payload.item.alwaysVisible !== undefined
                  ? action.payload.item.alwaysVisible
                  : shape.alwaysVisible,
            };
          }),
        ],
      };
    }

    case types.COMPOSITION_BACKGROUND_INSTANCE_MODIFY:
      return {
        ...state,
        backgroundInstances: state.backgroundInstances.map((instance) => {
          if (instance.id !== action.payload.id) return instance;
          const {
            top,
            left,
            width,
            height,
            scaleX,
            scaleY,
            lockMovementX,
            lockMovementY,
            minX,
            minY,
            maxX,
            maxY,
            angle,
            animationIn,
            animationOut,
            kenburns,
          } = action.payload.item;
          const clone = cloneDeep(instance);

          clone.top = top;
          clone.left = left;
          clone.width = width;
          clone.height = height;
          clone.scaleX = scaleX;
          clone.scaleY = scaleY;
          clone.lockX = lockMovementX;
          clone.lockY = lockMovementY;
          clone.minX = minX;
          clone.minY = minY;
          clone.maxX = maxX;
          clone.maxY = maxY;
          clone.angle = angle;
          clone.animationIn = animationIn;
          clone.animationOut = animationOut;
          if (instance.type === 'image') {
            clone.kenburns = kenburns;
            let zoomEffect;
            if (action.payload.item.kenburns) {
              zoomEffect = calculateZoom(instance);
            }
            return {
              ...instance,
              ...clone,
              zoomEffect,
            };
          }

          return {
            ...instance,
            ...clone,
          };
        }),
      };

    case types.COMPOSITION_BACKGROUND_INSTANCE_MOVE: {
      const { itemId, left, top } = action.payload;

      const newInstances = state.backgroundInstances.map((instance) => {
        if (instance.id !== itemId) return instance;

        return {
          ...instance,
          left,
          top,
        };
      });
      newInstances.sort((a, b) => a.visibleFrom - b.visibleFrom);
      return {
        ...state,
        backgroundInstances: newInstances,
      };
    }

    case types.COMPOSITION_VIDEO_INSTANCE_SPLIT: {
      const { timestamp, video } = action.payload;
      const newBgInstances = calculateCompositionInstanceStartTimes(
        splitVideoAtTimestamp(timestamp, state.backgroundInstances, video),
      );
      return {
        ...state,
        backgroundInstances: newBgInstances,
      };
    }

    case types.TEXTLAYER_APPLY_ATTRIBUTES_ALL:
      return {
        ...state,
        textInstances: applyAttributesToAll(
          action.payload.itemId,
          state.textInstances,
        ),
      };

    case types.COMPOSITION_DUPLICATE_INSTANCES: {
      const newTexts = duplicateTextInstances(
        action.payload.instanceIds,
        state.textInstances,
      );
      const newBg = duplicateInstances(
        action.payload.instanceIds,
        state.backgroundInstances,
      );

      return {
        ...state,
        textInstances: newTexts,
        backgroundInstances: newBg,
        duration: calculateCompositionDuration(newBg, newTexts, stateShapeInstances, stateLayers),
      };
    }

    case types.TITLE_SET_SEQUENCE_DELAY: {
      const { title, appearingDelay } = action.payload;

      const newTitleInstances = state.titleInstances.map((instance) => {
        if ((instance.visibleFrom && instance.visibleTo) !==
          (title.visibleFrom && title.visibleTo)) return instance;
        return {
          ...instance,
          appearingDelay,
        };
      });

      return {
        ...state,
        titleInstances: newTitleInstances,
      };
    }

    default:
      return state;
  }
}
